import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { TableCell, TableRow } from '@mui/material';
import { SelectionCheckbox } from './SelectionCheckbox';
import * as R from 'ramda';
export const TableSelectableRow = ({ selectedRows, onSelectionChange, selectionIndex, children, ...rest }) => {
    const isSelected = selectedRows.includes(selectionIndex);
    const handleCheckboxChange = (_, value) => {
        onSelectionChange(selectionIndex, value);
    };
    const handleCheckboxClick = (event) => {
        if (event.shiftKey) {
            const found = R.sort(R.subtract, selectedRows)?.[0];
            if (R.isNil(found))
                return;
            event.preventDefault();
            event.stopPropagation();
            const change = R.curry(onSelectionChange);
            const first = found < selectionIndex ? found : selectionIndex;
            const last = found < selectionIndex ? selectionIndex + 1 : found;
            R.pipe(
            // we get the range from the first index in the selectedRows and the current clicked index
            R.range(first), 
            // for each index in the range we invert the current selection
            R.forEach(change(R.__, !isSelected)))(last);
        }
    };
    return (_jsxs(TableRow, { selected: isSelected, ...rest, children: [_jsx(TableCell, { onClick: handleCheckboxClick, children: _jsx(SelectionCheckbox, { checked: isSelected, onChange: handleCheckboxChange, "aria-label": "select row" }) }), children] }));
};
