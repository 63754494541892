{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-28",
    "endpointPrefix": "lightsail",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Lightsail",
    "serviceId": "Lightsail",
    "signatureVersion": "v4",
    "targetPrefix": "Lightsail_20161128",
    "uid": "lightsail-2016-11-28"
  },
  "operations": {
    "AllocateStaticIp": {
      "input": {
        "type": "structure",
        "required": [
          "staticIpName"
        ],
        "members": {
          "staticIpName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "AttachDisk": {
      "input": {
        "type": "structure",
        "required": [
          "diskName",
          "instanceName",
          "diskPath"
        ],
        "members": {
          "diskName": {},
          "instanceName": {},
          "diskPath": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "AttachInstancesToLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "instanceNames"
        ],
        "members": {
          "loadBalancerName": {},
          "instanceNames": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "AttachLoadBalancerTlsCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "certificateName"
        ],
        "members": {
          "loadBalancerName": {},
          "certificateName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "AttachStaticIp": {
      "input": {
        "type": "structure",
        "required": [
          "staticIpName",
          "instanceName"
        ],
        "members": {
          "staticIpName": {},
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CloseInstancePublicPorts": {
      "input": {
        "type": "structure",
        "required": [
          "portInfo",
          "instanceName"
        ],
        "members": {
          "portInfo": {
            "shape": "Sp"
          },
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "sourceSnapshotName",
          "targetSnapshotName",
          "sourceRegion"
        ],
        "members": {
          "sourceSnapshotName": {},
          "targetSnapshotName": {},
          "sourceRegion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateCloudFormationStack": {
      "input": {
        "type": "structure",
        "required": [
          "instances"
        ],
        "members": {
          "instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "sourceName",
                "instanceType",
                "portInfoSource",
                "availabilityZone"
              ],
              "members": {
                "sourceName": {},
                "instanceType": {},
                "portInfoSource": {},
                "userData": {},
                "availabilityZone": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateDisk": {
      "input": {
        "type": "structure",
        "required": [
          "diskName",
          "availabilityZone",
          "sizeInGb"
        ],
        "members": {
          "diskName": {},
          "availabilityZone": {},
          "sizeInGb": {
            "type": "integer"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateDiskFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "diskName",
          "diskSnapshotName",
          "availabilityZone",
          "sizeInGb"
        ],
        "members": {
          "diskName": {},
          "diskSnapshotName": {},
          "availabilityZone": {},
          "sizeInGb": {
            "type": "integer"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateDiskSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "diskSnapshotName"
        ],
        "members": {
          "diskName": {},
          "diskSnapshotName": {},
          "instanceName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateDomain": {
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "CreateDomainEntry": {
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "domainEntry"
        ],
        "members": {
          "domainName": {},
          "domainEntry": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "CreateInstanceSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "instanceSnapshotName",
          "instanceName"
        ],
        "members": {
          "instanceSnapshotName": {},
          "instanceName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateInstances": {
      "input": {
        "type": "structure",
        "required": [
          "instanceNames",
          "availabilityZone",
          "blueprintId",
          "bundleId"
        ],
        "members": {
          "instanceNames": {
            "shape": "S1n"
          },
          "availabilityZone": {},
          "customImageName": {
            "deprecated": true
          },
          "blueprintId": {},
          "bundleId": {},
          "userData": {},
          "keyPairName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateInstancesFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "instanceNames",
          "availabilityZone",
          "instanceSnapshotName",
          "bundleId"
        ],
        "members": {
          "instanceNames": {
            "shape": "S1n"
          },
          "attachedDiskMapping": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "originalDiskPath": {},
                  "newDiskName": {}
                }
              }
            }
          },
          "availabilityZone": {},
          "instanceSnapshotName": {},
          "bundleId": {},
          "userData": {},
          "keyPairName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "keyPairName"
        ],
        "members": {
          "keyPairName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "keyPair": {
            "shape": "S1w"
          },
          "publicKeyBase64": {},
          "privateKeyBase64": {},
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "CreateLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "instancePort"
        ],
        "members": {
          "loadBalancerName": {},
          "instancePort": {
            "type": "integer"
          },
          "healthCheckPath": {},
          "certificateName": {},
          "certificateDomainName": {},
          "certificateAlternativeNames": {
            "shape": "S1z"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateLoadBalancerTlsCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "certificateName",
          "certificateDomainName"
        ],
        "members": {
          "loadBalancerName": {},
          "certificateName": {},
          "certificateDomainName": {},
          "certificateAlternativeNames": {
            "shape": "S1z"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateRelationalDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName",
          "relationalDatabaseBlueprintId",
          "relationalDatabaseBundleId",
          "masterDatabaseName",
          "masterUsername"
        ],
        "members": {
          "relationalDatabaseName": {},
          "availabilityZone": {},
          "relationalDatabaseBlueprintId": {},
          "relationalDatabaseBundleId": {},
          "masterDatabaseName": {},
          "masterUsername": {},
          "masterUserPassword": {
            "shape": "S24"
          },
          "preferredBackupWindow": {},
          "preferredMaintenanceWindow": {},
          "publiclyAccessible": {
            "type": "boolean"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateRelationalDatabaseFromSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "availabilityZone": {},
          "publiclyAccessible": {
            "type": "boolean"
          },
          "relationalDatabaseSnapshotName": {},
          "relationalDatabaseBundleId": {},
          "sourceRelationalDatabaseName": {},
          "restoreTime": {
            "type": "timestamp"
          },
          "useLatestRestorableTime": {
            "type": "boolean"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateRelationalDatabaseSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName",
          "relationalDatabaseSnapshotName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "relationalDatabaseSnapshotName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteDisk": {
      "input": {
        "type": "structure",
        "required": [
          "diskName"
        ],
        "members": {
          "diskName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteDiskSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "diskSnapshotName"
        ],
        "members": {
          "diskSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteDomain": {
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "DeleteDomainEntry": {
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "domainEntry"
        ],
        "members": {
          "domainName": {},
          "domainEntry": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "DeleteInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteInstanceSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "instanceSnapshotName"
        ],
        "members": {
          "instanceSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "keyPairName"
        ],
        "members": {
          "keyPairName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "DeleteKnownHostKeys": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName"
        ],
        "members": {
          "loadBalancerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteLoadBalancerTlsCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "certificateName"
        ],
        "members": {
          "loadBalancerName": {},
          "certificateName": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteRelationalDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "skipFinalSnapshot": {
            "type": "boolean"
          },
          "finalRelationalDatabaseSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteRelationalDatabaseSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseSnapshotName"
        ],
        "members": {
          "relationalDatabaseSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DetachDisk": {
      "input": {
        "type": "structure",
        "required": [
          "diskName"
        ],
        "members": {
          "diskName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DetachInstancesFromLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "instanceNames"
        ],
        "members": {
          "loadBalancerName": {},
          "instanceNames": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DetachStaticIp": {
      "input": {
        "type": "structure",
        "required": [
          "staticIpName"
        ],
        "members": {
          "staticIpName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "DownloadDefaultKeyPair": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "publicKeyBase64": {},
          "privateKeyBase64": {}
        }
      }
    },
    "ExportSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "sourceSnapshotName"
        ],
        "members": {
          "sourceSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "GetActiveNames": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "activeNames": {
            "shape": "S1n"
          },
          "nextPageToken": {}
        }
      }
    },
    "GetBlueprints": {
      "input": {
        "type": "structure",
        "members": {
          "includeInactive": {
            "type": "boolean"
          },
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "blueprints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "blueprintId": {},
                "name": {},
                "group": {},
                "type": {},
                "description": {},
                "isActive": {
                  "type": "boolean"
                },
                "minPower": {
                  "type": "integer"
                },
                "version": {},
                "versionCode": {},
                "productUrl": {},
                "licenseUrl": {},
                "platform": {}
              }
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetBundles": {
      "input": {
        "type": "structure",
        "members": {
          "includeInactive": {
            "type": "boolean"
          },
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "bundles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "price": {
                  "type": "float"
                },
                "cpuCount": {
                  "type": "integer"
                },
                "diskSizeInGb": {
                  "type": "integer"
                },
                "bundleId": {},
                "instanceType": {},
                "isActive": {
                  "type": "boolean"
                },
                "name": {},
                "power": {
                  "type": "integer"
                },
                "ramSizeInGb": {
                  "type": "float"
                },
                "transferPerMonthInGb": {
                  "type": "integer"
                },
                "supportedPlatforms": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetCloudFormationStackRecords": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cloudFormationStackRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "location": {
                  "shape": "S9"
                },
                "resourceType": {},
                "state": {},
                "sourceInfo": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "resourceType": {},
                      "name": {},
                      "arn": {}
                    }
                  }
                },
                "destinationInfo": {
                  "shape": "S3u"
                }
              }
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetDisk": {
      "input": {
        "type": "structure",
        "required": [
          "diskName"
        ],
        "members": {
          "diskName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "disk": {
            "shape": "S3x"
          }
        }
      }
    },
    "GetDiskSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "diskSnapshotName"
        ],
        "members": {
          "diskSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "diskSnapshot": {
            "shape": "S41"
          }
        }
      }
    },
    "GetDiskSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "diskSnapshots": {
            "type": "list",
            "member": {
              "shape": "S41"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetDisks": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "disks": {
            "shape": "S48"
          },
          "nextPageToken": {}
        }
      }
    },
    "GetDomain": {
      "input": {
        "type": "structure",
        "required": [
          "domainName"
        ],
        "members": {
          "domainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domain": {
            "shape": "S4b"
          }
        }
      }
    },
    "GetDomains": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "domains": {
            "type": "list",
            "member": {
              "shape": "S4b"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetExportSnapshotRecords": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "exportSnapshotRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "location": {
                  "shape": "S9"
                },
                "resourceType": {},
                "state": {},
                "sourceInfo": {
                  "type": "structure",
                  "members": {
                    "resourceType": {},
                    "createdAt": {
                      "type": "timestamp"
                    },
                    "name": {},
                    "arn": {},
                    "fromResourceName": {},
                    "fromResourceArn": {},
                    "instanceSnapshotInfo": {
                      "type": "structure",
                      "members": {
                        "fromBundleId": {},
                        "fromBlueprintId": {},
                        "fromDiskInfo": {
                          "type": "list",
                          "member": {
                            "type": "structure",
                            "members": {
                              "name": {},
                              "path": {},
                              "sizeInGb": {
                                "type": "integer"
                              },
                              "isSystemDisk": {
                                "type": "boolean"
                              }
                            }
                          }
                        }
                      }
                    },
                    "diskSnapshotInfo": {
                      "type": "structure",
                      "members": {
                        "sizeInGb": {
                          "type": "integer"
                        }
                      }
                    }
                  }
                },
                "destinationInfo": {
                  "shape": "S3u"
                }
              }
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instance": {
            "shape": "S4s"
          }
        }
      }
    },
    "GetInstanceAccessDetails": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {},
          "protocol": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "accessDetails": {
            "type": "structure",
            "members": {
              "certKey": {},
              "expiresAt": {
                "type": "timestamp"
              },
              "ipAddress": {},
              "password": {},
              "passwordData": {
                "type": "structure",
                "members": {
                  "ciphertext": {},
                  "keyPairName": {}
                }
              },
              "privateKey": {},
              "protocol": {},
              "instanceName": {},
              "username": {},
              "hostKeys": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "algorithm": {},
                    "publicKey": {},
                    "witnessedAt": {
                      "type": "timestamp"
                    },
                    "fingerprintSHA1": {},
                    "fingerprintSHA256": {},
                    "notValidBefore": {
                      "type": "timestamp"
                    },
                    "notValidAfter": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetInstanceMetricData": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName",
          "metricName",
          "period",
          "startTime",
          "endTime",
          "unit",
          "statistics"
        ],
        "members": {
          "instanceName": {},
          "metricName": {},
          "period": {
            "type": "integer"
          },
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "unit": {},
          "statistics": {
            "shape": "S5f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "metricData": {
            "shape": "S5i"
          }
        }
      }
    },
    "GetInstancePortStates": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "portStates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "fromPort": {
                  "type": "integer"
                },
                "toPort": {
                  "type": "integer"
                },
                "protocol": {},
                "state": {}
              }
            }
          }
        }
      }
    },
    "GetInstanceSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "instanceSnapshotName"
        ],
        "members": {
          "instanceSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceSnapshot": {
            "shape": "S5s"
          }
        }
      }
    },
    "GetInstanceSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instanceSnapshots": {
            "type": "list",
            "member": {
              "shape": "S5s"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetInstanceState": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "state": {
            "shape": "S52"
          }
        }
      }
    },
    "GetInstances": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "instances": {
            "type": "list",
            "member": {
              "shape": "S4s"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "keyPairName"
        ],
        "members": {
          "keyPairName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "keyPair": {
            "shape": "S1w"
          }
        }
      }
    },
    "GetKeyPairs": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "keyPairs": {
            "type": "list",
            "member": {
              "shape": "S1w"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetLoadBalancer": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName"
        ],
        "members": {
          "loadBalancerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loadBalancer": {
            "shape": "S69"
          }
        }
      }
    },
    "GetLoadBalancerMetricData": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "metricName",
          "period",
          "startTime",
          "endTime",
          "unit",
          "statistics"
        ],
        "members": {
          "loadBalancerName": {},
          "metricName": {},
          "period": {
            "type": "integer"
          },
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "unit": {},
          "statistics": {
            "shape": "S5f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "metricData": {
            "shape": "S5i"
          }
        }
      }
    },
    "GetLoadBalancerTlsCertificates": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName"
        ],
        "members": {
          "loadBalancerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tlsCertificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "arn": {},
                "supportCode": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "location": {
                  "shape": "S9"
                },
                "resourceType": {},
                "tags": {
                  "shape": "S12"
                },
                "loadBalancerName": {},
                "isAttached": {
                  "type": "boolean"
                },
                "status": {},
                "domainName": {},
                "domainValidationRecords": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "name": {},
                      "type": {},
                      "value": {},
                      "validationStatus": {},
                      "domainName": {}
                    }
                  }
                },
                "failureReason": {},
                "issuedAt": {
                  "type": "timestamp"
                },
                "issuer": {},
                "keyAlgorithm": {},
                "notAfter": {
                  "type": "timestamp"
                },
                "notBefore": {
                  "type": "timestamp"
                },
                "renewalSummary": {
                  "type": "structure",
                  "members": {
                    "renewalStatus": {},
                    "domainValidationOptions": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "domainName": {},
                          "validationStatus": {}
                        }
                      }
                    }
                  }
                },
                "revocationReason": {},
                "revokedAt": {
                  "type": "timestamp"
                },
                "serial": {},
                "signatureAlgorithm": {},
                "subject": {},
                "subjectAlternativeNames": {
                  "shape": "S1n"
                }
              }
            }
          }
        }
      }
    },
    "GetLoadBalancers": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loadBalancers": {
            "type": "list",
            "member": {
              "shape": "S69"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetOperation": {
      "input": {
        "type": "structure",
        "required": [
          "operationId"
        ],
        "members": {
          "operationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "GetOperations": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          },
          "nextPageToken": {}
        }
      }
    },
    "GetOperationsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceName"
        ],
        "members": {
          "resourceName": {},
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          },
          "nextPageCount": {
            "deprecated": true
          },
          "nextPageToken": {}
        }
      }
    },
    "GetRegions": {
      "input": {
        "type": "structure",
        "members": {
          "includeAvailabilityZones": {
            "type": "boolean"
          },
          "includeRelationalDatabaseAvailabilityZones": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "regions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "continentCode": {},
                "description": {},
                "displayName": {},
                "name": {},
                "availabilityZones": {
                  "shape": "S7f"
                },
                "relationalDatabaseAvailabilityZones": {
                  "shape": "S7f"
                }
              }
            }
          }
        }
      }
    },
    "GetRelationalDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "relationalDatabase": {
            "shape": "S7j"
          }
        }
      }
    },
    "GetRelationalDatabaseBlueprints": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "blueprints": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "blueprintId": {},
                "engine": {},
                "engineVersion": {},
                "engineDescription": {},
                "engineVersionDescription": {},
                "isEngineDefault": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetRelationalDatabaseBundles": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "bundles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "bundleId": {},
                "name": {},
                "price": {
                  "type": "float"
                },
                "ramSizeInGb": {
                  "type": "float"
                },
                "diskSizeInGb": {
                  "type": "integer"
                },
                "transferPerMonthInGb": {
                  "type": "integer"
                },
                "cpuCount": {
                  "type": "integer"
                },
                "isEncrypted": {
                  "type": "boolean"
                },
                "isActive": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetRelationalDatabaseEvents": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "durationInMinutes": {
            "type": "integer"
          },
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "relationalDatabaseEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resource": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "message": {},
                "eventCategories": {
                  "shape": "S1n"
                }
              }
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetRelationalDatabaseLogEvents": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName",
          "logStreamName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "logStreamName": {},
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "startFromHead": {
            "type": "boolean"
          },
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceLogEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "createdAt": {
                  "type": "timestamp"
                },
                "message": {}
              }
            }
          },
          "nextBackwardToken": {},
          "nextForwardToken": {}
        }
      }
    },
    "GetRelationalDatabaseLogStreams": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logStreams": {
            "shape": "S1n"
          }
        }
      }
    },
    "GetRelationalDatabaseMasterUserPassword": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "passwordVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "masterUserPassword": {
            "shape": "S24"
          },
          "createdAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetRelationalDatabaseMetricData": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName",
          "metricName",
          "period",
          "startTime",
          "endTime",
          "unit",
          "statistics"
        ],
        "members": {
          "relationalDatabaseName": {},
          "metricName": {},
          "period": {
            "type": "integer"
          },
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "unit": {},
          "statistics": {
            "shape": "S5f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "metricName": {},
          "metricData": {
            "shape": "S5i"
          }
        }
      }
    },
    "GetRelationalDatabaseParameters": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "parameters": {
            "shape": "S8g"
          },
          "nextPageToken": {}
        }
      }
    },
    "GetRelationalDatabaseSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseSnapshotName"
        ],
        "members": {
          "relationalDatabaseSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "relationalDatabaseSnapshot": {
            "shape": "S8k"
          }
        }
      }
    },
    "GetRelationalDatabaseSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "relationalDatabaseSnapshots": {
            "type": "list",
            "member": {
              "shape": "S8k"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetRelationalDatabases": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "relationalDatabases": {
            "type": "list",
            "member": {
              "shape": "S7j"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "GetStaticIp": {
      "input": {
        "type": "structure",
        "required": [
          "staticIpName"
        ],
        "members": {
          "staticIpName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "staticIp": {
            "shape": "S8t"
          }
        }
      }
    },
    "GetStaticIps": {
      "input": {
        "type": "structure",
        "members": {
          "pageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "staticIps": {
            "type": "list",
            "member": {
              "shape": "S8t"
            }
          },
          "nextPageToken": {}
        }
      }
    },
    "ImportKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "keyPairName",
          "publicKeyBase64"
        ],
        "members": {
          "keyPairName": {},
          "publicKeyBase64": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "IsVpcPeered": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "isPeered": {
            "type": "boolean"
          }
        }
      }
    },
    "OpenInstancePublicPorts": {
      "input": {
        "type": "structure",
        "required": [
          "portInfo",
          "instanceName"
        ],
        "members": {
          "portInfo": {
            "shape": "Sp"
          },
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "PeerVpc": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "PutInstancePublicPorts": {
      "input": {
        "type": "structure",
        "required": [
          "portInfos",
          "instanceName"
        ],
        "members": {
          "portInfos": {
            "type": "list",
            "member": {
              "shape": "Sp"
            }
          },
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "RebootInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "RebootRelationalDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "ReleaseStaticIp": {
      "input": {
        "type": "structure",
        "required": [
          "staticIpName"
        ],
        "members": {
          "staticIpName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "StartInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "StartRelationalDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "StopInstance": {
      "input": {
        "type": "structure",
        "required": [
          "instanceName"
        ],
        "members": {
          "instanceName": {},
          "force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "StopRelationalDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "relationalDatabaseSnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceName",
          "tags"
        ],
        "members": {
          "resourceName": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "UnpeerVpc": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "operation": {
            "shape": "S5"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceName",
          "tagKeys"
        ],
        "members": {
          "resourceName": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "UpdateDomainEntry": {
      "input": {
        "type": "structure",
        "required": [
          "domainName",
          "domainEntry"
        ],
        "members": {
          "domainName": {},
          "domainEntry": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "UpdateLoadBalancerAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "loadBalancerName",
          "attributeName",
          "attributeValue"
        ],
        "members": {
          "loadBalancerName": {},
          "attributeName": {},
          "attributeValue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "UpdateRelationalDatabase": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName"
        ],
        "members": {
          "relationalDatabaseName": {},
          "masterUserPassword": {
            "shape": "S24"
          },
          "rotateMasterUserPassword": {
            "type": "boolean"
          },
          "preferredBackupWindow": {},
          "preferredMaintenanceWindow": {},
          "enableBackupRetention": {
            "type": "boolean"
          },
          "disableBackupRetention": {
            "type": "boolean"
          },
          "publiclyAccessible": {
            "type": "boolean"
          },
          "applyImmediately": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    },
    "UpdateRelationalDatabaseParameters": {
      "input": {
        "type": "structure",
        "required": [
          "relationalDatabaseName",
          "parameters"
        ],
        "members": {
          "relationalDatabaseName": {},
          "parameters": {
            "shape": "S8g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "operations": {
            "shape": "S4"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "shape": "S5"
      }
    },
    "S5": {
      "type": "structure",
      "members": {
        "id": {},
        "resourceName": {},
        "resourceType": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "isTerminal": {
          "type": "boolean"
        },
        "operationDetails": {},
        "operationType": {},
        "status": {},
        "statusChangedAt": {
          "type": "timestamp"
        },
        "errorCode": {},
        "errorDetails": {}
      }
    },
    "S9": {
      "type": "structure",
      "members": {
        "availabilityZone": {},
        "regionName": {}
      }
    },
    "Si": {
      "type": "list",
      "member": {}
    },
    "Sp": {
      "type": "structure",
      "members": {
        "fromPort": {
          "type": "integer"
        },
        "toPort": {
          "type": "integer"
        },
        "protocol": {}
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "id": {},
        "name": {},
        "target": {},
        "isAlias": {
          "type": "boolean"
        },
        "type": {},
        "options": {
          "deprecated": true,
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "S1n": {
      "type": "list",
      "member": {}
    },
    "S1w": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "fingerprint": {}
      }
    },
    "S1z": {
      "type": "list",
      "member": {}
    },
    "S24": {
      "type": "string",
      "sensitive": true
    },
    "S3u": {
      "type": "structure",
      "members": {
        "id": {},
        "service": {}
      }
    },
    "S3x": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "sizeInGb": {
          "type": "integer"
        },
        "isSystemDisk": {
          "type": "boolean"
        },
        "iops": {
          "type": "integer"
        },
        "path": {},
        "state": {},
        "attachedTo": {},
        "isAttached": {
          "type": "boolean"
        },
        "attachmentState": {
          "deprecated": true
        },
        "gbInUse": {
          "deprecated": true,
          "type": "integer"
        }
      }
    },
    "S41": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "sizeInGb": {
          "type": "integer"
        },
        "state": {},
        "progress": {},
        "fromDiskName": {},
        "fromDiskArn": {},
        "fromInstanceName": {},
        "fromInstanceArn": {}
      }
    },
    "S48": {
      "type": "list",
      "member": {
        "shape": "S3x"
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "domainEntries": {
          "type": "list",
          "member": {
            "shape": "S1f"
          }
        }
      }
    },
    "S4s": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "blueprintId": {},
        "blueprintName": {},
        "bundleId": {},
        "isStaticIp": {
          "type": "boolean"
        },
        "privateIpAddress": {},
        "publicIpAddress": {},
        "ipv6Address": {},
        "hardware": {
          "type": "structure",
          "members": {
            "cpuCount": {
              "type": "integer"
            },
            "disks": {
              "shape": "S48"
            },
            "ramSizeInGb": {
              "type": "float"
            }
          }
        },
        "networking": {
          "type": "structure",
          "members": {
            "monthlyTransfer": {
              "type": "structure",
              "members": {
                "gbPerMonthAllocated": {
                  "type": "integer"
                }
              }
            },
            "ports": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "fromPort": {
                    "type": "integer"
                  },
                  "toPort": {
                    "type": "integer"
                  },
                  "protocol": {},
                  "accessFrom": {},
                  "accessType": {},
                  "commonName": {},
                  "accessDirection": {}
                }
              }
            }
          }
        },
        "state": {
          "shape": "S52"
        },
        "username": {},
        "sshKeyName": {}
      }
    },
    "S52": {
      "type": "structure",
      "members": {
        "code": {
          "type": "integer"
        },
        "name": {}
      }
    },
    "S5f": {
      "type": "list",
      "member": {}
    },
    "S5i": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "average": {
            "type": "double"
          },
          "maximum": {
            "type": "double"
          },
          "minimum": {
            "type": "double"
          },
          "sampleCount": {
            "type": "double"
          },
          "sum": {
            "type": "double"
          },
          "timestamp": {
            "type": "timestamp"
          },
          "unit": {}
        }
      }
    },
    "S5s": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "state": {},
        "progress": {},
        "fromAttachedDisks": {
          "shape": "S48"
        },
        "fromInstanceName": {},
        "fromInstanceArn": {},
        "fromBlueprintId": {},
        "fromBundleId": {},
        "sizeInGb": {
          "type": "integer"
        }
      }
    },
    "S69": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "dnsName": {},
        "state": {},
        "protocol": {},
        "publicPorts": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        },
        "healthCheckPath": {},
        "instancePort": {
          "type": "integer"
        },
        "instanceHealthSummary": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "instanceName": {},
              "instanceHealth": {},
              "instanceHealthReason": {}
            }
          }
        },
        "tlsCertificateSummaries": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "name": {},
              "isAttached": {
                "type": "boolean"
              }
            }
          }
        },
        "configurationOptions": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    },
    "S7f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "zoneName": {},
          "state": {}
        }
      }
    },
    "S7j": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "relationalDatabaseBlueprintId": {},
        "relationalDatabaseBundleId": {},
        "masterDatabaseName": {},
        "hardware": {
          "type": "structure",
          "members": {
            "cpuCount": {
              "type": "integer"
            },
            "diskSizeInGb": {
              "type": "integer"
            },
            "ramSizeInGb": {
              "type": "float"
            }
          }
        },
        "state": {},
        "secondaryAvailabilityZone": {},
        "backupRetentionEnabled": {
          "type": "boolean"
        },
        "pendingModifiedValues": {
          "type": "structure",
          "members": {
            "masterUserPassword": {},
            "engineVersion": {},
            "backupRetentionEnabled": {
              "type": "boolean"
            }
          }
        },
        "engine": {},
        "engineVersion": {},
        "latestRestorableTime": {
          "type": "timestamp"
        },
        "masterUsername": {},
        "parameterApplyStatus": {},
        "preferredBackupWindow": {},
        "preferredMaintenanceWindow": {},
        "publiclyAccessible": {
          "type": "boolean"
        },
        "masterEndpoint": {
          "type": "structure",
          "members": {
            "port": {
              "type": "integer"
            },
            "address": {}
          }
        },
        "pendingMaintenanceActions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "action": {},
              "description": {},
              "currentApplyDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "S8g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "allowedValues": {},
          "applyMethod": {},
          "applyType": {},
          "dataType": {},
          "description": {},
          "isModifiable": {
            "type": "boolean"
          },
          "parameterName": {},
          "parameterValue": {}
        }
      }
    },
    "S8k": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "tags": {
          "shape": "S12"
        },
        "engine": {},
        "engineVersion": {},
        "sizeInGb": {
          "type": "integer"
        },
        "state": {},
        "fromRelationalDatabaseName": {},
        "fromRelationalDatabaseArn": {},
        "fromRelationalDatabaseBundleId": {},
        "fromRelationalDatabaseBlueprintId": {}
      }
    },
    "S8t": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "supportCode": {},
        "createdAt": {
          "type": "timestamp"
        },
        "location": {
          "shape": "S9"
        },
        "resourceType": {},
        "ipAddress": {},
        "attachedTo": {},
        "isAttached": {
          "type": "boolean"
        }
      }
    }
  }
}