import React, { lazy } from 'react';
import { PageHeading } from '../UI/PageHeading';
import { Box, Divider } from '@mui/material';
import { ConfigReturnType } from '../../hooks';

type PagePropsType = {
  providers: ConfigReturnType['identityProviders'];
  title: string;
  Component: React.FC<any>;
};

const Federate = lazy(() => import('../Federate'));

export const Page: React.FC<PagePropsType> = ({ title, Component, providers }: PagePropsType) => {
  return (
    <div>
      <PageHeading>{title}</PageHeading>
      {providers.email && <Component />}
      {(providers.google || providers.googleWorkspace) && (
        <div>
          {providers.email && (
            <Box px={3}>
              <Divider>oder</Divider>
            </Box>
          )}
          {providers.google && <Federate provider={providers.google} />}
          {providers.googleWorkspace && <Federate provider={providers.googleWorkspace} />}
        </div>
      )}
    </div>
  );
};
