{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-07-25",
    "endpointPrefix": "appsync",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AWSAppSync",
    "serviceFullName": "AWS AppSync",
    "serviceId": "AppSync",
    "signatureVersion": "v4",
    "signingName": "appsync",
    "uid": "appsync-2017-07-25"
  },
  "operations": {
    "CreateApiKey": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/apikeys"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "description": {},
          "expires": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiKey": {
            "shape": "S5"
          }
        }
      }
    },
    "CreateDataSource": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/datasources"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "type"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "description": {},
          "type": {},
          "serviceRoleArn": {},
          "dynamodbConfig": {
            "shape": "S9"
          },
          "lambdaConfig": {
            "shape": "Sb"
          },
          "elasticsearchConfig": {
            "shape": "Sc"
          },
          "httpConfig": {
            "shape": "Sd"
          },
          "relationalDatabaseConfig": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSource": {
            "shape": "Sl"
          }
        }
      }
    },
    "CreateFunction": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/functions"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "dataSourceName",
          "requestMappingTemplate",
          "functionVersion"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "description": {},
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "functionVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functionConfiguration": {
            "shape": "Sp"
          }
        }
      }
    },
    "CreateGraphqlApi": {
      "http": {
        "requestUri": "/v1/apis"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "authenticationType"
        ],
        "members": {
          "name": {},
          "logConfig": {
            "shape": "Sr"
          },
          "authenticationType": {},
          "userPoolConfig": {
            "shape": "Su"
          },
          "openIDConnectConfig": {
            "shape": "Sw"
          },
          "tags": {
            "shape": "Sx"
          },
          "additionalAuthenticationProviders": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApi": {
            "shape": "S14"
          }
        }
      }
    },
    "CreateResolver": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName",
          "requestMappingTemplate"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {},
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "kind": {},
          "pipelineConfig": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolver": {
            "shape": "S1b"
          }
        }
      }
    },
    "CreateType": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "definition",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "definition": {},
          "format": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "type": {
            "shape": "S1f"
          }
        }
      }
    },
    "DeleteApiKey": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/apikeys/{id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "id"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteDataSource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/datasources/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "functionId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteGraphqlApi": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteResolver": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers/{fieldName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {
            "location": "uri",
            "locationName": "fieldName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetDataSource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/datasources/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSource": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "functionId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functionConfiguration": {
            "shape": "Sp"
          }
        }
      }
    },
    "GetGraphqlApi": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApi": {
            "shape": "S14"
          }
        }
      }
    },
    "GetIntrospectionSchema": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/schema"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "includeDirectives": {
            "location": "querystring",
            "locationName": "includeDirectives",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "schema": {
            "type": "blob"
          }
        },
        "payload": "schema"
      }
    },
    "GetResolver": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers/{fieldName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {
            "location": "uri",
            "locationName": "fieldName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolver": {
            "shape": "S1b"
          }
        }
      }
    },
    "GetSchemaCreationStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/schemacreation"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "details": {}
        }
      }
    },
    "GetType": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "type": {
            "shape": "S1f"
          }
        }
      }
    },
    "ListApiKeys": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/apikeys"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiKeys": {
            "type": "list",
            "member": {
              "shape": "S5"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDataSources": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/datasources"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSources": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/functions"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functions": {
            "type": "list",
            "member": {
              "shape": "Sp"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListGraphqlApis": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApis": {
            "type": "list",
            "member": {
              "shape": "S14"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListResolvers": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolvers": {
            "shape": "S2q"
          },
          "nextToken": {}
        }
      }
    },
    "ListResolversByFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}/resolvers"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "functionId"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolvers": {
            "shape": "S2q"
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "Sx"
          }
        }
      }
    },
    "ListTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apis/{apiId}/types"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "format": {
            "location": "querystring",
            "locationName": "format"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "types": {
            "type": "list",
            "member": {
              "shape": "S1f"
            }
          },
          "nextToken": {}
        }
      }
    },
    "StartSchemaCreation": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/schemacreation"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "definition"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "definition": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {}
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateApiKey": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/apikeys/{id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "id"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "id": {
            "location": "uri",
            "locationName": "id"
          },
          "description": {},
          "expires": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "apiKey": {
            "shape": "S5"
          }
        }
      }
    },
    "UpdateDataSource": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/datasources/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "type"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "description": {},
          "type": {},
          "serviceRoleArn": {},
          "dynamodbConfig": {
            "shape": "S9"
          },
          "lambdaConfig": {
            "shape": "Sb"
          },
          "elasticsearchConfig": {
            "shape": "Sc"
          },
          "httpConfig": {
            "shape": "Sd"
          },
          "relationalDatabaseConfig": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "dataSource": {
            "shape": "Sl"
          }
        }
      }
    },
    "UpdateFunction": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/functions/{functionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name",
          "functionId",
          "dataSourceName",
          "requestMappingTemplate",
          "functionVersion"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "description": {},
          "functionId": {
            "location": "uri",
            "locationName": "functionId"
          },
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "functionVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "functionConfiguration": {
            "shape": "Sp"
          }
        }
      }
    },
    "UpdateGraphqlApi": {
      "http": {
        "requestUri": "/v1/apis/{apiId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "name"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "name": {},
          "logConfig": {
            "shape": "Sr"
          },
          "authenticationType": {},
          "userPoolConfig": {
            "shape": "Su"
          },
          "openIDConnectConfig": {
            "shape": "Sw"
          },
          "additionalAuthenticationProviders": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "graphqlApi": {
            "shape": "S14"
          }
        }
      }
    },
    "UpdateResolver": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types/{typeName}/resolvers/{fieldName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "fieldName",
          "requestMappingTemplate"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "fieldName": {
            "location": "uri",
            "locationName": "fieldName"
          },
          "dataSourceName": {},
          "requestMappingTemplate": {},
          "responseMappingTemplate": {},
          "kind": {},
          "pipelineConfig": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resolver": {
            "shape": "S1b"
          }
        }
      }
    },
    "UpdateType": {
      "http": {
        "requestUri": "/v1/apis/{apiId}/types/{typeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "apiId",
          "typeName",
          "format"
        ],
        "members": {
          "apiId": {
            "location": "uri",
            "locationName": "apiId"
          },
          "typeName": {
            "location": "uri",
            "locationName": "typeName"
          },
          "definition": {},
          "format": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "type": {
            "shape": "S1f"
          }
        }
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "id": {},
        "description": {},
        "expires": {
          "type": "long"
        }
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "tableName",
        "awsRegion"
      ],
      "members": {
        "tableName": {},
        "awsRegion": {},
        "useCallerCredentials": {
          "type": "boolean"
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "lambdaFunctionArn"
      ],
      "members": {
        "lambdaFunctionArn": {}
      }
    },
    "Sc": {
      "type": "structure",
      "required": [
        "endpoint",
        "awsRegion"
      ],
      "members": {
        "endpoint": {},
        "awsRegion": {}
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "endpoint": {},
        "authorizationConfig": {
          "type": "structure",
          "required": [
            "authorizationType"
          ],
          "members": {
            "authorizationType": {},
            "awsIamConfig": {
              "type": "structure",
              "members": {
                "signingRegion": {},
                "signingServiceName": {}
              }
            }
          }
        }
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "relationalDatabaseSourceType": {},
        "rdsHttpEndpointConfig": {
          "type": "structure",
          "members": {
            "awsRegion": {},
            "dbClusterIdentifier": {},
            "databaseName": {},
            "schema": {},
            "awsSecretStoreArn": {}
          }
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "dataSourceArn": {},
        "name": {},
        "description": {},
        "type": {},
        "serviceRoleArn": {},
        "dynamodbConfig": {
          "shape": "S9"
        },
        "lambdaConfig": {
          "shape": "Sb"
        },
        "elasticsearchConfig": {
          "shape": "Sc"
        },
        "httpConfig": {
          "shape": "Sd"
        },
        "relationalDatabaseConfig": {
          "shape": "Sh"
        }
      }
    },
    "Sp": {
      "type": "structure",
      "members": {
        "functionId": {},
        "functionArn": {},
        "name": {},
        "description": {},
        "dataSourceName": {},
        "requestMappingTemplate": {},
        "responseMappingTemplate": {},
        "functionVersion": {}
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "fieldLogLevel",
        "cloudWatchLogsRoleArn"
      ],
      "members": {
        "fieldLogLevel": {},
        "cloudWatchLogsRoleArn": {},
        "excludeVerboseContent": {
          "type": "boolean"
        }
      }
    },
    "Su": {
      "type": "structure",
      "required": [
        "userPoolId",
        "awsRegion",
        "defaultAction"
      ],
      "members": {
        "userPoolId": {},
        "awsRegion": {},
        "defaultAction": {},
        "appIdClientRegex": {}
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "issuer"
      ],
      "members": {
        "issuer": {},
        "clientId": {},
        "iatTTL": {
          "type": "long"
        },
        "authTTL": {
          "type": "long"
        }
      }
    },
    "Sx": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S10": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "authenticationType": {},
          "openIDConnectConfig": {
            "shape": "Sw"
          },
          "userPoolConfig": {
            "type": "structure",
            "required": [
              "userPoolId",
              "awsRegion"
            ],
            "members": {
              "userPoolId": {},
              "awsRegion": {},
              "appIdClientRegex": {}
            }
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "name": {},
        "apiId": {},
        "authenticationType": {},
        "logConfig": {
          "shape": "Sr"
        },
        "userPoolConfig": {
          "shape": "Su"
        },
        "openIDConnectConfig": {
          "shape": "Sw"
        },
        "arn": {},
        "uris": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "tags": {
          "shape": "Sx"
        },
        "additionalAuthenticationProviders": {
          "shape": "S10"
        }
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "functions": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "typeName": {},
        "fieldName": {},
        "dataSourceName": {},
        "resolverArn": {},
        "requestMappingTemplate": {},
        "responseMappingTemplate": {},
        "kind": {},
        "pipelineConfig": {
          "shape": "S18"
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "name": {},
        "description": {},
        "arn": {},
        "definition": {},
        "format": {}
      }
    },
    "S2q": {
      "type": "list",
      "member": {
        "shape": "S1b"
      }
    }
  }
}