{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-06-27",
    "endpointPrefix": "rekognition",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Rekognition",
    "serviceId": "Rekognition",
    "signatureVersion": "v4",
    "targetPrefix": "RekognitionService",
    "uid": "rekognition-2016-06-27"
  },
  "operations": {
    "CompareFaces": {
      "input": {
        "type": "structure",
        "required": [
          "SourceImage",
          "TargetImage"
        ],
        "members": {
          "SourceImage": {
            "shape": "S2"
          },
          "TargetImage": {
            "shape": "S2"
          },
          "SimilarityThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SourceImageFace": {
            "type": "structure",
            "members": {
              "BoundingBox": {
                "shape": "Sb"
              },
              "Confidence": {
                "type": "float"
              }
            }
          },
          "FaceMatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Similarity": {
                  "type": "float"
                },
                "Face": {
                  "shape": "Sf"
                }
              }
            }
          },
          "UnmatchedFaces": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          },
          "SourceImageOrientationCorrection": {},
          "TargetImageOrientationCorrection": {}
        }
      }
    },
    "CreateCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "type": "integer"
          },
          "CollectionArn": {},
          "FaceModelVersion": {}
        }
      }
    },
    "CreateStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Input",
          "Output",
          "Name",
          "Settings",
          "RoleArn"
        ],
        "members": {
          "Input": {
            "shape": "Su"
          },
          "Output": {
            "shape": "Sx"
          },
          "Name": {},
          "Settings": {
            "shape": "S11"
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamProcessorArn": {}
        }
      }
    },
    "DeleteCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "type": "integer"
          }
        }
      }
    },
    "DeleteFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "FaceIds"
        ],
        "members": {
          "CollectionId": {},
          "FaceIds": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeletedFaces": {
            "shape": "S19"
          }
        }
      }
    },
    "DeleteStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCollection": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceCount": {
            "type": "long"
          },
          "FaceModelVersion": {},
          "CollectionARN": {},
          "CreationTimestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "StreamProcessorArn": {},
          "Status": {},
          "StatusMessage": {},
          "CreationTimestamp": {
            "type": "timestamp"
          },
          "LastUpdateTimestamp": {
            "type": "timestamp"
          },
          "Input": {
            "shape": "Su"
          },
          "Output": {
            "shape": "Sx"
          },
          "RoleArn": {},
          "Settings": {
            "shape": "S11"
          }
        }
      }
    },
    "DetectFaces": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "Attributes": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceDetails": {
            "type": "list",
            "member": {
              "shape": "S1q"
            }
          },
          "OrientationCorrection": {}
        }
      }
    },
    "DetectLabels": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "MaxLabels": {
            "type": "integer"
          },
          "MinConfidence": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Labels": {
            "type": "list",
            "member": {
              "shape": "S28"
            }
          },
          "OrientationCorrection": {},
          "LabelModelVersion": {}
        }
      }
    },
    "DetectModerationLabels": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          },
          "MinConfidence": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ModerationLabels": {
            "type": "list",
            "member": {
              "shape": "S2g"
            }
          },
          "ModerationModelVersion": {}
        }
      }
    },
    "DetectText": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TextDetections": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DetectedText": {},
                "Type": {},
                "Id": {
                  "type": "integer"
                },
                "ParentId": {
                  "type": "integer"
                },
                "Confidence": {
                  "type": "float"
                },
                "Geometry": {
                  "type": "structure",
                  "members": {
                    "BoundingBox": {
                      "shape": "Sb"
                    },
                    "Polygon": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "X": {
                            "type": "float"
                          },
                          "Y": {
                            "type": "float"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetCelebrityInfo": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Urls": {
            "shape": "S2s"
          },
          "Name": {}
        }
      }
    },
    "GetCelebrityRecognition": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S32"
          },
          "NextToken": {},
          "Celebrities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Celebrity": {
                  "type": "structure",
                  "members": {
                    "Urls": {
                      "shape": "S2s"
                    },
                    "Name": {},
                    "Id": {},
                    "Confidence": {
                      "type": "float"
                    },
                    "BoundingBox": {
                      "shape": "Sb"
                    },
                    "Face": {
                      "shape": "S1q"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetContentModeration": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S32"
          },
          "ModerationLabels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "ModerationLabel": {
                  "shape": "S2g"
                }
              }
            }
          },
          "NextToken": {},
          "ModerationModelVersion": {}
        }
      }
    },
    "GetFaceDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S32"
          },
          "NextToken": {},
          "Faces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Face": {
                  "shape": "S1q"
                }
              }
            }
          }
        }
      }
    },
    "GetFaceSearch": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "NextToken": {},
          "VideoMetadata": {
            "shape": "S32"
          },
          "Persons": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Person": {
                  "shape": "S3l"
                },
                "FaceMatches": {
                  "shape": "S3n"
                }
              }
            }
          }
        }
      }
    },
    "GetLabelDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S32"
          },
          "NextToken": {},
          "Labels": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Label": {
                  "shape": "S28"
                }
              }
            }
          },
          "LabelModelVersion": {}
        }
      }
    },
    "GetPersonTracking": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "SortBy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobStatus": {},
          "StatusMessage": {},
          "VideoMetadata": {
            "shape": "S32"
          },
          "NextToken": {},
          "Persons": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "long"
                },
                "Person": {
                  "shape": "S3l"
                }
              }
            }
          }
        }
      }
    },
    "IndexFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "Image"
        ],
        "members": {
          "CollectionId": {},
          "Image": {
            "shape": "S2"
          },
          "ExternalImageId": {},
          "DetectionAttributes": {
            "shape": "S1m"
          },
          "MaxFaces": {
            "type": "integer"
          },
          "QualityFilter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FaceRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Face": {
                  "shape": "S3p"
                },
                "FaceDetail": {
                  "shape": "S1q"
                }
              }
            }
          },
          "OrientationCorrection": {},
          "FaceModelVersion": {},
          "UnindexedFaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Reasons": {
                  "type": "list",
                  "member": {}
                },
                "FaceDetail": {
                  "shape": "S1q"
                }
              }
            }
          }
        }
      }
    },
    "ListCollections": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CollectionIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "FaceModelVersions": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId"
        ],
        "members": {
          "CollectionId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Faces": {
            "type": "list",
            "member": {
              "shape": "S3p"
            }
          },
          "NextToken": {},
          "FaceModelVersion": {}
        }
      }
    },
    "ListStreamProcessors": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StreamProcessors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Status": {}
              }
            }
          }
        }
      }
    },
    "RecognizeCelebrities": {
      "input": {
        "type": "structure",
        "required": [
          "Image"
        ],
        "members": {
          "Image": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CelebrityFaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Urls": {
                  "shape": "S2s"
                },
                "Name": {},
                "Id": {},
                "Face": {
                  "shape": "Sf"
                },
                "MatchConfidence": {
                  "type": "float"
                }
              }
            }
          },
          "UnrecognizedFaces": {
            "type": "list",
            "member": {
              "shape": "Sf"
            }
          },
          "OrientationCorrection": {}
        }
      }
    },
    "SearchFaces": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "FaceId"
        ],
        "members": {
          "CollectionId": {},
          "FaceId": {},
          "MaxFaces": {
            "type": "integer"
          },
          "FaceMatchThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SearchedFaceId": {},
          "FaceMatches": {
            "shape": "S3n"
          },
          "FaceModelVersion": {}
        }
      }
    },
    "SearchFacesByImage": {
      "input": {
        "type": "structure",
        "required": [
          "CollectionId",
          "Image"
        ],
        "members": {
          "CollectionId": {},
          "Image": {
            "shape": "S2"
          },
          "MaxFaces": {
            "type": "integer"
          },
          "FaceMatchThreshold": {
            "type": "float"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SearchedFaceBoundingBox": {
            "shape": "Sb"
          },
          "SearchedFaceConfidence": {
            "type": "float"
          },
          "FaceMatches": {
            "shape": "S3n"
          },
          "FaceModelVersion": {}
        }
      }
    },
    "StartCelebrityRecognition": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S4z"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S51"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartContentModeration": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S4z"
          },
          "MinConfidence": {
            "type": "float"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S51"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartFaceDetection": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S4z"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S51"
          },
          "FaceAttributes": {},
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartFaceSearch": {
      "input": {
        "type": "structure",
        "required": [
          "Video",
          "CollectionId"
        ],
        "members": {
          "Video": {
            "shape": "S4z"
          },
          "ClientRequestToken": {},
          "FaceMatchThreshold": {
            "type": "float"
          },
          "CollectionId": {},
          "NotificationChannel": {
            "shape": "S51"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartLabelDetection": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S4z"
          },
          "ClientRequestToken": {},
          "MinConfidence": {
            "type": "float"
          },
          "NotificationChannel": {
            "shape": "S51"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartPersonTracking": {
      "input": {
        "type": "structure",
        "required": [
          "Video"
        ],
        "members": {
          "Video": {
            "shape": "S4z"
          },
          "ClientRequestToken": {},
          "NotificationChannel": {
            "shape": "S51"
          },
          "JobTag": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      },
      "idempotent": true
    },
    "StartStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopStreamProcessor": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "Bytes": {
          "type": "blob"
        },
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S4": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Name": {},
        "Version": {}
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "Width": {
          "type": "float"
        },
        "Height": {
          "type": "float"
        },
        "Left": {
          "type": "float"
        },
        "Top": {
          "type": "float"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "shape": "Sb"
        },
        "Confidence": {
          "type": "float"
        },
        "Landmarks": {
          "shape": "Sg"
        },
        "Pose": {
          "shape": "Sj"
        },
        "Quality": {
          "shape": "Sl"
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "X": {
            "type": "float"
          },
          "Y": {
            "type": "float"
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "Roll": {
          "type": "float"
        },
        "Yaw": {
          "type": "float"
        },
        "Pitch": {
          "type": "float"
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "Brightness": {
          "type": "float"
        },
        "Sharpness": {
          "type": "float"
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "KinesisVideoStream": {
          "type": "structure",
          "members": {
            "Arn": {}
          }
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "KinesisDataStream": {
          "type": "structure",
          "members": {
            "Arn": {}
          }
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "FaceSearch": {
          "type": "structure",
          "members": {
            "CollectionId": {},
            "FaceMatchThreshold": {
              "type": "float"
            }
          }
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {}
    },
    "S1m": {
      "type": "list",
      "member": {}
    },
    "S1q": {
      "type": "structure",
      "members": {
        "BoundingBox": {
          "shape": "Sb"
        },
        "AgeRange": {
          "type": "structure",
          "members": {
            "Low": {
              "type": "integer"
            },
            "High": {
              "type": "integer"
            }
          }
        },
        "Smile": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Eyeglasses": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Sunglasses": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Gender": {
          "type": "structure",
          "members": {
            "Value": {},
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Beard": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Mustache": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "EyesOpen": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "MouthOpen": {
          "type": "structure",
          "members": {
            "Value": {
              "type": "boolean"
            },
            "Confidence": {
              "type": "float"
            }
          }
        },
        "Emotions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Type": {},
              "Confidence": {
                "type": "float"
              }
            }
          }
        },
        "Landmarks": {
          "shape": "Sg"
        },
        "Pose": {
          "shape": "Sj"
        },
        "Quality": {
          "shape": "Sl"
        },
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "Name": {},
        "Confidence": {
          "type": "float"
        },
        "Instances": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "BoundingBox": {
                "shape": "Sb"
              },
              "Confidence": {
                "type": "float"
              }
            }
          }
        },
        "Parents": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {}
            }
          }
        }
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "Confidence": {
          "type": "float"
        },
        "Name": {},
        "ParentName": {}
      }
    },
    "S2s": {
      "type": "list",
      "member": {}
    },
    "S32": {
      "type": "structure",
      "members": {
        "Codec": {},
        "DurationMillis": {
          "type": "long"
        },
        "Format": {},
        "FrameRate": {
          "type": "float"
        },
        "FrameHeight": {
          "type": "long"
        },
        "FrameWidth": {
          "type": "long"
        }
      }
    },
    "S3l": {
      "type": "structure",
      "members": {
        "Index": {
          "type": "long"
        },
        "BoundingBox": {
          "shape": "Sb"
        },
        "Face": {
          "shape": "S1q"
        }
      }
    },
    "S3n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Similarity": {
            "type": "float"
          },
          "Face": {
            "shape": "S3p"
          }
        }
      }
    },
    "S3p": {
      "type": "structure",
      "members": {
        "FaceId": {},
        "BoundingBox": {
          "shape": "Sb"
        },
        "ImageId": {},
        "ExternalImageId": {},
        "Confidence": {
          "type": "float"
        }
      }
    },
    "S4z": {
      "type": "structure",
      "members": {
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S51": {
      "type": "structure",
      "required": [
        "SNSTopicArn",
        "RoleArn"
      ],
      "members": {
        "SNSTopicArn": {},
        "RoleArn": {}
      }
    }
  }
}