{
  "pagination": {
    "DescribeDBClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBClusters"
    },
    "DescribeDBEngineVersions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBEngineVersions"
    },
    "DescribeDBInstanceAutomatedBackups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstanceAutomatedBackups"
    },
    "DescribeDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBInstances"
    },
    "DescribeDBLogFiles": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DescribeDBLogFiles"
    },
    "DescribeDBParameterGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBParameterGroups"
    },
    "DescribeDBParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Parameters"
    },
    "DescribeDBSecurityGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSecurityGroups"
    },
    "DescribeDBSnapshots": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSnapshots"
    },
    "DescribeDBSubnetGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "DBSubnetGroups"
    },
    "DescribeEngineDefaultParameters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "EngineDefaults.Marker",
      "result_key": "EngineDefaults.Parameters"
    },
    "DescribeEventSubscriptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "EventSubscriptionsList"
    },
    "DescribeEvents": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "Events"
    },
    "DescribeGlobalClusters": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "GlobalClusters"
    },
    "DescribeOptionGroupOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupOptions"
    },
    "DescribeOptionGroups": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OptionGroupsList"
    },
    "DescribeOrderableDBInstanceOptions": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "OrderableDBInstanceOptions"
    },
    "DescribeReservedDBInstances": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstances"
    },
    "DescribeReservedDBInstancesOfferings": {
      "input_token": "Marker",
      "limit_key": "MaxRecords",
      "output_token": "Marker",
      "result_key": "ReservedDBInstancesOfferings"
    },
    "DownloadDBLogFilePortion": {
      "input_token": "Marker",
      "limit_key": "NumberOfLines",
      "more_results": "AdditionalDataPending",
      "output_token": "Marker",
      "result_key": "LogFileData"
    },
    "ListTagsForResource": {
      "result_key": "TagList"
    }
  }
}