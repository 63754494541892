"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFormState = void 0;
const react_1 = require("react");
const useFormState = (initialState = '') => {
    const [state, setState] = (0, react_1.useState)(initialState);
    const setFormState = (e) => setState(e.target.value);
    return [state, setFormState];
};
exports.useFormState = useFormState;
