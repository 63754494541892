{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2012-10-25",
    "endpointPrefix": "directconnect",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Direct Connect",
    "serviceId": "Direct Connect",
    "signatureVersion": "v4",
    "targetPrefix": "OvertureService",
    "uid": "directconnect-2012-10-25"
  },
  "operations": {
    "AcceptDirectConnectGatewayAssociationProposal": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId",
          "proposalId",
          "associatedGatewayOwnerAccount"
        ],
        "members": {
          "directConnectGatewayId": {},
          "proposalId": {},
          "associatedGatewayOwnerAccount": {},
          "overrideAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "AllocateConnectionOnInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "bandwidth",
          "connectionName",
          "ownerAccount",
          "interconnectId",
          "vlan"
        ],
        "members": {
          "bandwidth": {},
          "connectionName": {},
          "ownerAccount": {},
          "interconnectId": {},
          "vlan": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "So"
      },
      "deprecated": true
    },
    "AllocateHostedConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "bandwidth",
          "connectionName",
          "vlan"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "bandwidth": {},
          "connectionName": {},
          "vlan": {
            "type": "integer"
          },
          "tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "AllocatePrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newPrivateVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newPrivateVirtualInterfaceAllocation": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "addressFamily": {},
              "customerAddress": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1d"
      }
    },
    "AllocatePublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newPublicVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newPublicVirtualInterfaceAllocation": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "routeFilterPrefixes": {
                "shape": "S5"
              },
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1d"
      }
    },
    "AllocateTransitVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "ownerAccount",
          "newTransitVirtualInterfaceAllocation"
        ],
        "members": {
          "connectionId": {},
          "ownerAccount": {},
          "newTransitVirtualInterfaceAllocation": {
            "type": "structure",
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1d"
          }
        }
      }
    },
    "AssociateConnectionWithLag": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "lagId"
        ],
        "members": {
          "connectionId": {},
          "lagId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "AssociateHostedConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "parentConnectionId"
        ],
        "members": {
          "connectionId": {},
          "parentConnectionId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "AssociateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId",
          "connectionId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S1d"
      }
    },
    "ConfirmConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "connectionState": {}
        }
      }
    },
    "ConfirmPrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "virtualGatewayId": {
            "shape": "Sh"
          },
          "directConnectGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "ConfirmPublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "ConfirmTransitVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId",
          "directConnectGatewayId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "directConnectGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "CreateBGPPeer": {
      "input": {
        "type": "structure",
        "members": {
          "virtualInterfaceId": {},
          "newBGPPeer": {
            "type": "structure",
            "members": {
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "addressFamily": {},
              "amazonAddress": {},
              "customerAddress": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1d"
          }
        }
      }
    },
    "CreateConnection": {
      "input": {
        "type": "structure",
        "required": [
          "location",
          "bandwidth",
          "connectionName"
        ],
        "members": {
          "location": {},
          "bandwidth": {},
          "connectionName": {},
          "lagId": {},
          "tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "CreateDirectConnectGateway": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayName"
        ],
        "members": {
          "directConnectGatewayName": {},
          "amazonSideAsn": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGateway": {
            "shape": "S2b"
          }
        }
      }
    },
    "CreateDirectConnectGatewayAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId"
        ],
        "members": {
          "directConnectGatewayId": {},
          "gatewayId": {},
          "addAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          },
          "virtualGatewayId": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "CreateDirectConnectGatewayAssociationProposal": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId",
          "directConnectGatewayOwnerAccount",
          "gatewayId"
        ],
        "members": {
          "directConnectGatewayId": {},
          "directConnectGatewayOwnerAccount": {},
          "gatewayId": {},
          "addAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          },
          "removeAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociationProposal": {
            "shape": "S2i"
          }
        }
      }
    },
    "CreateInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectName",
          "bandwidth",
          "location"
        ],
        "members": {
          "interconnectName": {},
          "bandwidth": {},
          "location": {},
          "lagId": {},
          "tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "shape": "S2m"
      }
    },
    "CreateLag": {
      "input": {
        "type": "structure",
        "required": [
          "numberOfConnections",
          "location",
          "connectionsBandwidth",
          "lagName"
        ],
        "members": {
          "numberOfConnections": {
            "type": "integer"
          },
          "location": {},
          "connectionsBandwidth": {},
          "lagName": {},
          "connectionId": {},
          "tags": {
            "shape": "Sz"
          },
          "childConnectionTags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "shape": "S2r"
      }
    },
    "CreatePrivateVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newPrivateVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newPrivateVirtualInterface": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "virtualGatewayId": {
                "shape": "Sh"
              },
              "directConnectGatewayId": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1d"
      }
    },
    "CreatePublicVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newPublicVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newPublicVirtualInterface": {
            "type": "structure",
            "required": [
              "virtualInterfaceName",
              "vlan",
              "asn"
            ],
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "routeFilterPrefixes": {
                "shape": "S5"
              },
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "shape": "S1d"
      }
    },
    "CreateTransitVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "newTransitVirtualInterface"
        ],
        "members": {
          "connectionId": {},
          "newTransitVirtualInterface": {
            "type": "structure",
            "members": {
              "virtualInterfaceName": {},
              "vlan": {
                "type": "integer"
              },
              "asn": {
                "type": "integer"
              },
              "mtu": {
                "type": "integer"
              },
              "authKey": {},
              "amazonAddress": {},
              "customerAddress": {},
              "addressFamily": {},
              "directConnectGatewayId": {},
              "tags": {
                "shape": "Sz"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1d"
          }
        }
      }
    },
    "DeleteBGPPeer": {
      "input": {
        "type": "structure",
        "members": {
          "virtualInterfaceId": {},
          "asn": {
            "type": "integer"
          },
          "customerAddress": {},
          "bgpPeerId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterface": {
            "shape": "S1d"
          }
        }
      }
    },
    "DeleteConnection": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "DeleteDirectConnectGateway": {
      "input": {
        "type": "structure",
        "required": [
          "directConnectGatewayId"
        ],
        "members": {
          "directConnectGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGateway": {
            "shape": "S2b"
          }
        }
      }
    },
    "DeleteDirectConnectGatewayAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "associationId": {},
          "directConnectGatewayId": {},
          "virtualGatewayId": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "DeleteDirectConnectGatewayAssociationProposal": {
      "input": {
        "type": "structure",
        "required": [
          "proposalId"
        ],
        "members": {
          "proposalId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociationProposal": {
            "shape": "S2i"
          }
        }
      }
    },
    "DeleteInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "interconnectState": {}
        }
      }
    },
    "DeleteLag": {
      "input": {
        "type": "structure",
        "required": [
          "lagId"
        ],
        "members": {
          "lagId": {}
        }
      },
      "output": {
        "shape": "S2r"
      }
    },
    "DeleteVirtualInterface": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaceState": {}
        }
      }
    },
    "DescribeConnectionLoa": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loa": {
            "shape": "S3k"
          }
        }
      },
      "deprecated": true
    },
    "DescribeConnections": {
      "input": {
        "type": "structure",
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S3n"
      }
    },
    "DescribeConnectionsOnInterconnect": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "shape": "S3n"
      },
      "deprecated": true
    },
    "DescribeDirectConnectGatewayAssociationProposals": {
      "input": {
        "type": "structure",
        "members": {
          "directConnectGatewayId": {},
          "proposalId": {},
          "associatedGatewayId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociationProposals": {
            "type": "list",
            "member": {
              "shape": "S2i"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeDirectConnectGatewayAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "associationId": {},
          "associatedGatewayId": {},
          "directConnectGatewayId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "virtualGatewayId": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociations": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeDirectConnectGatewayAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "directConnectGatewayId": {},
          "virtualInterfaceId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAttachments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "directConnectGatewayId": {},
                "virtualInterfaceId": {},
                "virtualInterfaceRegion": {},
                "virtualInterfaceOwnerAccount": {},
                "attachmentState": {},
                "attachmentType": {},
                "stateChangeError": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeDirectConnectGateways": {
      "input": {
        "type": "structure",
        "members": {
          "directConnectGatewayId": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGateways": {
            "type": "list",
            "member": {
              "shape": "S2b"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeHostedConnections": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {}
        }
      },
      "output": {
        "shape": "S3n"
      }
    },
    "DescribeInterconnectLoa": {
      "input": {
        "type": "structure",
        "required": [
          "interconnectId"
        ],
        "members": {
          "interconnectId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "loa": {
            "shape": "S3k"
          }
        }
      },
      "deprecated": true
    },
    "DescribeInterconnects": {
      "input": {
        "type": "structure",
        "members": {
          "interconnectId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "interconnects": {
            "type": "list",
            "member": {
              "shape": "S2m"
            }
          }
        }
      }
    },
    "DescribeLags": {
      "input": {
        "type": "structure",
        "members": {
          "lagId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "lags": {
            "type": "list",
            "member": {
              "shape": "S2r"
            }
          }
        }
      }
    },
    "DescribeLoa": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId"
        ],
        "members": {
          "connectionId": {},
          "providerName": {},
          "loaContentType": {}
        }
      },
      "output": {
        "shape": "S3k"
      }
    },
    "DescribeLocations": {
      "output": {
        "type": "structure",
        "members": {
          "locations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "locationCode": {},
                "locationName": {},
                "region": {},
                "availablePortSpeeds": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArns"
        ],
        "members": {
          "resourceArns": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceTags": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceArn": {},
                "tags": {
                  "shape": "Sz"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVirtualGateways": {
      "output": {
        "type": "structure",
        "members": {
          "virtualGateways": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "virtualGatewayId": {
                  "shape": "Sh"
                },
                "virtualGatewayState": {}
              }
            }
          }
        }
      }
    },
    "DescribeVirtualInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "connectionId": {},
          "virtualInterfaceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "virtualInterfaces": {
            "type": "list",
            "member": {
              "shape": "S1d"
            }
          }
        }
      }
    },
    "DisassociateConnectionFromLag": {
      "input": {
        "type": "structure",
        "required": [
          "connectionId",
          "lagId"
        ],
        "members": {
          "connectionId": {},
          "lagId": {}
        }
      },
      "output": {
        "shape": "So"
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDirectConnectGatewayAssociation": {
      "input": {
        "type": "structure",
        "members": {
          "associationId": {},
          "addAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          },
          "removeAllowedPrefixesToDirectConnectGateway": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "directConnectGatewayAssociation": {
            "shape": "S9"
          }
        }
      }
    },
    "UpdateLag": {
      "input": {
        "type": "structure",
        "required": [
          "lagId"
        ],
        "members": {
          "lagId": {},
          "lagName": {},
          "minimumLinks": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S2r"
      }
    },
    "UpdateVirtualInterfaceAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "virtualInterfaceId"
        ],
        "members": {
          "virtualInterfaceId": {},
          "mtu": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S1d"
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "cidr": {}
        }
      }
    },
    "S9": {
      "type": "structure",
      "members": {
        "directConnectGatewayId": {},
        "directConnectGatewayOwnerAccount": {},
        "associationState": {},
        "stateChangeError": {},
        "associatedGateway": {
          "shape": "Sc"
        },
        "associationId": {},
        "allowedPrefixesToDirectConnectGateway": {
          "shape": "S5"
        },
        "virtualGatewayId": {
          "shape": "Sh"
        },
        "virtualGatewayRegion": {
          "type": "string",
          "deprecated": true
        },
        "virtualGatewayOwnerAccount": {}
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "id": {},
        "type": {},
        "ownerAccount": {},
        "region": {}
      }
    },
    "Sh": {
      "type": "string",
      "deprecated": true
    },
    "So": {
      "type": "structure",
      "members": {
        "ownerAccount": {},
        "connectionId": {},
        "connectionName": {},
        "connectionState": {},
        "region": {},
        "location": {},
        "bandwidth": {},
        "vlan": {
          "type": "integer"
        },
        "partnerName": {},
        "loaIssueTime": {
          "type": "timestamp"
        },
        "lagId": {},
        "awsDevice": {
          "shape": "Sv"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "awsDeviceV2": {},
        "hasLogicalRedundancy": {},
        "tags": {
          "shape": "Sz"
        }
      }
    },
    "Sv": {
      "type": "string",
      "deprecated": true
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "ownerAccount": {},
        "virtualInterfaceId": {},
        "location": {},
        "connectionId": {},
        "virtualInterfaceType": {},
        "virtualInterfaceName": {},
        "vlan": {
          "type": "integer"
        },
        "asn": {
          "type": "integer"
        },
        "amazonSideAsn": {
          "type": "long"
        },
        "authKey": {},
        "amazonAddress": {},
        "customerAddress": {},
        "addressFamily": {},
        "virtualInterfaceState": {},
        "customerRouterConfig": {},
        "mtu": {
          "type": "integer"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "virtualGatewayId": {
          "shape": "Sh"
        },
        "directConnectGatewayId": {},
        "routeFilterPrefixes": {
          "shape": "S5"
        },
        "bgpPeers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "bgpPeerId": {},
              "asn": {
                "type": "integer"
              },
              "authKey": {},
              "addressFamily": {},
              "amazonAddress": {},
              "customerAddress": {},
              "bgpPeerState": {},
              "bgpStatus": {},
              "awsDeviceV2": {}
            }
          }
        },
        "region": {},
        "awsDeviceV2": {},
        "tags": {
          "shape": "Sz"
        }
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "directConnectGatewayId": {},
        "directConnectGatewayName": {},
        "amazonSideAsn": {
          "type": "long"
        },
        "ownerAccount": {},
        "directConnectGatewayState": {},
        "stateChangeError": {}
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "proposalId": {},
        "directConnectGatewayId": {},
        "directConnectGatewayOwnerAccount": {},
        "proposalState": {},
        "associatedGateway": {
          "shape": "Sc"
        },
        "existingAllowedPrefixesToDirectConnectGateway": {
          "shape": "S5"
        },
        "requestedAllowedPrefixesToDirectConnectGateway": {
          "shape": "S5"
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "interconnectId": {},
        "interconnectName": {},
        "interconnectState": {},
        "region": {},
        "location": {},
        "bandwidth": {},
        "loaIssueTime": {
          "type": "timestamp"
        },
        "lagId": {},
        "awsDevice": {
          "shape": "Sv"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "awsDeviceV2": {},
        "hasLogicalRedundancy": {},
        "tags": {
          "shape": "Sz"
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "connectionsBandwidth": {},
        "numberOfConnections": {
          "type": "integer"
        },
        "lagId": {},
        "ownerAccount": {},
        "lagName": {},
        "lagState": {},
        "location": {},
        "region": {},
        "minimumLinks": {
          "type": "integer"
        },
        "awsDevice": {
          "shape": "Sv"
        },
        "awsDeviceV2": {},
        "connections": {
          "shape": "S2t"
        },
        "allowsHostedConnections": {
          "type": "boolean"
        },
        "jumboFrameCapable": {
          "type": "boolean"
        },
        "hasLogicalRedundancy": {},
        "tags": {
          "shape": "Sz"
        }
      }
    },
    "S2t": {
      "type": "list",
      "member": {
        "shape": "So"
      }
    },
    "S3k": {
      "type": "structure",
      "members": {
        "loaContent": {
          "type": "blob"
        },
        "loaContentType": {}
      }
    },
    "S3n": {
      "type": "structure",
      "members": {
        "connections": {
          "shape": "S2t"
        }
      }
    }
  }
}