{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-10-07",
    "endpointPrefix": "events",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon CloudWatch Events",
    "serviceId": "CloudWatch Events",
    "signatureVersion": "v4",
    "targetPrefix": "AWSEvents",
    "uid": "events-2015-10-07"
  },
  "operations": {
    "ActivateEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "CreateEventBus": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventSourceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventBusArn": {}
        }
      }
    },
    "CreatePartnerEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Account"
        ],
        "members": {
          "Name": {},
          "Account": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSourceArn": {}
        }
      }
    },
    "DeactivateEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeleteEventBus": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      }
    },
    "DeletePartnerEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Account"
        ],
        "members": {
          "Name": {},
          "Account": {}
        }
      }
    },
    "DeleteRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {},
          "Force": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeEventBus": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Arn": {},
          "Policy": {}
        }
      }
    },
    "DescribeEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "CreatedBy": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "ExpirationTime": {
            "type": "timestamp"
          },
          "Name": {},
          "State": {}
        }
      }
    },
    "DescribePartnerEventSource": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Name": {}
        }
      }
    },
    "DescribeRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "Arn": {},
          "EventPattern": {},
          "ScheduleExpression": {},
          "State": {},
          "Description": {},
          "RoleArn": {},
          "ManagedBy": {},
          "EventBusName": {}
        }
      }
    },
    "DisableRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {}
        }
      }
    },
    "EnableRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "EventBusName": {}
        }
      }
    },
    "ListEventBuses": {
      "input": {
        "type": "structure",
        "members": {
          "NamePrefix": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventBuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "Policy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEventSources": {
      "input": {
        "type": "structure",
        "members": {
          "NamePrefix": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "CreatedBy": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ExpirationTime": {
                  "type": "timestamp"
                },
                "Name": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPartnerEventSourceAccounts": {
      "input": {
        "type": "structure",
        "required": [
          "EventSourceName"
        ],
        "members": {
          "EventSourceName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PartnerEventSourceAccounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Account": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ExpirationTime": {
                  "type": "timestamp"
                },
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPartnerEventSources": {
      "input": {
        "type": "structure",
        "required": [
          "NamePrefix"
        ],
        "members": {
          "NamePrefix": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PartnerEventSources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListRuleNamesByTarget": {
      "input": {
        "type": "structure",
        "required": [
          "TargetArn"
        ],
        "members": {
          "TargetArn": {},
          "EventBusName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListRules": {
      "input": {
        "type": "structure",
        "members": {
          "NamePrefix": {},
          "EventBusName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "EventPattern": {},
                "State": {},
                "Description": {},
                "ScheduleExpression": {},
                "RoleArn": {},
                "ManagedBy": {},
                "EventBusName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S1u"
          }
        }
      }
    },
    "ListTargetsByRule": {
      "input": {
        "type": "structure",
        "required": [
          "Rule"
        ],
        "members": {
          "Rule": {},
          "EventBusName": {},
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Targets": {
            "shape": "S20"
          },
          "NextToken": {}
        }
      }
    },
    "PutEvents": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Time": {
                  "type": "timestamp"
                },
                "Source": {},
                "Resources": {
                  "shape": "S2y"
                },
                "DetailType": {},
                "Detail": {},
                "EventBusName": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "PutPartnerEvents": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Time": {
                  "type": "timestamp"
                },
                "Source": {},
                "Resources": {
                  "shape": "S2y"
                },
                "DetailType": {},
                "Detail": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "Entries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "PutPermission": {
      "input": {
        "type": "structure",
        "required": [
          "Action",
          "Principal",
          "StatementId"
        ],
        "members": {
          "EventBusName": {},
          "Action": {},
          "Principal": {},
          "StatementId": {},
          "Condition": {
            "type": "structure",
            "required": [
              "Type",
              "Key",
              "Value"
            ],
            "members": {
              "Type": {},
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "PutRule": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "ScheduleExpression": {},
          "EventPattern": {},
          "State": {},
          "Description": {},
          "RoleArn": {},
          "Tags": {
            "shape": "S1u"
          },
          "EventBusName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuleArn": {}
        }
      }
    },
    "PutTargets": {
      "input": {
        "type": "structure",
        "required": [
          "Rule",
          "Targets"
        ],
        "members": {
          "Rule": {},
          "EventBusName": {},
          "Targets": {
            "shape": "S20"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "FailedEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "RemovePermission": {
      "input": {
        "type": "structure",
        "required": [
          "StatementId"
        ],
        "members": {
          "StatementId": {},
          "EventBusName": {}
        }
      }
    },
    "RemoveTargets": {
      "input": {
        "type": "structure",
        "required": [
          "Rule",
          "Ids"
        ],
        "members": {
          "Rule": {},
          "EventBusName": {},
          "Ids": {
            "type": "list",
            "member": {}
          },
          "Force": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEntryCount": {
            "type": "integer"
          },
          "FailedEntries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S1u"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TestEventPattern": {
      "input": {
        "type": "structure",
        "required": [
          "EventPattern",
          "Event"
        ],
        "members": {
          "EventPattern": {},
          "Event": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Result": {
            "type": "boolean"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S1u": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Id",
          "Arn"
        ],
        "members": {
          "Id": {},
          "Arn": {},
          "RoleArn": {},
          "Input": {},
          "InputPath": {},
          "InputTransformer": {
            "type": "structure",
            "required": [
              "InputTemplate"
            ],
            "members": {
              "InputPathsMap": {
                "type": "map",
                "key": {},
                "value": {}
              },
              "InputTemplate": {}
            }
          },
          "KinesisParameters": {
            "type": "structure",
            "required": [
              "PartitionKeyPath"
            ],
            "members": {
              "PartitionKeyPath": {}
            }
          },
          "RunCommandParameters": {
            "type": "structure",
            "required": [
              "RunCommandTargets"
            ],
            "members": {
              "RunCommandTargets": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "Key",
                    "Values"
                  ],
                  "members": {
                    "Key": {},
                    "Values": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "EcsParameters": {
            "type": "structure",
            "required": [
              "TaskDefinitionArn"
            ],
            "members": {
              "TaskDefinitionArn": {},
              "TaskCount": {
                "type": "integer"
              },
              "LaunchType": {},
              "NetworkConfiguration": {
                "type": "structure",
                "members": {
                  "awsvpcConfiguration": {
                    "type": "structure",
                    "required": [
                      "Subnets"
                    ],
                    "members": {
                      "Subnets": {
                        "shape": "S2m"
                      },
                      "SecurityGroups": {
                        "shape": "S2m"
                      },
                      "AssignPublicIp": {}
                    }
                  }
                }
              },
              "PlatformVersion": {},
              "Group": {}
            }
          },
          "BatchParameters": {
            "type": "structure",
            "required": [
              "JobDefinition",
              "JobName"
            ],
            "members": {
              "JobDefinition": {},
              "JobName": {},
              "ArrayProperties": {
                "type": "structure",
                "members": {
                  "Size": {
                    "type": "integer"
                  }
                }
              },
              "RetryStrategy": {
                "type": "structure",
                "members": {
                  "Attempts": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "SqsParameters": {
            "type": "structure",
            "members": {
              "MessageGroupId": {}
            }
          }
        }
      }
    },
    "S2m": {
      "type": "list",
      "member": {}
    },
    "S2y": {
      "type": "list",
      "member": {}
    }
  }
}