{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-02-06",
    "endpointPrefix": "autoscaling",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Application Auto Scaling",
    "serviceId": "Application Auto Scaling",
    "signatureVersion": "v4",
    "signingName": "application-autoscaling",
    "targetPrefix": "AnyScaleFrontendService",
    "uid": "application-autoscaling-2016-02-06"
  },
  "operations": {
    "DeleteScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName",
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "PolicyName": {},
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ScheduledActionName",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ScheduledActionName": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterScalableTarget": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeScalableTargets": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceIds": {
            "shape": "Sb"
          },
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalableTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "MinCapacity",
                "MaxCapacity",
                "RoleARN",
                "CreationTime"
              ],
              "members": {
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "MinCapacity": {
                  "type": "integer"
                },
                "MaxCapacity": {
                  "type": "integer"
                },
                "RoleARN": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingActivities": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingActivities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ActivityId",
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "Description",
                "Cause",
                "StartTime",
                "StatusCode"
              ],
              "members": {
                "ActivityId": {},
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "Description": {},
                "Cause": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "StatusCode": {},
                "StatusMessage": {},
                "Details": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScalingPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "PolicyNames": {
            "shape": "Sb"
          },
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "PolicyARN",
                "PolicyName",
                "ServiceNamespace",
                "ResourceId",
                "ScalableDimension",
                "PolicyType",
                "CreationTime"
              ],
              "members": {
                "PolicyARN": {},
                "PolicyName": {},
                "ServiceNamespace": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "PolicyType": {},
                "StepScalingPolicyConfiguration": {
                  "shape": "Sv"
                },
                "TargetTrackingScalingPolicyConfiguration": {
                  "shape": "S14"
                },
                "Alarms": {
                  "shape": "S1i"
                },
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScheduledActions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace"
        ],
        "members": {
          "ScheduledActionNames": {
            "shape": "Sb"
          },
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduledActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ScheduledActionName",
                "ScheduledActionARN",
                "ServiceNamespace",
                "Schedule",
                "ResourceId",
                "CreationTime"
              ],
              "members": {
                "ScheduledActionName": {},
                "ScheduledActionARN": {},
                "ServiceNamespace": {},
                "Schedule": {},
                "ResourceId": {},
                "ScalableDimension": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "ScalableTargetAction": {
                  "shape": "S1p"
                },
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyName",
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "PolicyName": {},
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "PolicyType": {},
          "StepScalingPolicyConfiguration": {
            "shape": "Sv"
          },
          "TargetTrackingScalingPolicyConfiguration": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "PolicyARN"
        ],
        "members": {
          "PolicyARN": {},
          "Alarms": {
            "shape": "S1i"
          }
        }
      }
    },
    "PutScheduledAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ScheduledActionName",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "Schedule": {},
          "ScheduledActionName": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "ScalableTargetAction": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RegisterScalableTarget": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceNamespace",
          "ResourceId",
          "ScalableDimension"
        ],
        "members": {
          "ServiceNamespace": {},
          "ResourceId": {},
          "ScalableDimension": {},
          "MinCapacity": {
            "type": "integer"
          },
          "MaxCapacity": {
            "type": "integer"
          },
          "RoleARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sv": {
      "type": "structure",
      "members": {
        "AdjustmentType": {},
        "StepAdjustments": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ScalingAdjustment"
            ],
            "members": {
              "MetricIntervalLowerBound": {
                "type": "double"
              },
              "MetricIntervalUpperBound": {
                "type": "double"
              },
              "ScalingAdjustment": {
                "type": "integer"
              }
            }
          }
        },
        "MinAdjustmentMagnitude": {
          "type": "integer"
        },
        "Cooldown": {
          "type": "integer"
        },
        "MetricAggregationType": {}
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "TargetValue"
      ],
      "members": {
        "TargetValue": {
          "type": "double"
        },
        "PredefinedMetricSpecification": {
          "type": "structure",
          "required": [
            "PredefinedMetricType"
          ],
          "members": {
            "PredefinedMetricType": {},
            "ResourceLabel": {}
          }
        },
        "CustomizedMetricSpecification": {
          "type": "structure",
          "required": [
            "MetricName",
            "Namespace",
            "Statistic"
          ],
          "members": {
            "MetricName": {},
            "Namespace": {},
            "Dimensions": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Value"
                ],
                "members": {
                  "Name": {},
                  "Value": {}
                }
              }
            },
            "Statistic": {},
            "Unit": {}
          }
        },
        "ScaleOutCooldown": {
          "type": "integer"
        },
        "ScaleInCooldown": {
          "type": "integer"
        },
        "DisableScaleIn": {
          "type": "boolean"
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AlarmName",
          "AlarmARN"
        ],
        "members": {
          "AlarmName": {},
          "AlarmARN": {}
        }
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "MinCapacity": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "integer"
        }
      }
    }
  }
}