"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useDocumentTitle = exports.usePureDocumentTitle = void 0;
const react_1 = require("react");
function usePureDocumentTitle(title, retainOnUnmount = false) {
    const defaultTitle = (0, react_1.useRef)(document.title);
    (0, react_1.useEffect)(() => {
        document.title = title;
    }, [title]);
    (0, react_1.useEffect)(() => {
        return () => {
            if (!retainOnUnmount) {
                document.title = defaultTitle.current;
            }
        };
    }, []);
}
exports.usePureDocumentTitle = usePureDocumentTitle;
const useDocumentTitle = (value) => {
    let pageTitle = 'Mineko.de';
    if (value) {
        pageTitle = `${value} | ${pageTitle}`;
    }
    usePureDocumentTitle(pageTitle);
};
exports.useDocumentTitle = useDocumentTitle;
