import { useConfig as useConfigHook } from '@mineko/react-toolkit';
import { FederalProviderType } from '../components/Federate';

export type ConfigReturnType = {
  isRegistrationEnabled: boolean;
  isStrongPasswordEnabled: boolean;
  defaultRegistrationRoutes: string[];
  identityProviders: {
    email?: string;
    google?: FederalProviderType;
    googleWorkspace?: FederalProviderType;
  };
  APP_ENV: string;
  amplifyConfig?: {
    region: string;
    userPoolId: string;
    userPoolWebClientId: string;
  };
  gtm?: {
    REACT_APP_GTM_ID: string;
    REACT_APP_GTM_PREVIEW: string;
    REACT_APP_GTM_AUTH: string;
  };
  SENTRY_DSN: string | undefined;
};

export const useConfig = (): ConfigReturnType | null => {
  const config = useConfigHook();

  const {
    COGNITO_REGION,
    COGNITO_USER_POOL_ID,
    COGNITO_USER_POOL_CLIENT_ID,
    ENABLE_REGISTRATION,
    STRONG_PASSWORD_VALIDATION,
    DEFAULT_REGISTRATION_ROUTES,
    IDENTITY_PROVIDERS,
    REACT_APP_GTM_ID,
    REACT_APP_GTM_PREVIEW,
    REACT_APP_GTM_AUTH,
  } = config?.ENV || {};

  const { REACT_APP_ENV, REACT_APP_SENTRY_DSN } = config?.APP || {};

  const amplifyConfig =
    COGNITO_REGION && COGNITO_USER_POOL_ID && COGNITO_USER_POOL_CLIENT_ID
      ? {
          region: COGNITO_REGION,
          userPoolId: COGNITO_USER_POOL_ID,
          userPoolWebClientId: COGNITO_USER_POOL_CLIENT_ID,
        }
      : undefined;

  const gtm =
    REACT_APP_GTM_ID && REACT_APP_GTM_PREVIEW && REACT_APP_GTM_AUTH
      ? {
          REACT_APP_GTM_ID,
          REACT_APP_GTM_PREVIEW,
          REACT_APP_GTM_AUTH,
        }
      : undefined;

  return !!config
    ? {
        isRegistrationEnabled: !!ENABLE_REGISTRATION,
        isStrongPasswordEnabled: !!STRONG_PASSWORD_VALIDATION,
        defaultRegistrationRoutes: DEFAULT_REGISTRATION_ROUTES ?? [],
        identityProviders: {
          email: IDENTITY_PROVIDERS.includes('EMAIL') ? 'Email' : undefined,
          google: IDENTITY_PROVIDERS.includes('GOOGLE') ? 'Google' : undefined,
          googleWorkspace: IDENTITY_PROVIDERS.includes('GOOGLE-WORKSPACE') ? 'Google-Workspace' : undefined,
        },
        amplifyConfig,
        APP_ENV: REACT_APP_ENV,
        SENTRY_DSN: REACT_APP_SENTRY_DSN,
        gtm,
      }
    : null;
};
