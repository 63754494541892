import { jsx as _jsx } from "@emotion/react/jsx-runtime";
import { AppBar, Toolbar, useTheme } from '@mui/material';
import { css } from '@emotion/react';
const toolbarStyles = (theme) => css `
  box-sizing: border-box;
  ${theme.breakpoints.down('md')} {
    padding-left: 13px;
    padding-right: 13px;
  }
`;
export const Header = ({ children, toolbarProps, ...rest }) => {
    const theme = useTheme();
    return (_jsx(AppBar, { position: "sticky", color: "inherit", variant: "outlined", elevation: 0, ...rest, children: _jsx(Toolbar, { css: toolbarStyles(theme), ...toolbarProps, children: children }) }));
};
