{
  "pagination":{
    "ListMemberAccounts":{
      "input_token":"nextToken",
      "output_token":"nextToken",
      "limit_key":"maxResults"
    },
    "ListS3Resources":{
      "input_token":"nextToken",
      "output_token":"nextToken",
      "limit_key":"maxResults"
    }
  }
}
