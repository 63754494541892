{
  "pagination": {
    "ListCampaigns": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "campaigns"
    },
    "ListDatasetGroups": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "datasetGroups"
    },
    "ListDatasetImportJobs": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "datasetImportJobs"
    },
    "ListDatasets": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "datasets"
    },
    "ListEventTrackers": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "eventTrackers"
    },
    "ListRecipes": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "recipes"
    },
    "ListSchemas": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "schemas"
    },
    "ListSolutionVersions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "solutionVersions"
    },
    "ListSolutions": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "solutions"
    }
  }
}