{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-01-25",
    "endpointPrefix": "appmesh",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS App Mesh",
    "serviceId": "App Mesh",
    "signatureVersion": "v4",
    "signingName": "appmesh",
    "uid": "appmesh-2019-01-25"
  },
  "operations": {
    "CreateMesh": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {},
          "spec": {
            "shape": "S4"
          },
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "Sc"
          }
        },
        "payload": "mesh"
      },
      "idempotent": true
    },
    "CreateRoute": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "routeName": {},
          "spec": {
            "shape": "Sk"
          },
          "tags": {
            "shape": "S7"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S14"
          }
        },
        "payload": "route"
      },
      "idempotent": true
    },
    "CreateVirtualNode": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualNodeName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S18"
          },
          "tags": {
            "shape": "S7"
          },
          "virtualNodeName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S20"
          }
        },
        "payload": "virtualNode"
      },
      "idempotent": true
    },
    "CreateVirtualRouter": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S24"
          },
          "tags": {
            "shape": "S7"
          },
          "virtualRouterName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S28"
          }
        },
        "payload": "virtualRouter"
      },
      "idempotent": true
    },
    "CreateVirtualService": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualServiceName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S2c"
          },
          "tags": {
            "shape": "S7"
          },
          "virtualServiceName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S2h"
          }
        },
        "payload": "virtualService"
      },
      "idempotent": true
    },
    "DeleteMesh": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "Sc"
          }
        },
        "payload": "mesh"
      },
      "idempotent": true
    },
    "DeleteRoute": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes/{routeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "routeName": {
            "location": "uri",
            "locationName": "routeName"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S14"
          }
        },
        "payload": "route"
      },
      "idempotent": true
    },
    "DeleteVirtualNode": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes/{virtualNodeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualNodeName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "virtualNodeName": {
            "location": "uri",
            "locationName": "virtualNodeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S20"
          }
        },
        "payload": "virtualNode"
      },
      "idempotent": true
    },
    "DeleteVirtualRouter": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters/{virtualRouterName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S28"
          }
        },
        "payload": "virtualRouter"
      },
      "idempotent": true
    },
    "DeleteVirtualService": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices/{virtualServiceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualServiceName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "virtualServiceName": {
            "location": "uri",
            "locationName": "virtualServiceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S2h"
          }
        },
        "payload": "virtualService"
      },
      "idempotent": true
    },
    "DescribeMesh": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "Sc"
          }
        },
        "payload": "mesh"
      }
    },
    "DescribeRoute": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes/{routeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "routeName": {
            "location": "uri",
            "locationName": "routeName"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S14"
          }
        },
        "payload": "route"
      }
    },
    "DescribeVirtualNode": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes/{virtualNodeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualNodeName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "virtualNodeName": {
            "location": "uri",
            "locationName": "virtualNodeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S20"
          }
        },
        "payload": "virtualNode"
      }
    },
    "DescribeVirtualRouter": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters/{virtualRouterName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualRouterName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S28"
          }
        },
        "payload": "virtualRouter"
      }
    },
    "DescribeVirtualService": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices/{virtualServiceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualServiceName"
        ],
        "members": {
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "virtualServiceName": {
            "location": "uri",
            "locationName": "virtualServiceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S2h"
          }
        },
        "payload": "virtualService"
      }
    },
    "ListMeshes": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "meshes"
        ],
        "members": {
          "meshes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "meshName"
              ],
              "members": {
                "arn": {},
                "meshName": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRoutes": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "virtualRouterName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "routes"
        ],
        "members": {
          "nextToken": {},
          "routes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "meshName",
                "routeName",
                "virtualRouterName"
              ],
              "members": {
                "arn": {},
                "meshName": {},
                "routeName": {},
                "virtualRouterName": {}
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "tags"
        ],
        "members": {
          "nextToken": {},
          "tags": {
            "shape": "S7"
          }
        }
      }
    },
    "ListVirtualNodes": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNodes"
        ],
        "members": {
          "nextToken": {},
          "virtualNodes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "meshName",
                "virtualNodeName"
              ],
              "members": {
                "arn": {},
                "meshName": {},
                "virtualNodeName": {}
              }
            }
          }
        }
      }
    },
    "ListVirtualRouters": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouters"
        ],
        "members": {
          "nextToken": {},
          "virtualRouters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "meshName",
                "virtualRouterName"
              ],
              "members": {
                "arn": {},
                "meshName": {},
                "virtualRouterName": {}
              }
            }
          }
        }
      }
    },
    "ListVirtualServices": {
      "http": {
        "method": "GET",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "limit": {
            "location": "querystring",
            "locationName": "limit",
            "type": "integer"
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualServices"
        ],
        "members": {
          "nextToken": {},
          "virtualServices": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "arn",
                "meshName",
                "virtualServiceName"
              ],
              "members": {
                "arn": {},
                "meshName": {},
                "virtualServiceName": {}
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/tag",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/untag",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateMesh": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "mesh"
        ],
        "members": {
          "mesh": {
            "shape": "Sc"
          }
        },
        "payload": "mesh"
      },
      "idempotent": true
    },
    "UpdateRoute": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouter/{virtualRouterName}/routes/{routeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "routeName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "routeName": {
            "location": "uri",
            "locationName": "routeName"
          },
          "spec": {
            "shape": "Sk"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "route"
        ],
        "members": {
          "route": {
            "shape": "S14"
          }
        },
        "payload": "route"
      },
      "idempotent": true
    },
    "UpdateVirtualNode": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualNodes/{virtualNodeName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualNodeName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S18"
          },
          "virtualNodeName": {
            "location": "uri",
            "locationName": "virtualNodeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualNode"
        ],
        "members": {
          "virtualNode": {
            "shape": "S20"
          }
        },
        "payload": "virtualNode"
      },
      "idempotent": true
    },
    "UpdateVirtualRouter": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualRouters/{virtualRouterName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualRouterName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S24"
          },
          "virtualRouterName": {
            "location": "uri",
            "locationName": "virtualRouterName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualRouter"
        ],
        "members": {
          "virtualRouter": {
            "shape": "S28"
          }
        },
        "payload": "virtualRouter"
      },
      "idempotent": true
    },
    "UpdateVirtualService": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20190125/meshes/{meshName}/virtualServices/{virtualServiceName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "meshName",
          "spec",
          "virtualServiceName"
        ],
        "members": {
          "clientToken": {
            "idempotencyToken": true
          },
          "meshName": {
            "location": "uri",
            "locationName": "meshName"
          },
          "spec": {
            "shape": "S2c"
          },
          "virtualServiceName": {
            "location": "uri",
            "locationName": "virtualServiceName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "virtualService"
        ],
        "members": {
          "virtualService": {
            "shape": "S2h"
          }
        },
        "payload": "virtualService"
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "egressFilter": {
          "type": "structure",
          "required": [
            "type"
          ],
          "members": {
            "type": {}
          }
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "Sc": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "Sd"
        },
        "spec": {
          "shape": "S4"
        },
        "status": {
          "type": "structure",
          "members": {
            "status": {}
          }
        }
      }
    },
    "Sd": {
      "type": "structure",
      "required": [
        "arn",
        "createdAt",
        "lastUpdatedAt",
        "uid",
        "version"
      ],
      "members": {
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "uid": {},
        "version": {
          "type": "long"
        }
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "httpRoute": {
          "type": "structure",
          "required": [
            "action",
            "match"
          ],
          "members": {
            "action": {
              "type": "structure",
              "required": [
                "weightedTargets"
              ],
              "members": {
                "weightedTargets": {
                  "shape": "Sn"
                }
              }
            },
            "match": {
              "type": "structure",
              "required": [
                "prefix"
              ],
              "members": {
                "headers": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "name"
                    ],
                    "members": {
                      "invert": {
                        "type": "boolean"
                      },
                      "match": {
                        "type": "structure",
                        "members": {
                          "exact": {},
                          "prefix": {},
                          "range": {
                            "type": "structure",
                            "required": [
                              "end",
                              "start"
                            ],
                            "members": {
                              "end": {
                                "type": "long"
                              },
                              "start": {
                                "type": "long"
                              }
                            }
                          },
                          "regex": {},
                          "suffix": {}
                        }
                      },
                      "name": {}
                    }
                  }
                },
                "method": {},
                "prefix": {},
                "scheme": {}
              }
            }
          }
        },
        "priority": {
          "type": "integer"
        },
        "tcpRoute": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {
              "type": "structure",
              "required": [
                "weightedTargets"
              ],
              "members": {
                "weightedTargets": {
                  "shape": "Sn"
                }
              }
            }
          }
        }
      }
    },
    "Sn": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "virtualNode",
          "weight"
        ],
        "members": {
          "virtualNode": {},
          "weight": {
            "type": "integer"
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "routeName",
        "spec",
        "status",
        "virtualRouterName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "Sd"
        },
        "routeName": {},
        "spec": {
          "shape": "Sk"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualRouterName": {}
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "backends": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "virtualService": {
                "type": "structure",
                "required": [
                  "virtualServiceName"
                ],
                "members": {
                  "virtualServiceName": {}
                }
              }
            }
          }
        },
        "listeners": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "portMapping"
            ],
            "members": {
              "healthCheck": {
                "type": "structure",
                "required": [
                  "healthyThreshold",
                  "intervalMillis",
                  "protocol",
                  "timeoutMillis",
                  "unhealthyThreshold"
                ],
                "members": {
                  "healthyThreshold": {
                    "type": "integer"
                  },
                  "intervalMillis": {
                    "type": "long"
                  },
                  "path": {},
                  "port": {
                    "type": "integer"
                  },
                  "protocol": {},
                  "timeoutMillis": {
                    "type": "long"
                  },
                  "unhealthyThreshold": {
                    "type": "integer"
                  }
                }
              },
              "portMapping": {
                "shape": "S1l"
              }
            }
          }
        },
        "logging": {
          "type": "structure",
          "members": {
            "accessLog": {
              "type": "structure",
              "members": {
                "file": {
                  "type": "structure",
                  "required": [
                    "path"
                  ],
                  "members": {
                    "path": {}
                  }
                }
              }
            }
          }
        },
        "serviceDiscovery": {
          "type": "structure",
          "members": {
            "awsCloudMap": {
              "type": "structure",
              "required": [
                "namespaceName",
                "serviceName"
              ],
              "members": {
                "attributes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "key",
                      "value"
                    ],
                    "members": {
                      "key": {},
                      "value": {}
                    }
                  }
                },
                "namespaceName": {},
                "serviceName": {}
              }
            },
            "dns": {
              "type": "structure",
              "required": [
                "hostname"
              ],
              "members": {
                "hostname": {}
              }
            }
          }
        }
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "port",
        "protocol"
      ],
      "members": {
        "port": {
          "type": "integer"
        },
        "protocol": {}
      }
    },
    "S20": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualNodeName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "Sd"
        },
        "spec": {
          "shape": "S18"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualNodeName": {}
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "listeners": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "portMapping"
            ],
            "members": {
              "portMapping": {
                "shape": "S1l"
              }
            }
          }
        }
      }
    },
    "S28": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualRouterName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "Sd"
        },
        "spec": {
          "shape": "S24"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualRouterName": {}
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "provider": {
          "type": "structure",
          "members": {
            "virtualNode": {
              "type": "structure",
              "required": [
                "virtualNodeName"
              ],
              "members": {
                "virtualNodeName": {}
              }
            },
            "virtualRouter": {
              "type": "structure",
              "required": [
                "virtualRouterName"
              ],
              "members": {
                "virtualRouterName": {}
              }
            }
          }
        }
      }
    },
    "S2h": {
      "type": "structure",
      "required": [
        "meshName",
        "metadata",
        "spec",
        "status",
        "virtualServiceName"
      ],
      "members": {
        "meshName": {},
        "metadata": {
          "shape": "Sd"
        },
        "spec": {
          "shape": "S2c"
        },
        "status": {
          "type": "structure",
          "required": [
            "status"
          ],
          "members": {
            "status": {}
          }
        },
        "virtualServiceName": {}
      }
    }
  }
}