"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useOnBeforeUnload = void 0;
const react_1 = require("react");
const useOnBeforeUnload = (callback) => {
    const callbackRef = (0, react_1.useRef)(callback);
    (0, react_1.useEffect)(() => {
        callbackRef.current = callback;
    }, [callback]);
    (0, react_1.useEffect)(() => {
        const cb = () => {
            callbackRef.current();
        };
        window.addEventListener('beforeunload', cb);
        return () => {
            window.removeEventListener('beforeunload', cb);
        };
    }, []);
};
exports.useOnBeforeUnload = useOnBeforeUnload;
