import * as R from 'ramda';
import { useCallback, useState } from 'react';
export const useTableSelection = () => {
    const [selected, setSelected] = useState([]);
    const handleSelectionChange = useCallback((index, checked) => {
        if (checked) {
            setSelected((selection) => R.pipe(R.append(index), R.uniq)(selection));
        }
        else {
            setSelected((selection) => R.reject(R.equals(index), selection));
        }
    }, [setSelected]);
    const reset = useCallback(() => setSelected([]), [setSelected]);
    return {
        selected,
        handleSelectionChange,
        reset,
    };
};
