{
  "name": "@aws-amplify/core",
  "version": "2.3.0",
  "description": "Core category of aws-amplify",
  "main": "./lib/index.js",
  "module": "./lib-esm/index.js",
  "typings": "./lib-esm/index.d.ts",
  "publishConfig": {
    "access": "public"
  },
  "scripts": {
    "test": "tslint 'src/**/*.ts' && jest -w 1 --coverage",
    "build-with-test": "npm test && npm run build",
    "build:cjs": "node ./build es5 && webpack && webpack --config ./webpack.config.dev.js",
    "build:esm": "node ./build es6",
    "build:cjs:watch": "node ./build es5 --watch",
    "build:esm:watch": "node ./build es6 --watch",
    "build": "npm run clean && npm run build:esm && npm run build:cjs",
    "clean": "rimraf lib-esm lib dist",
    "format": "echo \"Not implemented\"",
    "lint": "tslint 'src/**/*.ts'"
  },
  "react-native": {
    "./index": "./lib/index.js",
    "./lib/ClientDevice": "./lib/ClientDevice/reactnative.js",
    "./lib/RNComponents": "./lib/RNComponents/reactnative.js",
    "./lib/StorageHelper": "./lib/StorageHelper/reactnative.js"
  },
  "repository": {
    "type": "git",
    "url": "https://github.com/aws-amplify/amplify-js.git"
  },
  "author": "Amazon Web Services",
  "license": "Apache-2.0",
  "bugs": {
    "url": "https://github.com/aws/aws-amplify/issues"
  },
  "homepage": "https://aws-amplify.github.io/",
  "devDependencies": {
    "@react-native-community/netinfo": "4.7.0",
    "find": "^0.2.7",
    "prepend-file": "^1.3.1",
    "react-native": "0.59.0"
  },
  "dependencies": {
    "aws-sdk": "2.518.0",
    "url": "^0.11.0",
    "zen-observable": "^0.8.6"
  },
  "peerDependencies": {
    "@react-native-community/netinfo": "^5.5.0"
  },
  "jest": {
    "globals": {
      "ts-jest": {
        "diagnostics": false,
        "tsConfig": {
          "lib": [
            "es5",
            "es2015",
            "dom",
            "esnext.asynciterable",
            "es2017.object"
          ],
          "allowJs": true
        }
      }
    },
    "transform": {
      "^.+\\.(js|jsx|ts|tsx)$": "ts-jest"
    },
    "testRegex": "(/__tests__/.*|\\.(test|spec))\\.(tsx?|jsx?)$",
    "moduleFileExtensions": [
      "ts",
      "tsx",
      "js",
      "json",
      "jsx"
    ],
    "testEnvironment": "jsdom",
    "coverageThreshold": {
      "global": {
        "branches": 0,
        "functions": 0,
        "lines": 0,
        "statements": 0
      }
    },
    "testURL": "http://localhost/",
    "coveragePathIgnorePatterns": [
      "/node_modules/"
    ]
  },
  "gitHead": "a105664b4028e06246c6d9f6ceebb8730187d50c"
}
