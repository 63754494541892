{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-08-10",
    "endpointPrefix": "batch",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "AWS Batch",
    "serviceFullName": "AWS Batch",
    "serviceId": "Batch",
    "signatureVersion": "v4",
    "uid": "batch-2016-08-10"
  },
  "operations": {
    "CancelJob": {
      "http": {
        "requestUri": "/v1/canceljob"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "reason"
        ],
        "members": {
          "jobId": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateComputeEnvironment": {
      "http": {
        "requestUri": "/v1/createcomputeenvironment"
      },
      "input": {
        "type": "structure",
        "required": [
          "computeEnvironmentName",
          "type",
          "serviceRole"
        ],
        "members": {
          "computeEnvironmentName": {},
          "type": {},
          "state": {},
          "computeResources": {
            "shape": "S7"
          },
          "serviceRole": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "computeEnvironmentName": {},
          "computeEnvironmentArn": {}
        }
      }
    },
    "CreateJobQueue": {
      "http": {
        "requestUri": "/v1/createjobqueue"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobQueueName",
          "priority",
          "computeEnvironmentOrder"
        ],
        "members": {
          "jobQueueName": {},
          "state": {},
          "priority": {
            "type": "integer"
          },
          "computeEnvironmentOrder": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobQueueName",
          "jobQueueArn"
        ],
        "members": {
          "jobQueueName": {},
          "jobQueueArn": {}
        }
      }
    },
    "DeleteComputeEnvironment": {
      "http": {
        "requestUri": "/v1/deletecomputeenvironment"
      },
      "input": {
        "type": "structure",
        "required": [
          "computeEnvironment"
        ],
        "members": {
          "computeEnvironment": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteJobQueue": {
      "http": {
        "requestUri": "/v1/deletejobqueue"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobQueue"
        ],
        "members": {
          "jobQueue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterJobDefinition": {
      "http": {
        "requestUri": "/v1/deregisterjobdefinition"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobDefinition"
        ],
        "members": {
          "jobDefinition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeComputeEnvironments": {
      "http": {
        "requestUri": "/v1/describecomputeenvironments"
      },
      "input": {
        "type": "structure",
        "members": {
          "computeEnvironments": {
            "shape": "Sa"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "computeEnvironments": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "computeEnvironmentName",
                "computeEnvironmentArn",
                "ecsClusterArn"
              ],
              "members": {
                "computeEnvironmentName": {},
                "computeEnvironmentArn": {},
                "ecsClusterArn": {},
                "type": {},
                "state": {},
                "status": {},
                "statusReason": {},
                "computeResources": {
                  "shape": "S7"
                },
                "serviceRole": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobDefinitions": {
      "http": {
        "requestUri": "/v1/describejobdefinitions"
      },
      "input": {
        "type": "structure",
        "members": {
          "jobDefinitions": {
            "shape": "Sa"
          },
          "maxResults": {
            "type": "integer"
          },
          "jobDefinitionName": {},
          "status": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobDefinitions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobDefinitionName",
                "jobDefinitionArn",
                "revision",
                "type"
              ],
              "members": {
                "jobDefinitionName": {},
                "jobDefinitionArn": {},
                "revision": {
                  "type": "integer"
                },
                "status": {},
                "type": {},
                "parameters": {
                  "shape": "Sy"
                },
                "retryStrategy": {
                  "shape": "Sz"
                },
                "containerProperties": {
                  "shape": "S10"
                },
                "timeout": {
                  "shape": "S1j"
                },
                "nodeProperties": {
                  "shape": "S1k"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobQueues": {
      "http": {
        "requestUri": "/v1/describejobqueues"
      },
      "input": {
        "type": "structure",
        "members": {
          "jobQueues": {
            "shape": "Sa"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobQueues": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobQueueName",
                "jobQueueArn",
                "state",
                "priority",
                "computeEnvironmentOrder"
              ],
              "members": {
                "jobQueueName": {},
                "jobQueueArn": {},
                "state": {},
                "status": {},
                "statusReason": {},
                "priority": {
                  "type": "integer"
                },
                "computeEnvironmentOrder": {
                  "shape": "Sg"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeJobs": {
      "http": {
        "requestUri": "/v1/describejobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobs"
        ],
        "members": {
          "jobs": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobName",
                "jobId",
                "jobQueue",
                "status",
                "startedAt",
                "jobDefinition"
              ],
              "members": {
                "jobName": {},
                "jobId": {},
                "jobQueue": {},
                "status": {},
                "attempts": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "container": {
                        "type": "structure",
                        "members": {
                          "containerInstanceArn": {},
                          "taskArn": {},
                          "exitCode": {
                            "type": "integer"
                          },
                          "reason": {},
                          "logStreamName": {},
                          "networkInterfaces": {
                            "shape": "S20"
                          }
                        }
                      },
                      "startedAt": {
                        "type": "long"
                      },
                      "stoppedAt": {
                        "type": "long"
                      },
                      "statusReason": {}
                    }
                  }
                },
                "statusReason": {},
                "createdAt": {
                  "type": "long"
                },
                "retryStrategy": {
                  "shape": "Sz"
                },
                "startedAt": {
                  "type": "long"
                },
                "stoppedAt": {
                  "type": "long"
                },
                "dependsOn": {
                  "shape": "S23"
                },
                "jobDefinition": {},
                "parameters": {
                  "shape": "Sy"
                },
                "container": {
                  "type": "structure",
                  "members": {
                    "image": {},
                    "vcpus": {
                      "type": "integer"
                    },
                    "memory": {
                      "type": "integer"
                    },
                    "command": {
                      "shape": "Sa"
                    },
                    "jobRoleArn": {},
                    "volumes": {
                      "shape": "S11"
                    },
                    "environment": {
                      "shape": "S14"
                    },
                    "mountPoints": {
                      "shape": "S16"
                    },
                    "readonlyRootFilesystem": {
                      "type": "boolean"
                    },
                    "ulimits": {
                      "shape": "S19"
                    },
                    "privileged": {
                      "type": "boolean"
                    },
                    "user": {},
                    "exitCode": {
                      "type": "integer"
                    },
                    "reason": {},
                    "containerInstanceArn": {},
                    "taskArn": {},
                    "logStreamName": {},
                    "instanceType": {},
                    "networkInterfaces": {
                      "shape": "S20"
                    },
                    "resourceRequirements": {
                      "shape": "S1b"
                    },
                    "linuxParameters": {
                      "shape": "S1e"
                    }
                  }
                },
                "nodeDetails": {
                  "type": "structure",
                  "members": {
                    "nodeIndex": {
                      "type": "integer"
                    },
                    "isMainNode": {
                      "type": "boolean"
                    }
                  }
                },
                "nodeProperties": {
                  "shape": "S1k"
                },
                "arrayProperties": {
                  "type": "structure",
                  "members": {
                    "statusSummary": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "integer"
                      }
                    },
                    "size": {
                      "type": "integer"
                    },
                    "index": {
                      "type": "integer"
                    }
                  }
                },
                "timeout": {
                  "shape": "S1j"
                }
              }
            }
          }
        }
      }
    },
    "ListJobs": {
      "http": {
        "requestUri": "/v1/listjobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "jobQueue": {},
          "arrayJobId": {},
          "multiNodeJobId": {},
          "jobStatus": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobSummaryList"
        ],
        "members": {
          "jobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "jobId",
                "jobName"
              ],
              "members": {
                "jobId": {},
                "jobName": {},
                "createdAt": {
                  "type": "long"
                },
                "status": {},
                "statusReason": {},
                "startedAt": {
                  "type": "long"
                },
                "stoppedAt": {
                  "type": "long"
                },
                "container": {
                  "type": "structure",
                  "members": {
                    "exitCode": {
                      "type": "integer"
                    },
                    "reason": {}
                  }
                },
                "arrayProperties": {
                  "type": "structure",
                  "members": {
                    "size": {
                      "type": "integer"
                    },
                    "index": {
                      "type": "integer"
                    }
                  }
                },
                "nodeProperties": {
                  "type": "structure",
                  "members": {
                    "isMainNode": {
                      "type": "boolean"
                    },
                    "numNodes": {
                      "type": "integer"
                    },
                    "nodeIndex": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "RegisterJobDefinition": {
      "http": {
        "requestUri": "/v1/registerjobdefinition"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobDefinitionName",
          "type"
        ],
        "members": {
          "jobDefinitionName": {},
          "type": {},
          "parameters": {
            "shape": "Sy"
          },
          "containerProperties": {
            "shape": "S10"
          },
          "nodeProperties": {
            "shape": "S1k"
          },
          "retryStrategy": {
            "shape": "Sz"
          },
          "timeout": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobDefinitionName",
          "jobDefinitionArn",
          "revision"
        ],
        "members": {
          "jobDefinitionName": {},
          "jobDefinitionArn": {},
          "revision": {
            "type": "integer"
          }
        }
      }
    },
    "SubmitJob": {
      "http": {
        "requestUri": "/v1/submitjob"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobName",
          "jobQueue",
          "jobDefinition"
        ],
        "members": {
          "jobName": {},
          "jobQueue": {},
          "arrayProperties": {
            "type": "structure",
            "members": {
              "size": {
                "type": "integer"
              }
            }
          },
          "dependsOn": {
            "shape": "S23"
          },
          "jobDefinition": {},
          "parameters": {
            "shape": "Sy"
          },
          "containerOverrides": {
            "shape": "S2m"
          },
          "nodeOverrides": {
            "type": "structure",
            "members": {
              "numNodes": {
                "type": "integer"
              },
              "nodePropertyOverrides": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "targetNodes"
                  ],
                  "members": {
                    "targetNodes": {},
                    "containerOverrides": {
                      "shape": "S2m"
                    }
                  }
                }
              }
            }
          },
          "retryStrategy": {
            "shape": "Sz"
          },
          "timeout": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "jobName",
          "jobId"
        ],
        "members": {
          "jobName": {},
          "jobId": {}
        }
      }
    },
    "TerminateJob": {
      "http": {
        "requestUri": "/v1/terminatejob"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "reason"
        ],
        "members": {
          "jobId": {},
          "reason": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateComputeEnvironment": {
      "http": {
        "requestUri": "/v1/updatecomputeenvironment"
      },
      "input": {
        "type": "structure",
        "required": [
          "computeEnvironment"
        ],
        "members": {
          "computeEnvironment": {},
          "state": {},
          "computeResources": {
            "type": "structure",
            "members": {
              "minvCpus": {
                "type": "integer"
              },
              "maxvCpus": {
                "type": "integer"
              },
              "desiredvCpus": {
                "type": "integer"
              }
            }
          },
          "serviceRole": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "computeEnvironmentName": {},
          "computeEnvironmentArn": {}
        }
      }
    },
    "UpdateJobQueue": {
      "http": {
        "requestUri": "/v1/updatejobqueue"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobQueue"
        ],
        "members": {
          "jobQueue": {},
          "state": {},
          "priority": {
            "type": "integer"
          },
          "computeEnvironmentOrder": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobQueueName": {},
          "jobQueueArn": {}
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "required": [
        "type",
        "minvCpus",
        "maxvCpus",
        "instanceTypes",
        "subnets",
        "instanceRole"
      ],
      "members": {
        "type": {},
        "minvCpus": {
          "type": "integer"
        },
        "maxvCpus": {
          "type": "integer"
        },
        "desiredvCpus": {
          "type": "integer"
        },
        "instanceTypes": {
          "shape": "Sa"
        },
        "imageId": {},
        "subnets": {
          "shape": "Sa"
        },
        "securityGroupIds": {
          "shape": "Sa"
        },
        "ec2KeyPair": {},
        "instanceRole": {},
        "tags": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "placementGroup": {},
        "bidPercentage": {
          "type": "integer"
        },
        "spotIamFleetRole": {},
        "launchTemplate": {
          "type": "structure",
          "members": {
            "launchTemplateId": {},
            "launchTemplateName": {},
            "version": {}
          }
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {}
    },
    "Sg": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "order",
          "computeEnvironment"
        ],
        "members": {
          "order": {
            "type": "integer"
          },
          "computeEnvironment": {}
        }
      }
    },
    "Sy": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sz": {
      "type": "structure",
      "members": {
        "attempts": {
          "type": "integer"
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "image": {},
        "vcpus": {
          "type": "integer"
        },
        "memory": {
          "type": "integer"
        },
        "command": {
          "shape": "Sa"
        },
        "jobRoleArn": {},
        "volumes": {
          "shape": "S11"
        },
        "environment": {
          "shape": "S14"
        },
        "mountPoints": {
          "shape": "S16"
        },
        "readonlyRootFilesystem": {
          "type": "boolean"
        },
        "privileged": {
          "type": "boolean"
        },
        "ulimits": {
          "shape": "S19"
        },
        "user": {},
        "instanceType": {},
        "resourceRequirements": {
          "shape": "S1b"
        },
        "linuxParameters": {
          "shape": "S1e"
        }
      }
    },
    "S11": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "host": {
            "type": "structure",
            "members": {
              "sourcePath": {}
            }
          },
          "name": {}
        }
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "value": {}
        }
      }
    },
    "S16": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "containerPath": {},
          "readOnly": {
            "type": "boolean"
          },
          "sourceVolume": {}
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "hardLimit",
          "name",
          "softLimit"
        ],
        "members": {
          "hardLimit": {
            "type": "integer"
          },
          "name": {},
          "softLimit": {
            "type": "integer"
          }
        }
      }
    },
    "S1b": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "value",
          "type"
        ],
        "members": {
          "value": {},
          "type": {}
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "devices": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "hostPath"
            ],
            "members": {
              "hostPath": {},
              "containerPath": {},
              "permissions": {
                "type": "list",
                "member": {}
              }
            }
          }
        }
      }
    },
    "S1j": {
      "type": "structure",
      "members": {
        "attemptDurationSeconds": {
          "type": "integer"
        }
      }
    },
    "S1k": {
      "type": "structure",
      "required": [
        "numNodes",
        "mainNode",
        "nodeRangeProperties"
      ],
      "members": {
        "numNodes": {
          "type": "integer"
        },
        "mainNode": {
          "type": "integer"
        },
        "nodeRangeProperties": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "targetNodes"
            ],
            "members": {
              "targetNodes": {},
              "container": {
                "shape": "S10"
              }
            }
          }
        }
      }
    },
    "S20": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "attachmentId": {},
          "ipv6Address": {},
          "privateIpv4Address": {}
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "jobId": {},
          "type": {}
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "vcpus": {
          "type": "integer"
        },
        "memory": {
          "type": "integer"
        },
        "command": {
          "shape": "Sa"
        },
        "instanceType": {},
        "environment": {
          "shape": "S14"
        },
        "resourceRequirements": {
          "shape": "S1b"
        }
      }
    }
  }
}