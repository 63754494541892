{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-04-12",
    "endpointPrefix": "xray",
    "protocol": "rest-json",
    "serviceFullName": "AWS X-Ray",
    "serviceId": "XRay",
    "signatureVersion": "v4",
    "uid": "xray-2016-04-12"
  },
  "operations": {
    "BatchGetTraces": {
      "http": {
        "requestUri": "/Traces"
      },
      "input": {
        "type": "structure",
        "required": [
          "TraceIds"
        ],
        "members": {
          "TraceIds": {
            "shape": "S2"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Traces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Duration": {
                  "type": "double"
                },
                "Segments": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Id": {},
                      "Document": {}
                    }
                  }
                }
              }
            }
          },
          "UnprocessedTraceIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "CreateGroup": {
      "http": {
        "requestUri": "/CreateGroup"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "FilterExpression": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Si"
          }
        }
      }
    },
    "CreateSamplingRule": {
      "http": {
        "requestUri": "/CreateSamplingRule"
      },
      "input": {
        "type": "structure",
        "required": [
          "SamplingRule"
        ],
        "members": {
          "SamplingRule": {
            "shape": "Sk"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecord": {
            "shape": "S10"
          }
        }
      }
    },
    "DeleteGroup": {
      "http": {
        "requestUri": "/DeleteGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "GroupName": {},
          "GroupARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSamplingRule": {
      "http": {
        "requestUri": "/DeleteSamplingRule"
      },
      "input": {
        "type": "structure",
        "members": {
          "RuleName": {},
          "RuleARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecord": {
            "shape": "S10"
          }
        }
      }
    },
    "GetEncryptionConfig": {
      "http": {
        "requestUri": "/EncryptionConfig"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "EncryptionConfig": {
            "shape": "S19"
          }
        }
      }
    },
    "GetGroup": {
      "http": {
        "requestUri": "/GetGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "GroupName": {},
          "GroupARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Si"
          }
        }
      }
    },
    "GetGroups": {
      "http": {
        "requestUri": "/Groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupName": {},
                "GroupARN": {},
                "FilterExpression": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSamplingRules": {
      "http": {
        "requestUri": "/GetSamplingRules"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecords": {
            "type": "list",
            "member": {
              "shape": "S10"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSamplingStatisticSummaries": {
      "http": {
        "requestUri": "/SamplingStatisticSummaries"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingStatisticSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleName": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "RequestCount": {
                  "type": "integer"
                },
                "BorrowCount": {
                  "type": "integer"
                },
                "SampledCount": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetSamplingTargets": {
      "http": {
        "requestUri": "/SamplingTargets"
      },
      "input": {
        "type": "structure",
        "required": [
          "SamplingStatisticsDocuments"
        ],
        "members": {
          "SamplingStatisticsDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "RuleName",
                "ClientID",
                "Timestamp",
                "RequestCount",
                "SampledCount"
              ],
              "members": {
                "RuleName": {},
                "ClientID": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "RequestCount": {
                  "type": "integer"
                },
                "SampledCount": {
                  "type": "integer"
                },
                "BorrowCount": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingTargetDocuments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleName": {},
                "FixedRate": {
                  "type": "double"
                },
                "ReservoirQuota": {
                  "type": "integer"
                },
                "ReservoirQuotaTTL": {
                  "type": "timestamp"
                },
                "Interval": {
                  "type": "integer"
                }
              }
            }
          },
          "LastRuleModification": {
            "type": "timestamp"
          },
          "UnprocessedStatistics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RuleName": {},
                "ErrorCode": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "GetServiceGraph": {
      "http": {
        "requestUri": "/ServiceGraph"
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "GroupName": {},
          "GroupARN": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Services": {
            "shape": "S27"
          },
          "ContainsOldGroupVersions": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      }
    },
    "GetTimeSeriesServiceStatistics": {
      "http": {
        "requestUri": "/TimeSeriesServiceStatistics"
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "GroupName": {},
          "GroupARN": {},
          "EntitySelectorExpression": {},
          "Period": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TimeSeriesServiceStatistics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "EdgeSummaryStatistics": {
                  "shape": "S2d"
                },
                "ServiceSummaryStatistics": {
                  "shape": "S2m"
                },
                "ResponseTimeHistogram": {
                  "shape": "S2h"
                }
              }
            }
          },
          "ContainsOldGroupVersions": {
            "type": "boolean"
          },
          "NextToken": {}
        }
      }
    },
    "GetTraceGraph": {
      "http": {
        "requestUri": "/TraceGraph"
      },
      "input": {
        "type": "structure",
        "required": [
          "TraceIds"
        ],
        "members": {
          "TraceIds": {
            "shape": "S2"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Services": {
            "shape": "S27"
          },
          "NextToken": {}
        }
      }
    },
    "GetTraceSummaries": {
      "http": {
        "requestUri": "/TraceSummaries"
      },
      "input": {
        "type": "structure",
        "required": [
          "StartTime",
          "EndTime"
        ],
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "TimeRangeType": {},
          "Sampling": {
            "type": "boolean"
          },
          "SamplingStrategy": {
            "type": "structure",
            "members": {
              "Name": {},
              "Value": {
                "type": "double"
              }
            }
          },
          "FilterExpression": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TraceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Duration": {
                  "type": "double"
                },
                "ResponseTime": {
                  "type": "double"
                },
                "HasFault": {
                  "type": "boolean"
                },
                "HasError": {
                  "type": "boolean"
                },
                "HasThrottle": {
                  "type": "boolean"
                },
                "IsPartial": {
                  "type": "boolean"
                },
                "Http": {
                  "type": "structure",
                  "members": {
                    "HttpURL": {},
                    "HttpStatus": {
                      "type": "integer"
                    },
                    "HttpMethod": {},
                    "UserAgent": {},
                    "ClientIp": {}
                  }
                },
                "Annotations": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "AnnotationValue": {
                          "type": "structure",
                          "members": {
                            "NumberValue": {
                              "type": "double"
                            },
                            "BooleanValue": {
                              "type": "boolean"
                            },
                            "StringValue": {}
                          }
                        },
                        "ServiceIds": {
                          "shape": "S38"
                        }
                      }
                    }
                  }
                },
                "Users": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "UserName": {},
                      "ServiceIds": {
                        "shape": "S38"
                      }
                    }
                  }
                },
                "ServiceIds": {
                  "shape": "S38"
                },
                "ResourceARNs": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ARN": {}
                    }
                  }
                },
                "InstanceIds": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Id": {}
                    }
                  }
                },
                "AvailabilityZones": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {}
                    }
                  }
                },
                "EntryPoint": {
                  "shape": "S39"
                },
                "FaultRootCauses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Services": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Names": {
                              "shape": "S29"
                            },
                            "Type": {},
                            "AccountId": {},
                            "EntityPath": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "Exceptions": {
                                    "shape": "S3o"
                                  },
                                  "Remote": {
                                    "type": "boolean"
                                  }
                                }
                              }
                            },
                            "Inferred": {
                              "type": "boolean"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "ErrorRootCauses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Services": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Names": {
                              "shape": "S29"
                            },
                            "Type": {},
                            "AccountId": {},
                            "EntityPath": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "Exceptions": {
                                    "shape": "S3o"
                                  },
                                  "Remote": {
                                    "type": "boolean"
                                  }
                                }
                              }
                            },
                            "Inferred": {
                              "type": "boolean"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "ResponseTimeRootCauses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Services": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "Name": {},
                            "Names": {
                              "shape": "S29"
                            },
                            "Type": {},
                            "AccountId": {},
                            "EntityPath": {
                              "type": "list",
                              "member": {
                                "type": "structure",
                                "members": {
                                  "Name": {},
                                  "Coverage": {
                                    "type": "double"
                                  },
                                  "Remote": {
                                    "type": "boolean"
                                  }
                                }
                              }
                            },
                            "Inferred": {
                              "type": "boolean"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "Revision": {
                  "type": "integer"
                },
                "MatchedEventTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "ApproximateTime": {
            "type": "timestamp"
          },
          "TracesProcessedCount": {
            "type": "long"
          },
          "NextToken": {}
        }
      }
    },
    "PutEncryptionConfig": {
      "http": {
        "requestUri": "/PutEncryptionConfig"
      },
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "KeyId": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EncryptionConfig": {
            "shape": "S19"
          }
        }
      }
    },
    "PutTelemetryRecords": {
      "http": {
        "requestUri": "/TelemetryRecords"
      },
      "input": {
        "type": "structure",
        "required": [
          "TelemetryRecords"
        ],
        "members": {
          "TelemetryRecords": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Timestamp"
              ],
              "members": {
                "Timestamp": {
                  "type": "timestamp"
                },
                "SegmentsReceivedCount": {
                  "type": "integer"
                },
                "SegmentsSentCount": {
                  "type": "integer"
                },
                "SegmentsSpilloverCount": {
                  "type": "integer"
                },
                "SegmentsRejectedCount": {
                  "type": "integer"
                },
                "BackendConnectionErrors": {
                  "type": "structure",
                  "members": {
                    "TimeoutCount": {
                      "type": "integer"
                    },
                    "ConnectionRefusedCount": {
                      "type": "integer"
                    },
                    "HTTPCode4XXCount": {
                      "type": "integer"
                    },
                    "HTTPCode5XXCount": {
                      "type": "integer"
                    },
                    "UnknownHostCount": {
                      "type": "integer"
                    },
                    "OtherCount": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "EC2InstanceId": {},
          "Hostname": {},
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutTraceSegments": {
      "http": {
        "requestUri": "/TraceSegments"
      },
      "input": {
        "type": "structure",
        "required": [
          "TraceSegmentDocuments"
        ],
        "members": {
          "TraceSegmentDocuments": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedTraceSegments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ErrorCode": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "UpdateGroup": {
      "http": {
        "requestUri": "/UpdateGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "GroupName": {},
          "GroupARN": {},
          "FilterExpression": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Si"
          }
        }
      }
    },
    "UpdateSamplingRule": {
      "http": {
        "requestUri": "/UpdateSamplingRule"
      },
      "input": {
        "type": "structure",
        "required": [
          "SamplingRuleUpdate"
        ],
        "members": {
          "SamplingRuleUpdate": {
            "type": "structure",
            "members": {
              "RuleName": {},
              "RuleARN": {},
              "ResourceARN": {},
              "Priority": {
                "type": "integer"
              },
              "FixedRate": {
                "type": "double"
              },
              "ReservoirSize": {
                "type": "integer"
              },
              "Host": {},
              "ServiceName": {},
              "ServiceType": {},
              "HTTPMethod": {},
              "URLPath": {},
              "Attributes": {
                "shape": "Sw"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SamplingRuleRecord": {
            "shape": "S10"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "structure",
      "members": {
        "GroupName": {},
        "GroupARN": {},
        "FilterExpression": {}
      }
    },
    "Sk": {
      "type": "structure",
      "required": [
        "ResourceARN",
        "Priority",
        "FixedRate",
        "ReservoirSize",
        "ServiceName",
        "ServiceType",
        "Host",
        "HTTPMethod",
        "URLPath",
        "Version"
      ],
      "members": {
        "RuleName": {},
        "RuleARN": {},
        "ResourceARN": {},
        "Priority": {
          "type": "integer"
        },
        "FixedRate": {
          "type": "double"
        },
        "ReservoirSize": {
          "type": "integer"
        },
        "ServiceName": {},
        "ServiceType": {},
        "Host": {},
        "HTTPMethod": {},
        "URLPath": {},
        "Version": {
          "type": "integer"
        },
        "Attributes": {
          "shape": "Sw"
        }
      }
    },
    "Sw": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S10": {
      "type": "structure",
      "members": {
        "SamplingRule": {
          "shape": "Sk"
        },
        "CreatedAt": {
          "type": "timestamp"
        },
        "ModifiedAt": {
          "type": "timestamp"
        }
      }
    },
    "S19": {
      "type": "structure",
      "members": {
        "KeyId": {},
        "Status": {},
        "Type": {}
      }
    },
    "S27": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ReferenceId": {
            "type": "integer"
          },
          "Name": {},
          "Names": {
            "shape": "S29"
          },
          "Root": {
            "type": "boolean"
          },
          "AccountId": {},
          "Type": {},
          "State": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Edges": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReferenceId": {
                  "type": "integer"
                },
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "SummaryStatistics": {
                  "shape": "S2d"
                },
                "ResponseTimeHistogram": {
                  "shape": "S2h"
                },
                "Aliases": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "Names": {
                        "type": "list",
                        "member": {}
                      },
                      "Type": {}
                    }
                  }
                }
              }
            }
          },
          "SummaryStatistics": {
            "shape": "S2m"
          },
          "DurationHistogram": {
            "shape": "S2h"
          },
          "ResponseTimeHistogram": {
            "shape": "S2h"
          }
        }
      }
    },
    "S29": {
      "type": "list",
      "member": {}
    },
    "S2d": {
      "type": "structure",
      "members": {
        "OkCount": {
          "type": "long"
        },
        "ErrorStatistics": {
          "shape": "S2f"
        },
        "FaultStatistics": {
          "shape": "S2g"
        },
        "TotalCount": {
          "type": "long"
        },
        "TotalResponseTime": {
          "type": "double"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "members": {
        "ThrottleCount": {
          "type": "long"
        },
        "OtherCount": {
          "type": "long"
        },
        "TotalCount": {
          "type": "long"
        }
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "OtherCount": {
          "type": "long"
        },
        "TotalCount": {
          "type": "long"
        }
      }
    },
    "S2h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Value": {
            "type": "double"
          },
          "Count": {
            "type": "integer"
          }
        }
      }
    },
    "S2m": {
      "type": "structure",
      "members": {
        "OkCount": {
          "type": "long"
        },
        "ErrorStatistics": {
          "shape": "S2f"
        },
        "FaultStatistics": {
          "shape": "S2g"
        },
        "TotalCount": {
          "type": "long"
        },
        "TotalResponseTime": {
          "type": "double"
        }
      }
    },
    "S38": {
      "type": "list",
      "member": {
        "shape": "S39"
      }
    },
    "S39": {
      "type": "structure",
      "members": {
        "Name": {},
        "Names": {
          "shape": "S29"
        },
        "AccountId": {},
        "Type": {}
      }
    },
    "S3o": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Message": {}
        }
      }
    }
  }
}