{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-04-08",
    "endpointPrefix": "workspaces",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon WorkSpaces",
    "serviceId": "WorkSpaces",
    "signatureVersion": "v4",
    "targetPrefix": "WorkspacesService",
    "uid": "workspaces-2015-04-08"
  },
  "operations": {
    "AssociateIpGroups": {
      "input": {
        "type": "structure",
        "required": [
          "DirectoryId",
          "GroupIds"
        ],
        "members": {
          "DirectoryId": {},
          "GroupIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AuthorizeIpRules": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId",
          "UserRules"
        ],
        "members": {
          "GroupId": {},
          "UserRules": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CopyWorkspaceImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SourceImageId",
          "SourceRegion"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "SourceImageId": {},
          "SourceRegion": {},
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {}
        }
      }
    },
    "CreateIpGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {},
          "GroupDesc": {},
          "UserRules": {
            "shape": "S7"
          },
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {}
        }
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceId": {},
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "Workspaces"
        ],
        "members": {
          "Workspaces": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceRequest": {
                  "shape": "Sv"
                },
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "PendingRequests": {
            "shape": "S1b"
          }
        }
      }
    },
    "DeleteIpGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkspaceImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccount": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "DedicatedTenancySupport": {},
          "DedicatedTenancyManagementCidrRange": {}
        }
      }
    },
    "DescribeAccountModifications": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountModifications": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ModificationState": {},
                "DedicatedTenancySupport": {},
                "DedicatedTenancyManagementCidrRange": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeClientProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIds"
        ],
        "members": {
          "ResourceIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientPropertiesList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceId": {},
                "ClientProperties": {
                  "shape": "S2a"
                }
              }
            }
          }
        }
      }
    },
    "DescribeIpGroups": {
      "input": {
        "type": "structure",
        "members": {
          "GroupIds": {
            "shape": "S3"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Result": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "groupId": {},
                "groupName": {},
                "groupDesc": {},
                "userRules": {
                  "shape": "S7"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "Sh"
          }
        }
      }
    },
    "DescribeWorkspaceBundles": {
      "input": {
        "type": "structure",
        "members": {
          "BundleIds": {
            "type": "list",
            "member": {}
          },
          "Owner": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bundles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "BundleId": {},
                "Name": {},
                "Owner": {},
                "Description": {},
                "RootStorage": {
                  "type": "structure",
                  "members": {
                    "Capacity": {}
                  }
                },
                "UserStorage": {
                  "type": "structure",
                  "members": {
                    "Capacity": {}
                  }
                },
                "ComputeType": {
                  "type": "structure",
                  "members": {
                    "Name": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspaceDirectories": {
      "input": {
        "type": "structure",
        "members": {
          "DirectoryIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Directories": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DirectoryId": {},
                "Alias": {},
                "DirectoryName": {},
                "RegistrationCode": {},
                "SubnetIds": {
                  "type": "list",
                  "member": {}
                },
                "DnsIpAddresses": {
                  "type": "list",
                  "member": {}
                },
                "CustomerUserName": {},
                "IamRoleId": {},
                "DirectoryType": {},
                "WorkspaceSecurityGroupId": {},
                "State": {},
                "WorkspaceCreationProperties": {
                  "type": "structure",
                  "members": {
                    "EnableWorkDocs": {
                      "type": "boolean"
                    },
                    "EnableInternetAccess": {
                      "type": "boolean"
                    },
                    "DefaultOu": {},
                    "CustomSecurityGroupId": {},
                    "UserEnabledAsLocalAdministrator": {
                      "type": "boolean"
                    }
                  }
                },
                "ipGroupIds": {
                  "shape": "S3"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspaceImages": {
      "input": {
        "type": "structure",
        "members": {
          "ImageIds": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ImageId": {},
                "Name": {},
                "Description": {},
                "OperatingSystem": {
                  "type": "structure",
                  "members": {
                    "Type": {}
                  }
                },
                "State": {},
                "RequiredTenancy": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspaces": {
      "input": {
        "type": "structure",
        "members": {
          "WorkspaceIds": {
            "shape": "S3j"
          },
          "DirectoryId": {},
          "UserName": {},
          "BundleId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Workspaces": {
            "shape": "S1b"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeWorkspacesConnectionStatus": {
      "input": {
        "type": "structure",
        "members": {
          "WorkspaceIds": {
            "shape": "S3j"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkspacesConnectionStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceId": {},
                "ConnectionState": {},
                "ConnectionStateCheckTimestamp": {
                  "type": "timestamp"
                },
                "LastKnownUserConnectionTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisassociateIpGroups": {
      "input": {
        "type": "structure",
        "required": [
          "DirectoryId",
          "GroupIds"
        ],
        "members": {
          "DirectoryId": {},
          "GroupIds": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ImportWorkspaceImage": {
      "input": {
        "type": "structure",
        "required": [
          "Ec2ImageId",
          "IngestionProcess",
          "ImageName",
          "ImageDescription"
        ],
        "members": {
          "Ec2ImageId": {},
          "IngestionProcess": {},
          "ImageName": {},
          "ImageDescription": {},
          "Tags": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {}
        }
      }
    },
    "ListAvailableManagementCidrRanges": {
      "input": {
        "type": "structure",
        "required": [
          "ManagementCidrRangeConstraint"
        ],
        "members": {
          "ManagementCidrRangeConstraint": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ManagementCidrRanges": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ModifyAccount": {
      "input": {
        "type": "structure",
        "members": {
          "DedicatedTenancySupport": {},
          "DedicatedTenancyManagementCidrRange": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyClientProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "ClientProperties"
        ],
        "members": {
          "ResourceId": {},
          "ClientProperties": {
            "shape": "S2a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyWorkspaceProperties": {
      "input": {
        "type": "structure",
        "required": [
          "WorkspaceId",
          "WorkspaceProperties"
        ],
        "members": {
          "WorkspaceId": {},
          "WorkspaceProperties": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ModifyWorkspaceState": {
      "input": {
        "type": "structure",
        "required": [
          "WorkspaceId",
          "WorkspaceState"
        ],
        "members": {
          "WorkspaceId": {},
          "WorkspaceState": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RebootWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "RebootWorkspaceRequests"
        ],
        "members": {
          "RebootWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WorkspaceId"
              ],
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S4g"
            }
          }
        }
      }
    },
    "RebuildWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "RebuildWorkspaceRequests"
        ],
        "members": {
          "RebuildWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WorkspaceId"
              ],
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S4g"
            }
          }
        }
      }
    },
    "RevokeIpRules": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId",
          "UserRules"
        ],
        "members": {
          "GroupId": {},
          "UserRules": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "StartWorkspaceRequests"
        ],
        "members": {
          "StartWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S4g"
            }
          }
        }
      }
    },
    "StopWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "StopWorkspaceRequests"
        ],
        "members": {
          "StopWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S4g"
            }
          }
        }
      }
    },
    "TerminateWorkspaces": {
      "input": {
        "type": "structure",
        "required": [
          "TerminateWorkspaceRequests"
        ],
        "members": {
          "TerminateWorkspaceRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "WorkspaceId"
              ],
              "members": {
                "WorkspaceId": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedRequests": {
            "type": "list",
            "member": {
              "shape": "S4g"
            }
          }
        }
      }
    },
    "UpdateRulesOfIpGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId",
          "UserRules"
        ],
        "members": {
          "GroupId": {},
          "UserRules": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ipRule": {},
          "ruleDesc": {}
        }
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "DirectoryId",
        "UserName",
        "BundleId"
      ],
      "members": {
        "DirectoryId": {},
        "UserName": {},
        "BundleId": {},
        "VolumeEncryptionKey": {},
        "UserVolumeEncryptionEnabled": {
          "type": "boolean"
        },
        "RootVolumeEncryptionEnabled": {
          "type": "boolean"
        },
        "WorkspaceProperties": {
          "shape": "S10"
        },
        "Tags": {
          "shape": "Sh"
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "RunningMode": {},
        "RunningModeAutoStopTimeoutInMinutes": {
          "type": "integer"
        },
        "RootVolumeSizeGib": {
          "type": "integer"
        },
        "UserVolumeSizeGib": {
          "type": "integer"
        },
        "ComputeTypeName": {}
      }
    },
    "S1b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "WorkspaceId": {},
          "DirectoryId": {},
          "UserName": {},
          "IpAddress": {},
          "State": {},
          "BundleId": {},
          "SubnetId": {},
          "ErrorMessage": {},
          "ErrorCode": {},
          "ComputerName": {},
          "VolumeEncryptionKey": {},
          "UserVolumeEncryptionEnabled": {
            "type": "boolean"
          },
          "RootVolumeEncryptionEnabled": {
            "type": "boolean"
          },
          "WorkspaceProperties": {
            "shape": "S10"
          },
          "ModificationStates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Resource": {},
                "State": {}
              }
            }
          }
        }
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "ReconnectEnabled": {}
      }
    },
    "S3j": {
      "type": "list",
      "member": {}
    },
    "S4g": {
      "type": "structure",
      "members": {
        "WorkspaceId": {},
        "ErrorCode": {},
        "ErrorMessage": {}
      }
    }
  }
}