{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-08-22",
    "endpointPrefix": "acm-pca",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "ACM-PCA",
    "serviceFullName": "AWS Certificate Manager Private Certificate Authority",
    "serviceId": "ACM PCA",
    "signatureVersion": "v4",
    "targetPrefix": "ACMPrivateCA",
    "uid": "acm-pca-2017-08-22"
  },
  "operations": {
    "CreateCertificateAuthority": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityConfiguration",
          "CertificateAuthorityType"
        ],
        "members": {
          "CertificateAuthorityConfiguration": {
            "shape": "S2"
          },
          "RevocationConfiguration": {
            "shape": "Se"
          },
          "CertificateAuthorityType": {},
          "IdempotencyToken": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateAuthorityArn": {}
        }
      },
      "idempotent": true
    },
    "CreateCertificateAuthorityAuditReport": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "S3BucketName",
          "AuditReportResponseFormat"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "S3BucketName": {},
          "AuditReportResponseFormat": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuditReportId": {},
          "S3Key": {}
        }
      },
      "idempotent": true
    },
    "CreatePermission": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "Principal",
          "Actions"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "Principal": {},
          "SourceAccount": {},
          "Actions": {
            "shape": "S10"
          }
        }
      }
    },
    "DeleteCertificateAuthority": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "PermanentDeletionTimeInDays": {
            "type": "integer"
          }
        }
      }
    },
    "DeletePermission": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "Principal"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "Principal": {},
          "SourceAccount": {}
        }
      }
    },
    "DescribeCertificateAuthority": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateAuthority": {
            "shape": "S17"
          }
        }
      }
    },
    "DescribeCertificateAuthorityAuditReport": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "AuditReportId"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "AuditReportId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuditReportStatus": {},
          "S3BucketName": {},
          "S3Key": {},
          "CreatedAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "CertificateArn"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "CertificateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {},
          "CertificateChain": {}
        }
      }
    },
    "GetCertificateAuthorityCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {},
          "CertificateChain": {}
        }
      }
    },
    "GetCertificateAuthorityCsr": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Csr": {}
        }
      }
    },
    "ImportCertificateAuthorityCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "Certificate"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "Certificate": {
            "type": "blob"
          },
          "CertificateChain": {
            "type": "blob"
          }
        }
      }
    },
    "IssueCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "Csr",
          "SigningAlgorithm",
          "Validity"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "Csr": {
            "type": "blob"
          },
          "SigningAlgorithm": {},
          "TemplateArn": {},
          "Validity": {
            "type": "structure",
            "required": [
              "Value",
              "Type"
            ],
            "members": {
              "Value": {
                "type": "long"
              },
              "Type": {}
            }
          },
          "IdempotencyToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateArn": {}
        }
      },
      "idempotent": true
    },
    "ListCertificateAuthorities": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateAuthorities": {
            "type": "list",
            "member": {
              "shape": "S17"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "CertificateAuthorityArn": {},
                "CreatedAt": {
                  "type": "timestamp"
                },
                "Principal": {},
                "SourceAccount": {},
                "Actions": {
                  "shape": "S10"
                },
                "Policy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sm"
          },
          "NextToken": {}
        }
      }
    },
    "RestoreCertificateAuthority": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {}
        }
      }
    },
    "RevokeCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "CertificateSerial",
          "RevocationReason"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "CertificateSerial": {},
          "RevocationReason": {}
        }
      }
    },
    "TagCertificateAuthority": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "Tags"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      }
    },
    "UntagCertificateAuthority": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn",
          "Tags"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "Tags": {
            "shape": "Sm"
          }
        }
      }
    },
    "UpdateCertificateAuthority": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateAuthorityArn"
        ],
        "members": {
          "CertificateAuthorityArn": {},
          "RevocationConfiguration": {
            "shape": "Se"
          },
          "Status": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "KeyAlgorithm",
        "SigningAlgorithm",
        "Subject"
      ],
      "members": {
        "KeyAlgorithm": {},
        "SigningAlgorithm": {},
        "Subject": {
          "type": "structure",
          "members": {
            "Country": {},
            "Organization": {},
            "OrganizationalUnit": {},
            "DistinguishedNameQualifier": {},
            "State": {},
            "CommonName": {},
            "SerialNumber": {},
            "Locality": {},
            "Title": {},
            "Surname": {},
            "GivenName": {},
            "Initials": {},
            "Pseudonym": {},
            "GenerationQualifier": {}
          }
        }
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "CrlConfiguration": {
          "type": "structure",
          "required": [
            "Enabled"
          ],
          "members": {
            "Enabled": {
              "type": "boolean"
            },
            "ExpirationInDays": {
              "type": "integer"
            },
            "CustomCname": {},
            "S3BucketName": {}
          }
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S10": {
      "type": "list",
      "member": {}
    },
    "S17": {
      "type": "structure",
      "members": {
        "Arn": {},
        "CreatedAt": {
          "type": "timestamp"
        },
        "LastStateChangeAt": {
          "type": "timestamp"
        },
        "Type": {},
        "Serial": {},
        "Status": {},
        "NotBefore": {
          "type": "timestamp"
        },
        "NotAfter": {
          "type": "timestamp"
        },
        "FailureReason": {},
        "CertificateAuthorityConfiguration": {
          "shape": "S2"
        },
        "RevocationConfiguration": {
          "shape": "Se"
        },
        "RestorableUntil": {
          "type": "timestamp"
        }
      }
    }
  }
}