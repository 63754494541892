{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-09-24",
    "endpointPrefix": "managedblockchain",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "ManagedBlockchain",
    "serviceFullName": "Amazon Managed Blockchain",
    "serviceId": "ManagedBlockchain",
    "signatureVersion": "v4",
    "signingName": "managedblockchain",
    "uid": "managedblockchain-2018-09-24"
  },
  "operations": {
    "CreateMember": {
      "http": {
        "requestUri": "/networks/{networkId}/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "ClientRequestToken",
          "InvitationId",
          "NetworkId",
          "MemberConfiguration"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "InvitationId": {},
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberConfiguration": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MemberId": {}
        }
      }
    },
    "CreateNetwork": {
      "http": {
        "requestUri": "/networks"
      },
      "input": {
        "type": "structure",
        "required": [
          "ClientRequestToken",
          "Name",
          "Framework",
          "FrameworkVersion",
          "VotingPolicy",
          "MemberConfiguration"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Name": {},
          "Description": {},
          "Framework": {},
          "FrameworkVersion": {},
          "FrameworkConfiguration": {
            "type": "structure",
            "members": {
              "Fabric": {
                "type": "structure",
                "required": [
                  "Edition"
                ],
                "members": {
                  "Edition": {}
                }
              }
            }
          },
          "VotingPolicy": {
            "shape": "Sj"
          },
          "MemberConfiguration": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkId": {},
          "MemberId": {}
        }
      }
    },
    "CreateNode": {
      "http": {
        "requestUri": "/networks/{networkId}/members/{memberId}/nodes"
      },
      "input": {
        "type": "structure",
        "required": [
          "ClientRequestToken",
          "NetworkId",
          "MemberId",
          "NodeConfiguration"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "memberId"
          },
          "NodeConfiguration": {
            "type": "structure",
            "required": [
              "InstanceType",
              "AvailabilityZone"
            ],
            "members": {
              "InstanceType": {},
              "AvailabilityZone": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NodeId": {}
        }
      }
    },
    "CreateProposal": {
      "http": {
        "requestUri": "/networks/{networkId}/proposals"
      },
      "input": {
        "type": "structure",
        "required": [
          "ClientRequestToken",
          "NetworkId",
          "MemberId",
          "Actions"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberId": {},
          "Actions": {
            "shape": "Sv"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProposalId": {}
        }
      }
    },
    "DeleteMember": {
      "http": {
        "method": "DELETE",
        "requestUri": "/networks/{networkId}/members/{memberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "MemberId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "memberId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNode": {
      "http": {
        "method": "DELETE",
        "requestUri": "/networks/{networkId}/members/{memberId}/nodes/{nodeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "MemberId",
          "NodeId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "memberId"
          },
          "NodeId": {
            "location": "uri",
            "locationName": "nodeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetMember": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}/members/{memberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "MemberId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "memberId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Member": {
            "type": "structure",
            "members": {
              "NetworkId": {},
              "Id": {},
              "Name": {},
              "Description": {},
              "FrameworkAttributes": {
                "type": "structure",
                "members": {
                  "Fabric": {
                    "type": "structure",
                    "members": {
                      "AdminUsername": {},
                      "CaEndpoint": {}
                    }
                  }
                }
              },
              "Status": {},
              "CreationDate": {
                "shape": "S1d"
              }
            }
          }
        }
      }
    },
    "GetNetwork": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Network": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Description": {},
              "Framework": {},
              "FrameworkVersion": {},
              "FrameworkAttributes": {
                "type": "structure",
                "members": {
                  "Fabric": {
                    "type": "structure",
                    "members": {
                      "OrderingServiceEndpoint": {},
                      "Edition": {}
                    }
                  }
                }
              },
              "VpcEndpointServiceName": {},
              "VotingPolicy": {
                "shape": "Sj"
              },
              "Status": {},
              "CreationDate": {
                "shape": "S1d"
              }
            }
          }
        }
      }
    },
    "GetNode": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}/members/{memberId}/nodes/{nodeId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "MemberId",
          "NodeId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "memberId"
          },
          "NodeId": {
            "location": "uri",
            "locationName": "nodeId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Node": {
            "type": "structure",
            "members": {
              "NetworkId": {},
              "MemberId": {},
              "Id": {},
              "InstanceType": {},
              "AvailabilityZone": {},
              "FrameworkAttributes": {
                "type": "structure",
                "members": {
                  "Fabric": {
                    "type": "structure",
                    "members": {
                      "PeerEndpoint": {},
                      "PeerEventEndpoint": {}
                    }
                  }
                }
              },
              "Status": {},
              "CreationDate": {
                "shape": "S1d"
              }
            }
          }
        }
      }
    },
    "GetProposal": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}/proposals/{proposalId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "ProposalId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "ProposalId": {
            "location": "uri",
            "locationName": "proposalId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Proposal": {
            "type": "structure",
            "members": {
              "ProposalId": {},
              "NetworkId": {},
              "Description": {},
              "Actions": {
                "shape": "Sv"
              },
              "ProposedByMemberId": {},
              "ProposedByMemberName": {},
              "Status": {},
              "CreationDate": {
                "shape": "S1d"
              },
              "ExpirationDate": {
                "shape": "S1d"
              },
              "YesVoteCount": {
                "type": "integer"
              },
              "NoVoteCount": {
                "type": "integer"
              },
              "OutstandingVoteCount": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "ListInvitations": {
      "http": {
        "method": "GET",
        "requestUri": "/invitations"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invitations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InvitationId": {},
                "CreationDate": {
                  "shape": "S1d"
                },
                "ExpirationDate": {
                  "shape": "S1d"
                },
                "Status": {},
                "NetworkSummary": {
                  "shape": "S22"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}/members"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "Name": {
            "location": "querystring",
            "locationName": "name"
          },
          "Status": {
            "location": "querystring",
            "locationName": "status"
          },
          "IsOwned": {
            "location": "querystring",
            "locationName": "isOwned",
            "type": "boolean"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Members": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Description": {},
                "Status": {},
                "CreationDate": {
                  "shape": "S1d"
                },
                "IsOwned": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListNetworks": {
      "http": {
        "method": "GET",
        "requestUri": "/networks"
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "location": "querystring",
            "locationName": "name"
          },
          "Framework": {
            "location": "querystring",
            "locationName": "framework"
          },
          "Status": {
            "location": "querystring",
            "locationName": "status"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Networks": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListNodes": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}/members/{memberId}/nodes"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "MemberId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MemberId": {
            "location": "uri",
            "locationName": "memberId"
          },
          "Status": {
            "location": "querystring",
            "locationName": "status"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Nodes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Status": {},
                "CreationDate": {
                  "shape": "S1d"
                },
                "AvailabilityZone": {},
                "InstanceType": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProposalVotes": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}/proposals/{proposalId}/votes"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "ProposalId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "ProposalId": {
            "location": "uri",
            "locationName": "proposalId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProposalVotes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Vote": {},
                "MemberName": {},
                "MemberId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProposals": {
      "http": {
        "method": "GET",
        "requestUri": "/networks/{networkId}/proposals"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Proposals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProposalId": {},
                "Description": {},
                "ProposedByMemberId": {},
                "ProposedByMemberName": {},
                "Status": {},
                "CreationDate": {
                  "shape": "S1d"
                },
                "ExpirationDate": {
                  "shape": "S1d"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "RejectInvitation": {
      "http": {
        "method": "DELETE",
        "requestUri": "/invitations/{invitationId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InvitationId"
        ],
        "members": {
          "InvitationId": {
            "location": "uri",
            "locationName": "invitationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "VoteOnProposal": {
      "http": {
        "requestUri": "/networks/{networkId}/proposals/{proposalId}/votes"
      },
      "input": {
        "type": "structure",
        "required": [
          "NetworkId",
          "ProposalId",
          "VoterMemberId",
          "Vote"
        ],
        "members": {
          "NetworkId": {
            "location": "uri",
            "locationName": "networkId"
          },
          "ProposalId": {
            "location": "uri",
            "locationName": "proposalId"
          },
          "VoterMemberId": {},
          "Vote": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "Name",
        "FrameworkConfiguration"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "FrameworkConfiguration": {
          "type": "structure",
          "members": {
            "Fabric": {
              "type": "structure",
              "required": [
                "AdminUsername",
                "AdminPassword"
              ],
              "members": {
                "AdminUsername": {},
                "AdminPassword": {
                  "type": "string",
                  "sensitive": true
                }
              }
            }
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "ApprovalThresholdPolicy": {
          "type": "structure",
          "members": {
            "ThresholdPercentage": {
              "type": "integer"
            },
            "ProposalDurationInHours": {
              "type": "integer"
            },
            "ThresholdComparator": {}
          }
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "Invitations": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Principal"
            ],
            "members": {
              "Principal": {}
            }
          }
        },
        "Removals": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "MemberId"
            ],
            "members": {
              "MemberId": {}
            }
          }
        }
      }
    },
    "S1d": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S22": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "Framework": {},
        "FrameworkVersion": {},
        "Status": {},
        "CreationDate": {
          "shape": "S1d"
        }
      }
    }
  }
}