/* eslint-disable @typescript-eslint/ban-types */
/**
 * Check if we're on the server or client side
 */
const isClient = typeof window !== 'undefined';
/**
 * Check if object is a react ref
 */
const isRef = (obj) => obj !== null &&
    typeof obj === 'object' &&
    Object.prototype.hasOwnProperty.call(obj, 'current');
const toString = Object.prototype.toString;
const isBoolean = (val) => typeof val === 'boolean';
const isFunction = (val) => typeof val === 'function';
const isNumber = (val) => typeof val === 'number';
const isString = (val) => typeof val === 'string';
const isObject = (val) => toString.call(val) === '[object Object]';
const isWindow = (val) => typeof window !== 'undefined' && toString.call(val) === '[object Window]';
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => { };
const rand = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};
const round = (num) => Math.round(num * 1e2) / 1e2;

/**
 * Accepts either a ref object or a dom node and returns a dom node
 *
 * @param target - ref or a dom node
 * @returns dom noe
 */
function unRef(target) {
    const element = isRef(target)
        ? target.current
        : target;
    return element;
}

export { isBoolean, isClient, isFunction, isNumber, isObject, isRef, isString, isWindow, noop, rand, round, unRef };
