{
  "metadata": {
    "apiVersion": "2018-11-14",
    "endpointPrefix": "kafka",
    "signingName": "kafka",
    "serviceFullName": "Managed Streaming for Kafka",
    "serviceAbbreviation": "Kafka",
    "serviceId": "Kafka",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "kafka-2018-11-14",
    "signatureVersion": "v4"
  },
  "operations": {
    "CreateCluster": {
      "http": {
        "requestUri": "/v1/clusters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "BrokerNodeGroupInfo": {
            "shape": "S2",
            "locationName": "brokerNodeGroupInfo"
          },
          "ClientAuthentication": {
            "shape": "Sa",
            "locationName": "clientAuthentication"
          },
          "ClusterName": {
            "locationName": "clusterName"
          },
          "ConfigurationInfo": {
            "shape": "Sd",
            "locationName": "configurationInfo"
          },
          "EncryptionInfo": {
            "shape": "Sf",
            "locationName": "encryptionInfo"
          },
          "EnhancedMonitoring": {
            "locationName": "enhancedMonitoring"
          },
          "KafkaVersion": {
            "locationName": "kafkaVersion"
          },
          "NumberOfBrokerNodes": {
            "locationName": "numberOfBrokerNodes",
            "type": "integer"
          },
          "Tags": {
            "shape": "Sn",
            "locationName": "tags"
          }
        },
        "required": [
          "BrokerNodeGroupInfo",
          "KafkaVersion",
          "NumberOfBrokerNodes",
          "ClusterName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterName": {
            "locationName": "clusterName"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "CreateConfiguration": {
      "http": {
        "requestUri": "/v1/configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "KafkaVersions": {
            "shape": "S4",
            "locationName": "kafkaVersions"
          },
          "Name": {
            "locationName": "name"
          },
          "ServerProperties": {
            "locationName": "serverProperties",
            "type": "blob"
          }
        },
        "required": [
          "ServerProperties",
          "KafkaVersions",
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CreationTime": {
            "shape": "St",
            "locationName": "creationTime"
          },
          "LatestRevision": {
            "shape": "Su",
            "locationName": "latestRevision"
          },
          "Name": {
            "locationName": "name"
          }
        }
      }
    },
    "DeleteCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/clusters/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "location": "querystring",
            "locationName": "currentVersion"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "DescribeCluster": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterInfo": {
            "shape": "Sz",
            "locationName": "clusterInfo"
          }
        }
      }
    },
    "DescribeClusterOperation": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/operations/{clusterOperationArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterOperationArn": {
            "location": "uri",
            "locationName": "clusterOperationArn"
          }
        },
        "required": [
          "ClusterOperationArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterOperationInfo": {
            "shape": "S14",
            "locationName": "clusterOperationInfo"
          }
        }
      }
    },
    "DescribeConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CreationTime": {
            "shape": "St",
            "locationName": "creationTime"
          },
          "Description": {
            "locationName": "description"
          },
          "KafkaVersions": {
            "shape": "S4",
            "locationName": "kafkaVersions"
          },
          "LatestRevision": {
            "shape": "Su",
            "locationName": "latestRevision"
          },
          "Name": {
            "locationName": "name"
          }
        }
      }
    },
    "DescribeConfigurationRevision": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations/{arn}/revisions/{revision}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          },
          "Revision": {
            "location": "uri",
            "locationName": "revision",
            "type": "long"
          }
        },
        "required": [
          "Revision",
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CreationTime": {
            "shape": "St",
            "locationName": "creationTime"
          },
          "Description": {
            "locationName": "description"
          },
          "Revision": {
            "locationName": "revision",
            "type": "long"
          },
          "ServerProperties": {
            "locationName": "serverProperties",
            "type": "blob"
          }
        }
      }
    },
    "GetBootstrapBrokers": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}/bootstrap-brokers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "BootstrapBrokerString": {
            "locationName": "bootstrapBrokerString"
          },
          "BootstrapBrokerStringTls": {
            "locationName": "bootstrapBrokerStringTls"
          }
        }
      }
    },
    "ListClusterOperations": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}/operations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterOperationInfoList": {
            "locationName": "clusterOperationInfoList",
            "type": "list",
            "member": {
              "shape": "S14"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListClusters": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterNameFilter": {
            "location": "querystring",
            "locationName": "clusterNameFilter"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterInfoList": {
            "locationName": "clusterInfoList",
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListConfigurationRevisions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations/{arn}/revisions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "arn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Revisions": {
            "locationName": "revisions",
            "type": "list",
            "member": {
              "shape": "Su"
            }
          }
        }
      }
    },
    "ListConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/configurations",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configurations": {
            "locationName": "configurations",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "CreationTime": {
                  "shape": "St",
                  "locationName": "creationTime"
                },
                "Description": {
                  "locationName": "description"
                },
                "KafkaVersions": {
                  "shape": "S4",
                  "locationName": "kafkaVersions"
                },
                "LatestRevision": {
                  "shape": "Su",
                  "locationName": "latestRevision"
                },
                "Name": {
                  "locationName": "name"
                }
              },
              "required": [
                "Description",
                "LatestRevision",
                "CreationTime",
                "KafkaVersions",
                "Arn",
                "Name"
              ]
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListNodes": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/clusters/{clusterArn}/nodes",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "required": [
          "ClusterArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "NodeInfoList": {
            "locationName": "nodeInfoList",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AddedToClusterTime": {
                  "locationName": "addedToClusterTime"
                },
                "BrokerNodeInfo": {
                  "locationName": "brokerNodeInfo",
                  "type": "structure",
                  "members": {
                    "AttachedENIId": {
                      "locationName": "attachedENIId"
                    },
                    "BrokerId": {
                      "locationName": "brokerId",
                      "type": "double"
                    },
                    "ClientSubnet": {
                      "locationName": "clientSubnet"
                    },
                    "ClientVpcIpAddress": {
                      "locationName": "clientVpcIpAddress"
                    },
                    "CurrentBrokerSoftwareInfo": {
                      "shape": "S10",
                      "locationName": "currentBrokerSoftwareInfo"
                    },
                    "Endpoints": {
                      "shape": "S4",
                      "locationName": "endpoints"
                    }
                  }
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "NodeARN": {
                  "locationName": "nodeARN"
                },
                "NodeType": {
                  "locationName": "nodeType"
                },
                "ZookeeperNodeInfo": {
                  "locationName": "zookeeperNodeInfo",
                  "type": "structure",
                  "members": {
                    "AttachedENIId": {
                      "locationName": "attachedENIId"
                    },
                    "ClientVpcIpAddress": {
                      "locationName": "clientVpcIpAddress"
                    },
                    "Endpoints": {
                      "shape": "S4",
                      "locationName": "endpoints"
                    },
                    "ZookeeperId": {
                      "locationName": "zookeeperId",
                      "type": "double"
                    },
                    "ZookeeperVersion": {
                      "locationName": "zookeeperVersion"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        },
        "required": [
          "ResourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "Sn",
            "locationName": "tags"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "Tags": {
            "shape": "Sn",
            "locationName": "tags"
          }
        },
        "required": [
          "ResourceArn",
          "Tags"
        ]
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "TagKeys": {
            "shape": "S4",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        },
        "required": [
          "TagKeys",
          "ResourceArn"
        ]
      }
    },
    "UpdateBrokerStorage": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/nodes/storage",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          },
          "TargetBrokerEBSVolumeInfo": {
            "shape": "S17",
            "locationName": "targetBrokerEBSVolumeInfo"
          }
        },
        "required": [
          "ClusterArn",
          "TargetBrokerEBSVolumeInfo",
          "CurrentVersion"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    },
    "UpdateClusterConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/clusters/{clusterArn}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "location": "uri",
            "locationName": "clusterArn"
          },
          "ConfigurationInfo": {
            "shape": "Sd",
            "locationName": "configurationInfo"
          },
          "CurrentVersion": {
            "locationName": "currentVersion"
          }
        },
        "required": [
          "ClusterArn",
          "CurrentVersion",
          "ConfigurationInfo"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterArn": {
            "locationName": "clusterArn"
          },
          "ClusterOperationArn": {
            "locationName": "clusterOperationArn"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "BrokerAZDistribution": {
          "locationName": "brokerAZDistribution"
        },
        "ClientSubnets": {
          "shape": "S4",
          "locationName": "clientSubnets"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "SecurityGroups": {
          "shape": "S4",
          "locationName": "securityGroups"
        },
        "StorageInfo": {
          "locationName": "storageInfo",
          "type": "structure",
          "members": {
            "EbsStorageInfo": {
              "locationName": "ebsStorageInfo",
              "type": "structure",
              "members": {
                "VolumeSize": {
                  "locationName": "volumeSize",
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "required": [
        "ClientSubnets",
        "InstanceType"
      ]
    },
    "S4": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "structure",
      "members": {
        "Tls": {
          "locationName": "tls",
          "type": "structure",
          "members": {
            "CertificateAuthorityArnList": {
              "shape": "S4",
              "locationName": "certificateAuthorityArnList"
            }
          }
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Revision": {
          "locationName": "revision",
          "type": "long"
        }
      },
      "required": [
        "Revision",
        "Arn"
      ]
    },
    "Sf": {
      "type": "structure",
      "members": {
        "EncryptionAtRest": {
          "locationName": "encryptionAtRest",
          "type": "structure",
          "members": {
            "DataVolumeKMSKeyId": {
              "locationName": "dataVolumeKMSKeyId"
            }
          },
          "required": [
            "DataVolumeKMSKeyId"
          ]
        },
        "EncryptionInTransit": {
          "locationName": "encryptionInTransit",
          "type": "structure",
          "members": {
            "ClientBroker": {
              "locationName": "clientBroker"
            },
            "InCluster": {
              "locationName": "inCluster",
              "type": "boolean"
            }
          }
        }
      }
    },
    "Sn": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "St": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "Su": {
      "type": "structure",
      "members": {
        "CreationTime": {
          "shape": "St",
          "locationName": "creationTime"
        },
        "Description": {
          "locationName": "description"
        },
        "Revision": {
          "locationName": "revision",
          "type": "long"
        }
      },
      "required": [
        "Revision",
        "CreationTime"
      ]
    },
    "Sz": {
      "type": "structure",
      "members": {
        "ActiveOperationArn": {
          "locationName": "activeOperationArn"
        },
        "BrokerNodeGroupInfo": {
          "shape": "S2",
          "locationName": "brokerNodeGroupInfo"
        },
        "ClientAuthentication": {
          "shape": "Sa",
          "locationName": "clientAuthentication"
        },
        "ClusterArn": {
          "locationName": "clusterArn"
        },
        "ClusterName": {
          "locationName": "clusterName"
        },
        "CreationTime": {
          "shape": "St",
          "locationName": "creationTime"
        },
        "CurrentBrokerSoftwareInfo": {
          "shape": "S10",
          "locationName": "currentBrokerSoftwareInfo"
        },
        "CurrentVersion": {
          "locationName": "currentVersion"
        },
        "EncryptionInfo": {
          "shape": "Sf",
          "locationName": "encryptionInfo"
        },
        "EnhancedMonitoring": {
          "locationName": "enhancedMonitoring"
        },
        "NumberOfBrokerNodes": {
          "locationName": "numberOfBrokerNodes",
          "type": "integer"
        },
        "State": {
          "locationName": "state"
        },
        "Tags": {
          "shape": "Sn",
          "locationName": "tags"
        },
        "ZookeeperConnectString": {
          "locationName": "zookeeperConnectString"
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "ConfigurationArn": {
          "locationName": "configurationArn"
        },
        "ConfigurationRevision": {
          "locationName": "configurationRevision",
          "type": "long"
        },
        "KafkaVersion": {
          "locationName": "kafkaVersion"
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "ClientRequestId": {
          "locationName": "clientRequestId"
        },
        "ClusterArn": {
          "locationName": "clusterArn"
        },
        "CreationTime": {
          "shape": "St",
          "locationName": "creationTime"
        },
        "EndTime": {
          "shape": "St",
          "locationName": "endTime"
        },
        "ErrorInfo": {
          "locationName": "errorInfo",
          "type": "structure",
          "members": {
            "ErrorCode": {
              "locationName": "errorCode"
            },
            "ErrorString": {
              "locationName": "errorString"
            }
          }
        },
        "OperationArn": {
          "locationName": "operationArn"
        },
        "OperationState": {
          "locationName": "operationState"
        },
        "OperationType": {
          "locationName": "operationType"
        },
        "SourceClusterInfo": {
          "shape": "S16",
          "locationName": "sourceClusterInfo"
        },
        "TargetClusterInfo": {
          "shape": "S16",
          "locationName": "targetClusterInfo"
        }
      }
    },
    "S16": {
      "type": "structure",
      "members": {
        "BrokerEBSVolumeInfo": {
          "shape": "S17",
          "locationName": "brokerEBSVolumeInfo"
        },
        "ConfigurationInfo": {
          "shape": "Sd",
          "locationName": "configurationInfo"
        },
        "NumberOfBrokerNodes": {
          "locationName": "numberOfBrokerNodes",
          "type": "integer"
        }
      }
    },
    "S17": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "KafkaBrokerNodeId": {
            "locationName": "kafkaBrokerNodeId"
          },
          "VolumeSizeGB": {
            "locationName": "volumeSizeGB",
            "type": "integer"
          }
        },
        "required": [
          "VolumeSizeGB",
          "KafkaBrokerNodeId"
        ]
      }
    }
  }
}