{
  "pagination": {
    "DescribeJobFlows": {
      "result_key": "JobFlows"
    },
    "ListBootstrapActions": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "BootstrapActions"
    },
    "ListClusters": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "Clusters"
    },
    "ListInstanceFleets": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "InstanceFleets"
    },
    "ListInstanceGroups": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "InstanceGroups"
    },
    "ListInstances": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "Instances"
    },
    "ListSecurityConfigurations": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "SecurityConfigurations"
    },
    "ListSteps": {
      "input_token": "Marker",
      "output_token": "Marker",
      "result_key": "Steps"
    }
  }
}