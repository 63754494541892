{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-05-13",
    "endpointPrefix": "runtime.sagemaker",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon SageMaker Runtime",
    "serviceId": "SageMaker Runtime",
    "signatureVersion": "v4",
    "signingName": "sagemaker",
    "uid": "runtime.sagemaker-2017-05-13"
  },
  "operations": {
    "InvokeEndpoint": {
      "http": {
        "requestUri": "/endpoints/{EndpointName}/invocations"
      },
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "Body"
        ],
        "members": {
          "EndpointName": {
            "location": "uri",
            "locationName": "EndpointName"
          },
          "Body": {
            "shape": "S3"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "Accept": {
            "location": "header",
            "locationName": "Accept"
          },
          "CustomAttributes": {
            "shape": "S5",
            "location": "header",
            "locationName": "X-Amzn-SageMaker-Custom-Attributes"
          }
        },
        "payload": "Body"
      },
      "output": {
        "type": "structure",
        "required": [
          "Body"
        ],
        "members": {
          "Body": {
            "shape": "S3"
          },
          "ContentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "InvokedProductionVariant": {
            "location": "header",
            "locationName": "x-Amzn-Invoked-Production-Variant"
          },
          "CustomAttributes": {
            "shape": "S5",
            "location": "header",
            "locationName": "X-Amzn-SageMaker-Custom-Attributes"
          }
        },
        "payload": "Body"
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "blob",
      "sensitive": true
    },
    "S5": {
      "type": "string",
      "sensitive": true
    }
  }
}