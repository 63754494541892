const log = (msg: string): void => console.debug(msg);

const promiseTimeout = (
  ms: number,
  promise: Promise<any>,
  eventCallback: Function,
): Promise<any> => {
  let id: any;
  const timeout = new Promise((resolve) => {
    id = setTimeout(() => {
      log('Forced resolve');
      resolve(eventCallback());
    }, ms);
  });

  return Promise.race([
    promise,
    timeout,
  ]).then((result) => {
    clearTimeout(id);
    return result;
  });
};

export default {
  log,
  promiseTimeout,
};
