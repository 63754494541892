{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-01",
    "endpointPrefix": "chime",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Chime",
    "serviceId": "Chime",
    "signatureVersion": "v4",
    "uid": "chime-2018-05-01"
  },
  "operations": {
    "AssociatePhoneNumberWithUser": {
      "http": {
        "requestUri": "/accounts/{accountId}/users/{userId}?operation=associate-phone-number",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId",
          "E164PhoneNumber"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          },
          "E164PhoneNumber": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociatePhoneNumbersWithVoiceConnector": {
      "http": {
        "requestUri": "/voice-connectors/{voiceConnectorId}?operation=associate-phone-numbers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          },
          "E164PhoneNumbers": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberErrors": {
            "shape": "S9"
          }
        }
      }
    },
    "BatchDeletePhoneNumber": {
      "http": {
        "requestUri": "/phone-numbers?operation=batch-delete",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberIds"
        ],
        "members": {
          "PhoneNumberIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberErrors": {
            "shape": "S9"
          }
        }
      }
    },
    "BatchSuspendUser": {
      "http": {
        "requestUri": "/accounts/{accountId}/users?operation=suspend",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserIdList"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserIdList": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserErrors": {
            "shape": "Si"
          }
        }
      }
    },
    "BatchUnsuspendUser": {
      "http": {
        "requestUri": "/accounts/{accountId}/users?operation=unsuspend",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserIdList"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserIdList": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserErrors": {
            "shape": "Si"
          }
        }
      }
    },
    "BatchUpdatePhoneNumber": {
      "http": {
        "requestUri": "/phone-numbers?operation=batch-update",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UpdatePhoneNumberRequestItems"
        ],
        "members": {
          "UpdatePhoneNumberRequestItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "PhoneNumberId"
              ],
              "members": {
                "PhoneNumberId": {},
                "ProductType": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberErrors": {
            "shape": "S9"
          }
        }
      }
    },
    "BatchUpdateUser": {
      "http": {
        "requestUri": "/accounts/{accountId}/users",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UpdateUserRequestItems"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UpdateUserRequestItems": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "UserId"
              ],
              "members": {
                "UserId": {},
                "LicenseType": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserErrors": {
            "shape": "Si"
          }
        }
      }
    },
    "CreateAccount": {
      "http": {
        "requestUri": "/accounts",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Account": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateBot": {
      "http": {
        "requestUri": "/accounts/{accountId}/bots",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "DisplayName",
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "DisplayName": {
            "shape": "S14"
          },
          "Domain": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bot": {
            "shape": "S16"
          }
        }
      }
    },
    "CreatePhoneNumberOrder": {
      "http": {
        "requestUri": "/phone-number-orders",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "ProductType",
          "E164PhoneNumbers"
        ],
        "members": {
          "ProductType": {},
          "E164PhoneNumbers": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberOrder": {
            "shape": "S1b"
          }
        }
      }
    },
    "CreateVoiceConnector": {
      "http": {
        "requestUri": "/voice-connectors",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RequireEncryption"
        ],
        "members": {
          "Name": {},
          "RequireEncryption": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceConnector": {
            "shape": "S1l"
          }
        }
      }
    },
    "DeleteAccount": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{accountId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEventsConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts/{accountId}/bots/{botId}/events-configuration",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "BotId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "BotId": {
            "location": "uri",
            "locationName": "botId"
          }
        }
      }
    },
    "DeletePhoneNumber": {
      "http": {
        "method": "DELETE",
        "requestUri": "/phone-numbers/{phoneNumberId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "phoneNumberId"
          }
        }
      }
    },
    "DeleteVoiceConnector": {
      "http": {
        "method": "DELETE",
        "requestUri": "/voice-connectors/{voiceConnectorId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      }
    },
    "DeleteVoiceConnectorOrigination": {
      "http": {
        "method": "DELETE",
        "requestUri": "/voice-connectors/{voiceConnectorId}/origination",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      }
    },
    "DeleteVoiceConnectorTermination": {
      "http": {
        "method": "DELETE",
        "requestUri": "/voice-connectors/{voiceConnectorId}/termination",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      }
    },
    "DeleteVoiceConnectorTerminationCredentials": {
      "http": {
        "requestUri": "/voice-connectors/{voiceConnectorId}/termination/credentials?operation=delete",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          },
          "Usernames": {
            "shape": "S1u"
          }
        }
      }
    },
    "DisassociatePhoneNumberFromUser": {
      "http": {
        "requestUri": "/accounts/{accountId}/users/{userId}?operation=disassociate-phone-number",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociatePhoneNumbersFromVoiceConnector": {
      "http": {
        "requestUri": "/voice-connectors/{voiceConnectorId}?operation=disassociate-phone-numbers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          },
          "E164PhoneNumbers": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberErrors": {
            "shape": "S9"
          }
        }
      }
    },
    "GetAccount": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Account": {
            "shape": "Sz"
          }
        }
      }
    },
    "GetAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}/settings"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountSettings": {
            "shape": "S23"
          }
        }
      }
    },
    "GetBot": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}/bots/{botId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "BotId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "BotId": {
            "location": "uri",
            "locationName": "botId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bot": {
            "shape": "S16"
          }
        }
      }
    },
    "GetEventsConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}/bots/{botId}/events-configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "BotId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "BotId": {
            "location": "uri",
            "locationName": "botId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventsConfiguration": {
            "shape": "S28"
          }
        }
      }
    },
    "GetGlobalSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/settings",
        "responseCode": 200
      },
      "output": {
        "type": "structure",
        "members": {
          "BusinessCalling": {
            "shape": "S2a"
          },
          "VoiceConnector": {
            "shape": "S2b"
          }
        }
      }
    },
    "GetPhoneNumber": {
      "http": {
        "method": "GET",
        "requestUri": "/phone-numbers/{phoneNumberId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "phoneNumberId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumber": {
            "shape": "S2e"
          }
        }
      }
    },
    "GetPhoneNumberOrder": {
      "http": {
        "method": "GET",
        "requestUri": "/phone-number-orders/{phoneNumberOrderId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberOrderId"
        ],
        "members": {
          "PhoneNumberOrderId": {
            "location": "uri",
            "locationName": "phoneNumberOrderId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberOrder": {
            "shape": "S1b"
          }
        }
      }
    },
    "GetUser": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}/users/{userId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S2p"
          }
        }
      }
    },
    "GetUserSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}/users/{userId}/settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserSettings": {
            "shape": "S2v"
          }
        }
      }
    },
    "GetVoiceConnector": {
      "http": {
        "method": "GET",
        "requestUri": "/voice-connectors/{voiceConnectorId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceConnector": {
            "shape": "S1l"
          }
        }
      }
    },
    "GetVoiceConnectorOrigination": {
      "http": {
        "method": "GET",
        "requestUri": "/voice-connectors/{voiceConnectorId}/origination",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Origination": {
            "shape": "S31"
          }
        }
      }
    },
    "GetVoiceConnectorTermination": {
      "http": {
        "method": "GET",
        "requestUri": "/voice-connectors/{voiceConnectorId}/termination",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Termination": {
            "shape": "S3a"
          }
        }
      }
    },
    "GetVoiceConnectorTerminationHealth": {
      "http": {
        "method": "GET",
        "requestUri": "/voice-connectors/{voiceConnectorId}/termination/health",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminationHealth": {
            "type": "structure",
            "members": {
              "Timestamp": {
                "shape": "S11"
              },
              "Source": {}
            }
          }
        }
      }
    },
    "InviteUsers": {
      "http": {
        "requestUri": "/accounts/{accountId}/users?operation=add",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserEmailList"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserEmailList": {
            "type": "list",
            "member": {
              "shape": "S2q"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invites": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "InviteId": {},
                "Status": {},
                "EmailAddress": {
                  "shape": "S2q"
                },
                "EmailStatus": {}
              }
            }
          }
        }
      }
    },
    "ListAccounts": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "location": "querystring",
            "locationName": "name"
          },
          "UserEmail": {
            "shape": "S2q",
            "location": "querystring",
            "locationName": "user-email"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accounts": {
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListBots": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}/bots",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bots": {
            "type": "list",
            "member": {
              "shape": "S16"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPhoneNumberOrders": {
      "http": {
        "method": "GET",
        "requestUri": "/phone-number-orders",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumberOrders": {
            "type": "list",
            "member": {
              "shape": "S1b"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPhoneNumbers": {
      "http": {
        "method": "GET",
        "requestUri": "/phone-numbers"
      },
      "input": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "querystring",
            "locationName": "status"
          },
          "ProductType": {
            "location": "querystring",
            "locationName": "product-type"
          },
          "FilterName": {
            "location": "querystring",
            "locationName": "filter-name"
          },
          "FilterValue": {
            "location": "querystring",
            "locationName": "filter-value"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumbers": {
            "type": "list",
            "member": {
              "shape": "S2e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts/{accountId}/users",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserEmail": {
            "shape": "S2q",
            "location": "querystring",
            "locationName": "user-email"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "shape": "S2p"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListVoiceConnectorTerminationCredentials": {
      "http": {
        "method": "GET",
        "requestUri": "/voice-connectors/{voiceConnectorId}/termination/credentials",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Usernames": {
            "shape": "S1u"
          }
        }
      }
    },
    "ListVoiceConnectors": {
      "http": {
        "method": "GET",
        "requestUri": "/voice-connectors",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceConnectors": {
            "type": "list",
            "member": {
              "shape": "S1l"
            }
          },
          "NextToken": {}
        }
      }
    },
    "LogoutUser": {
      "http": {
        "requestUri": "/accounts/{accountId}/users/{userId}?operation=logout",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "PutEventsConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{accountId}/bots/{botId}/events-configuration",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "BotId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "BotId": {
            "location": "uri",
            "locationName": "botId"
          },
          "OutboundEventsHTTPSEndpoint": {
            "shape": "S14"
          },
          "LambdaFunctionArn": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventsConfiguration": {
            "shape": "S28"
          }
        }
      }
    },
    "PutVoiceConnectorOrigination": {
      "http": {
        "method": "PUT",
        "requestUri": "/voice-connectors/{voiceConnectorId}/origination",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId",
          "Origination"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          },
          "Origination": {
            "shape": "S31"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Origination": {
            "shape": "S31"
          }
        }
      }
    },
    "PutVoiceConnectorTermination": {
      "http": {
        "method": "PUT",
        "requestUri": "/voice-connectors/{voiceConnectorId}/termination",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId",
          "Termination"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          },
          "Termination": {
            "shape": "S3a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Termination": {
            "shape": "S3a"
          }
        }
      }
    },
    "PutVoiceConnectorTerminationCredentials": {
      "http": {
        "requestUri": "/voice-connectors/{voiceConnectorId}/termination/credentials?operation=put",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          },
          "Credentials": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Username": {
                  "shape": "S14"
                },
                "Password": {
                  "shape": "S14"
                }
              }
            }
          }
        }
      }
    },
    "RegenerateSecurityToken": {
      "http": {
        "requestUri": "/accounts/{accountId}/bots/{botId}?operation=regenerate-security-token",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "BotId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "BotId": {
            "location": "uri",
            "locationName": "botId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bot": {
            "shape": "S16"
          }
        }
      }
    },
    "ResetPersonalPIN": {
      "http": {
        "requestUri": "/accounts/{accountId}/users/{userId}?operation=reset-personal-pin",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S2p"
          }
        }
      }
    },
    "RestorePhoneNumber": {
      "http": {
        "requestUri": "/phone-numbers/{phoneNumberId}?operation=restore",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "phoneNumberId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumber": {
            "shape": "S2e"
          }
        }
      }
    },
    "SearchAvailablePhoneNumbers": {
      "http": {
        "method": "GET",
        "requestUri": "/search?type=phone-numbers"
      },
      "input": {
        "type": "structure",
        "members": {
          "AreaCode": {
            "location": "querystring",
            "locationName": "area-code"
          },
          "City": {
            "location": "querystring",
            "locationName": "city"
          },
          "Country": {
            "location": "querystring",
            "locationName": "country"
          },
          "State": {
            "location": "querystring",
            "locationName": "state"
          },
          "TollFreePrefix": {
            "location": "querystring",
            "locationName": "toll-free-prefix"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "max-results",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "E164PhoneNumbers": {
            "shape": "S7"
          }
        }
      }
    },
    "UpdateAccount": {
      "http": {
        "requestUri": "/accounts/{accountId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Account": {
            "shape": "Sz"
          }
        }
      }
    },
    "UpdateAccountSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{accountId}/settings",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "AccountSettings"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "AccountSettings": {
            "shape": "S23"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateBot": {
      "http": {
        "requestUri": "/accounts/{accountId}/bots/{botId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "BotId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "BotId": {
            "location": "uri",
            "locationName": "botId"
          },
          "Disabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Bot": {
            "shape": "S16"
          }
        }
      }
    },
    "UpdateGlobalSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/settings",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "BusinessCalling",
          "VoiceConnector"
        ],
        "members": {
          "BusinessCalling": {
            "shape": "S2a"
          },
          "VoiceConnector": {
            "shape": "S2b"
          }
        }
      }
    },
    "UpdatePhoneNumber": {
      "http": {
        "requestUri": "/phone-numbers/{phoneNumberId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneNumberId"
        ],
        "members": {
          "PhoneNumberId": {
            "location": "uri",
            "locationName": "phoneNumberId"
          },
          "ProductType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PhoneNumber": {
            "shape": "S2e"
          }
        }
      }
    },
    "UpdateUser": {
      "http": {
        "requestUri": "/accounts/{accountId}/users/{userId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          },
          "LicenseType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "S2p"
          }
        }
      }
    },
    "UpdateUserSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/accounts/{accountId}/users/{userId}/settings",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "UserId",
          "UserSettings"
        ],
        "members": {
          "AccountId": {
            "location": "uri",
            "locationName": "accountId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "userId"
          },
          "UserSettings": {
            "shape": "S2v"
          }
        }
      }
    },
    "UpdateVoiceConnector": {
      "http": {
        "method": "PUT",
        "requestUri": "/voice-connectors/{voiceConnectorId}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "VoiceConnectorId",
          "Name",
          "RequireEncryption"
        ],
        "members": {
          "VoiceConnectorId": {
            "location": "uri",
            "locationName": "voiceConnectorId"
          },
          "Name": {},
          "RequireEncryption": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceConnector": {
            "shape": "S1l"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "S7": {
      "type": "list",
      "member": {
        "shape": "S3"
      }
    },
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PhoneNumberId": {},
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "UserId": {},
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "Sz": {
      "type": "structure",
      "required": [
        "AwsAccountId",
        "AccountId",
        "Name"
      ],
      "members": {
        "AwsAccountId": {},
        "AccountId": {},
        "Name": {},
        "AccountType": {},
        "CreatedTimestamp": {
          "shape": "S11"
        },
        "DefaultLicense": {},
        "SupportedLicenses": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S11": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S14": {
      "type": "string",
      "sensitive": true
    },
    "S16": {
      "type": "structure",
      "members": {
        "BotId": {},
        "UserId": {},
        "DisplayName": {
          "shape": "S14"
        },
        "BotType": {},
        "Disabled": {
          "type": "boolean"
        },
        "CreatedTimestamp": {
          "shape": "S11"
        },
        "UpdatedTimestamp": {
          "shape": "S11"
        },
        "BotEmail": {
          "shape": "S14"
        },
        "SecurityToken": {
          "shape": "S14"
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "PhoneNumberOrderId": {},
        "ProductType": {},
        "Status": {},
        "OrderedPhoneNumbers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "E164PhoneNumber": {
                "shape": "S3"
              },
              "Status": {}
            }
          }
        },
        "CreatedTimestamp": {
          "shape": "S11"
        },
        "UpdatedTimestamp": {
          "shape": "S11"
        }
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "VoiceConnectorId": {},
        "Name": {},
        "OutboundHostName": {},
        "RequireEncryption": {
          "type": "boolean"
        },
        "CreatedTimestamp": {
          "shape": "S11"
        },
        "UpdatedTimestamp": {
          "shape": "S11"
        }
      }
    },
    "S1u": {
      "type": "list",
      "member": {
        "shape": "S14"
      }
    },
    "S23": {
      "type": "structure",
      "members": {
        "DisableRemoteControl": {
          "type": "boolean"
        },
        "EnableDialOut": {
          "type": "boolean"
        }
      }
    },
    "S28": {
      "type": "structure",
      "members": {
        "BotId": {},
        "OutboundEventsHTTPSEndpoint": {
          "shape": "S14"
        },
        "LambdaFunctionArn": {
          "shape": "S14"
        }
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "CdrBucket": {}
      }
    },
    "S2b": {
      "type": "structure",
      "members": {
        "CdrBucket": {}
      }
    },
    "S2e": {
      "type": "structure",
      "members": {
        "PhoneNumberId": {},
        "E164PhoneNumber": {
          "shape": "S3"
        },
        "Type": {},
        "ProductType": {},
        "Status": {},
        "Capabilities": {
          "type": "structure",
          "members": {
            "InboundCall": {
              "type": "boolean"
            },
            "OutboundCall": {
              "type": "boolean"
            },
            "InboundSMS": {
              "type": "boolean"
            },
            "OutboundSMS": {
              "type": "boolean"
            },
            "InboundMMS": {
              "type": "boolean"
            },
            "OutboundMMS": {
              "type": "boolean"
            }
          }
        },
        "Associations": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Value": {},
              "Name": {},
              "AssociatedTimestamp": {
                "shape": "S11"
              }
            }
          }
        },
        "CreatedTimestamp": {
          "shape": "S11"
        },
        "UpdatedTimestamp": {
          "shape": "S11"
        },
        "DeletionTimestamp": {
          "shape": "S11"
        }
      }
    },
    "S2p": {
      "type": "structure",
      "required": [
        "UserId"
      ],
      "members": {
        "UserId": {},
        "AccountId": {},
        "PrimaryEmail": {
          "shape": "S2q"
        },
        "PrimaryProvisionedNumber": {
          "shape": "S14"
        },
        "DisplayName": {
          "shape": "S14"
        },
        "LicenseType": {},
        "UserRegistrationStatus": {},
        "UserInvitationStatus": {},
        "RegisteredOn": {
          "shape": "S11"
        },
        "InvitedOn": {
          "shape": "S11"
        },
        "PersonalPIN": {}
      }
    },
    "S2q": {
      "type": "string",
      "sensitive": true
    },
    "S2v": {
      "type": "structure",
      "required": [
        "Telephony"
      ],
      "members": {
        "Telephony": {
          "type": "structure",
          "required": [
            "InboundCalling",
            "OutboundCalling",
            "SMS"
          ],
          "members": {
            "InboundCalling": {
              "type": "boolean"
            },
            "OutboundCalling": {
              "type": "boolean"
            },
            "SMS": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "Routes": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Host": {},
              "Port": {
                "type": "integer"
              },
              "Protocol": {},
              "Priority": {
                "type": "integer"
              },
              "Weight": {
                "type": "integer"
              }
            }
          }
        },
        "Disabled": {
          "type": "boolean"
        }
      }
    },
    "S3a": {
      "type": "structure",
      "members": {
        "CpsLimit": {
          "type": "integer"
        },
        "DefaultPhoneNumber": {
          "shape": "S3"
        },
        "CallingRegions": {
          "type": "list",
          "member": {}
        },
        "CidrAllowedList": {
          "type": "list",
          "member": {}
        },
        "Disabled": {
          "type": "boolean"
        }
      }
    }
  }
}