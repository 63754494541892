import { useQueryParams } from '@mineko/react-toolkit';

type InitialData = {
  salutation?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  gender?: string;
  requestedUri?: string;
  code?: string;
  token?: string;
};

const initialQueryParams = Object.fromEntries(new URLSearchParams(location.search.substring(1)));

export function useInitialData(): InitialData {
  const params = useQueryParams();
  const userMetaData = JSON.parse(localStorage.getItem('user-meta-data')!) || {};
  return {
    ...initialQueryParams,
    ...userMetaData,
    ...params,
  };
}
