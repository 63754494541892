{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-27",
    "endpointPrefix": "comprehend",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Comprehend",
    "serviceId": "Comprehend",
    "signatureVersion": "v4",
    "signingName": "comprehend",
    "targetPrefix": "Comprehend_20171127",
    "uid": "comprehend-2017-11-27"
  },
  "operations": {
    "BatchDetectDominantLanguage": {
      "input": {
        "type": "structure",
        "required": [
          "TextList"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Languages": {
                  "shape": "S8"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectEntities": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Entities": {
                  "shape": "Si"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectKeyPhrases": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "KeyPhrases": {
                  "shape": "Sp"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectSentiment": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "Sentiment": {},
                "SentimentScore": {
                  "shape": "Sw"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "BatchDetectSyntax": {
      "input": {
        "type": "structure",
        "required": [
          "TextList",
          "LanguageCode"
        ],
        "members": {
          "TextList": {
            "shape": "S2"
          },
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResultList",
          "ErrorList"
        ],
        "members": {
          "ResultList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Index": {
                  "type": "integer"
                },
                "SyntaxTokens": {
                  "shape": "S12"
                }
              }
            }
          },
          "ErrorList": {
            "shape": "Sb"
          }
        }
      }
    },
    "CreateDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierName",
          "DataAccessRoleArn",
          "InputDataConfig",
          "LanguageCode"
        ],
        "members": {
          "DocumentClassifierName": {},
          "DataAccessRoleArn": {},
          "Tags": {
            "shape": "S19"
          },
          "InputDataConfig": {
            "shape": "S1d"
          },
          "OutputDataConfig": {
            "shape": "S1f"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {},
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierArn": {}
        }
      }
    },
    "CreateEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "RecognizerName",
          "DataAccessRoleArn",
          "InputDataConfig",
          "LanguageCode"
        ],
        "members": {
          "RecognizerName": {},
          "DataAccessRoleArn": {},
          "Tags": {
            "shape": "S19"
          },
          "InputDataConfig": {
            "shape": "S1q"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "LanguageCode": {},
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerArn": {}
        }
      }
    },
    "DeleteDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDocumentClassificationJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassificationJobProperties": {
            "shape": "S26"
          }
        }
      }
    },
    "DescribeDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierProperties": {
            "shape": "S2g"
          }
        }
      }
    },
    "DescribeDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DominantLanguageDetectionJobProperties": {
            "shape": "S2n"
          }
        }
      }
    },
    "DescribeEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitiesDetectionJobProperties": {
            "shape": "S2q"
          }
        }
      }
    },
    "DescribeEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerProperties": {
            "shape": "S2t"
          }
        }
      }
    },
    "DescribeKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrasesDetectionJobProperties": {
            "shape": "S31"
          }
        }
      }
    },
    "DescribeSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SentimentDetectionJobProperties": {
            "shape": "S34"
          }
        }
      }
    },
    "DescribeTopicsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicsDetectionJobProperties": {
            "shape": "S37"
          }
        }
      }
    },
    "DetectDominantLanguage": {
      "input": {
        "type": "structure",
        "required": [
          "Text"
        ],
        "members": {
          "Text": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Languages": {
            "shape": "S8"
          }
        }
      }
    },
    "DetectEntities": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entities": {
            "shape": "Si"
          }
        }
      }
    },
    "DetectKeyPhrases": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrases": {
            "shape": "Sp"
          }
        }
      }
    },
    "DetectSentiment": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Sentiment": {},
          "SentimentScore": {
            "shape": "Sw"
          }
        }
      }
    },
    "DetectSyntax": {
      "input": {
        "type": "structure",
        "required": [
          "Text",
          "LanguageCode"
        ],
        "members": {
          "Text": {},
          "LanguageCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SyntaxTokens": {
            "shape": "S12"
          }
        }
      }
    },
    "ListDocumentClassificationJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassificationJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S26"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDocumentClassifiers": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "Status": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentClassifierPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S2g"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDominantLanguageDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DominantLanguageDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S2n"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntitiesDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitiesDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S2q"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEntityRecognizers": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "Status": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EntityRecognizerPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S2t"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListKeyPhrasesDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPhrasesDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S31"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSentimentDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SentimentDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S34"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S19"
          }
        }
      }
    },
    "ListTopicsDetectionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "type": "structure",
            "members": {
              "JobName": {},
              "JobStatus": {},
              "SubmitTimeBefore": {
                "type": "timestamp"
              },
              "SubmitTimeAfter": {
                "type": "timestamp"
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TopicsDetectionJobPropertiesList": {
            "type": "list",
            "member": {
              "shape": "S37"
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartDocumentClassificationJob": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn",
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "JobName": {},
          "DocumentClassifierArn": {},
          "InputDataConfig": {
            "shape": "S2b"
          },
          "OutputDataConfig": {
            "shape": "S2d"
          },
          "DataAccessRoleArn": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2b"
          },
          "OutputDataConfig": {
            "shape": "S2d"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2b"
          },
          "OutputDataConfig": {
            "shape": "S2d"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "EntityRecognizerArn": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2b"
          },
          "OutputDataConfig": {
            "shape": "S2d"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn",
          "LanguageCode"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2b"
          },
          "OutputDataConfig": {
            "shape": "S2d"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "LanguageCode": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StartTopicsDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "InputDataConfig",
          "OutputDataConfig",
          "DataAccessRoleArn"
        ],
        "members": {
          "InputDataConfig": {
            "shape": "S2b"
          },
          "OutputDataConfig": {
            "shape": "S2d"
          },
          "DataAccessRoleArn": {},
          "JobName": {},
          "NumberOfTopics": {
            "type": "integer"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "VolumeKmsKeyId": {},
          "VpcConfig": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopDominantLanguageDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopEntitiesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopKeyPhrasesDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopSentimentDetectionJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "JobStatus": {}
        }
      }
    },
    "StopTrainingDocumentClassifier": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentClassifierArn"
        ],
        "members": {
          "DocumentClassifierArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopTrainingEntityRecognizer": {
      "input": {
        "type": "structure",
        "required": [
          "EntityRecognizerArn"
        ],
        "members": {
          "EntityRecognizerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S8": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "LanguageCode": {},
          "Score": {
            "type": "float"
          }
        }
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Index": {
            "type": "integer"
          },
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Score": {
            "type": "float"
          },
          "Type": {},
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          }
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Score": {
            "type": "float"
          },
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          }
        }
      }
    },
    "Sw": {
      "type": "structure",
      "members": {
        "Positive": {
          "type": "float"
        },
        "Negative": {
          "type": "float"
        },
        "Neutral": {
          "type": "float"
        },
        "Mixed": {
          "type": "float"
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TokenId": {
            "type": "integer"
          },
          "Text": {},
          "BeginOffset": {
            "type": "integer"
          },
          "EndOffset": {
            "type": "integer"
          },
          "PartOfSpeech": {
            "type": "structure",
            "members": {
              "Tag": {},
              "Score": {
                "type": "float"
              }
            }
          }
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {}
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "S3Uri": {},
        "KmsKeyId": {}
      }
    },
    "S1i": {
      "type": "structure",
      "required": [
        "SecurityGroupIds",
        "Subnets"
      ],
      "members": {
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        },
        "Subnets": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1q": {
      "type": "structure",
      "required": [
        "EntityTypes",
        "Documents"
      ],
      "members": {
        "EntityTypes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {}
            }
          }
        },
        "Documents": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {}
          }
        },
        "Annotations": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {}
          }
        },
        "EntityList": {
          "type": "structure",
          "required": [
            "S3Uri"
          ],
          "members": {
            "S3Uri": {}
          }
        }
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "DocumentClassifierArn": {},
        "InputDataConfig": {
          "shape": "S2b"
        },
        "OutputDataConfig": {
          "shape": "S2d"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    },
    "S2b": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "InputFormat": {}
      }
    },
    "S2d": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "KmsKeyId": {}
      }
    },
    "S2g": {
      "type": "structure",
      "members": {
        "DocumentClassifierArn": {},
        "LanguageCode": {},
        "Status": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S1d"
        },
        "OutputDataConfig": {
          "shape": "S1f"
        },
        "ClassifierMetadata": {
          "type": "structure",
          "members": {
            "NumberOfLabels": {
              "type": "integer"
            },
            "NumberOfTrainedDocuments": {
              "type": "integer"
            },
            "NumberOfTestDocuments": {
              "type": "integer"
            },
            "EvaluationMetrics": {
              "type": "structure",
              "members": {
                "Accuracy": {
                  "type": "double"
                },
                "Precision": {
                  "type": "double"
                },
                "Recall": {
                  "type": "double"
                },
                "F1Score": {
                  "type": "double"
                }
              }
            }
          }
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2b"
        },
        "OutputDataConfig": {
          "shape": "S2d"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    },
    "S2q": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "EntityRecognizerArn": {},
        "InputDataConfig": {
          "shape": "S2b"
        },
        "OutputDataConfig": {
          "shape": "S2d"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    },
    "S2t": {
      "type": "structure",
      "members": {
        "EntityRecognizerArn": {},
        "LanguageCode": {},
        "Status": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S1q"
        },
        "RecognizerMetadata": {
          "type": "structure",
          "members": {
            "NumberOfTrainedDocuments": {
              "type": "integer"
            },
            "NumberOfTestDocuments": {
              "type": "integer"
            },
            "EvaluationMetrics": {
              "type": "structure",
              "members": {
                "Precision": {
                  "type": "double"
                },
                "Recall": {
                  "type": "double"
                },
                "F1Score": {
                  "type": "double"
                }
              }
            },
            "EntityTypes": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Type": {},
                  "EvaluationMetrics": {
                    "type": "structure",
                    "members": {
                      "Precision": {
                        "type": "double"
                      },
                      "Recall": {
                        "type": "double"
                      },
                      "F1Score": {
                        "type": "double"
                      }
                    }
                  },
                  "NumberOfTrainMentions": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2b"
        },
        "OutputDataConfig": {
          "shape": "S2d"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    },
    "S34": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2b"
        },
        "OutputDataConfig": {
          "shape": "S2d"
        },
        "LanguageCode": {},
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "JobId": {},
        "JobName": {},
        "JobStatus": {},
        "Message": {},
        "SubmitTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "InputDataConfig": {
          "shape": "S2b"
        },
        "OutputDataConfig": {
          "shape": "S2d"
        },
        "NumberOfTopics": {
          "type": "integer"
        },
        "DataAccessRoleArn": {},
        "VolumeKmsKeyId": {},
        "VpcConfig": {
          "shape": "S1i"
        }
      }
    }
  }
}