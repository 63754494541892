{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2014-11-12",
    "endpointPrefix": "config",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Config Service",
    "serviceFullName": "AWS Config",
    "serviceId": "Config Service",
    "signatureVersion": "v4",
    "targetPrefix": "StarlingDoveService",
    "uid": "config-2014-11-12"
  },
  "operations": {
    "BatchGetAggregateResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ResourceIdentifiers"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ResourceIdentifiers": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BaseConfigurationItems": {
            "shape": "Sb"
          },
          "UnprocessedResourceIdentifiers": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      }
    },
    "BatchGetResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "resourceKeys"
        ],
        "members": {
          "resourceKeys": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "baseConfigurationItems": {
            "shape": "Sb"
          },
          "unprocessedResourceKeys": {
            "shape": "Sq"
          }
        }
      }
    },
    "DeleteAggregationAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "AuthorizedAccountId",
          "AuthorizedAwsRegion"
        ],
        "members": {
          "AuthorizedAccountId": {},
          "AuthorizedAwsRegion": {}
        }
      }
    },
    "DeleteConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {}
        }
      }
    },
    "DeleteConfigurationAggregator": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {}
        }
      }
    },
    "DeleteConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "DeleteDeliveryChannel": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryChannelName"
        ],
        "members": {
          "DeliveryChannelName": {}
        }
      }
    },
    "DeleteEvaluationResults": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteOrganizationConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConfigRuleName"
        ],
        "members": {
          "OrganizationConfigRuleName": {}
        }
      }
    },
    "DeletePendingAggregationRequest": {
      "input": {
        "type": "structure",
        "required": [
          "RequesterAccountId",
          "RequesterAwsRegion"
        ],
        "members": {
          "RequesterAccountId": {},
          "RequesterAwsRegion": {}
        }
      }
    },
    "DeleteRemediationConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRetentionConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "RetentionConfigurationName"
        ],
        "members": {
          "RetentionConfigurationName": {}
        }
      }
    },
    "DeliverConfigSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "deliveryChannelName"
        ],
        "members": {
          "deliveryChannelName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configSnapshotId": {}
        }
      }
    },
    "DescribeAggregateComplianceByConfigRules": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "ConfigRuleName": {},
              "ComplianceType": {},
              "AccountId": {},
              "AwsRegion": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregateComplianceByConfigRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "Compliance": {
                  "shape": "S1l"
                },
                "AccountId": {},
                "AwsRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeAggregationAuthorizations": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregationAuthorizations": {
            "type": "list",
            "member": {
              "shape": "S1t"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeComplianceByConfigRule": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "S1w"
          },
          "ComplianceTypes": {
            "shape": "S1x"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceByConfigRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "Compliance": {
                  "shape": "S1l"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeComplianceByResource": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "ComplianceTypes": {
            "shape": "S1x"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceByResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "ResourceId": {},
                "Compliance": {
                  "shape": "S1l"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigRuleEvaluationStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "S1w"
          },
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigRulesEvaluationStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ConfigRuleName": {},
                "ConfigRuleArn": {},
                "ConfigRuleId": {},
                "LastSuccessfulInvocationTime": {
                  "type": "timestamp"
                },
                "LastFailedInvocationTime": {
                  "type": "timestamp"
                },
                "LastSuccessfulEvaluationTime": {
                  "type": "timestamp"
                },
                "LastFailedEvaluationTime": {
                  "type": "timestamp"
                },
                "FirstActivatedTime": {
                  "type": "timestamp"
                },
                "LastErrorCode": {},
                "LastErrorMessage": {},
                "FirstEvaluationStarted": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigRules": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "shape": "S1w"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigRules": {
            "type": "list",
            "member": {
              "shape": "S2f"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigurationAggregatorSourcesStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "UpdateStatus": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregatedSourceStatusList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceId": {},
                "SourceType": {},
                "AwsRegion": {},
                "LastUpdateStatus": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                },
                "LastErrorCode": {},
                "LastErrorMessage": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigurationAggregators": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationAggregatorNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationAggregators": {
            "type": "list",
            "member": {
              "shape": "S34"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeConfigurationRecorderStatus": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationRecorderNames": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationRecordersStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "lastStartTime": {
                  "type": "timestamp"
                },
                "lastStopTime": {
                  "type": "timestamp"
                },
                "recording": {
                  "type": "boolean"
                },
                "lastStatus": {},
                "lastErrorCode": {},
                "lastErrorMessage": {},
                "lastStatusChangeTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeConfigurationRecorders": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigurationRecorderNames": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationRecorders": {
            "type": "list",
            "member": {
              "shape": "S3k"
            }
          }
        }
      }
    },
    "DescribeDeliveryChannelStatus": {
      "input": {
        "type": "structure",
        "members": {
          "DeliveryChannelNames": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryChannelsStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "configSnapshotDeliveryInfo": {
                  "shape": "S3u"
                },
                "configHistoryDeliveryInfo": {
                  "shape": "S3u"
                },
                "configStreamDeliveryInfo": {
                  "type": "structure",
                  "members": {
                    "lastStatus": {},
                    "lastErrorCode": {},
                    "lastErrorMessage": {},
                    "lastStatusChangeTime": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDeliveryChannels": {
      "input": {
        "type": "structure",
        "members": {
          "DeliveryChannelNames": {
            "shape": "S3q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryChannels": {
            "type": "list",
            "member": {
              "shape": "S40"
            }
          }
        }
      }
    },
    "DescribeOrganizationConfigRuleStatuses": {
      "input": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleNames": {
            "shape": "S43"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OrganizationConfigRuleName",
                "OrganizationRuleStatus"
              ],
              "members": {
                "OrganizationConfigRuleName": {},
                "OrganizationRuleStatus": {},
                "ErrorCode": {},
                "ErrorMessage": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeOrganizationConfigRules": {
      "input": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleNames": {
            "shape": "S43"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRules": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "OrganizationConfigRuleName",
                "OrganizationConfigRuleArn"
              ],
              "members": {
                "OrganizationConfigRuleName": {},
                "OrganizationConfigRuleArn": {},
                "OrganizationManagedRuleMetadata": {
                  "shape": "S4d"
                },
                "OrganizationCustomRuleMetadata": {
                  "shape": "S4i"
                },
                "ExcludedAccounts": {
                  "shape": "S4l"
                },
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePendingAggregationRequests": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PendingAggregationRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RequesterAccountId": {},
                "RequesterAwsRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRemediationConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleNames"
        ],
        "members": {
          "ConfigRuleNames": {
            "shape": "S1w"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RemediationConfigurations": {
            "shape": "S4t"
          }
        }
      }
    },
    "DescribeRemediationExecutionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceKeys": {
            "shape": "Sq"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RemediationExecutionStatuses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceKey": {
                  "shape": "Sr"
                },
                "State": {},
                "StepDetails": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Name": {},
                      "State": {},
                      "ErrorMessage": {},
                      "StartTime": {
                        "type": "timestamp"
                      },
                      "StopTime": {
                        "type": "timestamp"
                      }
                    }
                  }
                },
                "InvocationTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRetentionConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "RetentionConfigurationNames": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RetentionConfigurations": {
            "type": "list",
            "member": {
              "shape": "S5e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateComplianceDetailsByConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ConfigRuleName",
          "AccountId",
          "AwsRegion"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ConfigRuleName": {},
          "AccountId": {},
          "AwsRegion": {},
          "ComplianceType": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregateEvaluationResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EvaluationResultIdentifier": {
                  "shape": "S5k"
                },
                "ComplianceType": {},
                "ResultRecordedTime": {
                  "type": "timestamp"
                },
                "ConfigRuleInvokedTime": {
                  "type": "timestamp"
                },
                "Annotation": {},
                "AccountId": {},
                "AwsRegion": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateConfigRuleComplianceSummary": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "AccountId": {},
              "AwsRegion": {}
            }
          },
          "GroupByKey": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupByKey": {},
          "AggregateComplianceCounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupName": {},
                "ComplianceSummary": {
                  "shape": "S5s"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateDiscoveredResourceCounts": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "ResourceType": {},
              "AccountId": {},
              "Region": {}
            }
          },
          "GroupByKey": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TotalDiscoveredResources"
        ],
        "members": {
          "TotalDiscoveredResources": {
            "type": "long"
          },
          "GroupByKey": {},
          "GroupedResourceCounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GroupName",
                "ResourceCount"
              ],
              "members": {
                "GroupName": {},
                "ResourceCount": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetAggregateResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ResourceIdentifier"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ResourceIdentifier": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationItem": {
            "shape": "S62"
          }
        }
      }
    },
    "GetComplianceDetailsByConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName"
        ],
        "members": {
          "ConfigRuleName": {},
          "ComplianceTypes": {
            "shape": "S1x"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationResults": {
            "shape": "S6e"
          },
          "NextToken": {}
        }
      }
    },
    "GetComplianceDetailsByResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceType",
          "ResourceId"
        ],
        "members": {
          "ResourceType": {},
          "ResourceId": {},
          "ComplianceTypes": {
            "shape": "S1x"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EvaluationResults": {
            "shape": "S6e"
          },
          "NextToken": {}
        }
      }
    },
    "GetComplianceSummaryByConfigRule": {
      "output": {
        "type": "structure",
        "members": {
          "ComplianceSummary": {
            "shape": "S5s"
          }
        }
      }
    },
    "GetComplianceSummaryByResourceType": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceTypes": {
            "shape": "S6k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComplianceSummariesByResourceType": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceType": {},
                "ComplianceSummary": {
                  "shape": "S5s"
                }
              }
            }
          }
        }
      }
    },
    "GetDiscoveredResourceCounts": {
      "input": {
        "type": "structure",
        "members": {
          "resourceTypes": {
            "shape": "S6k"
          },
          "limit": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "totalDiscoveredResources": {
            "type": "long"
          },
          "resourceCounts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceType": {},
                "count": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "GetOrganizationConfigRuleDetailedStatus": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConfigRuleName"
        ],
        "members": {
          "OrganizationConfigRuleName": {},
          "Filters": {
            "type": "structure",
            "members": {
              "AccountId": {},
              "MemberAccountRuleStatus": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleDetailedStatus": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AccountId",
                "ConfigRuleName",
                "MemberAccountRuleStatus"
              ],
              "members": {
                "AccountId": {},
                "ConfigRuleName": {},
                "MemberAccountRuleStatus": {},
                "ErrorCode": {},
                "ErrorMessage": {},
                "LastUpdateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetResourceConfigHistory": {
      "input": {
        "type": "structure",
        "required": [
          "resourceType",
          "resourceId"
        ],
        "members": {
          "resourceType": {},
          "resourceId": {},
          "laterTime": {
            "type": "timestamp"
          },
          "earlierTime": {
            "type": "timestamp"
          },
          "chronologicalOrder": {},
          "limit": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "configurationItems": {
            "type": "list",
            "member": {
              "shape": "S62"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAggregateDiscoveredResources": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName",
          "ResourceType"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "ResourceType": {},
          "Filters": {
            "type": "structure",
            "members": {
              "AccountId": {},
              "ResourceId": {},
              "ResourceName": {},
              "Region": {}
            }
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceIdentifiers": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListDiscoveredResources": {
      "input": {
        "type": "structure",
        "required": [
          "resourceType"
        ],
        "members": {
          "resourceType": {},
          "resourceIds": {
            "type": "list",
            "member": {}
          },
          "resourceName": {},
          "limit": {
            "type": "integer"
          },
          "includeDeletedResources": {
            "type": "boolean"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "resourceType": {},
                "resourceId": {},
                "resourceName": {},
                "resourceDeletionTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7h"
          },
          "NextToken": {}
        }
      }
    },
    "PutAggregationAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "AuthorizedAccountId",
          "AuthorizedAwsRegion"
        ],
        "members": {
          "AuthorizedAccountId": {},
          "AuthorizedAwsRegion": {},
          "Tags": {
            "shape": "S7m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AggregationAuthorization": {
            "shape": "S1t"
          }
        }
      }
    },
    "PutConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRule"
        ],
        "members": {
          "ConfigRule": {
            "shape": "S2f"
          },
          "Tags": {
            "shape": "S7m"
          }
        }
      }
    },
    "PutConfigurationAggregator": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationAggregatorName"
        ],
        "members": {
          "ConfigurationAggregatorName": {},
          "AccountAggregationSources": {
            "shape": "S36"
          },
          "OrganizationAggregationSource": {
            "shape": "S3a"
          },
          "Tags": {
            "shape": "S7m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConfigurationAggregator": {
            "shape": "S34"
          }
        }
      }
    },
    "PutConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorder"
        ],
        "members": {
          "ConfigurationRecorder": {
            "shape": "S3k"
          }
        }
      }
    },
    "PutDeliveryChannel": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryChannel"
        ],
        "members": {
          "DeliveryChannel": {
            "shape": "S40"
          }
        }
      }
    },
    "PutEvaluations": {
      "input": {
        "type": "structure",
        "required": [
          "ResultToken"
        ],
        "members": {
          "Evaluations": {
            "shape": "S7u"
          },
          "ResultToken": {},
          "TestMode": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedEvaluations": {
            "shape": "S7u"
          }
        }
      }
    },
    "PutOrganizationConfigRule": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationConfigRuleName"
        ],
        "members": {
          "OrganizationConfigRuleName": {},
          "OrganizationManagedRuleMetadata": {
            "shape": "S4d"
          },
          "OrganizationCustomRuleMetadata": {
            "shape": "S4i"
          },
          "ExcludedAccounts": {
            "shape": "S4l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationConfigRuleArn": {}
        }
      }
    },
    "PutRemediationConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "RemediationConfigurations"
        ],
        "members": {
          "RemediationConfigurations": {
            "shape": "S4t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedBatches": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FailureMessage": {},
                "FailedItems": {
                  "shape": "S4t"
                }
              }
            }
          }
        }
      }
    },
    "PutRetentionConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "RetentionPeriodInDays"
        ],
        "members": {
          "RetentionPeriodInDays": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RetentionConfiguration": {
            "shape": "S5e"
          }
        }
      }
    },
    "SelectResourceConfig": {
      "input": {
        "type": "structure",
        "required": [
          "Expression"
        ],
        "members": {
          "Expression": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {}
          },
          "QueryInfo": {
            "type": "structure",
            "members": {
              "SelectFields": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Name": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartConfigRulesEvaluation": {
      "input": {
        "type": "structure",
        "members": {
          "ConfigRuleNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "StartRemediationExecution": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigRuleName",
          "ResourceKeys"
        ],
        "members": {
          "ConfigRuleName": {},
          "ResourceKeys": {
            "shape": "Sq"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailureMessage": {},
          "FailedItems": {
            "shape": "Sq"
          }
        }
      }
    },
    "StopConfigurationRecorder": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationRecorderName"
        ],
        "members": {
          "ConfigurationRecorderName": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S7h"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "SourceAccountId",
        "SourceRegion",
        "ResourceId",
        "ResourceType"
      ],
      "members": {
        "SourceAccountId": {},
        "SourceRegion": {},
        "ResourceId": {},
        "ResourceType": {},
        "ResourceName": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "version": {},
          "accountId": {},
          "configurationItemCaptureTime": {
            "type": "timestamp"
          },
          "configurationItemStatus": {},
          "configurationStateId": {},
          "arn": {},
          "resourceType": {},
          "resourceId": {},
          "resourceName": {},
          "awsRegion": {},
          "availabilityZone": {},
          "resourceCreationTime": {
            "type": "timestamp"
          },
          "configuration": {},
          "supplementaryConfiguration": {
            "shape": "Sl"
          }
        }
      }
    },
    "Sl": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sq": {
      "type": "list",
      "member": {
        "shape": "Sr"
      }
    },
    "Sr": {
      "type": "structure",
      "required": [
        "resourceType",
        "resourceId"
      ],
      "members": {
        "resourceType": {},
        "resourceId": {}
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "ComplianceType": {},
        "ComplianceContributorCount": {
          "shape": "S1m"
        }
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "CappedCount": {
          "type": "integer"
        },
        "CapExceeded": {
          "type": "boolean"
        }
      }
    },
    "S1t": {
      "type": "structure",
      "members": {
        "AggregationAuthorizationArn": {},
        "AuthorizedAccountId": {},
        "AuthorizedAwsRegion": {},
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "S1w": {
      "type": "list",
      "member": {}
    },
    "S1x": {
      "type": "list",
      "member": {}
    },
    "S2f": {
      "type": "structure",
      "required": [
        "Source"
      ],
      "members": {
        "ConfigRuleName": {},
        "ConfigRuleArn": {},
        "ConfigRuleId": {},
        "Description": {},
        "Scope": {
          "type": "structure",
          "members": {
            "ComplianceResourceTypes": {
              "type": "list",
              "member": {}
            },
            "TagKey": {},
            "TagValue": {},
            "ComplianceResourceId": {}
          }
        },
        "Source": {
          "type": "structure",
          "required": [
            "Owner",
            "SourceIdentifier"
          ],
          "members": {
            "Owner": {},
            "SourceIdentifier": {},
            "SourceDetails": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "EventSource": {},
                  "MessageType": {},
                  "MaximumExecutionFrequency": {}
                }
              }
            }
          }
        },
        "InputParameters": {},
        "MaximumExecutionFrequency": {},
        "ConfigRuleState": {},
        "CreatedBy": {}
      }
    },
    "S34": {
      "type": "structure",
      "members": {
        "ConfigurationAggregatorName": {},
        "ConfigurationAggregatorArn": {},
        "AccountAggregationSources": {
          "shape": "S36"
        },
        "OrganizationAggregationSource": {
          "shape": "S3a"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S36": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "type": "list",
            "member": {}
          },
          "AllAwsRegions": {
            "type": "boolean"
          },
          "AwsRegions": {
            "shape": "S39"
          }
        }
      }
    },
    "S39": {
      "type": "list",
      "member": {}
    },
    "S3a": {
      "type": "structure",
      "required": [
        "RoleArn"
      ],
      "members": {
        "RoleArn": {},
        "AwsRegions": {
          "shape": "S39"
        },
        "AllAwsRegions": {
          "type": "boolean"
        }
      }
    },
    "S3c": {
      "type": "list",
      "member": {}
    },
    "S3k": {
      "type": "structure",
      "members": {
        "name": {},
        "roleARN": {},
        "recordingGroup": {
          "type": "structure",
          "members": {
            "allSupported": {
              "type": "boolean"
            },
            "includeGlobalResourceTypes": {
              "type": "boolean"
            },
            "resourceTypes": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S3q": {
      "type": "list",
      "member": {}
    },
    "S3u": {
      "type": "structure",
      "members": {
        "lastStatus": {},
        "lastErrorCode": {},
        "lastErrorMessage": {},
        "lastAttemptTime": {
          "type": "timestamp"
        },
        "lastSuccessfulTime": {
          "type": "timestamp"
        },
        "nextDeliveryTime": {
          "type": "timestamp"
        }
      }
    },
    "S40": {
      "type": "structure",
      "members": {
        "name": {},
        "s3BucketName": {},
        "s3KeyPrefix": {},
        "snsTopicARN": {},
        "configSnapshotDeliveryProperties": {
          "type": "structure",
          "members": {
            "deliveryFrequency": {}
          }
        }
      }
    },
    "S43": {
      "type": "list",
      "member": {}
    },
    "S4d": {
      "type": "structure",
      "required": [
        "RuleIdentifier"
      ],
      "members": {
        "Description": {},
        "RuleIdentifier": {},
        "InputParameters": {},
        "MaximumExecutionFrequency": {},
        "ResourceTypesScope": {
          "shape": "S4g"
        },
        "ResourceIdScope": {},
        "TagKeyScope": {},
        "TagValueScope": {}
      }
    },
    "S4g": {
      "type": "list",
      "member": {}
    },
    "S4i": {
      "type": "structure",
      "required": [
        "LambdaFunctionArn",
        "OrganizationConfigRuleTriggerTypes"
      ],
      "members": {
        "Description": {},
        "LambdaFunctionArn": {},
        "OrganizationConfigRuleTriggerTypes": {
          "type": "list",
          "member": {}
        },
        "InputParameters": {},
        "MaximumExecutionFrequency": {},
        "ResourceTypesScope": {
          "shape": "S4g"
        },
        "ResourceIdScope": {},
        "TagKeyScope": {},
        "TagValueScope": {}
      }
    },
    "S4l": {
      "type": "list",
      "member": {}
    },
    "S4t": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ConfigRuleName",
          "TargetType",
          "TargetId"
        ],
        "members": {
          "ConfigRuleName": {},
          "TargetType": {},
          "TargetId": {},
          "TargetVersion": {},
          "Parameters": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "ResourceValue": {
                  "type": "structure",
                  "members": {
                    "Value": {}
                  }
                },
                "StaticValue": {
                  "type": "structure",
                  "members": {
                    "Values": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "ResourceType": {}
        }
      }
    },
    "S5e": {
      "type": "structure",
      "required": [
        "Name",
        "RetentionPeriodInDays"
      ],
      "members": {
        "Name": {},
        "RetentionPeriodInDays": {
          "type": "integer"
        }
      }
    },
    "S5k": {
      "type": "structure",
      "members": {
        "EvaluationResultQualifier": {
          "type": "structure",
          "members": {
            "ConfigRuleName": {},
            "ResourceType": {},
            "ResourceId": {}
          }
        },
        "OrderingTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S5s": {
      "type": "structure",
      "members": {
        "CompliantResourceCount": {
          "shape": "S1m"
        },
        "NonCompliantResourceCount": {
          "shape": "S1m"
        },
        "ComplianceSummaryTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S62": {
      "type": "structure",
      "members": {
        "version": {},
        "accountId": {},
        "configurationItemCaptureTime": {
          "type": "timestamp"
        },
        "configurationItemStatus": {},
        "configurationStateId": {},
        "configurationItemMD5Hash": {},
        "arn": {},
        "resourceType": {},
        "resourceId": {},
        "resourceName": {},
        "awsRegion": {},
        "availabilityZone": {},
        "resourceCreationTime": {
          "type": "timestamp"
        },
        "tags": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "relatedEvents": {
          "type": "list",
          "member": {}
        },
        "relationships": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "resourceType": {},
              "resourceId": {},
              "resourceName": {},
              "relationshipName": {}
            }
          }
        },
        "configuration": {},
        "supplementaryConfiguration": {
          "shape": "Sl"
        }
      }
    },
    "S6e": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "EvaluationResultIdentifier": {
            "shape": "S5k"
          },
          "ComplianceType": {},
          "ResultRecordedTime": {
            "type": "timestamp"
          },
          "ConfigRuleInvokedTime": {
            "type": "timestamp"
          },
          "Annotation": {},
          "ResultToken": {}
        }
      }
    },
    "S6k": {
      "type": "list",
      "member": {}
    },
    "S7h": {
      "type": "list",
      "member": {
        "shape": "S7i"
      }
    },
    "S7i": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S7m": {
      "type": "list",
      "member": {
        "shape": "S7i"
      }
    },
    "S7u": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ComplianceResourceType",
          "ComplianceResourceId",
          "ComplianceType",
          "OrderingTimestamp"
        ],
        "members": {
          "ComplianceResourceType": {},
          "ComplianceResourceId": {},
          "ComplianceType": {},
          "Annotation": {},
          "OrderingTimestamp": {
            "type": "timestamp"
          }
        }
      }
    }
  }
}