{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-26",
    "endpointPrefix": "transcribe",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Transcribe Service",
    "serviceId": "Transcribe",
    "signatureVersion": "v4",
    "signingName": "transcribe",
    "targetPrefix": "Transcribe",
    "uid": "transcribe-2017-10-26"
  },
  "operations": {
    "CreateVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName",
          "LanguageCode"
        ],
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "Phrases": {
            "shape": "S4"
          },
          "VocabularyFileUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {}
        }
      }
    },
    "DeleteTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "TranscriptionJobName"
        ],
        "members": {
          "TranscriptionJobName": {}
        }
      }
    },
    "DeleteVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName"
        ],
        "members": {
          "VocabularyName": {}
        }
      }
    },
    "GetTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "TranscriptionJobName"
        ],
        "members": {
          "TranscriptionJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TranscriptionJob": {
            "shape": "Sg"
          }
        }
      }
    },
    "GetVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName"
        ],
        "members": {
          "VocabularyName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "VocabularyState": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "DownloadUri": {}
        }
      }
    },
    "ListTranscriptionJobs": {
      "input": {
        "type": "structure",
        "members": {
          "Status": {},
          "JobNameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "NextToken": {},
          "TranscriptionJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TranscriptionJobName": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "LanguageCode": {},
                "TranscriptionJobStatus": {},
                "FailureReason": {},
                "OutputLocationType": {}
              }
            }
          }
        }
      }
    },
    "ListVocabularies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StateEquals": {},
          "NameContains": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "NextToken": {},
          "Vocabularies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VocabularyName": {},
                "LanguageCode": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "VocabularyState": {}
              }
            }
          }
        }
      }
    },
    "StartTranscriptionJob": {
      "input": {
        "type": "structure",
        "required": [
          "TranscriptionJobName",
          "LanguageCode",
          "MediaFormat",
          "Media"
        ],
        "members": {
          "TranscriptionJobName": {},
          "LanguageCode": {},
          "MediaSampleRateHertz": {
            "type": "integer"
          },
          "MediaFormat": {},
          "Media": {
            "shape": "Sk"
          },
          "OutputBucketName": {},
          "Settings": {
            "shape": "Sm"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TranscriptionJob": {
            "shape": "Sg"
          }
        }
      }
    },
    "UpdateVocabulary": {
      "input": {
        "type": "structure",
        "required": [
          "VocabularyName",
          "LanguageCode"
        ],
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "Phrases": {
            "shape": "S4"
          },
          "VocabularyFileUri": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VocabularyName": {},
          "LanguageCode": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "VocabularyState": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {}
    },
    "Sg": {
      "type": "structure",
      "members": {
        "TranscriptionJobName": {},
        "TranscriptionJobStatus": {},
        "LanguageCode": {},
        "MediaSampleRateHertz": {
          "type": "integer"
        },
        "MediaFormat": {},
        "Media": {
          "shape": "Sk"
        },
        "Transcript": {
          "type": "structure",
          "members": {
            "TranscriptFileUri": {}
          }
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "CompletionTime": {
          "type": "timestamp"
        },
        "FailureReason": {},
        "Settings": {
          "shape": "Sm"
        }
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "MediaFileUri": {}
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "VocabularyName": {},
        "ShowSpeakerLabels": {
          "type": "boolean"
        },
        "MaxSpeakerLabels": {
          "type": "integer"
        },
        "ChannelIdentification": {
          "type": "boolean"
        }
      }
    }
  }
}