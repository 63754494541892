import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Dropzone from 'react-dropzone';
import { Stack, Typography, useTheme } from '@mui/material';
import { CloudOffOutlined, CloudUpload } from '@mui/icons-material';
import { css } from '@emotion/react';
const getStyles = (theme) => ({
    select: css `
    color: ${theme.palette.secondary.main};
    text-decoration: underline;
    cursor: pointer;
  `,
    icon: css `
    font-size: 60px;
  `,
    root: css `
    background-color: ${theme.extraColors.secondaryContrast}ff;
    outline: 2px dashed #0000001f;
    outline-offset: -10px;
    width: 100%;
    transition: 0.1s;
  `,
    rootActive: css `
    background-color: ${theme.extraColors.secondaryContrast}80;
    outline: 2px dashed #0000001f;
    outline-offset: -15px;
    width: 100%;
    transition: 0.1s;
  `,
});
export const UploadArea = ({ AcceptFiles, acceptFilesText, chooseFilesText, dropFilesText, onDrop, rejectFilesText, }) => {
    const theme = useTheme();
    const styles = getStyles(theme);
    return (_jsx(Dropzone, { onDrop: onDrop, accept: AcceptFiles, children: ({ getRootProps, getInputProps, isDragActive, isDragReject }) => (_jsxs("div", { css: isDragActive ? styles.rootActive : styles.root, ...getRootProps(), "data-id": "drop-zone", children: [_jsx("input", { ...getInputProps(), "data-id": "drop-input" }), _jsxs(Stack, { justifyContent: 'center', alignItems: 'center', spacing: 1, padding: 3, children: [isDragReject ? (_jsx(CloudOffOutlined, { css: styles.icon, color: "error" })) : (_jsx(CloudUpload, { css: styles.icon, color: "success" })), _jsx(Stack, { direction: 'row', spacing: 0.5, children: isDragReject ? (_jsx(Typography, { color: "error", children: rejectFilesText })) : (_jsxs(_Fragment, { children: [_jsx(Typography, { children: dropFilesText }), _jsx(Typography, { css: styles.select, children: chooseFilesText })] })) }), _jsx(Stack, { direction: 'row', spacing: 0.5, children: _jsx(Typography, { color: "GrayText", variant: "caption", children: acceptFilesText }) })] })] })) }));
};
