{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-17",
    "endpointPrefix": "secretsmanager",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Secrets Manager",
    "serviceId": "Secrets Manager",
    "signatureVersion": "v4",
    "signingName": "secretsmanager",
    "targetPrefix": "secretsmanager",
    "uid": "secretsmanager-2017-10-17"
  },
  "operations": {
    "CancelRotateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "CreateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "KmsKeyId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "Tags": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "DeleteResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "DeleteSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "RecoveryWindowInDays": {
            "type": "long"
          },
          "ForceDeleteWithoutRecovery": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "DeletionDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "Description": {},
          "KmsKeyId": {},
          "RotationEnabled": {
            "type": "boolean"
          },
          "RotationLambdaARN": {},
          "RotationRules": {
            "shape": "Su"
          },
          "LastRotatedDate": {
            "type": "timestamp"
          },
          "LastChangedDate": {
            "type": "timestamp"
          },
          "LastAccessedDate": {
            "type": "timestamp"
          },
          "DeletedDate": {
            "type": "timestamp"
          },
          "Tags": {
            "shape": "Se"
          },
          "VersionIdsToStages": {
            "shape": "S10"
          },
          "OwningService": {}
        }
      }
    },
    "GetRandomPassword": {
      "input": {
        "type": "structure",
        "members": {
          "PasswordLength": {
            "type": "long"
          },
          "ExcludeCharacters": {},
          "ExcludeNumbers": {
            "type": "boolean"
          },
          "ExcludePunctuation": {
            "type": "boolean"
          },
          "ExcludeUppercase": {
            "type": "boolean"
          },
          "ExcludeLowercase": {
            "type": "boolean"
          },
          "IncludeSpace": {
            "type": "boolean"
          },
          "RequireEachIncludedType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RandomPassword": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "GetResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "ResourcePolicy": {}
        }
      }
    },
    "GetSecretValue": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "VersionId": {},
          "VersionStage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "VersionStages": {
            "shape": "S11"
          },
          "CreatedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "ListSecretVersionIds": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "IncludeDeprecated": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Versions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VersionId": {},
                "VersionStages": {
                  "shape": "S11"
                },
                "LastAccessedDate": {
                  "type": "timestamp"
                },
                "CreatedDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {},
          "ARN": {},
          "Name": {}
        }
      }
    },
    "ListSecrets": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecretList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ARN": {},
                "Name": {},
                "Description": {},
                "KmsKeyId": {},
                "RotationEnabled": {
                  "type": "boolean"
                },
                "RotationLambdaARN": {},
                "RotationRules": {
                  "shape": "Su"
                },
                "LastRotatedDate": {
                  "type": "timestamp"
                },
                "LastChangedDate": {
                  "type": "timestamp"
                },
                "LastAccessedDate": {
                  "type": "timestamp"
                },
                "DeletedDate": {
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Se"
                },
                "SecretVersionsToStages": {
                  "shape": "S10"
                },
                "OwningService": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutResourcePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "ResourcePolicy"
        ],
        "members": {
          "SecretId": {},
          "ResourcePolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "PutSecretValue": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          },
          "VersionStages": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {},
          "VersionStages": {
            "shape": "S11"
          }
        }
      }
    },
    "RestoreSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    },
    "RotateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "RotationLambdaARN": {},
          "RotationRules": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "Tags"
        ],
        "members": {
          "SecretId": {},
          "Tags": {
            "shape": "Se"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "TagKeys"
        ],
        "members": {
          "SecretId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateSecret": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId"
        ],
        "members": {
          "SecretId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "KmsKeyId": {},
          "SecretBinary": {
            "shape": "Sc"
          },
          "SecretString": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {},
          "VersionId": {}
        }
      }
    },
    "UpdateSecretVersionStage": {
      "input": {
        "type": "structure",
        "required": [
          "SecretId",
          "VersionStage"
        ],
        "members": {
          "SecretId": {},
          "VersionStage": {},
          "RemoveFromVersionId": {},
          "MoveToVersionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ARN": {},
          "Name": {}
        }
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "blob",
      "sensitive": true
    },
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "AutomaticallyAfterDays": {
          "type": "long"
        }
      }
    },
    "S10": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "S11"
      }
    },
    "S11": {
      "type": "list",
      "member": {}
    }
  }
}