import { ApolloClient, ApolloLink, InMemoryCache, concat, HttpLink, NormalizedCacheObject } from '@apollo/client';
import { createAuthLink, AuthOptions, AUTH_TYPE } from 'aws-appsync-auth-link';
import { get as getCookie } from 'js-cookie';

const baseAuthOptions: AuthOptions = {
  type: AUTH_TYPE.NONE,
};

const authMiddleware = (csrfCookieName?: string) =>
  new ApolloLink((operation, forward) => {
    csrfCookieName &&
      operation.setContext(({ headers = {} }) => ({
        headers: {
          ...headers,
          'x-csrf': getCookie(csrfCookieName),
        },
      }));

    return forward(operation);
  });

type ClientPropsType = {
  url: string;
  secondEndpoint?: {
    url: string;
    clientName: string;
  };
  cache: InMemoryCache;
  authOptions?: AuthOptions;
  region?: string;
  links?: ApolloLink[];
  csrfCookieName?: string;
};

const getEndpointLinks = (url: string, secondEndpoint?: ClientPropsType['secondEndpoint']) => {
  const defaultLink = new HttpLink({ uri: url });

  if (secondEndpoint) {
    const secondLink = new HttpLink({ uri: secondEndpoint.url });
    return ApolloLink.split(
      (operation) => operation.getContext().clientName === secondEndpoint.clientName,
      secondLink,
      defaultLink,
    );
  }
  return defaultLink;
};

export const initializeClient = ({
  url,
  secondEndpoint,
  cache,
  authOptions = {} as AuthOptions,
  region = 'AWS_REGION',
  links = [],
  csrfCookieName,
}: ClientPropsType): ApolloClient<NormalizedCacheObject> => {
  const config = {
    url,
    region,
    auth: { ...baseAuthOptions, ...authOptions },
  };

  const link = concat(
    authMiddleware(csrfCookieName),
    ApolloLink.from([createAuthLink(config), ...links, getEndpointLinks(url, secondEndpoint)]),
  );

  return new ApolloClient({
    link,
    cache,
  });
};
