{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-03-31",
    "endpointPrefix": "lambda",
    "protocol": "rest-json",
    "serviceFullName": "AWS Lambda",
    "serviceId": "Lambda",
    "signatureVersion": "v4",
    "uid": "lambda-2015-03-31"
  },
  "operations": {
    "AddLayerVersionPermission": {
      "http": {
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber",
          "StatementId",
          "Action",
          "Principal"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          },
          "StatementId": {},
          "Action": {},
          "Principal": {},
          "OrganizationId": {},
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {},
          "RevisionId": {}
        }
      }
    },
    "AddPermission": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId",
          "Action",
          "Principal"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {},
          "Action": {},
          "Principal": {},
          "SourceArn": {},
          "SourceAccount": {},
          "EventSourceToken": {},
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statement": {}
        }
      }
    },
    "CreateAlias": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name",
          "FunctionVersion"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {},
          "FunctionVersion": {},
          "Description": {},
          "RoutingConfig": {
            "shape": "Sn"
          }
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "CreateEventSourceMapping": {
      "http": {
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "EventSourceArn",
          "FunctionName"
        ],
        "members": {
          "EventSourceArn": {},
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          },
          "StartingPosition": {},
          "StartingPositionTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "shape": "Sy"
      }
    },
    "CreateFunction": {
      "http": {
        "requestUri": "/2015-03-31/functions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Runtime",
          "Role",
          "Handler",
          "Code"
        ],
        "members": {
          "FunctionName": {},
          "Runtime": {},
          "Role": {},
          "Handler": {},
          "Code": {
            "type": "structure",
            "members": {
              "ZipFile": {
                "shape": "S14"
              },
              "S3Bucket": {},
              "S3Key": {},
              "S3ObjectVersion": {}
            }
          },
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "Publish": {
            "type": "boolean"
          },
          "VpcConfig": {
            "shape": "S1b"
          },
          "DeadLetterConfig": {
            "shape": "S1g"
          },
          "Environment": {
            "shape": "S1i"
          },
          "KMSKeyArn": {},
          "TracingConfig": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1p"
          },
          "Layers": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "shape": "S1u"
      }
    },
    "DeleteAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      }
    },
    "DeleteEventSourceMapping": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "Sy"
      }
    },
    "DeleteFunction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      }
    },
    "DeleteFunctionConcurrency": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-10-31/functions/{FunctionName}/concurrency",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          }
        }
      }
    },
    "DeleteLayerVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      }
    },
    "GetAccountSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/2016-08-19/account-settings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountLimit": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "CodeSizeUnzipped": {
                "type": "long"
              },
              "CodeSizeZipped": {
                "type": "long"
              },
              "ConcurrentExecutions": {
                "type": "integer"
              },
              "UnreservedConcurrentExecutions": {
                "type": "integer"
              }
            }
          },
          "AccountUsage": {
            "type": "structure",
            "members": {
              "TotalCodeSize": {
                "type": "long"
              },
              "FunctionCount": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "GetAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "GetEventSourceMapping": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          }
        }
      },
      "output": {
        "shape": "Sy"
      }
    },
    "GetFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S1u"
          },
          "Code": {
            "type": "structure",
            "members": {
              "RepositoryType": {},
              "Location": {}
            }
          },
          "Tags": {
            "shape": "S1p"
          },
          "Concurrency": {
            "shape": "S2n"
          }
        }
      }
    },
    "GetFunctionConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "shape": "S1u"
      }
    },
    "GetLayerVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "shape": "S2r"
      }
    },
    "GetLayerVersionByArn": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers?find=LayerVersion",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {
            "location": "querystring",
            "locationName": "Arn"
          }
        }
      },
      "output": {
        "shape": "S2r"
      }
    },
    "GetLayerVersionPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {},
          "RevisionId": {}
        }
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {},
          "RevisionId": {}
        }
      }
    },
    "Invoke": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/invocations"
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvocationType": {
            "location": "header",
            "locationName": "X-Amz-Invocation-Type"
          },
          "LogType": {
            "location": "header",
            "locationName": "X-Amz-Log-Type"
          },
          "ClientContext": {
            "location": "header",
            "locationName": "X-Amz-Client-Context"
          },
          "Payload": {
            "shape": "S14"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          }
        },
        "payload": "Payload"
      },
      "output": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "location": "statusCode",
            "type": "integer"
          },
          "FunctionError": {
            "location": "header",
            "locationName": "X-Amz-Function-Error"
          },
          "LogResult": {
            "location": "header",
            "locationName": "X-Amz-Log-Result"
          },
          "Payload": {
            "shape": "S14"
          },
          "ExecutedVersion": {
            "location": "header",
            "locationName": "X-Amz-Executed-Version"
          }
        },
        "payload": "Payload"
      }
    },
    "InvokeAsync": {
      "http": {
        "requestUri": "/2014-11-13/functions/{FunctionName}/invoke-async/",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "InvokeArgs"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "InvokeArgs": {
            "type": "blob",
            "streaming": true
          }
        },
        "deprecated": true,
        "payload": "InvokeArgs"
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "location": "statusCode",
            "type": "integer"
          }
        },
        "deprecated": true
      },
      "deprecated": true
    },
    "ListAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "FunctionVersion": {
            "location": "querystring",
            "locationName": "FunctionVersion"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Aliases": {
            "type": "list",
            "member": {
              "shape": "Sr"
            }
          }
        }
      }
    },
    "ListEventSourceMappings": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/event-source-mappings/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "EventSourceArn": {
            "location": "querystring",
            "locationName": "EventSourceArn"
          },
          "FunctionName": {
            "location": "querystring",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "EventSourceMappings": {
            "type": "list",
            "member": {
              "shape": "Sy"
            }
          }
        }
      }
    },
    "ListFunctions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MasterRegion": {
            "location": "querystring",
            "locationName": "MasterRegion"
          },
          "FunctionVersion": {
            "location": "querystring",
            "locationName": "FunctionVersion"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Functions": {
            "shape": "S3k"
          }
        }
      }
    },
    "ListLayerVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName"
        ],
        "members": {
          "CompatibleRuntime": {
            "location": "querystring",
            "locationName": "CompatibleRuntime"
          },
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "LayerVersions": {
            "type": "list",
            "member": {
              "shape": "S3p"
            }
          }
        }
      }
    },
    "ListLayers": {
      "http": {
        "method": "GET",
        "requestUri": "/2018-10-31/layers",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "CompatibleRuntime": {
            "location": "querystring",
            "locationName": "CompatibleRuntime"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Layers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LayerName": {},
                "LayerArn": {},
                "LatestMatchingVersion": {
                  "shape": "S3p"
                }
              }
            }
          }
        }
      }
    },
    "ListTags": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-03-31/tags/{ARN}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S1p"
          }
        }
      }
    },
    "ListVersionsByFunction": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Marker": {
            "location": "querystring",
            "locationName": "Marker"
          },
          "MaxItems": {
            "location": "querystring",
            "locationName": "MaxItems",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextMarker": {},
          "Versions": {
            "shape": "S3k"
          }
        }
      }
    },
    "PublishLayerVersion": {
      "http": {
        "requestUri": "/2018-10-31/layers/{LayerName}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "Content"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "Description": {},
          "Content": {
            "type": "structure",
            "members": {
              "S3Bucket": {},
              "S3Key": {},
              "S3ObjectVersion": {},
              "ZipFile": {
                "shape": "S14"
              }
            }
          },
          "CompatibleRuntimes": {
            "shape": "S2u"
          },
          "LicenseInfo": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Content": {
            "shape": "S2s"
          },
          "LayerArn": {},
          "LayerVersionArn": {},
          "Description": {},
          "CreatedDate": {},
          "Version": {
            "type": "long"
          },
          "CompatibleRuntimes": {
            "shape": "S2u"
          },
          "LicenseInfo": {}
        }
      }
    },
    "PublishVersion": {
      "http": {
        "requestUri": "/2015-03-31/functions/{FunctionName}/versions",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "CodeSha256": {},
          "Description": {},
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "S1u"
      }
    },
    "PutFunctionConcurrency": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-10-31/functions/{FunctionName}/concurrency",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "ReservedConcurrentExecutions"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "ReservedConcurrentExecutions": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "S2n"
      }
    },
    "RemoveLayerVersionPermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2018-10-31/layers/{LayerName}/versions/{VersionNumber}/policy/{StatementId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "LayerName",
          "VersionNumber",
          "StatementId"
        ],
        "members": {
          "LayerName": {
            "location": "uri",
            "locationName": "LayerName"
          },
          "VersionNumber": {
            "location": "uri",
            "locationName": "VersionNumber",
            "type": "long"
          },
          "StatementId": {
            "location": "uri",
            "locationName": "StatementId"
          },
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      }
    },
    "RemovePermission": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-03-31/functions/{FunctionName}/policy/{StatementId}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "StatementId"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "StatementId": {
            "location": "uri",
            "locationName": "StatementId"
          },
          "Qualifier": {
            "location": "querystring",
            "locationName": "Qualifier"
          },
          "RevisionId": {
            "location": "querystring",
            "locationName": "RevisionId"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2017-03-31/tags/{ARN}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "Tags"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          },
          "Tags": {
            "shape": "S1p"
          }
        }
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-03-31/tags/{ARN}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "TagKeys"
        ],
        "members": {
          "Resource": {
            "location": "uri",
            "locationName": "ARN"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/aliases/{Name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName",
          "Name"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Name": {
            "location": "uri",
            "locationName": "Name"
          },
          "FunctionVersion": {},
          "Description": {},
          "RoutingConfig": {
            "shape": "Sn"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "Sr"
      }
    },
    "UpdateEventSourceMapping": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/event-source-mappings/{UUID}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "required": [
          "UUID"
        ],
        "members": {
          "UUID": {
            "location": "uri",
            "locationName": "UUID"
          },
          "FunctionName": {},
          "Enabled": {
            "type": "boolean"
          },
          "BatchSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "shape": "Sy"
      }
    },
    "UpdateFunctionCode": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/code",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "ZipFile": {
            "shape": "S14"
          },
          "S3Bucket": {},
          "S3Key": {},
          "S3ObjectVersion": {},
          "Publish": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "RevisionId": {}
        }
      },
      "output": {
        "shape": "S1u"
      }
    },
    "UpdateFunctionConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/2015-03-31/functions/{FunctionName}/configuration",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "required": [
          "FunctionName"
        ],
        "members": {
          "FunctionName": {
            "location": "uri",
            "locationName": "FunctionName"
          },
          "Role": {},
          "Handler": {},
          "Description": {},
          "Timeout": {
            "type": "integer"
          },
          "MemorySize": {
            "type": "integer"
          },
          "VpcConfig": {
            "shape": "S1b"
          },
          "Environment": {
            "shape": "S1i"
          },
          "Runtime": {},
          "DeadLetterConfig": {
            "shape": "S1g"
          },
          "KMSKeyArn": {},
          "TracingConfig": {
            "shape": "S1n"
          },
          "RevisionId": {},
          "Layers": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "shape": "S1u"
      }
    }
  },
  "shapes": {
    "Sn": {
      "type": "structure",
      "members": {
        "AdditionalVersionWeights": {
          "type": "map",
          "key": {},
          "value": {
            "type": "double"
          }
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "AliasArn": {},
        "Name": {},
        "FunctionVersion": {},
        "Description": {},
        "RoutingConfig": {
          "shape": "Sn"
        },
        "RevisionId": {}
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "UUID": {},
        "BatchSize": {
          "type": "integer"
        },
        "EventSourceArn": {},
        "FunctionArn": {},
        "LastModified": {
          "type": "timestamp"
        },
        "LastProcessingResult": {},
        "State": {},
        "StateTransitionReason": {}
      }
    },
    "S14": {
      "type": "blob",
      "sensitive": true
    },
    "S1b": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "S1c"
        },
        "SecurityGroupIds": {
          "shape": "S1e"
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {}
    },
    "S1e": {
      "type": "list",
      "member": {}
    },
    "S1g": {
      "type": "structure",
      "members": {
        "TargetArn": {}
      }
    },
    "S1i": {
      "type": "structure",
      "members": {
        "Variables": {
          "shape": "S1j"
        }
      }
    },
    "S1j": {
      "type": "map",
      "key": {
        "type": "string",
        "sensitive": true
      },
      "value": {
        "type": "string",
        "sensitive": true
      },
      "sensitive": true
    },
    "S1n": {
      "type": "structure",
      "members": {
        "Mode": {}
      }
    },
    "S1p": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1s": {
      "type": "list",
      "member": {}
    },
    "S1u": {
      "type": "structure",
      "members": {
        "FunctionName": {},
        "FunctionArn": {},
        "Runtime": {},
        "Role": {},
        "Handler": {},
        "CodeSize": {
          "type": "long"
        },
        "Description": {},
        "Timeout": {
          "type": "integer"
        },
        "MemorySize": {
          "type": "integer"
        },
        "LastModified": {},
        "CodeSha256": {},
        "Version": {},
        "VpcConfig": {
          "type": "structure",
          "members": {
            "SubnetIds": {
              "shape": "S1c"
            },
            "SecurityGroupIds": {
              "shape": "S1e"
            },
            "VpcId": {}
          }
        },
        "DeadLetterConfig": {
          "shape": "S1g"
        },
        "Environment": {
          "type": "structure",
          "members": {
            "Variables": {
              "shape": "S1j"
            },
            "Error": {
              "type": "structure",
              "members": {
                "ErrorCode": {},
                "Message": {
                  "type": "string",
                  "sensitive": true
                }
              }
            }
          }
        },
        "KMSKeyArn": {},
        "TracingConfig": {
          "type": "structure",
          "members": {
            "Mode": {}
          }
        },
        "MasterArn": {},
        "RevisionId": {},
        "Layers": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Arn": {},
              "CodeSize": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S2n": {
      "type": "structure",
      "members": {
        "ReservedConcurrentExecutions": {
          "type": "integer"
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "Content": {
          "shape": "S2s"
        },
        "LayerArn": {},
        "LayerVersionArn": {},
        "Description": {},
        "CreatedDate": {},
        "Version": {
          "type": "long"
        },
        "CompatibleRuntimes": {
          "shape": "S2u"
        },
        "LicenseInfo": {}
      }
    },
    "S2s": {
      "type": "structure",
      "members": {
        "Location": {},
        "CodeSha256": {},
        "CodeSize": {
          "type": "long"
        }
      }
    },
    "S2u": {
      "type": "list",
      "member": {}
    },
    "S3k": {
      "type": "list",
      "member": {
        "shape": "S1u"
      }
    },
    "S3p": {
      "type": "structure",
      "members": {
        "LayerVersionArn": {},
        "Version": {
          "type": "long"
        },
        "Description": {},
        "CreatedDate": {},
        "CompatibleRuntimes": {
          "shape": "S2u"
        },
        "LicenseInfo": {}
      }
    }
  }
}