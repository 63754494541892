{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-07-24",
    "endpointPrefix": "api.sagemaker",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "SageMaker",
    "serviceFullName": "Amazon SageMaker Service",
    "serviceId": "SageMaker",
    "signatureVersion": "v4",
    "signingName": "sagemaker",
    "targetPrefix": "SageMaker",
    "uid": "sagemaker-2017-07-24"
  },
  "operations": {
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3"
          }
        }
      }
    },
    "CreateAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "AlgorithmName",
          "TrainingSpecification"
        ],
        "members": {
          "AlgorithmName": {},
          "AlgorithmDescription": {},
          "TrainingSpecification": {
            "shape": "Sb"
          },
          "InferenceSpecification": {
            "shape": "S17"
          },
          "ValidationSpecification": {
            "shape": "S1j"
          },
          "CertifyForMarketplace": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AlgorithmArn"
        ],
        "members": {
          "AlgorithmArn": {}
        }
      }
    },
    "CreateCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName",
          "GitConfig"
        ],
        "members": {
          "CodeRepositoryName": {},
          "GitConfig": {
            "shape": "S2z"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositoryArn"
        ],
        "members": {
          "CodeRepositoryArn": {}
        }
      }
    },
    "CreateCompilationJob": {
      "input": {
        "type": "structure",
        "required": [
          "CompilationJobName",
          "RoleArn",
          "InputConfig",
          "OutputConfig",
          "StoppingCondition"
        ],
        "members": {
          "CompilationJobName": {},
          "RoleArn": {},
          "InputConfig": {
            "shape": "S36"
          },
          "OutputConfig": {
            "shape": "S39"
          },
          "StoppingCondition": {
            "shape": "S2c"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CompilationJobArn"
        ],
        "members": {
          "CompilationJobArn": {}
        }
      }
    },
    "CreateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "EndpointConfigName"
        ],
        "members": {
          "EndpointName": {},
          "EndpointConfigName": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "CreateEndpointConfig": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointConfigName",
          "ProductionVariants"
        ],
        "members": {
          "EndpointConfigName": {},
          "ProductionVariants": {
            "shape": "S3j"
          },
          "Tags": {
            "shape": "S3"
          },
          "KmsKeyId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointConfigArn"
        ],
        "members": {
          "EndpointConfigArn": {}
        }
      }
    },
    "CreateHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName",
          "HyperParameterTuningJobConfig"
        ],
        "members": {
          "HyperParameterTuningJobName": {},
          "HyperParameterTuningJobConfig": {
            "shape": "S3u"
          },
          "TrainingJobDefinition": {
            "shape": "S48"
          },
          "WarmStartConfig": {
            "shape": "S4i"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobArn"
        ],
        "members": {
          "HyperParameterTuningJobArn": {}
        }
      }
    },
    "CreateLabelingJob": {
      "input": {
        "type": "structure",
        "required": [
          "LabelingJobName",
          "LabelAttributeName",
          "InputConfig",
          "OutputConfig",
          "RoleArn",
          "HumanTaskConfig"
        ],
        "members": {
          "LabelingJobName": {},
          "LabelAttributeName": {},
          "InputConfig": {
            "shape": "S4r"
          },
          "OutputConfig": {
            "shape": "S4x"
          },
          "RoleArn": {},
          "LabelCategoryConfigS3Uri": {},
          "StoppingConditions": {
            "shape": "S4y"
          },
          "LabelingJobAlgorithmsConfig": {
            "shape": "S51"
          },
          "HumanTaskConfig": {
            "shape": "S55"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "LabelingJobArn"
        ],
        "members": {
          "LabelingJobArn": {}
        }
      }
    },
    "CreateModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName",
          "ExecutionRoleArn"
        ],
        "members": {
          "ModelName": {},
          "PrimaryContainer": {
            "shape": "S5q"
          },
          "Containers": {
            "shape": "S5u"
          },
          "ExecutionRoleArn": {},
          "Tags": {
            "shape": "S3"
          },
          "VpcConfig": {
            "shape": "S4c"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelArn"
        ],
        "members": {
          "ModelArn": {}
        }
      }
    },
    "CreateModelPackage": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageName"
        ],
        "members": {
          "ModelPackageName": {},
          "ModelPackageDescription": {},
          "InferenceSpecification": {
            "shape": "S17"
          },
          "ValidationSpecification": {
            "shape": "S5x"
          },
          "SourceAlgorithmSpecification": {
            "shape": "S60"
          },
          "CertifyForMarketplace": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageArn"
        ],
        "members": {
          "ModelPackageArn": {}
        }
      }
    },
    "CreateNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName",
          "InstanceType",
          "RoleArn"
        ],
        "members": {
          "NotebookInstanceName": {},
          "InstanceType": {},
          "SubnetId": {},
          "SecurityGroupIds": {
            "shape": "S68"
          },
          "RoleArn": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "S3"
          },
          "LifecycleConfigName": {},
          "DirectInternetAccess": {},
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "AcceleratorTypes": {
            "shape": "S6c"
          },
          "DefaultCodeRepository": {},
          "AdditionalCodeRepositories": {
            "shape": "S6f"
          },
          "RootAccess": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceArn": {}
        }
      }
    },
    "CreateNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {},
          "OnCreate": {
            "shape": "S6k"
          },
          "OnStart": {
            "shape": "S6k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceLifecycleConfigArn": {}
        }
      }
    },
    "CreatePresignedNotebookInstanceUrl": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {},
          "SessionExpirationDurationInSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthorizedUrl": {}
        }
      }
    },
    "CreateTrainingJob": {
      "input": {
        "type": "structure",
        "required": [
          "TrainingJobName",
          "AlgorithmSpecification",
          "RoleArn",
          "OutputDataConfig",
          "ResourceConfig",
          "StoppingCondition"
        ],
        "members": {
          "TrainingJobName": {},
          "HyperParameters": {
            "shape": "S1o"
          },
          "AlgorithmSpecification": {
            "shape": "S6v"
          },
          "RoleArn": {},
          "InputDataConfig": {
            "shape": "S1q"
          },
          "OutputDataConfig": {
            "shape": "S27"
          },
          "ResourceConfig": {
            "shape": "S29"
          },
          "VpcConfig": {
            "shape": "S4c"
          },
          "StoppingCondition": {
            "shape": "S2c"
          },
          "Tags": {
            "shape": "S3"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          },
          "EnableInterContainerTrafficEncryption": {
            "type": "boolean"
          },
          "EnableManagedSpotTraining": {
            "type": "boolean"
          },
          "CheckpointConfig": {
            "shape": "S4h"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobArn"
        ],
        "members": {
          "TrainingJobArn": {}
        }
      }
    },
    "CreateTransformJob": {
      "input": {
        "type": "structure",
        "required": [
          "TransformJobName",
          "ModelName",
          "TransformInput",
          "TransformOutput",
          "TransformResources"
        ],
        "members": {
          "TransformJobName": {},
          "ModelName": {},
          "MaxConcurrentTransforms": {
            "type": "integer"
          },
          "MaxPayloadInMB": {
            "type": "integer"
          },
          "BatchStrategy": {},
          "Environment": {
            "shape": "S2j"
          },
          "TransformInput": {
            "shape": "S2m"
          },
          "TransformOutput": {
            "shape": "S2q"
          },
          "TransformResources": {
            "shape": "S2t"
          },
          "DataProcessing": {
            "shape": "S70"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformJobArn"
        ],
        "members": {
          "TransformJobArn": {}
        }
      }
    },
    "CreateWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName",
          "MemberDefinitions",
          "Description"
        ],
        "members": {
          "WorkteamName": {},
          "MemberDefinitions": {
            "shape": "S77"
          },
          "Description": {},
          "NotificationConfiguration": {
            "shape": "S7e"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkteamArn": {}
        }
      }
    },
    "DeleteAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "AlgorithmName"
        ],
        "members": {
          "AlgorithmName": {}
        }
      }
    },
    "DeleteCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName"
        ],
        "members": {
          "CodeRepositoryName": {}
        }
      }
    },
    "DeleteEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      }
    },
    "DeleteEndpointConfig": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointConfigName"
        ],
        "members": {
          "EndpointConfigName": {}
        }
      }
    },
    "DeleteModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      }
    },
    "DeleteModelPackage": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageName"
        ],
        "members": {
          "ModelPackageName": {}
        }
      }
    },
    "DeleteNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      }
    },
    "DeleteNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {}
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName"
        ],
        "members": {
          "WorkteamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Success"
        ],
        "members": {
          "Success": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeAlgorithm": {
      "input": {
        "type": "structure",
        "required": [
          "AlgorithmName"
        ],
        "members": {
          "AlgorithmName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AlgorithmName",
          "AlgorithmArn",
          "CreationTime",
          "TrainingSpecification",
          "AlgorithmStatus",
          "AlgorithmStatusDetails"
        ],
        "members": {
          "AlgorithmName": {},
          "AlgorithmArn": {},
          "AlgorithmDescription": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "TrainingSpecification": {
            "shape": "Sb"
          },
          "InferenceSpecification": {
            "shape": "S17"
          },
          "ValidationSpecification": {
            "shape": "S1j"
          },
          "AlgorithmStatus": {},
          "AlgorithmStatusDetails": {
            "type": "structure",
            "members": {
              "ValidationStatuses": {
                "shape": "S80"
              },
              "ImageScanStatuses": {
                "shape": "S80"
              }
            }
          },
          "ProductId": {},
          "CertifyForMarketplace": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName"
        ],
        "members": {
          "CodeRepositoryName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositoryName",
          "CodeRepositoryArn",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "CodeRepositoryName": {},
          "CodeRepositoryArn": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "GitConfig": {
            "shape": "S2z"
          }
        }
      }
    },
    "DescribeCompilationJob": {
      "input": {
        "type": "structure",
        "required": [
          "CompilationJobName"
        ],
        "members": {
          "CompilationJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CompilationJobName",
          "CompilationJobArn",
          "CompilationJobStatus",
          "StoppingCondition",
          "CreationTime",
          "LastModifiedTime",
          "FailureReason",
          "ModelArtifacts",
          "RoleArn",
          "InputConfig",
          "OutputConfig"
        ],
        "members": {
          "CompilationJobName": {},
          "CompilationJobArn": {},
          "CompilationJobStatus": {},
          "CompilationStartTime": {
            "type": "timestamp"
          },
          "CompilationEndTime": {
            "type": "timestamp"
          },
          "StoppingCondition": {
            "shape": "S2c"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "FailureReason": {},
          "ModelArtifacts": {
            "shape": "S8c"
          },
          "RoleArn": {},
          "InputConfig": {
            "shape": "S36"
          },
          "OutputConfig": {
            "shape": "S39"
          }
        }
      }
    },
    "DescribeEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName"
        ],
        "members": {
          "EndpointName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointName",
          "EndpointArn",
          "EndpointConfigName",
          "EndpointStatus",
          "CreationTime",
          "LastModifiedTime"
        ],
        "members": {
          "EndpointName": {},
          "EndpointArn": {},
          "EndpointConfigName": {},
          "ProductionVariants": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "VariantName"
              ],
              "members": {
                "VariantName": {},
                "DeployedImages": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "SpecifiedImage": {},
                      "ResolvedImage": {},
                      "ResolutionTime": {
                        "type": "timestamp"
                      }
                    }
                  }
                },
                "CurrentWeight": {
                  "type": "float"
                },
                "DesiredWeight": {
                  "type": "float"
                },
                "CurrentInstanceCount": {
                  "type": "integer"
                },
                "DesiredInstanceCount": {
                  "type": "integer"
                }
              }
            }
          },
          "EndpointStatus": {},
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeEndpointConfig": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointConfigName"
        ],
        "members": {
          "EndpointConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointConfigName",
          "EndpointConfigArn",
          "ProductionVariants",
          "CreationTime"
        ],
        "members": {
          "EndpointConfigName": {},
          "EndpointConfigArn": {},
          "ProductionVariants": {
            "shape": "S3j"
          },
          "KmsKeyId": {},
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName"
        ],
        "members": {
          "HyperParameterTuningJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName",
          "HyperParameterTuningJobArn",
          "HyperParameterTuningJobConfig",
          "HyperParameterTuningJobStatus",
          "CreationTime",
          "TrainingJobStatusCounters",
          "ObjectiveStatusCounters"
        ],
        "members": {
          "HyperParameterTuningJobName": {},
          "HyperParameterTuningJobArn": {},
          "HyperParameterTuningJobConfig": {
            "shape": "S3u"
          },
          "TrainingJobDefinition": {
            "shape": "S48"
          },
          "HyperParameterTuningJobStatus": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "HyperParameterTuningEndTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "TrainingJobStatusCounters": {
            "shape": "S8p"
          },
          "ObjectiveStatusCounters": {
            "shape": "S8r"
          },
          "BestTrainingJob": {
            "shape": "S8t"
          },
          "OverallBestTrainingJob": {
            "shape": "S8t"
          },
          "WarmStartConfig": {
            "shape": "S4i"
          },
          "FailureReason": {}
        }
      }
    },
    "DescribeLabelingJob": {
      "input": {
        "type": "structure",
        "required": [
          "LabelingJobName"
        ],
        "members": {
          "LabelingJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "LabelingJobStatus",
          "LabelCounters",
          "CreationTime",
          "LastModifiedTime",
          "JobReferenceCode",
          "LabelingJobName",
          "LabelingJobArn",
          "InputConfig",
          "OutputConfig",
          "RoleArn",
          "HumanTaskConfig"
        ],
        "members": {
          "LabelingJobStatus": {},
          "LabelCounters": {
            "shape": "S91"
          },
          "FailureReason": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "JobReferenceCode": {},
          "LabelingJobName": {},
          "LabelingJobArn": {},
          "LabelAttributeName": {},
          "InputConfig": {
            "shape": "S4r"
          },
          "OutputConfig": {
            "shape": "S4x"
          },
          "RoleArn": {},
          "LabelCategoryConfigS3Uri": {},
          "StoppingConditions": {
            "shape": "S4y"
          },
          "LabelingJobAlgorithmsConfig": {
            "shape": "S51"
          },
          "HumanTaskConfig": {
            "shape": "S55"
          },
          "Tags": {
            "shape": "S3"
          },
          "LabelingJobOutput": {
            "shape": "S94"
          }
        }
      }
    },
    "DescribeModel": {
      "input": {
        "type": "structure",
        "required": [
          "ModelName"
        ],
        "members": {
          "ModelName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelName",
          "ExecutionRoleArn",
          "CreationTime",
          "ModelArn"
        ],
        "members": {
          "ModelName": {},
          "PrimaryContainer": {
            "shape": "S5q"
          },
          "Containers": {
            "shape": "S5u"
          },
          "ExecutionRoleArn": {},
          "VpcConfig": {
            "shape": "S4c"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "ModelArn": {},
          "EnableNetworkIsolation": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeModelPackage": {
      "input": {
        "type": "structure",
        "required": [
          "ModelPackageName"
        ],
        "members": {
          "ModelPackageName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageName",
          "ModelPackageArn",
          "CreationTime",
          "ModelPackageStatus",
          "ModelPackageStatusDetails"
        ],
        "members": {
          "ModelPackageName": {},
          "ModelPackageArn": {},
          "ModelPackageDescription": {},
          "CreationTime": {
            "type": "timestamp"
          },
          "InferenceSpecification": {
            "shape": "S17"
          },
          "SourceAlgorithmSpecification": {
            "shape": "S60"
          },
          "ValidationSpecification": {
            "shape": "S5x"
          },
          "ModelPackageStatus": {},
          "ModelPackageStatusDetails": {
            "type": "structure",
            "required": [
              "ValidationStatuses"
            ],
            "members": {
              "ValidationStatuses": {
                "shape": "S9b"
              },
              "ImageScanStatuses": {
                "shape": "S9b"
              }
            }
          },
          "CertifyForMarketplace": {
            "type": "boolean"
          }
        }
      }
    },
    "DescribeNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceArn": {},
          "NotebookInstanceName": {},
          "NotebookInstanceStatus": {},
          "FailureReason": {},
          "Url": {},
          "InstanceType": {},
          "SubnetId": {},
          "SecurityGroups": {
            "shape": "S68"
          },
          "RoleArn": {},
          "KmsKeyId": {},
          "NetworkInterfaceId": {},
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "NotebookInstanceLifecycleConfigName": {},
          "DirectInternetAccess": {},
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "AcceleratorTypes": {
            "shape": "S6c"
          },
          "DefaultCodeRepository": {},
          "AdditionalCodeRepositories": {
            "shape": "S6f"
          },
          "RootAccess": {}
        }
      }
    },
    "DescribeNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NotebookInstanceLifecycleConfigArn": {},
          "NotebookInstanceLifecycleConfigName": {},
          "OnCreate": {
            "shape": "S6k"
          },
          "OnStart": {
            "shape": "S6k"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "CreationTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeSubscribedWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamArn"
        ],
        "members": {
          "WorkteamArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SubscribedWorkteam"
        ],
        "members": {
          "SubscribedWorkteam": {
            "shape": "S9m"
          }
        }
      }
    },
    "DescribeTrainingJob": {
      "input": {
        "type": "structure",
        "required": [
          "TrainingJobName"
        ],
        "members": {
          "TrainingJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobName",
          "TrainingJobArn",
          "ModelArtifacts",
          "TrainingJobStatus",
          "SecondaryStatus",
          "AlgorithmSpecification",
          "ResourceConfig",
          "StoppingCondition",
          "CreationTime"
        ],
        "members": {
          "TrainingJobName": {},
          "TrainingJobArn": {},
          "TuningJobArn": {},
          "LabelingJobArn": {},
          "ModelArtifacts": {
            "shape": "S8c"
          },
          "TrainingJobStatus": {},
          "SecondaryStatus": {},
          "FailureReason": {},
          "HyperParameters": {
            "shape": "S1o"
          },
          "AlgorithmSpecification": {
            "shape": "S6v"
          },
          "RoleArn": {},
          "InputDataConfig": {
            "shape": "S1q"
          },
          "OutputDataConfig": {
            "shape": "S27"
          },
          "ResourceConfig": {
            "shape": "S29"
          },
          "VpcConfig": {
            "shape": "S4c"
          },
          "StoppingCondition": {
            "shape": "S2c"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "TrainingStartTime": {
            "type": "timestamp"
          },
          "TrainingEndTime": {
            "type": "timestamp"
          },
          "LastModifiedTime": {
            "type": "timestamp"
          },
          "SecondaryStatusTransitions": {
            "shape": "S9q"
          },
          "FinalMetricDataList": {
            "shape": "S9t"
          },
          "EnableNetworkIsolation": {
            "type": "boolean"
          },
          "EnableInterContainerTrafficEncryption": {
            "type": "boolean"
          },
          "EnableManagedSpotTraining": {
            "type": "boolean"
          },
          "CheckpointConfig": {
            "shape": "S4h"
          },
          "TrainingTimeInSeconds": {
            "type": "integer"
          },
          "BillableTimeInSeconds": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeTransformJob": {
      "input": {
        "type": "structure",
        "required": [
          "TransformJobName"
        ],
        "members": {
          "TransformJobName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformJobName",
          "TransformJobArn",
          "TransformJobStatus",
          "ModelName",
          "TransformInput",
          "TransformResources",
          "CreationTime"
        ],
        "members": {
          "TransformJobName": {},
          "TransformJobArn": {},
          "TransformJobStatus": {},
          "FailureReason": {},
          "ModelName": {},
          "MaxConcurrentTransforms": {
            "type": "integer"
          },
          "MaxPayloadInMB": {
            "type": "integer"
          },
          "BatchStrategy": {},
          "Environment": {
            "shape": "S2j"
          },
          "TransformInput": {
            "shape": "S2m"
          },
          "TransformOutput": {
            "shape": "S2q"
          },
          "TransformResources": {
            "shape": "S2t"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "TransformStartTime": {
            "type": "timestamp"
          },
          "TransformEndTime": {
            "type": "timestamp"
          },
          "LabelingJobArn": {},
          "DataProcessing": {
            "shape": "S70"
          }
        }
      }
    },
    "DescribeWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName"
        ],
        "members": {
          "WorkteamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workteam"
        ],
        "members": {
          "Workteam": {
            "shape": "Sa3"
          }
        }
      }
    },
    "GetSearchSuggestions": {
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {},
          "SuggestionQuery": {
            "type": "structure",
            "members": {
              "PropertyNameQuery": {
                "type": "structure",
                "required": [
                  "PropertyNameHint"
                ],
                "members": {
                  "PropertyNameHint": {}
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PropertyNameSuggestions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PropertyName": {}
              }
            }
          }
        }
      }
    },
    "ListAlgorithms": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "AlgorithmSummaryList"
        ],
        "members": {
          "AlgorithmSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AlgorithmName",
                "AlgorithmArn",
                "CreationTime",
                "AlgorithmStatus"
              ],
              "members": {
                "AlgorithmName": {},
                "AlgorithmArn": {},
                "AlgorithmDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "AlgorithmStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCodeRepositories": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositorySummaryList"
        ],
        "members": {
          "CodeRepositorySummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CodeRepositoryName",
                "CodeRepositoryArn",
                "CreationTime",
                "LastModifiedTime"
              ],
              "members": {
                "CodeRepositoryName": {},
                "CodeRepositoryArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "GitConfig": {
                  "shape": "S2z"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCompilationJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CompilationJobSummaries"
        ],
        "members": {
          "CompilationJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CompilationJobName",
                "CompilationJobArn",
                "CreationTime",
                "CompilationTargetDevice",
                "CompilationJobStatus"
              ],
              "members": {
                "CompilationJobName": {},
                "CompilationJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "CompilationStartTime": {
                  "type": "timestamp"
                },
                "CompilationEndTime": {
                  "type": "timestamp"
                },
                "CompilationTargetDevice": {},
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "CompilationJobStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpointConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointConfigs"
        ],
        "members": {
          "EndpointConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EndpointConfigName",
                "EndpointConfigArn",
                "CreationTime"
              ],
              "members": {
                "EndpointConfigName": {},
                "EndpointConfigArn": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Endpoints"
        ],
        "members": {
          "Endpoints": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "EndpointName",
                "EndpointArn",
                "CreationTime",
                "LastModifiedTime",
                "EndpointStatus"
              ],
              "members": {
                "EndpointName": {},
                "EndpointArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "EndpointStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListHyperParameterTuningJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "StatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobSummaries"
        ],
        "members": {
          "HyperParameterTuningJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "HyperParameterTuningJobName",
                "HyperParameterTuningJobArn",
                "HyperParameterTuningJobStatus",
                "Strategy",
                "CreationTime",
                "TrainingJobStatusCounters",
                "ObjectiveStatusCounters"
              ],
              "members": {
                "HyperParameterTuningJobName": {},
                "HyperParameterTuningJobArn": {},
                "HyperParameterTuningJobStatus": {},
                "Strategy": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "HyperParameterTuningEndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "TrainingJobStatusCounters": {
                  "shape": "S8p"
                },
                "ObjectiveStatusCounters": {
                  "shape": "S8r"
                },
                "ResourceLimits": {
                  "shape": "S3w"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLabelingJobs": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "NameContains": {},
          "SortBy": {},
          "SortOrder": {},
          "StatusEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LabelingJobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LabelingJobName",
                "LabelingJobArn",
                "CreationTime",
                "LastModifiedTime",
                "LabelingJobStatus",
                "LabelCounters",
                "WorkteamArn",
                "PreHumanTaskLambdaArn"
              ],
              "members": {
                "LabelingJobName": {},
                "LabelingJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "LabelingJobStatus": {},
                "LabelCounters": {
                  "shape": "S91"
                },
                "WorkteamArn": {},
                "PreHumanTaskLambdaArn": {},
                "AnnotationConsolidationLambdaArn": {},
                "FailureReason": {},
                "LabelingJobOutput": {
                  "shape": "S94"
                },
                "InputConfig": {
                  "shape": "S4r"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLabelingJobsForWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamArn"
        ],
        "members": {
          "WorkteamArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "JobReferenceCodeContains": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "LabelingJobSummaryList"
        ],
        "members": {
          "LabelingJobSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "JobReferenceCode",
                "WorkRequesterAccountId",
                "CreationTime"
              ],
              "members": {
                "LabelingJobName": {},
                "JobReferenceCode": {},
                "WorkRequesterAccountId": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LabelCounters": {
                  "type": "structure",
                  "members": {
                    "HumanLabeled": {
                      "type": "integer"
                    },
                    "PendingHuman": {
                      "type": "integer"
                    },
                    "Total": {
                      "type": "integer"
                    }
                  }
                },
                "NumberOfHumanWorkersPerDataObject": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModelPackages": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "NextToken": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ModelPackageSummaryList"
        ],
        "members": {
          "ModelPackageSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelPackageName",
                "ModelPackageArn",
                "CreationTime",
                "ModelPackageStatus"
              ],
              "members": {
                "ModelPackageName": {},
                "ModelPackageArn": {},
                "ModelPackageDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "ModelPackageStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListModels": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Models"
        ],
        "members": {
          "Models": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ModelName",
                "ModelArn",
                "CreationTime"
              ],
              "members": {
                "ModelName": {},
                "ModelArn": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListNotebookInstanceLifecycleConfigs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NotebookInstanceLifecycleConfigs": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "NotebookInstanceLifecycleConfigName",
                "NotebookInstanceLifecycleConfigArn"
              ],
              "members": {
                "NotebookInstanceLifecycleConfigName": {},
                "NotebookInstanceLifecycleConfigArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListNotebookInstances": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "StatusEquals": {},
          "NotebookInstanceLifecycleConfigNameContains": {},
          "DefaultCodeRepositoryContains": {},
          "AdditionalCodeRepositoryEquals": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "NotebookInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "NotebookInstanceName",
                "NotebookInstanceArn"
              ],
              "members": {
                "NotebookInstanceName": {},
                "NotebookInstanceArn": {},
                "NotebookInstanceStatus": {},
                "Url": {},
                "InstanceType": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "NotebookInstanceLifecycleConfigName": {},
                "DefaultCodeRepository": {},
                "AdditionalCodeRepositories": {
                  "shape": "S6f"
                }
              }
            }
          }
        }
      }
    },
    "ListSubscribedWorkteams": {
      "input": {
        "type": "structure",
        "members": {
          "NameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SubscribedWorkteams"
        ],
        "members": {
          "SubscribedWorkteams": {
            "type": "list",
            "member": {
              "shape": "S9m"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3"
          },
          "NextToken": {}
        }
      }
    },
    "ListTrainingJobs": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobSummaries"
        ],
        "members": {
          "TrainingJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TrainingJobName",
                "TrainingJobArn",
                "CreationTime",
                "TrainingJobStatus"
              ],
              "members": {
                "TrainingJobName": {},
                "TrainingJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "TrainingEndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "TrainingJobStatus": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTrainingJobsForHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName"
        ],
        "members": {
          "HyperParameterTuningJobName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TrainingJobSummaries"
        ],
        "members": {
          "TrainingJobSummaries": {
            "type": "list",
            "member": {
              "shape": "S8t"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTransformJobs": {
      "input": {
        "type": "structure",
        "members": {
          "CreationTimeAfter": {
            "type": "timestamp"
          },
          "CreationTimeBefore": {
            "type": "timestamp"
          },
          "LastModifiedTimeAfter": {
            "type": "timestamp"
          },
          "LastModifiedTimeBefore": {
            "type": "timestamp"
          },
          "NameContains": {},
          "StatusEquals": {},
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "TransformJobSummaries"
        ],
        "members": {
          "TransformJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "TransformJobName",
                "TransformJobArn",
                "CreationTime",
                "TransformJobStatus"
              ],
              "members": {
                "TransformJobName": {},
                "TransformJobArn": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "TransformEndTime": {
                  "type": "timestamp"
                },
                "LastModifiedTime": {
                  "type": "timestamp"
                },
                "TransformJobStatus": {},
                "FailureReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkteams": {
      "input": {
        "type": "structure",
        "members": {
          "SortBy": {},
          "SortOrder": {},
          "NameContains": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workteams"
        ],
        "members": {
          "Workteams": {
            "type": "list",
            "member": {
              "shape": "Sa3"
            }
          },
          "NextToken": {}
        }
      }
    },
    "RenderUiTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "UiTemplate",
          "Task",
          "RoleArn"
        ],
        "members": {
          "UiTemplate": {
            "type": "structure",
            "required": [
              "Content"
            ],
            "members": {
              "Content": {}
            }
          },
          "Task": {
            "type": "structure",
            "required": [
              "Input"
            ],
            "members": {
              "Input": {}
            }
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RenderedContent",
          "Errors"
        ],
        "members": {
          "RenderedContent": {},
          "Errors": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Code",
                "Message"
              ],
              "members": {
                "Code": {},
                "Message": {}
              }
            }
          }
        }
      }
    },
    "Search": {
      "input": {
        "type": "structure",
        "required": [
          "Resource"
        ],
        "members": {
          "Resource": {},
          "SearchExpression": {
            "shape": "Sdg"
          },
          "SortBy": {},
          "SortOrder": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Results": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TrainingJob": {
                  "type": "structure",
                  "members": {
                    "TrainingJobName": {},
                    "TrainingJobArn": {},
                    "TuningJobArn": {},
                    "LabelingJobArn": {},
                    "ModelArtifacts": {
                      "shape": "S8c"
                    },
                    "TrainingJobStatus": {},
                    "SecondaryStatus": {},
                    "FailureReason": {},
                    "HyperParameters": {
                      "shape": "S1o"
                    },
                    "AlgorithmSpecification": {
                      "shape": "S6v"
                    },
                    "RoleArn": {},
                    "InputDataConfig": {
                      "shape": "S1q"
                    },
                    "OutputDataConfig": {
                      "shape": "S27"
                    },
                    "ResourceConfig": {
                      "shape": "S29"
                    },
                    "VpcConfig": {
                      "shape": "S4c"
                    },
                    "StoppingCondition": {
                      "shape": "S2c"
                    },
                    "CreationTime": {
                      "type": "timestamp"
                    },
                    "TrainingStartTime": {
                      "type": "timestamp"
                    },
                    "TrainingEndTime": {
                      "type": "timestamp"
                    },
                    "LastModifiedTime": {
                      "type": "timestamp"
                    },
                    "SecondaryStatusTransitions": {
                      "shape": "S9q"
                    },
                    "FinalMetricDataList": {
                      "shape": "S9t"
                    },
                    "EnableNetworkIsolation": {
                      "type": "boolean"
                    },
                    "EnableInterContainerTrafficEncryption": {
                      "type": "boolean"
                    },
                    "Tags": {
                      "shape": "S3"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      }
    },
    "StopCompilationJob": {
      "input": {
        "type": "structure",
        "required": [
          "CompilationJobName"
        ],
        "members": {
          "CompilationJobName": {}
        }
      }
    },
    "StopHyperParameterTuningJob": {
      "input": {
        "type": "structure",
        "required": [
          "HyperParameterTuningJobName"
        ],
        "members": {
          "HyperParameterTuningJobName": {}
        }
      }
    },
    "StopLabelingJob": {
      "input": {
        "type": "structure",
        "required": [
          "LabelingJobName"
        ],
        "members": {
          "LabelingJobName": {}
        }
      }
    },
    "StopNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {}
        }
      }
    },
    "StopTrainingJob": {
      "input": {
        "type": "structure",
        "required": [
          "TrainingJobName"
        ],
        "members": {
          "TrainingJobName": {}
        }
      }
    },
    "StopTransformJob": {
      "input": {
        "type": "structure",
        "required": [
          "TransformJobName"
        ],
        "members": {
          "TransformJobName": {}
        }
      }
    },
    "UpdateCodeRepository": {
      "input": {
        "type": "structure",
        "required": [
          "CodeRepositoryName"
        ],
        "members": {
          "CodeRepositoryName": {},
          "GitConfig": {
            "type": "structure",
            "members": {
              "SecretArn": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CodeRepositoryArn"
        ],
        "members": {
          "CodeRepositoryArn": {}
        }
      }
    },
    "UpdateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "EndpointConfigName"
        ],
        "members": {
          "EndpointName": {},
          "EndpointConfigName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "UpdateEndpointWeightsAndCapacities": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointName",
          "DesiredWeightsAndCapacities"
        ],
        "members": {
          "EndpointName": {},
          "DesiredWeightsAndCapacities": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "VariantName"
              ],
              "members": {
                "VariantName": {},
                "DesiredWeight": {
                  "type": "float"
                },
                "DesiredInstanceCount": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      }
    },
    "UpdateNotebookInstance": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceName"
        ],
        "members": {
          "NotebookInstanceName": {},
          "InstanceType": {},
          "RoleArn": {},
          "LifecycleConfigName": {},
          "DisassociateLifecycleConfig": {
            "type": "boolean"
          },
          "VolumeSizeInGB": {
            "type": "integer"
          },
          "DefaultCodeRepository": {},
          "AdditionalCodeRepositories": {
            "shape": "S6f"
          },
          "AcceleratorTypes": {
            "shape": "S6c"
          },
          "DisassociateAcceleratorTypes": {
            "type": "boolean"
          },
          "DisassociateDefaultCodeRepository": {
            "type": "boolean"
          },
          "DisassociateAdditionalCodeRepositories": {
            "type": "boolean"
          },
          "RootAccess": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateNotebookInstanceLifecycleConfig": {
      "input": {
        "type": "structure",
        "required": [
          "NotebookInstanceLifecycleConfigName"
        ],
        "members": {
          "NotebookInstanceLifecycleConfigName": {},
          "OnCreate": {
            "shape": "S6k"
          },
          "OnStart": {
            "shape": "S6k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateWorkteam": {
      "input": {
        "type": "structure",
        "required": [
          "WorkteamName"
        ],
        "members": {
          "WorkteamName": {},
          "MemberDefinitions": {
            "shape": "S77"
          },
          "Description": {},
          "NotificationConfiguration": {
            "shape": "S7e"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Workteam"
        ],
        "members": {
          "Workteam": {
            "shape": "Sa3"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "TrainingImage",
        "SupportedTrainingInstanceTypes",
        "TrainingChannels"
      ],
      "members": {
        "TrainingImage": {},
        "TrainingImageDigest": {},
        "SupportedHyperParameters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "Type"
            ],
            "members": {
              "Name": {},
              "Description": {},
              "Type": {},
              "Range": {
                "type": "structure",
                "members": {
                  "IntegerParameterRangeSpecification": {
                    "type": "structure",
                    "required": [
                      "MinValue",
                      "MaxValue"
                    ],
                    "members": {
                      "MinValue": {},
                      "MaxValue": {}
                    }
                  },
                  "ContinuousParameterRangeSpecification": {
                    "type": "structure",
                    "required": [
                      "MinValue",
                      "MaxValue"
                    ],
                    "members": {
                      "MinValue": {},
                      "MaxValue": {}
                    }
                  },
                  "CategoricalParameterRangeSpecification": {
                    "type": "structure",
                    "required": [
                      "Values"
                    ],
                    "members": {
                      "Values": {
                        "shape": "Sn"
                      }
                    }
                  }
                }
              },
              "IsTunable": {
                "type": "boolean"
              },
              "IsRequired": {
                "type": "boolean"
              },
              "DefaultValue": {}
            }
          }
        },
        "SupportedTrainingInstanceTypes": {
          "type": "list",
          "member": {}
        },
        "SupportsDistributedTraining": {
          "type": "boolean"
        },
        "MetricDefinitions": {
          "shape": "Sr"
        },
        "TrainingChannels": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Name",
              "SupportedContentTypes",
              "SupportedInputModes"
            ],
            "members": {
              "Name": {},
              "Description": {},
              "IsRequired": {
                "type": "boolean"
              },
              "SupportedContentTypes": {
                "shape": "Sy"
              },
              "SupportedCompressionTypes": {
                "type": "list",
                "member": {}
              },
              "SupportedInputModes": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "SupportedTuningJobObjectiveMetrics": {
          "type": "list",
          "member": {
            "shape": "S15"
          }
        }
      }
    },
    "Sn": {
      "type": "list",
      "member": {}
    },
    "Sr": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Regex"
        ],
        "members": {
          "Name": {},
          "Regex": {}
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {}
    },
    "S15": {
      "type": "structure",
      "required": [
        "Type",
        "MetricName"
      ],
      "members": {
        "Type": {},
        "MetricName": {}
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "Containers",
        "SupportedTransformInstanceTypes",
        "SupportedRealtimeInferenceInstanceTypes",
        "SupportedContentTypes",
        "SupportedResponseMIMETypes"
      ],
      "members": {
        "Containers": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Image"
            ],
            "members": {
              "ContainerHostname": {},
              "Image": {},
              "ImageDigest": {},
              "ModelDataUrl": {},
              "ProductId": {}
            }
          }
        },
        "SupportedTransformInstanceTypes": {
          "type": "list",
          "member": {}
        },
        "SupportedRealtimeInferenceInstanceTypes": {
          "type": "list",
          "member": {}
        },
        "SupportedContentTypes": {
          "shape": "Sy"
        },
        "SupportedResponseMIMETypes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "ValidationRole",
        "ValidationProfiles"
      ],
      "members": {
        "ValidationRole": {},
        "ValidationProfiles": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ProfileName",
              "TrainingJobDefinition"
            ],
            "members": {
              "ProfileName": {},
              "TrainingJobDefinition": {
                "type": "structure",
                "required": [
                  "TrainingInputMode",
                  "InputDataConfig",
                  "OutputDataConfig",
                  "ResourceConfig",
                  "StoppingCondition"
                ],
                "members": {
                  "TrainingInputMode": {},
                  "HyperParameters": {
                    "shape": "S1o"
                  },
                  "InputDataConfig": {
                    "shape": "S1q"
                  },
                  "OutputDataConfig": {
                    "shape": "S27"
                  },
                  "ResourceConfig": {
                    "shape": "S29"
                  },
                  "StoppingCondition": {
                    "shape": "S2c"
                  }
                }
              },
              "TransformJobDefinition": {
                "shape": "S2f"
              }
            }
          }
        }
      }
    },
    "S1o": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ChannelName",
          "DataSource"
        ],
        "members": {
          "ChannelName": {},
          "DataSource": {
            "type": "structure",
            "members": {
              "S3DataSource": {
                "type": "structure",
                "required": [
                  "S3DataType",
                  "S3Uri"
                ],
                "members": {
                  "S3DataType": {},
                  "S3Uri": {},
                  "S3DataDistributionType": {},
                  "AttributeNames": {
                    "type": "list",
                    "member": {}
                  }
                }
              },
              "FileSystemDataSource": {
                "type": "structure",
                "required": [
                  "FileSystemId",
                  "FileSystemAccessMode",
                  "FileSystemType",
                  "DirectoryPath"
                ],
                "members": {
                  "FileSystemId": {},
                  "FileSystemAccessMode": {},
                  "FileSystemType": {},
                  "DirectoryPath": {}
                }
              }
            }
          },
          "ContentType": {},
          "CompressionType": {},
          "RecordWrapperType": {},
          "InputMode": {},
          "ShuffleConfig": {
            "type": "structure",
            "required": [
              "Seed"
            ],
            "members": {
              "Seed": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "S27": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "KmsKeyId": {},
        "S3OutputPath": {}
      }
    },
    "S29": {
      "type": "structure",
      "required": [
        "InstanceType",
        "InstanceCount",
        "VolumeSizeInGB"
      ],
      "members": {
        "InstanceType": {},
        "InstanceCount": {
          "type": "integer"
        },
        "VolumeSizeInGB": {
          "type": "integer"
        },
        "VolumeKmsKeyId": {}
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "MaxRuntimeInSeconds": {
          "type": "integer"
        },
        "MaxWaitTimeInSeconds": {
          "type": "integer"
        }
      }
    },
    "S2f": {
      "type": "structure",
      "required": [
        "TransformInput",
        "TransformOutput",
        "TransformResources"
      ],
      "members": {
        "MaxConcurrentTransforms": {
          "type": "integer"
        },
        "MaxPayloadInMB": {
          "type": "integer"
        },
        "BatchStrategy": {},
        "Environment": {
          "shape": "S2j"
        },
        "TransformInput": {
          "shape": "S2m"
        },
        "TransformOutput": {
          "shape": "S2q"
        },
        "TransformResources": {
          "shape": "S2t"
        }
      }
    },
    "S2j": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2m": {
      "type": "structure",
      "required": [
        "DataSource"
      ],
      "members": {
        "DataSource": {
          "type": "structure",
          "required": [
            "S3DataSource"
          ],
          "members": {
            "S3DataSource": {
              "type": "structure",
              "required": [
                "S3DataType",
                "S3Uri"
              ],
              "members": {
                "S3DataType": {},
                "S3Uri": {}
              }
            }
          }
        },
        "ContentType": {},
        "CompressionType": {},
        "SplitType": {}
      }
    },
    "S2q": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "S3OutputPath": {},
        "Accept": {},
        "AssembleWith": {},
        "KmsKeyId": {}
      }
    },
    "S2t": {
      "type": "structure",
      "required": [
        "InstanceType",
        "InstanceCount"
      ],
      "members": {
        "InstanceType": {},
        "InstanceCount": {
          "type": "integer"
        },
        "VolumeKmsKeyId": {}
      }
    },
    "S2z": {
      "type": "structure",
      "required": [
        "RepositoryUrl"
      ],
      "members": {
        "RepositoryUrl": {},
        "Branch": {},
        "SecretArn": {}
      }
    },
    "S36": {
      "type": "structure",
      "required": [
        "S3Uri",
        "DataInputConfig",
        "Framework"
      ],
      "members": {
        "S3Uri": {},
        "DataInputConfig": {},
        "Framework": {}
      }
    },
    "S39": {
      "type": "structure",
      "required": [
        "S3OutputLocation",
        "TargetDevice"
      ],
      "members": {
        "S3OutputLocation": {},
        "TargetDevice": {}
      }
    },
    "S3j": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "VariantName",
          "ModelName",
          "InitialInstanceCount",
          "InstanceType"
        ],
        "members": {
          "VariantName": {},
          "ModelName": {},
          "InitialInstanceCount": {
            "type": "integer"
          },
          "InstanceType": {},
          "InitialVariantWeight": {
            "type": "float"
          },
          "AcceleratorType": {}
        }
      }
    },
    "S3u": {
      "type": "structure",
      "required": [
        "Strategy",
        "ResourceLimits"
      ],
      "members": {
        "Strategy": {},
        "HyperParameterTuningJobObjective": {
          "shape": "S15"
        },
        "ResourceLimits": {
          "shape": "S3w"
        },
        "ParameterRanges": {
          "type": "structure",
          "members": {
            "IntegerParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "MinValue",
                  "MaxValue"
                ],
                "members": {
                  "Name": {},
                  "MinValue": {},
                  "MaxValue": {},
                  "ScalingType": {}
                }
              }
            },
            "ContinuousParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "MinValue",
                  "MaxValue"
                ],
                "members": {
                  "Name": {},
                  "MinValue": {},
                  "MaxValue": {},
                  "ScalingType": {}
                }
              }
            },
            "CategoricalParameterRanges": {
              "type": "list",
              "member": {
                "type": "structure",
                "required": [
                  "Name",
                  "Values"
                ],
                "members": {
                  "Name": {},
                  "Values": {
                    "shape": "Sn"
                  }
                }
              }
            }
          }
        },
        "TrainingJobEarlyStoppingType": {}
      }
    },
    "S3w": {
      "type": "structure",
      "required": [
        "MaxNumberOfTrainingJobs",
        "MaxParallelTrainingJobs"
      ],
      "members": {
        "MaxNumberOfTrainingJobs": {
          "type": "integer"
        },
        "MaxParallelTrainingJobs": {
          "type": "integer"
        }
      }
    },
    "S48": {
      "type": "structure",
      "required": [
        "AlgorithmSpecification",
        "RoleArn",
        "OutputDataConfig",
        "ResourceConfig",
        "StoppingCondition"
      ],
      "members": {
        "StaticHyperParameters": {
          "shape": "S1o"
        },
        "AlgorithmSpecification": {
          "type": "structure",
          "required": [
            "TrainingInputMode"
          ],
          "members": {
            "TrainingImage": {},
            "TrainingInputMode": {},
            "AlgorithmName": {},
            "MetricDefinitions": {
              "shape": "Sr"
            }
          }
        },
        "RoleArn": {},
        "InputDataConfig": {
          "shape": "S1q"
        },
        "VpcConfig": {
          "shape": "S4c"
        },
        "OutputDataConfig": {
          "shape": "S27"
        },
        "ResourceConfig": {
          "shape": "S29"
        },
        "StoppingCondition": {
          "shape": "S2c"
        },
        "EnableNetworkIsolation": {
          "type": "boolean"
        },
        "EnableInterContainerTrafficEncryption": {
          "type": "boolean"
        },
        "EnableManagedSpotTraining": {
          "type": "boolean"
        },
        "CheckpointConfig": {
          "shape": "S4h"
        }
      }
    },
    "S4c": {
      "type": "structure",
      "required": [
        "SecurityGroupIds",
        "Subnets"
      ],
      "members": {
        "SecurityGroupIds": {
          "type": "list",
          "member": {}
        },
        "Subnets": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S4h": {
      "type": "structure",
      "required": [
        "S3Uri"
      ],
      "members": {
        "S3Uri": {},
        "LocalPath": {}
      }
    },
    "S4i": {
      "type": "structure",
      "required": [
        "ParentHyperParameterTuningJobs",
        "WarmStartType"
      ],
      "members": {
        "ParentHyperParameterTuningJobs": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "HyperParameterTuningJobName": {}
            }
          }
        },
        "WarmStartType": {}
      }
    },
    "S4r": {
      "type": "structure",
      "required": [
        "DataSource"
      ],
      "members": {
        "DataSource": {
          "type": "structure",
          "required": [
            "S3DataSource"
          ],
          "members": {
            "S3DataSource": {
              "type": "structure",
              "required": [
                "ManifestS3Uri"
              ],
              "members": {
                "ManifestS3Uri": {}
              }
            }
          }
        },
        "DataAttributes": {
          "type": "structure",
          "members": {
            "ContentClassifiers": {
              "type": "list",
              "member": {}
            }
          }
        }
      }
    },
    "S4x": {
      "type": "structure",
      "required": [
        "S3OutputPath"
      ],
      "members": {
        "S3OutputPath": {},
        "KmsKeyId": {}
      }
    },
    "S4y": {
      "type": "structure",
      "members": {
        "MaxHumanLabeledObjectCount": {
          "type": "integer"
        },
        "MaxPercentageOfInputDatasetLabeled": {
          "type": "integer"
        }
      }
    },
    "S51": {
      "type": "structure",
      "required": [
        "LabelingJobAlgorithmSpecificationArn"
      ],
      "members": {
        "LabelingJobAlgorithmSpecificationArn": {},
        "InitialActiveLearningModelArn": {},
        "LabelingJobResourceConfig": {
          "type": "structure",
          "members": {
            "VolumeKmsKeyId": {}
          }
        }
      }
    },
    "S55": {
      "type": "structure",
      "required": [
        "WorkteamArn",
        "UiConfig",
        "PreHumanTaskLambdaArn",
        "TaskTitle",
        "TaskDescription",
        "NumberOfHumanWorkersPerDataObject",
        "TaskTimeLimitInSeconds",
        "AnnotationConsolidationConfig"
      ],
      "members": {
        "WorkteamArn": {},
        "UiConfig": {
          "type": "structure",
          "required": [
            "UiTemplateS3Uri"
          ],
          "members": {
            "UiTemplateS3Uri": {}
          }
        },
        "PreHumanTaskLambdaArn": {},
        "TaskKeywords": {
          "type": "list",
          "member": {}
        },
        "TaskTitle": {},
        "TaskDescription": {},
        "NumberOfHumanWorkersPerDataObject": {
          "type": "integer"
        },
        "TaskTimeLimitInSeconds": {
          "type": "integer"
        },
        "TaskAvailabilityLifetimeInSeconds": {
          "type": "integer"
        },
        "MaxConcurrentTaskCount": {
          "type": "integer"
        },
        "AnnotationConsolidationConfig": {
          "type": "structure",
          "required": [
            "AnnotationConsolidationLambdaArn"
          ],
          "members": {
            "AnnotationConsolidationLambdaArn": {}
          }
        },
        "PublicWorkforceTaskPrice": {
          "type": "structure",
          "members": {
            "AmountInUsd": {
              "type": "structure",
              "members": {
                "Dollars": {
                  "type": "integer"
                },
                "Cents": {
                  "type": "integer"
                },
                "TenthFractionsOfACent": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "S5q": {
      "type": "structure",
      "members": {
        "ContainerHostname": {},
        "Image": {},
        "ModelDataUrl": {},
        "Environment": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "ModelPackageName": {}
      }
    },
    "S5u": {
      "type": "list",
      "member": {
        "shape": "S5q"
      }
    },
    "S5x": {
      "type": "structure",
      "required": [
        "ValidationRole",
        "ValidationProfiles"
      ],
      "members": {
        "ValidationRole": {},
        "ValidationProfiles": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "ProfileName",
              "TransformJobDefinition"
            ],
            "members": {
              "ProfileName": {},
              "TransformJobDefinition": {
                "shape": "S2f"
              }
            }
          }
        }
      }
    },
    "S60": {
      "type": "structure",
      "required": [
        "SourceAlgorithms"
      ],
      "members": {
        "SourceAlgorithms": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "AlgorithmName"
            ],
            "members": {
              "ModelDataUrl": {},
              "AlgorithmName": {}
            }
          }
        }
      }
    },
    "S68": {
      "type": "list",
      "member": {}
    },
    "S6c": {
      "type": "list",
      "member": {}
    },
    "S6f": {
      "type": "list",
      "member": {}
    },
    "S6k": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Content": {}
        }
      }
    },
    "S6v": {
      "type": "structure",
      "required": [
        "TrainingInputMode"
      ],
      "members": {
        "TrainingImage": {},
        "AlgorithmName": {},
        "TrainingInputMode": {},
        "MetricDefinitions": {
          "shape": "Sr"
        }
      }
    },
    "S70": {
      "type": "structure",
      "members": {
        "InputFilter": {},
        "OutputFilter": {},
        "JoinSource": {}
      }
    },
    "S77": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "CognitoMemberDefinition": {
            "type": "structure",
            "required": [
              "UserPool",
              "UserGroup",
              "ClientId"
            ],
            "members": {
              "UserPool": {},
              "UserGroup": {},
              "ClientId": {}
            }
          }
        }
      }
    },
    "S7e": {
      "type": "structure",
      "members": {
        "NotificationTopicArn": {}
      }
    },
    "S80": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Status"
        ],
        "members": {
          "Name": {},
          "Status": {},
          "FailureReason": {}
        }
      }
    },
    "S8c": {
      "type": "structure",
      "required": [
        "S3ModelArtifacts"
      ],
      "members": {
        "S3ModelArtifacts": {}
      }
    },
    "S8p": {
      "type": "structure",
      "members": {
        "Completed": {
          "type": "integer"
        },
        "InProgress": {
          "type": "integer"
        },
        "RetryableError": {
          "type": "integer"
        },
        "NonRetryableError": {
          "type": "integer"
        },
        "Stopped": {
          "type": "integer"
        }
      }
    },
    "S8r": {
      "type": "structure",
      "members": {
        "Succeeded": {
          "type": "integer"
        },
        "Pending": {
          "type": "integer"
        },
        "Failed": {
          "type": "integer"
        }
      }
    },
    "S8t": {
      "type": "structure",
      "required": [
        "TrainingJobName",
        "TrainingJobArn",
        "CreationTime",
        "TrainingJobStatus",
        "TunedHyperParameters"
      ],
      "members": {
        "TrainingJobName": {},
        "TrainingJobArn": {},
        "TuningJobName": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TrainingStartTime": {
          "type": "timestamp"
        },
        "TrainingEndTime": {
          "type": "timestamp"
        },
        "TrainingJobStatus": {},
        "TunedHyperParameters": {
          "shape": "S1o"
        },
        "FailureReason": {},
        "FinalHyperParameterTuningJobObjectiveMetric": {
          "type": "structure",
          "required": [
            "MetricName",
            "Value"
          ],
          "members": {
            "Type": {},
            "MetricName": {},
            "Value": {
              "type": "float"
            }
          }
        },
        "ObjectiveStatus": {}
      }
    },
    "S91": {
      "type": "structure",
      "members": {
        "TotalLabeled": {
          "type": "integer"
        },
        "HumanLabeled": {
          "type": "integer"
        },
        "MachineLabeled": {
          "type": "integer"
        },
        "FailedNonRetryableError": {
          "type": "integer"
        },
        "Unlabeled": {
          "type": "integer"
        }
      }
    },
    "S94": {
      "type": "structure",
      "required": [
        "OutputDatasetS3Uri"
      ],
      "members": {
        "OutputDatasetS3Uri": {},
        "FinalActiveLearningModelArn": {}
      }
    },
    "S9b": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Status"
        ],
        "members": {
          "Name": {},
          "Status": {},
          "FailureReason": {}
        }
      }
    },
    "S9m": {
      "type": "structure",
      "required": [
        "WorkteamArn"
      ],
      "members": {
        "WorkteamArn": {},
        "MarketplaceTitle": {},
        "SellerName": {},
        "MarketplaceDescription": {},
        "ListingId": {}
      }
    },
    "S9q": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Status",
          "StartTime"
        ],
        "members": {
          "Status": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "StatusMessage": {}
        }
      }
    },
    "S9t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "MetricName": {},
          "Value": {
            "type": "float"
          },
          "Timestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sa3": {
      "type": "structure",
      "required": [
        "WorkteamName",
        "MemberDefinitions",
        "WorkteamArn",
        "Description"
      ],
      "members": {
        "WorkteamName": {},
        "MemberDefinitions": {
          "shape": "S77"
        },
        "WorkteamArn": {},
        "ProductListingIds": {
          "type": "list",
          "member": {}
        },
        "Description": {},
        "SubDomain": {},
        "CreateDate": {
          "type": "timestamp"
        },
        "LastUpdatedDate": {
          "type": "timestamp"
        },
        "NotificationConfiguration": {
          "shape": "S7e"
        }
      }
    },
    "Sdg": {
      "type": "structure",
      "members": {
        "Filters": {
          "shape": "Sdh"
        },
        "NestedFilters": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "NestedPropertyName",
              "Filters"
            ],
            "members": {
              "NestedPropertyName": {},
              "Filters": {
                "shape": "Sdh"
              }
            }
          }
        },
        "SubExpressions": {
          "type": "list",
          "member": {
            "shape": "Sdg"
          }
        },
        "Operator": {}
      }
    },
    "Sdh": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Operator": {},
          "Value": {}
        }
      }
    }
  }
}