import React from 'react';
import { ConfigProvider } from '@mineko/react-toolkit';
import { SentryErrorBoundary } from '@mineko/ui-core';
import { Entrypoint } from './components/Entrypoint';

const { PUBLIC_URL } = process.env;
const configs = [
  { name: 'APP', url: `${PUBLIC_URL}config.json?v=${(window as Record<string, any>)['VERSION']}` },
  { name: 'ENV', url: `${PUBLIC_URL}config/${location.hostname}.json?v=${(window as Record<string, any>)['VERSION']}` },
];

const App: React.FC = () => {
  return (
    <SentryErrorBoundary
      fallback={({ error }) => (
        <details>
          {error?.name}
          <br />
          {error?.message}
          <br />
          {error?.stack}
          <br />
        </details>
      )}
    >
      <ConfigProvider items={configs}>
        <Entrypoint />
      </ConfigProvider>
    </SentryErrorBoundary>
  );
};

export default App;
