import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { CaptureConsole as CaptureConsoleIntegration } from '@sentry/integrations';

interface InitProps {
  dsn: string;
  release: string;
  environment?: string;
}
export const SentryInit = ({ dsn, release, environment }: InitProps): void =>
  Sentry.init({
    dsn,
    release,
    environment,
    // since v6 of sentry there is an issue withg sessionTracking, so this needs to be disabled until they fix it
    // see https://github.com/getsentry/sentry-javascript/issues/3234
    autoSessionTracking: false,
    integrations: [
      new Integrations.BrowserTracing(),
      new CaptureConsoleIntegration({
        // array of methods that should be captured
        // defaults to ['log', 'info', 'warn', 'error', 'debug', 'assert']
        levels: ['warn', 'error'],
      }),
    ],
  });

export const SentryErrorBoundary = Sentry.ErrorBoundary;
