/**
 * A custom Hook that returns an object of flags determining breakpoints currently triggered
 */
import { useMediaQuery, useTheme } from '@mui/material';
export const useScreenSize = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isVerySmallScreen = useMediaQuery(theme.breakpoints.down('xs'));
    return { isVerySmallScreen, isSmallScreen };
};
