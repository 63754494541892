import { theme } from './is24.theme';

export const getTheme = (): typeof theme | undefined => {
  switch (location.hostname) {
    case 'is24.staging.mineko.de':
    case 'is24.mineko.de':
      return theme;
    default:
      return undefined;
  }
};
