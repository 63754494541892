/*
 * Copyright 2017-2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */
import PredictionsClass from './Predictions';

import Amplify, { ConsoleLogger as Logger } from '@aws-amplify/core';
import {
	AmazonAIConvertPredictionsProvider,
	AmazonAIIdentifyPredictionsProvider,
	AmazonAIPredictionsProvider,
	AmazonAIInterpretPredictionsProvider,
} from './Providers';

import { InterpretTextCategories } from './types';
const logger = new Logger('Predictions');

let _instance: PredictionsClass = null;

if (!_instance) {
	logger.debug('Create Predictions Instance');
	_instance = new PredictionsClass({});
}

const Predictions = _instance;
Amplify.register(Predictions);

export default Predictions;
export {
	AmazonAIIdentifyPredictionsProvider,
	AmazonAIConvertPredictionsProvider,
	AmazonAIPredictionsProvider,
	AmazonAIInterpretPredictionsProvider,
	InterpretTextCategories,
};
