{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-15",
    "endpointPrefix": "ec2",
    "protocol": "ec2",
    "serviceAbbreviation": "Amazon EC2",
    "serviceFullName": "Amazon Elastic Compute Cloud",
    "serviceId": "EC2",
    "signatureVersion": "v4",
    "uid": "ec2-2016-11-15",
    "xmlNamespace": "http://ec2.amazonaws.com/doc/2016-11-15"
  },
  "operations": {
    "AcceptReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "S3",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "S5",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExchangeId": {
            "locationName": "exchangeId"
          }
        }
      }
    },
    "AcceptTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sb",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "AcceptVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId",
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointIds": {
            "shape": "Sd",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sm",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "AcceptVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "Sr",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "AdvertiseByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S12",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "AllocateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "Domain": {},
          "Address": {},
          "PublicIpv4Pool": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIp": {
            "locationName": "publicIp"
          },
          "AllocationId": {
            "locationName": "allocationId"
          },
          "PublicIpv4Pool": {
            "locationName": "publicIpv4Pool"
          },
          "Domain": {
            "locationName": "domain"
          }
        }
      }
    },
    "AllocateHosts": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone",
          "InstanceType",
          "Quantity"
        ],
        "members": {
          "AutoPlacement": {
            "locationName": "autoPlacement"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "Quantity": {
            "locationName": "quantity",
            "type": "integer"
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "HostRecovery": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostIds": {
            "shape": "S1e",
            "locationName": "hostIdSet"
          }
        }
      }
    },
    "ApplySecurityGroupsToClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "VpcId",
          "SecurityGroupIds"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "VpcId": {},
          "SecurityGroupIds": {
            "shape": "S1g",
            "locationName": "SecurityGroupId"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupIds": {
            "shape": "S1g",
            "locationName": "securityGroupIds"
          }
        }
      }
    },
    "AssignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "S1j",
            "locationName": "ipv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssignedIpv6Addresses": {
            "shape": "S1j",
            "locationName": "assignedIpv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      }
    },
    "AssignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "AllowReassignment": {
            "locationName": "allowReassignment",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "S1m",
            "locationName": "privateIpAddress"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "AssignedPrivateIpAddresses": {
            "locationName": "assignedPrivateIpAddressesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrivateIpAddress": {
                  "locationName": "privateIpAddress"
                }
              }
            }
          }
        }
      }
    },
    "AssociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationId": {},
          "InstanceId": {},
          "PublicIp": {},
          "AllowReassociation": {
            "locationName": "allowReassociation",
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      }
    },
    "AssociateClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "SubnetId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "SubnetId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "Status": {
            "shape": "S1u",
            "locationName": "status"
          }
        }
      }
    },
    "AssociateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId",
          "VpcId"
        ],
        "members": {
          "DhcpOptionsId": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "AssociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "InstanceId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S1y"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S20",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "AssociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId",
          "SubnetId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      }
    },
    "AssociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "Ipv6CidrBlock",
          "SubnetId"
        ],
        "members": {
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S27",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      }
    },
    "AssociateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S2c",
            "locationName": "association"
          }
        }
      }
    },
    "AssociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          },
          "CidrBlock": {},
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S2h",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "CidrBlockAssociation": {
            "shape": "S2k",
            "locationName": "cidrBlockAssociation"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "AttachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "Groups",
          "InstanceId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "S2m",
            "locationName": "SecurityGroupId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "AttachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "AttachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceIndex",
          "InstanceId",
          "NetworkInterfaceId"
        ],
        "members": {
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AttachmentId": {
            "locationName": "attachmentId"
          }
        }
      }
    },
    "AttachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "Device",
          "InstanceId",
          "VolumeId"
        ],
        "members": {
          "Device": {},
          "InstanceId": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S2s"
      }
    },
    "AttachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "VpnGatewayId"
        ],
        "members": {
          "VpcId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcAttachment": {
            "shape": "S2w",
            "locationName": "attachment"
          }
        }
      }
    },
    "AuthorizeClientVpnIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "TargetNetworkCidr"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetNetworkCidr": {},
          "AccessGroupId": {},
          "AuthorizeAllGroups": {
            "type": "boolean"
          },
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S30",
            "locationName": "status"
          }
        }
      }
    },
    "AuthorizeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "IpPermissions": {
            "shape": "S33",
            "locationName": "ipPermissions"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          }
        }
      }
    },
    "AuthorizeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "CidrIp": {},
          "FromPort": {
            "type": "integer"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S33"
          },
          "IpProtocol": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "ToPort": {
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "BundleInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Storage"
        ],
        "members": {
          "InstanceId": {},
          "Storage": {
            "shape": "S3f"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S3j",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelBundleTask": {
      "input": {
        "type": "structure",
        "required": [
          "BundleId"
        ],
        "members": {
          "BundleId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTask": {
            "shape": "S3j",
            "locationName": "bundleInstanceTask"
          }
        }
      }
    },
    "CancelCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CancelConversionTask": {
      "input": {
        "type": "structure",
        "required": [
          "ConversionTaskId"
        ],
        "members": {
          "ConversionTaskId": {
            "locationName": "conversionTaskId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "ReasonMessage": {
            "locationName": "reasonMessage"
          }
        }
      }
    },
    "CancelExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "ExportTaskId"
        ],
        "members": {
          "ExportTaskId": {
            "locationName": "exportTaskId"
          }
        }
      }
    },
    "CancelImportTask": {
      "input": {
        "type": "structure",
        "members": {
          "CancelReason": {},
          "DryRun": {
            "type": "boolean"
          },
          "ImportTaskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "PreviousState": {
            "locationName": "previousState"
          },
          "State": {
            "locationName": "state"
          }
        }
      }
    },
    "CancelReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesListingId"
        ],
        "members": {
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S3w",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CancelSpotFleetRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestIds",
          "TerminateInstances"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestIds": {
            "shape": "Sd",
            "locationName": "spotFleetRequestId"
          },
          "TerminateInstances": {
            "locationName": "terminateInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulFleetRequests": {
            "locationName": "successfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrentSpotFleetRequestState": {
                  "locationName": "currentSpotFleetRequestState"
                },
                "PreviousSpotFleetRequestState": {
                  "locationName": "previousSpotFleetRequestState"
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                }
              }
            }
          },
          "UnsuccessfulFleetRequests": {
            "locationName": "unsuccessfulFleetRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                }
              }
            }
          }
        }
      }
    },
    "CancelSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "required": [
          "SpotInstanceRequestIds"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S4h",
            "locationName": "SpotInstanceRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancelledSpotInstanceRequests": {
            "locationName": "spotInstanceRequestSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "SpotInstanceRequestId": {
                  "locationName": "spotInstanceRequestId"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          }
        }
      }
    },
    "ConfirmProductInstance": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "ProductCode"
        ],
        "members": {
          "InstanceId": {},
          "ProductCode": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OwnerId": {
            "locationName": "ownerId"
          },
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CopyFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "SourceFpgaImageId",
          "SourceRegion"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "SourceFpgaImageId": {},
          "Description": {},
          "Name": {},
          "SourceRegion": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageId": {
            "locationName": "fpgaImageId"
          }
        }
      }
    },
    "CopyImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SourceImageId",
          "SourceRegion"
        ],
        "members": {
          "ClientToken": {},
          "Description": {},
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "Name": {},
          "SourceImageId": {},
          "SourceRegion": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CopySnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SourceRegion",
          "SourceSnapshotId"
        ],
        "members": {
          "Description": {},
          "DestinationRegion": {
            "locationName": "destinationRegion"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "PresignedUrl": {
            "locationName": "presignedUrl"
          },
          "SourceRegion": {},
          "SourceSnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {
            "locationName": "snapshotId"
          }
        }
      }
    },
    "CreateCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "InstancePlatform",
          "InstanceCount"
        ],
        "members": {
          "ClientToken": {},
          "InstanceType": {},
          "InstancePlatform": {},
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "Tenancy": {},
          "InstanceCount": {
            "type": "integer"
          },
          "EbsOptimized": {
            "type": "boolean"
          },
          "EphemeralStorage": {
            "type": "boolean"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "EndDateType": {},
          "InstanceMatchCriteria": {},
          "TagSpecifications": {
            "shape": "S19"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CapacityReservation": {
            "shape": "S50",
            "locationName": "capacityReservation"
          }
        }
      }
    },
    "CreateClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientCidrBlock",
          "ServerCertificateArn",
          "AuthenticationOptions",
          "ConnectionLogOptions"
        ],
        "members": {
          "ClientCidrBlock": {},
          "ServerCertificateArn": {},
          "AuthenticationOptions": {
            "locationName": "Authentication",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "ActiveDirectory": {
                  "type": "structure",
                  "members": {
                    "DirectoryId": {}
                  }
                },
                "MutualAuthentication": {
                  "type": "structure",
                  "members": {
                    "ClientRootCertificateChainArn": {}
                  }
                }
              }
            }
          },
          "ConnectionLogOptions": {
            "shape": "S58"
          },
          "DnsServers": {
            "shape": "Sd"
          },
          "TransportProtocol": {},
          "Description": {},
          "SplitTunnel": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointId": {
            "locationName": "clientVpnEndpointId"
          },
          "Status": {
            "shape": "S5b",
            "locationName": "status"
          },
          "DnsName": {
            "locationName": "dnsName"
          }
        }
      }
    },
    "CreateClientVpnRoute": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "DestinationCidrBlock",
          "TargetVpcSubnetId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DestinationCidrBlock": {},
          "TargetVpcSubnetId": {},
          "Description": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S5f",
            "locationName": "status"
          }
        }
      }
    },
    "CreateCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "BgpAsn",
          "Type"
        ],
        "members": {
          "BgpAsn": {
            "type": "integer"
          },
          "PublicIp": {
            "locationName": "IpAddress"
          },
          "CertificateArn": {},
          "Type": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateway": {
            "shape": "S5k",
            "locationName": "customerGateway"
          }
        }
      }
    },
    "CreateDefaultSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "AvailabilityZone": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnet": {
            "shape": "S5n",
            "locationName": "subnet"
          }
        }
      }
    },
    "CreateDefaultVpc": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpc": {
            "shape": "S5s",
            "locationName": "vpc"
          }
        }
      }
    },
    "CreateDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpConfigurations"
        ],
        "members": {
          "DhcpConfigurations": {
            "locationName": "dhcpConfiguration",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {
                  "locationName": "key"
                },
                "Values": {
                  "shape": "Sd",
                  "locationName": "Value"
                }
              }
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "shape": "S61",
            "locationName": "dhcpOptions"
          }
        }
      }
    },
    "CreateEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "ClientToken": {},
          "DryRun": {
            "type": "boolean"
          },
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "EgressOnlyInternetGateway": {
            "shape": "S68",
            "locationName": "egressOnlyInternetGateway"
          }
        }
      }
    },
    "CreateFleet": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateConfigs",
          "TargetCapacitySpecification"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "SpotOptions": {
            "type": "structure",
            "members": {
              "AllocationStrategy": {},
              "InstanceInterruptionBehavior": {},
              "InstancePoolsToUseCount": {
                "type": "integer"
              },
              "SingleInstanceType": {
                "type": "boolean"
              },
              "SingleAvailabilityZone": {
                "type": "boolean"
              },
              "MinTargetCapacity": {
                "type": "integer"
              },
              "MaxTotalPrice": {}
            }
          },
          "OnDemandOptions": {
            "type": "structure",
            "members": {
              "AllocationStrategy": {},
              "SingleInstanceType": {
                "type": "boolean"
              },
              "SingleAvailabilityZone": {
                "type": "boolean"
              },
              "MinTargetCapacity": {
                "type": "integer"
              },
              "MaxTotalPrice": {}
            }
          },
          "ExcessCapacityTerminationPolicy": {},
          "LaunchTemplateConfigs": {
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateSpecification": {
                  "type": "structure",
                  "members": {
                    "LaunchTemplateId": {},
                    "LaunchTemplateName": {},
                    "Version": {}
                  }
                },
                "Overrides": {
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "InstanceType": {},
                      "MaxPrice": {},
                      "SubnetId": {},
                      "AvailabilityZone": {},
                      "WeightedCapacity": {
                        "type": "double"
                      },
                      "Priority": {
                        "type": "double"
                      },
                      "Placement": {
                        "shape": "S6q"
                      }
                    }
                  }
                }
              }
            }
          },
          "TargetCapacitySpecification": {
            "shape": "S6r"
          },
          "TerminateInstancesWithExpiration": {
            "type": "boolean"
          },
          "Type": {},
          "ValidFrom": {
            "type": "timestamp"
          },
          "ValidUntil": {
            "type": "timestamp"
          },
          "ReplaceUnhealthyInstances": {
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {
            "locationName": "fleetId"
          },
          "Errors": {
            "locationName": "errorSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateAndOverrides": {
                  "shape": "S6y",
                  "locationName": "launchTemplateAndOverrides"
                },
                "Lifecycle": {
                  "locationName": "lifecycle"
                },
                "ErrorCode": {
                  "locationName": "errorCode"
                },
                "ErrorMessage": {
                  "locationName": "errorMessage"
                }
              }
            }
          },
          "Instances": {
            "locationName": "fleetInstanceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateAndOverrides": {
                  "shape": "S6y",
                  "locationName": "launchTemplateAndOverrides"
                },
                "Lifecycle": {
                  "locationName": "lifecycle"
                },
                "InstanceIds": {
                  "shape": "S75",
                  "locationName": "instanceIds"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "Platform": {
                  "locationName": "platform"
                }
              }
            }
          }
        }
      }
    },
    "CreateFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIds",
          "ResourceType",
          "TrafficType"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "DeliverLogsPermissionArn": {},
          "LogGroupName": {},
          "ResourceIds": {
            "shape": "Sd",
            "locationName": "ResourceId"
          },
          "ResourceType": {},
          "TrafficType": {},
          "LogDestinationType": {},
          "LogDestination": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "FlowLogIds": {
            "shape": "Sd",
            "locationName": "flowLogIdSet"
          },
          "Unsuccessful": {
            "shape": "Sm",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "CreateFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "InputStorageLocation"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InputStorageLocation": {
            "shape": "S7e"
          },
          "LogsStorageLocation": {
            "shape": "S7e"
          },
          "Description": {},
          "Name": {},
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageId": {
            "locationName": "fpgaImageId"
          },
          "FpgaImageGlobalId": {
            "locationName": "fpgaImageGlobalId"
          }
        }
      }
    },
    "CreateImage": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Name"
        ],
        "members": {
          "BlockDeviceMappings": {
            "shape": "S7h",
            "locationName": "blockDeviceMapping"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Name": {
            "locationName": "name"
          },
          "NoReboot": {
            "locationName": "noReboot",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "CreateInstanceExportTask": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "ExportToS3Task": {
            "locationName": "exportToS3",
            "type": "structure",
            "members": {
              "ContainerFormat": {
                "locationName": "containerFormat"
              },
              "DiskImageFormat": {
                "locationName": "diskImageFormat"
              },
              "S3Bucket": {
                "locationName": "s3Bucket"
              },
              "S3Prefix": {
                "locationName": "s3Prefix"
              }
            }
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "TargetEnvironment": {
            "locationName": "targetEnvironment"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTask": {
            "shape": "S7s",
            "locationName": "exportTask"
          }
        }
      }
    },
    "CreateInternetGateway": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateway": {
            "shape": "S7y",
            "locationName": "internetGateway"
          }
        }
      }
    },
    "CreateKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName"
        ],
        "members": {
          "KeyName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          },
          "KeyMaterial": {
            "locationName": "keyMaterial"
          },
          "KeyName": {
            "locationName": "keyName"
          }
        }
      }
    },
    "CreateLaunchTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateName",
          "LaunchTemplateData"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateName": {},
          "VersionDescription": {},
          "LaunchTemplateData": {
            "shape": "S83"
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "S93",
            "locationName": "launchTemplate"
          }
        }
      }
    },
    "CreateLaunchTemplateVersion": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchTemplateData"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "SourceVersion": {},
          "VersionDescription": {},
          "LaunchTemplateData": {
            "shape": "S83"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateVersion": {
            "shape": "S96",
            "locationName": "launchTemplateVersion"
          }
        }
      }
    },
    "CreateNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "AllocationId",
          "SubnetId"
        ],
        "members": {
          "AllocationId": {},
          "ClientToken": {},
          "SubnetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "NatGateway": {
            "shape": "S9z",
            "locationName": "natGateway"
          }
        }
      }
    },
    "CreateNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcl": {
            "shape": "Sa6",
            "locationName": "networkAcl"
          }
        }
      }
    },
    "CreateNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "Protocol",
          "RuleAction",
          "RuleNumber"
        ],
        "members": {
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "IcmpTypeCode": {
            "shape": "Sab",
            "locationName": "Icmp"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "PortRange": {
            "shape": "Sac",
            "locationName": "portRange"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "CreateNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "S8a",
            "locationName": "SecurityGroupId"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "S9e",
            "locationName": "ipv6Addresses"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "PrivateIpAddresses": {
            "shape": "S8d",
            "locationName": "privateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "InterfaceType": {},
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterface": {
            "shape": "Sai",
            "locationName": "networkInterface"
          }
        }
      }
    },
    "CreateNetworkInterfacePermission": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "Permission"
        ],
        "members": {
          "NetworkInterfaceId": {},
          "AwsAccountId": {},
          "AwsService": {},
          "Permission": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InterfacePermission": {
            "shape": "Saw",
            "locationName": "interfacePermission"
          }
        }
      }
    },
    "CreatePlacementGroup": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          },
          "Strategy": {
            "locationName": "strategy"
          },
          "PartitionCount": {
            "type": "integer"
          }
        }
      }
    },
    "CreateReservedInstancesListing": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "InstanceCount",
          "PriceSchedules",
          "ReservedInstancesId"
        ],
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "Term": {
                  "locationName": "term",
                  "type": "long"
                }
              }
            }
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S3w",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "CreateRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "TransitGatewayId": {},
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "CreateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTable": {
            "shape": "Sb9",
            "locationName": "routeTable"
          }
        }
      }
    },
    "CreateSecurityGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Description",
          "GroupName"
        ],
        "members": {
          "Description": {
            "locationName": "GroupDescription"
          },
          "GroupName": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {
            "locationName": "groupId"
          }
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "Description": {},
          "VolumeId": {},
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "Sbl"
      }
    },
    "CreateSnapshots": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceSpecification"
        ],
        "members": {
          "Description": {},
          "InstanceSpecification": {
            "type": "structure",
            "members": {
              "InstanceId": {},
              "ExcludeBootVolume": {
                "type": "boolean"
              }
            }
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "CopyTagsFromSource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "Encrypted": {
                  "locationName": "encrypted",
                  "type": "boolean"
                },
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "State": {
                  "locationName": "state"
                },
                "VolumeSize": {
                  "locationName": "volumeSize",
                  "type": "integer"
                },
                "StartTime": {
                  "locationName": "startTime",
                  "type": "timestamp"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "SnapshotId": {
                  "locationName": "snapshotId"
                }
              }
            }
          }
        }
      }
    },
    "CreateSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "Bucket"
        ],
        "members": {
          "Bucket": {
            "locationName": "bucket"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Prefix": {
            "locationName": "prefix"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "Sbw",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "CreateSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "CidrBlock",
          "VpcId"
        ],
        "members": {
          "AvailabilityZone": {},
          "AvailabilityZoneId": {},
          "CidrBlock": {},
          "Ipv6CidrBlock": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnet": {
            "shape": "S5n",
            "locationName": "subnet"
          }
        }
      }
    },
    "CreateTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources",
          "Tags"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "Sc2",
            "locationName": "ResourceId"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "Tag"
          }
        }
      }
    },
    "CreateTrafficMirrorFilter": {
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilter": {
            "shape": "Sc5",
            "locationName": "trafficMirrorFilter"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId",
          "TrafficDirection",
          "RuleNumber",
          "RuleAction",
          "DestinationCidrBlock",
          "SourceCidrBlock"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "TrafficDirection": {},
          "RuleNumber": {
            "type": "integer"
          },
          "RuleAction": {},
          "DestinationPortRange": {
            "shape": "Sce"
          },
          "SourcePortRange": {
            "shape": "Sce"
          },
          "Protocol": {
            "type": "integer"
          },
          "DestinationCidrBlock": {},
          "SourceCidrBlock": {},
          "Description": {},
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRule": {
            "shape": "Sc7",
            "locationName": "trafficMirrorFilterRule"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "TrafficMirrorTargetId",
          "TrafficMirrorFilterId",
          "SessionNumber"
        ],
        "members": {
          "NetworkInterfaceId": {},
          "TrafficMirrorTargetId": {},
          "TrafficMirrorFilterId": {},
          "PacketLength": {
            "type": "integer"
          },
          "SessionNumber": {
            "type": "integer"
          },
          "VirtualNetworkId": {
            "type": "integer"
          },
          "Description": {},
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSession": {
            "shape": "Sci",
            "locationName": "trafficMirrorSession"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTrafficMirrorTarget": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {},
          "NetworkLoadBalancerArn": {},
          "Description": {},
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTarget": {
            "shape": "Scl",
            "locationName": "trafficMirrorTarget"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateTransitGateway": {
      "input": {
        "type": "structure",
        "members": {
          "Description": {},
          "Options": {
            "type": "structure",
            "members": {
              "AmazonSideAsn": {
                "type": "long"
              },
              "AutoAcceptSharedAttachments": {},
              "DefaultRouteTableAssociation": {},
              "DefaultRouteTablePropagation": {},
              "VpnEcmpSupport": {},
              "DnsSupport": {}
            }
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateway": {
            "shape": "Scu",
            "locationName": "transitGateway"
          }
        }
      }
    },
    "CreateTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Scz",
            "locationName": "route"
          }
        }
      }
    },
    "CreateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "TagSpecifications": {
            "shape": "S19"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTable": {
            "shape": "Sd6",
            "locationName": "transitGatewayRouteTable"
          }
        }
      }
    },
    "CreateTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId",
          "VpcId",
          "SubnetIds"
        ],
        "members": {
          "TransitGatewayId": {},
          "VpcId": {},
          "SubnetIds": {
            "shape": "Sd"
          },
          "Options": {
            "type": "structure",
            "members": {
              "DnsSupport": {},
              "Ipv6Support": {}
            }
          },
          "TagSpecifications": {
            "shape": "S19"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sb",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "CreateVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone"
        ],
        "members": {
          "AvailabilityZone": {},
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "Iops": {
            "type": "integer"
          },
          "KmsKeyId": {},
          "Size": {
            "type": "integer"
          },
          "SnapshotId": {},
          "VolumeType": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          }
        }
      },
      "output": {
        "shape": "Sdc"
      }
    },
    "CreateVpc": {
      "input": {
        "type": "structure",
        "required": [
          "CidrBlock"
        ],
        "members": {
          "CidrBlock": {},
          "AmazonProvidedIpv6CidrBlock": {
            "locationName": "amazonProvidedIpv6CidrBlock",
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpc": {
            "shape": "S5s",
            "locationName": "vpc"
          }
        }
      }
    },
    "CreateVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "ServiceName"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointType": {},
          "VpcId": {},
          "ServiceName": {},
          "PolicyDocument": {},
          "RouteTableIds": {
            "shape": "Sd",
            "locationName": "RouteTableId"
          },
          "SubnetIds": {
            "shape": "Sd",
            "locationName": "SubnetId"
          },
          "SecurityGroupIds": {
            "shape": "Sd",
            "locationName": "SecurityGroupId"
          },
          "ClientToken": {},
          "PrivateDnsEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoint": {
            "shape": "Sdk",
            "locationName": "vpcEndpoint"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcEndpointConnectionNotification": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationArn",
          "ConnectionEvents"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointId": {},
          "ConnectionNotificationArn": {},
          "ConnectionEvents": {
            "shape": "Sd"
          },
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionNotification": {
            "shape": "Sds",
            "locationName": "connectionNotification"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcEndpointServiceConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkLoadBalancerArns"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "NetworkLoadBalancerArns": {
            "shape": "Sd",
            "locationName": "NetworkLoadBalancerArn"
          },
          "ClientToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceConfiguration": {
            "shape": "Sdx",
            "locationName": "serviceConfiguration"
          },
          "ClientToken": {
            "locationName": "clientToken"
          }
        }
      }
    },
    "CreateVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PeerOwnerId": {
            "locationName": "peerOwnerId"
          },
          "PeerVpcId": {
            "locationName": "peerVpcId"
          },
          "VpcId": {
            "locationName": "vpcId"
          },
          "PeerRegion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnection": {
            "shape": "Sr",
            "locationName": "vpcPeeringConnection"
          }
        }
      }
    },
    "CreateVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayId",
          "Type"
        ],
        "members": {
          "CustomerGatewayId": {},
          "Type": {},
          "VpnGatewayId": {},
          "TransitGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Options": {
            "locationName": "options",
            "type": "structure",
            "members": {
              "StaticRoutesOnly": {
                "locationName": "staticRoutesOnly",
                "type": "boolean"
              },
              "TunnelOptions": {
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "TunnelInsideCidr": {},
                    "PreSharedKey": {}
                  }
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Se9",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "CreateVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "VpnConnectionId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "VpnConnectionId": {}
        }
      }
    },
    "CreateVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "Type"
        ],
        "members": {
          "AvailabilityZone": {},
          "Type": {},
          "AmazonSideAsn": {
            "type": "long"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateway": {
            "shape": "Sel",
            "locationName": "vpnGateway"
          }
        }
      }
    },
    "DeleteClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S5b",
            "locationName": "status"
          }
        }
      }
    },
    "DeleteClientVpnRoute": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "DestinationCidrBlock"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetVpcSubnetId": {},
          "DestinationCidrBlock": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S5f",
            "locationName": "status"
          }
        }
      }
    },
    "DeleteCustomerGateway": {
      "input": {
        "type": "structure",
        "required": [
          "CustomerGatewayId"
        ],
        "members": {
          "CustomerGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteDhcpOptions": {
      "input": {
        "type": "structure",
        "required": [
          "DhcpOptionsId"
        ],
        "members": {
          "DhcpOptionsId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteEgressOnlyInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "EgressOnlyInternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnCode": {
            "locationName": "returnCode",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteFleets": {
      "input": {
        "type": "structure",
        "required": [
          "FleetIds",
          "TerminateInstances"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FleetIds": {
            "shape": "Sew",
            "locationName": "FleetId"
          },
          "TerminateInstances": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulFleetDeletions": {
            "locationName": "successfulFleetDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrentFleetState": {
                  "locationName": "currentFleetState"
                },
                "PreviousFleetState": {
                  "locationName": "previousFleetState"
                },
                "FleetId": {
                  "locationName": "fleetId"
                }
              }
            }
          },
          "UnsuccessfulFleetDeletions": {
            "locationName": "unsuccessfulFleetDeletionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "FleetId": {
                  "locationName": "fleetId"
                }
              }
            }
          }
        }
      }
    },
    "DeleteFlowLogs": {
      "input": {
        "type": "structure",
        "required": [
          "FlowLogIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FlowLogIds": {
            "shape": "Sd",
            "locationName": "FlowLogId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sm",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteFpgaImage": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          }
        }
      }
    },
    "DeleteKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName"
        ],
        "members": {
          "KeyName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteLaunchTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "S93",
            "locationName": "launchTemplate"
          }
        }
      }
    },
    "DeleteLaunchTemplateVersions": {
      "input": {
        "type": "structure",
        "required": [
          "Versions"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "Versions": {
            "shape": "Sfe",
            "locationName": "LaunchTemplateVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfullyDeletedLaunchTemplateVersions": {
            "locationName": "successfullyDeletedLaunchTemplateVersionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateId": {
                  "locationName": "launchTemplateId"
                },
                "LaunchTemplateName": {
                  "locationName": "launchTemplateName"
                },
                "VersionNumber": {
                  "locationName": "versionNumber",
                  "type": "long"
                }
              }
            }
          },
          "UnsuccessfullyDeletedLaunchTemplateVersions": {
            "locationName": "unsuccessfullyDeletedLaunchTemplateVersionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LaunchTemplateId": {
                  "locationName": "launchTemplateId"
                },
                "LaunchTemplateName": {
                  "locationName": "launchTemplateName"
                },
                "VersionNumber": {
                  "locationName": "versionNumber",
                  "type": "long"
                },
                "ResponseError": {
                  "locationName": "responseError",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DeleteNatGateway": {
      "input": {
        "type": "structure",
        "required": [
          "NatGatewayId"
        ],
        "members": {
          "NatGatewayId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGatewayId": {
            "locationName": "natGatewayId"
          }
        }
      }
    },
    "DeleteNetworkAcl": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkAclId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      }
    },
    "DeleteNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "RuleNumber"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "DeleteNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      }
    },
    "DeleteNetworkInterfacePermission": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfacePermissionId"
        ],
        "members": {
          "NetworkInterfacePermissionId": {},
          "Force": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeletePlacementGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupName": {
            "locationName": "groupName"
          }
        }
      }
    },
    "DeleteRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      }
    },
    "DeleteRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      }
    },
    "DeleteSecurityGroup": {
      "input": {
        "type": "structure",
        "members": {
          "GroupId": {},
          "GroupName": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteSubnet": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "SubnetId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteTags": {
      "input": {
        "type": "structure",
        "required": [
          "Resources"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Resources": {
            "shape": "Sc2",
            "locationName": "resourceId"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "tag"
          }
        }
      }
    },
    "DeleteTrafficMirrorFilter": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterId": {
            "locationName": "trafficMirrorFilterId"
          }
        }
      }
    },
    "DeleteTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterRuleId"
        ],
        "members": {
          "TrafficMirrorFilterRuleId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRuleId": {
            "locationName": "trafficMirrorFilterRuleId"
          }
        }
      }
    },
    "DeleteTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorSessionId"
        ],
        "members": {
          "TrafficMirrorSessionId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessionId": {
            "locationName": "trafficMirrorSessionId"
          }
        }
      }
    },
    "DeleteTrafficMirrorTarget": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorTargetId"
        ],
        "members": {
          "TrafficMirrorTargetId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargetId": {
            "locationName": "trafficMirrorTargetId"
          }
        }
      }
    },
    "DeleteTransitGateway": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayId"
        ],
        "members": {
          "TransitGatewayId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateway": {
            "shape": "Scu",
            "locationName": "transitGateway"
          }
        }
      }
    },
    "DeleteTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "DestinationCidrBlock"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "DestinationCidrBlock": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Scz",
            "locationName": "route"
          }
        }
      }
    },
    "DeleteTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTable": {
            "shape": "Sd6",
            "locationName": "transitGatewayRouteTable"
          }
        }
      }
    },
    "DeleteTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sb",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpc": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpcEndpointConnectionNotifications": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationIds": {
            "shape": "Sd",
            "locationName": "ConnectionNotificationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sm",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcEndpointServiceConfigurations": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceIds": {
            "shape": "Sd",
            "locationName": "ServiceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sm",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcEndpoints": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "Sd",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sm",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "DeleteVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "VpnConnectionId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteVpnConnectionRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "VpnConnectionId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "VpnConnectionId": {}
        }
      }
    },
    "DeleteVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpnGatewayId"
        ],
        "members": {
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DeprovisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S12",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "DeregisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "AttributeNames": {
            "locationName": "attributeName",
            "type": "list",
            "member": {
              "locationName": "attributeName"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountAttributes": {
            "locationName": "accountAttributeSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AttributeName": {
                  "locationName": "attributeName"
                },
                "AttributeValues": {
                  "locationName": "attributeValueSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "AttributeValue": {
                        "locationName": "attributeValue"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "PublicIps": {
            "locationName": "PublicIp",
            "type": "list",
            "member": {
              "locationName": "PublicIp"
            }
          },
          "AllocationIds": {
            "locationName": "AllocationId",
            "type": "list",
            "member": {
              "locationName": "AllocationId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Addresses": {
            "locationName": "addressesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "PublicIp": {
                  "locationName": "publicIp"
                },
                "AllocationId": {
                  "locationName": "allocationId"
                },
                "AssociationId": {
                  "locationName": "associationId"
                },
                "Domain": {
                  "locationName": "domain"
                },
                "NetworkInterfaceId": {
                  "locationName": "networkInterfaceId"
                },
                "NetworkInterfaceOwnerId": {
                  "locationName": "networkInterfaceOwnerId"
                },
                "PrivateIpAddress": {
                  "locationName": "privateIpAddress"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "PublicIpv4Pool": {
                  "locationName": "publicIpv4Pool"
                }
              }
            }
          }
        }
      }
    },
    "DescribeAggregateIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UseLongIdsAggregated": {
            "locationName": "useLongIdsAggregated",
            "type": "boolean"
          },
          "Statuses": {
            "shape": "Shf",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeAvailabilityZones": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "ZoneNames": {
            "locationName": "ZoneName",
            "type": "list",
            "member": {
              "locationName": "ZoneName"
            }
          },
          "ZoneIds": {
            "locationName": "ZoneId",
            "type": "list",
            "member": {
              "locationName": "ZoneId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AvailabilityZones": {
            "locationName": "availabilityZoneInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "State": {
                  "locationName": "zoneState"
                },
                "Messages": {
                  "locationName": "messageSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Message": {
                        "locationName": "message"
                      }
                    }
                  }
                },
                "RegionName": {
                  "locationName": "regionName"
                },
                "ZoneName": {
                  "locationName": "zoneName"
                },
                "ZoneId": {
                  "locationName": "zoneId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeBundleTasks": {
      "input": {
        "type": "structure",
        "members": {
          "BundleIds": {
            "locationName": "BundleId",
            "type": "list",
            "member": {
              "locationName": "BundleId"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BundleTasks": {
            "locationName": "bundleInstanceTasksSet",
            "type": "list",
            "member": {
              "shape": "S3j",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeByoipCidrs": {
      "input": {
        "type": "structure",
        "required": [
          "MaxResults"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidrs": {
            "locationName": "byoipCidrSet",
            "type": "list",
            "member": {
              "shape": "S12",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeCapacityReservations": {
      "input": {
        "type": "structure",
        "members": {
          "CapacityReservationIds": {
            "locationName": "CapacityReservationId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "CapacityReservations": {
            "locationName": "capacityReservationSet",
            "type": "list",
            "member": {
              "shape": "S50",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeClassicLinkInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "locationName": "instancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Groups": {
                  "shape": "Sal",
                  "locationName": "groupSet"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnAuthorizationRules": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthorizationRules": {
            "locationName": "authorizationRule",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Description": {
                  "locationName": "description"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "AccessAll": {
                  "locationName": "accessAll",
                  "type": "boolean"
                },
                "DestinationCidr": {
                  "locationName": "destinationCidr"
                },
                "Status": {
                  "shape": "S30",
                  "locationName": "status"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connections": {
            "locationName": "connections",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Timestamp": {
                  "locationName": "timestamp"
                },
                "ConnectionId": {
                  "locationName": "connectionId"
                },
                "Username": {
                  "locationName": "username"
                },
                "ConnectionEstablishedTime": {
                  "locationName": "connectionEstablishedTime"
                },
                "IngressBytes": {
                  "locationName": "ingressBytes"
                },
                "EgressBytes": {
                  "locationName": "egressBytes"
                },
                "IngressPackets": {
                  "locationName": "ingressPackets"
                },
                "EgressPackets": {
                  "locationName": "egressPackets"
                },
                "ClientIp": {
                  "locationName": "clientIp"
                },
                "CommonName": {
                  "locationName": "commonName"
                },
                "Status": {
                  "shape": "Sik",
                  "locationName": "status"
                },
                "ConnectionEndTime": {
                  "locationName": "connectionEndTime"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointIds": {
            "shape": "Sd",
            "locationName": "ClientVpnEndpointId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpoints": {
            "locationName": "clientVpnEndpoint",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Description": {
                  "locationName": "description"
                },
                "Status": {
                  "shape": "S5b",
                  "locationName": "status"
                },
                "CreationTime": {
                  "locationName": "creationTime"
                },
                "DeletionTime": {
                  "locationName": "deletionTime"
                },
                "DnsName": {
                  "locationName": "dnsName"
                },
                "ClientCidrBlock": {
                  "locationName": "clientCidrBlock"
                },
                "DnsServers": {
                  "shape": "Sd",
                  "locationName": "dnsServer"
                },
                "SplitTunnel": {
                  "locationName": "splitTunnel",
                  "type": "boolean"
                },
                "VpnProtocol": {
                  "locationName": "vpnProtocol"
                },
                "TransportProtocol": {
                  "locationName": "transportProtocol"
                },
                "AssociatedTargetNetworks": {
                  "deprecated": true,
                  "deprecatedMessage": "This property is deprecated. To view the target networks associated with a Client VPN endpoint, call DescribeClientVpnTargetNetworks and inspect the clientVpnTargetNetworks response element.",
                  "locationName": "associatedTargetNetwork",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "NetworkId": {
                        "locationName": "networkId"
                      },
                      "NetworkType": {
                        "locationName": "networkType"
                      }
                    }
                  }
                },
                "ServerCertificateArn": {
                  "locationName": "serverCertificateArn"
                },
                "AuthenticationOptions": {
                  "locationName": "authenticationOptions",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Type": {
                        "locationName": "type"
                      },
                      "ActiveDirectory": {
                        "locationName": "activeDirectory",
                        "type": "structure",
                        "members": {
                          "DirectoryId": {
                            "locationName": "directoryId"
                          }
                        }
                      },
                      "MutualAuthentication": {
                        "locationName": "mutualAuthentication",
                        "type": "structure",
                        "members": {
                          "ClientRootCertificateChain": {
                            "locationName": "clientRootCertificateChain"
                          }
                        }
                      }
                    }
                  }
                },
                "ConnectionLogOptions": {
                  "locationName": "connectionLogOptions",
                  "type": "structure",
                  "members": {
                    "Enabled": {
                      "type": "boolean"
                    },
                    "CloudwatchLogGroup": {},
                    "CloudwatchLogStream": {}
                  }
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routes",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "DestinationCidr": {
                  "locationName": "destinationCidr"
                },
                "TargetSubnet": {
                  "locationName": "targetSubnet"
                },
                "Type": {
                  "locationName": "type"
                },
                "Origin": {
                  "locationName": "origin"
                },
                "Status": {
                  "shape": "S5f",
                  "locationName": "status"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeClientVpnTargetNetworks": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "AssociationIds": {
            "shape": "Sd"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnTargetNetworks": {
            "locationName": "clientVpnTargetNetworks",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AssociationId": {
                  "locationName": "associationId"
                },
                "VpcId": {
                  "locationName": "vpcId"
                },
                "TargetNetworkId": {
                  "locationName": "targetNetworkId"
                },
                "ClientVpnEndpointId": {
                  "locationName": "clientVpnEndpointId"
                },
                "Status": {
                  "shape": "S1u",
                  "locationName": "status"
                },
                "SecurityGroups": {
                  "shape": "Sd",
                  "locationName": "securityGroups"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeConversionTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ConversionTaskIds": {
            "locationName": "conversionTaskId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTasks": {
            "locationName": "conversionTasks",
            "type": "list",
            "member": {
              "shape": "Sje",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeCustomerGateways": {
      "input": {
        "type": "structure",
        "members": {
          "CustomerGatewayIds": {
            "locationName": "CustomerGatewayId",
            "type": "list",
            "member": {
              "locationName": "CustomerGatewayId"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CustomerGateways": {
            "locationName": "customerGatewaySet",
            "type": "list",
            "member": {
              "shape": "S5k",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeDhcpOptions": {
      "input": {
        "type": "structure",
        "members": {
          "DhcpOptionsIds": {
            "locationName": "DhcpOptionsId",
            "type": "list",
            "member": {
              "locationName": "DhcpOptionsId"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DhcpOptions": {
            "locationName": "dhcpOptionsSet",
            "type": "list",
            "member": {
              "shape": "S61",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeEgressOnlyInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayIds": {
            "locationName": "EgressOnlyInternetGatewayId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EgressOnlyInternetGateways": {
            "locationName": "egressOnlyInternetGatewaySet",
            "type": "list",
            "member": {
              "shape": "S68",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeElasticGpus": {
      "input": {
        "type": "structure",
        "members": {
          "ElasticGpuIds": {
            "locationName": "ElasticGpuId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticGpuSet": {
            "locationName": "elasticGpuSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ElasticGpuId": {
                  "locationName": "elasticGpuId"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "ElasticGpuType": {
                  "locationName": "elasticGpuType"
                },
                "ElasticGpuHealth": {
                  "locationName": "elasticGpuHealth",
                  "type": "structure",
                  "members": {
                    "Status": {
                      "locationName": "status"
                    }
                  }
                },
                "ElasticGpuState": {
                  "locationName": "elasticGpuState"
                },
                "InstanceId": {
                  "locationName": "instanceId"
                }
              }
            }
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeExportImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "ExportImageTaskIds": {
            "locationName": "ExportImageTaskId",
            "type": "list",
            "member": {
              "locationName": "ExportImageTaskId"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportImageTasks": {
            "locationName": "exportImageTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "ExportImageTaskId": {
                  "locationName": "exportImageTaskId"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "S3ExportLocation": {
                  "shape": "Skf",
                  "locationName": "s3ExportLocation"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeExportTasks": {
      "input": {
        "type": "structure",
        "members": {
          "ExportTaskIds": {
            "locationName": "exportTaskId",
            "type": "list",
            "member": {
              "locationName": "ExportTaskId"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportTasks": {
            "locationName": "exportTaskSet",
            "type": "list",
            "member": {
              "shape": "S7s",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeFleetHistory": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "StartTime"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "EventType": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetId": {},
          "StartTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "EventInformation": {
                  "shape": "Skp",
                  "locationName": "eventInformation"
                },
                "EventType": {
                  "locationName": "eventType"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          },
          "LastEvaluatedTime": {
            "locationName": "lastEvaluatedTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "FleetId": {
            "locationName": "fleetId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeFleetInstances": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveInstances": {
            "shape": "Sks",
            "locationName": "activeInstanceSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "FleetId": {
            "locationName": "fleetId"
          }
        }
      }
    },
    "DescribeFleets": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "FleetIds": {
            "shape": "Sew",
            "locationName": "FleetId"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Fleets": {
            "locationName": "fleetSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ActivityStatus": {
                  "locationName": "activityStatus"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "FleetId": {
                  "locationName": "fleetId"
                },
                "FleetState": {
                  "locationName": "fleetState"
                },
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "ExcessCapacityTerminationPolicy": {
                  "locationName": "excessCapacityTerminationPolicy"
                },
                "FulfilledCapacity": {
                  "locationName": "fulfilledCapacity",
                  "type": "double"
                },
                "FulfilledOnDemandCapacity": {
                  "locationName": "fulfilledOnDemandCapacity",
                  "type": "double"
                },
                "LaunchTemplateConfigs": {
                  "locationName": "launchTemplateConfigs",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateSpecification": {
                        "shape": "S6z",
                        "locationName": "launchTemplateSpecification"
                      },
                      "Overrides": {
                        "locationName": "overrides",
                        "type": "list",
                        "member": {
                          "shape": "S70",
                          "locationName": "item"
                        }
                      }
                    }
                  }
                },
                "TargetCapacitySpecification": {
                  "locationName": "targetCapacitySpecification",
                  "type": "structure",
                  "members": {
                    "TotalTargetCapacity": {
                      "locationName": "totalTargetCapacity",
                      "type": "integer"
                    },
                    "OnDemandTargetCapacity": {
                      "locationName": "onDemandTargetCapacity",
                      "type": "integer"
                    },
                    "SpotTargetCapacity": {
                      "locationName": "spotTargetCapacity",
                      "type": "integer"
                    },
                    "DefaultTargetCapacityType": {
                      "locationName": "defaultTargetCapacityType"
                    }
                  }
                },
                "TerminateInstancesWithExpiration": {
                  "locationName": "terminateInstancesWithExpiration",
                  "type": "boolean"
                },
                "Type": {
                  "locationName": "type"
                },
                "ValidFrom": {
                  "locationName": "validFrom",
                  "type": "timestamp"
                },
                "ValidUntil": {
                  "locationName": "validUntil",
                  "type": "timestamp"
                },
                "ReplaceUnhealthyInstances": {
                  "locationName": "replaceUnhealthyInstances",
                  "type": "boolean"
                },
                "SpotOptions": {
                  "locationName": "spotOptions",
                  "type": "structure",
                  "members": {
                    "AllocationStrategy": {
                      "locationName": "allocationStrategy"
                    },
                    "InstanceInterruptionBehavior": {
                      "locationName": "instanceInterruptionBehavior"
                    },
                    "InstancePoolsToUseCount": {
                      "locationName": "instancePoolsToUseCount",
                      "type": "integer"
                    },
                    "SingleInstanceType": {
                      "locationName": "singleInstanceType",
                      "type": "boolean"
                    },
                    "SingleAvailabilityZone": {
                      "locationName": "singleAvailabilityZone",
                      "type": "boolean"
                    },
                    "MinTargetCapacity": {
                      "locationName": "minTargetCapacity",
                      "type": "integer"
                    },
                    "MaxTotalPrice": {
                      "locationName": "maxTotalPrice"
                    }
                  }
                },
                "OnDemandOptions": {
                  "locationName": "onDemandOptions",
                  "type": "structure",
                  "members": {
                    "AllocationStrategy": {
                      "locationName": "allocationStrategy"
                    },
                    "SingleInstanceType": {
                      "locationName": "singleInstanceType",
                      "type": "boolean"
                    },
                    "SingleAvailabilityZone": {
                      "locationName": "singleAvailabilityZone",
                      "type": "boolean"
                    },
                    "MinTargetCapacity": {
                      "locationName": "minTargetCapacity",
                      "type": "integer"
                    },
                    "MaxTotalPrice": {
                      "locationName": "maxTotalPrice"
                    }
                  }
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "Errors": {
                  "locationName": "errorSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateAndOverrides": {
                        "shape": "S6y",
                        "locationName": "launchTemplateAndOverrides"
                      },
                      "Lifecycle": {
                        "locationName": "lifecycle"
                      },
                      "ErrorCode": {
                        "locationName": "errorCode"
                      },
                      "ErrorMessage": {
                        "locationName": "errorMessage"
                      }
                    }
                  }
                },
                "Instances": {
                  "locationName": "fleetInstanceSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "LaunchTemplateAndOverrides": {
                        "shape": "S6y",
                        "locationName": "launchTemplateAndOverrides"
                      },
                      "Lifecycle": {
                        "locationName": "lifecycle"
                      },
                      "InstanceIds": {
                        "shape": "S75",
                        "locationName": "instanceIds"
                      },
                      "InstanceType": {
                        "locationName": "instanceType"
                      },
                      "Platform": {
                        "locationName": "platform"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeFlowLogs": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filter": {
            "shape": "Sh6"
          },
          "FlowLogIds": {
            "shape": "Sd",
            "locationName": "FlowLogId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowLogs": {
            "locationName": "flowLogSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CreationTime": {
                  "locationName": "creationTime",
                  "type": "timestamp"
                },
                "DeliverLogsErrorMessage": {
                  "locationName": "deliverLogsErrorMessage"
                },
                "DeliverLogsPermissionArn": {
                  "locationName": "deliverLogsPermissionArn"
                },
                "DeliverLogsStatus": {
                  "locationName": "deliverLogsStatus"
                },
                "FlowLogId": {
                  "locationName": "flowLogId"
                },
                "FlowLogStatus": {
                  "locationName": "flowLogStatus"
                },
                "LogGroupName": {
                  "locationName": "logGroupName"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "TrafficType": {
                  "locationName": "trafficType"
                },
                "LogDestinationType": {
                  "locationName": "logDestinationType"
                },
                "LogDestination": {
                  "locationName": "logDestination"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId",
          "Attribute"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageAttribute": {
            "shape": "Slh",
            "locationName": "fpgaImageAttribute"
          }
        }
      }
    },
    "DescribeFpgaImages": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageIds": {
            "locationName": "FpgaImageId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Owners": {
            "shape": "Slq",
            "locationName": "Owner"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImages": {
            "locationName": "fpgaImageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "FpgaImageId": {
                  "locationName": "fpgaImageId"
                },
                "FpgaImageGlobalId": {
                  "locationName": "fpgaImageGlobalId"
                },
                "Name": {
                  "locationName": "name"
                },
                "Description": {
                  "locationName": "description"
                },
                "ShellVersion": {
                  "locationName": "shellVersion"
                },
                "PciId": {
                  "locationName": "pciId",
                  "type": "structure",
                  "members": {
                    "DeviceId": {},
                    "VendorId": {},
                    "SubsystemId": {},
                    "SubsystemVendorId": {}
                  }
                },
                "State": {
                  "locationName": "state",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "UpdateTime": {
                  "locationName": "updateTime",
                  "type": "timestamp"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "OwnerAlias": {
                  "locationName": "ownerAlias"
                },
                "ProductCodes": {
                  "shape": "Sll",
                  "locationName": "productCodes"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tags"
                },
                "Public": {
                  "locationName": "public",
                  "type": "boolean"
                },
                "DataRetentionSupport": {
                  "locationName": "dataRetentionSupport",
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHostReservationOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "Sh6"
          },
          "MaxDuration": {
            "type": "integer"
          },
          "MaxResults": {
            "type": "integer"
          },
          "MinDuration": {
            "type": "integer"
          },
          "NextToken": {},
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "OfferingSet": {
            "locationName": "offeringSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                }
              }
            }
          }
        }
      }
    },
    "DescribeHostReservations": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "Sh6"
          },
          "HostReservationIdSet": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HostReservationSet": {
            "locationName": "hostReservationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Count": {
                  "locationName": "count",
                  "type": "integer"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "integer"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "HostIdSet": {
                  "shape": "Sm9",
                  "locationName": "hostIdSet"
                },
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceFamily": {
                  "locationName": "instanceFamily"
                },
                "OfferingId": {
                  "locationName": "offeringId"
                },
                "PaymentOption": {
                  "locationName": "paymentOption"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "State": {
                  "locationName": "state"
                },
                "UpfrontPrice": {
                  "locationName": "upfrontPrice"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeHosts": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "Sh6",
            "locationName": "filter"
          },
          "HostIds": {
            "shape": "Smc",
            "locationName": "hostId"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Hosts": {
            "locationName": "hostSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AutoPlacement": {
                  "locationName": "autoPlacement"
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "AvailableCapacity": {
                  "locationName": "availableCapacity",
                  "type": "structure",
                  "members": {
                    "AvailableInstanceCapacity": {
                      "locationName": "availableInstanceCapacity",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "AvailableCapacity": {
                            "locationName": "availableCapacity",
                            "type": "integer"
                          },
                          "InstanceType": {
                            "locationName": "instanceType"
                          },
                          "TotalCapacity": {
                            "locationName": "totalCapacity",
                            "type": "integer"
                          }
                        }
                      }
                    },
                    "AvailableVCpus": {
                      "locationName": "availableVCpus",
                      "type": "integer"
                    }
                  }
                },
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "HostId": {
                  "locationName": "hostId"
                },
                "HostProperties": {
                  "locationName": "hostProperties",
                  "type": "structure",
                  "members": {
                    "Cores": {
                      "locationName": "cores",
                      "type": "integer"
                    },
                    "InstanceType": {
                      "locationName": "instanceType"
                    },
                    "Sockets": {
                      "locationName": "sockets",
                      "type": "integer"
                    },
                    "TotalVCpus": {
                      "locationName": "totalVCpus",
                      "type": "integer"
                    }
                  }
                },
                "HostReservationId": {
                  "locationName": "hostReservationId"
                },
                "Instances": {
                  "locationName": "instances",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "InstanceId": {
                        "locationName": "instanceId"
                      },
                      "InstanceType": {
                        "locationName": "instanceType"
                      }
                    }
                  }
                },
                "State": {
                  "locationName": "state"
                },
                "AllocationTime": {
                  "locationName": "allocationTime",
                  "type": "timestamp"
                },
                "ReleaseTime": {
                  "locationName": "releaseTime",
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "HostRecovery": {
                  "locationName": "hostRecovery"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIamInstanceProfileAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationIds": {
            "locationName": "AssociationId",
            "type": "list",
            "member": {
              "locationName": "AssociationId"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociations": {
            "locationName": "iamInstanceProfileAssociationSet",
            "type": "list",
            "member": {
              "shape": "S20",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "Resource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "Shf",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "PrincipalArn"
        ],
        "members": {
          "PrincipalArn": {
            "locationName": "principalArn"
          },
          "Resource": {
            "locationName": "resource"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Statuses": {
            "shape": "Shf",
            "locationName": "statusSet"
          }
        }
      }
    },
    "DescribeImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BlockDeviceMappings": {
            "shape": "Smz",
            "locationName": "blockDeviceMapping"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "LaunchPermissions": {
            "shape": "Sn0",
            "locationName": "launchPermission"
          },
          "ProductCodes": {
            "shape": "Sll",
            "locationName": "productCodes"
          },
          "Description": {
            "shape": "S65",
            "locationName": "description"
          },
          "KernelId": {
            "shape": "S65",
            "locationName": "kernel"
          },
          "RamdiskId": {
            "shape": "S65",
            "locationName": "ramdisk"
          },
          "SriovNetSupport": {
            "shape": "S65",
            "locationName": "sriovNetSupport"
          }
        }
      }
    },
    "DescribeImages": {
      "input": {
        "type": "structure",
        "members": {
          "ExecutableUsers": {
            "locationName": "ExecutableBy",
            "type": "list",
            "member": {
              "locationName": "ExecutableBy"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "ImageIds": {
            "locationName": "ImageId",
            "type": "list",
            "member": {
              "locationName": "ImageId"
            }
          },
          "Owners": {
            "shape": "Slq",
            "locationName": "Owner"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Images": {
            "locationName": "imagesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Architecture": {
                  "locationName": "architecture"
                },
                "CreationDate": {
                  "locationName": "creationDate"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "ImageLocation": {
                  "locationName": "imageLocation"
                },
                "ImageType": {
                  "locationName": "imageType"
                },
                "Public": {
                  "locationName": "isPublic",
                  "type": "boolean"
                },
                "KernelId": {
                  "locationName": "kernelId"
                },
                "OwnerId": {
                  "locationName": "imageOwnerId"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "ProductCodes": {
                  "shape": "Sll",
                  "locationName": "productCodes"
                },
                "RamdiskId": {
                  "locationName": "ramdiskId"
                },
                "State": {
                  "locationName": "imageState"
                },
                "BlockDeviceMappings": {
                  "shape": "Smz",
                  "locationName": "blockDeviceMapping"
                },
                "Description": {
                  "locationName": "description"
                },
                "EnaSupport": {
                  "locationName": "enaSupport",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ImageOwnerAlias": {
                  "locationName": "imageOwnerAlias"
                },
                "Name": {
                  "locationName": "name"
                },
                "RootDeviceName": {
                  "locationName": "rootDeviceName"
                },
                "RootDeviceType": {
                  "locationName": "rootDeviceType"
                },
                "SriovNetSupport": {
                  "locationName": "sriovNetSupport"
                },
                "StateReason": {
                  "shape": "Snd",
                  "locationName": "stateReason"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "VirtualizationType": {
                  "locationName": "virtualizationType"
                }
              }
            }
          }
        }
      }
    },
    "DescribeImportImageTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6"
          },
          "ImportTaskIds": {
            "shape": "Sng",
            "locationName": "ImportTaskId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportImageTasks": {
            "locationName": "importImageTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Architecture": {
                  "locationName": "architecture"
                },
                "Description": {
                  "locationName": "description"
                },
                "Encrypted": {
                  "locationName": "encrypted",
                  "type": "boolean"
                },
                "Hypervisor": {
                  "locationName": "hypervisor"
                },
                "ImageId": {
                  "locationName": "imageId"
                },
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "KmsKeyId": {
                  "locationName": "kmsKeyId"
                },
                "LicenseType": {
                  "locationName": "licenseType"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "Progress": {
                  "locationName": "progress"
                },
                "SnapshotDetails": {
                  "shape": "Snk",
                  "locationName": "snapshotDetailSet"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeImportSnapshotTasks": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6"
          },
          "ImportTaskIds": {
            "shape": "Sng",
            "locationName": "ImportTaskId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportSnapshotTasks": {
            "locationName": "importSnapshotTaskSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "ImportTaskId": {
                  "locationName": "importTaskId"
                },
                "SnapshotTaskDetail": {
                  "shape": "Snr",
                  "locationName": "snapshotTaskDetail"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "InstanceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "Sal",
            "locationName": "groupSet"
          },
          "BlockDeviceMappings": {
            "shape": "Snv",
            "locationName": "blockDeviceMapping"
          },
          "DisableApiTermination": {
            "shape": "Sny",
            "locationName": "disableApiTermination"
          },
          "EnaSupport": {
            "shape": "Sny",
            "locationName": "enaSupport"
          },
          "EbsOptimized": {
            "shape": "Sny",
            "locationName": "ebsOptimized"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "S65",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "InstanceType": {
            "shape": "S65",
            "locationName": "instanceType"
          },
          "KernelId": {
            "shape": "S65",
            "locationName": "kernel"
          },
          "ProductCodes": {
            "shape": "Sll",
            "locationName": "productCodes"
          },
          "RamdiskId": {
            "shape": "S65",
            "locationName": "ramdisk"
          },
          "RootDeviceName": {
            "shape": "S65",
            "locationName": "rootDeviceName"
          },
          "SourceDestCheck": {
            "shape": "Sny",
            "locationName": "sourceDestCheck"
          },
          "SriovNetSupport": {
            "shape": "S65",
            "locationName": "sriovNetSupport"
          },
          "UserData": {
            "shape": "S65",
            "locationName": "userData"
          }
        }
      }
    },
    "DescribeInstanceCreditSpecifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceCreditSpecifications": {
            "locationName": "instanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "CpuCredits": {
                  "locationName": "cpuCredits"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstanceStatus": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "IncludeAllInstances": {
            "locationName": "includeAllInstances",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceStatuses": {
            "locationName": "instanceStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "shape": "So9",
                    "locationName": "item"
                  }
                },
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "InstanceState": {
                  "shape": "Soc",
                  "locationName": "instanceState"
                },
                "InstanceStatus": {
                  "shape": "Soe",
                  "locationName": "instanceStatus"
                },
                "SystemStatus": {
                  "shape": "Soe",
                  "locationName": "systemStatus"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Reservations": {
            "locationName": "reservationSet",
            "type": "list",
            "member": {
              "shape": "Son",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeInternetGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayIds": {
            "shape": "Sd",
            "locationName": "internetGatewayId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InternetGateways": {
            "locationName": "internetGatewaySet",
            "type": "list",
            "member": {
              "shape": "S7y",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeKeyPairs": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "KeyNames": {
            "locationName": "KeyName",
            "type": "list",
            "member": {
              "locationName": "KeyName"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyPairs": {
            "locationName": "keySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "KeyFingerprint": {
                  "locationName": "keyFingerprint"
                },
                "KeyName": {
                  "locationName": "keyName"
                }
              }
            }
          }
        }
      }
    },
    "DescribeLaunchTemplateVersions": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "Versions": {
            "shape": "Sfe",
            "locationName": "LaunchTemplateVersion"
          },
          "MinVersion": {},
          "MaxVersion": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateVersions": {
            "locationName": "launchTemplateVersionSet",
            "type": "list",
            "member": {
              "shape": "S96",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeLaunchTemplates": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "LaunchTemplateIds": {
            "shape": "Sd",
            "locationName": "LaunchTemplateId"
          },
          "LaunchTemplateNames": {
            "locationName": "LaunchTemplateName",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplates": {
            "locationName": "launchTemplates",
            "type": "list",
            "member": {
              "shape": "S93",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeMovingAddresses": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "PublicIps": {
            "shape": "Sd",
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MovingAddressStatuses": {
            "locationName": "movingAddressStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "MoveStatus": {
                  "locationName": "moveStatus"
                },
                "PublicIp": {
                  "locationName": "publicIp"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNatGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "Sh6"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NatGatewayIds": {
            "shape": "Sd",
            "locationName": "NatGatewayId"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NatGateways": {
            "locationName": "natGatewaySet",
            "type": "list",
            "member": {
              "shape": "S9z",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkAcls": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclIds": {
            "shape": "Sd",
            "locationName": "NetworkAclId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkAcls": {
            "locationName": "networkAclSet",
            "type": "list",
            "member": {
              "shape": "Sa6",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attachment": {
            "shape": "Sak",
            "locationName": "attachment"
          },
          "Description": {
            "shape": "S65",
            "locationName": "description"
          },
          "Groups": {
            "shape": "Sal",
            "locationName": "groupSet"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "shape": "Sny",
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "DescribeNetworkInterfacePermissions": {
      "input": {
        "type": "structure",
        "members": {
          "NetworkInterfacePermissionIds": {
            "locationName": "NetworkInterfacePermissionId",
            "type": "list",
            "member": {}
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfacePermissions": {
            "locationName": "networkInterfacePermissions",
            "type": "list",
            "member": {
              "shape": "Saw",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeNetworkInterfaces": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceIds": {
            "locationName": "NetworkInterfaceId",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaces": {
            "locationName": "networkInterfaceSet",
            "type": "list",
            "member": {
              "shape": "Sai",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribePlacementGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupNames": {
            "locationName": "groupName",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlacementGroups": {
            "locationName": "placementGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupName": {
                  "locationName": "groupName"
                },
                "State": {
                  "locationName": "state"
                },
                "Strategy": {
                  "locationName": "strategy"
                },
                "PartitionCount": {
                  "locationName": "partitionCount",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribePrefixLists": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "PrefixListIds": {
            "shape": "Sd",
            "locationName": "PrefixListId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "PrefixLists": {
            "locationName": "prefixListSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Cidrs": {
                  "shape": "Sd",
                  "locationName": "cidrSet"
                },
                "PrefixListId": {
                  "locationName": "prefixListId"
                },
                "PrefixListName": {
                  "locationName": "prefixListName"
                }
              }
            }
          }
        }
      }
    },
    "DescribePrincipalIdFormat": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Resources": {
            "locationName": "Resource",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Principals": {
            "locationName": "principalSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "Statuses": {
                  "shape": "Shf",
                  "locationName": "statusSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribePublicIpv4Pools": {
      "input": {
        "type": "structure",
        "members": {
          "PoolIds": {
            "shape": "Sd",
            "locationName": "PoolId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIpv4Pools": {
            "locationName": "publicIpv4PoolSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PoolId": {
                  "locationName": "poolId"
                },
                "Description": {
                  "locationName": "description"
                },
                "PoolAddressRanges": {
                  "locationName": "poolAddressRangeSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "FirstAddress": {
                        "locationName": "firstAddress"
                      },
                      "LastAddress": {
                        "locationName": "lastAddress"
                      },
                      "AddressCount": {
                        "locationName": "addressCount",
                        "type": "integer"
                      },
                      "AvailableAddressCount": {
                        "locationName": "availableAddressCount",
                        "type": "integer"
                      }
                    }
                  }
                },
                "TotalAddressCount": {
                  "locationName": "totalAddressCount",
                  "type": "integer"
                },
                "TotalAvailableAddressCount": {
                  "locationName": "totalAvailableAddressCount",
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRegions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "RegionNames": {
            "locationName": "RegionName",
            "type": "list",
            "member": {
              "locationName": "RegionName"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "AllRegions": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Regions": {
            "locationName": "regionInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Endpoint": {
                  "locationName": "regionEndpoint"
                },
                "RegionName": {
                  "locationName": "regionName"
                },
                "OptInStatus": {
                  "locationName": "optInStatus"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "OfferingClass": {},
          "ReservedInstancesIds": {
            "shape": "Sra",
            "locationName": "ReservedInstancesId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "OfferingType": {
            "locationName": "offeringType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstances": {
            "locationName": "reservedInstancesSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "End": {
                  "locationName": "end",
                  "type": "timestamp"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "ReservedInstancesId": {
                  "locationName": "reservedInstancesId"
                },
                "Start": {
                  "locationName": "start",
                  "type": "timestamp"
                },
                "State": {
                  "locationName": "state"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "RecurringCharges": {
                  "shape": "Sri",
                  "locationName": "recurringCharges"
                },
                "Scope": {
                  "locationName": "scope"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstancesListings": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesListings": {
            "shape": "S3w",
            "locationName": "reservedInstancesListingsSet"
          }
        }
      }
    },
    "DescribeReservedInstancesModifications": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "ReservedInstancesModificationIds": {
            "locationName": "ReservedInstancesModificationId",
            "type": "list",
            "member": {
              "locationName": "ReservedInstancesModificationId"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ReservedInstancesModifications": {
            "locationName": "reservedInstancesModificationsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClientToken": {
                  "locationName": "clientToken"
                },
                "CreateDate": {
                  "locationName": "createDate",
                  "type": "timestamp"
                },
                "EffectiveDate": {
                  "locationName": "effectiveDate",
                  "type": "timestamp"
                },
                "ModificationResults": {
                  "locationName": "modificationResultSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      },
                      "TargetConfiguration": {
                        "shape": "Srv",
                        "locationName": "targetConfiguration"
                      }
                    }
                  }
                },
                "ReservedInstancesIds": {
                  "locationName": "reservedInstancesSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "ReservedInstancesId": {
                        "locationName": "reservedInstancesId"
                      }
                    }
                  }
                },
                "ReservedInstancesModificationId": {
                  "locationName": "reservedInstancesModificationId"
                },
                "Status": {
                  "locationName": "status"
                },
                "StatusMessage": {
                  "locationName": "statusMessage"
                },
                "UpdateDate": {
                  "locationName": "updateDate",
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedInstancesOfferings": {
      "input": {
        "type": "structure",
        "members": {
          "AvailabilityZone": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "IncludeMarketplace": {
            "type": "boolean"
          },
          "InstanceType": {},
          "MaxDuration": {
            "type": "long"
          },
          "MaxInstanceCount": {
            "type": "integer"
          },
          "MinDuration": {
            "type": "long"
          },
          "OfferingClass": {},
          "ProductDescription": {},
          "ReservedInstancesOfferingIds": {
            "locationName": "ReservedInstancesOfferingId",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceTenancy": {
            "locationName": "instanceTenancy"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "OfferingType": {
            "locationName": "offeringType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesOfferings": {
            "locationName": "reservedInstancesOfferingsSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Duration": {
                  "locationName": "duration",
                  "type": "long"
                },
                "FixedPrice": {
                  "locationName": "fixedPrice",
                  "type": "float"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "ReservedInstancesOfferingId": {
                  "locationName": "reservedInstancesOfferingId"
                },
                "UsagePrice": {
                  "locationName": "usagePrice",
                  "type": "float"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "InstanceTenancy": {
                  "locationName": "instanceTenancy"
                },
                "Marketplace": {
                  "locationName": "marketplace",
                  "type": "boolean"
                },
                "OfferingClass": {
                  "locationName": "offeringClass"
                },
                "OfferingType": {
                  "locationName": "offeringType"
                },
                "PricingDetails": {
                  "locationName": "pricingDetailsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Count": {
                        "locationName": "count",
                        "type": "integer"
                      },
                      "Price": {
                        "locationName": "price",
                        "type": "double"
                      }
                    }
                  }
                },
                "RecurringCharges": {
                  "shape": "Sri",
                  "locationName": "recurringCharges"
                },
                "Scope": {
                  "locationName": "scope"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableIds": {
            "shape": "Sd",
            "locationName": "RouteTableId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RouteTables": {
            "locationName": "routeTableSet",
            "type": "list",
            "member": {
              "shape": "Sb9",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeScheduledInstanceAvailability": {
      "input": {
        "type": "structure",
        "required": [
          "FirstSlotStartTimeRange",
          "Recurrence"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "FirstSlotStartTimeRange": {
            "type": "structure",
            "required": [
              "EarliestTime",
              "LatestTime"
            ],
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "MaxSlotDurationInHours": {
            "type": "integer"
          },
          "MinSlotDurationInHours": {
            "type": "integer"
          },
          "NextToken": {},
          "Recurrence": {
            "type": "structure",
            "members": {
              "Frequency": {},
              "Interval": {
                "type": "integer"
              },
              "OccurrenceDays": {
                "locationName": "OccurrenceDay",
                "type": "list",
                "member": {
                  "locationName": "OccurenceDay",
                  "type": "integer"
                }
              },
              "OccurrenceRelativeToEnd": {
                "type": "boolean"
              },
              "OccurrenceUnit": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceAvailabilitySet": {
            "locationName": "scheduledInstanceAvailabilitySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "AvailableInstanceCount": {
                  "locationName": "availableInstanceCount",
                  "type": "integer"
                },
                "FirstSlotStartTime": {
                  "locationName": "firstSlotStartTime",
                  "type": "timestamp"
                },
                "HourlyPrice": {
                  "locationName": "hourlyPrice"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "MaxTermDurationInDays": {
                  "locationName": "maxTermDurationInDays",
                  "type": "integer"
                },
                "MinTermDurationInDays": {
                  "locationName": "minTermDurationInDays",
                  "type": "integer"
                },
                "NetworkPlatform": {
                  "locationName": "networkPlatform"
                },
                "Platform": {
                  "locationName": "platform"
                },
                "PurchaseToken": {
                  "locationName": "purchaseToken"
                },
                "Recurrence": {
                  "shape": "Ssh",
                  "locationName": "recurrence"
                },
                "SlotDurationInHours": {
                  "locationName": "slotDurationInHours",
                  "type": "integer"
                },
                "TotalScheduledInstanceHours": {
                  "locationName": "totalScheduledInstanceHours",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeScheduledInstances": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ScheduledInstanceIds": {
            "locationName": "ScheduledInstanceId",
            "type": "list",
            "member": {
              "locationName": "ScheduledInstanceId"
            }
          },
          "SlotStartTimeRange": {
            "type": "structure",
            "members": {
              "EarliestTime": {
                "type": "timestamp"
              },
              "LatestTime": {
                "type": "timestamp"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "Sso",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeSecurityGroupReferences": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroupReferenceSet": {
            "locationName": "securityGroupReferenceSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "GroupId": {
                  "locationName": "groupId"
                },
                "ReferencingVpcId": {
                  "locationName": "referencingVpcId"
                },
                "VpcPeeringConnectionId": {
                  "locationName": "vpcPeeringConnectionId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSecurityGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "GroupIds": {
            "shape": "S2m",
            "locationName": "GroupId"
          },
          "GroupNames": {
            "shape": "Ssv",
            "locationName": "GroupName"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityGroups": {
            "locationName": "securityGroupInfo",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "groupDescription"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "IpPermissions": {
                  "shape": "S33",
                  "locationName": "ipPermissions"
                },
                "OwnerId": {
                  "locationName": "ownerId"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "IpPermissionsEgress": {
                  "shape": "S33",
                  "locationName": "ipPermissionsEgress"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateVolumePermissions": {
            "shape": "St3",
            "locationName": "createVolumePermission"
          },
          "ProductCodes": {
            "shape": "Sll",
            "locationName": "productCodes"
          },
          "SnapshotId": {
            "locationName": "snapshotId"
          }
        }
      }
    },
    "DescribeSnapshots": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "OwnerIds": {
            "shape": "Slq",
            "locationName": "Owner"
          },
          "RestorableByUserIds": {
            "locationName": "RestorableBy",
            "type": "list",
            "member": {}
          },
          "SnapshotIds": {
            "locationName": "SnapshotId",
            "type": "list",
            "member": {
              "locationName": "SnapshotId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Snapshots": {
            "locationName": "snapshotSet",
            "type": "list",
            "member": {
              "shape": "Sbl",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotDatafeedSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotDatafeedSubscription": {
            "shape": "Sbw",
            "locationName": "spotDatafeedSubscription"
          }
        }
      }
    },
    "DescribeSpotFleetInstances": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ActiveInstances": {
            "shape": "Sks",
            "locationName": "activeInstanceSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      }
    },
    "DescribeSpotFleetRequestHistory": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId",
          "StartTime"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EventType": {
            "locationName": "eventType"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HistoryRecords": {
            "locationName": "historyRecordSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "EventInformation": {
                  "shape": "Skp",
                  "locationName": "eventInformation"
                },
                "EventType": {
                  "locationName": "eventType"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          },
          "LastEvaluatedTime": {
            "locationName": "lastEvaluatedTime",
            "type": "timestamp"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeSpotFleetRequests": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestIds": {
            "shape": "Sd",
            "locationName": "spotFleetRequestId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotFleetRequestConfigs": {
            "locationName": "spotFleetRequestConfigSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ActivityStatus": {
                  "locationName": "activityStatus"
                },
                "CreateTime": {
                  "locationName": "createTime",
                  "type": "timestamp"
                },
                "SpotFleetRequestConfig": {
                  "shape": "Stq",
                  "locationName": "spotFleetRequestConfig"
                },
                "SpotFleetRequestId": {
                  "locationName": "spotFleetRequestId"
                },
                "SpotFleetRequestState": {
                  "locationName": "spotFleetRequestState"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSpotInstanceRequests": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotInstanceRequestIds": {
            "shape": "S4h",
            "locationName": "SpotInstanceRequestId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "Suf",
            "locationName": "spotInstanceRequestSet"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeSpotPriceHistory": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "InstanceTypes": {
            "locationName": "InstanceType",
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "ProductDescriptions": {
            "locationName": "ProductDescription",
            "type": "list",
            "member": {}
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "SpotPriceHistory": {
            "locationName": "spotPriceHistorySet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "InstanceType": {
                  "locationName": "instanceType"
                },
                "ProductDescription": {
                  "locationName": "productDescription"
                },
                "SpotPrice": {
                  "locationName": "spotPrice"
                },
                "Timestamp": {
                  "locationName": "timestamp",
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeStaleSecurityGroups": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "StaleSecurityGroupSet": {
            "locationName": "staleSecurityGroupSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "GroupId": {
                  "locationName": "groupId"
                },
                "GroupName": {
                  "locationName": "groupName"
                },
                "StaleIpPermissions": {
                  "shape": "Sux",
                  "locationName": "staleIpPermissions"
                },
                "StaleIpPermissionsEgress": {
                  "shape": "Sux",
                  "locationName": "staleIpPermissionsEgress"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSubnets": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "SubnetIds": {
            "locationName": "SubnetId",
            "type": "list",
            "member": {
              "locationName": "SubnetId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Subnets": {
            "locationName": "subnetSet",
            "type": "list",
            "member": {
              "shape": "S5n",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTags": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Tags": {
            "locationName": "tagSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Key": {
                  "locationName": "key"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "Value": {
                  "locationName": "value"
                }
              }
            }
          }
        }
      }
    },
    "DescribeTrafficMirrorFilters": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterIds": {
            "shape": "Sd",
            "locationName": "TrafficMirrorFilterId"
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilters": {
            "locationName": "trafficMirrorFilterSet",
            "type": "list",
            "member": {
              "shape": "Sc5",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrafficMirrorSessions": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessionIds": {
            "shape": "Sd",
            "locationName": "TrafficMirrorSessionId"
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSessions": {
            "locationName": "trafficMirrorSessionSet",
            "type": "list",
            "member": {
              "shape": "Sci",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTrafficMirrorTargets": {
      "input": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargetIds": {
            "shape": "Sd",
            "locationName": "TrafficMirrorTargetId"
          },
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorTargets": {
            "locationName": "trafficMirrorTargetSet",
            "type": "list",
            "member": {
              "shape": "Scl",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "Svm"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachments": {
            "locationName": "transitGatewayAttachments",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "TransitGatewayId": {
                  "locationName": "transitGatewayId"
                },
                "TransitGatewayOwnerId": {
                  "locationName": "transitGatewayOwnerId"
                },
                "ResourceOwnerId": {
                  "locationName": "resourceOwnerId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "State": {
                  "locationName": "state"
                },
                "Association": {
                  "locationName": "association",
                  "type": "structure",
                  "members": {
                    "TransitGatewayRouteTableId": {
                      "locationName": "transitGatewayRouteTableId"
                    },
                    "State": {
                      "locationName": "state"
                    }
                  }
                },
                "CreationTime": {
                  "locationName": "creationTime",
                  "type": "timestamp"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayRouteTables": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTableIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTables": {
            "locationName": "transitGatewayRouteTables",
            "type": "list",
            "member": {
              "shape": "Sd6",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGatewayVpcAttachments": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentIds": {
            "shape": "Svm"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachments": {
            "locationName": "transitGatewayVpcAttachments",
            "type": "list",
            "member": {
              "shape": "Sb",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeTransitGateways": {
      "input": {
        "type": "structure",
        "members": {
          "TransitGatewayIds": {
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGateways": {
            "locationName": "transitGatewaySet",
            "type": "list",
            "member": {
              "shape": "Scu",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "VolumeId"
        ],
        "members": {
          "Attribute": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AutoEnableIO": {
            "shape": "Sny",
            "locationName": "autoEnableIO"
          },
          "ProductCodes": {
            "shape": "Sll",
            "locationName": "productCodes"
          },
          "VolumeId": {
            "locationName": "volumeId"
          }
        }
      }
    },
    "DescribeVolumeStatus": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "VolumeIds": {
            "shape": "Sw7",
            "locationName": "VolumeId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "VolumeStatuses": {
            "locationName": "volumeStatusSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Actions": {
                  "locationName": "actionsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Code": {
                        "locationName": "code"
                      },
                      "Description": {
                        "locationName": "description"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      },
                      "EventType": {
                        "locationName": "eventType"
                      }
                    }
                  }
                },
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Events": {
                  "locationName": "eventsSet",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Description": {
                        "locationName": "description"
                      },
                      "EventId": {
                        "locationName": "eventId"
                      },
                      "EventType": {
                        "locationName": "eventType"
                      },
                      "NotAfter": {
                        "locationName": "notAfter",
                        "type": "timestamp"
                      },
                      "NotBefore": {
                        "locationName": "notBefore",
                        "type": "timestamp"
                      }
                    }
                  }
                },
                "VolumeId": {
                  "locationName": "volumeId"
                },
                "VolumeStatus": {
                  "locationName": "volumeStatus",
                  "type": "structure",
                  "members": {
                    "Details": {
                      "locationName": "details",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Name": {
                            "locationName": "name"
                          },
                          "Status": {
                            "locationName": "status"
                          }
                        }
                      }
                    },
                    "Status": {
                      "locationName": "status"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "VolumeIds": {
            "shape": "Sw7",
            "locationName": "VolumeId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Volumes": {
            "locationName": "volumeSet",
            "type": "list",
            "member": {
              "shape": "Sdc",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVolumesModifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VolumeIds": {
            "shape": "Sw7",
            "locationName": "VolumeId"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumesModifications": {
            "locationName": "volumeModificationSet",
            "type": "list",
            "member": {
              "shape": "Swq",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "VpcId"
        ],
        "members": {
          "Attribute": {},
          "VpcId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcId": {
            "locationName": "vpcId"
          },
          "EnableDnsHostnames": {
            "shape": "Sny",
            "locationName": "enableDnsHostnames"
          },
          "EnableDnsSupport": {
            "shape": "Sny",
            "locationName": "enableDnsSupport"
          }
        }
      }
    },
    "DescribeVpcClassicLink": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcIds": {
            "shape": "Sww",
            "locationName": "VpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClassicLinkEnabled": {
                  "locationName": "classicLinkEnabled",
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken"
          },
          "VpcIds": {
            "shape": "Sww"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Vpcs": {
            "locationName": "vpcs",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ClassicLinkDnsSupported": {
                  "locationName": "classicLinkDnsSupported",
                  "type": "boolean"
                },
                "VpcId": {
                  "locationName": "vpcId"
                }
              }
            }
          }
        }
      }
    },
    "DescribeVpcEndpointConnectionNotifications": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConnectionNotificationSet": {
            "locationName": "connectionNotificationSet",
            "type": "list",
            "member": {
              "shape": "Sds",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpointConnections": {
            "locationName": "vpcEndpointConnectionSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ServiceId": {
                  "locationName": "serviceId"
                },
                "VpcEndpointId": {
                  "locationName": "vpcEndpointId"
                },
                "VpcEndpointOwner": {
                  "locationName": "vpcEndpointOwner"
                },
                "VpcEndpointState": {
                  "locationName": "vpcEndpointState"
                },
                "CreationTimestamp": {
                  "locationName": "creationTimestamp",
                  "type": "timestamp"
                },
                "DnsEntries": {
                  "shape": "Sdo",
                  "locationName": "dnsEntrySet"
                },
                "NetworkLoadBalancerArns": {
                  "shape": "Sd",
                  "locationName": "networkLoadBalancerArnSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServiceConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceIds": {
            "shape": "Sd",
            "locationName": "ServiceId"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceConfigurations": {
            "locationName": "serviceConfigurationSet",
            "type": "list",
            "member": {
              "shape": "Sdx",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServicePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AllowedPrincipals": {
            "locationName": "allowedPrincipals",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "PrincipalType": {
                  "locationName": "principalType"
                },
                "Principal": {
                  "locationName": "principal"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpointServices": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceNames": {
            "shape": "Sd",
            "locationName": "ServiceName"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceNames": {
            "shape": "Sd",
            "locationName": "serviceNameSet"
          },
          "ServiceDetails": {
            "locationName": "serviceDetailSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ServiceName": {
                  "locationName": "serviceName"
                },
                "ServiceId": {
                  "locationName": "serviceId"
                },
                "ServiceType": {
                  "shape": "Sdy",
                  "locationName": "serviceType"
                },
                "AvailabilityZones": {
                  "shape": "Sd",
                  "locationName": "availabilityZoneSet"
                },
                "Owner": {
                  "locationName": "owner"
                },
                "BaseEndpointDnsNames": {
                  "shape": "Sd",
                  "locationName": "baseEndpointDnsNameSet"
                },
                "PrivateDnsName": {
                  "locationName": "privateDnsName"
                },
                "VpcEndpointPolicySupported": {
                  "locationName": "vpcEndpointPolicySupported",
                  "type": "boolean"
                },
                "AcceptanceRequired": {
                  "locationName": "acceptanceRequired",
                  "type": "boolean"
                },
                "ManagesVpcEndpoints": {
                  "locationName": "managesVpcEndpoints",
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "Si",
                  "locationName": "tagSet"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointIds": {
            "shape": "Sd",
            "locationName": "VpcEndpointId"
          },
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcEndpoints": {
            "locationName": "vpcEndpointSet",
            "type": "list",
            "member": {
              "shape": "Sdk",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcPeeringConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionIds": {
            "shape": "Sd",
            "locationName": "VpcPeeringConnectionId"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnections": {
            "locationName": "vpcPeeringConnectionSet",
            "type": "list",
            "member": {
              "shape": "Sr",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpcs": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "VpcIds": {
            "locationName": "VpcId",
            "type": "list",
            "member": {
              "locationName": "VpcId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Vpcs": {
            "locationName": "vpcSet",
            "type": "list",
            "member": {
              "shape": "S5s",
              "locationName": "item"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DescribeVpnConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "VpnConnectionIds": {
            "locationName": "VpnConnectionId",
            "type": "list",
            "member": {
              "locationName": "VpnConnectionId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnections": {
            "locationName": "vpnConnectionSet",
            "type": "list",
            "member": {
              "shape": "Se9",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DescribeVpnGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "VpnGatewayIds": {
            "locationName": "VpnGatewayId",
            "type": "list",
            "member": {
              "locationName": "VpnGatewayId"
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnGateways": {
            "locationName": "vpnGatewaySet",
            "type": "list",
            "member": {
              "shape": "Sel",
              "locationName": "item"
            }
          }
        }
      }
    },
    "DetachClassicLinkVpc": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DetachInternetGateway": {
      "input": {
        "type": "structure",
        "required": [
          "InternetGatewayId",
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InternetGatewayId": {
            "locationName": "internetGatewayId"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "DetachNetworkInterface": {
      "input": {
        "type": "structure",
        "required": [
          "AttachmentId"
        ],
        "members": {
          "AttachmentId": {
            "locationName": "attachmentId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DetachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "Device": {},
          "Force": {
            "type": "boolean"
          },
          "InstanceId": {},
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "shape": "S2s"
      }
    },
    "DetachVpnGateway": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "VpnGatewayId"
        ],
        "members": {
          "VpcId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisableEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "DisableTransitGatewayRouteTablePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Propagation": {
            "shape": "Syj",
            "locationName": "propagation"
          }
        }
      }
    },
    "DisableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayId",
          "RouteTableId"
        ],
        "members": {
          "GatewayId": {},
          "RouteTableId": {}
        }
      }
    },
    "DisableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AssociationId": {},
          "PublicIp": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateClientVpnTargetNetwork": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "AssociationId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "AssociationId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "Status": {
            "shape": "S1u",
            "locationName": "status"
          }
        }
      }
    },
    "DisassociateIamInstanceProfile": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S20",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "DisassociateRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateSubnetCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S27",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      }
    },
    "DisassociateTransitGatewayRouteTable": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Association": {
            "shape": "S2c",
            "locationName": "association"
          }
        }
      }
    },
    "DisassociateVpcCidrBlock": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Ipv6CidrBlockAssociation": {
            "shape": "S2h",
            "locationName": "ipv6CidrBlockAssociation"
          },
          "CidrBlockAssociation": {
            "shape": "S2k",
            "locationName": "cidrBlockAssociation"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "EnableEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "EnableTransitGatewayRouteTablePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Propagation": {
            "shape": "Syj",
            "locationName": "propagation"
          }
        }
      }
    },
    "EnableVgwRoutePropagation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayId",
          "RouteTableId"
        ],
        "members": {
          "GatewayId": {},
          "RouteTableId": {}
        }
      }
    },
    "EnableVolumeIO": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VolumeId": {
            "locationName": "volumeId"
          }
        }
      }
    },
    "EnableVpcClassicLink": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "EnableVpcClassicLinkDnsSupport": {
      "input": {
        "type": "structure",
        "members": {
          "VpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ExportClientVpnClientCertificateRevocationList": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CertificateRevocationList": {
            "locationName": "certificateRevocationList"
          },
          "Status": {
            "locationName": "status",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              }
            }
          }
        }
      }
    },
    "ExportClientVpnClientConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientConfiguration": {
            "locationName": "clientConfiguration"
          }
        }
      }
    },
    "ExportImage": {
      "input": {
        "type": "structure",
        "required": [
          "DiskImageFormat",
          "ImageId",
          "S3ExportLocation"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "Description": {},
          "DiskImageFormat": {},
          "DryRun": {
            "type": "boolean"
          },
          "ImageId": {},
          "S3ExportLocation": {
            "type": "structure",
            "required": [
              "S3Bucket"
            ],
            "members": {
              "S3Bucket": {},
              "S3Prefix": {}
            }
          },
          "RoleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DiskImageFormat": {
            "locationName": "diskImageFormat"
          },
          "ExportImageTaskId": {
            "locationName": "exportImageTaskId"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "RoleName": {
            "locationName": "roleName"
          },
          "Progress": {
            "locationName": "progress"
          },
          "S3ExportLocation": {
            "shape": "Skf",
            "locationName": "s3ExportLocation"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          }
        }
      }
    },
    "ExportTransitGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "S3Bucket"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "S3Bucket": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "S3Location": {
            "locationName": "s3Location"
          }
        }
      }
    },
    "GetCapacityReservationUsage": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "CapacityReservationId": {
            "locationName": "capacityReservationId"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "TotalInstanceCount": {
            "locationName": "totalInstanceCount",
            "type": "integer"
          },
          "AvailableInstanceCount": {
            "locationName": "availableInstanceCount",
            "type": "integer"
          },
          "State": {
            "locationName": "state"
          },
          "InstanceUsages": {
            "locationName": "instanceUsageSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "AccountId": {
                  "locationName": "accountId"
                },
                "UsedInstanceCount": {
                  "locationName": "usedInstanceCount",
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "GetConsoleOutput": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Latest": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Output": {
            "locationName": "output"
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          }
        }
      }
    },
    "GetConsoleScreenshot": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "WakeUp": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageData": {
            "locationName": "imageData"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      }
    },
    "GetEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "GetEbsEncryptionByDefault": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EbsEncryptionByDefault": {
            "locationName": "ebsEncryptionByDefault",
            "type": "boolean"
          }
        }
      }
    },
    "GetHostReservationPurchasePreview": {
      "input": {
        "type": "structure",
        "required": [
          "HostIdSet",
          "OfferingId"
        ],
        "members": {
          "HostIdSet": {
            "shape": "S101"
          },
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Purchase": {
            "shape": "S103",
            "locationName": "purchase"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          }
        }
      }
    },
    "GetLaunchTemplateData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplateData": {
            "shape": "S97",
            "locationName": "launchTemplateData"
          }
        }
      }
    },
    "GetPasswordData": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "PasswordData": {
            "locationName": "passwordData"
          },
          "Timestamp": {
            "locationName": "timestamp",
            "type": "timestamp"
          }
        }
      }
    },
    "GetReservedInstancesExchangeQuote": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstanceIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ReservedInstanceIds": {
            "shape": "S3",
            "locationName": "ReservedInstanceId"
          },
          "TargetConfigurations": {
            "shape": "S5",
            "locationName": "TargetConfiguration"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "IsValidExchange": {
            "locationName": "isValidExchange",
            "type": "boolean"
          },
          "OutputReservedInstancesWillExpireAt": {
            "locationName": "outputReservedInstancesWillExpireAt",
            "type": "timestamp"
          },
          "PaymentDue": {
            "locationName": "paymentDue"
          },
          "ReservedInstanceValueRollup": {
            "shape": "S10b",
            "locationName": "reservedInstanceValueRollup"
          },
          "ReservedInstanceValueSet": {
            "locationName": "reservedInstanceValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservationValue": {
                  "shape": "S10b",
                  "locationName": "reservationValue"
                },
                "ReservedInstanceId": {
                  "locationName": "reservedInstanceId"
                }
              }
            }
          },
          "TargetConfigurationValueRollup": {
            "shape": "S10b",
            "locationName": "targetConfigurationValueRollup"
          },
          "TargetConfigurationValueSet": {
            "locationName": "targetConfigurationValueSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ReservationValue": {
                  "shape": "S10b",
                  "locationName": "reservationValue"
                },
                "TargetConfiguration": {
                  "locationName": "targetConfiguration",
                  "type": "structure",
                  "members": {
                    "InstanceCount": {
                      "locationName": "instanceCount",
                      "type": "integer"
                    },
                    "OfferingId": {
                      "locationName": "offeringId"
                    }
                  }
                }
              }
            }
          },
          "ValidationFailureReason": {
            "locationName": "validationFailureReason"
          }
        }
      }
    },
    "GetTransitGatewayAttachmentPropagations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayAttachmentPropagations": {
            "locationName": "transitGatewayAttachmentPropagations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayRouteTableId": {
                  "locationName": "transitGatewayRouteTableId"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayRouteTableAssociations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Associations": {
            "locationName": "associations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "GetTransitGatewayRouteTablePropagations": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayRouteTablePropagations": {
            "locationName": "transitGatewayRouteTablePropagations",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "TransitGatewayAttachmentId": {
                  "locationName": "transitGatewayAttachmentId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "ResourceType": {
                  "locationName": "resourceType"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ImportClientVpnClientCertificateRevocationList": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "CertificateRevocationList"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "CertificateRevocationList": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ImportImage": {
      "input": {
        "type": "structure",
        "members": {
          "Architecture": {},
          "ClientData": {
            "shape": "S10w"
          },
          "ClientToken": {},
          "Description": {},
          "DiskContainers": {
            "locationName": "DiskContainer",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {},
                "DeviceName": {},
                "Format": {},
                "SnapshotId": {},
                "Url": {},
                "UserBucket": {
                  "shape": "S10z"
                }
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "Hypervisor": {},
          "KmsKeyId": {},
          "LicenseType": {},
          "Platform": {},
          "RoleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Architecture": {
            "locationName": "architecture"
          },
          "Description": {
            "locationName": "description"
          },
          "Encrypted": {
            "locationName": "encrypted",
            "type": "boolean"
          },
          "Hypervisor": {
            "locationName": "hypervisor"
          },
          "ImageId": {
            "locationName": "imageId"
          },
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          },
          "LicenseType": {
            "locationName": "licenseType"
          },
          "Platform": {
            "locationName": "platform"
          },
          "Progress": {
            "locationName": "progress"
          },
          "SnapshotDetails": {
            "shape": "Snk",
            "locationName": "snapshotDetailSet"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          }
        }
      }
    },
    "ImportInstance": {
      "input": {
        "type": "structure",
        "required": [
          "Platform"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DiskImages": {
            "locationName": "diskImage",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Description": {},
                "Image": {
                  "shape": "S114"
                },
                "Volume": {
                  "shape": "S115"
                }
              }
            }
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "AdditionalInfo": {
                "locationName": "additionalInfo"
              },
              "Architecture": {
                "locationName": "architecture"
              },
              "GroupIds": {
                "shape": "S8a",
                "locationName": "GroupId"
              },
              "GroupNames": {
                "shape": "S8o",
                "locationName": "GroupName"
              },
              "InstanceInitiatedShutdownBehavior": {
                "locationName": "instanceInitiatedShutdownBehavior"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "boolean"
              },
              "Placement": {
                "shape": "S6q",
                "locationName": "placement"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "locationName": "userData",
                "type": "structure",
                "members": {
                  "Data": {
                    "locationName": "data"
                  }
                }
              }
            }
          },
          "Platform": {
            "locationName": "platform"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "Sje",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ImportKeyPair": {
      "input": {
        "type": "structure",
        "required": [
          "KeyName",
          "PublicKeyMaterial"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "KeyName": {
            "locationName": "keyName"
          },
          "PublicKeyMaterial": {
            "locationName": "publicKeyMaterial",
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KeyFingerprint": {
            "locationName": "keyFingerprint"
          },
          "KeyName": {
            "locationName": "keyName"
          }
        }
      }
    },
    "ImportSnapshot": {
      "input": {
        "type": "structure",
        "members": {
          "ClientData": {
            "shape": "S10w"
          },
          "ClientToken": {},
          "Description": {},
          "DiskContainer": {
            "type": "structure",
            "members": {
              "Description": {},
              "Format": {},
              "Url": {},
              "UserBucket": {
                "shape": "S10z"
              }
            }
          },
          "DryRun": {
            "type": "boolean"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "KmsKeyId": {},
          "RoleName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "ImportTaskId": {
            "locationName": "importTaskId"
          },
          "SnapshotTaskDetail": {
            "shape": "Snr",
            "locationName": "snapshotTaskDetail"
          }
        }
      }
    },
    "ImportVolume": {
      "input": {
        "type": "structure",
        "required": [
          "AvailabilityZone",
          "Image",
          "Volume"
        ],
        "members": {
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Image": {
            "shape": "S114",
            "locationName": "image"
          },
          "Volume": {
            "shape": "S115",
            "locationName": "volume"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConversionTask": {
            "shape": "Sje",
            "locationName": "conversionTask"
          }
        }
      }
    },
    "ModifyCapacityReservation": {
      "input": {
        "type": "structure",
        "required": [
          "CapacityReservationId"
        ],
        "members": {
          "CapacityReservationId": {},
          "InstanceCount": {
            "type": "integer"
          },
          "EndDate": {
            "type": "timestamp"
          },
          "EndDateType": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyClientVpnEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "ServerCertificateArn": {},
          "ConnectionLogOptions": {
            "shape": "S58"
          },
          "DnsServers": {
            "type": "structure",
            "members": {
              "CustomDnsServers": {
                "shape": "Sd"
              },
              "Enabled": {
                "type": "boolean"
              }
            }
          },
          "Description": {},
          "SplitTunnel": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "required": [
          "KmsKeyId"
        ],
        "members": {
          "KmsKeyId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "ModifyFleet": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "TargetCapacitySpecification"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ExcessCapacityTerminationPolicy": {},
          "FleetId": {},
          "TargetCapacitySpecification": {
            "shape": "S6r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {},
          "OperationType": {},
          "UserIds": {
            "shape": "S11r",
            "locationName": "UserId"
          },
          "UserGroups": {
            "shape": "S11s",
            "locationName": "UserGroup"
          },
          "ProductCodes": {
            "shape": "S11t",
            "locationName": "ProductCode"
          },
          "LoadPermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "S11v"
              },
              "Remove": {
                "shape": "S11v"
              }
            }
          },
          "Description": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FpgaImageAttribute": {
            "shape": "Slh",
            "locationName": "fpgaImageAttribute"
          }
        }
      }
    },
    "ModifyHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds"
        ],
        "members": {
          "AutoPlacement": {
            "locationName": "autoPlacement"
          },
          "HostIds": {
            "shape": "Smc",
            "locationName": "hostId"
          },
          "HostRecovery": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "S1e",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "S120",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ModifyIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "Resource",
          "UseLongIds"
        ],
        "members": {
          "Resource": {},
          "UseLongIds": {
            "type": "boolean"
          }
        }
      }
    },
    "ModifyIdentityIdFormat": {
      "input": {
        "type": "structure",
        "required": [
          "PrincipalArn",
          "Resource",
          "UseLongIds"
        ],
        "members": {
          "PrincipalArn": {
            "locationName": "principalArn"
          },
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "Description": {
            "shape": "S65"
          },
          "ImageId": {},
          "LaunchPermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "Sn0"
              },
              "Remove": {
                "shape": "Sn0"
              }
            }
          },
          "OperationType": {},
          "ProductCodes": {
            "shape": "S11t",
            "locationName": "ProductCode"
          },
          "UserGroups": {
            "shape": "S11s",
            "locationName": "UserGroup"
          },
          "UserIds": {
            "shape": "S11r",
            "locationName": "UserId"
          },
          "Value": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "SourceDestCheck": {
            "shape": "Sny"
          },
          "Attribute": {
            "locationName": "attribute"
          },
          "BlockDeviceMappings": {
            "locationName": "blockDeviceMapping",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "DeviceName": {
                  "locationName": "deviceName"
                },
                "Ebs": {
                  "locationName": "ebs",
                  "type": "structure",
                  "members": {
                    "DeleteOnTermination": {
                      "locationName": "deleteOnTermination",
                      "type": "boolean"
                    },
                    "VolumeId": {
                      "locationName": "volumeId"
                    }
                  }
                },
                "NoDevice": {
                  "locationName": "noDevice"
                },
                "VirtualName": {
                  "locationName": "virtualName"
                }
              }
            }
          },
          "DisableApiTermination": {
            "shape": "Sny",
            "locationName": "disableApiTermination"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EbsOptimized": {
            "shape": "Sny",
            "locationName": "ebsOptimized"
          },
          "EnaSupport": {
            "shape": "Sny",
            "locationName": "enaSupport"
          },
          "Groups": {
            "shape": "S2m",
            "locationName": "GroupId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceInitiatedShutdownBehavior": {
            "shape": "S65",
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "InstanceType": {
            "shape": "S65",
            "locationName": "instanceType"
          },
          "Kernel": {
            "shape": "S65",
            "locationName": "kernel"
          },
          "Ramdisk": {
            "shape": "S65",
            "locationName": "ramdisk"
          },
          "SriovNetSupport": {
            "shape": "S65",
            "locationName": "sriovNetSupport"
          },
          "UserData": {
            "locationName": "userData",
            "type": "structure",
            "members": {
              "Value": {
                "locationName": "value",
                "type": "blob"
              }
            }
          },
          "Value": {
            "locationName": "value"
          }
        }
      }
    },
    "ModifyInstanceCapacityReservationAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "CapacityReservationSpecification"
        ],
        "members": {
          "InstanceId": {},
          "CapacityReservationSpecification": {
            "shape": "S12b"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyInstanceCreditSpecification": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceCreditSpecifications"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "InstanceCreditSpecifications": {
            "locationName": "InstanceCreditSpecification",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {},
                "CpuCredits": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SuccessfulInstanceCreditSpecifications": {
            "locationName": "successfulInstanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                }
              }
            }
          },
          "UnsuccessfulInstanceCreditSpecifications": {
            "locationName": "unsuccessfulInstanceCreditSpecificationSet",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceId": {
                  "locationName": "instanceId"
                },
                "Error": {
                  "locationName": "error",
                  "type": "structure",
                  "members": {
                    "Code": {
                      "locationName": "code"
                    },
                    "Message": {
                      "locationName": "message"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ModifyInstanceEventStartTime": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InstanceEventId",
          "NotBefore"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "InstanceId": {},
          "InstanceEventId": {},
          "NotBefore": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Event": {
            "shape": "So9",
            "locationName": "event"
          }
        }
      }
    },
    "ModifyInstancePlacement": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "Affinity": {
            "locationName": "affinity"
          },
          "GroupName": {},
          "HostId": {
            "locationName": "hostId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Tenancy": {
            "locationName": "tenancy"
          },
          "PartitionNumber": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyLaunchTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ClientToken": {},
          "LaunchTemplateId": {},
          "LaunchTemplateName": {},
          "DefaultVersion": {
            "locationName": "SetDefaultVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchTemplate": {
            "shape": "S93",
            "locationName": "launchTemplate"
          }
        }
      }
    },
    "ModifyNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "Attachment": {
            "locationName": "attachment",
            "type": "structure",
            "members": {
              "AttachmentId": {
                "locationName": "attachmentId"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              }
            }
          },
          "Description": {
            "shape": "S65",
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Groups": {
            "shape": "S8a",
            "locationName": "SecurityGroupId"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "shape": "Sny",
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "ModifyReservedInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ReservedInstancesIds",
          "TargetConfigurations"
        ],
        "members": {
          "ReservedInstancesIds": {
            "shape": "Sra",
            "locationName": "ReservedInstancesId"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "TargetConfigurations": {
            "locationName": "ReservedInstancesConfigurationSetItemType",
            "type": "list",
            "member": {
              "shape": "Srv",
              "locationName": "item"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesModificationId": {
            "locationName": "reservedInstancesModificationId"
          }
        }
      }
    },
    "ModifySnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "CreateVolumePermission": {
            "type": "structure",
            "members": {
              "Add": {
                "shape": "St3"
              },
              "Remove": {
                "shape": "St3"
              }
            }
          },
          "GroupNames": {
            "shape": "Ssv",
            "locationName": "UserGroup"
          },
          "OperationType": {},
          "SnapshotId": {},
          "UserIds": {
            "shape": "S11r",
            "locationName": "UserId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySpotFleetRequest": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestId"
        ],
        "members": {
          "ExcessCapacityTerminationPolicy": {
            "locationName": "excessCapacityTerminationPolicy"
          },
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          },
          "TargetCapacity": {
            "locationName": "targetCapacity",
            "type": "integer"
          },
          "OnDemandTargetCapacity": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifySubnetAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "SubnetId"
        ],
        "members": {
          "AssignIpv6AddressOnCreation": {
            "shape": "Sny"
          },
          "MapPublicIpOnLaunch": {
            "shape": "Sny"
          },
          "SubnetId": {
            "locationName": "subnetId"
          }
        }
      }
    },
    "ModifyTrafficMirrorFilterNetworkServices": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterId"
        ],
        "members": {
          "TrafficMirrorFilterId": {},
          "AddNetworkServices": {
            "shape": "Scb",
            "locationName": "AddNetworkService"
          },
          "RemoveNetworkServices": {
            "shape": "Scb",
            "locationName": "RemoveNetworkService"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilter": {
            "shape": "Sc5",
            "locationName": "trafficMirrorFilter"
          }
        }
      }
    },
    "ModifyTrafficMirrorFilterRule": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorFilterRuleId"
        ],
        "members": {
          "TrafficMirrorFilterRuleId": {},
          "TrafficDirection": {},
          "RuleNumber": {
            "type": "integer"
          },
          "RuleAction": {},
          "DestinationPortRange": {
            "shape": "Sce"
          },
          "SourcePortRange": {
            "shape": "Sce"
          },
          "Protocol": {
            "type": "integer"
          },
          "DestinationCidrBlock": {},
          "SourceCidrBlock": {},
          "Description": {},
          "RemoveFields": {
            "locationName": "RemoveField",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorFilterRule": {
            "shape": "Sc7",
            "locationName": "trafficMirrorFilterRule"
          }
        }
      }
    },
    "ModifyTrafficMirrorSession": {
      "input": {
        "type": "structure",
        "required": [
          "TrafficMirrorSessionId"
        ],
        "members": {
          "TrafficMirrorSessionId": {},
          "TrafficMirrorTargetId": {},
          "TrafficMirrorFilterId": {},
          "PacketLength": {
            "type": "integer"
          },
          "SessionNumber": {
            "type": "integer"
          },
          "VirtualNetworkId": {
            "type": "integer"
          },
          "Description": {},
          "RemoveFields": {
            "locationName": "RemoveField",
            "type": "list",
            "member": {}
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrafficMirrorSession": {
            "shape": "Sci",
            "locationName": "trafficMirrorSession"
          }
        }
      }
    },
    "ModifyTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "AddSubnetIds": {
            "shape": "Sd"
          },
          "RemoveSubnetIds": {
            "shape": "Sd"
          },
          "Options": {
            "type": "structure",
            "members": {
              "DnsSupport": {},
              "Ipv6Support": {}
            }
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sb",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "ModifyVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VolumeId": {},
          "Size": {
            "type": "integer"
          },
          "VolumeType": {},
          "Iops": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeModification": {
            "shape": "Swq",
            "locationName": "volumeModification"
          }
        }
      }
    },
    "ModifyVolumeAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeId"
        ],
        "members": {
          "AutoEnableIO": {
            "shape": "Sny"
          },
          "VolumeId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId"
        ],
        "members": {
          "EnableDnsHostnames": {
            "shape": "Sny"
          },
          "EnableDnsSupport": {
            "shape": "Sny"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "ModifyVpcEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "VpcEndpointId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "VpcEndpointId": {},
          "ResetPolicy": {
            "type": "boolean"
          },
          "PolicyDocument": {},
          "AddRouteTableIds": {
            "shape": "Sd",
            "locationName": "AddRouteTableId"
          },
          "RemoveRouteTableIds": {
            "shape": "Sd",
            "locationName": "RemoveRouteTableId"
          },
          "AddSubnetIds": {
            "shape": "Sd",
            "locationName": "AddSubnetId"
          },
          "RemoveSubnetIds": {
            "shape": "Sd",
            "locationName": "RemoveSubnetId"
          },
          "AddSecurityGroupIds": {
            "shape": "Sd",
            "locationName": "AddSecurityGroupId"
          },
          "RemoveSecurityGroupIds": {
            "shape": "Sd",
            "locationName": "RemoveSecurityGroupId"
          },
          "PrivateDnsEnabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointConnectionNotification": {
      "input": {
        "type": "structure",
        "required": [
          "ConnectionNotificationId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ConnectionNotificationId": {},
          "ConnectionNotificationArn": {},
          "ConnectionEvents": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointServiceConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "AddNetworkLoadBalancerArns": {
            "shape": "Sd",
            "locationName": "AddNetworkLoadBalancerArn"
          },
          "RemoveNetworkLoadBalancerArns": {
            "shape": "Sd",
            "locationName": "RemoveNetworkLoadBalancerArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcEndpointServicePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "AddAllowedPrincipals": {
            "shape": "Sd"
          },
          "RemoveAllowedPrincipals": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpcPeeringConnectionOptions": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "AccepterPeeringConnectionOptions": {
            "shape": "S13v"
          },
          "DryRun": {
            "type": "boolean"
          },
          "RequesterPeeringConnectionOptions": {
            "shape": "S13v"
          },
          "VpcPeeringConnectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccepterPeeringConnectionOptions": {
            "shape": "S13x",
            "locationName": "accepterPeeringConnectionOptions"
          },
          "RequesterPeeringConnectionOptions": {
            "shape": "S13x",
            "locationName": "requesterPeeringConnectionOptions"
          }
        }
      }
    },
    "ModifyVpcTenancy": {
      "input": {
        "type": "structure",
        "required": [
          "VpcId",
          "InstanceTenancy"
        ],
        "members": {
          "VpcId": {},
          "InstanceTenancy": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ModifyVpnConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId"
        ],
        "members": {
          "VpnConnectionId": {},
          "TransitGatewayId": {},
          "CustomerGatewayId": {},
          "VpnGatewayId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Se9",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "ModifyVpnTunnelCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "VpnConnectionId",
          "VpnTunnelOutsideIpAddress"
        ],
        "members": {
          "VpnConnectionId": {},
          "VpnTunnelOutsideIpAddress": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpnConnection": {
            "shape": "Se9",
            "locationName": "vpnConnection"
          }
        }
      }
    },
    "MonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "S147",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "MoveAddressToVpc": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AllocationId": {
            "locationName": "allocationId"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "ProvisionByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "CidrAuthorizationContext": {
            "type": "structure",
            "required": [
              "Message",
              "Signature"
            ],
            "members": {
              "Message": {},
              "Signature": {}
            }
          },
          "Description": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S12",
            "locationName": "byoipCidr"
          }
        }
      }
    },
    "PurchaseHostReservation": {
      "input": {
        "type": "structure",
        "required": [
          "HostIdSet",
          "OfferingId"
        ],
        "members": {
          "ClientToken": {},
          "CurrencyCode": {},
          "HostIdSet": {
            "shape": "S101"
          },
          "LimitPrice": {},
          "OfferingId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Purchase": {
            "shape": "S103",
            "locationName": "purchase"
          },
          "TotalHourlyPrice": {
            "locationName": "totalHourlyPrice"
          },
          "TotalUpfrontPrice": {
            "locationName": "totalUpfrontPrice"
          }
        }
      }
    },
    "PurchaseReservedInstancesOffering": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceCount",
          "ReservedInstancesOfferingId"
        ],
        "members": {
          "InstanceCount": {
            "type": "integer"
          },
          "ReservedInstancesOfferingId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "LimitPrice": {
            "locationName": "limitPrice",
            "type": "structure",
            "members": {
              "Amount": {
                "locationName": "amount",
                "type": "double"
              },
              "CurrencyCode": {
                "locationName": "currencyCode"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          }
        }
      }
    },
    "PurchaseScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "PurchaseRequests"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "PurchaseRequests": {
            "locationName": "PurchaseRequest",
            "type": "list",
            "member": {
              "locationName": "PurchaseRequest",
              "type": "structure",
              "required": [
                "InstanceCount",
                "PurchaseToken"
              ],
              "members": {
                "InstanceCount": {
                  "type": "integer"
                },
                "PurchaseToken": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScheduledInstanceSet": {
            "locationName": "scheduledInstanceSet",
            "type": "list",
            "member": {
              "shape": "Sso",
              "locationName": "item"
            }
          }
        }
      }
    },
    "RebootInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RegisterImage": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "ImageLocation": {},
          "Architecture": {
            "locationName": "architecture"
          },
          "BlockDeviceMappings": {
            "shape": "S7h",
            "locationName": "BlockDeviceMapping"
          },
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EnaSupport": {
            "locationName": "enaSupport",
            "type": "boolean"
          },
          "KernelId": {
            "locationName": "kernelId"
          },
          "Name": {
            "locationName": "name"
          },
          "BillingProducts": {
            "locationName": "BillingProduct",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "RamdiskId": {
            "locationName": "ramdiskId"
          },
          "RootDeviceName": {
            "locationName": "rootDeviceName"
          },
          "SriovNetSupport": {
            "locationName": "sriovNetSupport"
          },
          "VirtualizationType": {
            "locationName": "virtualizationType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ImageId": {
            "locationName": "imageId"
          }
        }
      }
    },
    "RejectTransitGatewayVpcAttachment": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayAttachmentId"
        ],
        "members": {
          "TransitGatewayAttachmentId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TransitGatewayVpcAttachment": {
            "shape": "Sb",
            "locationName": "transitGatewayVpcAttachment"
          }
        }
      }
    },
    "RejectVpcEndpointConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceId",
          "VpcEndpointIds"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "ServiceId": {},
          "VpcEndpointIds": {
            "shape": "Sd",
            "locationName": "VpcEndpointId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Unsuccessful": {
            "shape": "Sm",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "RejectVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "VpcPeeringConnectionId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ReleaseAddress": {
      "input": {
        "type": "structure",
        "members": {
          "AllocationId": {},
          "PublicIp": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ReleaseHosts": {
      "input": {
        "type": "structure",
        "required": [
          "HostIds"
        ],
        "members": {
          "HostIds": {
            "shape": "Smc",
            "locationName": "hostId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Successful": {
            "shape": "S1e",
            "locationName": "successful"
          },
          "Unsuccessful": {
            "shape": "S120",
            "locationName": "unsuccessful"
          }
        }
      }
    },
    "ReplaceIamInstanceProfileAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "IamInstanceProfile",
          "AssociationId"
        ],
        "members": {
          "IamInstanceProfile": {
            "shape": "S1y"
          },
          "AssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IamInstanceProfileAssociation": {
            "shape": "S20",
            "locationName": "iamInstanceProfileAssociation"
          }
        }
      }
    },
    "ReplaceNetworkAclAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "NetworkAclId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          }
        }
      }
    },
    "ReplaceNetworkAclEntry": {
      "input": {
        "type": "structure",
        "required": [
          "Egress",
          "NetworkAclId",
          "Protocol",
          "RuleAction",
          "RuleNumber"
        ],
        "members": {
          "CidrBlock": {
            "locationName": "cidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Egress": {
            "locationName": "egress",
            "type": "boolean"
          },
          "IcmpTypeCode": {
            "shape": "Sab",
            "locationName": "Icmp"
          },
          "Ipv6CidrBlock": {
            "locationName": "ipv6CidrBlock"
          },
          "NetworkAclId": {
            "locationName": "networkAclId"
          },
          "PortRange": {
            "shape": "Sac",
            "locationName": "portRange"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RuleAction": {
            "locationName": "ruleAction"
          },
          "RuleNumber": {
            "locationName": "ruleNumber",
            "type": "integer"
          }
        }
      }
    },
    "ReplaceRoute": {
      "input": {
        "type": "structure",
        "required": [
          "RouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {
            "locationName": "destinationCidrBlock"
          },
          "DestinationIpv6CidrBlock": {
            "locationName": "destinationIpv6CidrBlock"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EgressOnlyInternetGatewayId": {
            "locationName": "egressOnlyInternetGatewayId"
          },
          "GatewayId": {
            "locationName": "gatewayId"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "NatGatewayId": {
            "locationName": "natGatewayId"
          },
          "TransitGatewayId": {},
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          },
          "VpcPeeringConnectionId": {
            "locationName": "vpcPeeringConnectionId"
          }
        }
      }
    },
    "ReplaceRouteTableAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "AssociationId",
          "RouteTableId"
        ],
        "members": {
          "AssociationId": {
            "locationName": "associationId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "RouteTableId": {
            "locationName": "routeTableId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NewAssociationId": {
            "locationName": "newAssociationId"
          }
        }
      }
    },
    "ReplaceTransitGatewayRoute": {
      "input": {
        "type": "structure",
        "required": [
          "DestinationCidrBlock",
          "TransitGatewayRouteTableId"
        ],
        "members": {
          "DestinationCidrBlock": {},
          "TransitGatewayRouteTableId": {},
          "TransitGatewayAttachmentId": {},
          "Blackhole": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Route": {
            "shape": "Scz",
            "locationName": "route"
          }
        }
      }
    },
    "ReportInstanceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Instances",
          "ReasonCodes",
          "Status"
        ],
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EndTime": {
            "locationName": "endTime",
            "type": "timestamp"
          },
          "Instances": {
            "shape": "Si5",
            "locationName": "instanceId"
          },
          "ReasonCodes": {
            "locationName": "reasonCode",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "StartTime": {
            "locationName": "startTime",
            "type": "timestamp"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "RequestSpotFleet": {
      "input": {
        "type": "structure",
        "required": [
          "SpotFleetRequestConfig"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "SpotFleetRequestConfig": {
            "shape": "Stq",
            "locationName": "spotFleetRequestConfig"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotFleetRequestId": {
            "locationName": "spotFleetRequestId"
          }
        }
      }
    },
    "RequestSpotInstances": {
      "input": {
        "type": "structure",
        "members": {
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceCount": {
            "locationName": "instanceCount",
            "type": "integer"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "LaunchSpecification": {
            "type": "structure",
            "members": {
              "SecurityGroupIds": {
                "shape": "Sd",
                "locationName": "SecurityGroupId"
              },
              "SecurityGroups": {
                "shape": "Sd",
                "locationName": "SecurityGroup"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "Smz",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S1y",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "Monitoring": {
                "shape": "Sui",
                "locationName": "monitoring"
              },
              "NetworkInterfaces": {
                "shape": "Stx",
                "locationName": "NetworkInterface"
              },
              "Placement": {
                "shape": "Stz",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "locationName": "userData"
              }
            }
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "Type": {
            "locationName": "type"
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "InstanceInterruptionBehavior": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SpotInstanceRequests": {
            "shape": "Suf",
            "locationName": "spotInstanceRequestSet"
          }
        }
      }
    },
    "ResetEbsDefaultKmsKeyId": {
      "input": {
        "type": "structure",
        "members": {
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "KmsKeyId": {
            "locationName": "kmsKeyId"
          }
        }
      }
    },
    "ResetFpgaImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "FpgaImageId"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "FpgaImageId": {},
          "Attribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "ResetImageAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "ImageId"
        ],
        "members": {
          "Attribute": {},
          "ImageId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "ResetInstanceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "InstanceId"
        ],
        "members": {
          "Attribute": {
            "locationName": "attribute"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "InstanceId": {
            "locationName": "instanceId"
          }
        }
      }
    },
    "ResetNetworkInterfaceAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "SourceDestCheck": {
            "locationName": "sourceDestCheck"
          }
        }
      }
    },
    "ResetSnapshotAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "Attribute",
          "SnapshotId"
        ],
        "members": {
          "Attribute": {},
          "SnapshotId": {},
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RestoreAddressToClassic": {
      "input": {
        "type": "structure",
        "required": [
          "PublicIp"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "PublicIp": {
            "locationName": "publicIp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicIp": {
            "locationName": "publicIp"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "RevokeClientVpnIngress": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId",
          "TargetNetworkCidr"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "TargetNetworkCidr": {},
          "AccessGroupId": {},
          "RevokeAllGroups": {
            "type": "boolean"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {
            "shape": "S30",
            "locationName": "status"
          }
        }
      }
    },
    "RevokeSecurityGroupEgress": {
      "input": {
        "type": "structure",
        "required": [
          "GroupId"
        ],
        "members": {
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "GroupId": {
            "locationName": "groupId"
          },
          "IpPermissions": {
            "shape": "S33",
            "locationName": "ipPermissions"
          },
          "CidrIp": {
            "locationName": "cidrIp"
          },
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "SourceSecurityGroupName": {
            "locationName": "sourceSecurityGroupName"
          },
          "SourceSecurityGroupOwnerId": {
            "locationName": "sourceSecurityGroupOwnerId"
          }
        }
      }
    },
    "RevokeSecurityGroupIngress": {
      "input": {
        "type": "structure",
        "members": {
          "CidrIp": {},
          "FromPort": {
            "type": "integer"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S33"
          },
          "IpProtocol": {},
          "SourceSecurityGroupName": {},
          "SourceSecurityGroupOwnerId": {},
          "ToPort": {
            "type": "integer"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      }
    },
    "RunInstances": {
      "input": {
        "type": "structure",
        "required": [
          "MaxCount",
          "MinCount"
        ],
        "members": {
          "BlockDeviceMappings": {
            "shape": "S7h",
            "locationName": "BlockDeviceMapping"
          },
          "ImageId": {},
          "InstanceType": {},
          "Ipv6AddressCount": {
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "S9e",
            "locationName": "Ipv6Address"
          },
          "KernelId": {},
          "KeyName": {},
          "MaxCount": {
            "type": "integer"
          },
          "MinCount": {
            "type": "integer"
          },
          "Monitoring": {
            "shape": "Sui"
          },
          "Placement": {
            "shape": "S6q"
          },
          "RamdiskId": {},
          "SecurityGroupIds": {
            "shape": "S8a",
            "locationName": "SecurityGroupId"
          },
          "SecurityGroups": {
            "shape": "S8o",
            "locationName": "SecurityGroup"
          },
          "SubnetId": {},
          "UserData": {},
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "ClientToken": {
            "locationName": "clientToken"
          },
          "DisableApiTermination": {
            "locationName": "disableApiTermination",
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "EbsOptimized": {
            "locationName": "ebsOptimized",
            "type": "boolean"
          },
          "IamInstanceProfile": {
            "shape": "S1y",
            "locationName": "iamInstanceProfile"
          },
          "InstanceInitiatedShutdownBehavior": {
            "locationName": "instanceInitiatedShutdownBehavior"
          },
          "NetworkInterfaces": {
            "shape": "Stx",
            "locationName": "networkInterface"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "ElasticGpuSpecification": {
            "type": "list",
            "member": {
              "shape": "S8l",
              "locationName": "item"
            }
          },
          "ElasticInferenceAccelerators": {
            "locationName": "ElasticInferenceAccelerator",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "required": [
                "Type"
              ],
              "members": {
                "Type": {}
              }
            }
          },
          "TagSpecifications": {
            "shape": "S19",
            "locationName": "TagSpecification"
          },
          "LaunchTemplate": {
            "type": "structure",
            "members": {
              "LaunchTemplateId": {},
              "LaunchTemplateName": {},
              "Version": {}
            }
          },
          "InstanceMarketOptions": {
            "type": "structure",
            "members": {
              "MarketType": {},
              "SpotOptions": {
                "type": "structure",
                "members": {
                  "MaxPrice": {},
                  "SpotInstanceType": {},
                  "BlockDurationMinutes": {
                    "type": "integer"
                  },
                  "ValidUntil": {
                    "type": "timestamp"
                  },
                  "InstanceInterruptionBehavior": {}
                }
              }
            }
          },
          "CreditSpecification": {
            "shape": "S8u"
          },
          "CpuOptions": {
            "type": "structure",
            "members": {
              "CoreCount": {
                "type": "integer"
              },
              "ThreadsPerCore": {
                "type": "integer"
              }
            }
          },
          "CapacityReservationSpecification": {
            "shape": "S12b"
          },
          "HibernationOptions": {
            "type": "structure",
            "members": {
              "Configured": {
                "type": "boolean"
              }
            }
          },
          "LicenseSpecifications": {
            "locationName": "LicenseSpecification",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "LicenseConfigurationArn": {}
              }
            }
          }
        }
      },
      "output": {
        "shape": "Son"
      }
    },
    "RunScheduledInstances": {
      "input": {
        "type": "structure",
        "required": [
          "LaunchSpecification",
          "ScheduledInstanceId"
        ],
        "members": {
          "ClientToken": {
            "idempotencyToken": true
          },
          "DryRun": {
            "type": "boolean"
          },
          "InstanceCount": {
            "type": "integer"
          },
          "LaunchSpecification": {
            "type": "structure",
            "required": [
              "ImageId"
            ],
            "members": {
              "BlockDeviceMappings": {
                "locationName": "BlockDeviceMapping",
                "type": "list",
                "member": {
                  "locationName": "BlockDeviceMapping",
                  "type": "structure",
                  "members": {
                    "DeviceName": {},
                    "Ebs": {
                      "type": "structure",
                      "members": {
                        "DeleteOnTermination": {
                          "type": "boolean"
                        },
                        "Encrypted": {
                          "type": "boolean"
                        },
                        "Iops": {
                          "type": "integer"
                        },
                        "SnapshotId": {},
                        "VolumeSize": {
                          "type": "integer"
                        },
                        "VolumeType": {}
                      }
                    },
                    "NoDevice": {},
                    "VirtualName": {}
                  }
                }
              },
              "EbsOptimized": {
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "type": "structure",
                "members": {
                  "Arn": {},
                  "Name": {}
                }
              },
              "ImageId": {},
              "InstanceType": {},
              "KernelId": {},
              "KeyName": {},
              "Monitoring": {
                "type": "structure",
                "members": {
                  "Enabled": {
                    "type": "boolean"
                  }
                }
              },
              "NetworkInterfaces": {
                "locationName": "NetworkInterface",
                "type": "list",
                "member": {
                  "locationName": "NetworkInterface",
                  "type": "structure",
                  "members": {
                    "AssociatePublicIpAddress": {
                      "type": "boolean"
                    },
                    "DeleteOnTermination": {
                      "type": "boolean"
                    },
                    "Description": {},
                    "DeviceIndex": {
                      "type": "integer"
                    },
                    "Groups": {
                      "shape": "S16l",
                      "locationName": "Group"
                    },
                    "Ipv6AddressCount": {
                      "type": "integer"
                    },
                    "Ipv6Addresses": {
                      "locationName": "Ipv6Address",
                      "type": "list",
                      "member": {
                        "locationName": "Ipv6Address",
                        "type": "structure",
                        "members": {
                          "Ipv6Address": {}
                        }
                      }
                    },
                    "NetworkInterfaceId": {},
                    "PrivateIpAddress": {},
                    "PrivateIpAddressConfigs": {
                      "locationName": "PrivateIpAddressConfig",
                      "type": "list",
                      "member": {
                        "locationName": "PrivateIpAddressConfigSet",
                        "type": "structure",
                        "members": {
                          "Primary": {
                            "type": "boolean"
                          },
                          "PrivateIpAddress": {}
                        }
                      }
                    },
                    "SecondaryPrivateIpAddressCount": {
                      "type": "integer"
                    },
                    "SubnetId": {}
                  }
                }
              },
              "Placement": {
                "type": "structure",
                "members": {
                  "AvailabilityZone": {},
                  "GroupName": {}
                }
              },
              "RamdiskId": {},
              "SecurityGroupIds": {
                "shape": "S16l",
                "locationName": "SecurityGroupId"
              },
              "SubnetId": {},
              "UserData": {}
            }
          },
          "ScheduledInstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceIdSet": {
            "locationName": "instanceIdSet",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          }
        }
      }
    },
    "SearchTransitGatewayRoutes": {
      "input": {
        "type": "structure",
        "required": [
          "TransitGatewayRouteTableId",
          "Filters"
        ],
        "members": {
          "TransitGatewayRouteTableId": {},
          "Filters": {
            "shape": "Sh6",
            "locationName": "Filter"
          },
          "MaxResults": {
            "type": "integer"
          },
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Routes": {
            "locationName": "routeSet",
            "type": "list",
            "member": {
              "shape": "Scz",
              "locationName": "item"
            }
          },
          "AdditionalRoutesAvailable": {
            "locationName": "additionalRoutesAvailable",
            "type": "boolean"
          }
        }
      }
    },
    "SendDiagnosticInterrupt": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      }
    },
    "StartInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "AdditionalInfo": {
            "locationName": "additionalInfo"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StartingInstances": {
            "shape": "S170",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "StopInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "Hibernate": {
            "type": "boolean"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          },
          "Force": {
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StoppingInstances": {
            "shape": "S170",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "TerminateClientVpnConnections": {
      "input": {
        "type": "structure",
        "required": [
          "ClientVpnEndpointId"
        ],
        "members": {
          "ClientVpnEndpointId": {},
          "ConnectionId": {},
          "Username": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClientVpnEndpointId": {
            "locationName": "clientVpnEndpointId"
          },
          "Username": {
            "locationName": "username"
          },
          "ConnectionStatuses": {
            "locationName": "connectionStatuses",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "ConnectionId": {
                  "locationName": "connectionId"
                },
                "PreviousStatus": {
                  "shape": "Sik",
                  "locationName": "previousStatus"
                },
                "CurrentStatus": {
                  "shape": "Sik",
                  "locationName": "currentStatus"
                }
              }
            }
          }
        }
      }
    },
    "TerminateInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TerminatingInstances": {
            "shape": "S170",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "UnassignIpv6Addresses": {
      "input": {
        "type": "structure",
        "required": [
          "Ipv6Addresses",
          "NetworkInterfaceId"
        ],
        "members": {
          "Ipv6Addresses": {
            "shape": "S1j",
            "locationName": "ipv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "UnassignedIpv6Addresses": {
            "shape": "S1j",
            "locationName": "unassignedIpv6Addresses"
          }
        }
      }
    },
    "UnassignPrivateIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "NetworkInterfaceId",
          "PrivateIpAddresses"
        ],
        "members": {
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddresses": {
            "shape": "S1m",
            "locationName": "privateIpAddress"
          }
        }
      }
    },
    "UnmonitorInstances": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceIds"
        ],
        "members": {
          "InstanceIds": {
            "shape": "Si5",
            "locationName": "InstanceId"
          },
          "DryRun": {
            "locationName": "dryRun",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceMonitorings": {
            "shape": "S147",
            "locationName": "instancesSet"
          }
        }
      }
    },
    "UpdateSecurityGroupRuleDescriptionsEgress": {
      "input": {
        "type": "structure",
        "required": [
          "IpPermissions"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S33"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "UpdateSecurityGroupRuleDescriptionsIngress": {
      "input": {
        "type": "structure",
        "required": [
          "IpPermissions"
        ],
        "members": {
          "DryRun": {
            "type": "boolean"
          },
          "GroupId": {},
          "GroupName": {},
          "IpPermissions": {
            "shape": "S33"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Return": {
            "locationName": "return",
            "type": "boolean"
          }
        }
      }
    },
    "WithdrawByoipCidr": {
      "input": {
        "type": "structure",
        "required": [
          "Cidr"
        ],
        "members": {
          "Cidr": {},
          "DryRun": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ByoipCidr": {
            "shape": "S12",
            "locationName": "byoipCidr"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstanceId"
      }
    },
    "S5": {
      "type": "list",
      "member": {
        "locationName": "TargetConfigurationRequest",
        "type": "structure",
        "required": [
          "OfferingId"
        ],
        "members": {
          "InstanceCount": {
            "type": "integer"
          },
          "OfferingId": {}
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "VpcOwnerId": {
          "locationName": "vpcOwnerId"
        },
        "State": {
          "locationName": "state"
        },
        "SubnetIds": {
          "shape": "Sd",
          "locationName": "subnetIds"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "DnsSupport": {
              "locationName": "dnsSupport"
            },
            "Ipv6Support": {
              "locationName": "ipv6Support"
            }
          }
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sd": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Key": {
            "locationName": "key"
          },
          "Value": {
            "locationName": "value"
          }
        }
      }
    },
    "Sm": {
      "type": "list",
      "member": {
        "shape": "Sn",
        "locationName": "item"
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "Error": {
          "locationName": "error",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "ResourceId": {
          "locationName": "resourceId"
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "AccepterVpcInfo": {
          "shape": "Ss",
          "locationName": "accepterVpcInfo"
        },
        "ExpirationTime": {
          "locationName": "expirationTime",
          "type": "timestamp"
        },
        "RequesterVpcInfo": {
          "shape": "Ss",
          "locationName": "requesterVpcInfo"
        },
        "Status": {
          "locationName": "status",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        }
      }
    },
    "Ss": {
      "type": "structure",
      "members": {
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "Ipv6CidrBlockSet": {
          "locationName": "ipv6CidrBlockSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              }
            }
          }
        },
        "CidrBlockSet": {
          "locationName": "cidrBlockSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "CidrBlock": {
                "locationName": "cidrBlock"
              }
            }
          }
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PeeringOptions": {
          "locationName": "peeringOptions",
          "type": "structure",
          "members": {
            "AllowDnsResolutionFromRemoteVpc": {
              "locationName": "allowDnsResolutionFromRemoteVpc",
              "type": "boolean"
            },
            "AllowEgressFromLocalClassicLinkToRemoteVpc": {
              "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
              "type": "boolean"
            },
            "AllowEgressFromLocalVpcToRemoteClassicLink": {
              "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
              "type": "boolean"
            }
          }
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Region": {
          "locationName": "region"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "Cidr": {
          "locationName": "cidr"
        },
        "Description": {
          "locationName": "description"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ResourceType": {
            "locationName": "resourceType"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "Tag"
          }
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1g": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S1m": {
      "type": "list",
      "member": {
        "locationName": "PrivateIpAddress"
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "IamInstanceProfile": {
          "shape": "S21",
          "locationName": "iamInstanceProfile"
        },
        "State": {
          "locationName": "state"
        },
        "Timestamp": {
          "locationName": "timestamp",
          "type": "timestamp"
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Id": {
          "locationName": "id"
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "locationName": "ipv6CidrBlockState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        }
      }
    },
    "S2c": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "Ipv6CidrBlock": {
          "locationName": "ipv6CidrBlock"
        },
        "Ipv6CidrBlockState": {
          "shape": "S2i",
          "locationName": "ipv6CidrBlockState"
        }
      }
    },
    "S2i": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "AssociationId": {
          "locationName": "associationId"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "CidrBlockState": {
          "shape": "S2i",
          "locationName": "cidrBlockState"
        }
      }
    },
    "S2m": {
      "type": "list",
      "member": {
        "locationName": "groupId"
      }
    },
    "S2s": {
      "type": "structure",
      "members": {
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "Device": {
          "locationName": "device"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "State": {
          "locationName": "status"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        }
      }
    },
    "S2w": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        },
        "VpcId": {
          "locationName": "vpcId"
        }
      }
    },
    "S30": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S33": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIp": {
                  "locationName": "cidrIp"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "Ipv6Ranges": {
            "locationName": "ipv6Ranges",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "CidrIpv6": {
                  "locationName": "cidrIpv6"
                },
                "Description": {
                  "locationName": "description"
                }
              }
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Description": {
                  "locationName": "description"
                },
                "PrefixListId": {
                  "locationName": "prefixListId"
                }
              }
            }
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S3c",
              "locationName": "item"
            }
          }
        }
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "GroupId": {
          "locationName": "groupId"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "PeeringStatus": {
          "locationName": "peeringStatus"
        },
        "UserId": {
          "locationName": "userId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "VpcPeeringConnectionId": {
          "locationName": "vpcPeeringConnectionId"
        }
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "S3": {
          "type": "structure",
          "members": {
            "AWSAccessKeyId": {},
            "Bucket": {
              "locationName": "bucket"
            },
            "Prefix": {
              "locationName": "prefix"
            },
            "UploadPolicy": {
              "locationName": "uploadPolicy",
              "type": "blob"
            },
            "UploadPolicySignature": {
              "locationName": "uploadPolicySignature"
            }
          }
        }
      }
    },
    "S3j": {
      "type": "structure",
      "members": {
        "BundleId": {
          "locationName": "bundleId"
        },
        "BundleTaskError": {
          "locationName": "error",
          "type": "structure",
          "members": {
            "Code": {
              "locationName": "code"
            },
            "Message": {
              "locationName": "message"
            }
          }
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "State": {
          "locationName": "state"
        },
        "Storage": {
          "shape": "S3f",
          "locationName": "storage"
        },
        "UpdateTime": {
          "locationName": "updateTime",
          "type": "timestamp"
        }
      }
    },
    "S3w": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ClientToken": {
            "locationName": "clientToken"
          },
          "CreateDate": {
            "locationName": "createDate",
            "type": "timestamp"
          },
          "InstanceCounts": {
            "locationName": "instanceCounts",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "InstanceCount": {
                  "locationName": "instanceCount",
                  "type": "integer"
                },
                "State": {
                  "locationName": "state"
                }
              }
            }
          },
          "PriceSchedules": {
            "locationName": "priceSchedules",
            "type": "list",
            "member": {
              "locationName": "item",
              "type": "structure",
              "members": {
                "Active": {
                  "locationName": "active",
                  "type": "boolean"
                },
                "CurrencyCode": {
                  "locationName": "currencyCode"
                },
                "Price": {
                  "locationName": "price",
                  "type": "double"
                },
                "Term": {
                  "locationName": "term",
                  "type": "long"
                }
              }
            }
          },
          "ReservedInstancesId": {
            "locationName": "reservedInstancesId"
          },
          "ReservedInstancesListingId": {
            "locationName": "reservedInstancesListingId"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Tags": {
            "shape": "Si",
            "locationName": "tagSet"
          },
          "UpdateDate": {
            "locationName": "updateDate",
            "type": "timestamp"
          }
        }
      }
    },
    "S4h": {
      "type": "list",
      "member": {
        "locationName": "SpotInstanceRequestId"
      }
    },
    "S50": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {
          "locationName": "capacityReservationId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "CapacityReservationArn": {
          "locationName": "capacityReservationArn"
        },
        "AvailabilityZoneId": {
          "locationName": "availabilityZoneId"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "InstancePlatform": {
          "locationName": "instancePlatform"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Tenancy": {
          "locationName": "tenancy"
        },
        "TotalInstanceCount": {
          "locationName": "totalInstanceCount",
          "type": "integer"
        },
        "AvailableInstanceCount": {
          "locationName": "availableInstanceCount",
          "type": "integer"
        },
        "EbsOptimized": {
          "locationName": "ebsOptimized",
          "type": "boolean"
        },
        "EphemeralStorage": {
          "locationName": "ephemeralStorage",
          "type": "boolean"
        },
        "State": {
          "locationName": "state"
        },
        "EndDate": {
          "locationName": "endDate",
          "type": "timestamp"
        },
        "EndDateType": {
          "locationName": "endDateType"
        },
        "InstanceMatchCriteria": {
          "locationName": "instanceMatchCriteria"
        },
        "CreateDate": {
          "locationName": "createDate",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "S58": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "CloudwatchLogGroup": {},
        "CloudwatchLogStream": {}
      }
    },
    "S5b": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S5f": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "S5k": {
      "type": "structure",
      "members": {
        "BgpAsn": {
          "locationName": "bgpAsn"
        },
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "IpAddress": {
          "locationName": "ipAddress"
        },
        "CertificateArn": {
          "locationName": "certificateArn"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "S5n": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "AvailabilityZoneId": {
          "locationName": "availabilityZoneId"
        },
        "AvailableIpAddressCount": {
          "locationName": "availableIpAddressCount",
          "type": "integer"
        },
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "DefaultForAz": {
          "locationName": "defaultForAz",
          "type": "boolean"
        },
        "MapPublicIpOnLaunch": {
          "locationName": "mapPublicIpOnLaunch",
          "type": "boolean"
        },
        "State": {
          "locationName": "state"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "AssignIpv6AddressOnCreation": {
          "locationName": "assignIpv6AddressOnCreation",
          "type": "boolean"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S27",
            "locationName": "item"
          }
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "SubnetArn": {
          "locationName": "subnetArn"
        }
      }
    },
    "S5s": {
      "type": "structure",
      "members": {
        "CidrBlock": {
          "locationName": "cidrBlock"
        },
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "State": {
          "locationName": "state"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "InstanceTenancy": {
          "locationName": "instanceTenancy"
        },
        "Ipv6CidrBlockAssociationSet": {
          "locationName": "ipv6CidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S2h",
            "locationName": "item"
          }
        },
        "CidrBlockAssociationSet": {
          "locationName": "cidrBlockAssociationSet",
          "type": "list",
          "member": {
            "shape": "S2k",
            "locationName": "item"
          }
        },
        "IsDefault": {
          "locationName": "isDefault",
          "type": "boolean"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "S61": {
      "type": "structure",
      "members": {
        "DhcpConfigurations": {
          "locationName": "dhcpConfigurationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Key": {
                "locationName": "key"
              },
              "Values": {
                "locationName": "valueSet",
                "type": "list",
                "member": {
                  "shape": "S65",
                  "locationName": "item"
                }
              }
            }
          }
        },
        "DhcpOptionsId": {
          "locationName": "dhcpOptionsId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "S65": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value"
        }
      }
    },
    "S68": {
      "type": "structure",
      "members": {
        "Attachments": {
          "shape": "S69",
          "locationName": "attachmentSet"
        },
        "EgressOnlyInternetGatewayId": {
          "locationName": "egressOnlyInternetGatewayId"
        }
      }
    },
    "S69": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "State": {
            "locationName": "state"
          },
          "VpcId": {
            "locationName": "vpcId"
          }
        }
      }
    },
    "S6q": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Affinity": {
          "locationName": "affinity"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "PartitionNumber": {
          "locationName": "partitionNumber",
          "type": "integer"
        },
        "HostId": {
          "locationName": "hostId"
        },
        "Tenancy": {
          "locationName": "tenancy"
        },
        "SpreadDomain": {
          "locationName": "spreadDomain"
        }
      }
    },
    "S6r": {
      "type": "structure",
      "required": [
        "TotalTargetCapacity"
      ],
      "members": {
        "TotalTargetCapacity": {
          "type": "integer"
        },
        "OnDemandTargetCapacity": {
          "type": "integer"
        },
        "SpotTargetCapacity": {
          "type": "integer"
        },
        "DefaultTargetCapacityType": {}
      }
    },
    "S6y": {
      "type": "structure",
      "members": {
        "LaunchTemplateSpecification": {
          "shape": "S6z",
          "locationName": "launchTemplateSpecification"
        },
        "Overrides": {
          "shape": "S70",
          "locationName": "overrides"
        }
      }
    },
    "S6z": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "Version": {
          "locationName": "version"
        }
      }
    },
    "S70": {
      "type": "structure",
      "members": {
        "InstanceType": {
          "locationName": "instanceType"
        },
        "MaxPrice": {
          "locationName": "maxPrice"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "WeightedCapacity": {
          "locationName": "weightedCapacity",
          "type": "double"
        },
        "Priority": {
          "locationName": "priority",
          "type": "double"
        },
        "Placement": {
          "locationName": "placement",
          "type": "structure",
          "members": {
            "GroupName": {
              "locationName": "groupName"
            }
          }
        }
      }
    },
    "S75": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S7e": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Key": {}
      }
    },
    "S7h": {
      "type": "list",
      "member": {
        "shape": "S7i",
        "locationName": "BlockDeviceMapping"
      }
    },
    "S7i": {
      "type": "structure",
      "members": {
        "DeviceName": {
          "locationName": "deviceName"
        },
        "VirtualName": {
          "locationName": "virtualName"
        },
        "Ebs": {
          "locationName": "ebs",
          "type": "structure",
          "members": {
            "DeleteOnTermination": {
              "locationName": "deleteOnTermination",
              "type": "boolean"
            },
            "Iops": {
              "locationName": "iops",
              "type": "integer"
            },
            "SnapshotId": {
              "locationName": "snapshotId"
            },
            "VolumeSize": {
              "locationName": "volumeSize",
              "type": "integer"
            },
            "VolumeType": {
              "locationName": "volumeType"
            },
            "Encrypted": {
              "locationName": "encrypted",
              "type": "boolean"
            },
            "KmsKeyId": {}
          }
        },
        "NoDevice": {
          "locationName": "noDevice"
        }
      }
    },
    "S7s": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "ExportTaskId": {
          "locationName": "exportTaskId"
        },
        "ExportToS3Task": {
          "locationName": "exportToS3",
          "type": "structure",
          "members": {
            "ContainerFormat": {
              "locationName": "containerFormat"
            },
            "DiskImageFormat": {
              "locationName": "diskImageFormat"
            },
            "S3Bucket": {
              "locationName": "s3Bucket"
            },
            "S3Key": {
              "locationName": "s3Key"
            }
          }
        },
        "InstanceExportDetails": {
          "locationName": "instanceExport",
          "type": "structure",
          "members": {
            "InstanceId": {
              "locationName": "instanceId"
            },
            "TargetEnvironment": {
              "locationName": "targetEnvironment"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        }
      }
    },
    "S7y": {
      "type": "structure",
      "members": {
        "Attachments": {
          "shape": "S69",
          "locationName": "attachmentSet"
        },
        "InternetGatewayId": {
          "locationName": "internetGatewayId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "S83": {
      "type": "structure",
      "members": {
        "KernelId": {},
        "EbsOptimized": {
          "type": "boolean"
        },
        "IamInstanceProfile": {
          "type": "structure",
          "members": {
            "Arn": {},
            "Name": {}
          }
        },
        "BlockDeviceMappings": {
          "locationName": "BlockDeviceMapping",
          "type": "list",
          "member": {
            "locationName": "BlockDeviceMapping",
            "type": "structure",
            "members": {
              "DeviceName": {},
              "VirtualName": {},
              "Ebs": {
                "type": "structure",
                "members": {
                  "Encrypted": {
                    "type": "boolean"
                  },
                  "DeleteOnTermination": {
                    "type": "boolean"
                  },
                  "Iops": {
                    "type": "integer"
                  },
                  "KmsKeyId": {},
                  "SnapshotId": {},
                  "VolumeSize": {
                    "type": "integer"
                  },
                  "VolumeType": {}
                }
              },
              "NoDevice": {}
            }
          }
        },
        "NetworkInterfaces": {
          "locationName": "NetworkInterface",
          "type": "list",
          "member": {
            "locationName": "InstanceNetworkInterfaceSpecification",
            "type": "structure",
            "members": {
              "AssociatePublicIpAddress": {
                "type": "boolean"
              },
              "DeleteOnTermination": {
                "type": "boolean"
              },
              "Description": {},
              "DeviceIndex": {
                "type": "integer"
              },
              "Groups": {
                "shape": "S8a",
                "locationName": "SecurityGroupId"
              },
              "InterfaceType": {},
              "Ipv6AddressCount": {
                "type": "integer"
              },
              "Ipv6Addresses": {
                "type": "list",
                "member": {
                  "locationName": "InstanceIpv6Address",
                  "type": "structure",
                  "members": {
                    "Ipv6Address": {}
                  }
                }
              },
              "NetworkInterfaceId": {},
              "PrivateIpAddress": {},
              "PrivateIpAddresses": {
                "shape": "S8d"
              },
              "SecondaryPrivateIpAddressCount": {
                "type": "integer"
              },
              "SubnetId": {}
            }
          }
        },
        "ImageId": {},
        "InstanceType": {},
        "KeyName": {},
        "Monitoring": {
          "type": "structure",
          "members": {
            "Enabled": {
              "type": "boolean"
            }
          }
        },
        "Placement": {
          "type": "structure",
          "members": {
            "AvailabilityZone": {},
            "Affinity": {},
            "GroupName": {},
            "HostId": {},
            "Tenancy": {},
            "SpreadDomain": {}
          }
        },
        "RamDiskId": {},
        "DisableApiTermination": {
          "type": "boolean"
        },
        "InstanceInitiatedShutdownBehavior": {},
        "UserData": {},
        "TagSpecifications": {
          "locationName": "TagSpecification",
          "type": "list",
          "member": {
            "locationName": "LaunchTemplateTagSpecificationRequest",
            "type": "structure",
            "members": {
              "ResourceType": {},
              "Tags": {
                "shape": "Si",
                "locationName": "Tag"
              }
            }
          }
        },
        "ElasticGpuSpecifications": {
          "locationName": "ElasticGpuSpecification",
          "type": "list",
          "member": {
            "shape": "S8l",
            "locationName": "ElasticGpuSpecification"
          }
        },
        "ElasticInferenceAccelerators": {
          "locationName": "ElasticInferenceAccelerator",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {}
            }
          }
        },
        "SecurityGroupIds": {
          "shape": "S8a",
          "locationName": "SecurityGroupId"
        },
        "SecurityGroups": {
          "shape": "S8o",
          "locationName": "SecurityGroup"
        },
        "InstanceMarketOptions": {
          "type": "structure",
          "members": {
            "MarketType": {},
            "SpotOptions": {
              "type": "structure",
              "members": {
                "MaxPrice": {},
                "SpotInstanceType": {},
                "BlockDurationMinutes": {
                  "type": "integer"
                },
                "ValidUntil": {
                  "type": "timestamp"
                },
                "InstanceInterruptionBehavior": {}
              }
            }
          }
        },
        "CreditSpecification": {
          "shape": "S8u"
        },
        "CpuOptions": {
          "type": "structure",
          "members": {
            "CoreCount": {
              "type": "integer"
            },
            "ThreadsPerCore": {
              "type": "integer"
            }
          }
        },
        "CapacityReservationSpecification": {
          "type": "structure",
          "members": {
            "CapacityReservationPreference": {},
            "CapacityReservationTarget": {
              "shape": "S8y"
            }
          }
        },
        "LicenseSpecifications": {
          "locationName": "LicenseSpecification",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LicenseConfigurationArn": {}
            }
          }
        },
        "HibernationOptions": {
          "type": "structure",
          "members": {
            "Configured": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S8a": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S8d": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Primary": {
            "locationName": "primary",
            "type": "boolean"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          }
        }
      }
    },
    "S8l": {
      "type": "structure",
      "required": [
        "Type"
      ],
      "members": {
        "Type": {}
      }
    },
    "S8o": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroup"
      }
    },
    "S8u": {
      "type": "structure",
      "required": [
        "CpuCredits"
      ],
      "members": {
        "CpuCredits": {}
      }
    },
    "S8y": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {}
      }
    },
    "S93": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "CreatedBy": {
          "locationName": "createdBy"
        },
        "DefaultVersionNumber": {
          "locationName": "defaultVersionNumber",
          "type": "long"
        },
        "LatestVersionNumber": {
          "locationName": "latestVersionNumber",
          "type": "long"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "S96": {
      "type": "structure",
      "members": {
        "LaunchTemplateId": {
          "locationName": "launchTemplateId"
        },
        "LaunchTemplateName": {
          "locationName": "launchTemplateName"
        },
        "VersionNumber": {
          "locationName": "versionNumber",
          "type": "long"
        },
        "VersionDescription": {
          "locationName": "versionDescription"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "CreatedBy": {
          "locationName": "createdBy"
        },
        "DefaultVersion": {
          "locationName": "defaultVersion",
          "type": "boolean"
        },
        "LaunchTemplateData": {
          "shape": "S97",
          "locationName": "launchTemplateData"
        }
      }
    },
    "S97": {
      "type": "structure",
      "members": {
        "KernelId": {
          "locationName": "kernelId"
        },
        "EbsOptimized": {
          "locationName": "ebsOptimized",
          "type": "boolean"
        },
        "IamInstanceProfile": {
          "locationName": "iamInstanceProfile",
          "type": "structure",
          "members": {
            "Arn": {
              "locationName": "arn"
            },
            "Name": {
              "locationName": "name"
            }
          }
        },
        "BlockDeviceMappings": {
          "locationName": "blockDeviceMappingSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DeviceName": {
                "locationName": "deviceName"
              },
              "VirtualName": {
                "locationName": "virtualName"
              },
              "Ebs": {
                "locationName": "ebs",
                "type": "structure",
                "members": {
                  "Encrypted": {
                    "locationName": "encrypted",
                    "type": "boolean"
                  },
                  "DeleteOnTermination": {
                    "locationName": "deleteOnTermination",
                    "type": "boolean"
                  },
                  "Iops": {
                    "locationName": "iops",
                    "type": "integer"
                  },
                  "KmsKeyId": {
                    "locationName": "kmsKeyId"
                  },
                  "SnapshotId": {
                    "locationName": "snapshotId"
                  },
                  "VolumeSize": {
                    "locationName": "volumeSize",
                    "type": "integer"
                  },
                  "VolumeType": {
                    "locationName": "volumeType"
                  }
                }
              },
              "NoDevice": {
                "locationName": "noDevice"
              }
            }
          }
        },
        "NetworkInterfaces": {
          "locationName": "networkInterfaceSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AssociatePublicIpAddress": {
                "locationName": "associatePublicIpAddress",
                "type": "boolean"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              },
              "Description": {
                "locationName": "description"
              },
              "DeviceIndex": {
                "locationName": "deviceIndex",
                "type": "integer"
              },
              "Groups": {
                "shape": "S2m",
                "locationName": "groupSet"
              },
              "InterfaceType": {
                "locationName": "interfaceType"
              },
              "Ipv6AddressCount": {
                "locationName": "ipv6AddressCount",
                "type": "integer"
              },
              "Ipv6Addresses": {
                "shape": "S9e",
                "locationName": "ipv6AddressesSet"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "PrivateIpAddresses": {
                "shape": "S8d",
                "locationName": "privateIpAddressesSet"
              },
              "SecondaryPrivateIpAddressCount": {
                "locationName": "secondaryPrivateIpAddressCount",
                "type": "integer"
              },
              "SubnetId": {
                "locationName": "subnetId"
              }
            }
          }
        },
        "ImageId": {
          "locationName": "imageId"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "KeyName": {
          "locationName": "keyName"
        },
        "Monitoring": {
          "locationName": "monitoring",
          "type": "structure",
          "members": {
            "Enabled": {
              "locationName": "enabled",
              "type": "boolean"
            }
          }
        },
        "Placement": {
          "locationName": "placement",
          "type": "structure",
          "members": {
            "AvailabilityZone": {
              "locationName": "availabilityZone"
            },
            "Affinity": {
              "locationName": "affinity"
            },
            "GroupName": {
              "locationName": "groupName"
            },
            "HostId": {
              "locationName": "hostId"
            },
            "Tenancy": {
              "locationName": "tenancy"
            },
            "SpreadDomain": {
              "locationName": "spreadDomain"
            }
          }
        },
        "RamDiskId": {
          "locationName": "ramDiskId"
        },
        "DisableApiTermination": {
          "locationName": "disableApiTermination",
          "type": "boolean"
        },
        "InstanceInitiatedShutdownBehavior": {
          "locationName": "instanceInitiatedShutdownBehavior"
        },
        "UserData": {
          "locationName": "userData"
        },
        "TagSpecifications": {
          "locationName": "tagSpecificationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ResourceType": {
                "locationName": "resourceType"
              },
              "Tags": {
                "shape": "Si",
                "locationName": "tagSet"
              }
            }
          }
        },
        "ElasticGpuSpecifications": {
          "locationName": "elasticGpuSpecificationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Type": {
                "locationName": "type"
              }
            }
          }
        },
        "ElasticInferenceAccelerators": {
          "locationName": "elasticInferenceAcceleratorSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Type": {
                "locationName": "type"
              }
            }
          }
        },
        "SecurityGroupIds": {
          "shape": "Sd",
          "locationName": "securityGroupIdSet"
        },
        "SecurityGroups": {
          "shape": "Sd",
          "locationName": "securityGroupSet"
        },
        "InstanceMarketOptions": {
          "locationName": "instanceMarketOptions",
          "type": "structure",
          "members": {
            "MarketType": {
              "locationName": "marketType"
            },
            "SpotOptions": {
              "locationName": "spotOptions",
              "type": "structure",
              "members": {
                "MaxPrice": {
                  "locationName": "maxPrice"
                },
                "SpotInstanceType": {
                  "locationName": "spotInstanceType"
                },
                "BlockDurationMinutes": {
                  "locationName": "blockDurationMinutes",
                  "type": "integer"
                },
                "ValidUntil": {
                  "locationName": "validUntil",
                  "type": "timestamp"
                },
                "InstanceInterruptionBehavior": {
                  "locationName": "instanceInterruptionBehavior"
                }
              }
            }
          }
        },
        "CreditSpecification": {
          "locationName": "creditSpecification",
          "type": "structure",
          "members": {
            "CpuCredits": {
              "locationName": "cpuCredits"
            }
          }
        },
        "CpuOptions": {
          "locationName": "cpuOptions",
          "type": "structure",
          "members": {
            "CoreCount": {
              "locationName": "coreCount",
              "type": "integer"
            },
            "ThreadsPerCore": {
              "locationName": "threadsPerCore",
              "type": "integer"
            }
          }
        },
        "CapacityReservationSpecification": {
          "locationName": "capacityReservationSpecification",
          "type": "structure",
          "members": {
            "CapacityReservationPreference": {
              "locationName": "capacityReservationPreference"
            },
            "CapacityReservationTarget": {
              "shape": "S9t",
              "locationName": "capacityReservationTarget"
            }
          }
        },
        "LicenseSpecifications": {
          "locationName": "licenseSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LicenseConfigurationArn": {
                "locationName": "licenseConfigurationArn"
              }
            }
          }
        },
        "HibernationOptions": {
          "locationName": "hibernationOptions",
          "type": "structure",
          "members": {
            "Configured": {
              "locationName": "configured",
              "type": "boolean"
            }
          }
        }
      }
    },
    "S9e": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Ipv6Address": {
            "locationName": "ipv6Address"
          }
        }
      }
    },
    "S9t": {
      "type": "structure",
      "members": {
        "CapacityReservationId": {
          "locationName": "capacityReservationId"
        }
      }
    },
    "S9z": {
      "type": "structure",
      "members": {
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "DeleteTime": {
          "locationName": "deleteTime",
          "type": "timestamp"
        },
        "FailureCode": {
          "locationName": "failureCode"
        },
        "FailureMessage": {
          "locationName": "failureMessage"
        },
        "NatGatewayAddresses": {
          "locationName": "natGatewayAddressSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AllocationId": {
                "locationName": "allocationId"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "PrivateIp": {
                "locationName": "privateIp"
              },
              "PublicIp": {
                "locationName": "publicIp"
              }
            }
          }
        },
        "NatGatewayId": {
          "locationName": "natGatewayId"
        },
        "ProvisionedBandwidth": {
          "locationName": "provisionedBandwidth",
          "type": "structure",
          "members": {
            "ProvisionTime": {
              "locationName": "provisionTime",
              "type": "timestamp"
            },
            "Provisioned": {
              "locationName": "provisioned"
            },
            "RequestTime": {
              "locationName": "requestTime",
              "type": "timestamp"
            },
            "Requested": {
              "locationName": "requested"
            },
            "Status": {
              "locationName": "status"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sa6": {
      "type": "structure",
      "members": {
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "NetworkAclAssociationId": {
                "locationName": "networkAclAssociationId"
              },
              "NetworkAclId": {
                "locationName": "networkAclId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              }
            }
          }
        },
        "Entries": {
          "locationName": "entrySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "CidrBlock": {
                "locationName": "cidrBlock"
              },
              "Egress": {
                "locationName": "egress",
                "type": "boolean"
              },
              "IcmpTypeCode": {
                "shape": "Sab",
                "locationName": "icmpTypeCode"
              },
              "Ipv6CidrBlock": {
                "locationName": "ipv6CidrBlock"
              },
              "PortRange": {
                "shape": "Sac",
                "locationName": "portRange"
              },
              "Protocol": {
                "locationName": "protocol"
              },
              "RuleAction": {
                "locationName": "ruleAction"
              },
              "RuleNumber": {
                "locationName": "ruleNumber",
                "type": "integer"
              }
            }
          }
        },
        "IsDefault": {
          "locationName": "default",
          "type": "boolean"
        },
        "NetworkAclId": {
          "locationName": "networkAclId"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Sab": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code",
          "type": "integer"
        },
        "Type": {
          "locationName": "type",
          "type": "integer"
        }
      }
    },
    "Sac": {
      "type": "structure",
      "members": {
        "From": {
          "locationName": "from",
          "type": "integer"
        },
        "To": {
          "locationName": "to",
          "type": "integer"
        }
      }
    },
    "Sai": {
      "type": "structure",
      "members": {
        "Association": {
          "shape": "Saj",
          "locationName": "association"
        },
        "Attachment": {
          "shape": "Sak",
          "locationName": "attachment"
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Description": {
          "locationName": "description"
        },
        "Groups": {
          "shape": "Sal",
          "locationName": "groupSet"
        },
        "InterfaceType": {
          "locationName": "interfaceType"
        },
        "Ipv6Addresses": {
          "locationName": "ipv6AddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Ipv6Address": {
                "locationName": "ipv6Address"
              }
            }
          }
        },
        "MacAddress": {
          "locationName": "macAddress"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PrivateDnsName": {
          "locationName": "privateDnsName"
        },
        "PrivateIpAddress": {
          "locationName": "privateIpAddress"
        },
        "PrivateIpAddresses": {
          "locationName": "privateIpAddressesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Association": {
                "shape": "Saj",
                "locationName": "association"
              },
              "Primary": {
                "locationName": "primary",
                "type": "boolean"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              }
            }
          }
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "RequesterManaged": {
          "locationName": "requesterManaged",
          "type": "boolean"
        },
        "SourceDestCheck": {
          "locationName": "sourceDestCheck",
          "type": "boolean"
        },
        "Status": {
          "locationName": "status"
        },
        "SubnetId": {
          "locationName": "subnetId"
        },
        "TagSet": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        }
      }
    },
    "Saj": {
      "type": "structure",
      "members": {
        "AllocationId": {
          "locationName": "allocationId"
        },
        "AssociationId": {
          "locationName": "associationId"
        },
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "PublicIp": {
          "locationName": "publicIp"
        }
      }
    },
    "Sak": {
      "type": "structure",
      "members": {
        "AttachTime": {
          "locationName": "attachTime",
          "type": "timestamp"
        },
        "AttachmentId": {
          "locationName": "attachmentId"
        },
        "DeleteOnTermination": {
          "locationName": "deleteOnTermination",
          "type": "boolean"
        },
        "DeviceIndex": {
          "locationName": "deviceIndex",
          "type": "integer"
        },
        "InstanceId": {
          "locationName": "instanceId"
        },
        "InstanceOwnerId": {
          "locationName": "instanceOwnerId"
        },
        "Status": {
          "locationName": "status"
        }
      }
    },
    "Sal": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "GroupName": {
            "locationName": "groupName"
          },
          "GroupId": {
            "locationName": "groupId"
          }
        }
      }
    },
    "Saw": {
      "type": "structure",
      "members": {
        "NetworkInterfacePermissionId": {
          "locationName": "networkInterfacePermissionId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "AwsAccountId": {
          "locationName": "awsAccountId"
        },
        "AwsService": {
          "locationName": "awsService"
        },
        "Permission": {
          "locationName": "permission"
        },
        "PermissionState": {
          "locationName": "permissionState",
          "type": "structure",
          "members": {
            "State": {
              "locationName": "state"
            },
            "StatusMessage": {
              "locationName": "statusMessage"
            }
          }
        }
      }
    },
    "Sb9": {
      "type": "structure",
      "members": {
        "Associations": {
          "locationName": "associationSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "Main": {
                "locationName": "main",
                "type": "boolean"
              },
              "RouteTableAssociationId": {
                "locationName": "routeTableAssociationId"
              },
              "RouteTableId": {
                "locationName": "routeTableId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              }
            }
          }
        },
        "PropagatingVgws": {
          "locationName": "propagatingVgwSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "GatewayId": {
                "locationName": "gatewayId"
              }
            }
          }
        },
        "RouteTableId": {
          "locationName": "routeTableId"
        },
        "Routes": {
          "locationName": "routeSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "DestinationIpv6CidrBlock": {
                "locationName": "destinationIpv6CidrBlock"
              },
              "DestinationPrefixListId": {
                "locationName": "destinationPrefixListId"
              },
              "EgressOnlyInternetGatewayId": {
                "locationName": "egressOnlyInternetGatewayId"
              },
              "GatewayId": {
                "locationName": "gatewayId"
              },
              "InstanceId": {
                "locationName": "instanceId"
              },
              "InstanceOwnerId": {
                "locationName": "instanceOwnerId"
              },
              "NatGatewayId": {
                "locationName": "natGatewayId"
              },
              "TransitGatewayId": {
                "locationName": "transitGatewayId"
              },
              "NetworkInterfaceId": {
                "locationName": "networkInterfaceId"
              },
              "Origin": {
                "locationName": "origin"
              },
              "State": {
                "locationName": "state"
              },
              "VpcPeeringConnectionId": {
                "locationName": "vpcPeeringConnectionId"
              }
            }
          }
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Sbl": {
      "type": "structure",
      "members": {
        "DataEncryptionKeyId": {
          "locationName": "dataEncryptionKeyId"
        },
        "Description": {
          "locationName": "description"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "State": {
          "locationName": "status"
        },
        "StateMessage": {
          "locationName": "statusMessage"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "VolumeSize": {
          "locationName": "volumeSize",
          "type": "integer"
        },
        "OwnerAlias": {
          "locationName": "ownerAlias"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sbw": {
      "type": "structure",
      "members": {
        "Bucket": {
          "locationName": "bucket"
        },
        "Fault": {
          "shape": "Sbx",
          "locationName": "fault"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Prefix": {
          "locationName": "prefix"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sbx": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sc2": {
      "type": "list",
      "member": {}
    },
    "Sc5": {
      "type": "structure",
      "members": {
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "IngressFilterRules": {
          "shape": "Sc6",
          "locationName": "ingressFilterRuleSet"
        },
        "EgressFilterRules": {
          "shape": "Sc6",
          "locationName": "egressFilterRuleSet"
        },
        "NetworkServices": {
          "shape": "Scb",
          "locationName": "networkServiceSet"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sc6": {
      "type": "list",
      "member": {
        "shape": "Sc7",
        "locationName": "item"
      }
    },
    "Sc7": {
      "type": "structure",
      "members": {
        "TrafficMirrorFilterRuleId": {
          "locationName": "trafficMirrorFilterRuleId"
        },
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "TrafficDirection": {
          "locationName": "trafficDirection"
        },
        "RuleNumber": {
          "locationName": "ruleNumber",
          "type": "integer"
        },
        "RuleAction": {
          "locationName": "ruleAction"
        },
        "Protocol": {
          "locationName": "protocol",
          "type": "integer"
        },
        "DestinationPortRange": {
          "shape": "Sca",
          "locationName": "destinationPortRange"
        },
        "SourcePortRange": {
          "shape": "Sca",
          "locationName": "sourcePortRange"
        },
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "SourceCidrBlock": {
          "locationName": "sourceCidrBlock"
        },
        "Description": {
          "locationName": "description"
        }
      }
    },
    "Sca": {
      "type": "structure",
      "members": {
        "FromPort": {
          "locationName": "fromPort",
          "type": "integer"
        },
        "ToPort": {
          "locationName": "toPort",
          "type": "integer"
        }
      }
    },
    "Scb": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sce": {
      "type": "structure",
      "members": {
        "FromPort": {
          "type": "integer"
        },
        "ToPort": {
          "type": "integer"
        }
      }
    },
    "Sci": {
      "type": "structure",
      "members": {
        "TrafficMirrorSessionId": {
          "locationName": "trafficMirrorSessionId"
        },
        "TrafficMirrorTargetId": {
          "locationName": "trafficMirrorTargetId"
        },
        "TrafficMirrorFilterId": {
          "locationName": "trafficMirrorFilterId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "PacketLength": {
          "locationName": "packetLength",
          "type": "integer"
        },
        "SessionNumber": {
          "locationName": "sessionNumber",
          "type": "integer"
        },
        "VirtualNetworkId": {
          "locationName": "virtualNetworkId",
          "type": "integer"
        },
        "Description": {
          "locationName": "description"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Scl": {
      "type": "structure",
      "members": {
        "TrafficMirrorTargetId": {
          "locationName": "trafficMirrorTargetId"
        },
        "NetworkInterfaceId": {
          "locationName": "networkInterfaceId"
        },
        "NetworkLoadBalancerArn": {
          "locationName": "networkLoadBalancerArn"
        },
        "Type": {
          "locationName": "type"
        },
        "Description": {
          "locationName": "description"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Scu": {
      "type": "structure",
      "members": {
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "TransitGatewayArn": {
          "locationName": "transitGatewayArn"
        },
        "State": {
          "locationName": "state"
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "Description": {
          "locationName": "description"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "AmazonSideAsn": {
              "locationName": "amazonSideAsn",
              "type": "long"
            },
            "AutoAcceptSharedAttachments": {
              "locationName": "autoAcceptSharedAttachments"
            },
            "DefaultRouteTableAssociation": {
              "locationName": "defaultRouteTableAssociation"
            },
            "AssociationDefaultRouteTableId": {
              "locationName": "associationDefaultRouteTableId"
            },
            "DefaultRouteTablePropagation": {
              "locationName": "defaultRouteTablePropagation"
            },
            "PropagationDefaultRouteTableId": {
              "locationName": "propagationDefaultRouteTableId"
            },
            "VpnEcmpSupport": {
              "locationName": "vpnEcmpSupport"
            },
            "DnsSupport": {
              "locationName": "dnsSupport"
            }
          }
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Scz": {
      "type": "structure",
      "members": {
        "DestinationCidrBlock": {
          "locationName": "destinationCidrBlock"
        },
        "TransitGatewayAttachments": {
          "locationName": "transitGatewayAttachments",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ResourceId": {
                "locationName": "resourceId"
              },
              "TransitGatewayAttachmentId": {
                "locationName": "transitGatewayAttachmentId"
              },
              "ResourceType": {
                "locationName": "resourceType"
              }
            }
          }
        },
        "Type": {
          "locationName": "type"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "Sd6": {
      "type": "structure",
      "members": {
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "State": {
          "locationName": "state"
        },
        "DefaultAssociationRouteTable": {
          "locationName": "defaultAssociationRouteTable",
          "type": "boolean"
        },
        "DefaultPropagationRouteTable": {
          "locationName": "defaultPropagationRouteTable",
          "type": "boolean"
        },
        "CreationTime": {
          "locationName": "creationTime",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sdc": {
      "type": "structure",
      "members": {
        "Attachments": {
          "locationName": "attachmentSet",
          "type": "list",
          "member": {
            "shape": "S2s",
            "locationName": "item"
          }
        },
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "CreateTime": {
          "locationName": "createTime",
          "type": "timestamp"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "Size": {
          "locationName": "size",
          "type": "integer"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "State": {
          "locationName": "status"
        },
        "VolumeId": {
          "locationName": "volumeId"
        },
        "Iops": {
          "locationName": "iops",
          "type": "integer"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "VolumeType": {
          "locationName": "volumeType"
        }
      }
    },
    "Sdk": {
      "type": "structure",
      "members": {
        "VpcEndpointId": {
          "locationName": "vpcEndpointId"
        },
        "VpcEndpointType": {
          "locationName": "vpcEndpointType"
        },
        "VpcId": {
          "locationName": "vpcId"
        },
        "ServiceName": {
          "locationName": "serviceName"
        },
        "State": {
          "locationName": "state"
        },
        "PolicyDocument": {
          "locationName": "policyDocument"
        },
        "RouteTableIds": {
          "shape": "Sd",
          "locationName": "routeTableIdSet"
        },
        "SubnetIds": {
          "shape": "Sd",
          "locationName": "subnetIdSet"
        },
        "Groups": {
          "locationName": "groupSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "GroupId": {
                "locationName": "groupId"
              },
              "GroupName": {
                "locationName": "groupName"
              }
            }
          }
        },
        "PrivateDnsEnabled": {
          "locationName": "privateDnsEnabled",
          "type": "boolean"
        },
        "RequesterManaged": {
          "locationName": "requesterManaged",
          "type": "boolean"
        },
        "NetworkInterfaceIds": {
          "shape": "Sd",
          "locationName": "networkInterfaceIdSet"
        },
        "DnsEntries": {
          "shape": "Sdo",
          "locationName": "dnsEntrySet"
        },
        "CreationTimestamp": {
          "locationName": "creationTimestamp",
          "type": "timestamp"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "OwnerId": {
          "locationName": "ownerId"
        }
      }
    },
    "Sdo": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DnsName": {
            "locationName": "dnsName"
          },
          "HostedZoneId": {
            "locationName": "hostedZoneId"
          }
        }
      }
    },
    "Sds": {
      "type": "structure",
      "members": {
        "ConnectionNotificationId": {
          "locationName": "connectionNotificationId"
        },
        "ServiceId": {
          "locationName": "serviceId"
        },
        "VpcEndpointId": {
          "locationName": "vpcEndpointId"
        },
        "ConnectionNotificationType": {
          "locationName": "connectionNotificationType"
        },
        "ConnectionNotificationArn": {
          "locationName": "connectionNotificationArn"
        },
        "ConnectionEvents": {
          "shape": "Sd",
          "locationName": "connectionEvents"
        },
        "ConnectionNotificationState": {
          "locationName": "connectionNotificationState"
        }
      }
    },
    "Sdx": {
      "type": "structure",
      "members": {
        "ServiceType": {
          "shape": "Sdy",
          "locationName": "serviceType"
        },
        "ServiceId": {
          "locationName": "serviceId"
        },
        "ServiceName": {
          "locationName": "serviceName"
        },
        "ServiceState": {
          "locationName": "serviceState"
        },
        "AvailabilityZones": {
          "shape": "Sd",
          "locationName": "availabilityZoneSet"
        },
        "AcceptanceRequired": {
          "locationName": "acceptanceRequired",
          "type": "boolean"
        },
        "ManagesVpcEndpoints": {
          "locationName": "managesVpcEndpoints",
          "type": "boolean"
        },
        "NetworkLoadBalancerArns": {
          "shape": "Sd",
          "locationName": "networkLoadBalancerArnSet"
        },
        "BaseEndpointDnsNames": {
          "shape": "Sd",
          "locationName": "baseEndpointDnsNameSet"
        },
        "PrivateDnsName": {
          "locationName": "privateDnsName"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sdy": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ServiceType": {
            "locationName": "serviceType"
          }
        }
      }
    },
    "Se9": {
      "type": "structure",
      "members": {
        "CustomerGatewayConfiguration": {
          "locationName": "customerGatewayConfiguration"
        },
        "CustomerGatewayId": {
          "locationName": "customerGatewayId"
        },
        "Category": {
          "locationName": "category"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "VpnConnectionId": {
          "locationName": "vpnConnectionId"
        },
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "TransitGatewayId": {
          "locationName": "transitGatewayId"
        },
        "Options": {
          "locationName": "options",
          "type": "structure",
          "members": {
            "StaticRoutesOnly": {
              "locationName": "staticRoutesOnly",
              "type": "boolean"
            }
          }
        },
        "Routes": {
          "locationName": "routes",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "DestinationCidrBlock": {
                "locationName": "destinationCidrBlock"
              },
              "Source": {
                "locationName": "source"
              },
              "State": {
                "locationName": "state"
              }
            }
          }
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        },
        "VgwTelemetry": {
          "locationName": "vgwTelemetry",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AcceptedRouteCount": {
                "locationName": "acceptedRouteCount",
                "type": "integer"
              },
              "LastStatusChange": {
                "locationName": "lastStatusChange",
                "type": "timestamp"
              },
              "OutsideIpAddress": {
                "locationName": "outsideIpAddress"
              },
              "Status": {
                "locationName": "status"
              },
              "StatusMessage": {
                "locationName": "statusMessage"
              },
              "CertificateArn": {
                "locationName": "certificateArn"
              }
            }
          }
        }
      }
    },
    "Sel": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "State": {
          "locationName": "state"
        },
        "Type": {
          "locationName": "type"
        },
        "VpcAttachments": {
          "locationName": "attachments",
          "type": "list",
          "member": {
            "shape": "S2w",
            "locationName": "item"
          }
        },
        "VpnGatewayId": {
          "locationName": "vpnGatewayId"
        },
        "AmazonSideAsn": {
          "locationName": "amazonSideAsn",
          "type": "long"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sew": {
      "type": "list",
      "member": {}
    },
    "Sfe": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Sh6": {
      "type": "list",
      "member": {
        "locationName": "Filter",
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "shape": "Sd",
            "locationName": "Value"
          }
        }
      }
    },
    "Shf": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Deadline": {
            "locationName": "deadline",
            "type": "timestamp"
          },
          "Resource": {
            "locationName": "resource"
          },
          "UseLongIds": {
            "locationName": "useLongIds",
            "type": "boolean"
          }
        }
      }
    },
    "Si5": {
      "type": "list",
      "member": {
        "locationName": "InstanceId"
      }
    },
    "Sik": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sje": {
      "type": "structure",
      "members": {
        "ConversionTaskId": {
          "locationName": "conversionTaskId"
        },
        "ExpirationTime": {
          "locationName": "expirationTime"
        },
        "ImportInstance": {
          "locationName": "importInstance",
          "type": "structure",
          "members": {
            "Description": {
              "locationName": "description"
            },
            "InstanceId": {
              "locationName": "instanceId"
            },
            "Platform": {
              "locationName": "platform"
            },
            "Volumes": {
              "locationName": "volumes",
              "type": "list",
              "member": {
                "locationName": "item",
                "type": "structure",
                "members": {
                  "AvailabilityZone": {
                    "locationName": "availabilityZone"
                  },
                  "BytesConverted": {
                    "locationName": "bytesConverted",
                    "type": "long"
                  },
                  "Description": {
                    "locationName": "description"
                  },
                  "Image": {
                    "shape": "Sji",
                    "locationName": "image"
                  },
                  "Status": {
                    "locationName": "status"
                  },
                  "StatusMessage": {
                    "locationName": "statusMessage"
                  },
                  "Volume": {
                    "shape": "Sjj",
                    "locationName": "volume"
                  }
                }
              }
            }
          }
        },
        "ImportVolume": {
          "locationName": "importVolume",
          "type": "structure",
          "members": {
            "AvailabilityZone": {
              "locationName": "availabilityZone"
            },
            "BytesConverted": {
              "locationName": "bytesConverted",
              "type": "long"
            },
            "Description": {
              "locationName": "description"
            },
            "Image": {
              "shape": "Sji",
              "locationName": "image"
            },
            "Volume": {
              "shape": "Sjj",
              "locationName": "volume"
            }
          }
        },
        "State": {
          "locationName": "state"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Tags": {
          "shape": "Si",
          "locationName": "tagSet"
        }
      }
    },
    "Sji": {
      "type": "structure",
      "members": {
        "Checksum": {
          "locationName": "checksum"
        },
        "Format": {
          "locationName": "format"
        },
        "ImportManifestUrl": {
          "locationName": "importManifestUrl"
        },
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "Sjj": {
      "type": "structure",
      "members": {
        "Id": {
          "locationName": "id"
        },
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "Skf": {
      "type": "structure",
      "members": {
        "S3Bucket": {
          "locationName": "s3Bucket"
        },
        "S3Prefix": {
          "locationName": "s3Prefix"
        }
      }
    },
    "Skp": {
      "type": "structure",
      "members": {
        "EventDescription": {
          "locationName": "eventDescription"
        },
        "EventSubType": {
          "locationName": "eventSubType"
        },
        "InstanceId": {
          "locationName": "instanceId"
        }
      }
    },
    "Sks": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "InstanceType": {
            "locationName": "instanceType"
          },
          "SpotInstanceRequestId": {
            "locationName": "spotInstanceRequestId"
          },
          "InstanceHealth": {
            "locationName": "instanceHealth"
          }
        }
      }
    },
    "Slh": {
      "type": "structure",
      "members": {
        "FpgaImageId": {
          "locationName": "fpgaImageId"
        },
        "Name": {
          "locationName": "name"
        },
        "Description": {
          "locationName": "description"
        },
        "LoadPermissions": {
          "locationName": "loadPermissions",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "UserId": {
                "locationName": "userId"
              },
              "Group": {
                "locationName": "group"
              }
            }
          }
        },
        "ProductCodes": {
          "shape": "Sll",
          "locationName": "productCodes"
        }
      }
    },
    "Sll": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ProductCodeId": {
            "locationName": "productCode"
          },
          "ProductCodeType": {
            "locationName": "type"
          }
        }
      }
    },
    "Slq": {
      "type": "list",
      "member": {
        "locationName": "Owner"
      }
    },
    "Sm9": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Smc": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "Smz": {
      "type": "list",
      "member": {
        "shape": "S7i",
        "locationName": "item"
      }
    },
    "Sn0": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {
            "locationName": "group"
          },
          "UserId": {
            "locationName": "userId"
          }
        }
      }
    },
    "Snd": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code"
        },
        "Message": {
          "locationName": "message"
        }
      }
    },
    "Sng": {
      "type": "list",
      "member": {
        "locationName": "ImportTaskId"
      }
    },
    "Snk": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "DeviceName": {
            "locationName": "deviceName"
          },
          "DiskImageSize": {
            "locationName": "diskImageSize",
            "type": "double"
          },
          "Format": {
            "locationName": "format"
          },
          "Progress": {
            "locationName": "progress"
          },
          "SnapshotId": {
            "locationName": "snapshotId"
          },
          "Status": {
            "locationName": "status"
          },
          "StatusMessage": {
            "locationName": "statusMessage"
          },
          "Url": {
            "locationName": "url"
          },
          "UserBucket": {
            "shape": "Snm",
            "locationName": "userBucket"
          }
        }
      }
    },
    "Snm": {
      "type": "structure",
      "members": {
        "S3Bucket": {
          "locationName": "s3Bucket"
        },
        "S3Key": {
          "locationName": "s3Key"
        }
      }
    },
    "Snr": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "DiskImageSize": {
          "locationName": "diskImageSize",
          "type": "double"
        },
        "Encrypted": {
          "locationName": "encrypted",
          "type": "boolean"
        },
        "Format": {
          "locationName": "format"
        },
        "KmsKeyId": {
          "locationName": "kmsKeyId"
        },
        "Progress": {
          "locationName": "progress"
        },
        "SnapshotId": {
          "locationName": "snapshotId"
        },
        "Status": {
          "locationName": "status"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "Url": {
          "locationName": "url"
        },
        "UserBucket": {
          "shape": "Snm",
          "locationName": "userBucket"
        }
      }
    },
    "Snv": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "DeviceName": {
            "locationName": "deviceName"
          },
          "Ebs": {
            "locationName": "ebs",
            "type": "structure",
            "members": {
              "AttachTime": {
                "locationName": "attachTime",
                "type": "timestamp"
              },
              "DeleteOnTermination": {
                "locationName": "deleteOnTermination",
                "type": "boolean"
              },
              "Status": {
                "locationName": "status"
              },
              "VolumeId": {
                "locationName": "volumeId"
              }
            }
          }
        }
      }
    },
    "Sny": {
      "type": "structure",
      "members": {
        "Value": {
          "locationName": "value",
          "type": "boolean"
        }
      }
    },
    "So9": {
      "type": "structure",
      "members": {
        "InstanceEventId": {
          "locationName": "instanceEventId"
        },
        "Code": {
          "locationName": "code"
        },
        "Description": {
          "locationName": "description"
        },
        "NotAfter": {
          "locationName": "notAfter",
          "type": "timestamp"
        },
        "NotBefore": {
          "locationName": "notBefore",
          "type": "timestamp"
        },
        "NotBeforeDeadline": {
          "locationName": "notBeforeDeadline",
          "type": "timestamp"
        }
      }
    },
    "Soc": {
      "type": "structure",
      "members": {
        "Code": {
          "locationName": "code",
          "type": "integer"
        },
        "Name": {
          "locationName": "name"
        }
      }
    },
    "Soe": {
      "type": "structure",
      "members": {
        "Details": {
          "locationName": "details",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "ImpairedSince": {
                "locationName": "impairedSince",
                "type": "timestamp"
              },
              "Name": {
                "locationName": "name"
              },
              "Status": {
                "locationName": "status"
              }
            }
          }
        },
        "Status": {
          "locationName": "status"
        }
      }
    },
    "Son": {
      "type": "structure",
      "members": {
        "Groups": {
          "shape": "Sal",
          "locationName": "groupSet"
        },
        "Instances": {
          "locationName": "instancesSet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "AmiLaunchIndex": {
                "locationName": "amiLaunchIndex",
                "type": "integer"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceId": {
                "locationName": "instanceId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "LaunchTime": {
                "locationName": "launchTime",
                "type": "timestamp"
              },
              "Monitoring": {
                "shape": "Soq",
                "locationName": "monitoring"
              },
              "Placement": {
                "shape": "S6q",
                "locationName": "placement"
              },
              "Platform": {
                "locationName": "platform"
              },
              "PrivateDnsName": {
                "locationName": "privateDnsName"
              },
              "PrivateIpAddress": {
                "locationName": "privateIpAddress"
              },
              "ProductCodes": {
                "shape": "Sll",
                "locationName": "productCodes"
              },
              "PublicDnsName": {
                "locationName": "dnsName"
              },
              "PublicIpAddress": {
                "locationName": "ipAddress"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "State": {
                "shape": "Soc",
                "locationName": "instanceState"
              },
              "StateTransitionReason": {
                "locationName": "reason"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "VpcId": {
                "locationName": "vpcId"
              },
              "Architecture": {
                "locationName": "architecture"
              },
              "BlockDeviceMappings": {
                "shape": "Snv",
                "locationName": "blockDeviceMapping"
              },
              "ClientToken": {
                "locationName": "clientToken"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "EnaSupport": {
                "locationName": "enaSupport",
                "type": "boolean"
              },
              "Hypervisor": {
                "locationName": "hypervisor"
              },
              "IamInstanceProfile": {
                "shape": "S21",
                "locationName": "iamInstanceProfile"
              },
              "InstanceLifecycle": {
                "locationName": "instanceLifecycle"
              },
              "ElasticGpuAssociations": {
                "locationName": "elasticGpuAssociationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ElasticGpuId": {
                      "locationName": "elasticGpuId"
                    },
                    "ElasticGpuAssociationId": {
                      "locationName": "elasticGpuAssociationId"
                    },
                    "ElasticGpuAssociationState": {
                      "locationName": "elasticGpuAssociationState"
                    },
                    "ElasticGpuAssociationTime": {
                      "locationName": "elasticGpuAssociationTime"
                    }
                  }
                }
              },
              "ElasticInferenceAcceleratorAssociations": {
                "locationName": "elasticInferenceAcceleratorAssociationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ElasticInferenceAcceleratorArn": {
                      "locationName": "elasticInferenceAcceleratorArn"
                    },
                    "ElasticInferenceAcceleratorAssociationId": {
                      "locationName": "elasticInferenceAcceleratorAssociationId"
                    },
                    "ElasticInferenceAcceleratorAssociationState": {
                      "locationName": "elasticInferenceAcceleratorAssociationState"
                    },
                    "ElasticInferenceAcceleratorAssociationTime": {
                      "locationName": "elasticInferenceAcceleratorAssociationTime",
                      "type": "timestamp"
                    }
                  }
                }
              },
              "NetworkInterfaces": {
                "locationName": "networkInterfaceSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "Association": {
                      "shape": "Soz",
                      "locationName": "association"
                    },
                    "Attachment": {
                      "locationName": "attachment",
                      "type": "structure",
                      "members": {
                        "AttachTime": {
                          "locationName": "attachTime",
                          "type": "timestamp"
                        },
                        "AttachmentId": {
                          "locationName": "attachmentId"
                        },
                        "DeleteOnTermination": {
                          "locationName": "deleteOnTermination",
                          "type": "boolean"
                        },
                        "DeviceIndex": {
                          "locationName": "deviceIndex",
                          "type": "integer"
                        },
                        "Status": {
                          "locationName": "status"
                        }
                      }
                    },
                    "Description": {
                      "locationName": "description"
                    },
                    "Groups": {
                      "shape": "Sal",
                      "locationName": "groupSet"
                    },
                    "Ipv6Addresses": {
                      "shape": "S9e",
                      "locationName": "ipv6AddressesSet"
                    },
                    "MacAddress": {
                      "locationName": "macAddress"
                    },
                    "NetworkInterfaceId": {
                      "locationName": "networkInterfaceId"
                    },
                    "OwnerId": {
                      "locationName": "ownerId"
                    },
                    "PrivateDnsName": {
                      "locationName": "privateDnsName"
                    },
                    "PrivateIpAddress": {
                      "locationName": "privateIpAddress"
                    },
                    "PrivateIpAddresses": {
                      "locationName": "privateIpAddressesSet",
                      "type": "list",
                      "member": {
                        "locationName": "item",
                        "type": "structure",
                        "members": {
                          "Association": {
                            "shape": "Soz",
                            "locationName": "association"
                          },
                          "Primary": {
                            "locationName": "primary",
                            "type": "boolean"
                          },
                          "PrivateDnsName": {
                            "locationName": "privateDnsName"
                          },
                          "PrivateIpAddress": {
                            "locationName": "privateIpAddress"
                          }
                        }
                      }
                    },
                    "SourceDestCheck": {
                      "locationName": "sourceDestCheck",
                      "type": "boolean"
                    },
                    "Status": {
                      "locationName": "status"
                    },
                    "SubnetId": {
                      "locationName": "subnetId"
                    },
                    "VpcId": {
                      "locationName": "vpcId"
                    },
                    "InterfaceType": {
                      "locationName": "interfaceType"
                    }
                  }
                }
              },
              "RootDeviceName": {
                "locationName": "rootDeviceName"
              },
              "RootDeviceType": {
                "locationName": "rootDeviceType"
              },
              "SecurityGroups": {
                "shape": "Sal",
                "locationName": "groupSet"
              },
              "SourceDestCheck": {
                "locationName": "sourceDestCheck",
                "type": "boolean"
              },
              "SpotInstanceRequestId": {
                "locationName": "spotInstanceRequestId"
              },
              "SriovNetSupport": {
                "locationName": "sriovNetSupport"
              },
              "StateReason": {
                "shape": "Snd",
                "locationName": "stateReason"
              },
              "Tags": {
                "shape": "Si",
                "locationName": "tagSet"
              },
              "VirtualizationType": {
                "locationName": "virtualizationType"
              },
              "CpuOptions": {
                "locationName": "cpuOptions",
                "type": "structure",
                "members": {
                  "CoreCount": {
                    "locationName": "coreCount",
                    "type": "integer"
                  },
                  "ThreadsPerCore": {
                    "locationName": "threadsPerCore",
                    "type": "integer"
                  }
                }
              },
              "CapacityReservationId": {
                "locationName": "capacityReservationId"
              },
              "CapacityReservationSpecification": {
                "locationName": "capacityReservationSpecification",
                "type": "structure",
                "members": {
                  "CapacityReservationPreference": {
                    "locationName": "capacityReservationPreference"
                  },
                  "CapacityReservationTarget": {
                    "shape": "S9t",
                    "locationName": "capacityReservationTarget"
                  }
                }
              },
              "HibernationOptions": {
                "locationName": "hibernationOptions",
                "type": "structure",
                "members": {
                  "Configured": {
                    "locationName": "configured",
                    "type": "boolean"
                  }
                }
              },
              "Licenses": {
                "locationName": "licenseSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "LicenseConfigurationArn": {
                      "locationName": "licenseConfigurationArn"
                    }
                  }
                }
              }
            }
          }
        },
        "OwnerId": {
          "locationName": "ownerId"
        },
        "RequesterId": {
          "locationName": "requesterId"
        },
        "ReservationId": {
          "locationName": "reservationId"
        }
      }
    },
    "Soq": {
      "type": "structure",
      "members": {
        "State": {
          "locationName": "state"
        }
      }
    },
    "Soz": {
      "type": "structure",
      "members": {
        "IpOwnerId": {
          "locationName": "ipOwnerId"
        },
        "PublicDnsName": {
          "locationName": "publicDnsName"
        },
        "PublicIp": {
          "locationName": "publicIp"
        }
      }
    },
    "Sra": {
      "type": "list",
      "member": {
        "locationName": "ReservedInstancesId"
      }
    },
    "Sri": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Amount": {
            "locationName": "amount",
            "type": "double"
          },
          "Frequency": {
            "locationName": "frequency"
          }
        }
      }
    },
    "Srv": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "Platform": {
          "locationName": "platform"
        },
        "Scope": {
          "locationName": "scope"
        }
      }
    },
    "Ssh": {
      "type": "structure",
      "members": {
        "Frequency": {
          "locationName": "frequency"
        },
        "Interval": {
          "locationName": "interval",
          "type": "integer"
        },
        "OccurrenceDaySet": {
          "locationName": "occurrenceDaySet",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "integer"
          }
        },
        "OccurrenceRelativeToEnd": {
          "locationName": "occurrenceRelativeToEnd",
          "type": "boolean"
        },
        "OccurrenceUnit": {
          "locationName": "occurrenceUnit"
        }
      }
    },
    "Sso": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "CreateDate": {
          "locationName": "createDate",
          "type": "timestamp"
        },
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        },
        "InstanceCount": {
          "locationName": "instanceCount",
          "type": "integer"
        },
        "InstanceType": {
          "locationName": "instanceType"
        },
        "NetworkPlatform": {
          "locationName": "networkPlatform"
        },
        "NextSlotStartTime": {
          "locationName": "nextSlotStartTime",
          "type": "timestamp"
        },
        "Platform": {
          "locationName": "platform"
        },
        "PreviousSlotEndTime": {
          "locationName": "previousSlotEndTime",
          "type": "timestamp"
        },
        "Recurrence": {
          "shape": "Ssh",
          "locationName": "recurrence"
        },
        "ScheduledInstanceId": {
          "locationName": "scheduledInstanceId"
        },
        "SlotDurationInHours": {
          "locationName": "slotDurationInHours",
          "type": "integer"
        },
        "TermEndDate": {
          "locationName": "termEndDate",
          "type": "timestamp"
        },
        "TermStartDate": {
          "locationName": "termStartDate",
          "type": "timestamp"
        },
        "TotalScheduledInstanceHours": {
          "locationName": "totalScheduledInstanceHours",
          "type": "integer"
        }
      }
    },
    "Ssv": {
      "type": "list",
      "member": {
        "locationName": "GroupName"
      }
    },
    "St3": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {
            "locationName": "group"
          },
          "UserId": {
            "locationName": "userId"
          }
        }
      }
    },
    "Stq": {
      "type": "structure",
      "required": [
        "IamFleetRole",
        "TargetCapacity"
      ],
      "members": {
        "AllocationStrategy": {
          "locationName": "allocationStrategy"
        },
        "OnDemandAllocationStrategy": {
          "locationName": "onDemandAllocationStrategy"
        },
        "ClientToken": {
          "locationName": "clientToken"
        },
        "ExcessCapacityTerminationPolicy": {
          "locationName": "excessCapacityTerminationPolicy"
        },
        "FulfilledCapacity": {
          "locationName": "fulfilledCapacity",
          "type": "double"
        },
        "OnDemandFulfilledCapacity": {
          "locationName": "onDemandFulfilledCapacity",
          "type": "double"
        },
        "IamFleetRole": {
          "locationName": "iamFleetRole"
        },
        "LaunchSpecifications": {
          "locationName": "launchSpecifications",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "SecurityGroups": {
                "shape": "Sal",
                "locationName": "groupSet"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "Smz",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S1y",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "Monitoring": {
                "locationName": "monitoring",
                "type": "structure",
                "members": {
                  "Enabled": {
                    "locationName": "enabled",
                    "type": "boolean"
                  }
                }
              },
              "NetworkInterfaces": {
                "shape": "Stx",
                "locationName": "networkInterfaceSet"
              },
              "Placement": {
                "shape": "Stz",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SpotPrice": {
                "locationName": "spotPrice"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "UserData": {
                "locationName": "userData"
              },
              "WeightedCapacity": {
                "locationName": "weightedCapacity",
                "type": "double"
              },
              "TagSpecifications": {
                "locationName": "tagSpecificationSet",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "ResourceType": {
                      "locationName": "resourceType"
                    },
                    "Tags": {
                      "shape": "Si",
                      "locationName": "tag"
                    }
                  }
                }
              }
            }
          }
        },
        "LaunchTemplateConfigs": {
          "locationName": "launchTemplateConfigs",
          "type": "list",
          "member": {
            "locationName": "item",
            "type": "structure",
            "members": {
              "LaunchTemplateSpecification": {
                "shape": "S6z",
                "locationName": "launchTemplateSpecification"
              },
              "Overrides": {
                "locationName": "overrides",
                "type": "list",
                "member": {
                  "locationName": "item",
                  "type": "structure",
                  "members": {
                    "InstanceType": {
                      "locationName": "instanceType"
                    },
                    "SpotPrice": {
                      "locationName": "spotPrice"
                    },
                    "SubnetId": {
                      "locationName": "subnetId"
                    },
                    "AvailabilityZone": {
                      "locationName": "availabilityZone"
                    },
                    "WeightedCapacity": {
                      "locationName": "weightedCapacity",
                      "type": "double"
                    },
                    "Priority": {
                      "locationName": "priority",
                      "type": "double"
                    }
                  }
                }
              }
            }
          }
        },
        "SpotPrice": {
          "locationName": "spotPrice"
        },
        "TargetCapacity": {
          "locationName": "targetCapacity",
          "type": "integer"
        },
        "OnDemandTargetCapacity": {
          "locationName": "onDemandTargetCapacity",
          "type": "integer"
        },
        "OnDemandMaxTotalPrice": {
          "locationName": "onDemandMaxTotalPrice"
        },
        "SpotMaxTotalPrice": {
          "locationName": "spotMaxTotalPrice"
        },
        "TerminateInstancesWithExpiration": {
          "locationName": "terminateInstancesWithExpiration",
          "type": "boolean"
        },
        "Type": {
          "locationName": "type"
        },
        "ValidFrom": {
          "locationName": "validFrom",
          "type": "timestamp"
        },
        "ValidUntil": {
          "locationName": "validUntil",
          "type": "timestamp"
        },
        "ReplaceUnhealthyInstances": {
          "locationName": "replaceUnhealthyInstances",
          "type": "boolean"
        },
        "InstanceInterruptionBehavior": {
          "locationName": "instanceInterruptionBehavior"
        },
        "LoadBalancersConfig": {
          "locationName": "loadBalancersConfig",
          "type": "structure",
          "members": {
            "ClassicLoadBalancersConfig": {
              "locationName": "classicLoadBalancersConfig",
              "type": "structure",
              "members": {
                "ClassicLoadBalancers": {
                  "locationName": "classicLoadBalancers",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Name": {
                        "locationName": "name"
                      }
                    }
                  }
                }
              }
            },
            "TargetGroupsConfig": {
              "locationName": "targetGroupsConfig",
              "type": "structure",
              "members": {
                "TargetGroups": {
                  "locationName": "targetGroups",
                  "type": "list",
                  "member": {
                    "locationName": "item",
                    "type": "structure",
                    "members": {
                      "Arn": {
                        "locationName": "arn"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "InstancePoolsToUseCount": {
          "locationName": "instancePoolsToUseCount",
          "type": "integer"
        }
      }
    },
    "Stx": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "AssociatePublicIpAddress": {
            "locationName": "associatePublicIpAddress",
            "type": "boolean"
          },
          "DeleteOnTermination": {
            "locationName": "deleteOnTermination",
            "type": "boolean"
          },
          "Description": {
            "locationName": "description"
          },
          "DeviceIndex": {
            "locationName": "deviceIndex",
            "type": "integer"
          },
          "Groups": {
            "shape": "S8a",
            "locationName": "SecurityGroupId"
          },
          "Ipv6AddressCount": {
            "locationName": "ipv6AddressCount",
            "type": "integer"
          },
          "Ipv6Addresses": {
            "shape": "S9e",
            "locationName": "ipv6AddressesSet",
            "queryName": "Ipv6Addresses"
          },
          "NetworkInterfaceId": {
            "locationName": "networkInterfaceId"
          },
          "PrivateIpAddress": {
            "locationName": "privateIpAddress"
          },
          "PrivateIpAddresses": {
            "shape": "S8d",
            "locationName": "privateIpAddressesSet",
            "queryName": "PrivateIpAddresses"
          },
          "SecondaryPrivateIpAddressCount": {
            "locationName": "secondaryPrivateIpAddressCount",
            "type": "integer"
          },
          "SubnetId": {
            "locationName": "subnetId"
          },
          "InterfaceType": {}
        }
      }
    },
    "Stz": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "GroupName": {
          "locationName": "groupName"
        },
        "Tenancy": {
          "locationName": "tenancy"
        }
      }
    },
    "Suf": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "ActualBlockHourlyPrice": {
            "locationName": "actualBlockHourlyPrice"
          },
          "AvailabilityZoneGroup": {
            "locationName": "availabilityZoneGroup"
          },
          "BlockDurationMinutes": {
            "locationName": "blockDurationMinutes",
            "type": "integer"
          },
          "CreateTime": {
            "locationName": "createTime",
            "type": "timestamp"
          },
          "Fault": {
            "shape": "Sbx",
            "locationName": "fault"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "LaunchGroup": {
            "locationName": "launchGroup"
          },
          "LaunchSpecification": {
            "locationName": "launchSpecification",
            "type": "structure",
            "members": {
              "UserData": {
                "locationName": "userData"
              },
              "SecurityGroups": {
                "shape": "Sal",
                "locationName": "groupSet"
              },
              "AddressingType": {
                "locationName": "addressingType"
              },
              "BlockDeviceMappings": {
                "shape": "Smz",
                "locationName": "blockDeviceMapping"
              },
              "EbsOptimized": {
                "locationName": "ebsOptimized",
                "type": "boolean"
              },
              "IamInstanceProfile": {
                "shape": "S1y",
                "locationName": "iamInstanceProfile"
              },
              "ImageId": {
                "locationName": "imageId"
              },
              "InstanceType": {
                "locationName": "instanceType"
              },
              "KernelId": {
                "locationName": "kernelId"
              },
              "KeyName": {
                "locationName": "keyName"
              },
              "NetworkInterfaces": {
                "shape": "Stx",
                "locationName": "networkInterfaceSet"
              },
              "Placement": {
                "shape": "Stz",
                "locationName": "placement"
              },
              "RamdiskId": {
                "locationName": "ramdiskId"
              },
              "SubnetId": {
                "locationName": "subnetId"
              },
              "Monitoring": {
                "shape": "Sui",
                "locationName": "monitoring"
              }
            }
          },
          "LaunchedAvailabilityZone": {
            "locationName": "launchedAvailabilityZone"
          },
          "ProductDescription": {
            "locationName": "productDescription"
          },
          "SpotInstanceRequestId": {
            "locationName": "spotInstanceRequestId"
          },
          "SpotPrice": {
            "locationName": "spotPrice"
          },
          "State": {
            "locationName": "state"
          },
          "Status": {
            "locationName": "status",
            "type": "structure",
            "members": {
              "Code": {
                "locationName": "code"
              },
              "Message": {
                "locationName": "message"
              },
              "UpdateTime": {
                "locationName": "updateTime",
                "type": "timestamp"
              }
            }
          },
          "Tags": {
            "shape": "Si",
            "locationName": "tagSet"
          },
          "Type": {
            "locationName": "type"
          },
          "ValidFrom": {
            "locationName": "validFrom",
            "type": "timestamp"
          },
          "ValidUntil": {
            "locationName": "validUntil",
            "type": "timestamp"
          },
          "InstanceInterruptionBehavior": {
            "locationName": "instanceInterruptionBehavior"
          }
        }
      }
    },
    "Sui": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Enabled": {
          "locationName": "enabled",
          "type": "boolean"
        }
      }
    },
    "Sux": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "FromPort": {
            "locationName": "fromPort",
            "type": "integer"
          },
          "IpProtocol": {
            "locationName": "ipProtocol"
          },
          "IpRanges": {
            "locationName": "ipRanges",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "PrefixListIds": {
            "locationName": "prefixListIds",
            "type": "list",
            "member": {
              "locationName": "item"
            }
          },
          "ToPort": {
            "locationName": "toPort",
            "type": "integer"
          },
          "UserIdGroupPairs": {
            "locationName": "groups",
            "type": "list",
            "member": {
              "shape": "S3c",
              "locationName": "item"
            }
          }
        }
      }
    },
    "Svm": {
      "type": "list",
      "member": {}
    },
    "Sw7": {
      "type": "list",
      "member": {
        "locationName": "VolumeId"
      }
    },
    "Swq": {
      "type": "structure",
      "members": {
        "VolumeId": {
          "locationName": "volumeId"
        },
        "ModificationState": {
          "locationName": "modificationState"
        },
        "StatusMessage": {
          "locationName": "statusMessage"
        },
        "TargetSize": {
          "locationName": "targetSize",
          "type": "integer"
        },
        "TargetIops": {
          "locationName": "targetIops",
          "type": "integer"
        },
        "TargetVolumeType": {
          "locationName": "targetVolumeType"
        },
        "OriginalSize": {
          "locationName": "originalSize",
          "type": "integer"
        },
        "OriginalIops": {
          "locationName": "originalIops",
          "type": "integer"
        },
        "OriginalVolumeType": {
          "locationName": "originalVolumeType"
        },
        "Progress": {
          "locationName": "progress",
          "type": "long"
        },
        "StartTime": {
          "locationName": "startTime",
          "type": "timestamp"
        },
        "EndTime": {
          "locationName": "endTime",
          "type": "timestamp"
        }
      }
    },
    "Sww": {
      "type": "list",
      "member": {
        "locationName": "VpcId"
      }
    },
    "Syj": {
      "type": "structure",
      "members": {
        "TransitGatewayAttachmentId": {
          "locationName": "transitGatewayAttachmentId"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "ResourceType": {
          "locationName": "resourceType"
        },
        "TransitGatewayRouteTableId": {
          "locationName": "transitGatewayRouteTableId"
        },
        "State": {
          "locationName": "state"
        }
      }
    },
    "S101": {
      "type": "list",
      "member": {
        "locationName": "item"
      }
    },
    "S103": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "CurrencyCode": {
            "locationName": "currencyCode"
          },
          "Duration": {
            "locationName": "duration",
            "type": "integer"
          },
          "HostIdSet": {
            "shape": "Sm9",
            "locationName": "hostIdSet"
          },
          "HostReservationId": {
            "locationName": "hostReservationId"
          },
          "HourlyPrice": {
            "locationName": "hourlyPrice"
          },
          "InstanceFamily": {
            "locationName": "instanceFamily"
          },
          "PaymentOption": {
            "locationName": "paymentOption"
          },
          "UpfrontPrice": {
            "locationName": "upfrontPrice"
          }
        }
      }
    },
    "S10b": {
      "type": "structure",
      "members": {
        "HourlyPrice": {
          "locationName": "hourlyPrice"
        },
        "RemainingTotalValue": {
          "locationName": "remainingTotalValue"
        },
        "RemainingUpfrontValue": {
          "locationName": "remainingUpfrontValue"
        }
      }
    },
    "S10w": {
      "type": "structure",
      "members": {
        "Comment": {},
        "UploadEnd": {
          "type": "timestamp"
        },
        "UploadSize": {
          "type": "double"
        },
        "UploadStart": {
          "type": "timestamp"
        }
      }
    },
    "S10z": {
      "type": "structure",
      "members": {
        "S3Bucket": {},
        "S3Key": {}
      }
    },
    "S114": {
      "type": "structure",
      "required": [
        "Bytes",
        "Format",
        "ImportManifestUrl"
      ],
      "members": {
        "Bytes": {
          "locationName": "bytes",
          "type": "long"
        },
        "Format": {
          "locationName": "format"
        },
        "ImportManifestUrl": {
          "locationName": "importManifestUrl"
        }
      }
    },
    "S115": {
      "type": "structure",
      "required": [
        "Size"
      ],
      "members": {
        "Size": {
          "locationName": "size",
          "type": "long"
        }
      }
    },
    "S11r": {
      "type": "list",
      "member": {
        "locationName": "UserId"
      }
    },
    "S11s": {
      "type": "list",
      "member": {
        "locationName": "UserGroup"
      }
    },
    "S11t": {
      "type": "list",
      "member": {
        "locationName": "ProductCode"
      }
    },
    "S11v": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "Group": {},
          "UserId": {}
        }
      }
    },
    "S120": {
      "type": "list",
      "member": {
        "shape": "Sn",
        "locationName": "item"
      }
    },
    "S12b": {
      "type": "structure",
      "members": {
        "CapacityReservationPreference": {},
        "CapacityReservationTarget": {
          "shape": "S8y"
        }
      }
    },
    "S13v": {
      "type": "structure",
      "members": {
        "AllowDnsResolutionFromRemoteVpc": {
          "type": "boolean"
        },
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "type": "boolean"
        }
      }
    },
    "S13x": {
      "type": "structure",
      "members": {
        "AllowDnsResolutionFromRemoteVpc": {
          "locationName": "allowDnsResolutionFromRemoteVpc",
          "type": "boolean"
        },
        "AllowEgressFromLocalClassicLinkToRemoteVpc": {
          "locationName": "allowEgressFromLocalClassicLinkToRemoteVpc",
          "type": "boolean"
        },
        "AllowEgressFromLocalVpcToRemoteClassicLink": {
          "locationName": "allowEgressFromLocalVpcToRemoteClassicLink",
          "type": "boolean"
        }
      }
    },
    "S147": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "InstanceId": {
            "locationName": "instanceId"
          },
          "Monitoring": {
            "shape": "Soq",
            "locationName": "monitoring"
          }
        }
      }
    },
    "S16l": {
      "type": "list",
      "member": {
        "locationName": "SecurityGroupId"
      }
    },
    "S170": {
      "type": "list",
      "member": {
        "locationName": "item",
        "type": "structure",
        "members": {
          "CurrentState": {
            "shape": "Soc",
            "locationName": "currentState"
          },
          "InstanceId": {
            "locationName": "instanceId"
          },
          "PreviousState": {
            "shape": "Soc",
            "locationName": "previousState"
          }
        }
      }
    }
  }
}