{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-25",
    "endpointPrefix": "applicationinsights",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Application Insights",
    "serviceFullName": "Amazon CloudWatch Application Insights",
    "serviceId": "Application Insights",
    "signatureVersion": "v4",
    "signingName": "applicationinsights",
    "targetPrefix": "EC2WindowsBarleyService",
    "uid": "application-insights-2018-11-25"
  },
  "operations": {
    "CreateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {},
          "OpsCenterEnabled": {
            "type": "boolean"
          },
          "OpsItemSNSTopicArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfo": {
            "shape": "S6"
          }
        }
      }
    },
    "CreateComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName",
          "ResourceList"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "ResourceList": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfo": {
            "shape": "S6"
          }
        }
      }
    },
    "DescribeComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationComponent": {
            "shape": "Sm"
          },
          "ResourceList": {
            "shape": "Sb"
          }
        }
      }
    },
    "DescribeComponentConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Monitor": {
            "type": "boolean"
          },
          "Tier": {},
          "ComponentConfiguration": {}
        }
      }
    },
    "DescribeComponentConfigurationRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName",
          "Tier"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "Tier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ComponentConfiguration": {}
        }
      }
    },
    "DescribeObservation": {
      "input": {
        "type": "structure",
        "required": [
          "ObservationId"
        ],
        "members": {
          "ObservationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Observation": {
            "shape": "Sy"
          }
        }
      }
    },
    "DescribeProblem": {
      "input": {
        "type": "structure",
        "required": [
          "ProblemId"
        ],
        "members": {
          "ProblemId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Problem": {
            "shape": "S1e"
          }
        }
      }
    },
    "DescribeProblemObservations": {
      "input": {
        "type": "structure",
        "required": [
          "ProblemId"
        ],
        "members": {
          "ProblemId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RelatedObservations": {
            "type": "structure",
            "members": {
              "ObservationList": {
                "type": "list",
                "member": {
                  "shape": "Sy"
                }
              }
            }
          }
        }
      }
    },
    "ListApplications": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfoList": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListComponents": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationComponentList": {
            "type": "list",
            "member": {
              "shape": "Sm"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProblems": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceGroupName": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProblemList": {
            "type": "list",
            "member": {
              "shape": "S1e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "UpdateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName"
        ],
        "members": {
          "ResourceGroupName": {},
          "OpsCenterEnabled": {
            "type": "boolean"
          },
          "OpsItemSNSTopicArn": {},
          "RemoveSNSTopic": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationInfo": {
            "shape": "S6"
          }
        }
      }
    },
    "UpdateComponent": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "NewComponentName": {},
          "ResourceList": {
            "shape": "Sb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateComponentConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceGroupName",
          "ComponentName"
        ],
        "members": {
          "ResourceGroupName": {},
          "ComponentName": {},
          "Monitor": {
            "type": "boolean"
          },
          "Tier": {},
          "ComponentConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "ResourceGroupName": {},
        "LifeCycle": {},
        "OpsItemSNSTopicArn": {},
        "OpsCenterEnabled": {
          "type": "boolean"
        },
        "Remarks": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sm": {
      "type": "structure",
      "members": {
        "ComponentName": {},
        "ResourceType": {},
        "Tier": {},
        "Monitor": {
          "type": "boolean"
        }
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "Id": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "SourceType": {},
        "SourceARN": {},
        "LogGroup": {},
        "LineTime": {
          "type": "timestamp"
        },
        "LogText": {},
        "LogFilter": {},
        "MetricNamespace": {},
        "MetricName": {},
        "Unit": {},
        "Value": {
          "type": "double"
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "Id": {},
        "Title": {},
        "Insights": {},
        "Status": {},
        "AffectedResource": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "SeverityLevel": {},
        "ResourceGroupName": {},
        "Feedback": {
          "type": "map",
          "key": {},
          "value": {}
        }
      }
    }
  }
}