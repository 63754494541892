import { useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { selectCellsInRange, sortCells } from './helpers';
import { useCopy, useCut, usePaste } from '../../hooks';
import { mergeRefs } from '../../utils';
export const useSelectableCells = ({ rowsCount, columnsCount, onCopy, onCut, onPaste, }) => {
    const [selectedCells, setSelectedCells] = useState([]);
    const [activeCell, setActiveCell] = useState(null);
    const isSelected = (row, col) => selectedCells.some(([r, c]) => row === r && col === c);
    const isActive = (row, col) => activeCell?.[0] === row && activeCell?.[1] === col;
    const copyRef = useCopy(() => onCopy && onCopy({ selectedCells, isActive }));
    const cutRef = useCut(() => onCut && onCut({ selectedCells, isActive }));
    const pasteRef = usePaste(() => onPaste && onPaste({ selectedCells, isActive }));
    const scopedCopyPasteRef = mergeRefs(copyRef, pasteRef, cutRef);
    const handleCellClick = (row, col) => (event) => {
        const selection = [row, col];
        event.preventDefault();
        if (event.metaKey && event.shiftKey) {
            // implement mutliple range selection here
            return;
        }
        if (event.metaKey || event.ctrlKey) {
            const newSelectedCells = isSelected(row, col)
                ? selectedCells.filter(([r, c]) => !(row === r && col === c))
                : [...selectedCells, selection];
            setSelectedCells(sortCells(newSelectedCells));
            return;
        }
        if (event.shiftKey) {
            event.preventDefault();
            const found = selectedCells.find(([, col]) => col === selection[1] || row === selection[0]);
            if (!found)
                return;
            const range = selectCellsInRange(found, selection);
            range && setSelectedCells(range);
            return;
        }
        if (isSelected(row, col)) {
            setActiveCell([row, col]);
            return;
        }
        setActiveCell(null);
        setSelectedCells([selection]);
    };
    const resetSelection = () => {
        setSelectedCells([]);
    };
    const resetActive = () => {
        setActiveCell(null);
    };
    const selectNextCell = (row, col, isTab) => () => {
        if (isTab) {
            const nextCol = col + 1;
            resetActive();
            nextCol < columnsCount && setSelectedCells([[row, nextCol]]);
            return;
        }
        const nextRow = row + 1;
        resetActive();
        nextRow < rowsCount && setSelectedCells([[nextRow, col]]);
    };
    const activateCell = (row, col) => () => setActiveCell([row, col]);
    const navigateCell = (e) => {
        e.preventDefault();
        if (selectedCells.length > 0) {
            const [row, col] = selectedCells[selectedCells.length - 1];
            if (isActive(row, col))
                return;
            switch (e.key) {
                case 'ArrowUp':
                    row - 1 >= 0 && handleCellClick(row - 1, col)(e);
                    break;
                case 'ArrowDown':
                    row + 1 < rowsCount && handleCellClick(row + 1, col)(e);
                    break;
                case 'ArrowLeft':
                    col - 1 >= 0 && handleCellClick(row, col - 1)(e);
                    break;
                case 'ArrowRight':
                    col + 1 < columnsCount && handleCellClick(row, col + 1)(e);
                    break;
            }
        }
    };
    useHotkeys('up, down, right, left, shift+up, shift+down, shift+right, shiff+left', (e) => {
        navigateCell(e);
    }, [handleCellClick, isActive, selectedCells]);
    useHotkeys('enter', (e) => {
        if (selectedCells.length > 0) {
            const [row, col] = selectedCells[selectedCells.length - 1];
            if (isActive(row, col))
                return;
            handleCellClick(row, col)(e);
        }
    }, [handleCellClick, isActive, selectedCells]);
    useHotkeys('tab', (e) => {
        if (selectedCells.length > 0) {
            e.preventDefault();
            const [row, col] = selectedCells[selectedCells.length - 1];
            selectNextCell(row, col, true)();
            return;
        }
    }, { enableOnFormTags: ['input', 'textarea', 'select'] }, [handleCellClick, isActive, selectedCells]);
    return {
        isSelected,
        isActive,
        handleCellClick,
        selectedCells,
        selectNextCell,
        resetSelection,
        resetActive,
        activateCell,
        scopedCopyPasteRef,
    };
};
