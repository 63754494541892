{
  "metadata": {
    "apiVersion": "2016-12-01",
    "endpointPrefix": "pinpoint",
    "signingName": "mobiletargeting",
    "serviceFullName": "Amazon Pinpoint",
    "serviceId": "Pinpoint",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "pinpoint-2016-12-01",
    "signatureVersion": "v4"
  },
  "operations": {
    "CreateApp": {
      "http": {
        "requestUri": "/v1/apps",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "CreateApplicationRequest": {
            "type": "structure",
            "members": {
              "Name": {},
              "tags": {
                "shape": "S4",
                "locationName": "tags"
              }
            },
            "required": [
              "Name"
            ]
          }
        },
        "required": [
          "CreateApplicationRequest"
        ],
        "payload": "CreateApplicationRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationResponse": {
            "shape": "S6"
          }
        },
        "required": [
          "ApplicationResponse"
        ],
        "payload": "ApplicationResponse"
      }
    },
    "CreateCampaign": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/campaigns",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteCampaignRequest": {
            "shape": "S8"
          }
        },
        "required": [
          "ApplicationId",
          "WriteCampaignRequest"
        ],
        "payload": "WriteCampaignRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S12"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "CreateExportJob": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/jobs/export",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "ExportJobRequest": {
            "type": "structure",
            "members": {
              "RoleArn": {},
              "S3UrlPrefix": {},
              "SegmentId": {},
              "SegmentVersion": {
                "type": "integer"
              }
            },
            "required": [
              "S3UrlPrefix",
              "RoleArn"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "ExportJobRequest"
        ],
        "payload": "ExportJobRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobResponse": {
            "shape": "S1a"
          }
        },
        "required": [
          "ExportJobResponse"
        ],
        "payload": "ExportJobResponse"
      }
    },
    "CreateImportJob": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/jobs/import",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "ImportJobRequest": {
            "type": "structure",
            "members": {
              "DefineSegment": {
                "type": "boolean"
              },
              "ExternalId": {},
              "Format": {},
              "RegisterEndpoints": {
                "type": "boolean"
              },
              "RoleArn": {},
              "S3Url": {},
              "SegmentId": {},
              "SegmentName": {}
            },
            "required": [
              "Format",
              "S3Url",
              "RoleArn"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "ImportJobRequest"
        ],
        "payload": "ImportJobRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobResponse": {
            "shape": "S1h"
          }
        },
        "required": [
          "ImportJobResponse"
        ],
        "payload": "ImportJobResponse"
      }
    },
    "CreateSegment": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/segments",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteSegmentRequest": {
            "shape": "S1k"
          }
        },
        "required": [
          "ApplicationId",
          "WriteSegmentRequest"
        ],
        "payload": "WriteSegmentRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S24"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "DeleteAdmChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/adm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ADMChannelResponse": {
            "shape": "S2a"
          }
        },
        "required": [
          "ADMChannelResponse"
        ],
        "payload": "ADMChannelResponse"
      }
    },
    "DeleteApnsChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSChannelResponse": {
            "shape": "S2d"
          }
        },
        "required": [
          "APNSChannelResponse"
        ],
        "payload": "APNSChannelResponse"
      }
    },
    "DeleteApnsSandboxChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelResponse": {
            "shape": "S2g"
          }
        },
        "required": [
          "APNSSandboxChannelResponse"
        ],
        "payload": "APNSSandboxChannelResponse"
      }
    },
    "DeleteApnsVoipChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipChannelResponse": {
            "shape": "S2j"
          }
        },
        "required": [
          "APNSVoipChannelResponse"
        ],
        "payload": "APNSVoipChannelResponse"
      }
    },
    "DeleteApnsVoipSandboxChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelResponse": {
            "shape": "S2m"
          }
        },
        "required": [
          "APNSVoipSandboxChannelResponse"
        ],
        "payload": "APNSVoipSandboxChannelResponse"
      }
    },
    "DeleteApp": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationResponse": {
            "shape": "S6"
          }
        },
        "required": [
          "ApplicationResponse"
        ],
        "payload": "ApplicationResponse"
      }
    },
    "DeleteBaiduChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/baidu",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "BaiduChannelResponse": {
            "shape": "S2r"
          }
        },
        "required": [
          "BaiduChannelResponse"
        ],
        "payload": "BaiduChannelResponse"
      }
    },
    "DeleteCampaign": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          }
        },
        "required": [
          "CampaignId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S12"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "DeleteEmailChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/email",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EmailChannelResponse": {
            "shape": "S2w"
          }
        },
        "required": [
          "EmailChannelResponse"
        ],
        "payload": "EmailChannelResponse"
      }
    },
    "DeleteEndpoint": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/endpoints/{endpoint-id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointId": {
            "location": "uri",
            "locationName": "endpoint-id"
          }
        },
        "required": [
          "ApplicationId",
          "EndpointId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointResponse": {
            "shape": "S2z"
          }
        },
        "required": [
          "EndpointResponse"
        ],
        "payload": "EndpointResponse"
      }
    },
    "DeleteEventStream": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/eventstream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EventStream": {
            "shape": "S38"
          }
        },
        "required": [
          "EventStream"
        ],
        "payload": "EventStream"
      }
    },
    "DeleteGcmChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/gcm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "GCMChannelResponse": {
            "shape": "S3b"
          }
        },
        "required": [
          "GCMChannelResponse"
        ],
        "payload": "GCMChannelResponse"
      }
    },
    "DeleteSegment": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S24"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "DeleteSmsChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/sms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SMSChannelResponse": {
            "shape": "S3g"
          }
        },
        "required": [
          "SMSChannelResponse"
        ],
        "payload": "SMSChannelResponse"
      }
    },
    "DeleteUserEndpoints": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/users/{user-id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "UserId": {
            "location": "uri",
            "locationName": "user-id"
          }
        },
        "required": [
          "ApplicationId",
          "UserId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointsResponse": {
            "shape": "S3j"
          }
        },
        "required": [
          "EndpointsResponse"
        ],
        "payload": "EndpointsResponse"
      }
    },
    "DeleteVoiceChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/apps/{application-id}/channels/voice",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceChannelResponse": {
            "shape": "S3n"
          }
        },
        "required": [
          "VoiceChannelResponse"
        ],
        "payload": "VoiceChannelResponse"
      }
    },
    "GetAdmChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/adm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ADMChannelResponse": {
            "shape": "S2a"
          }
        },
        "required": [
          "ADMChannelResponse"
        ],
        "payload": "ADMChannelResponse"
      }
    },
    "GetApnsChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSChannelResponse": {
            "shape": "S2d"
          }
        },
        "required": [
          "APNSChannelResponse"
        ],
        "payload": "APNSChannelResponse"
      }
    },
    "GetApnsSandboxChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelResponse": {
            "shape": "S2g"
          }
        },
        "required": [
          "APNSSandboxChannelResponse"
        ],
        "payload": "APNSSandboxChannelResponse"
      }
    },
    "GetApnsVoipChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipChannelResponse": {
            "shape": "S2j"
          }
        },
        "required": [
          "APNSVoipChannelResponse"
        ],
        "payload": "APNSVoipChannelResponse"
      }
    },
    "GetApnsVoipSandboxChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelResponse": {
            "shape": "S2m"
          }
        },
        "required": [
          "APNSVoipSandboxChannelResponse"
        ],
        "payload": "APNSVoipSandboxChannelResponse"
      }
    },
    "GetApp": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationResponse": {
            "shape": "S6"
          }
        },
        "required": [
          "ApplicationResponse"
        ],
        "payload": "ApplicationResponse"
      }
    },
    "GetApplicationDateRangeKpi": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/kpis/daterange/{kpi-name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndTime": {
            "shape": "S41",
            "location": "querystring",
            "locationName": "end-time"
          },
          "KpiName": {
            "location": "uri",
            "locationName": "kpi-name"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "StartTime": {
            "shape": "S41",
            "location": "querystring",
            "locationName": "start-time"
          }
        },
        "required": [
          "ApplicationId",
          "KpiName"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationDateRangeKpiResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "EndTime": {
                "shape": "S41"
              },
              "KpiName": {},
              "KpiResult": {
                "shape": "S44"
              },
              "NextToken": {},
              "StartTime": {
                "shape": "S41"
              }
            },
            "required": [
              "KpiResult",
              "KpiName",
              "EndTime",
              "StartTime",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "ApplicationDateRangeKpiResponse"
        ],
        "payload": "ApplicationDateRangeKpiResponse"
      }
    },
    "GetApplicationSettings": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationSettingsResource": {
            "shape": "S4b"
          }
        },
        "required": [
          "ApplicationSettingsResource"
        ],
        "payload": "ApplicationSettingsResource"
      }
    },
    "GetApps": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationsResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "shape": "S6"
                }
              },
              "NextToken": {}
            }
          }
        },
        "required": [
          "ApplicationsResponse"
        ],
        "payload": "ApplicationsResponse"
      }
    },
    "GetBaiduChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/baidu",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "BaiduChannelResponse": {
            "shape": "S2r"
          }
        },
        "required": [
          "BaiduChannelResponse"
        ],
        "payload": "BaiduChannelResponse"
      }
    },
    "GetCampaign": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          }
        },
        "required": [
          "CampaignId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S12"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "GetCampaignActivities": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/activities",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ActivitiesResponse": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ApplicationId": {},
                    "CampaignId": {},
                    "End": {},
                    "Id": {},
                    "Result": {},
                    "ScheduledStart": {},
                    "Start": {},
                    "State": {},
                    "SuccessfulEndpointCount": {
                      "type": "integer"
                    },
                    "TimezonesCompletedCount": {
                      "type": "integer"
                    },
                    "TimezonesTotalCount": {
                      "type": "integer"
                    },
                    "TotalEndpointCount": {
                      "type": "integer"
                    },
                    "TreatmentId": {}
                  },
                  "required": [
                    "CampaignId",
                    "Id",
                    "ApplicationId"
                  ]
                }
              },
              "NextToken": {}
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "ActivitiesResponse"
        ],
        "payload": "ActivitiesResponse"
      }
    },
    "GetCampaignDateRangeKpi": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/kpis/daterange/{kpi-name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "EndTime": {
            "shape": "S41",
            "location": "querystring",
            "locationName": "end-time"
          },
          "KpiName": {
            "location": "uri",
            "locationName": "kpi-name"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "next-token"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "StartTime": {
            "shape": "S41",
            "location": "querystring",
            "locationName": "start-time"
          }
        },
        "required": [
          "ApplicationId",
          "KpiName",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignDateRangeKpiResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "CampaignId": {},
              "EndTime": {
                "shape": "S41"
              },
              "KpiName": {},
              "KpiResult": {
                "shape": "S44"
              },
              "NextToken": {},
              "StartTime": {
                "shape": "S41"
              }
            },
            "required": [
              "KpiResult",
              "KpiName",
              "EndTime",
              "CampaignId",
              "StartTime",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "CampaignDateRangeKpiResponse"
        ],
        "payload": "CampaignDateRangeKpiResponse"
      }
    },
    "GetCampaignVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/versions/{version}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "Version": {
            "location": "uri",
            "locationName": "version"
          }
        },
        "required": [
          "Version",
          "ApplicationId",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S12"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "GetCampaignVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId",
          "CampaignId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignsResponse": {
            "shape": "S4w"
          }
        },
        "required": [
          "CampaignsResponse"
        ],
        "payload": "CampaignsResponse"
      }
    },
    "GetCampaigns": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/campaigns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignsResponse": {
            "shape": "S4w"
          }
        },
        "required": [
          "CampaignsResponse"
        ],
        "payload": "CampaignsResponse"
      }
    },
    "GetChannels": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ChannelsResponse": {
            "type": "structure",
            "members": {
              "Channels": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "ApplicationId": {},
                    "CreationDate": {},
                    "Enabled": {
                      "type": "boolean"
                    },
                    "HasCredential": {
                      "type": "boolean"
                    },
                    "Id": {},
                    "IsArchived": {
                      "type": "boolean"
                    },
                    "LastModifiedBy": {},
                    "LastModifiedDate": {},
                    "Version": {
                      "type": "integer"
                    }
                  }
                }
              }
            },
            "required": [
              "Channels"
            ]
          }
        },
        "required": [
          "ChannelsResponse"
        ],
        "payload": "ChannelsResponse"
      }
    },
    "GetEmailChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/email",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EmailChannelResponse": {
            "shape": "S2w"
          }
        },
        "required": [
          "EmailChannelResponse"
        ],
        "payload": "EmailChannelResponse"
      }
    },
    "GetEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/endpoints/{endpoint-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointId": {
            "location": "uri",
            "locationName": "endpoint-id"
          }
        },
        "required": [
          "ApplicationId",
          "EndpointId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointResponse": {
            "shape": "S2z"
          }
        },
        "required": [
          "EndpointResponse"
        ],
        "payload": "EndpointResponse"
      }
    },
    "GetEventStream": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/eventstream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EventStream": {
            "shape": "S38"
          }
        },
        "required": [
          "EventStream"
        ],
        "payload": "EventStream"
      }
    },
    "GetExportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/export/{job-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "job-id"
          }
        },
        "required": [
          "ApplicationId",
          "JobId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobResponse": {
            "shape": "S1a"
          }
        },
        "required": [
          "ExportJobResponse"
        ],
        "payload": "ExportJobResponse"
      }
    },
    "GetExportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/export",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobsResponse": {
            "shape": "S5f"
          }
        },
        "required": [
          "ExportJobsResponse"
        ],
        "payload": "ExportJobsResponse"
      }
    },
    "GetGcmChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/gcm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "GCMChannelResponse": {
            "shape": "S3b"
          }
        },
        "required": [
          "GCMChannelResponse"
        ],
        "payload": "GCMChannelResponse"
      }
    },
    "GetImportJob": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/import/{job-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "job-id"
          }
        },
        "required": [
          "ApplicationId",
          "JobId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobResponse": {
            "shape": "S1h"
          }
        },
        "required": [
          "ImportJobResponse"
        ],
        "payload": "ImportJobResponse"
      }
    },
    "GetImportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/jobs/import",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobsResponse": {
            "shape": "S5n"
          }
        },
        "required": [
          "ImportJobsResponse"
        ],
        "payload": "ImportJobsResponse"
      }
    },
    "GetSegment": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S24"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "GetSegmentExportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/jobs/export",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ExportJobsResponse": {
            "shape": "S5f"
          }
        },
        "required": [
          "ExportJobsResponse"
        ],
        "payload": "ExportJobsResponse"
      }
    },
    "GetSegmentImportJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/jobs/import",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ImportJobsResponse": {
            "shape": "S5n"
          }
        },
        "required": [
          "ImportJobsResponse"
        ],
        "payload": "ImportJobsResponse"
      }
    },
    "GetSegmentVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/versions/{version}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Version": {
            "location": "uri",
            "locationName": "version"
          }
        },
        "required": [
          "SegmentId",
          "Version",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S24"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "GetSegmentVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}/versions",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentsResponse": {
            "shape": "S5z"
          }
        },
        "required": [
          "SegmentsResponse"
        ],
        "payload": "SegmentsResponse"
      }
    },
    "GetSegments": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/segments",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "PageSize": {
            "location": "querystring",
            "locationName": "page-size"
          },
          "Token": {
            "location": "querystring",
            "locationName": "token"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentsResponse": {
            "shape": "S5z"
          }
        },
        "required": [
          "SegmentsResponse"
        ],
        "payload": "SegmentsResponse"
      }
    },
    "GetSmsChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/sms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "SMSChannelResponse": {
            "shape": "S3g"
          }
        },
        "required": [
          "SMSChannelResponse"
        ],
        "payload": "SMSChannelResponse"
      }
    },
    "GetUserEndpoints": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/users/{user-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "UserId": {
            "location": "uri",
            "locationName": "user-id"
          }
        },
        "required": [
          "ApplicationId",
          "UserId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointsResponse": {
            "shape": "S3j"
          }
        },
        "required": [
          "EndpointsResponse"
        ],
        "payload": "EndpointsResponse"
      }
    },
    "GetVoiceChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/apps/{application-id}/channels/voice",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceChannelResponse": {
            "shape": "S3n"
          }
        },
        "required": [
          "VoiceChannelResponse"
        ],
        "payload": "VoiceChannelResponse"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/tags/{resource-arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          }
        },
        "required": [
          "ResourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "TagsModel": {
            "shape": "S6b"
          }
        },
        "required": [
          "TagsModel"
        ],
        "payload": "TagsModel"
      }
    },
    "PhoneNumberValidate": {
      "http": {
        "requestUri": "/v1/phone/number/validate",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "NumberValidateRequest": {
            "type": "structure",
            "members": {
              "IsoCountryCode": {},
              "PhoneNumber": {}
            }
          }
        },
        "required": [
          "NumberValidateRequest"
        ],
        "payload": "NumberValidateRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "NumberValidateResponse": {
            "type": "structure",
            "members": {
              "Carrier": {},
              "City": {},
              "CleansedPhoneNumberE164": {},
              "CleansedPhoneNumberNational": {},
              "Country": {},
              "CountryCodeIso2": {},
              "CountryCodeNumeric": {},
              "County": {},
              "OriginalCountryCodeIso2": {},
              "OriginalPhoneNumber": {},
              "PhoneType": {},
              "PhoneTypeCode": {
                "type": "integer"
              },
              "Timezone": {},
              "ZipCode": {}
            }
          }
        },
        "required": [
          "NumberValidateResponse"
        ],
        "payload": "NumberValidateResponse"
      }
    },
    "PutEventStream": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/eventstream",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteEventStream": {
            "type": "structure",
            "members": {
              "DestinationStreamArn": {},
              "RoleArn": {}
            },
            "required": [
              "RoleArn",
              "DestinationStreamArn"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "WriteEventStream"
        ],
        "payload": "WriteEventStream"
      },
      "output": {
        "type": "structure",
        "members": {
          "EventStream": {
            "shape": "S38"
          }
        },
        "required": [
          "EventStream"
        ],
        "payload": "EventStream"
      }
    },
    "PutEvents": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/events",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EventsRequest": {
            "type": "structure",
            "members": {
              "BatchItem": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "Endpoint": {
                      "type": "structure",
                      "members": {
                        "Address": {},
                        "Attributes": {
                          "shape": "S30"
                        },
                        "ChannelType": {},
                        "Demographic": {
                          "shape": "S32"
                        },
                        "EffectiveDate": {},
                        "EndpointStatus": {},
                        "Location": {
                          "shape": "S33"
                        },
                        "Metrics": {
                          "shape": "S34"
                        },
                        "OptOut": {},
                        "RequestId": {},
                        "User": {
                          "shape": "S35"
                        }
                      }
                    },
                    "Events": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "structure",
                        "members": {
                          "AppPackageName": {},
                          "AppTitle": {},
                          "AppVersionCode": {},
                          "Attributes": {
                            "shape": "S4"
                          },
                          "ClientSdkVersion": {},
                          "EventType": {},
                          "Metrics": {
                            "shape": "S34"
                          },
                          "SdkName": {},
                          "Session": {
                            "type": "structure",
                            "members": {
                              "Duration": {
                                "type": "integer"
                              },
                              "Id": {},
                              "StartTimestamp": {},
                              "StopTimestamp": {}
                            },
                            "required": [
                              "StartTimestamp",
                              "Id"
                            ]
                          },
                          "Timestamp": {}
                        },
                        "required": [
                          "EventType",
                          "Timestamp"
                        ]
                      }
                    }
                  },
                  "required": [
                    "Endpoint",
                    "Events"
                  ]
                }
              }
            },
            "required": [
              "BatchItem"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "EventsRequest"
        ],
        "payload": "EventsRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "EventsResponse": {
            "type": "structure",
            "members": {
              "Results": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "EndpointItemResponse": {
                      "type": "structure",
                      "members": {
                        "Message": {},
                        "StatusCode": {
                          "type": "integer"
                        }
                      }
                    },
                    "EventsItemResponse": {
                      "type": "map",
                      "key": {},
                      "value": {
                        "type": "structure",
                        "members": {
                          "Message": {},
                          "StatusCode": {
                            "type": "integer"
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "required": [
          "EventsResponse"
        ],
        "payload": "EventsResponse"
      }
    },
    "RemoveAttributes": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/attributes/{attribute-type}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "AttributeType": {
            "location": "uri",
            "locationName": "attribute-type"
          },
          "UpdateAttributesRequest": {
            "type": "structure",
            "members": {
              "Blacklist": {
                "shape": "Sp"
              }
            }
          }
        },
        "required": [
          "AttributeType",
          "ApplicationId",
          "UpdateAttributesRequest"
        ],
        "payload": "UpdateAttributesRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "AttributesResource": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "AttributeType": {},
              "Attributes": {
                "shape": "Sp"
              }
            },
            "required": [
              "AttributeType",
              "ApplicationId"
            ]
          }
        },
        "required": [
          "AttributesResource"
        ],
        "payload": "AttributesResource"
      }
    },
    "SendMessages": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/messages",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "MessageRequest": {
            "type": "structure",
            "members": {
              "Addresses": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "BodyOverride": {},
                    "ChannelType": {},
                    "Context": {
                      "shape": "S4"
                    },
                    "RawContent": {},
                    "Substitutions": {
                      "shape": "S30"
                    },
                    "TitleOverride": {}
                  }
                }
              },
              "Context": {
                "shape": "S4"
              },
              "Endpoints": {
                "shape": "S76"
              },
              "MessageConfiguration": {
                "shape": "S78"
              },
              "TraceId": {}
            },
            "required": [
              "MessageConfiguration"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "MessageRequest"
        ],
        "payload": "MessageRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "EndpointResult": {
                "shape": "S7o"
              },
              "RequestId": {},
              "Result": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "structure",
                  "members": {
                    "DeliveryStatus": {},
                    "MessageId": {},
                    "StatusCode": {
                      "type": "integer"
                    },
                    "StatusMessage": {},
                    "UpdatedToken": {}
                  },
                  "required": [
                    "DeliveryStatus",
                    "StatusCode"
                  ]
                }
              }
            },
            "required": [
              "ApplicationId"
            ]
          }
        },
        "required": [
          "MessageResponse"
        ],
        "payload": "MessageResponse"
      }
    },
    "SendUsersMessages": {
      "http": {
        "requestUri": "/v1/apps/{application-id}/users-messages",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SendUsersMessageRequest": {
            "type": "structure",
            "members": {
              "Context": {
                "shape": "S4"
              },
              "MessageConfiguration": {
                "shape": "S78"
              },
              "TraceId": {},
              "Users": {
                "shape": "S76"
              }
            },
            "required": [
              "MessageConfiguration",
              "Users"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "SendUsersMessageRequest"
        ],
        "payload": "SendUsersMessageRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SendUsersMessageResponse": {
            "type": "structure",
            "members": {
              "ApplicationId": {},
              "RequestId": {},
              "Result": {
                "type": "map",
                "key": {},
                "value": {
                  "shape": "S7o"
                }
              }
            },
            "required": [
              "ApplicationId"
            ]
          }
        },
        "required": [
          "SendUsersMessageResponse"
        ],
        "payload": "SendUsersMessageResponse"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/v1/tags/{resource-arn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          },
          "TagsModel": {
            "shape": "S6b"
          }
        },
        "required": [
          "ResourceArn",
          "TagsModel"
        ],
        "payload": "TagsModel"
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/tags/{resource-arn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          },
          "TagKeys": {
            "shape": "Sp",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        },
        "required": [
          "TagKeys",
          "ResourceArn"
        ]
      }
    },
    "UpdateAdmChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/adm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ADMChannelRequest": {
            "type": "structure",
            "members": {
              "ClientId": {},
              "ClientSecret": {},
              "Enabled": {
                "type": "boolean"
              }
            },
            "required": [
              "ClientSecret",
              "ClientId"
            ]
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "ADMChannelRequest"
        ],
        "payload": "ADMChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ADMChannelResponse": {
            "shape": "S2a"
          }
        },
        "required": [
          "ADMChannelResponse"
        ],
        "payload": "ADMChannelResponse"
      }
    },
    "UpdateApnsChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSChannelRequest"
        ],
        "payload": "APNSChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSChannelResponse": {
            "shape": "S2d"
          }
        },
        "required": [
          "APNSChannelResponse"
        ],
        "payload": "APNSChannelResponse"
      }
    },
    "UpdateApnsSandboxChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSSandboxChannelRequest"
        ],
        "payload": "APNSSandboxChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSSandboxChannelResponse": {
            "shape": "S2g"
          }
        },
        "required": [
          "APNSSandboxChannelResponse"
        ],
        "payload": "APNSSandboxChannelResponse"
      }
    },
    "UpdateApnsVoipChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSVoipChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSVoipChannelRequest"
        ],
        "payload": "APNSVoipChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipChannelResponse": {
            "shape": "S2j"
          }
        },
        "required": [
          "APNSVoipChannelResponse"
        ],
        "payload": "APNSVoipChannelResponse"
      }
    },
    "UpdateApnsVoipSandboxChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/apns_voip_sandbox",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelRequest": {
            "type": "structure",
            "members": {
              "BundleId": {},
              "Certificate": {},
              "DefaultAuthenticationMethod": {},
              "Enabled": {
                "type": "boolean"
              },
              "PrivateKey": {},
              "TeamId": {},
              "TokenKey": {},
              "TokenKeyId": {}
            }
          },
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          }
        },
        "required": [
          "ApplicationId",
          "APNSVoipSandboxChannelRequest"
        ],
        "payload": "APNSVoipSandboxChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "APNSVoipSandboxChannelResponse": {
            "shape": "S2m"
          }
        },
        "required": [
          "APNSVoipSandboxChannelResponse"
        ],
        "payload": "APNSVoipSandboxChannelResponse"
      }
    },
    "UpdateApplicationSettings": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/settings",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "WriteApplicationSettingsRequest": {
            "type": "structure",
            "members": {
              "CampaignHook": {
                "shape": "Sy"
              },
              "CloudWatchMetricsEnabled": {
                "type": "boolean"
              },
              "Limits": {
                "shape": "S10"
              },
              "QuietTime": {
                "shape": "Sx"
              }
            }
          }
        },
        "required": [
          "ApplicationId",
          "WriteApplicationSettingsRequest"
        ],
        "payload": "WriteApplicationSettingsRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationSettingsResource": {
            "shape": "S4b"
          }
        },
        "required": [
          "ApplicationSettingsResource"
        ],
        "payload": "ApplicationSettingsResource"
      }
    },
    "UpdateBaiduChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/baidu",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "BaiduChannelRequest": {
            "type": "structure",
            "members": {
              "ApiKey": {},
              "Enabled": {
                "type": "boolean"
              },
              "SecretKey": {}
            },
            "required": [
              "SecretKey",
              "ApiKey"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "BaiduChannelRequest"
        ],
        "payload": "BaiduChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "BaiduChannelResponse": {
            "shape": "S2r"
          }
        },
        "required": [
          "BaiduChannelResponse"
        ],
        "payload": "BaiduChannelResponse"
      }
    },
    "UpdateCampaign": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/campaigns/{campaign-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "CampaignId": {
            "location": "uri",
            "locationName": "campaign-id"
          },
          "WriteCampaignRequest": {
            "shape": "S8"
          }
        },
        "required": [
          "CampaignId",
          "ApplicationId",
          "WriteCampaignRequest"
        ],
        "payload": "WriteCampaignRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "CampaignResponse": {
            "shape": "S12"
          }
        },
        "required": [
          "CampaignResponse"
        ],
        "payload": "CampaignResponse"
      }
    },
    "UpdateEmailChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/email",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EmailChannelRequest": {
            "type": "structure",
            "members": {
              "ConfigurationSet": {},
              "Enabled": {
                "type": "boolean"
              },
              "FromAddress": {},
              "Identity": {},
              "RoleArn": {}
            },
            "required": [
              "FromAddress",
              "Identity"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "EmailChannelRequest"
        ],
        "payload": "EmailChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "EmailChannelResponse": {
            "shape": "S2w"
          }
        },
        "required": [
          "EmailChannelResponse"
        ],
        "payload": "EmailChannelResponse"
      }
    },
    "UpdateEndpoint": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/endpoints/{endpoint-id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointId": {
            "location": "uri",
            "locationName": "endpoint-id"
          },
          "EndpointRequest": {
            "type": "structure",
            "members": {
              "Address": {},
              "Attributes": {
                "shape": "S30"
              },
              "ChannelType": {},
              "Demographic": {
                "shape": "S32"
              },
              "EffectiveDate": {},
              "EndpointStatus": {},
              "Location": {
                "shape": "S33"
              },
              "Metrics": {
                "shape": "S34"
              },
              "OptOut": {},
              "RequestId": {},
              "User": {
                "shape": "S35"
              }
            }
          }
        },
        "required": [
          "ApplicationId",
          "EndpointId",
          "EndpointRequest"
        ],
        "payload": "EndpointRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S8t"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateEndpointsBatch": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/endpoints",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "EndpointBatchRequest": {
            "type": "structure",
            "members": {
              "Item": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Address": {},
                    "Attributes": {
                      "shape": "S30"
                    },
                    "ChannelType": {},
                    "Demographic": {
                      "shape": "S32"
                    },
                    "EffectiveDate": {},
                    "EndpointStatus": {},
                    "Id": {},
                    "Location": {
                      "shape": "S33"
                    },
                    "Metrics": {
                      "shape": "S34"
                    },
                    "OptOut": {},
                    "RequestId": {},
                    "User": {
                      "shape": "S35"
                    }
                  }
                }
              }
            },
            "required": [
              "Item"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "EndpointBatchRequest"
        ],
        "payload": "EndpointBatchRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "MessageBody": {
            "shape": "S8t"
          }
        },
        "required": [
          "MessageBody"
        ],
        "payload": "MessageBody"
      }
    },
    "UpdateGcmChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/gcm",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "GCMChannelRequest": {
            "type": "structure",
            "members": {
              "ApiKey": {},
              "Enabled": {
                "type": "boolean"
              }
            },
            "required": [
              "ApiKey"
            ]
          }
        },
        "required": [
          "ApplicationId",
          "GCMChannelRequest"
        ],
        "payload": "GCMChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "GCMChannelResponse": {
            "shape": "S3b"
          }
        },
        "required": [
          "GCMChannelResponse"
        ],
        "payload": "GCMChannelResponse"
      }
    },
    "UpdateSegment": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/segments/{segment-id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SegmentId": {
            "location": "uri",
            "locationName": "segment-id"
          },
          "WriteSegmentRequest": {
            "shape": "S1k"
          }
        },
        "required": [
          "SegmentId",
          "ApplicationId",
          "WriteSegmentRequest"
        ],
        "payload": "WriteSegmentRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SegmentResponse": {
            "shape": "S24"
          }
        },
        "required": [
          "SegmentResponse"
        ],
        "payload": "SegmentResponse"
      }
    },
    "UpdateSmsChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/sms",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "SMSChannelRequest": {
            "type": "structure",
            "members": {
              "Enabled": {
                "type": "boolean"
              },
              "SenderId": {},
              "ShortCode": {}
            }
          }
        },
        "required": [
          "ApplicationId",
          "SMSChannelRequest"
        ],
        "payload": "SMSChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "SMSChannelResponse": {
            "shape": "S3g"
          }
        },
        "required": [
          "SMSChannelResponse"
        ],
        "payload": "SMSChannelResponse"
      }
    },
    "UpdateVoiceChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/apps/{application-id}/channels/voice",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ApplicationId": {
            "location": "uri",
            "locationName": "application-id"
          },
          "VoiceChannelRequest": {
            "type": "structure",
            "members": {
              "Enabled": {
                "type": "boolean"
              }
            }
          }
        },
        "required": [
          "ApplicationId",
          "VoiceChannelRequest"
        ],
        "payload": "VoiceChannelRequest"
      },
      "output": {
        "type": "structure",
        "members": {
          "VoiceChannelResponse": {
            "shape": "S3n"
          }
        },
        "required": [
          "VoiceChannelResponse"
        ],
        "payload": "VoiceChannelResponse"
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S6": {
      "type": "structure",
      "members": {
        "Arn": {},
        "Id": {},
        "Name": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        }
      },
      "required": [
        "Id",
        "Arn",
        "Name"
      ]
    },
    "S8": {
      "type": "structure",
      "members": {
        "AdditionalTreatments": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "MessageConfiguration": {
                "shape": "Sb"
              },
              "Schedule": {
                "shape": "Sj"
              },
              "SizePercent": {
                "type": "integer"
              },
              "TreatmentDescription": {},
              "TreatmentName": {}
            },
            "required": [
              "SizePercent"
            ]
          }
        },
        "Description": {},
        "HoldoutPercent": {
          "type": "integer"
        },
        "Hook": {
          "shape": "Sy"
        },
        "IsPaused": {
          "type": "boolean"
        },
        "Limits": {
          "shape": "S10"
        },
        "MessageConfiguration": {
          "shape": "Sb"
        },
        "Name": {},
        "Schedule": {
          "shape": "Sj"
        },
        "SegmentId": {},
        "SegmentVersion": {
          "type": "integer"
        },
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TreatmentDescription": {},
        "TreatmentName": {}
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "ADMMessage": {
          "shape": "Sc"
        },
        "APNSMessage": {
          "shape": "Sc"
        },
        "BaiduMessage": {
          "shape": "Sc"
        },
        "DefaultMessage": {
          "shape": "Sc"
        },
        "EmailMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "FromAddress": {},
            "HtmlBody": {},
            "Title": {}
          },
          "required": [
            "Title"
          ]
        },
        "GCMMessage": {
          "shape": "Sc"
        },
        "SMSMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "MessageType": {},
            "SenderId": {}
          }
        }
      }
    },
    "Sc": {
      "type": "structure",
      "members": {
        "Action": {},
        "Body": {},
        "ImageIconUrl": {},
        "ImageSmallIconUrl": {},
        "ImageUrl": {},
        "JsonBody": {},
        "MediaUrl": {},
        "RawContent": {},
        "SilentPush": {
          "type": "boolean"
        },
        "TimeToLive": {
          "type": "integer"
        },
        "Title": {},
        "Url": {}
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "EndTime": {},
        "EventFilter": {
          "type": "structure",
          "members": {
            "Dimensions": {
              "type": "structure",
              "members": {
                "Attributes": {
                  "shape": "Sm"
                },
                "EventType": {
                  "shape": "Sq"
                },
                "Metrics": {
                  "shape": "Ss"
                }
              }
            },
            "FilterType": {}
          },
          "required": [
            "FilterType",
            "Dimensions"
          ]
        },
        "Frequency": {},
        "IsLocalTime": {
          "type": "boolean"
        },
        "QuietTime": {
          "shape": "Sx"
        },
        "StartTime": {},
        "Timezone": {}
      },
      "required": [
        "StartTime"
      ]
    },
    "Sm": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "AttributeType": {},
          "Values": {
            "shape": "Sp"
          }
        },
        "required": [
          "Values"
        ]
      }
    },
    "Sp": {
      "type": "list",
      "member": {}
    },
    "Sq": {
      "type": "structure",
      "members": {
        "DimensionType": {},
        "Values": {
          "shape": "Sp"
        }
      },
      "required": [
        "Values"
      ]
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "ComparisonOperator": {},
          "Value": {
            "type": "double"
          }
        },
        "required": [
          "ComparisonOperator",
          "Value"
        ]
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "End": {},
        "Start": {}
      }
    },
    "Sy": {
      "type": "structure",
      "members": {
        "LambdaFunctionName": {},
        "Mode": {},
        "WebUrl": {}
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "Daily": {
          "type": "integer"
        },
        "MaximumDuration": {
          "type": "integer"
        },
        "MessagesPerSecond": {
          "type": "integer"
        },
        "Total": {
          "type": "integer"
        }
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "AdditionalTreatments": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id": {},
              "MessageConfiguration": {
                "shape": "Sb"
              },
              "Schedule": {
                "shape": "Sj"
              },
              "SizePercent": {
                "type": "integer"
              },
              "State": {
                "shape": "S15"
              },
              "TreatmentDescription": {},
              "TreatmentName": {}
            },
            "required": [
              "Id",
              "SizePercent"
            ]
          }
        },
        "ApplicationId": {},
        "Arn": {},
        "CreationDate": {},
        "DefaultState": {
          "shape": "S15"
        },
        "Description": {},
        "HoldoutPercent": {
          "type": "integer"
        },
        "Hook": {
          "shape": "Sy"
        },
        "Id": {},
        "IsPaused": {
          "type": "boolean"
        },
        "LastModifiedDate": {},
        "Limits": {
          "shape": "S10"
        },
        "MessageConfiguration": {
          "shape": "Sb"
        },
        "Name": {},
        "Schedule": {
          "shape": "Sj"
        },
        "SegmentId": {},
        "SegmentVersion": {
          "type": "integer"
        },
        "State": {
          "shape": "S15"
        },
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "TreatmentDescription": {},
        "TreatmentName": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "LastModifiedDate",
        "CreationDate",
        "SegmentId",
        "SegmentVersion",
        "Id",
        "Arn",
        "ApplicationId"
      ]
    },
    "S15": {
      "type": "structure",
      "members": {
        "CampaignStatus": {}
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CompletedPieces": {
          "type": "integer"
        },
        "CompletionDate": {},
        "CreationDate": {},
        "Definition": {
          "type": "structure",
          "members": {
            "RoleArn": {},
            "S3UrlPrefix": {},
            "SegmentId": {},
            "SegmentVersion": {
              "type": "integer"
            }
          },
          "required": [
            "S3UrlPrefix",
            "RoleArn"
          ]
        },
        "FailedPieces": {
          "type": "integer"
        },
        "Failures": {
          "shape": "Sp"
        },
        "Id": {},
        "JobStatus": {},
        "TotalFailures": {
          "type": "integer"
        },
        "TotalPieces": {
          "type": "integer"
        },
        "TotalProcessed": {
          "type": "integer"
        },
        "Type": {}
      },
      "required": [
        "JobStatus",
        "CreationDate",
        "Type",
        "Definition",
        "Id",
        "ApplicationId"
      ]
    },
    "S1h": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CompletedPieces": {
          "type": "integer"
        },
        "CompletionDate": {},
        "CreationDate": {},
        "Definition": {
          "type": "structure",
          "members": {
            "DefineSegment": {
              "type": "boolean"
            },
            "ExternalId": {},
            "Format": {},
            "RegisterEndpoints": {
              "type": "boolean"
            },
            "RoleArn": {},
            "S3Url": {},
            "SegmentId": {},
            "SegmentName": {}
          },
          "required": [
            "Format",
            "S3Url",
            "RoleArn"
          ]
        },
        "FailedPieces": {
          "type": "integer"
        },
        "Failures": {
          "shape": "Sp"
        },
        "Id": {},
        "JobStatus": {},
        "TotalFailures": {
          "type": "integer"
        },
        "TotalPieces": {
          "type": "integer"
        },
        "TotalProcessed": {
          "type": "integer"
        },
        "Type": {}
      },
      "required": [
        "JobStatus",
        "CreationDate",
        "Type",
        "Definition",
        "Id",
        "ApplicationId"
      ]
    },
    "S1k": {
      "type": "structure",
      "members": {
        "Dimensions": {
          "shape": "S1l"
        },
        "Name": {},
        "SegmentGroups": {
          "shape": "S1u"
        },
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        }
      }
    },
    "S1l": {
      "type": "structure",
      "members": {
        "Attributes": {
          "shape": "Sm"
        },
        "Behavior": {
          "type": "structure",
          "members": {
            "Recency": {
              "type": "structure",
              "members": {
                "Duration": {},
                "RecencyType": {}
              },
              "required": [
                "Duration",
                "RecencyType"
              ]
            }
          }
        },
        "Demographic": {
          "type": "structure",
          "members": {
            "AppVersion": {
              "shape": "Sq"
            },
            "Channel": {
              "shape": "Sq"
            },
            "DeviceType": {
              "shape": "Sq"
            },
            "Make": {
              "shape": "Sq"
            },
            "Model": {
              "shape": "Sq"
            },
            "Platform": {
              "shape": "Sq"
            }
          }
        },
        "Location": {
          "type": "structure",
          "members": {
            "Country": {
              "shape": "Sq"
            },
            "GPSPoint": {
              "type": "structure",
              "members": {
                "Coordinates": {
                  "type": "structure",
                  "members": {
                    "Latitude": {
                      "type": "double"
                    },
                    "Longitude": {
                      "type": "double"
                    }
                  },
                  "required": [
                    "Latitude",
                    "Longitude"
                  ]
                },
                "RangeInKilometers": {
                  "type": "double"
                }
              },
              "required": [
                "Coordinates"
              ]
            }
          }
        },
        "Metrics": {
          "shape": "Ss"
        },
        "UserAttributes": {
          "shape": "Sm"
        }
      }
    },
    "S1u": {
      "type": "structure",
      "members": {
        "Groups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Dimensions": {
                "type": "list",
                "member": {
                  "shape": "S1l"
                }
              },
              "SourceSegments": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Id": {},
                    "Version": {
                      "type": "integer"
                    }
                  },
                  "required": [
                    "Id"
                  ]
                }
              },
              "SourceType": {},
              "Type": {}
            }
          }
        },
        "Include": {}
      }
    },
    "S24": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "Arn": {},
        "CreationDate": {},
        "Dimensions": {
          "shape": "S1l"
        },
        "Id": {},
        "ImportDefinition": {
          "type": "structure",
          "members": {
            "ChannelCounts": {
              "type": "map",
              "key": {},
              "value": {
                "type": "integer"
              }
            },
            "ExternalId": {},
            "Format": {},
            "RoleArn": {},
            "S3Url": {},
            "Size": {
              "type": "integer"
            }
          },
          "required": [
            "Format",
            "S3Url",
            "Size",
            "ExternalId",
            "RoleArn"
          ]
        },
        "LastModifiedDate": {},
        "Name": {},
        "SegmentGroups": {
          "shape": "S1u"
        },
        "SegmentType": {},
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        },
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "SegmentType",
        "CreationDate",
        "Id",
        "Arn",
        "ApplicationId"
      ]
    },
    "S2a": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S2d": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S2g": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S2j": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S2m": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "DefaultAuthenticationMethod": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "HasTokenKey": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S2r": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Credential": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Credential",
        "Platform"
      ]
    },
    "S2w": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "ConfigurationSet": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "FromAddress": {},
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "Identity": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "MessagesPerSecond": {
          "type": "integer"
        },
        "Platform": {},
        "RoleArn": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S2z": {
      "type": "structure",
      "members": {
        "Address": {},
        "ApplicationId": {},
        "Attributes": {
          "shape": "S30"
        },
        "ChannelType": {},
        "CohortId": {},
        "CreationDate": {},
        "Demographic": {
          "shape": "S32"
        },
        "EffectiveDate": {},
        "EndpointStatus": {},
        "Id": {},
        "Location": {
          "shape": "S33"
        },
        "Metrics": {
          "shape": "S34"
        },
        "OptOut": {},
        "RequestId": {},
        "User": {
          "shape": "S35"
        }
      }
    },
    "S30": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "Sp"
      }
    },
    "S32": {
      "type": "structure",
      "members": {
        "AppVersion": {},
        "Locale": {},
        "Make": {},
        "Model": {},
        "ModelVersion": {},
        "Platform": {},
        "PlatformVersion": {},
        "Timezone": {}
      }
    },
    "S33": {
      "type": "structure",
      "members": {
        "City": {},
        "Country": {},
        "Latitude": {
          "type": "double"
        },
        "Longitude": {
          "type": "double"
        },
        "PostalCode": {},
        "Region": {}
      }
    },
    "S34": {
      "type": "map",
      "key": {},
      "value": {
        "type": "double"
      }
    },
    "S35": {
      "type": "structure",
      "members": {
        "UserAttributes": {
          "shape": "S30"
        },
        "UserId": {}
      }
    },
    "S38": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "DestinationStreamArn": {},
        "ExternalId": {},
        "LastModifiedDate": {},
        "LastUpdatedBy": {},
        "RoleArn": {}
      },
      "required": [
        "ApplicationId",
        "RoleArn",
        "DestinationStreamArn"
      ]
    },
    "S3b": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Credential": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Credential",
        "Platform"
      ]
    },
    "S3g": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "PromotionalMessagesPerSecond": {
          "type": "integer"
        },
        "SenderId": {},
        "ShortCode": {},
        "TransactionalMessagesPerSecond": {
          "type": "integer"
        },
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S3j": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S2z"
          }
        }
      },
      "required": [
        "Item"
      ]
    },
    "S3n": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CreationDate": {},
        "Enabled": {
          "type": "boolean"
        },
        "HasCredential": {
          "type": "boolean"
        },
        "Id": {},
        "IsArchived": {
          "type": "boolean"
        },
        "LastModifiedBy": {},
        "LastModifiedDate": {},
        "Platform": {},
        "Version": {
          "type": "integer"
        }
      },
      "required": [
        "Platform"
      ]
    },
    "S41": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S44": {
      "type": "structure",
      "members": {
        "Rows": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "GroupedBys": {
                "shape": "S47"
              },
              "Values": {
                "shape": "S47"
              }
            },
            "required": [
              "GroupedBys",
              "Values"
            ]
          }
        }
      },
      "required": [
        "Rows"
      ]
    },
    "S47": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Type": {},
          "Value": {}
        },
        "required": [
          "Type",
          "Value",
          "Key"
        ]
      }
    },
    "S4b": {
      "type": "structure",
      "members": {
        "ApplicationId": {},
        "CampaignHook": {
          "shape": "Sy"
        },
        "LastModifiedDate": {},
        "Limits": {
          "shape": "S10"
        },
        "QuietTime": {
          "shape": "Sx"
        }
      },
      "required": [
        "ApplicationId"
      ]
    },
    "S4w": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S12"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "S5f": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S1a"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "S5n": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S1h"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "S5z": {
      "type": "structure",
      "members": {
        "Item": {
          "type": "list",
          "member": {
            "shape": "S24"
          }
        },
        "NextToken": {}
      },
      "required": [
        "Item"
      ]
    },
    "S6b": {
      "type": "structure",
      "members": {
        "tags": {
          "shape": "S4",
          "locationName": "tags"
        }
      },
      "required": [
        "tags"
      ]
    },
    "S76": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "BodyOverride": {},
          "Context": {
            "shape": "S4"
          },
          "RawContent": {},
          "Substitutions": {
            "shape": "S30"
          },
          "TitleOverride": {}
        }
      }
    },
    "S78": {
      "type": "structure",
      "members": {
        "ADMMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "ConsolidationKey": {},
            "Data": {
              "shape": "S4"
            },
            "ExpiresAfter": {},
            "IconReference": {},
            "ImageIconUrl": {},
            "ImageUrl": {},
            "MD5": {},
            "RawContent": {},
            "SilentPush": {
              "type": "boolean"
            },
            "SmallImageIconUrl": {},
            "Sound": {},
            "Substitutions": {
              "shape": "S30"
            },
            "Title": {},
            "Url": {}
          }
        },
        "APNSMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Badge": {
              "type": "integer"
            },
            "Body": {},
            "Category": {},
            "CollapseId": {},
            "Data": {
              "shape": "S4"
            },
            "MediaUrl": {},
            "PreferredAuthenticationMethod": {},
            "Priority": {},
            "RawContent": {},
            "SilentPush": {
              "type": "boolean"
            },
            "Sound": {},
            "Substitutions": {
              "shape": "S30"
            },
            "ThreadId": {},
            "TimeToLive": {
              "type": "integer"
            },
            "Title": {},
            "Url": {}
          }
        },
        "BaiduMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "Data": {
              "shape": "S4"
            },
            "IconReference": {},
            "ImageIconUrl": {},
            "ImageUrl": {},
            "RawContent": {},
            "SilentPush": {
              "type": "boolean"
            },
            "SmallImageIconUrl": {},
            "Sound": {},
            "Substitutions": {
              "shape": "S30"
            },
            "TimeToLive": {
              "type": "integer"
            },
            "Title": {},
            "Url": {}
          }
        },
        "DefaultMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "Substitutions": {
              "shape": "S30"
            }
          }
        },
        "DefaultPushNotificationMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "Data": {
              "shape": "S4"
            },
            "SilentPush": {
              "type": "boolean"
            },
            "Substitutions": {
              "shape": "S30"
            },
            "Title": {},
            "Url": {}
          }
        },
        "EmailMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "FeedbackForwardingAddress": {},
            "FromAddress": {},
            "RawEmail": {
              "type": "structure",
              "members": {
                "Data": {
                  "type": "blob"
                }
              }
            },
            "ReplyToAddresses": {
              "shape": "Sp"
            },
            "SimpleEmail": {
              "type": "structure",
              "members": {
                "HtmlPart": {
                  "shape": "S7i"
                },
                "Subject": {
                  "shape": "S7i"
                },
                "TextPart": {
                  "shape": "S7i"
                }
              }
            },
            "Substitutions": {
              "shape": "S30"
            }
          }
        },
        "GCMMessage": {
          "type": "structure",
          "members": {
            "Action": {},
            "Body": {},
            "CollapseKey": {},
            "Data": {
              "shape": "S4"
            },
            "IconReference": {},
            "ImageIconUrl": {},
            "ImageUrl": {},
            "Priority": {},
            "RawContent": {},
            "RestrictedPackageName": {},
            "SilentPush": {
              "type": "boolean"
            },
            "SmallImageIconUrl": {},
            "Sound": {},
            "Substitutions": {
              "shape": "S30"
            },
            "TimeToLive": {
              "type": "integer"
            },
            "Title": {},
            "Url": {}
          }
        },
        "SMSMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "Keyword": {},
            "MessageType": {},
            "OriginationNumber": {},
            "SenderId": {},
            "Substitutions": {
              "shape": "S30"
            }
          }
        },
        "VoiceMessage": {
          "type": "structure",
          "members": {
            "Body": {},
            "LanguageCode": {},
            "OriginationNumber": {},
            "Substitutions": {
              "shape": "S30"
            },
            "VoiceId": {}
          }
        }
      }
    },
    "S7i": {
      "type": "structure",
      "members": {
        "Charset": {},
        "Data": {}
      }
    },
    "S7o": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Address": {},
          "DeliveryStatus": {},
          "MessageId": {},
          "StatusCode": {
            "type": "integer"
          },
          "StatusMessage": {},
          "UpdatedToken": {}
        },
        "required": [
          "DeliveryStatus",
          "StatusCode"
        ]
      }
    },
    "S8t": {
      "type": "structure",
      "members": {
        "Message": {},
        "RequestID": {}
      }
    }
  }
}