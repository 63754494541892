{
  "metadata": {
    "apiVersion": "2018-04-23",
    "endpointPrefix": "api.mediatailor",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "MediaTailor",
    "serviceFullName": "AWS MediaTailor",
    "serviceId": "MediaTailor",
    "signatureVersion": "v4",
    "signingName": "mediatailor",
    "uid": "mediatailor-2018-04-23"
  },
  "operations": {
    "DeletePlaybackConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/playbackConfiguration/{Name}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        },
        "required": [
          "Name"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "GetPlaybackConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/playbackConfiguration/{Name}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Name": {
            "location": "uri",
            "locationName": "Name"
          }
        },
        "required": [
          "Name"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "AdDecisionServerUrl": {},
          "CdnConfiguration": {
            "shape": "S6"
          },
          "DashConfiguration": {
            "shape": "S7"
          },
          "HlsConfiguration": {
            "shape": "S9"
          },
          "Name": {},
          "PlaybackConfigurationArn": {},
          "PlaybackEndpointPrefix": {},
          "SessionInitializationEndpointPrefix": {},
          "SlateAdUrl": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sa"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        },
        "type": "structure"
      }
    },
    "ListPlaybackConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/playbackConfigurations",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "Items": {
            "member": {
              "members": {
                "AdDecisionServerUrl": {},
                "CdnConfiguration": {
                  "shape": "S6"
                },
                "DashConfiguration": {
                  "shape": "S7"
                },
                "HlsConfiguration": {
                  "shape": "S9"
                },
                "Name": {},
                "PlaybackConfigurationArn": {},
                "PlaybackEndpointPrefix": {},
                "SessionInitializationEndpointPrefix": {},
                "SlateAdUrl": {},
                "Tags": {
                  "locationName": "tags",
                  "shape": "Sa"
                },
                "TranscodeProfileName": {},
                "VideoContentSourceUrl": {}
              },
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {}
        },
        "type": "structure"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        },
        "required": [
          "ResourceArn"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Tags": {
            "locationName": "tags",
            "shape": "Sa"
          }
        },
        "type": "structure"
      }
    },
    "PutPlaybackConfiguration": {
      "http": {
        "method": "PUT",
        "requestUri": "/playbackConfiguration",
        "responseCode": 200
      },
      "input": {
        "members": {
          "AdDecisionServerUrl": {},
          "CdnConfiguration": {
            "shape": "S6"
          },
          "DashConfiguration": {
            "members": {
              "MpdLocation": {},
              "OriginManifestType": {}
            },
            "type": "structure"
          },
          "Name": {},
          "SlateAdUrl": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sa"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "AdDecisionServerUrl": {},
          "CdnConfiguration": {
            "shape": "S6"
          },
          "DashConfiguration": {
            "shape": "S7"
          },
          "HlsConfiguration": {
            "shape": "S9"
          },
          "Name": {},
          "PlaybackConfigurationArn": {},
          "PlaybackEndpointPrefix": {},
          "SessionInitializationEndpointPrefix": {},
          "SlateAdUrl": {},
          "Tags": {
            "locationName": "tags",
            "shape": "Sa"
          },
          "TranscodeProfileName": {},
          "VideoContentSourceUrl": {}
        },
        "type": "structure"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "Sa"
          }
        },
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "type": "structure"
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "member": {},
            "type": "list"
          }
        },
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "type": "structure"
      }
    }
  },
  "shapes": {
    "S6": {
      "members": {
        "AdSegmentUrlPrefix": {},
        "ContentSegmentUrlPrefix": {}
      },
      "type": "structure"
    },
    "S7": {
      "members": {
        "ManifestEndpointPrefix": {},
        "MpdLocation": {},
        "OriginManifestType": {}
      },
      "type": "structure"
    },
    "S9": {
      "members": {
        "ManifestEndpointPrefix": {}
      },
      "type": "structure"
    },
    "Sa": {
      "key": {},
      "type": "map",
      "value": {}
    }
  }
}