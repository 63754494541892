{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-04-02",
    "endpointPrefix": "ec2-instance-connect",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "EC2 Instance Connect",
    "serviceFullName": "AWS EC2 Instance Connect",
    "serviceId": "EC2 Instance Connect",
    "signatureVersion": "v4",
    "targetPrefix": "AWSEC2InstanceConnectService",
    "uid": "ec2-instance-connect-2018-04-02"
  },
  "operations": {
    "SendSSHPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InstanceOSUser",
          "SSHPublicKey",
          "AvailabilityZone"
        ],
        "members": {
          "InstanceId": {},
          "InstanceOSUser": {},
          "SSHPublicKey": {},
          "AvailabilityZone": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestId": {},
          "Success": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {}
}