{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2019-06-24",
    "endpointPrefix": "servicequotas",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Service Quotas",
    "serviceId": "Service Quotas",
    "signatureVersion": "v4",
    "targetPrefix": "ServiceQuotasV20190624",
    "uid": "service-quotas-2019-06-24"
  },
  "operations": {
    "AssociateServiceQuotaTemplate": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteServiceQuotaIncreaseRequestFromTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode",
          "QuotaCode",
          "AwsRegion"
        ],
        "members": {
          "ServiceCode": {},
          "QuotaCode": {},
          "AwsRegion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateServiceQuotaTemplate": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetAWSDefaultServiceQuota": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode",
          "QuotaCode"
        ],
        "members": {
          "ServiceCode": {},
          "QuotaCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Quota": {
            "shape": "Sc"
          }
        }
      }
    },
    "GetAssociationForServiceQuotaTemplate": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceQuotaTemplateAssociationStatus": {}
        }
      }
    },
    "GetRequestedServiceQuotaChange": {
      "input": {
        "type": "structure",
        "required": [
          "RequestId"
        ],
        "members": {
          "RequestId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestedQuota": {
            "shape": "S13"
          }
        }
      }
    },
    "GetServiceQuota": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode",
          "QuotaCode"
        ],
        "members": {
          "ServiceCode": {},
          "QuotaCode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Quota": {
            "shape": "Sc"
          }
        }
      }
    },
    "GetServiceQuotaIncreaseRequestFromTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode",
          "QuotaCode",
          "AwsRegion"
        ],
        "members": {
          "ServiceCode": {},
          "QuotaCode": {},
          "AwsRegion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceQuotaIncreaseRequestInTemplate": {
            "shape": "S1c"
          }
        }
      }
    },
    "ListAWSDefaultServiceQuotas": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode"
        ],
        "members": {
          "ServiceCode": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Quotas": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListRequestedServiceQuotaChangeHistory": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceCode": {},
          "Status": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "RequestedQuotas": {
            "shape": "S1k"
          }
        }
      }
    },
    "ListRequestedServiceQuotaChangeHistoryByQuota": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode",
          "QuotaCode"
        ],
        "members": {
          "ServiceCode": {},
          "QuotaCode": {},
          "Status": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "RequestedQuotas": {
            "shape": "S1k"
          }
        }
      }
    },
    "ListServiceQuotaIncreaseRequestsInTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "ServiceCode": {},
          "AwsRegion": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceQuotaIncreaseRequestInTemplateList": {
            "type": "list",
            "member": {
              "shape": "S1c"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListServiceQuotas": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode"
        ],
        "members": {
          "ServiceCode": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Quotas": {
            "shape": "S1h"
          }
        }
      }
    },
    "ListServices": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Services": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ServiceCode": {},
                "ServiceName": {}
              }
            }
          }
        }
      }
    },
    "PutServiceQuotaIncreaseRequestIntoTemplate": {
      "input": {
        "type": "structure",
        "required": [
          "QuotaCode",
          "ServiceCode",
          "AwsRegion",
          "DesiredValue"
        ],
        "members": {
          "QuotaCode": {},
          "ServiceCode": {},
          "AwsRegion": {},
          "DesiredValue": {
            "type": "double"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceQuotaIncreaseRequestInTemplate": {
            "shape": "S1c"
          }
        }
      }
    },
    "RequestServiceQuotaIncrease": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceCode",
          "QuotaCode",
          "DesiredValue"
        ],
        "members": {
          "ServiceCode": {},
          "QuotaCode": {},
          "DesiredValue": {
            "type": "double"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RequestedQuota": {
            "shape": "S13"
          }
        }
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "structure",
      "members": {
        "ServiceCode": {},
        "ServiceName": {},
        "QuotaArn": {},
        "QuotaCode": {},
        "QuotaName": {},
        "Value": {
          "type": "double"
        },
        "Unit": {},
        "Adjustable": {
          "type": "boolean"
        },
        "GlobalQuota": {
          "type": "boolean"
        },
        "UsageMetric": {
          "type": "structure",
          "members": {
            "MetricNamespace": {},
            "MetricName": {},
            "MetricDimensions": {
              "type": "map",
              "key": {},
              "value": {}
            },
            "MetricStatisticRecommendation": {}
          }
        },
        "Period": {
          "type": "structure",
          "members": {
            "PeriodValue": {
              "type": "integer"
            },
            "PeriodUnit": {}
          }
        },
        "ErrorReason": {
          "type": "structure",
          "members": {
            "ErrorCode": {},
            "ErrorMessage": {}
          }
        }
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "Id": {},
        "CaseId": {},
        "ServiceCode": {},
        "ServiceName": {},
        "QuotaCode": {},
        "QuotaName": {},
        "DesiredValue": {
          "type": "double"
        },
        "Status": {},
        "Created": {
          "type": "timestamp"
        },
        "LastUpdated": {
          "type": "timestamp"
        },
        "Requester": {},
        "QuotaArn": {},
        "GlobalQuota": {
          "type": "boolean"
        },
        "Unit": {}
      }
    },
    "S1c": {
      "type": "structure",
      "members": {
        "ServiceCode": {},
        "ServiceName": {},
        "QuotaCode": {},
        "QuotaName": {},
        "DesiredValue": {
          "type": "double"
        },
        "AwsRegion": {},
        "Unit": {},
        "GlobalQuota": {
          "type": "boolean"
        }
      }
    },
    "S1h": {
      "type": "list",
      "member": {
        "shape": "Sc"
      }
    },
    "S1k": {
      "type": "list",
      "member": {
        "shape": "S13"
      }
    }
  }
}