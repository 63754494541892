{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-11-01",
    "endpointPrefix": "cloudtrail",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "CloudTrail",
    "serviceFullName": "AWS CloudTrail",
    "serviceId": "CloudTrail",
    "signatureVersion": "v4",
    "targetPrefix": "com.amazonaws.cloudtrail.v20131101.CloudTrail_20131101",
    "uid": "cloudtrail-2013-11-01"
  },
  "operations": {
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "S3BucketName"
        ],
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "EnableLogFileValidation": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {
            "deprecated": true
          },
          "SnsTopicARN": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "TrailARN": {},
          "LogFileValidationEnabled": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    },
    "DeleteTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeTrails": {
      "input": {
        "type": "structure",
        "members": {
          "trailNameList": {
            "type": "list",
            "member": {}
          },
          "includeShadowTrails": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "trailList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "S3BucketName": {},
                "S3KeyPrefix": {},
                "SnsTopicName": {
                  "deprecated": true
                },
                "SnsTopicARN": {},
                "IncludeGlobalServiceEvents": {
                  "type": "boolean"
                },
                "IsMultiRegionTrail": {
                  "type": "boolean"
                },
                "HomeRegion": {},
                "TrailARN": {},
                "LogFileValidationEnabled": {
                  "type": "boolean"
                },
                "CloudWatchLogsLogGroupArn": {},
                "CloudWatchLogsRoleArn": {},
                "KmsKeyId": {},
                "HasCustomEventSelectors": {
                  "type": "boolean"
                },
                "IsOrganizationTrail": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "GetEventSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName"
        ],
        "members": {
          "TrailName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "EventSelectors": {
            "shape": "Si"
          }
        }
      },
      "idempotent": true
    },
    "GetTrailStatus": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "IsLogging": {
            "type": "boolean"
          },
          "LatestDeliveryError": {},
          "LatestNotificationError": {},
          "LatestDeliveryTime": {
            "type": "timestamp"
          },
          "LatestNotificationTime": {
            "type": "timestamp"
          },
          "StartLoggingTime": {
            "type": "timestamp"
          },
          "StopLoggingTime": {
            "type": "timestamp"
          },
          "LatestCloudWatchLogsDeliveryError": {},
          "LatestCloudWatchLogsDeliveryTime": {
            "type": "timestamp"
          },
          "LatestDigestDeliveryTime": {
            "type": "timestamp"
          },
          "LatestDigestDeliveryError": {},
          "LatestDeliveryAttemptTime": {},
          "LatestNotificationAttemptTime": {},
          "LatestNotificationAttemptSucceeded": {},
          "LatestDeliveryAttemptSucceeded": {},
          "TimeLoggingStarted": {},
          "TimeLoggingStopped": {}
        }
      },
      "idempotent": true
    },
    "ListPublicKeys": {
      "input": {
        "type": "structure",
        "members": {
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicKeyList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Value": {
                  "type": "blob"
                },
                "ValidityStartTime": {
                  "type": "timestamp"
                },
                "ValidityEndTime": {
                  "type": "timestamp"
                },
                "Fingerprint": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceIdList"
        ],
        "members": {
          "ResourceIdList": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceTagList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceId": {},
                "TagsList": {
                  "shape": "S3"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "LookupEvents": {
      "input": {
        "type": "structure",
        "members": {
          "LookupAttributes": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "AttributeKey",
                "AttributeValue"
              ],
              "members": {
                "AttributeKey": {},
                "AttributeValue": {}
              }
            }
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "EventName": {},
                "ReadOnly": {},
                "AccessKeyId": {},
                "EventTime": {
                  "type": "timestamp"
                },
                "EventSource": {},
                "Username": {},
                "Resources": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ResourceType": {},
                      "ResourceName": {}
                    }
                  }
                },
                "CloudTrailEvent": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "PutEventSelectors": {
      "input": {
        "type": "structure",
        "required": [
          "TrailName",
          "EventSelectors"
        ],
        "members": {
          "TrailName": {},
          "EventSelectors": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TrailARN": {},
          "EventSelectors": {
            "shape": "Si"
          }
        }
      },
      "idempotent": true
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "TagsList": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StartLogging": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "StopLogging": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateTrail": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "EnableLogFileValidation": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "S3BucketName": {},
          "S3KeyPrefix": {},
          "SnsTopicName": {
            "deprecated": true
          },
          "SnsTopicARN": {},
          "IncludeGlobalServiceEvents": {
            "type": "boolean"
          },
          "IsMultiRegionTrail": {
            "type": "boolean"
          },
          "TrailARN": {},
          "LogFileValidationEnabled": {
            "type": "boolean"
          },
          "CloudWatchLogsLogGroupArn": {},
          "CloudWatchLogsRoleArn": {},
          "KmsKeyId": {},
          "IsOrganizationTrail": {
            "type": "boolean"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ReadWriteType": {},
          "IncludeManagementEvents": {
            "type": "boolean"
          },
          "DataResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    }
  }
}