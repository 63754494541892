{
  "pagination": {
    "DescribeAnalysisSchemes": {
      "result_key": "AnalysisSchemes"
    },
    "DescribeDomains": {
      "result_key": "DomainStatusList"
    },
    "DescribeExpressions": {
      "result_key": "Expressions"
    },
    "DescribeIndexFields": {
      "result_key": "IndexFields"
    },
    "DescribeSuggesters": {
      "result_key": "Suggesters"
    }
  }
}

