{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-01",
    "endpointPrefix": "eks",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon EKS",
    "serviceFullName": "Amazon Elastic Kubernetes Service",
    "serviceId": "EKS",
    "signatureVersion": "v4",
    "signingName": "eks",
    "uid": "eks-2017-11-01"
  },
  "operations": {
    "CreateCluster": {
      "http": {
        "requestUri": "/clusters"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "roleArn",
          "resourcesVpcConfig"
        ],
        "members": {
          "name": {},
          "version": {},
          "roleArn": {},
          "resourcesVpcConfig": {
            "shape": "S4"
          },
          "logging": {
            "shape": "S7"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sd"
          }
        }
      }
    },
    "DeleteCluster": {
      "http": {
        "method": "DELETE",
        "requestUri": "/clusters/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sd"
          }
        }
      }
    },
    "DescribeCluster": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "cluster": {
            "shape": "Sd"
          }
        }
      }
    },
    "DescribeUpdate": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/updates/{updateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "updateId"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "updateId": {
            "location": "uri",
            "locationName": "updateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "Sp"
          }
        }
      }
    },
    "ListClusters": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "clusters": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "ListUpdates": {
      "http": {
        "method": "GET",
        "requestUri": "/clusters/{name}/updates"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "updateIds": {
            "shape": "S5"
          },
          "nextToken": {}
        }
      }
    },
    "UpdateClusterConfig": {
      "http": {
        "requestUri": "/clusters/{name}/update-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "resourcesVpcConfig": {
            "shape": "S4"
          },
          "logging": {
            "shape": "S7"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "Sp"
          }
        }
      }
    },
    "UpdateClusterVersion": {
      "http": {
        "requestUri": "/clusters/{name}/updates"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "version"
        ],
        "members": {
          "name": {
            "location": "uri",
            "locationName": "name"
          },
          "version": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "update": {
            "shape": "Sp"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "subnetIds": {
          "shape": "S5"
        },
        "securityGroupIds": {
          "shape": "S5"
        },
        "endpointPublicAccess": {
          "type": "boolean"
        },
        "endpointPrivateAccess": {
          "type": "boolean"
        }
      }
    },
    "S5": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "structure",
      "members": {
        "clusterLogging": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "types": {
                "type": "list",
                "member": {}
              },
              "enabled": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "createdAt": {
          "type": "timestamp"
        },
        "version": {},
        "endpoint": {},
        "roleArn": {},
        "resourcesVpcConfig": {
          "type": "structure",
          "members": {
            "subnetIds": {
              "shape": "S5"
            },
            "securityGroupIds": {
              "shape": "S5"
            },
            "vpcId": {},
            "endpointPublicAccess": {
              "type": "boolean"
            },
            "endpointPrivateAccess": {
              "type": "boolean"
            }
          }
        },
        "logging": {
          "shape": "S7"
        },
        "status": {},
        "certificateAuthority": {
          "type": "structure",
          "members": {
            "data": {}
          }
        },
        "clientRequestToken": {},
        "platformVersion": {}
      }
    },
    "Sp": {
      "type": "structure",
      "members": {
        "id": {},
        "status": {},
        "type": {},
        "params": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "type": {},
              "value": {}
            }
          }
        },
        "createdAt": {
          "type": "timestamp"
        },
        "errors": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "errorCode": {},
              "errorMessage": {},
              "resourceIds": {
                "shape": "S5"
              }
            }
          }
        }
      }
    }
  }
}