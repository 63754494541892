{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-27",
    "endpointPrefix": "textract",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Textract",
    "serviceId": "Textract",
    "signatureVersion": "v4",
    "targetPrefix": "Textract",
    "uid": "textract-2018-06-27"
  },
  "operations": {
    "AnalyzeDocument": {
      "input": {
        "type": "structure",
        "required": [
          "Document",
          "FeatureTypes"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          },
          "FeatureTypes": {
            "shape": "S8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sb"
          },
          "Blocks": {
            "shape": "Sd"
          }
        }
      }
    },
    "DetectDocumentText": {
      "input": {
        "type": "structure",
        "required": [
          "Document"
        ],
        "members": {
          "Document": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sb"
          },
          "Blocks": {
            "shape": "Sd"
          }
        }
      }
    },
    "GetDocumentAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sb"
          },
          "JobStatus": {},
          "NextToken": {},
          "Blocks": {
            "shape": "Sd"
          },
          "Warnings": {
            "shape": "S13"
          },
          "StatusMessage": {}
        }
      }
    },
    "GetDocumentTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "JobId"
        ],
        "members": {
          "JobId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DocumentMetadata": {
            "shape": "Sb"
          },
          "JobStatus": {},
          "NextToken": {},
          "Blocks": {
            "shape": "Sd"
          },
          "Warnings": {
            "shape": "S13"
          },
          "StatusMessage": {}
        }
      }
    },
    "StartDocumentAnalysis": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentLocation",
          "FeatureTypes"
        ],
        "members": {
          "DocumentLocation": {
            "shape": "S1b"
          },
          "FeatureTypes": {
            "shape": "S8"
          },
          "ClientRequestToken": {},
          "JobTag": {},
          "NotificationChannel": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "StartDocumentTextDetection": {
      "input": {
        "type": "structure",
        "required": [
          "DocumentLocation"
        ],
        "members": {
          "DocumentLocation": {
            "shape": "S1b"
          },
          "ClientRequestToken": {},
          "JobTag": {},
          "NotificationChannel": {
            "shape": "S1e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "Bytes": {
          "type": "blob"
        },
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S4": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Name": {},
        "Version": {}
      }
    },
    "S8": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "structure",
      "members": {
        "Pages": {
          "type": "integer"
        }
      }
    },
    "Sd": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BlockType": {},
          "Confidence": {
            "type": "float"
          },
          "Text": {},
          "RowIndex": {
            "type": "integer"
          },
          "ColumnIndex": {
            "type": "integer"
          },
          "RowSpan": {
            "type": "integer"
          },
          "ColumnSpan": {
            "type": "integer"
          },
          "Geometry": {
            "type": "structure",
            "members": {
              "BoundingBox": {
                "type": "structure",
                "members": {
                  "Width": {
                    "type": "float"
                  },
                  "Height": {
                    "type": "float"
                  },
                  "Left": {
                    "type": "float"
                  },
                  "Top": {
                    "type": "float"
                  }
                }
              },
              "Polygon": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "X": {
                      "type": "float"
                    },
                    "Y": {
                      "type": "float"
                    }
                  }
                }
              }
            }
          },
          "Id": {},
          "Relationships": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Ids": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "EntityTypes": {
            "type": "list",
            "member": {}
          },
          "SelectionStatus": {},
          "Page": {
            "type": "integer"
          }
        }
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ErrorCode": {},
          "Pages": {
            "type": "list",
            "member": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "S3Object": {
          "shape": "S4"
        }
      }
    },
    "S1e": {
      "type": "structure",
      "required": [
        "SNSTopicArn",
        "RoleArn"
      ],
      "members": {
        "SNSTopicArn": {},
        "RoleArn": {}
      }
    }
  }
}