{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-08-08",
    "endpointPrefix": "connect",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon Connect",
    "serviceFullName": "Amazon Connect Service",
    "serviceId": "Connect",
    "signatureVersion": "v4",
    "signingName": "connect",
    "uid": "connect-2017-08-08"
  },
  "operations": {
    "CreateUser": {
      "http": {
        "method": "PUT",
        "requestUri": "/users/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "PhoneConfig",
          "SecurityProfileIds",
          "RoutingProfileId",
          "InstanceId"
        ],
        "members": {
          "Username": {},
          "Password": {},
          "IdentityInfo": {
            "shape": "S4"
          },
          "PhoneConfig": {
            "shape": "S8"
          },
          "DirectoryUserId": {},
          "SecurityProfileIds": {
            "shape": "Se"
          },
          "RoutingProfileId": {},
          "HierarchyGroupId": {},
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {},
          "UserArn": {}
        }
      }
    },
    "DeleteUser": {
      "http": {
        "method": "DELETE",
        "requestUri": "/users/{InstanceId}/{UserId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "UserId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          }
        }
      }
    },
    "DescribeUser": {
      "http": {
        "method": "GET",
        "requestUri": "/users/{InstanceId}/{UserId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId"
        ],
        "members": {
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "Username": {},
              "IdentityInfo": {
                "shape": "S4"
              },
              "PhoneConfig": {
                "shape": "S8"
              },
              "DirectoryUserId": {},
              "SecurityProfileIds": {
                "shape": "Se"
              },
              "RoutingProfileId": {},
              "HierarchyGroupId": {}
            }
          }
        }
      }
    },
    "DescribeUserHierarchyGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-groups/{InstanceId}/{HierarchyGroupId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "HierarchyGroupId",
          "InstanceId"
        ],
        "members": {
          "HierarchyGroupId": {
            "location": "uri",
            "locationName": "HierarchyGroupId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HierarchyGroup": {
            "type": "structure",
            "members": {
              "Id": {},
              "Arn": {},
              "Name": {},
              "LevelId": {},
              "HierarchyPath": {
                "type": "structure",
                "members": {
                  "LevelOne": {
                    "shape": "Sw"
                  },
                  "LevelTwo": {
                    "shape": "Sw"
                  },
                  "LevelThree": {
                    "shape": "Sw"
                  },
                  "LevelFour": {
                    "shape": "Sw"
                  },
                  "LevelFive": {
                    "shape": "Sw"
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeUserHierarchyStructure": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-structure/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HierarchyStructure": {
            "type": "structure",
            "members": {
              "LevelOne": {
                "shape": "S10"
              },
              "LevelTwo": {
                "shape": "S10"
              },
              "LevelThree": {
                "shape": "S10"
              },
              "LevelFour": {
                "shape": "S10"
              },
              "LevelFive": {
                "shape": "S10"
              }
            }
          }
        }
      }
    },
    "GetContactAttributes": {
      "http": {
        "method": "GET",
        "requestUri": "/contact/attributes/{InstanceId}/{InitialContactId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "InitialContactId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "InitialContactId": {
            "location": "uri",
            "locationName": "InitialContactId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attributes": {
            "shape": "S15"
          }
        }
      }
    },
    "GetCurrentMetricData": {
      "http": {
        "requestUri": "/metrics/current/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "Filters",
          "CurrentMetrics"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "Filters": {
            "shape": "S19"
          },
          "Groupings": {
            "shape": "S1e"
          },
          "CurrentMetrics": {
            "type": "list",
            "member": {
              "shape": "S1h"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MetricResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "S1p"
                },
                "Collections": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {
                        "shape": "S1h"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "DataSnapshotTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "GetFederationToken": {
      "http": {
        "method": "GET",
        "requestUri": "/user/federate/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Credentials": {
            "type": "structure",
            "members": {
              "AccessToken": {
                "shape": "S1y"
              },
              "AccessTokenExpiration": {
                "type": "timestamp"
              },
              "RefreshToken": {
                "shape": "S1y"
              },
              "RefreshTokenExpiration": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "GetMetricData": {
      "http": {
        "requestUri": "/metrics/historical/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId",
          "StartTime",
          "EndTime",
          "Filters",
          "HistoricalMetrics"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Filters": {
            "shape": "S19"
          },
          "Groupings": {
            "shape": "S1e"
          },
          "HistoricalMetrics": {
            "type": "list",
            "member": {
              "shape": "S21"
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MetricResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Dimensions": {
                  "shape": "S1p"
                },
                "Collections": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Metric": {
                        "shape": "S21"
                      },
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListRoutingProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/routing-profiles-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RoutingProfileSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListSecurityProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityProfileSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Name": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUserHierarchyGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/user-hierarchy-groups-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserHierarchyGroupSummaryList": {
            "type": "list",
            "member": {
              "shape": "Sw"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUsers": {
      "http": {
        "method": "GET",
        "requestUri": "/users-summary/{InstanceId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceId"
        ],
        "members": {
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserSummaryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Arn": {},
                "Username": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartOutboundVoiceContact": {
      "http": {
        "method": "PUT",
        "requestUri": "/contact/outbound-voice"
      },
      "input": {
        "type": "structure",
        "required": [
          "DestinationPhoneNumber",
          "ContactFlowId",
          "InstanceId"
        ],
        "members": {
          "DestinationPhoneNumber": {},
          "ContactFlowId": {},
          "InstanceId": {},
          "ClientToken": {
            "idempotencyToken": true
          },
          "SourcePhoneNumber": {},
          "QueueId": {},
          "Attributes": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ContactId": {}
        }
      }
    },
    "StopContact": {
      "http": {
        "requestUri": "/contact/stop"
      },
      "input": {
        "type": "structure",
        "required": [
          "ContactId",
          "InstanceId"
        ],
        "members": {
          "ContactId": {},
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateContactAttributes": {
      "http": {
        "requestUri": "/contact/attributes"
      },
      "input": {
        "type": "structure",
        "required": [
          "InitialContactId",
          "InstanceId",
          "Attributes"
        ],
        "members": {
          "InitialContactId": {},
          "InstanceId": {},
          "Attributes": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateUserHierarchy": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/hierarchy"
      },
      "input": {
        "type": "structure",
        "required": [
          "UserId",
          "InstanceId"
        ],
        "members": {
          "HierarchyGroupId": {},
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserIdentityInfo": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/identity-info"
      },
      "input": {
        "type": "structure",
        "required": [
          "IdentityInfo",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "IdentityInfo": {
            "shape": "S4"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserPhoneConfig": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/phone-config"
      },
      "input": {
        "type": "structure",
        "required": [
          "PhoneConfig",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "PhoneConfig": {
            "shape": "S8"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserRoutingProfile": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/routing-profile"
      },
      "input": {
        "type": "structure",
        "required": [
          "RoutingProfileId",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "RoutingProfileId": {},
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    },
    "UpdateUserSecurityProfiles": {
      "http": {
        "requestUri": "/users/{InstanceId}/{UserId}/security-profiles"
      },
      "input": {
        "type": "structure",
        "required": [
          "SecurityProfileIds",
          "UserId",
          "InstanceId"
        ],
        "members": {
          "SecurityProfileIds": {
            "shape": "Se"
          },
          "UserId": {
            "location": "uri",
            "locationName": "UserId"
          },
          "InstanceId": {
            "location": "uri",
            "locationName": "InstanceId"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "FirstName": {},
        "LastName": {},
        "Email": {}
      }
    },
    "S8": {
      "type": "structure",
      "required": [
        "PhoneType"
      ],
      "members": {
        "PhoneType": {},
        "AutoAccept": {
          "type": "boolean"
        },
        "AfterContactWorkTimeLimit": {
          "type": "integer"
        },
        "DeskPhoneNumber": {}
      }
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Sw": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {}
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {}
      }
    },
    "S15": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S19": {
      "type": "structure",
      "members": {
        "Queues": {
          "type": "list",
          "member": {}
        },
        "Channels": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1e": {
      "type": "list",
      "member": {}
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Name": {},
        "Unit": {}
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "Queue": {
          "type": "structure",
          "members": {
            "Id": {},
            "Arn": {}
          }
        },
        "Channel": {}
      }
    },
    "S1y": {
      "type": "string",
      "sensitive": true
    },
    "S21": {
      "type": "structure",
      "members": {
        "Name": {},
        "Threshold": {
          "type": "structure",
          "members": {
            "Comparison": {},
            "ThresholdValue": {
              "type": "double"
            }
          }
        },
        "Statistic": {},
        "Unit": {}
      }
    }
  }
}