{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-08-25",
    "endpointPrefix": "signer",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "signer",
    "serviceFullName": "AWS Signer",
    "serviceId": "signer",
    "signatureVersion": "v4",
    "signingName": "signer",
    "uid": "signer-2017-08-25"
  },
  "operations": {
    "CancelSigningProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/signing-profiles/{profileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "profileName"
        ],
        "members": {
          "profileName": {
            "location": "uri",
            "locationName": "profileName"
          }
        }
      }
    },
    "DescribeSigningJob": {
      "http": {
        "method": "GET",
        "requestUri": "/signing-jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobId": {},
          "source": {
            "shape": "S6"
          },
          "signingMaterial": {
            "shape": "Sb"
          },
          "platformId": {},
          "profileName": {},
          "overrides": {
            "shape": "Se"
          },
          "signingParameters": {
            "shape": "Si"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "completedAt": {
            "type": "timestamp"
          },
          "requestedBy": {},
          "status": {},
          "statusReason": {},
          "signedObject": {
            "shape": "Sq"
          }
        }
      }
    },
    "GetSigningPlatform": {
      "http": {
        "method": "GET",
        "requestUri": "/signing-platforms/{platformId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "platformId"
        ],
        "members": {
          "platformId": {
            "location": "uri",
            "locationName": "platformId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "platformId": {},
          "displayName": {},
          "partner": {},
          "target": {},
          "category": {},
          "signingConfiguration": {
            "shape": "Sy"
          },
          "signingImageFormat": {
            "shape": "S13"
          },
          "maxSizeInMB": {
            "type": "integer"
          }
        }
      }
    },
    "GetSigningProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/signing-profiles/{profileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "profileName"
        ],
        "members": {
          "profileName": {
            "location": "uri",
            "locationName": "profileName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "profileName": {},
          "signingMaterial": {
            "shape": "Sb"
          },
          "platformId": {},
          "overrides": {
            "shape": "Se"
          },
          "signingParameters": {
            "shape": "Si"
          },
          "status": {}
        }
      }
    },
    "ListSigningJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/signing-jobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "platformId": {
            "location": "querystring",
            "locationName": "platformId"
          },
          "requestedBy": {
            "location": "querystring",
            "locationName": "requestedBy"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobId": {},
                "source": {
                  "shape": "S6"
                },
                "signedObject": {
                  "shape": "Sq"
                },
                "signingMaterial": {
                  "shape": "Sb"
                },
                "createdAt": {
                  "type": "timestamp"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSigningPlatforms": {
      "http": {
        "method": "GET",
        "requestUri": "/signing-platforms"
      },
      "input": {
        "type": "structure",
        "members": {
          "category": {
            "location": "querystring",
            "locationName": "category"
          },
          "partner": {
            "location": "querystring",
            "locationName": "partner"
          },
          "target": {
            "location": "querystring",
            "locationName": "target"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "platforms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "platformId": {},
                "displayName": {},
                "partner": {},
                "target": {},
                "category": {},
                "signingConfiguration": {
                  "shape": "Sy"
                },
                "signingImageFormat": {
                  "shape": "S13"
                },
                "maxSizeInMB": {
                  "type": "integer"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSigningProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/signing-profiles"
      },
      "input": {
        "type": "structure",
        "members": {
          "includeCanceled": {
            "location": "querystring",
            "locationName": "includeCanceled",
            "type": "boolean"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "profiles": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "profileName": {},
                "signingMaterial": {
                  "shape": "Sb"
                },
                "platformId": {},
                "signingParameters": {
                  "shape": "Si"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "PutSigningProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/signing-profiles/{profileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "profileName",
          "signingMaterial",
          "platformId"
        ],
        "members": {
          "profileName": {
            "location": "uri",
            "locationName": "profileName"
          },
          "signingMaterial": {
            "shape": "Sb"
          },
          "platformId": {},
          "overrides": {
            "shape": "Se"
          },
          "signingParameters": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {}
        }
      }
    },
    "StartSigningJob": {
      "http": {
        "requestUri": "/signing-jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "source",
          "destination",
          "clientRequestToken"
        ],
        "members": {
          "source": {
            "shape": "S6"
          },
          "destination": {
            "type": "structure",
            "members": {
              "s3": {
                "type": "structure",
                "members": {
                  "bucketName": {},
                  "prefix": {}
                }
              }
            }
          },
          "profileName": {},
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobId": {}
        }
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "structure",
      "members": {
        "s3": {
          "type": "structure",
          "required": [
            "bucketName",
            "key",
            "version"
          ],
          "members": {
            "bucketName": {},
            "key": {},
            "version": {}
          }
        }
      }
    },
    "Sb": {
      "type": "structure",
      "required": [
        "certificateArn"
      ],
      "members": {
        "certificateArn": {}
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "signingConfiguration": {
          "type": "structure",
          "members": {
            "encryptionAlgorithm": {},
            "hashAlgorithm": {}
          }
        }
      }
    },
    "Si": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sq": {
      "type": "structure",
      "members": {
        "s3": {
          "type": "structure",
          "members": {
            "bucketName": {},
            "key": {}
          }
        }
      }
    },
    "Sy": {
      "type": "structure",
      "required": [
        "encryptionAlgorithmOptions",
        "hashAlgorithmOptions"
      ],
      "members": {
        "encryptionAlgorithmOptions": {
          "type": "structure",
          "required": [
            "allowedValues",
            "defaultValue"
          ],
          "members": {
            "allowedValues": {
              "type": "list",
              "member": {}
            },
            "defaultValue": {}
          }
        },
        "hashAlgorithmOptions": {
          "type": "structure",
          "required": [
            "allowedValues",
            "defaultValue"
          ],
          "members": {
            "allowedValues": {
              "type": "list",
              "member": {}
            },
            "defaultValue": {}
          }
        }
      }
    },
    "S13": {
      "type": "structure",
      "required": [
        "supportedFormats",
        "defaultFormat"
      ],
      "members": {
        "supportedFormats": {
          "type": "list",
          "member": {}
        },
        "defaultFormat": {}
      }
    }
  }
}