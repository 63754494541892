import { useEffect, useState, useRef, useCallback, useLayoutEffect } from 'react';

/**
 * Run a function when a component is mounted.
 *
 * @deprecated This hook breaks in React 18's strict mode, since it's not idempotent
 *
 * @param callback function to be executed
 */
function useMount(callback) {
    useEffect(callback, []);
}

/**
 * Reactive media query hook that returns the truthy value of the media query.
 *
 * @param {string} query
 * @returns {boolean} boolean value of the query
 *
 * @see https://react-hooks-library.vercel.app/core/useMediaQuery
 */
function useMediaQuery(query) {
    const [matches, setMatches] = useState(false);
    useMount(() => {
        setMatches(window.matchMedia(query).matches);
    });
    useEffect(() => {
        const mediaQuery = window.matchMedia(query);
        const handler = (event) => {
            setMatches(event.matches);
        };
        // Add event listener for old safari browsers
        'addEventListener' in mediaQuery
            ? mediaQuery.addEventListener('change', handler)
            : mediaQuery.addListener(handler);
        return () => {
            'addEventListener' in mediaQuery
                ? mediaQuery.removeEventListener('change', handler)
                : mediaQuery.removeListener(handler);
        };
    }, [query]);
    return matches;
}

/**
 * Breakpoints from Tailwind V2
 *
 * @see https://tailwindcss.com/docs/breakpoints
 */
const breakpointsTailwind = {
    sm: 640,
    md: 768,
    lg: 1024,
    xl: 1280,
    '2xl': 1536
};
/**
 * Breakpoints from Bootstrap V5
 *
 * @see https://getbootstrap.com/docs/5.0/layout/breakpoints
 */
const breakpointsBootstrapV5 = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400
};
/**
 * Breakpoints from Vuetify V2
 *
 * @see https://vuetifyjs.com/en/features/breakpoints
 */
const breakpointsVuetify = {
    xs: 600,
    sm: 960,
    md: 1264,
    lg: 1904
};
/**
 * Breakpoints from Ant Design
 *
 * @see https://ant.design/components/layout/#breakpoint-width
 */
const breakpointsAntDesign = {
    xs: 480,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600
};
/**
 * Sematic Breakpoints
 */
const breakpointsSematic = {
    mobileS: 320,
    mobileM: 375,
    mobileL: 425,
    tablet: 768,
    laptop: 1024,
    laptopL: 1440,
    desktop4K: 2560
};

/* eslint-disable @typescript-eslint/ban-types */
/**
 * Check if we're on the server or client side
 */
const isClient = typeof window !== 'undefined';
/**
 * Check if object is a react ref
 */
const isRef = (obj) => obj !== null &&
    typeof obj === 'object' &&
    Object.prototype.hasOwnProperty.call(obj, 'current');
const isFunction = (val) => typeof val === 'function';
const isString = (val) => typeof val === 'string';
// eslint-disable-next-line @typescript-eslint/no-empty-function
const noop = () => { };
const round = (num) => Math.round(num * 1e2) / 1e2;

/**
 * Accepts either a ref object or a dom node and returns a dom node
 *
 * @param target - ref or a dom node
 * @returns dom noe
 */
function unRef(target) {
    const element = isRef(target)
        ? target.current
        : target;
    return element;
}

const _window =  isClient ? window : undefined;
const _document =  isClient ? window.document : undefined;
const _navigator =  isClient
    ? window.navigator
    : undefined;

function match(query) {
    if (!_window)
        return false;
    return _window.matchMedia(query).matches;
}
/**
 * Reactive hooks and utilities to be used with user provided breakpoints.
 *
 * @param {string} breakpoints
 * @returns functions to be used as hooks
 *
 * @see https://react-hooks-library.vercel.app/core/BreakPointHooks
 */
function BreakPointHooks(breakpoints) {
    return {
        /**
         * Hook that returns a boolean if screen width is greater than given breakpoint.
         *
         * @param k {string} breakpoint
         * @returns boolean
         *
         * @see https://react-hooks-library.vercel.app/core/BreakPointHooks
         **/
        useGreater: (k) => {
            return useMediaQuery(`(min-width: ${breakpoints[k]}px)`);
        },
        /**
         * Hook that returns a boolean if screen width is smaller than given breakpoint.
         *
         * @param k {string} breakpoint
         * @param k {string} breakpoint
         *
         * @returns boolean
         *
         * @see https://react-hooks-library.vercel.app/core/BreakPointHooks
         **/
        useSmaller: (k) => {
            return useMediaQuery(`(max-width: ${breakpoints[k]}px)`);
        },
        /**
         * Hook that returns a boolean if screen width is between two given breakpoint.
         *
         * @param a {string} breakpoint
         * @param b {string} breakpoint
         *
         * @returns boolean
         *
         * @see https://react-hooks-library.vercel.app/core/BreakPointHooks
         **/
        useBetween: (a, b) => {
            return useMediaQuery(`(min-width: ${breakpoints[a]}px) and (max-width: ${breakpoints[b]}px)`);
        },
        /**
         * Utility function that returns a boolean if screen width is greater than given breakpoint.
         *
         * @param k {string} breakpoint
         *
         * @see https://react-hooks-library.vercel.app/core/BreakPointHooks
         **/
        isGreater(k) {
            return match(`(min-width: ${breakpoints[k]}px)`);
        },
        /**
         * Utility function that returns a boolean if screen width is smaller than given breakpoint.
         *
         * @param k {string} breakpoint
         *
         * @see https://react-hooks-library.vercel.app/core/BreakPointHooks
         **/
        isSmaller(k) {
            return match(`(max-width: ${breakpoints[k]}px)`);
        },
        /**
         * Utility function that returns a boolean if screen width is between two given breakpoint.
         *
         * @param k {string} breakpoint
         *
         * @see https://react-hooks-library.vercel.app/core/BreakPointHooks
         **/
        isInBetween(a, b) {
            return match(`(min-width: ${breakpoints[a]}px) and (max-width: ${breakpoints[b]}px)`);
        }
    };
}

function useEventListener(...args) {
    let target = _window;
    let event;
    let listener;
    let options;
    isString(args[0])
        ? ([event, listener, options] = args)
        : ([target, event, listener, options] = args);
    const savedListener = useRef(listener);
    const cleanup = useRef(noop);
    useEffect(() => {
        savedListener.current = listener;
    }, [listener]);
    useEffect(() => {
        const el = unRef(target);
        if (!isClient || !el)
            return;
        el.addEventListener(event, savedListener.current, options);
        cleanup.current = () => {
            el.removeEventListener(event, savedListener.current, options);
        };
        return cleanup.current;
    }, [event, target, options]);
    return cleanup.current;
}

/**
 * Reactive document.activeElement, returns a reference to current active element
 *
 * @returns current active element (DOM node)
 **/
function useActiveElement() {
    const [activeElement, setActiveElement] = useState(() => _document === null || _document === void 0 ? void 0 : _document.activeElement);
    useEventListener('focus', () => setActiveElement(_document === null || _document === void 0 ? void 0 : _document.activeElement), true);
    useEventListener('blur', () => setActiveElement(null), true);
    return { activeElement };
}

/**
 * Returns a current execution state of an async function.
 * Use it to orchestrate async actions in UI.
 *
 * @see https://react-hooks-library.vercel.app/core/useAsyncCallback
 */
function useAsyncCallback(callback) {
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const [error, setError] = useState(false);
    const [data, setData] = useState();
    const _callback = useCallback(async (...args) => {
        try {
            setIsLoading(true);
            const results = await callback(...args);
            setData(results);
            setIsSuccess(true);
            return results;
        }
        catch (e) {
            setError(true);
            throw e;
        }
        finally {
            setIsLoading(false);
        }
    }, [callback]);
    return [{ data, error, isLoading, isSuccess }, _callback];
}

/**
 * Listen for clicks outside of an element.
 *
 * @param target
 * @param handler
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/onClickOutside
 */
function useClickOutside(target, handler, options = {}) {
    const { event = 'pointerdown' } = options;
    const listener = useCallback((event) => {
        const el = unRef(target);
        if (!el)
            return;
        if (el === event.target || event.composedPath().includes(el))
            return;
        handler(event);
    }, [handler, target]);
    return useEventListener(_window, event, listener, { passive: true });
}

/**
 * Used to debounce a quickly changing value.
 * Will return the latest value after a specified amount of time.
 *
 * @param {T} value
 * @param timeout
 * @returns {Readonly<T>} latest value
 * @see https://react-hooks-library.vercel.app/core/useDebounce
 */
function useDebounce(value, timeout) {
    const [state, setState] = useState(value);
    useEffect(() => {
        const tick = setTimeout(() => setState(value), timeout);
        return () => clearTimeout(tick);
    }, [value, timeout]);
    if (timeout <= 0)
        return value;
    return state;
}

/**
 * A useEffect hook does that not run on mount, but only on subsequent updates.
 *
 * @deprecated This hook breaks in React 18's strict mode, since it's not idempotent
 *
 * @param effect
 * @param deps
 *
 * @see https://react-hooks-library.vercel.app/core/useEffectAfterMount
 */
function useEffectAfterMount(effect, deps) {
    const isMounted = useRef(false);
    useEffect(() => {
        let cleanup = undefined;
        if (isMounted.current) {
            cleanup = effect();
        }
        isMounted.current = true;
        return cleanup;
    }, deps);
}

/**
 * React FontFace, a hook to load fonts asynchronously
 *
 * @param family
 * @param source
 * @param descriptors
 *
 * @see https://react-hooks-library.vercel.app/core/useFont
 */
function useFont(family, source, descriptors) {
    const [loaded, setLoaded] = useState(true);
    const [error, setError] = useState(false);
    const [font, setFont] = useState(null);
    useEffect(() => {
        const font = new FontFace(family, `url(${source})`, descriptors);
        setFont(font);
        setLoaded(false);
        font
            .load()
            .then(() => document.fonts.add(font))
            .catch(() => setError(true))
            .finally(() => setLoaded(true));
    }, [descriptors, family, source]);
    return { loaded, error, font };
}

/**
 * Hook that returns whether or not the component has mounted.
 * Useful in SSR frameworks like Next or Gatsby.
 *
 * @returns hasMounted
 */
function useHasMounted() {
    const [hasMounted, setHasMounted] = useState(false);
    useMount(() => {
        setHasMounted(true);
    });
    return hasMounted;
}

/**
 *
 * Detect if a dom element is hovered
 *
 * @param target - The element to listen to
 * @returns
 */
function useHover(target) {
    const [isHovered, setIsHovered] = useState(false);
    useEffect(() => {
        const el = unRef(target);
        if (!el)
            return;
        const onMouseEnter = () => setIsHovered(true);
        const onMouseLeave = () => setIsHovered(false);
        el.addEventListener('mouseenter', onMouseEnter);
        el.addEventListener('mouseleave', onMouseLeave);
        return () => {
            el.removeEventListener('mouseenter', onMouseEnter);
            el.removeEventListener('mouseleave', onMouseLeave);
        };
    }, [target]);
    return isHovered;
}

/**
 * Is a feature supported in the browser or not
 *
 * @param predicate - predicate to check if the feature is supported
 *
 * @see https://react-hooks-library.vercel.app/core/useIsSupported
 */
function useIsSupported(predicate) {
    const [isSupported, setIsSupported] = useState(false);
    useMount(() => {
        setIsSupported(predicate());
    });
    return isSupported;
}

/**
 * Run a function when component is unmounted.
 *
 * @deprecated This hook breaks in React 18's strict mode, since it's not idempotent
 *
 * @param callback function to be executed
 */
function useUnMount(func) {
    useEffect(() => {
        return func;
    }, [func]);
}

/**
 * Reactive intersection observer.
 *
 * @param target - React ref or DOM node
 * @param options - Options passed to mutation observer
 * @param callback - callback to execute when mutations are observed
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IntersectionObserver IntersectionObserver MDN
 * @see https://react-hooks-library.vercel.app/core/useIntersectionObserver
 */
function useIntersectionObserver(target, options = {}, callback = noop) {
    const { root = _document, rootMargin = '0px', threshold = 0 } = options;
    const [inView, setInView] = useState(false);
    const [entry, setEntry] = useState(null);
    const isSupported = useIsSupported(() => 'IntersectionObserver' in window);
    const observer = useRef(null);
    const stop = useCallback(() => {
        if (!observer.current)
            return;
        observer.current.disconnect();
        observer.current = null;
    }, []);
    useUnMount(stop);
    useEffect(() => {
        var _a;
        const el = unRef(target);
        const rootEl = unRef(root);
        if (!(isSupported && el && rootEl))
            return;
        observer.current = new window.IntersectionObserver((entries, observer) => {
            const thresholds = Array.isArray(threshold) ? threshold : [threshold];
            entries.forEach((entry) => {
                const inView = entry.isIntersecting &&
                    thresholds.some((threshold) => entry.intersectionRatio >= threshold);
                setInView(inView);
                setEntry(entry);
            });
            callback(entries, observer);
        }, {
            root: rootEl,
            rootMargin,
            threshold
        });
        (_a = observer.current) === null || _a === void 0 ? void 0 : _a.observe(el);
        return stop;
    }, [callback, isSupported, root, rootMargin, stop, target, threshold]);
    return {
        isSupported,
        stop,
        inView,
        entry
    };
}

/**
 * Run a function repeatedly at a specified interval.
 *
 * @see https://react-hooks-library.vercel.app/core/useInterval
 */
function useInterval(callback, delay, options) {
    const { immediate = false, paused = false } = options || {};
    const savedCallback = useRef(callback);
    const tickId = useRef();
    useEffect(() => {
        savedCallback.current = callback;
        if (!paused && immediate) {
            callback();
        }
    }, [callback, immediate, paused]);
    useEffect(() => {
        if (tickId.current && paused) {
            clearInterval(tickId.current);
            return;
        }
        tickId.current = setInterval(() => savedCallback.current(), delay);
        return () => tickId.current && clearInterval(tickId.current);
    }, [delay, paused]);
}

/**
 * Listen for keyboard keys being stroked.
 *
 * @param keys
 * @param handler
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useKeyStroke
 */
function useKeyStroke(keys, handler, options = {}) {
    const { target = _window, eventName = 'keydown', passive = false, code = false } = options;
    const listener = useCallback((e) => {
        const eventKey = code ? e.code : e.key;
        keys.includes(eventKey) && handler(e);
    }, [code, handler, keys]);
    return useEventListener(target, eventName, listener, { passive });
}
/**
 * Listen for keyboard keys on keydown.
 *
 * @param keys
 * @param handler
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useKeyStroke
 */
function useKeyDown(keys, handler, options = {}) {
    return useKeyStroke(keys, handler, Object.assign(Object.assign({}, options), { eventName: 'keydown' }));
}
/**
 * Listen for keyboard keys on keypress.
 *
 * @param keys
 * @param handler
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/onKeyStroke
 */
function useKeyPressed(keys, handler, options = {}) {
    return useKeyStroke(keys, handler, Object.assign(Object.assign({}, options), { eventName: 'keypress' }));
}
/**
 * Listen for keyboard keys on keyup.
 *
 * @param keys
 * @param handler
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/onKeyStroke
 */
function useKeyUp(keys, handler, options = {}) {
    return useKeyStroke(keys, handler, Object.assign(Object.assign({}, options), { eventName: 'keyup' }));
}

/**
 * Modified `useState` hook that syncs with localStorage.
 *
 * @param key
 * @param initialValue
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useLocalStorage
 */
function useLocalStorage(key, initialValue, options) {
    const [storedValue, setStoredValue] = useState(initialValue);
    const { deserialize = JSON.parse, serialize = JSON.stringify } = options || {};
    useMount(() => {
        try {
            const item = localStorage.getItem(key);
            item && setStoredValue(deserialize(item));
        }
        catch (error) {
            console.error(error);
        }
    });
    const setValue = useCallback((value) => {
        try {
            localStorage.setItem(key, serialize(value));
            setStoredValue(value);
        }
        catch (error) {
            console.error(error);
        }
    }, [key, serialize]);
    return [storedValue, setValue];
}

const buildState = (trigger) => {
    const { state, length } = (_window === null || _window === void 0 ? void 0 : _window.history) || {};
    const { hash, host, hostname, href, origin, pathname, port, protocol, search } = (_window === null || _window === void 0 ? void 0 : _window.location) || {};
    return {
        trigger,
        state,
        length,
        hash,
        host,
        hostname,
        href,
        origin,
        pathname,
        port,
        protocol,
        search
    };
};
/**
 * Reactive browser location.
 *
 * @see https://react-hooks-library.vercel.app/core/useLocation
 *
 */
function useLocation() {
    const [state, setState] = useState(null);
    useMount(() => {
        setState(buildState('load'));
    });
    useEventListener('popstate', () => setState(buildState('popstate')), {
        passive: true
    });
    useEventListener('hashchange', () => setState(buildState('hashchange')), {
        passive: true
    });
    return state;
}

/**
 * Reactive `mediaDevices.getUserMedia` streaming
 *
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useMediaStream
 */
function useMediaStream(options = {}) {
    const { audioDeviceId, videoDeviceId, autoSwitch } = options;
    const isSupported = useIsSupported(() => { var _a; return !!((_a = _navigator === null || _navigator === void 0 ? void 0 : _navigator.mediaDevices) === null || _a === void 0 ? void 0 : _a.getUserMedia); });
    const stream = useRef(null);
    const ref = useRef(null);
    const [isPlaying, setPlaying] = useState(false);
    const [isAudioMuted, setAudioMuted] = useState(false);
    const [isVideoMuted, setVideoMuted] = useState(false);
    const getDeviceOptions = useCallback((device) => {
        if (device === 'none' || device === false)
            return false;
        if (device === null)
            return true;
        return {
            deviceId: device
        };
    }, []);
    const play = useCallback(async () => {
        var _a;
        if (!isSupported || stream.current)
            return;
        stream.current =
            (_a = (await (_navigator === null || _navigator === void 0 ? void 0 : _navigator.mediaDevices.getUserMedia({
                video: getDeviceOptions(videoDeviceId),
                audio: getDeviceOptions(audioDeviceId)
            })))) !== null && _a !== void 0 ? _a : null;
        setPlaying(true);
        return stream.current;
    }, [audioDeviceId, getDeviceOptions, isSupported, videoDeviceId]);
    const stop = useCallback(() => {
        var _a;
        setPlaying(false);
        (_a = stream.current) === null || _a === void 0 ? void 0 : _a.getTracks().forEach((t) => t.stop());
        stream.current = null;
    }, []);
    const restart = useCallback(async () => {
        stop();
        return await play();
    }, [play, stop]);
    const muteAudio = useCallback(() => {
        var _a;
        setAudioMuted(true);
        (_a = stream.current) === null || _a === void 0 ? void 0 : _a.getAudioTracks().forEach((t) => (t.enabled = false));
    }, []);
    const unMuteAudio = useCallback(() => {
        var _a;
        setAudioMuted(false);
        (_a = stream.current) === null || _a === void 0 ? void 0 : _a.getAudioTracks().forEach((t) => (t.enabled = true));
    }, []);
    const muteVideo = useCallback(() => {
        var _a;
        setVideoMuted(true);
        (_a = stream.current) === null || _a === void 0 ? void 0 : _a.getVideoTracks().forEach((t) => (t.enabled = false));
    }, []);
    const unMuteVideo = useCallback(() => {
        var _a;
        setVideoMuted(false);
        (_a = stream.current) === null || _a === void 0 ? void 0 : _a.getVideoTracks().forEach((t) => (t.enabled = true));
    }, []);
    const pause = useCallback(() => {
        muteAudio();
        muteVideo();
    }, [muteAudio, muteVideo]);
    const resume = useCallback(() => {
        unMuteAudio();
        unMuteVideo();
    }, [unMuteAudio, unMuteVideo]);
    useEffect(() => {
        if (!ref.current)
            return;
        ref.current.srcObject = stream.current;
    }, [isPlaying]);
    useEffect(() => {
        if (autoSwitch && stream.current)
            restart();
    }, [videoDeviceId, audioDeviceId, autoSwitch, restart]);
    return {
        isSupported,
        ref,
        stream,
        isPlaying,
        play,
        stop,
        restart,
        isAudioMuted,
        muteAudio,
        unMuteAudio,
        isVideoMuted,
        muteVideo,
        unMuteVideo,
        pause,
        resume,
        isPaused: isAudioMuted && isVideoMuted
    };
}

/**
 * Run a function synchronously when a component is mounted and after DOM is painted.
 *
 * @deprecated This hook breaks in React 18's strict mode, since it's not idempotent
 *
 * @param callback function to be executed
 */
function useMountSync(callback) {
    useLayoutEffect(callback, []);
}

/**
 *
 * Reactive mouse position based by page or client
 *
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useMouse
 */
function useMouse(options = {}) {
    const { touch = true, type = 'client', resetOnTouchEnds = false, initialValue = { x: 0, y: 0 } } = options;
    const [x, setX] = useState(initialValue.x);
    const [y, setY] = useState(initialValue.y);
    const [source, setSource] = useState(null);
    useEffect(() => {
        const mouseHandler = (event) => {
            setSource('mouse');
            if (type === 'page') {
                setX(event.pageX);
                setY(event.pageY);
            }
            else if (type === 'client') {
                setX(event.clientX);
                setY(event.clientY);
            }
        };
        const reset = () => {
            setX(initialValue.x);
            setY(initialValue.y);
        };
        const touchHandler = (event) => {
            if (event.touches.length > 0) {
                setSource('touch');
                if (type === 'page') {
                    setX(event.touches[0].pageX);
                    setY(event.touches[0].pageY);
                }
                else if (type === 'client') {
                    setX(event.touches[0].clientX);
                    setY(event.touches[0].clientY);
                }
            }
        };
        window.addEventListener('mousemove', mouseHandler, { passive: true });
        window.addEventListener('dragover', mouseHandler, { passive: true });
        if (touch) {
            window.addEventListener('touchstart', touchHandler, { passive: true });
            window.addEventListener('touchmove', touchHandler, { passive: true });
            if (resetOnTouchEnds)
                window.addEventListener('touchend', reset, { passive: true });
        }
        return () => {
            window.removeEventListener('mousemove', mouseHandler);
            window.removeEventListener('dragover', mouseHandler);
            if (touch) {
                window.removeEventListener('touchstart', touchHandler);
                window.removeEventListener('touchmove', touchHandler);
                if (resetOnTouchEnds)
                    window.removeEventListener('touchend', reset);
            }
        };
    }, [initialValue.x, initialValue.y, resetOnTouchEnds, touch, type]);
    return { x, y, source };
}

/**
 * Watch for changes being made to the DOM tree.
 *
 * @param target - React ref or DOM node
 * @param callback - callback to execute when mutations are observed
 * @param options - Options passed to mutation observer
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/MutationObserver MutationObserver MDN
 * @see https://react-hooks-library.vercel.app/core/useMutationObserver
 */
function useMutationObserver(target, callback, options = {}) {
    const observer = useRef(null);
    const isSupported = useIsSupported(() => !!(_window === null || _window === void 0 ? void 0 : _window.IntersectionObserver));
    const stop = useCallback(() => {
        if (!observer.current)
            return;
        observer.current.disconnect();
        observer.current = null;
    }, []);
    useUnMount(stop);
    useEffect(() => {
        var _a;
        const el = unRef(target);
        if (!(isSupported && el && _window))
            return;
        observer.current = new _window.MutationObserver(callback);
        (_a = observer.current) === null || _a === void 0 ? void 0 : _a.observe(el, options);
        return stop;
    }, [callback, stop, options, target, isSupported]);
    return {
        isSupported,
        stop
    };
}

/**
 * Reactive Network status.
 *
 * @see https://react-hooks-library.vercel.app/core/useNetwork
 */
function useNetwork() {
    var _a, _b, _c, _d, _e, _f;
    const isSupported = useIsSupported(() => !!(_navigator === null || _navigator === void 0 ? void 0 : _navigator.connection));
    const [isOnline, setIsOnline] = useState(true);
    const [offlineAt, setOfflineAt] = useState(undefined);
    const connection = useRef(undefined);
    const rerender = useState({})[1];
    useMount(() => {
        if (!_navigator)
            return;
        setIsOnline(_navigator.onLine);
        setOfflineAt(isOnline ? undefined : Date.now());
        const _connection = _navigator === null || _navigator === void 0 ? void 0 : _navigator.connection;
        if (!_connection)
            return;
        connection.current = _connection;
        connection.current.onchange = () => rerender({});
    });
    useEventListener('offline', () => {
        setIsOnline(false);
        setOfflineAt(Date.now());
    });
    useEventListener('online', () => {
        setIsOnline(true);
    });
    return {
        isSupported,
        isOnline,
        offlineAt,
        saveData: (_a = connection.current) === null || _a === void 0 ? void 0 : _a.saveData,
        rtt: (_b = connection.current) === null || _b === void 0 ? void 0 : _b.rtt,
        downlink: (_c = connection.current) === null || _c === void 0 ? void 0 : _c.downlink,
        downlinkMax: (_d = connection.current) === null || _d === void 0 ? void 0 : _d.downlinkMax,
        effectiveType: (_e = connection.current) === null || _e === void 0 ? void 0 : _e.effectiveType,
        type: (_f = connection.current) === null || _f === void 0 ? void 0 : _f.type
    };
}

/**
 * Reactive online status
 *
 * @see https://react-hooks-library.vercel.app/core/useOnline
 */
function useOnline() {
    const [online, setOnline] = useState(false);
    useMount(() => {
        setOnline(navigator.onLine);
    });
    useEventListener('offline', () => {
        setOnline(false);
    });
    useEventListener('online', () => {
        setOnline(true);
    });
    return online;
}

/**
 * Reactive prefers-color-scheme media query.
 *
 * @see https://react-hooks-library.vercel.app/core/usePreferredColorScheme
 */
function usePreferredColorScheme() {
    const isDark = useMediaQuery('(prefers-color-scheme: dark)');
    return isDark ? 'dark' : 'light';
}

/**
 * Returns the value of the argument from the previous render
 * @param {T} value
 * @returns {T | undefined} previous value
 * @see https://react-hooks-library.vercel.app/core/usePrevious
 */
function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
        ref.current = value;
    }, [value]);
    return ref.current;
}

/**
 * Reactive screen sharing
 *
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useScreenShare
 */
function useScreenShare(options = {}) {
    const { audio = true, video = true } = options;
    const isSupported = useIsSupported(() => { var _a; return !!((_a = _navigator === null || _navigator === void 0 ? void 0 : _navigator.mediaDevices) === null || _a === void 0 ? void 0 : _a.getDisplayMedia); });
    const stream = useRef(null);
    const ref = useRef(null);
    const [isPlaying, setPlaying] = useState(false);
    const play = useCallback(async () => {
        var _a;
        if (!isSupported || !ref.current)
            return;
        stream.current =
            (_a = (await (_navigator === null || _navigator === void 0 ? void 0 : _navigator.mediaDevices.getDisplayMedia({
                audio,
                video
            })))) !== null && _a !== void 0 ? _a : null;
        setPlaying(true);
        return stream.current;
    }, [audio, isSupported, video]);
    const stop = useCallback(() => {
        var _a;
        (_a = stream.current) === null || _a === void 0 ? void 0 : _a.getTracks().forEach((t) => t.stop());
        stream.current = null;
        setPlaying(false);
    }, []);
    useEffect(() => {
        var _a;
        if (!ref.current)
            return;
        ref.current.srcObject = stream.current;
        // Handle os native stop screen sharing buttons
        (_a = stream.current) === null || _a === void 0 ? void 0 : _a.getVideoTracks()[0].addEventListener('ended', stop);
    }, [isPlaying, stop]);
    return {
        isSupported,
        isPlaying,
        ref,
        stream,
        play,
        stop
    };
}

/**
 * Reactive scroll values for a react ref or a dom node
 *
 * @param target - dom node or react ref
 * @param callback - callback to run on scroll
 *
 * @see https://react-hooks-library.vercel.app/core/useScroll
 */
function useScroll(target = _document === null || _document === void 0 ? void 0 : _document.documentElement, callback) {
    const getPositions = () => {
        const el = unRef(target);
        if (!el)
            return;
        return {
            x: round(el.scrollLeft / (el.scrollWidth - el.clientWidth)),
            y: round(el.scrollTop / (el.scrollHeight - el.clientHeight))
        };
    };
    useEventListener(target, 'scroll', () => {
        const newScrollValues = getPositions();
        if (!newScrollValues)
            return;
        const { x, y } = newScrollValues;
        callback({ scrollX: x, scrollY: y });
    }, {
        capture: false,
        passive: true
    });
}

/**
 *
 * A hook to scroll an element into view on mounting.
 *
 * @param options {UseScrollIntoViewOptions}
 *
 * @see https://react-hooks-library.vercel.app/core/useScrollIntoView
 */
function useScrollIntoView(target, options = {}) {
    const { behavior = 'auto', block = 'start', inline = 'nearest', scrollMargin = '0px', predicate = true } = options;
    useMount(() => {
        const el = unRef(target);
        if (!(el && (isFunction(predicate) ? predicate() : predicate))) {
            return;
        }
        el.style.scrollMargin = scrollMargin;
        el.scrollIntoView({
            behavior,
            block,
            inline
        });
    });
}

/**
 * Modified `useState` hook that syncs with useSessionStorage.
 *
 * @param key
 * @param initialValue
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useSessionStorage
 */
function useSessionStorage(key, initialValue, options) {
    const [storedValue, setStoredValue] = useState(initialValue);
    const { deserialize = JSON.parse, serialize = JSON.stringify } = options || {};
    useMount(() => {
        try {
            const item = sessionStorage.getItem(key);
            item && setStoredValue(deserialize(item));
        }
        catch (error) {
            console.error(error);
        }
    });
    const setValue = useCallback((value) => {
        try {
            setStoredValue(value);
            sessionStorage.setItem(key, serialize(value));
        }
        catch (error) {
            console.error(error);
        }
    }, [key, serialize]);
    return [storedValue, setValue];
}

/**
 * useState hook with custom compare function to avoid re-rendering
 * when state is the same, compares with previous state
 *
 *
 * Note: create a custom compare function, outside of the hook to keep
 * a stable reference, otherwise it will be recreated on every render
 *
 * @see https://react-hooks-library.vercel.app/core/useStateCompare
 */
function useStateCompare({ initialValue, compare }) {
    const [state, _setState] = useState(initialValue);
    const setState = useCallback((value) => {
        typeof value === 'function'
            ? _setState(value)
            : _setState((oldValue) => compare(oldValue, value));
    }, [compare]);
    return [state, setState];
}

/**
 *
 * useState with built in undo and redo history control
 *
 * @param defaultValue
 * @param options
 * @returns
 */
function useStateHistory(defaultValue, options = {}) {
    const { maxHistory = 10 } = options;
    const [state, setState] = useState(defaultValue);
    const lastSaved = useRef(isFunction(defaultValue) ? defaultValue() : defaultValue);
    const rerender = useState({})[1];
    const actionHistory = useRef([]);
    const redoHistory = useRef([]);
    const redoAllowed = useRef(false);
    const push = useCallback((value) => {
        if (actionHistory.current.length < maxHistory) {
            actionHistory.current.push(value);
        }
        else {
            actionHistory.current = [...actionHistory.current.slice(1), value];
            lastSaved.current = actionHistory.current[0];
        }
        redoAllowed.current = false;
        setState(value);
    }, [maxHistory]);
    const redo = useCallback(() => {
        if (!(redoHistory.current.length && redoAllowed.current))
            return;
        const lastUndoState = redoHistory.current.pop();
        lastUndoState && push(lastUndoState);
        redoAllowed.current = true;
    }, [push]);
    const undo = useCallback(() => {
        if (actionHistory.current.length < 1)
            return;
        const lastState = actionHistory.current.pop();
        lastState && redoHistory.current.push(lastState);
        const prev = actionHistory.current[actionHistory.current.length - 1];
        prev ? setState(prev) : setState(lastSaved.current);
        rerender({});
        redoAllowed.current = true;
    }, [rerender]);
    const reset = useCallback(() => {
        var _a;
        if (!((_a = actionHistory.current) === null || _a === void 0 ? void 0 : _a.length))
            return;
        setState(actionHistory.current[0]);
        actionHistory.current = [actionHistory.current[0]];
    }, []);
    return {
        state,
        push,
        undo,
        redo,
        reset,
        history: actionHistory.current,
        redoAllowed: redoAllowed.current
    };
}

/**
 * Reactive document title hook
 *
 * Set title or observe dom mutation reactively
 *
 * @param newTitle optional
 * @see https://react-hooks-library.vercel.app/core/useTitle
 */
function useTitle(newTitle) {
    const [title, setTitle] = useState(newTitle !== null && newTitle !== void 0 ? newTitle : '');
    useMount(() => {
        var _a;
        setTitle((_a = (newTitle || (_document === null || _document === void 0 ? void 0 : _document.title))) !== null && _a !== void 0 ? _a : '');
    });
    useEffect(() => {
        document.title = title;
    }, [title]);
    useMutationObserver(_document === null || _document === void 0 ? void 0 : _document.head.querySelector('title'), () => {
        if (document.title !== title)
            setTitle(document.title);
    }, { childList: true });
    return { title, setTitle };
}

/**
 * A state toggle hook
 *
 * @param defaultValue
 * @default false
 *
 * @see https://react-hooks-library.vercel.app/core/useToggle
 */
function useToggle(defaultValue = false) {
    const [bool, setBool] = useState(defaultValue);
    const toggle = useCallback(() => setBool((s) => !s), []);
    const setTrue = useCallback(() => setBool(true), []);
    const setFalse = useCallback(() => setBool(false), []);
    return { bool, toggle, setTrue, setFalse };
}

/**
 * Reactive window size.
 *
 * @param options
 *
 * @see https://react-hooks-library.vercel.app/core/useWindowSize
 */
function useWindowSize({ initialWidth = Infinity, initialHeight = Infinity } = {}) {
    const [width, setWidth] = useState(initialWidth);
    const [height, setHeight] = useState(initialHeight);
    useMount(() => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    });
    useEventListener('resize', () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    }, { passive: true });
    return { width, height };
}

export { BreakPointHooks, breakpointsAntDesign, breakpointsBootstrapV5, breakpointsSematic, breakpointsTailwind, breakpointsVuetify, useActiveElement, useAsyncCallback, useClickOutside, useDebounce, useEffectAfterMount, useEventListener, useFont, useHasMounted, useHover, useIntersectionObserver, useInterval, useIsSupported, useKeyDown, useKeyPressed, useKeyStroke, useKeyUp, useLocalStorage, useLocation, useMediaQuery, useMediaStream, useMount, useMountSync, useMouse, useMutationObserver, useNetwork, useOnline, usePreferredColorScheme, usePrevious, useScreenShare, useScroll, useScrollIntoView, useSessionStorage, useStateCompare, useStateHistory, useTitle, useToggle, useUnMount, useWindowSize };
