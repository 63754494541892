{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-06-02",
    "endpointPrefix": "shield",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWS Shield",
    "serviceFullName": "AWS Shield",
    "serviceId": "Shield",
    "signatureVersion": "v4",
    "targetPrefix": "AWSShield_20160616",
    "uid": "shield-2016-06-02"
  },
  "operations": {
    "AssociateDRTLogBucket": {
      "input": {
        "type": "structure",
        "required": [
          "LogBucket"
        ],
        "members": {
          "LogBucket": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateDRTRole": {
      "input": {
        "type": "structure",
        "required": [
          "RoleArn"
        ],
        "members": {
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateProtection": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ResourceArn"
        ],
        "members": {
          "Name": {},
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProtectionId": {}
        }
      }
    },
    "CreateSubscription": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProtection": {
      "input": {
        "type": "structure",
        "required": [
          "ProtectionId"
        ],
        "members": {
          "ProtectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSubscription": {
      "input": {
        "type": "structure",
        "members": {},
        "deprecated": true
      },
      "output": {
        "type": "structure",
        "members": {},
        "deprecated": true
      },
      "deprecated": true
    },
    "DescribeAttack": {
      "input": {
        "type": "structure",
        "required": [
          "AttackId"
        ],
        "members": {
          "AttackId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Attack": {
            "type": "structure",
            "members": {
              "AttackId": {},
              "ResourceArn": {},
              "SubResources": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Type": {},
                    "Id": {},
                    "AttackVectors": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "VectorType"
                        ],
                        "members": {
                          "VectorType": {},
                          "VectorCounters": {
                            "shape": "Ss"
                          }
                        }
                      }
                    },
                    "Counters": {
                      "shape": "Ss"
                    }
                  }
                }
              },
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              },
              "AttackCounters": {
                "shape": "Ss"
              },
              "AttackProperties": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "AttackLayer": {},
                    "AttackPropertyIdentifier": {},
                    "TopContributors": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Name": {},
                          "Value": {
                            "type": "long"
                          }
                        }
                      }
                    },
                    "Unit": {},
                    "Total": {
                      "type": "long"
                    }
                  }
                }
              },
              "Mitigations": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "MitigationName": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeDRTAccess": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "RoleArn": {},
          "LogBucketList": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "DescribeEmergencyContactSettings": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "EmergencyContactList": {
            "shape": "S1c"
          }
        }
      }
    },
    "DescribeProtection": {
      "input": {
        "type": "structure",
        "members": {
          "ProtectionId": {},
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Protection": {
            "shape": "S1h"
          }
        }
      }
    },
    "DescribeSubscription": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Subscription": {
            "type": "structure",
            "members": {
              "StartTime": {
                "type": "timestamp"
              },
              "EndTime": {
                "type": "timestamp"
              },
              "TimeCommitmentInSeconds": {
                "type": "long"
              },
              "AutoRenew": {},
              "Limits": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Type": {},
                    "Max": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DisassociateDRTLogBucket": {
      "input": {
        "type": "structure",
        "required": [
          "LogBucket"
        ],
        "members": {
          "LogBucket": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateDRTRole": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetSubscriptionState": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "SubscriptionState"
        ],
        "members": {
          "SubscriptionState": {}
        }
      }
    },
    "ListAttacks": {
      "input": {
        "type": "structure",
        "members": {
          "ResourceArns": {
            "type": "list",
            "member": {}
          },
          "StartTime": {
            "shape": "S1z"
          },
          "EndTime": {
            "shape": "S1z"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AttackSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AttackId": {},
                "ResourceArn": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "EndTime": {
                  "type": "timestamp"
                },
                "AttackVectors": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "VectorType"
                    ],
                    "members": {
                      "VectorType": {}
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListProtections": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Protections": {
            "type": "list",
            "member": {
              "shape": "S1h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "UpdateEmergencyContactSettings": {
      "input": {
        "type": "structure",
        "members": {
          "EmergencyContactList": {
            "shape": "S1c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateSubscription": {
      "input": {
        "type": "structure",
        "members": {
          "AutoRenew": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Max": {
            "type": "double"
          },
          "Average": {
            "type": "double"
          },
          "Sum": {
            "type": "double"
          },
          "N": {
            "type": "integer"
          },
          "Unit": {}
        }
      }
    },
    "S1c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "EmailAddress"
        ],
        "members": {
          "EmailAddress": {}
        }
      }
    },
    "S1h": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "ResourceArn": {}
      }
    },
    "S1z": {
      "type": "structure",
      "members": {
        "FromInclusive": {
          "type": "timestamp"
        },
        "ToExclusive": {
          "type": "timestamp"
        }
      }
    }
  }
}