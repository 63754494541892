{
  "metadata": {
    "apiVersion": "2018-11-07",
    "endpointPrefix": "mediapackage-vod",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "MediaPackage Vod",
    "serviceFullName": "AWS Elemental MediaPackage VOD",
    "serviceId": "MediaPackage Vod",
    "signatureVersion": "v4",
    "signingName": "mediapackage-vod",
    "uid": "mediapackage-vod-2018-11-07"
  },
  "operations": {
    "CreateAsset": {
      "http": {
        "requestUri": "/assets",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "locationName": "id"
          },
          "PackagingGroupId": {
            "locationName": "packagingGroupId"
          },
          "ResourceId": {
            "locationName": "resourceId"
          },
          "SourceArn": {
            "locationName": "sourceArn"
          },
          "SourceRoleArn": {
            "locationName": "sourceRoleArn"
          }
        },
        "required": [
          "SourceArn",
          "Id",
          "PackagingGroupId",
          "SourceRoleArn"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "EgressEndpoints": {
            "locationName": "egressEndpoints",
            "shape": "S4"
          },
          "Id": {
            "locationName": "id"
          },
          "PackagingGroupId": {
            "locationName": "packagingGroupId"
          },
          "ResourceId": {
            "locationName": "resourceId"
          },
          "SourceArn": {
            "locationName": "sourceArn"
          },
          "SourceRoleArn": {
            "locationName": "sourceRoleArn"
          }
        },
        "type": "structure"
      }
    },
    "CreatePackagingConfiguration": {
      "http": {
        "requestUri": "/packaging_configurations",
        "responseCode": 200
      },
      "input": {
        "members": {
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S7"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "Si"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sn"
          },
          "Id": {
            "locationName": "id"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sq"
          },
          "PackagingGroupId": {
            "locationName": "packagingGroupId"
          }
        },
        "required": [
          "Id",
          "PackagingGroupId"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S7"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "Si"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sn"
          },
          "Id": {
            "locationName": "id"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sq"
          },
          "PackagingGroupId": {
            "locationName": "packagingGroupId"
          }
        },
        "type": "structure"
      }
    },
    "CreatePackagingGroup": {
      "http": {
        "requestUri": "/packaging_groups",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Id": {
            "locationName": "id"
          }
        },
        "type": "structure"
      }
    },
    "DeleteAsset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/assets/{id}",
        "responseCode": 202
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeletePackagingConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/packaging_configurations/{id}",
        "responseCode": 202
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeletePackagingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/packaging_groups/{id}",
        "responseCode": 202
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DescribeAsset": {
      "http": {
        "method": "GET",
        "requestUri": "/assets/{id}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "EgressEndpoints": {
            "locationName": "egressEndpoints",
            "shape": "S4"
          },
          "Id": {
            "locationName": "id"
          },
          "PackagingGroupId": {
            "locationName": "packagingGroupId"
          },
          "ResourceId": {
            "locationName": "resourceId"
          },
          "SourceArn": {
            "locationName": "sourceArn"
          },
          "SourceRoleArn": {
            "locationName": "sourceRoleArn"
          }
        },
        "type": "structure"
      }
    },
    "DescribePackagingConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/packaging_configurations/{id}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S7"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "Si"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sn"
          },
          "Id": {
            "locationName": "id"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sq"
          },
          "PackagingGroupId": {
            "locationName": "packagingGroupId"
          }
        },
        "type": "structure"
      }
    },
    "DescribePackagingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/packaging_groups/{id}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Id": {
            "locationName": "id"
          }
        },
        "type": "structure"
      }
    },
    "ListAssets": {
      "http": {
        "method": "GET",
        "requestUri": "/assets",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "PackagingGroupId": {
            "location": "querystring",
            "locationName": "packagingGroupId"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "Assets": {
            "locationName": "assets",
            "member": {
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "Id": {
                  "locationName": "id"
                },
                "PackagingGroupId": {
                  "locationName": "packagingGroupId"
                },
                "ResourceId": {
                  "locationName": "resourceId"
                },
                "SourceArn": {
                  "locationName": "sourceArn"
                },
                "SourceRoleArn": {
                  "locationName": "sourceRoleArn"
                }
              },
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        },
        "type": "structure"
      }
    },
    "ListPackagingConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/packaging_configurations",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "PackagingGroupId": {
            "location": "querystring",
            "locationName": "packagingGroupId"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "PackagingConfigurations": {
            "locationName": "packagingConfigurations",
            "member": {
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "CmafPackage": {
                  "locationName": "cmafPackage",
                  "shape": "S7"
                },
                "DashPackage": {
                  "locationName": "dashPackage",
                  "shape": "Si"
                },
                "HlsPackage": {
                  "locationName": "hlsPackage",
                  "shape": "Sn"
                },
                "Id": {
                  "locationName": "id"
                },
                "MssPackage": {
                  "locationName": "mssPackage",
                  "shape": "Sq"
                },
                "PackagingGroupId": {
                  "locationName": "packagingGroupId"
                }
              },
              "type": "structure"
            },
            "type": "list"
          }
        },
        "type": "structure"
      }
    },
    "ListPackagingGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/packaging_groups",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "PackagingGroups": {
            "locationName": "packagingGroups",
            "member": {
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "Id": {
                  "locationName": "id"
                }
              },
              "type": "structure"
            },
            "type": "list"
          }
        },
        "type": "structure"
      }
    }
  },
  "shapes": {
    "S4": {
      "member": {
        "members": {
          "PackagingConfigurationId": {
            "locationName": "packagingConfigurationId"
          },
          "Url": {
            "locationName": "url"
          }
        },
        "type": "structure"
      },
      "type": "list"
    },
    "S7": {
      "members": {
        "Encryption": {
          "locationName": "encryption",
          "members": {
            "SpekeKeyProvider": {
              "locationName": "spekeKeyProvider",
              "shape": "S9"
            }
          },
          "required": [
            "SpekeKeyProvider"
          ],
          "type": "structure"
        },
        "HlsManifests": {
          "locationName": "hlsManifests",
          "shape": "Sb"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        }
      },
      "required": [
        "HlsManifests"
      ],
      "type": "structure"
    },
    "S9": {
      "members": {
        "RoleArn": {
          "locationName": "roleArn"
        },
        "SystemIds": {
          "locationName": "systemIds",
          "member": {},
          "type": "list"
        },
        "Url": {
          "locationName": "url"
        }
      },
      "required": [
        "SystemIds",
        "Url",
        "RoleArn"
      ],
      "type": "structure"
    },
    "Sb": {
      "member": {
        "members": {
          "AdMarkers": {
            "locationName": "adMarkers"
          },
          "IncludeIframeOnlyStream": {
            "locationName": "includeIframeOnlyStream",
            "type": "boolean"
          },
          "ManifestName": {
            "locationName": "manifestName"
          },
          "ProgramDateTimeIntervalSeconds": {
            "locationName": "programDateTimeIntervalSeconds",
            "type": "integer"
          },
          "RepeatExtXKey": {
            "locationName": "repeatExtXKey",
            "type": "boolean"
          },
          "StreamSelection": {
            "locationName": "streamSelection",
            "shape": "Sg"
          }
        },
        "type": "structure"
      },
      "type": "list"
    },
    "Sg": {
      "members": {
        "MaxVideoBitsPerSecond": {
          "locationName": "maxVideoBitsPerSecond",
          "type": "integer"
        },
        "MinVideoBitsPerSecond": {
          "locationName": "minVideoBitsPerSecond",
          "type": "integer"
        },
        "StreamOrder": {
          "locationName": "streamOrder"
        }
      },
      "type": "structure"
    },
    "Si": {
      "members": {
        "DashManifests": {
          "locationName": "dashManifests",
          "member": {
            "members": {
              "ManifestName": {
                "locationName": "manifestName"
              },
              "MinBufferTimeSeconds": {
                "locationName": "minBufferTimeSeconds",
                "type": "integer"
              },
              "Profile": {
                "locationName": "profile"
              },
              "StreamSelection": {
                "locationName": "streamSelection",
                "shape": "Sg"
              }
            },
            "type": "structure"
          },
          "type": "list"
        },
        "Encryption": {
          "locationName": "encryption",
          "members": {
            "SpekeKeyProvider": {
              "locationName": "spekeKeyProvider",
              "shape": "S9"
            }
          },
          "required": [
            "SpekeKeyProvider"
          ],
          "type": "structure"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        }
      },
      "required": [
        "DashManifests"
      ],
      "type": "structure"
    },
    "Sn": {
      "members": {
        "Encryption": {
          "locationName": "encryption",
          "members": {
            "ConstantInitializationVector": {
              "locationName": "constantInitializationVector"
            },
            "EncryptionMethod": {
              "locationName": "encryptionMethod"
            },
            "SpekeKeyProvider": {
              "locationName": "spekeKeyProvider",
              "shape": "S9"
            }
          },
          "required": [
            "SpekeKeyProvider"
          ],
          "type": "structure"
        },
        "HlsManifests": {
          "locationName": "hlsManifests",
          "shape": "Sb"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        },
        "UseAudioRenditionGroup": {
          "locationName": "useAudioRenditionGroup",
          "type": "boolean"
        }
      },
      "required": [
        "HlsManifests"
      ],
      "type": "structure"
    },
    "Sq": {
      "members": {
        "Encryption": {
          "locationName": "encryption",
          "members": {
            "SpekeKeyProvider": {
              "locationName": "spekeKeyProvider",
              "shape": "S9"
            }
          },
          "required": [
            "SpekeKeyProvider"
          ],
          "type": "structure"
        },
        "MssManifests": {
          "locationName": "mssManifests",
          "member": {
            "members": {
              "ManifestName": {
                "locationName": "manifestName"
              },
              "StreamSelection": {
                "locationName": "streamSelection",
                "shape": "Sg"
              }
            },
            "type": "structure"
          },
          "type": "list"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        }
      },
      "required": [
        "MssManifests"
      ],
      "type": "structure"
    }
  }
}