"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ConfigProvider = exports.ConfigContext = void 0;
const jsx_runtime_1 = require("react/jsx-runtime");
const react_1 = require("react");
exports.ConfigContext = (0, react_1.createContext)(undefined);
const fetchConfig = (name, url) => fetch(url)
    .then((response) => response.json())
    .then((config) => [name, config]);
const ConfigProvider = ({ items, children }) => {
    const [config, setConfig] = (0, react_1.useState)(null);
    (0, react_1.useEffect)(() => {
        Promise.all(items.map(({ name, url }) => fetchConfig(name, url)))
            .then((results) => {
            setConfig(Object.fromEntries(results));
        })
            .catch((error) => {
            setConfig(error);
        });
    }, [items]);
    return (0, jsx_runtime_1.jsx)(exports.ConfigContext.Provider, { value: config, children: children });
};
exports.ConfigProvider = ConfigProvider;
