{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-11-05",
    "endpointPrefix": "transfer",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWS Transfer",
    "serviceFullName": "AWS Transfer for SFTP",
    "serviceId": "Transfer",
    "signatureVersion": "v4",
    "signingName": "transfer",
    "targetPrefix": "TransferService",
    "uid": "transfer-2018-11-05"
  },
  "operations": {
    "CreateServer": {
      "input": {
        "type": "structure",
        "members": {
          "EndpointDetails": {
            "shape": "S2"
          },
          "EndpointType": {},
          "HostKey": {
            "shape": "S5"
          },
          "IdentityProviderDetails": {
            "shape": "S6"
          },
          "IdentityProviderType": {},
          "LoggingRole": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "CreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "Role",
          "ServerId",
          "UserName"
        ],
        "members": {
          "HomeDirectory": {},
          "Policy": {},
          "Role": {},
          "ServerId": {},
          "SshPublicKeyBody": {},
          "Tags": {
            "shape": "Sa"
          },
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      }
    },
    "DeleteServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "DeleteSshPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "SshPublicKeyId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "SshPublicKeyId": {},
          "UserName": {}
        }
      }
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      }
    },
    "DescribeServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Server"
        ],
        "members": {
          "Server": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "EndpointDetails": {
                "shape": "S2"
              },
              "EndpointType": {},
              "HostKeyFingerprint": {},
              "IdentityProviderDetails": {
                "shape": "S6"
              },
              "IdentityProviderType": {},
              "LoggingRole": {},
              "ServerId": {},
              "State": {},
              "Tags": {
                "shape": "Sa"
              },
              "UserCount": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "DescribeUser": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "User"
        ],
        "members": {
          "ServerId": {},
          "User": {
            "type": "structure",
            "required": [
              "Arn"
            ],
            "members": {
              "Arn": {},
              "HomeDirectory": {},
              "Policy": {},
              "Role": {},
              "SshPublicKeys": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "DateImported",
                    "SshPublicKeyBody",
                    "SshPublicKeyId"
                  ],
                  "members": {
                    "DateImported": {
                      "type": "timestamp"
                    },
                    "SshPublicKeyBody": {},
                    "SshPublicKeyId": {}
                  }
                }
              },
              "Tags": {
                "shape": "Sa"
              },
              "UserName": {}
            }
          }
        }
      }
    },
    "ImportSshPublicKey": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "SshPublicKeyBody",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "SshPublicKeyBody": {},
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "SshPublicKeyId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "SshPublicKeyId": {},
          "UserName": {}
        }
      }
    },
    "ListServers": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Servers"
        ],
        "members": {
          "NextToken": {},
          "Servers": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn"
              ],
              "members": {
                "Arn": {},
                "IdentityProviderType": {},
                "EndpointType": {},
                "LoggingRole": {},
                "ServerId": {},
                "State": {},
                "UserCount": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "NextToken": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "Users"
        ],
        "members": {
          "NextToken": {},
          "ServerId": {},
          "Users": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Arn"
              ],
              "members": {
                "Arn": {},
                "HomeDirectory": {},
                "Role": {},
                "SshPublicKeyCount": {
                  "type": "integer"
                },
                "UserName": {}
              }
            }
          }
        }
      }
    },
    "StartServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "StopServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "Tags"
        ],
        "members": {
          "Arn": {},
          "Tags": {
            "shape": "Sa"
          }
        }
      }
    },
    "TestIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {},
          "UserPassword": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StatusCode",
          "Url"
        ],
        "members": {
          "Response": {},
          "StatusCode": {
            "type": "integer"
          },
          "Message": {},
          "Url": {}
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "TagKeys"
        ],
        "members": {
          "Arn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateServer": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "EndpointDetails": {
            "shape": "S2"
          },
          "EndpointType": {},
          "HostKey": {
            "shape": "S5"
          },
          "IdentityProviderDetails": {
            "shape": "S6"
          },
          "LoggingRole": {},
          "ServerId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId"
        ],
        "members": {
          "ServerId": {}
        }
      }
    },
    "UpdateUser": {
      "input": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "HomeDirectory": {},
          "Policy": {},
          "Role": {},
          "ServerId": {},
          "UserName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ServerId",
          "UserName"
        ],
        "members": {
          "ServerId": {},
          "UserName": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "members": {
        "VpcEndpointId": {}
      }
    },
    "S5": {
      "type": "string",
      "sensitive": true
    },
    "S6": {
      "type": "structure",
      "members": {
        "Url": {},
        "InvocationRole": {}
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}