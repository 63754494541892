{
  "metadata": {
    "apiVersion": "2017-10-12",
    "endpointPrefix": "mediapackage",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "MediaPackage",
    "serviceFullName": "AWS Elemental MediaPackage",
    "serviceId": "MediaPackage",
    "signatureVersion": "v4",
    "signingName": "mediapackage",
    "uid": "mediapackage-2017-10-12"
  },
  "operations": {
    "CreateChannel": {
      "http": {
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Description": {
            "locationName": "description"
          },
          "Id": {
            "locationName": "id"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsIngest": {
            "locationName": "hlsIngest",
            "shape": "S5"
          },
          "Id": {
            "locationName": "id"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          }
        },
        "type": "structure"
      }
    },
    "CreateOriginEndpoint": {
      "http": {
        "requestUri": "/origin_endpoints",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelId": {
            "locationName": "channelId"
          },
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S9"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "So"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sv"
          },
          "Id": {
            "locationName": "id"
          },
          "ManifestName": {
            "locationName": "manifestName"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sy"
          },
          "StartoverWindowSeconds": {
            "locationName": "startoverWindowSeconds",
            "type": "integer"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          },
          "TimeDelaySeconds": {
            "locationName": "timeDelaySeconds",
            "type": "integer"
          },
          "Whitelist": {
            "locationName": "whitelist",
            "shape": "Sd"
          }
        },
        "required": [
          "ChannelId",
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "ChannelId": {
            "locationName": "channelId"
          },
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S11"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "So"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sv"
          },
          "Id": {
            "locationName": "id"
          },
          "ManifestName": {
            "locationName": "manifestName"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sy"
          },
          "StartoverWindowSeconds": {
            "locationName": "startoverWindowSeconds",
            "type": "integer"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          },
          "TimeDelaySeconds": {
            "locationName": "timeDelaySeconds",
            "type": "integer"
          },
          "Url": {
            "locationName": "url"
          },
          "Whitelist": {
            "locationName": "whitelist",
            "shape": "Sd"
          }
        },
        "type": "structure"
      }
    },
    "DeleteChannel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/channels/{id}",
        "responseCode": 202
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DeleteOriginEndpoint": {
      "http": {
        "method": "DELETE",
        "requestUri": "/origin_endpoints/{id}",
        "responseCode": 202
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {},
        "type": "structure"
      }
    },
    "DescribeChannel": {
      "http": {
        "method": "GET",
        "requestUri": "/channels/{id}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsIngest": {
            "locationName": "hlsIngest",
            "shape": "S5"
          },
          "Id": {
            "locationName": "id"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          }
        },
        "type": "structure"
      }
    },
    "DescribeOriginEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/origin_endpoints/{id}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "ChannelId": {
            "locationName": "channelId"
          },
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S11"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "So"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sv"
          },
          "Id": {
            "locationName": "id"
          },
          "ManifestName": {
            "locationName": "manifestName"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sy"
          },
          "StartoverWindowSeconds": {
            "locationName": "startoverWindowSeconds",
            "type": "integer"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          },
          "TimeDelaySeconds": {
            "locationName": "timeDelaySeconds",
            "type": "integer"
          },
          "Url": {
            "locationName": "url"
          },
          "Whitelist": {
            "locationName": "whitelist",
            "shape": "Sd"
          }
        },
        "type": "structure"
      }
    },
    "ListChannels": {
      "http": {
        "method": "GET",
        "requestUri": "/channels",
        "responseCode": 200
      },
      "input": {
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "Channels": {
            "locationName": "channels",
            "member": {
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "Description": {
                  "locationName": "description"
                },
                "HlsIngest": {
                  "locationName": "hlsIngest",
                  "shape": "S5"
                },
                "Id": {
                  "locationName": "id"
                },
                "Tags": {
                  "locationName": "tags",
                  "shape": "S3"
                }
              },
              "type": "structure"
            },
            "type": "list"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        },
        "type": "structure"
      }
    },
    "ListOriginEndpoints": {
      "http": {
        "method": "GET",
        "requestUri": "/origin_endpoints",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ChannelId": {
            "location": "querystring",
            "locationName": "channelId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        },
        "type": "structure"
      },
      "output": {
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "OriginEndpoints": {
            "locationName": "originEndpoints",
            "member": {
              "members": {
                "Arn": {
                  "locationName": "arn"
                },
                "ChannelId": {
                  "locationName": "channelId"
                },
                "CmafPackage": {
                  "locationName": "cmafPackage",
                  "shape": "S11"
                },
                "DashPackage": {
                  "locationName": "dashPackage",
                  "shape": "So"
                },
                "Description": {
                  "locationName": "description"
                },
                "HlsPackage": {
                  "locationName": "hlsPackage",
                  "shape": "Sv"
                },
                "Id": {
                  "locationName": "id"
                },
                "ManifestName": {
                  "locationName": "manifestName"
                },
                "MssPackage": {
                  "locationName": "mssPackage",
                  "shape": "Sy"
                },
                "StartoverWindowSeconds": {
                  "locationName": "startoverWindowSeconds",
                  "type": "integer"
                },
                "Tags": {
                  "locationName": "tags",
                  "shape": "S3"
                },
                "TimeDelaySeconds": {
                  "locationName": "timeDelaySeconds",
                  "type": "integer"
                },
                "Url": {
                  "locationName": "url"
                },
                "Whitelist": {
                  "locationName": "whitelist",
                  "shape": "Sd"
                }
              },
              "type": "structure"
            },
            "type": "list"
          }
        },
        "type": "structure"
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resource-arn}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          }
        },
        "required": [
          "ResourceArn"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Tags": {
            "locationName": "tags",
            "shape": "S1n"
          }
        },
        "type": "structure"
      }
    },
    "RotateChannelCredentials": {
      "deprecated": true,
      "deprecatedMessage": "This API is deprecated. Please use RotateIngestEndpointCredentials instead",
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{id}/credentials",
        "responseCode": 200
      },
      "input": {
        "deprecated": true,
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "deprecated": true,
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsIngest": {
            "locationName": "hlsIngest",
            "shape": "S5"
          },
          "Id": {
            "locationName": "id"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          }
        },
        "type": "structure"
      }
    },
    "RotateIngestEndpointCredentials": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{id}/ingest_endpoints/{ingest_endpoint_id}/credentials",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Id": {
            "location": "uri",
            "locationName": "id"
          },
          "IngestEndpointId": {
            "location": "uri",
            "locationName": "ingest_endpoint_id"
          }
        },
        "required": [
          "IngestEndpointId",
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsIngest": {
            "locationName": "hlsIngest",
            "shape": "S5"
          },
          "Id": {
            "locationName": "id"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          }
        },
        "type": "structure"
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resource-arn}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S1n"
          }
        },
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "type": "structure"
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resource-arn}",
        "responseCode": 204
      },
      "input": {
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resource-arn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "shape": "Sd"
          }
        },
        "required": [
          "TagKeys",
          "ResourceArn"
        ],
        "type": "structure"
      }
    },
    "UpdateChannel": {
      "http": {
        "method": "PUT",
        "requestUri": "/channels/{id}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "Description": {
            "locationName": "description"
          },
          "Id": {
            "location": "uri",
            "locationName": "id"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsIngest": {
            "locationName": "hlsIngest",
            "shape": "S5"
          },
          "Id": {
            "locationName": "id"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          }
        },
        "type": "structure"
      }
    },
    "UpdateOriginEndpoint": {
      "http": {
        "method": "PUT",
        "requestUri": "/origin_endpoints/{id}",
        "responseCode": 200
      },
      "input": {
        "members": {
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S9"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "So"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sv"
          },
          "Id": {
            "location": "uri",
            "locationName": "id"
          },
          "ManifestName": {
            "locationName": "manifestName"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sy"
          },
          "StartoverWindowSeconds": {
            "locationName": "startoverWindowSeconds",
            "type": "integer"
          },
          "TimeDelaySeconds": {
            "locationName": "timeDelaySeconds",
            "type": "integer"
          },
          "Whitelist": {
            "locationName": "whitelist",
            "shape": "Sd"
          }
        },
        "required": [
          "Id"
        ],
        "type": "structure"
      },
      "output": {
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "ChannelId": {
            "locationName": "channelId"
          },
          "CmafPackage": {
            "locationName": "cmafPackage",
            "shape": "S11"
          },
          "DashPackage": {
            "locationName": "dashPackage",
            "shape": "So"
          },
          "Description": {
            "locationName": "description"
          },
          "HlsPackage": {
            "locationName": "hlsPackage",
            "shape": "Sv"
          },
          "Id": {
            "locationName": "id"
          },
          "ManifestName": {
            "locationName": "manifestName"
          },
          "MssPackage": {
            "locationName": "mssPackage",
            "shape": "Sy"
          },
          "StartoverWindowSeconds": {
            "locationName": "startoverWindowSeconds",
            "type": "integer"
          },
          "Tags": {
            "locationName": "tags",
            "shape": "S3"
          },
          "TimeDelaySeconds": {
            "locationName": "timeDelaySeconds",
            "type": "integer"
          },
          "Url": {
            "locationName": "url"
          },
          "Whitelist": {
            "locationName": "whitelist",
            "shape": "Sd"
          }
        },
        "type": "structure"
      }
    }
  },
  "shapes": {
    "S3": {
      "key": {},
      "type": "map",
      "value": {}
    },
    "S5": {
      "members": {
        "IngestEndpoints": {
          "locationName": "ingestEndpoints",
          "member": {
            "members": {
              "Id": {
                "locationName": "id"
              },
              "Password": {
                "locationName": "password"
              },
              "Url": {
                "locationName": "url"
              },
              "Username": {
                "locationName": "username"
              }
            },
            "type": "structure"
          },
          "type": "list"
        }
      },
      "type": "structure"
    },
    "S9": {
      "members": {
        "Encryption": {
          "locationName": "encryption",
          "shape": "Sa"
        },
        "HlsManifests": {
          "locationName": "hlsManifests",
          "member": {
            "members": {
              "AdMarkers": {
                "locationName": "adMarkers"
              },
              "AdTriggers": {
                "locationName": "adTriggers",
                "shape": "Sh"
              },
              "AdsOnDeliveryRestrictions": {
                "locationName": "adsOnDeliveryRestrictions"
              },
              "Id": {
                "locationName": "id"
              },
              "IncludeIframeOnlyStream": {
                "locationName": "includeIframeOnlyStream",
                "type": "boolean"
              },
              "ManifestName": {
                "locationName": "manifestName"
              },
              "PlaylistType": {
                "locationName": "playlistType"
              },
              "PlaylistWindowSeconds": {
                "locationName": "playlistWindowSeconds",
                "type": "integer"
              },
              "ProgramDateTimeIntervalSeconds": {
                "locationName": "programDateTimeIntervalSeconds",
                "type": "integer"
              }
            },
            "required": [
              "Id"
            ],
            "type": "structure"
          },
          "type": "list"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        },
        "SegmentPrefix": {
          "locationName": "segmentPrefix"
        },
        "StreamSelection": {
          "locationName": "streamSelection",
          "shape": "Sm"
        }
      },
      "type": "structure"
    },
    "Sa": {
      "members": {
        "KeyRotationIntervalSeconds": {
          "locationName": "keyRotationIntervalSeconds",
          "type": "integer"
        },
        "SpekeKeyProvider": {
          "locationName": "spekeKeyProvider",
          "shape": "Sc"
        }
      },
      "required": [
        "SpekeKeyProvider"
      ],
      "type": "structure"
    },
    "Sc": {
      "members": {
        "CertificateArn": {
          "locationName": "certificateArn"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "RoleArn": {
          "locationName": "roleArn"
        },
        "SystemIds": {
          "locationName": "systemIds",
          "shape": "Sd"
        },
        "Url": {
          "locationName": "url"
        }
      },
      "required": [
        "Url",
        "ResourceId",
        "RoleArn",
        "SystemIds"
      ],
      "type": "structure"
    },
    "Sd": {
      "member": {},
      "type": "list"
    },
    "Sh": {
      "member": {},
      "type": "list"
    },
    "Sm": {
      "members": {
        "MaxVideoBitsPerSecond": {
          "locationName": "maxVideoBitsPerSecond",
          "type": "integer"
        },
        "MinVideoBitsPerSecond": {
          "locationName": "minVideoBitsPerSecond",
          "type": "integer"
        },
        "StreamOrder": {
          "locationName": "streamOrder"
        }
      },
      "type": "structure"
    },
    "So": {
      "members": {
        "AdTriggers": {
          "locationName": "adTriggers",
          "shape": "Sh"
        },
        "AdsOnDeliveryRestrictions": {
          "locationName": "adsOnDeliveryRestrictions"
        },
        "Encryption": {
          "locationName": "encryption",
          "members": {
            "KeyRotationIntervalSeconds": {
              "locationName": "keyRotationIntervalSeconds",
              "type": "integer"
            },
            "SpekeKeyProvider": {
              "locationName": "spekeKeyProvider",
              "shape": "Sc"
            }
          },
          "required": [
            "SpekeKeyProvider"
          ],
          "type": "structure"
        },
        "ManifestLayout": {
          "locationName": "manifestLayout"
        },
        "ManifestWindowSeconds": {
          "locationName": "manifestWindowSeconds",
          "type": "integer"
        },
        "MinBufferTimeSeconds": {
          "locationName": "minBufferTimeSeconds",
          "type": "integer"
        },
        "MinUpdatePeriodSeconds": {
          "locationName": "minUpdatePeriodSeconds",
          "type": "integer"
        },
        "PeriodTriggers": {
          "locationName": "periodTriggers",
          "member": {},
          "type": "list"
        },
        "Profile": {
          "locationName": "profile"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        },
        "SegmentTemplateFormat": {
          "locationName": "segmentTemplateFormat"
        },
        "StreamSelection": {
          "locationName": "streamSelection",
          "shape": "Sm"
        },
        "SuggestedPresentationDelaySeconds": {
          "locationName": "suggestedPresentationDelaySeconds",
          "type": "integer"
        }
      },
      "type": "structure"
    },
    "Sv": {
      "members": {
        "AdMarkers": {
          "locationName": "adMarkers"
        },
        "AdTriggers": {
          "locationName": "adTriggers",
          "shape": "Sh"
        },
        "AdsOnDeliveryRestrictions": {
          "locationName": "adsOnDeliveryRestrictions"
        },
        "Encryption": {
          "locationName": "encryption",
          "members": {
            "ConstantInitializationVector": {
              "locationName": "constantInitializationVector"
            },
            "EncryptionMethod": {
              "locationName": "encryptionMethod"
            },
            "KeyRotationIntervalSeconds": {
              "locationName": "keyRotationIntervalSeconds",
              "type": "integer"
            },
            "RepeatExtXKey": {
              "locationName": "repeatExtXKey",
              "type": "boolean"
            },
            "SpekeKeyProvider": {
              "locationName": "spekeKeyProvider",
              "shape": "Sc"
            }
          },
          "required": [
            "SpekeKeyProvider"
          ],
          "type": "structure"
        },
        "IncludeIframeOnlyStream": {
          "locationName": "includeIframeOnlyStream",
          "type": "boolean"
        },
        "PlaylistType": {
          "locationName": "playlistType"
        },
        "PlaylistWindowSeconds": {
          "locationName": "playlistWindowSeconds",
          "type": "integer"
        },
        "ProgramDateTimeIntervalSeconds": {
          "locationName": "programDateTimeIntervalSeconds",
          "type": "integer"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        },
        "StreamSelection": {
          "locationName": "streamSelection",
          "shape": "Sm"
        },
        "UseAudioRenditionGroup": {
          "locationName": "useAudioRenditionGroup",
          "type": "boolean"
        }
      },
      "type": "structure"
    },
    "Sy": {
      "members": {
        "Encryption": {
          "locationName": "encryption",
          "members": {
            "SpekeKeyProvider": {
              "locationName": "spekeKeyProvider",
              "shape": "Sc"
            }
          },
          "required": [
            "SpekeKeyProvider"
          ],
          "type": "structure"
        },
        "ManifestWindowSeconds": {
          "locationName": "manifestWindowSeconds",
          "type": "integer"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        },
        "StreamSelection": {
          "locationName": "streamSelection",
          "shape": "Sm"
        }
      },
      "type": "structure"
    },
    "S11": {
      "members": {
        "Encryption": {
          "locationName": "encryption",
          "shape": "Sa"
        },
        "HlsManifests": {
          "locationName": "hlsManifests",
          "member": {
            "members": {
              "AdMarkers": {
                "locationName": "adMarkers"
              },
              "Id": {
                "locationName": "id"
              },
              "IncludeIframeOnlyStream": {
                "locationName": "includeIframeOnlyStream",
                "type": "boolean"
              },
              "ManifestName": {
                "locationName": "manifestName"
              },
              "PlaylistType": {
                "locationName": "playlistType"
              },
              "PlaylistWindowSeconds": {
                "locationName": "playlistWindowSeconds",
                "type": "integer"
              },
              "ProgramDateTimeIntervalSeconds": {
                "locationName": "programDateTimeIntervalSeconds",
                "type": "integer"
              },
              "Url": {
                "locationName": "url"
              }
            },
            "required": [
              "Id"
            ],
            "type": "structure"
          },
          "type": "list"
        },
        "SegmentDurationSeconds": {
          "locationName": "segmentDurationSeconds",
          "type": "integer"
        },
        "SegmentPrefix": {
          "locationName": "segmentPrefix"
        },
        "StreamSelection": {
          "locationName": "streamSelection",
          "shape": "Sm"
        }
      },
      "type": "structure"
    },
    "S1n": {
      "key": {},
      "type": "map",
      "value": {}
    }
  }
}