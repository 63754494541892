import TagManager from './TagManager';

const push = (type: string, data: any, event?: string): Promise<any> => {
  const instance = TagManager.getInstance();
  const args: any = {
    ecommerce: {
      [type]: data,
    },
  };

  if (event) args.event = event;

  return instance.dataLayer(args);
};

const ProductImpressions = (data: any): Promise<any> => push('impressions', data, 'impressions');

const ProductClicks = (data: any): Promise<any> => push('impressions', data, 'productClick');

const Checkout = (data: any): Promise<any> => push('checkout', data, 'checkout');

const CheckoutOptions = (data: any): Promise<any> => push('checkout_option', data, 'checkoutOption');

const Purchase = (data: any): Promise<any> => push('purchase', data, 'purchase');

const Refund = (data: any): Promise<any> => push('refund', data, 'refund');

export default {
  ProductImpressions,
  ProductClicks,
  Checkout,
  CheckoutOptions,
  Purchase,
  Refund,
};
