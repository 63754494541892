import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Link, Typography } from '@mui/material';
import { Header as H } from '@mineko/mineko-ui';
import { Logo } from '../UI/Logo';
import { css } from '@emotion/react';
const toolbarStyles = css`
  display: flex;
  justify-content: space-between;
`;

const loginLinkStyles = css`
  text-decoration: none;
`;

const Header: React.FC = () => {
  const { t } = useTranslation();
  return (
    <H toolbarProps={{ css: toolbarStyles } as any}>
      <Logo />
      <Typography variant="body2">
        <span>{t('Global.already_registered')}&nbsp;</span>
        <Link component={RouterLink} color="secondary" to="/" css={loginLinkStyles}>
          <b>{t('Global.login_page')}</b>
        </Link>
      </Typography>
    </H>
  );
};
export default Header;
