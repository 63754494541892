{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-28",
    "endpointPrefix": "runtime.lex",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Lex Runtime Service",
    "serviceId": "Lex Runtime Service",
    "signatureVersion": "v4",
    "signingName": "lex",
    "uid": "runtime.lex-2016-11-28"
  },
  "operations": {
    "DeleteSession": {
      "http": {
        "method": "DELETE",
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/session"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "botName": {},
          "botAlias": {},
          "userId": {},
          "sessionId": {}
        }
      }
    },
    "GetSession": {
      "http": {
        "method": "GET",
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/session"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "recentIntentSummaryView": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "dialogActionType"
              ],
              "members": {
                "intentName": {},
                "slots": {
                  "shape": "Sc"
                },
                "confirmationStatus": {},
                "dialogActionType": {},
                "fulfillmentState": {},
                "slotToElicit": {}
              }
            }
          },
          "sessionAttributes": {
            "shape": "Sc"
          },
          "sessionId": {},
          "dialogAction": {
            "shape": "Sg"
          }
        }
      }
    },
    "PostContent": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/content"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId",
          "contentType",
          "inputStream"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "Sk",
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "requestAttributes": {
            "shape": "Sk",
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-request-attributes"
          },
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "accept": {
            "location": "header",
            "locationName": "Accept"
          },
          "inputStream": {
            "shape": "Sn"
          }
        },
        "payload": "inputStream"
      },
      "output": {
        "type": "structure",
        "members": {
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "intentName": {
            "location": "header",
            "locationName": "x-amz-lex-intent-name"
          },
          "slots": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-slots"
          },
          "sessionAttributes": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "message": {
            "shape": "Sh",
            "location": "header",
            "locationName": "x-amz-lex-message"
          },
          "messageFormat": {
            "location": "header",
            "locationName": "x-amz-lex-message-format"
          },
          "dialogState": {
            "location": "header",
            "locationName": "x-amz-lex-dialog-state"
          },
          "slotToElicit": {
            "location": "header",
            "locationName": "x-amz-lex-slot-to-elicit"
          },
          "inputTranscript": {
            "location": "header",
            "locationName": "x-amz-lex-input-transcript"
          },
          "audioStream": {
            "shape": "Sn"
          }
        },
        "payload": "audioStream"
      },
      "authtype": "v4-unsigned-body"
    },
    "PostText": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/text"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId",
          "inputText"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "Sc"
          },
          "requestAttributes": {
            "shape": "Sc"
          },
          "inputText": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "intentName": {},
          "slots": {
            "shape": "Sc"
          },
          "sessionAttributes": {
            "shape": "Sc"
          },
          "message": {
            "shape": "Sh"
          },
          "messageFormat": {},
          "dialogState": {},
          "slotToElicit": {},
          "responseCard": {
            "type": "structure",
            "members": {
              "version": {},
              "contentType": {},
              "genericAttachments": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "title": {},
                    "subTitle": {},
                    "attachmentLinkUrl": {},
                    "imageUrl": {},
                    "buttons": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "text",
                          "value"
                        ],
                        "members": {
                          "text": {},
                          "value": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "PutSession": {
      "http": {
        "requestUri": "/bot/{botName}/alias/{botAlias}/user/{userId}/session"
      },
      "input": {
        "type": "structure",
        "required": [
          "botName",
          "botAlias",
          "userId"
        ],
        "members": {
          "botName": {
            "location": "uri",
            "locationName": "botName"
          },
          "botAlias": {
            "location": "uri",
            "locationName": "botAlias"
          },
          "userId": {
            "location": "uri",
            "locationName": "userId"
          },
          "sessionAttributes": {
            "shape": "Sc"
          },
          "dialogAction": {
            "shape": "Sg"
          },
          "accept": {
            "location": "header",
            "locationName": "Accept"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "contentType": {
            "location": "header",
            "locationName": "Content-Type"
          },
          "intentName": {
            "location": "header",
            "locationName": "x-amz-lex-intent-name"
          },
          "slots": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-slots"
          },
          "sessionAttributes": {
            "jsonvalue": true,
            "location": "header",
            "locationName": "x-amz-lex-session-attributes"
          },
          "message": {
            "shape": "Sh",
            "location": "header",
            "locationName": "x-amz-lex-message"
          },
          "messageFormat": {
            "location": "header",
            "locationName": "x-amz-lex-message-format"
          },
          "dialogState": {
            "location": "header",
            "locationName": "x-amz-lex-dialog-state"
          },
          "slotToElicit": {
            "location": "header",
            "locationName": "x-amz-lex-slot-to-elicit"
          },
          "audioStream": {
            "shape": "Sn"
          },
          "sessionId": {
            "location": "header",
            "locationName": "x-amz-lex-session-id"
          }
        },
        "payload": "audioStream"
      }
    }
  },
  "shapes": {
    "Sc": {
      "type": "map",
      "key": {},
      "value": {},
      "sensitive": true
    },
    "Sg": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "intentName": {},
        "slots": {
          "shape": "Sc"
        },
        "slotToElicit": {},
        "fulfillmentState": {},
        "message": {
          "shape": "Sh"
        },
        "messageFormat": {}
      }
    },
    "Sh": {
      "type": "string",
      "sensitive": true
    },
    "Sk": {
      "type": "string",
      "sensitive": true
    },
    "Sn": {
      "type": "blob",
      "streaming": true
    }
  }
}