{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-09-23",
    "endpointPrefix": "cloud9",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Cloud9",
    "serviceId": "Cloud9",
    "signatureVersion": "v4",
    "targetPrefix": "AWSCloud9WorkspaceManagementService",
    "uid": "cloud9-2017-09-23"
  },
  "operations": {
    "CreateEnvironmentEC2": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "instanceType"
        ],
        "members": {
          "name": {},
          "description": {
            "shape": "S3"
          },
          "clientRequestToken": {},
          "instanceType": {},
          "subnetId": {},
          "automaticStopTimeMinutes": {
            "type": "integer"
          },
          "ownerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environmentId": {}
        }
      },
      "idempotent": true
    },
    "CreateEnvironmentMembership": {
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "userArn",
          "permissions"
        ],
        "members": {
          "environmentId": {},
          "userArn": {},
          "permissions": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "membership": {
            "shape": "Se"
          }
        }
      },
      "idempotent": true
    },
    "DeleteEnvironment": {
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteEnvironmentMembership": {
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "userArn"
        ],
        "members": {
          "environmentId": {},
          "userArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeEnvironmentMemberships": {
      "input": {
        "type": "structure",
        "members": {
          "userArn": {},
          "environmentId": {},
          "permissions": {
            "type": "list",
            "member": {}
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "memberships": {
            "type": "list",
            "member": {
              "shape": "Se"
            }
          },
          "nextToken": {}
        }
      }
    },
    "DescribeEnvironmentStatus": {
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "status": {},
          "message": {}
        }
      }
    },
    "DescribeEnvironments": {
      "input": {
        "type": "structure",
        "required": [
          "environmentIds"
        ],
        "members": {
          "environmentIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "environments": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "name": {},
                "description": {
                  "shape": "S3"
                },
                "type": {},
                "arn": {},
                "ownerArn": {},
                "lifecycle": {
                  "type": "structure",
                  "members": {
                    "status": {},
                    "reason": {},
                    "failureResource": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListEnvironments": {
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "environmentIds": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateEnvironment": {
      "input": {
        "type": "structure",
        "required": [
          "environmentId"
        ],
        "members": {
          "environmentId": {},
          "name": {},
          "description": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateEnvironmentMembership": {
      "input": {
        "type": "structure",
        "required": [
          "environmentId",
          "userArn",
          "permissions"
        ],
        "members": {
          "environmentId": {},
          "userArn": {},
          "permissions": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "membership": {
            "shape": "Se"
          }
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "string",
      "sensitive": true
    },
    "Se": {
      "type": "structure",
      "members": {
        "permissions": {},
        "userId": {},
        "userArn": {},
        "environmentId": {},
        "lastAccess": {
          "type": "timestamp"
        }
      }
    }
  }
}