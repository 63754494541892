{
  "pagination": {
    "DescribeMergeConflicts": {
      "input_token": "nextToken",
      "limit_key": "maxMergeHunks",
      "output_token": "nextToken"
    },
    "DescribePullRequestEvents": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "GetCommentsForComparedCommit": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "GetCommentsForPullRequest": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "GetDifferences": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken"
    },
    "GetMergeConflicts": {
      "input_token": "nextToken",
      "limit_key": "maxConflictFiles",
      "output_token": "nextToken"
    },
    "ListBranches": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "branches"
    },
    "ListPullRequests": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken"
    },
    "ListRepositories": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "result_key": "repositories"
    }
  }
}