{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-12-02",
    "endpointPrefix": "kinesis",
    "jsonVersion": "1.1",
    "protocol": "json",
    "protocolSettings": {
      "h2": "eventstream"
    },
    "serviceAbbreviation": "Kinesis",
    "serviceFullName": "Amazon Kinesis",
    "serviceId": "Kinesis",
    "signatureVersion": "v4",
    "targetPrefix": "Kinesis_20131202",
    "uid": "kinesis-2013-12-02"
  },
  "operations": {
    "AddTagsToStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "Tags"
        ],
        "members": {
          "StreamName": {},
          "Tags": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "CreateStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardCount"
        ],
        "members": {
          "StreamName": {},
          "ShardCount": {
            "type": "integer"
          }
        }
      }
    },
    "DecreaseStreamRetentionPeriod": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "RetentionPeriodHours"
        ],
        "members": {
          "StreamName": {},
          "RetentionPeriodHours": {
            "type": "integer"
          }
        }
      }
    },
    "DeleteStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {},
          "EnforceConsumerDeletion": {
            "type": "boolean"
          }
        }
      }
    },
    "DeregisterStreamConsumer": {
      "input": {
        "type": "structure",
        "members": {
          "StreamARN": {},
          "ConsumerName": {},
          "ConsumerARN": {}
        }
      }
    },
    "DescribeLimits": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "ShardLimit",
          "OpenShardCount"
        ],
        "members": {
          "ShardLimit": {
            "type": "integer"
          },
          "OpenShardCount": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartShardId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StreamDescription"
        ],
        "members": {
          "StreamDescription": {
            "type": "structure",
            "required": [
              "StreamName",
              "StreamARN",
              "StreamStatus",
              "Shards",
              "HasMoreShards",
              "RetentionPeriodHours",
              "StreamCreationTimestamp",
              "EnhancedMonitoring"
            ],
            "members": {
              "StreamName": {},
              "StreamARN": {},
              "StreamStatus": {},
              "Shards": {
                "shape": "Sp"
              },
              "HasMoreShards": {
                "type": "boolean"
              },
              "RetentionPeriodHours": {
                "type": "integer"
              },
              "StreamCreationTimestamp": {
                "type": "timestamp"
              },
              "EnhancedMonitoring": {
                "shape": "Sw"
              },
              "EncryptionType": {},
              "KeyId": {}
            }
          }
        }
      }
    },
    "DescribeStreamConsumer": {
      "input": {
        "type": "structure",
        "members": {
          "StreamARN": {},
          "ConsumerName": {},
          "ConsumerARN": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ConsumerDescription"
        ],
        "members": {
          "ConsumerDescription": {
            "type": "structure",
            "required": [
              "ConsumerName",
              "ConsumerARN",
              "ConsumerStatus",
              "ConsumerCreationTimestamp",
              "StreamARN"
            ],
            "members": {
              "ConsumerName": {},
              "ConsumerARN": {},
              "ConsumerStatus": {},
              "ConsumerCreationTimestamp": {
                "type": "timestamp"
              },
              "StreamARN": {}
            }
          }
        }
      }
    },
    "DescribeStreamSummary": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StreamDescriptionSummary"
        ],
        "members": {
          "StreamDescriptionSummary": {
            "type": "structure",
            "required": [
              "StreamName",
              "StreamARN",
              "StreamStatus",
              "RetentionPeriodHours",
              "StreamCreationTimestamp",
              "EnhancedMonitoring",
              "OpenShardCount"
            ],
            "members": {
              "StreamName": {},
              "StreamARN": {},
              "StreamStatus": {},
              "RetentionPeriodHours": {
                "type": "integer"
              },
              "StreamCreationTimestamp": {
                "type": "timestamp"
              },
              "EnhancedMonitoring": {
                "shape": "Sw"
              },
              "EncryptionType": {},
              "KeyId": {},
              "OpenShardCount": {
                "type": "integer"
              },
              "ConsumerCount": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "DisableEnhancedMonitoring": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardLevelMetrics"
        ],
        "members": {
          "StreamName": {},
          "ShardLevelMetrics": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "shape": "S1b"
      }
    },
    "EnableEnhancedMonitoring": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardLevelMetrics"
        ],
        "members": {
          "StreamName": {},
          "ShardLevelMetrics": {
            "shape": "Sy"
          }
        }
      },
      "output": {
        "shape": "S1b"
      }
    },
    "GetRecords": {
      "input": {
        "type": "structure",
        "required": [
          "ShardIterator"
        ],
        "members": {
          "ShardIterator": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Records"
        ],
        "members": {
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SequenceNumber",
                "Data",
                "PartitionKey"
              ],
              "members": {
                "SequenceNumber": {},
                "ApproximateArrivalTimestamp": {
                  "type": "timestamp"
                },
                "Data": {
                  "type": "blob"
                },
                "PartitionKey": {},
                "EncryptionType": {}
              }
            }
          },
          "NextShardIterator": {},
          "MillisBehindLatest": {
            "type": "long"
          }
        }
      }
    },
    "GetShardIterator": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardId",
          "ShardIteratorType"
        ],
        "members": {
          "StreamName": {},
          "ShardId": {},
          "ShardIteratorType": {},
          "StartingSequenceNumber": {},
          "Timestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ShardIterator": {}
        }
      }
    },
    "IncreaseStreamRetentionPeriod": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "RetentionPeriodHours"
        ],
        "members": {
          "StreamName": {},
          "RetentionPeriodHours": {
            "type": "integer"
          }
        }
      }
    },
    "ListShards": {
      "input": {
        "type": "structure",
        "members": {
          "StreamName": {},
          "NextToken": {},
          "ExclusiveStartShardId": {},
          "MaxResults": {
            "type": "integer"
          },
          "StreamCreationTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Shards": {
            "shape": "Sp"
          },
          "NextToken": {}
        }
      }
    },
    "ListStreamConsumers": {
      "input": {
        "type": "structure",
        "required": [
          "StreamARN"
        ],
        "members": {
          "StreamARN": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StreamCreationTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Consumers": {
            "type": "list",
            "member": {
              "shape": "S1y"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStreams": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "StreamNames",
          "HasMoreStreams"
        ],
        "members": {
          "StreamNames": {
            "type": "list",
            "member": {}
          },
          "HasMoreStreams": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName"
        ],
        "members": {
          "StreamName": {},
          "ExclusiveStartTagKey": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags",
          "HasMoreTags"
        ],
        "members": {
          "Tags": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Key"
              ],
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "HasMoreTags": {
            "type": "boolean"
          }
        }
      }
    },
    "MergeShards": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardToMerge",
          "AdjacentShardToMerge"
        ],
        "members": {
          "StreamName": {},
          "ShardToMerge": {},
          "AdjacentShardToMerge": {}
        }
      }
    },
    "PutRecord": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "Data",
          "PartitionKey"
        ],
        "members": {
          "StreamName": {},
          "Data": {
            "type": "blob"
          },
          "PartitionKey": {},
          "ExplicitHashKey": {},
          "SequenceNumberForOrdering": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ShardId",
          "SequenceNumber"
        ],
        "members": {
          "ShardId": {},
          "SequenceNumber": {},
          "EncryptionType": {}
        }
      }
    },
    "PutRecords": {
      "input": {
        "type": "structure",
        "required": [
          "Records",
          "StreamName"
        ],
        "members": {
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Data",
                "PartitionKey"
              ],
              "members": {
                "Data": {
                  "type": "blob"
                },
                "ExplicitHashKey": {},
                "PartitionKey": {}
              }
            }
          },
          "StreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Records"
        ],
        "members": {
          "FailedRecordCount": {
            "type": "integer"
          },
          "Records": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SequenceNumber": {},
                "ShardId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          },
          "EncryptionType": {}
        }
      }
    },
    "RegisterStreamConsumer": {
      "input": {
        "type": "structure",
        "required": [
          "StreamARN",
          "ConsumerName"
        ],
        "members": {
          "StreamARN": {},
          "ConsumerName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Consumer"
        ],
        "members": {
          "Consumer": {
            "shape": "S1y"
          }
        }
      }
    },
    "RemoveTagsFromStream": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "TagKeys"
        ],
        "members": {
          "StreamName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "SplitShard": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "ShardToSplit",
          "NewStartingHashKey"
        ],
        "members": {
          "StreamName": {},
          "ShardToSplit": {},
          "NewStartingHashKey": {}
        }
      }
    },
    "StartStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "EncryptionType",
          "KeyId"
        ],
        "members": {
          "StreamName": {},
          "EncryptionType": {},
          "KeyId": {}
        }
      }
    },
    "StopStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "EncryptionType",
          "KeyId"
        ],
        "members": {
          "StreamName": {},
          "EncryptionType": {},
          "KeyId": {}
        }
      }
    },
    "UpdateShardCount": {
      "input": {
        "type": "structure",
        "required": [
          "StreamName",
          "TargetShardCount",
          "ScalingType"
        ],
        "members": {
          "StreamName": {},
          "TargetShardCount": {
            "type": "integer"
          },
          "ScalingType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StreamName": {},
          "CurrentShardCount": {
            "type": "integer"
          },
          "TargetShardCount": {
            "type": "integer"
          }
        }
      }
    }
  },
  "shapes": {
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ShardId",
          "HashKeyRange",
          "SequenceNumberRange"
        ],
        "members": {
          "ShardId": {},
          "ParentShardId": {},
          "AdjacentParentShardId": {},
          "HashKeyRange": {
            "type": "structure",
            "required": [
              "StartingHashKey",
              "EndingHashKey"
            ],
            "members": {
              "StartingHashKey": {},
              "EndingHashKey": {}
            }
          },
          "SequenceNumberRange": {
            "type": "structure",
            "required": [
              "StartingSequenceNumber"
            ],
            "members": {
              "StartingSequenceNumber": {},
              "EndingSequenceNumber": {}
            }
          }
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ShardLevelMetrics": {
            "shape": "Sy"
          }
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "structure",
      "members": {
        "StreamName": {},
        "CurrentShardLevelMetrics": {
          "shape": "Sy"
        },
        "DesiredShardLevelMetrics": {
          "shape": "Sy"
        }
      }
    },
    "S1y": {
      "type": "structure",
      "required": [
        "ConsumerName",
        "ConsumerARN",
        "ConsumerStatus",
        "ConsumerCreationTimestamp"
      ],
      "members": {
        "ConsumerName": {},
        "ConsumerARN": {},
        "ConsumerStatus": {},
        "ConsumerCreationTimestamp": {
          "type": "timestamp"
        }
      }
    }
  }
}