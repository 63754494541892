import * as R from 'ramda';
import { COPY_MULTI_ITEM_VALUE_DELIMITER } from './constants';
const ALPHABET_CHARS = [
    'q',
    'w',
    'e',
    'r',
    't',
    'y',
    'u',
    'i',
    'o',
    'p',
    'a',
    's',
    'd',
    'f',
    'g',
    'h',
    'j',
    'k',
    'l',
    'z',
    'x',
    'c',
    'v',
    'b',
    'n',
    'm',
    'ä',
    'ß',
    'ö',
    'ü',
];
const SHIFT_KEYS_ALPHABET_CHAS = ALPHABET_CHARS.map((char) => `shift+${char}`);
const ALL_CAPS_ALPHABET_CHARS = ALPHABET_CHARS.map((char) => char.toUpperCase());
const nums = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
export const KEYS_TO_ACTIVATE_CELL = ALPHABET_CHARS.join(',')
    .concat(ALL_CAPS_ALPHABET_CHARS.join(','))
    .concat(SHIFT_KEYS_ALPHABET_CHAS.join(','))
    .concat(nums.join(','));
export const sortCells = (cells) => R.pipe(R.sortBy(R.prop(1)), R.sortBy(R.prop(0)))(cells);
const getRange = (first, selected, staticCoordinate, type) => {
    const [actualFirst, actualLast] = first > selected ? [selected, first] : [first, selected];
    const range = R.pipe(R.range(actualFirst), R.map((n) => (type === 'col' ? [staticCoordinate, n] : [n, staticCoordinate])))(actualLast + 1);
    return range;
};
export const selectCellsInRange = (firstSelectedCell, secondSelectedCell) => {
    const [firstRow, firstCol] = firstSelectedCell;
    const [selectedRow, selectedCol] = secondSelectedCell;
    if (firstRow === selectedRow) {
        return getRange(firstCol, selectedCol, firstRow, 'col');
    }
    if (firstCol === selectedCol) {
        return getRange(firstRow, selectedRow, firstCol, 'row');
    }
    return null;
};
export const pasteMultiValues = (mutableData, selectedCells, clipText) => {
    const values = clipText.split(COPY_MULTI_ITEM_VALUE_DELIMITER);
    if (!values.length)
        return null;
    if (selectedCells.length === 1) {
        const [row, col] = selectedCells[0];
        mutableData[row][col] = clipText.replace('\n', ' ').trim();
    }
    else {
        selectedCells.forEach(([row, col], index) => {
            const value = values.length > 1 ? values[index] : values[0];
            mutableData[row][col] = value?.trim() ?? mutableData[row][col];
        });
    }
    return mutableData;
};
