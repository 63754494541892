{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2010-05-15",
    "endpointPrefix": "cloudformation",
    "protocol": "query",
    "serviceFullName": "AWS CloudFormation",
    "serviceId": "CloudFormation",
    "signatureVersion": "v4",
    "uid": "cloudformation-2010-05-15",
    "xmlNamespace": "http://cloudformation.amazonaws.com/doc/2010-05-15/"
  },
  "operations": {
    "CancelUpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "ClientRequestToken": {}
        }
      }
    },
    "ContinueUpdateRollback": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RoleARN": {},
          "ResourcesToSkip": {
            "type": "list",
            "member": {}
          },
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "ContinueUpdateRollbackResult",
        "type": "structure",
        "members": {}
      }
    },
    "CreateChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "ChangeSetName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "ResourceTypes": {
            "shape": "Sl"
          },
          "RoleARN": {},
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "NotificationARNs": {
            "shape": "St"
          },
          "Tags": {
            "shape": "Sv"
          },
          "ChangeSetName": {},
          "ClientToken": {},
          "Description": {},
          "ChangeSetType": {}
        }
      },
      "output": {
        "resultWrapper": "CreateChangeSetResult",
        "type": "structure",
        "members": {
          "Id": {},
          "StackId": {}
        }
      }
    },
    "CreateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Se"
          },
          "DisableRollback": {
            "type": "boolean"
          },
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "TimeoutInMinutes": {
            "type": "integer"
          },
          "NotificationARNs": {
            "shape": "St"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "ResourceTypes": {
            "shape": "Sl"
          },
          "RoleARN": {},
          "OnFailure": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "Tags": {
            "shape": "Sv"
          },
          "ClientRequestToken": {},
          "EnableTerminationProtection": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "CreateStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Accounts",
          "Regions"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S1g"
          },
          "Regions": {
            "shape": "S1i"
          },
          "ParameterOverrides": {
            "shape": "Se"
          },
          "OperationPreferences": {
            "shape": "S1k"
          },
          "OperationId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "CreateStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "Description": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "Sv"
          },
          "AdministrationRoleARN": {},
          "ExecutionRoleName": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "CreateStackSetResult",
        "type": "structure",
        "members": {
          "StackSetId": {}
        }
      }
    },
    "DeleteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "RetainResources": {
            "type": "list",
            "member": {}
          },
          "RoleARN": {},
          "ClientRequestToken": {}
        }
      }
    },
    "DeleteStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Accounts",
          "Regions",
          "RetainStacks"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S1g"
          },
          "Regions": {
            "shape": "S1i"
          },
          "OperationPreferences": {
            "shape": "S1k"
          },
          "RetainStacks": {
            "type": "boolean"
          },
          "OperationId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "DeleteStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "DeleteStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DeleteStackSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountLimits": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeAccountLimitsResult",
        "type": "structure",
        "members": {
          "AccountLimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Value": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeChangeSetResult",
        "type": "structure",
        "members": {
          "ChangeSetName": {},
          "ChangeSetId": {},
          "StackId": {},
          "StackName": {},
          "Description": {},
          "Parameters": {
            "shape": "Se"
          },
          "CreationTime": {
            "type": "timestamp"
          },
          "ExecutionStatus": {},
          "Status": {},
          "StatusReason": {},
          "NotificationARNs": {
            "shape": "St"
          },
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "Sv"
          },
          "Changes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "ResourceChange": {
                  "type": "structure",
                  "members": {
                    "Action": {},
                    "LogicalResourceId": {},
                    "PhysicalResourceId": {},
                    "ResourceType": {},
                    "Replacement": {},
                    "Scope": {
                      "type": "list",
                      "member": {}
                    },
                    "Details": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "Target": {
                            "type": "structure",
                            "members": {
                              "Attribute": {},
                              "Name": {},
                              "RequiresRecreation": {}
                            }
                          },
                          "Evaluation": {},
                          "ChangeSource": {},
                          "CausingEntity": {}
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackDriftDetectionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "StackDriftDetectionId"
        ],
        "members": {
          "StackDriftDetectionId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackDriftDetectionStatusResult",
        "type": "structure",
        "required": [
          "StackId",
          "StackDriftDetectionId",
          "DetectionStatus",
          "Timestamp"
        ],
        "members": {
          "StackId": {},
          "StackDriftDetectionId": {},
          "StackDriftStatus": {},
          "DetectionStatus": {},
          "DetectionStatusReason": {},
          "DriftedStackResourceCount": {
            "type": "integer"
          },
          "Timestamp": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStackEvents": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackEventsResult",
        "type": "structure",
        "members": {
          "StackEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackId",
                "EventId",
                "StackName",
                "Timestamp"
              ],
              "members": {
                "StackId": {},
                "EventId": {},
                "StackName": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "ResourceProperties": {},
                "ClientRequestToken": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackInstance": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "StackInstanceAccount",
          "StackInstanceRegion"
        ],
        "members": {
          "StackSetName": {},
          "StackInstanceAccount": {},
          "StackInstanceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackInstanceResult",
        "type": "structure",
        "members": {
          "StackInstance": {
            "type": "structure",
            "members": {
              "StackSetId": {},
              "Region": {},
              "Account": {},
              "StackId": {},
              "ParameterOverrides": {
                "shape": "Se"
              },
              "Status": {},
              "StatusReason": {}
            }
          }
        }
      }
    },
    "DescribeStackResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourceResult",
        "type": "structure",
        "members": {
          "StackResourceDetail": {
            "type": "structure",
            "required": [
              "LogicalResourceId",
              "ResourceType",
              "LastUpdatedTimestamp",
              "ResourceStatus"
            ],
            "members": {
              "StackName": {},
              "StackId": {},
              "LogicalResourceId": {},
              "PhysicalResourceId": {},
              "ResourceType": {},
              "LastUpdatedTimestamp": {
                "type": "timestamp"
              },
              "ResourceStatus": {},
              "ResourceStatusReason": {},
              "Description": {},
              "Metadata": {},
              "DriftInformation": {
                "shape": "S3o"
              }
            }
          }
        }
      }
    },
    "DescribeStackResourceDrifts": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "StackResourceDriftStatusFilters": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourceDriftsResult",
        "type": "structure",
        "required": [
          "StackResourceDrifts"
        ],
        "members": {
          "StackResourceDrifts": {
            "type": "list",
            "member": {
              "shape": "S3v"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeStackResources": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "PhysicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "Timestamp",
                "ResourceStatus"
              ],
              "members": {
                "StackName": {},
                "StackId": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Timestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "Description": {},
                "DriftInformation": {
                  "shape": "S3o"
                }
              }
            }
          }
        }
      }
    },
    "DescribeStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackSetResult",
        "type": "structure",
        "members": {
          "StackSet": {
            "type": "structure",
            "members": {
              "StackSetName": {},
              "StackSetId": {},
              "Description": {},
              "Status": {},
              "TemplateBody": {},
              "Parameters": {
                "shape": "Se"
              },
              "Capabilities": {
                "shape": "Sj"
              },
              "Tags": {
                "shape": "Sv"
              },
              "StackSetARN": {},
              "AdministrationRoleARN": {},
              "ExecutionRoleName": {}
            }
          }
        }
      }
    },
    "DescribeStackSetOperation": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStackSetOperationResult",
        "type": "structure",
        "members": {
          "StackSetOperation": {
            "type": "structure",
            "members": {
              "OperationId": {},
              "StackSetId": {},
              "Action": {},
              "Status": {},
              "OperationPreferences": {
                "shape": "S1k"
              },
              "RetainStacks": {
                "type": "boolean"
              },
              "AdministrationRoleARN": {},
              "ExecutionRoleName": {},
              "CreationTimestamp": {
                "type": "timestamp"
              },
              "EndTimestamp": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeStacks": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "DescribeStacksResult",
        "type": "structure",
        "members": {
          "Stacks": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "Description": {},
                "Parameters": {
                  "shape": "Se"
                },
                "CreationTime": {
                  "type": "timestamp"
                },
                "DeletionTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "RollbackConfiguration": {
                  "shape": "Sn"
                },
                "StackStatus": {},
                "StackStatusReason": {},
                "DisableRollback": {
                  "type": "boolean"
                },
                "NotificationARNs": {
                  "shape": "St"
                },
                "TimeoutInMinutes": {
                  "type": "integer"
                },
                "Capabilities": {
                  "shape": "Sj"
                },
                "Outputs": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "OutputKey": {},
                      "OutputValue": {},
                      "Description": {},
                      "ExportName": {}
                    }
                  }
                },
                "RoleARN": {},
                "Tags": {
                  "shape": "Sv"
                },
                "EnableTerminationProtection": {
                  "type": "boolean"
                },
                "ParentId": {},
                "RootId": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackDriftStatus"
                  ],
                  "members": {
                    "StackDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DetectStackDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceIds": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "DetectStackDriftResult",
        "type": "structure",
        "required": [
          "StackDriftDetectionId"
        ],
        "members": {
          "StackDriftDetectionId": {}
        }
      }
    },
    "DetectStackResourceDrift": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {}
        }
      },
      "output": {
        "resultWrapper": "DetectStackResourceDriftResult",
        "type": "structure",
        "required": [
          "StackResourceDrift"
        ],
        "members": {
          "StackResourceDrift": {
            "shape": "S3v"
          }
        }
      }
    },
    "EstimateTemplateCost": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "Parameters": {
            "shape": "Se"
          }
        }
      },
      "output": {
        "resultWrapper": "EstimateTemplateCostResult",
        "type": "structure",
        "members": {
          "Url": {}
        }
      }
    },
    "ExecuteChangeSet": {
      "input": {
        "type": "structure",
        "required": [
          "ChangeSetName"
        ],
        "members": {
          "ChangeSetName": {},
          "StackName": {},
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "ExecuteChangeSetResult",
        "type": "structure",
        "members": {}
      }
    },
    "GetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "GetStackPolicyResult",
        "type": "structure",
        "members": {
          "StackPolicyBody": {}
        }
      }
    },
    "GetTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "StackName": {},
          "ChangeSetName": {},
          "TemplateStage": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateResult",
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "StagesAvailable": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetTemplateSummary": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {},
          "StackName": {},
          "StackSetName": {}
        }
      },
      "output": {
        "resultWrapper": "GetTemplateSummaryResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "ParameterType": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {},
                "ParameterConstraints": {
                  "type": "structure",
                  "members": {
                    "AllowedValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "Sj"
          },
          "CapabilitiesReason": {},
          "ResourceTypes": {
            "shape": "Sl"
          },
          "Version": {},
          "Metadata": {},
          "DeclaredTransforms": {
            "shape": "S5r"
          }
        }
      }
    },
    "ListChangeSets": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListChangeSetsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackId": {},
                "StackName": {},
                "ChangeSetId": {},
                "ChangeSetName": {},
                "ExecutionStatus": {},
                "Status": {},
                "StatusReason": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListExports": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListExportsResult",
        "type": "structure",
        "members": {
          "Exports": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ExportingStackId": {},
                "Name": {},
                "Value": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListImports": {
      "input": {
        "type": "structure",
        "required": [
          "ExportName"
        ],
        "members": {
          "ExportName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListImportsResult",
        "type": "structure",
        "members": {
          "Imports": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "StackInstanceAccount": {},
          "StackInstanceRegion": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackInstancesResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackSetId": {},
                "Region": {},
                "Account": {},
                "StackId": {},
                "Status": {},
                "StatusReason": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackResources": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "NextToken": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackResourcesResult",
        "type": "structure",
        "members": {
          "StackResourceSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "LogicalResourceId",
                "ResourceType",
                "LastUpdatedTimestamp",
                "ResourceStatus"
              ],
              "members": {
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "LastUpdatedTimestamp": {
                  "type": "timestamp"
                },
                "ResourceStatus": {},
                "ResourceStatusReason": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackResourceDriftStatus"
                  ],
                  "members": {
                    "StackResourceDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSetOperationResults": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListStackSetOperationResultsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Account": {},
                "Region": {},
                "Status": {},
                "StatusReason": {},
                "AccountGateResult": {
                  "type": "structure",
                  "members": {
                    "Status": {},
                    "StatusReason": {}
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSetOperations": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "resultWrapper": "ListStackSetOperationsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OperationId": {},
                "Action": {},
                "Status": {},
                "CreationTimestamp": {
                  "type": "timestamp"
                },
                "EndTimestamp": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStackSets": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "Status": {}
        }
      },
      "output": {
        "resultWrapper": "ListStackSetsResult",
        "type": "structure",
        "members": {
          "Summaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StackSetName": {},
                "StackSetId": {},
                "Description": {},
                "Status": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListStacks": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "StackStatusFilter": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "resultWrapper": "ListStacksResult",
        "type": "structure",
        "members": {
          "StackSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StackName",
                "CreationTime",
                "StackStatus"
              ],
              "members": {
                "StackId": {},
                "StackName": {},
                "TemplateDescription": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "LastUpdatedTime": {
                  "type": "timestamp"
                },
                "DeletionTime": {
                  "type": "timestamp"
                },
                "StackStatus": {},
                "StackStatusReason": {},
                "ParentId": {},
                "RootId": {},
                "DriftInformation": {
                  "type": "structure",
                  "required": [
                    "StackDriftStatus"
                  ],
                  "members": {
                    "StackDriftStatus": {},
                    "LastCheckTimestamp": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "SetStackPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "StackPolicyBody": {},
          "StackPolicyURL": {}
        }
      }
    },
    "SignalResource": {
      "input": {
        "type": "structure",
        "required": [
          "StackName",
          "LogicalResourceId",
          "UniqueId",
          "Status"
        ],
        "members": {
          "StackName": {},
          "LogicalResourceId": {},
          "UniqueId": {},
          "Status": {}
        }
      }
    },
    "StopStackSetOperation": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "OperationId"
        ],
        "members": {
          "StackSetName": {},
          "OperationId": {}
        }
      },
      "output": {
        "resultWrapper": "StopStackSetOperationResult",
        "type": "structure",
        "members": {}
      }
    },
    "UpdateStack": {
      "input": {
        "type": "structure",
        "required": [
          "StackName"
        ],
        "members": {
          "StackName": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "StackPolicyDuringUpdateBody": {},
          "StackPolicyDuringUpdateURL": {},
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "ResourceTypes": {
            "shape": "Sl"
          },
          "RoleARN": {},
          "RollbackConfiguration": {
            "shape": "Sn"
          },
          "StackPolicyBody": {},
          "StackPolicyURL": {},
          "NotificationARNs": {
            "shape": "St"
          },
          "Tags": {
            "shape": "Sv"
          },
          "ClientRequestToken": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateStackResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "UpdateStackInstances": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName",
          "Accounts",
          "Regions"
        ],
        "members": {
          "StackSetName": {},
          "Accounts": {
            "shape": "S1g"
          },
          "Regions": {
            "shape": "S1i"
          },
          "ParameterOverrides": {
            "shape": "Se"
          },
          "OperationPreferences": {
            "shape": "S1k"
          },
          "OperationId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateStackInstancesResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateStackSet": {
      "input": {
        "type": "structure",
        "required": [
          "StackSetName"
        ],
        "members": {
          "StackSetName": {},
          "Description": {},
          "TemplateBody": {},
          "TemplateURL": {},
          "UsePreviousTemplate": {
            "type": "boolean"
          },
          "Parameters": {
            "shape": "Se"
          },
          "Capabilities": {
            "shape": "Sj"
          },
          "Tags": {
            "shape": "Sv"
          },
          "OperationPreferences": {
            "shape": "S1k"
          },
          "AdministrationRoleARN": {},
          "ExecutionRoleName": {},
          "OperationId": {
            "idempotencyToken": true
          },
          "Accounts": {
            "shape": "S1g"
          },
          "Regions": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "resultWrapper": "UpdateStackSetResult",
        "type": "structure",
        "members": {
          "OperationId": {}
        }
      }
    },
    "UpdateTerminationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "EnableTerminationProtection",
          "StackName"
        ],
        "members": {
          "EnableTerminationProtection": {
            "type": "boolean"
          },
          "StackName": {}
        }
      },
      "output": {
        "resultWrapper": "UpdateTerminationProtectionResult",
        "type": "structure",
        "members": {
          "StackId": {}
        }
      }
    },
    "ValidateTemplate": {
      "input": {
        "type": "structure",
        "members": {
          "TemplateBody": {},
          "TemplateURL": {}
        }
      },
      "output": {
        "resultWrapper": "ValidateTemplateResult",
        "type": "structure",
        "members": {
          "Parameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "NoEcho": {
                  "type": "boolean"
                },
                "Description": {}
              }
            }
          },
          "Description": {},
          "Capabilities": {
            "shape": "Sj"
          },
          "CapabilitiesReason": {},
          "DeclaredTransforms": {
            "shape": "S5r"
          }
        }
      }
    }
  },
  "shapes": {
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ParameterKey": {},
          "ParameterValue": {},
          "UsePreviousValue": {
            "type": "boolean"
          },
          "ResolvedValue": {}
        }
      }
    },
    "Sj": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "list",
      "member": {}
    },
    "Sn": {
      "type": "structure",
      "members": {
        "RollbackTriggers": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Arn",
              "Type"
            ],
            "members": {
              "Arn": {},
              "Type": {}
            }
          }
        },
        "MonitoringTimeInMinutes": {
          "type": "integer"
        }
      }
    },
    "St": {
      "type": "list",
      "member": {}
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S1i": {
      "type": "list",
      "member": {}
    },
    "S1k": {
      "type": "structure",
      "members": {
        "RegionOrder": {
          "shape": "S1i"
        },
        "FailureToleranceCount": {
          "type": "integer"
        },
        "FailureTolerancePercentage": {
          "type": "integer"
        },
        "MaxConcurrentCount": {
          "type": "integer"
        },
        "MaxConcurrentPercentage": {
          "type": "integer"
        }
      }
    },
    "S3o": {
      "type": "structure",
      "required": [
        "StackResourceDriftStatus"
      ],
      "members": {
        "StackResourceDriftStatus": {},
        "LastCheckTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S3v": {
      "type": "structure",
      "required": [
        "StackId",
        "LogicalResourceId",
        "ResourceType",
        "StackResourceDriftStatus",
        "Timestamp"
      ],
      "members": {
        "StackId": {},
        "LogicalResourceId": {},
        "PhysicalResourceId": {},
        "PhysicalResourceIdContext": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Key",
              "Value"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        },
        "ResourceType": {},
        "ExpectedProperties": {},
        "ActualProperties": {},
        "PropertyDifferences": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "PropertyPath",
              "ExpectedValue",
              "ActualValue",
              "DifferenceType"
            ],
            "members": {
              "PropertyPath": {},
              "ExpectedValue": {},
              "ActualValue": {},
              "DifferenceType": {}
            }
          }
        },
        "StackResourceDriftStatus": {},
        "Timestamp": {
          "type": "timestamp"
        }
      }
    },
    "S5r": {
      "type": "list",
      "member": {}
    }
  }
}