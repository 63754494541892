{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2009-03-31",
    "endpointPrefix": "elasticmapreduce",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Amazon EMR",
    "serviceFullName": "Amazon Elastic MapReduce",
    "serviceId": "EMR",
    "signatureVersion": "v4",
    "targetPrefix": "ElasticMapReduce",
    "uid": "elasticmapreduce-2009-03-31"
  },
  "operations": {
    "AddInstanceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceFleet"
        ],
        "members": {
          "ClusterId": {},
          "InstanceFleet": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceFleetId": {}
        }
      }
    },
    "AddInstanceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "InstanceGroups",
          "JobFlowId"
        ],
        "members": {
          "InstanceGroups": {
            "shape": "Sq"
          },
          "JobFlowId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlowId": {},
          "InstanceGroupIds": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "AddJobFlowSteps": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowId",
          "Steps"
        ],
        "members": {
          "JobFlowId": {},
          "Steps": {
            "shape": "S1b"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StepIds": {
            "shape": "S1k"
          }
        }
      }
    },
    "AddTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceId": {},
          "Tags": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelSteps": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "StepIds": {
            "shape": "S1k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CancelStepsInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "StepId": {},
                "Status": {},
                "Reason": {}
              }
            }
          }
        }
      }
    },
    "CreateSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "SecurityConfiguration"
        ],
        "members": {
          "Name": {},
          "SecurityConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Name",
          "CreationDateTime"
        ],
        "members": {
          "Name": {},
          "CreationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DeleteSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeCluster": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Cluster": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Status": {
                "shape": "S25"
              },
              "Ec2InstanceAttributes": {
                "type": "structure",
                "members": {
                  "Ec2KeyName": {},
                  "Ec2SubnetId": {},
                  "RequestedEc2SubnetIds": {
                    "shape": "S2b"
                  },
                  "Ec2AvailabilityZone": {},
                  "RequestedEc2AvailabilityZones": {
                    "shape": "S2b"
                  },
                  "IamInstanceProfile": {},
                  "EmrManagedMasterSecurityGroup": {},
                  "EmrManagedSlaveSecurityGroup": {},
                  "ServiceAccessSecurityGroup": {},
                  "AdditionalMasterSecurityGroups": {
                    "shape": "S2c"
                  },
                  "AdditionalSlaveSecurityGroups": {
                    "shape": "S2c"
                  }
                }
              },
              "InstanceCollectionType": {},
              "LogUri": {},
              "RequestedAmiVersion": {},
              "RunningAmiVersion": {},
              "ReleaseLabel": {},
              "AutoTerminate": {
                "type": "boolean"
              },
              "TerminationProtected": {
                "type": "boolean"
              },
              "VisibleToAllUsers": {
                "type": "boolean"
              },
              "Applications": {
                "shape": "S2f"
              },
              "Tags": {
                "shape": "S1n"
              },
              "ServiceRole": {},
              "NormalizedInstanceHours": {
                "type": "integer"
              },
              "MasterPublicDnsName": {},
              "Configurations": {
                "shape": "Sh"
              },
              "SecurityConfiguration": {},
              "AutoScalingRole": {},
              "ScaleDownBehavior": {},
              "CustomAmiId": {},
              "EbsRootVolumeSize": {
                "type": "integer"
              },
              "RepoUpgradeOnBoot": {},
              "KerberosAttributes": {
                "shape": "S2j"
              }
            }
          }
        }
      }
    },
    "DescribeJobFlows": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "JobFlowIds": {
            "shape": "S1i"
          },
          "JobFlowStates": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlows": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "JobFlowId",
                "Name",
                "ExecutionStatusDetail",
                "Instances"
              ],
              "members": {
                "JobFlowId": {},
                "Name": {},
                "LogUri": {},
                "AmiVersion": {},
                "ExecutionStatusDetail": {
                  "type": "structure",
                  "required": [
                    "State",
                    "CreationDateTime"
                  ],
                  "members": {
                    "State": {},
                    "CreationDateTime": {
                      "type": "timestamp"
                    },
                    "StartDateTime": {
                      "type": "timestamp"
                    },
                    "ReadyDateTime": {
                      "type": "timestamp"
                    },
                    "EndDateTime": {
                      "type": "timestamp"
                    },
                    "LastStateChangeReason": {}
                  }
                },
                "Instances": {
                  "type": "structure",
                  "required": [
                    "MasterInstanceType",
                    "SlaveInstanceType",
                    "InstanceCount"
                  ],
                  "members": {
                    "MasterInstanceType": {},
                    "MasterPublicDnsName": {},
                    "MasterInstanceId": {},
                    "SlaveInstanceType": {},
                    "InstanceCount": {
                      "type": "integer"
                    },
                    "InstanceGroups": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "required": [
                          "Market",
                          "InstanceRole",
                          "InstanceType",
                          "InstanceRequestCount",
                          "InstanceRunningCount",
                          "State",
                          "CreationDateTime"
                        ],
                        "members": {
                          "InstanceGroupId": {},
                          "Name": {},
                          "Market": {},
                          "InstanceRole": {},
                          "BidPrice": {},
                          "InstanceType": {},
                          "InstanceRequestCount": {
                            "type": "integer"
                          },
                          "InstanceRunningCount": {
                            "type": "integer"
                          },
                          "State": {},
                          "LastStateChangeReason": {},
                          "CreationDateTime": {
                            "type": "timestamp"
                          },
                          "StartDateTime": {
                            "type": "timestamp"
                          },
                          "ReadyDateTime": {
                            "type": "timestamp"
                          },
                          "EndDateTime": {
                            "type": "timestamp"
                          }
                        }
                      }
                    },
                    "NormalizedInstanceHours": {
                      "type": "integer"
                    },
                    "Ec2KeyName": {},
                    "Ec2SubnetId": {},
                    "Placement": {
                      "shape": "S2v"
                    },
                    "KeepJobFlowAliveWhenNoSteps": {
                      "type": "boolean"
                    },
                    "TerminationProtected": {
                      "type": "boolean"
                    },
                    "HadoopVersion": {}
                  }
                },
                "Steps": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "StepConfig",
                      "ExecutionStatusDetail"
                    ],
                    "members": {
                      "StepConfig": {
                        "shape": "S1c"
                      },
                      "ExecutionStatusDetail": {
                        "type": "structure",
                        "required": [
                          "State",
                          "CreationDateTime"
                        ],
                        "members": {
                          "State": {},
                          "CreationDateTime": {
                            "type": "timestamp"
                          },
                          "StartDateTime": {
                            "type": "timestamp"
                          },
                          "EndDateTime": {
                            "type": "timestamp"
                          },
                          "LastStateChangeReason": {}
                        }
                      }
                    }
                  }
                },
                "BootstrapActions": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "BootstrapActionConfig": {
                        "shape": "S32"
                      }
                    }
                  }
                },
                "SupportedProducts": {
                  "shape": "S34"
                },
                "VisibleToAllUsers": {
                  "type": "boolean"
                },
                "JobFlowRole": {},
                "ServiceRole": {},
                "AutoScalingRole": {},
                "ScaleDownBehavior": {}
              }
            }
          }
        }
      },
      "deprecated": true
    },
    "DescribeSecurityConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {},
          "SecurityConfiguration": {},
          "CreationDateTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStep": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "StepId"
        ],
        "members": {
          "ClusterId": {},
          "StepId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Step": {
            "type": "structure",
            "members": {
              "Id": {},
              "Name": {},
              "Config": {
                "shape": "S3a"
              },
              "ActionOnFailure": {},
              "Status": {
                "shape": "S3b"
              }
            }
          }
        }
      }
    },
    "GetBlockPublicAccessConfiguration": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "required": [
          "BlockPublicAccessConfiguration",
          "BlockPublicAccessConfigurationMetadata"
        ],
        "members": {
          "BlockPublicAccessConfiguration": {
            "shape": "S3j"
          },
          "BlockPublicAccessConfigurationMetadata": {
            "type": "structure",
            "required": [
              "CreationDateTime",
              "CreatedByArn"
            ],
            "members": {
              "CreationDateTime": {
                "type": "timestamp"
              },
              "CreatedByArn": {}
            }
          }
        }
      }
    },
    "ListBootstrapActions": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BootstrapActions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "ScriptPath": {},
                "Args": {
                  "shape": "S2c"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListClusters": {
      "input": {
        "type": "structure",
        "members": {
          "CreatedAfter": {
            "type": "timestamp"
          },
          "CreatedBefore": {
            "type": "timestamp"
          },
          "ClusterStates": {
            "type": "list",
            "member": {}
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Clusters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {
                  "shape": "S25"
                },
                "NormalizedInstanceHours": {
                  "type": "integer"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstanceFleets": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceFleets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "InstanceFleetType": {},
                "TargetOnDemandCapacity": {
                  "type": "integer"
                },
                "TargetSpotCapacity": {
                  "type": "integer"
                },
                "ProvisionedOnDemandCapacity": {
                  "type": "integer"
                },
                "ProvisionedSpotCapacity": {
                  "type": "integer"
                },
                "InstanceTypeSpecifications": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "InstanceType": {},
                      "WeightedCapacity": {
                        "type": "integer"
                      },
                      "BidPrice": {},
                      "BidPriceAsPercentageOfOnDemandPrice": {
                        "type": "double"
                      },
                      "Configurations": {
                        "shape": "Sh"
                      },
                      "EbsBlockDevices": {
                        "shape": "S4a"
                      },
                      "EbsOptimized": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "LaunchSpecifications": {
                  "shape": "Sk"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstanceGroups": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Market": {},
                "InstanceGroupType": {},
                "BidPrice": {},
                "InstanceType": {},
                "RequestedInstanceCount": {
                  "type": "integer"
                },
                "RunningInstanceCount": {
                  "type": "integer"
                },
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "Configurations": {
                  "shape": "Sh"
                },
                "ConfigurationsVersion": {
                  "type": "long"
                },
                "LastSuccessfullyAppliedConfigurations": {
                  "shape": "Sh"
                },
                "LastSuccessfullyAppliedConfigurationsVersion": {
                  "type": "long"
                },
                "EbsBlockDevices": {
                  "shape": "S4a"
                },
                "EbsOptimized": {
                  "type": "boolean"
                },
                "ShrinkPolicy": {
                  "shape": "S4n"
                },
                "AutoScalingPolicy": {
                  "shape": "S4r"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListInstances": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "InstanceGroupTypes": {
            "type": "list",
            "member": {}
          },
          "InstanceFleetId": {},
          "InstanceFleetType": {},
          "InstanceStates": {
            "type": "list",
            "member": {}
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Ec2InstanceId": {},
                "PublicDnsName": {},
                "PublicIpAddress": {},
                "PrivateDnsName": {},
                "PrivateIpAddress": {},
                "Status": {
                  "type": "structure",
                  "members": {
                    "State": {},
                    "StateChangeReason": {
                      "type": "structure",
                      "members": {
                        "Code": {},
                        "Message": {}
                      }
                    },
                    "Timeline": {
                      "type": "structure",
                      "members": {
                        "CreationDateTime": {
                          "type": "timestamp"
                        },
                        "ReadyDateTime": {
                          "type": "timestamp"
                        },
                        "EndDateTime": {
                          "type": "timestamp"
                        }
                      }
                    }
                  }
                },
                "InstanceGroupId": {},
                "InstanceFleetId": {},
                "Market": {},
                "InstanceType": {},
                "EbsVolumes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Device": {},
                      "VolumeId": {}
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListSecurityConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecurityConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "CreationDateTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListSteps": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId"
        ],
        "members": {
          "ClusterId": {},
          "StepStates": {
            "type": "list",
            "member": {}
          },
          "StepIds": {
            "shape": "S1i"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Steps": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Config": {
                  "shape": "S3a"
                },
                "ActionOnFailure": {},
                "Status": {
                  "shape": "S3b"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ModifyInstanceFleet": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceFleet"
        ],
        "members": {
          "ClusterId": {},
          "InstanceFleet": {
            "type": "structure",
            "required": [
              "InstanceFleetId"
            ],
            "members": {
              "InstanceFleetId": {},
              "TargetOnDemandCapacity": {
                "type": "integer"
              },
              "TargetSpotCapacity": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "ModifyInstanceGroups": {
      "input": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InstanceGroupId"
              ],
              "members": {
                "InstanceGroupId": {},
                "InstanceCount": {
                  "type": "integer"
                },
                "EC2InstanceIdsToTerminate": {
                  "type": "list",
                  "member": {}
                },
                "ShrinkPolicy": {
                  "shape": "S4n"
                },
                "Configurations": {
                  "shape": "Sh"
                }
              }
            }
          }
        }
      }
    },
    "PutAutoScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceGroupId",
          "AutoScalingPolicy"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "AutoScalingPolicy": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {},
          "AutoScalingPolicy": {
            "shape": "S4r"
          }
        }
      }
    },
    "PutBlockPublicAccessConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "BlockPublicAccessConfiguration"
        ],
        "members": {
          "BlockPublicAccessConfiguration": {
            "shape": "S3j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveAutoScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "ClusterId",
          "InstanceGroupId"
        ],
        "members": {
          "ClusterId": {},
          "InstanceGroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveTags": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceId": {},
          "TagKeys": {
            "shape": "S2c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RunJobFlow": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Instances"
        ],
        "members": {
          "Name": {},
          "LogUri": {},
          "AdditionalInfo": {},
          "AmiVersion": {},
          "ReleaseLabel": {},
          "Instances": {
            "type": "structure",
            "members": {
              "MasterInstanceType": {},
              "SlaveInstanceType": {},
              "InstanceCount": {
                "type": "integer"
              },
              "InstanceGroups": {
                "shape": "Sq"
              },
              "InstanceFleets": {
                "type": "list",
                "member": {
                  "shape": "S3"
                }
              },
              "Ec2KeyName": {},
              "Placement": {
                "shape": "S2v"
              },
              "KeepJobFlowAliveWhenNoSteps": {
                "type": "boolean"
              },
              "TerminationProtected": {
                "type": "boolean"
              },
              "HadoopVersion": {},
              "Ec2SubnetId": {},
              "Ec2SubnetIds": {
                "shape": "S2b"
              },
              "EmrManagedMasterSecurityGroup": {},
              "EmrManagedSlaveSecurityGroup": {},
              "ServiceAccessSecurityGroup": {},
              "AdditionalMasterSecurityGroups": {
                "shape": "S5z"
              },
              "AdditionalSlaveSecurityGroups": {
                "shape": "S5z"
              }
            }
          },
          "Steps": {
            "shape": "S1b"
          },
          "BootstrapActions": {
            "type": "list",
            "member": {
              "shape": "S32"
            }
          },
          "SupportedProducts": {
            "shape": "S34"
          },
          "NewSupportedProducts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Args": {
                  "shape": "S1i"
                }
              }
            }
          },
          "Applications": {
            "shape": "S2f"
          },
          "Configurations": {
            "shape": "Sh"
          },
          "VisibleToAllUsers": {
            "type": "boolean"
          },
          "JobFlowRole": {},
          "ServiceRole": {},
          "Tags": {
            "shape": "S1n"
          },
          "SecurityConfiguration": {},
          "AutoScalingRole": {},
          "ScaleDownBehavior": {},
          "CustomAmiId": {},
          "EbsRootVolumeSize": {
            "type": "integer"
          },
          "RepoUpgradeOnBoot": {},
          "KerberosAttributes": {
            "shape": "S2j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobFlowId": {}
        }
      }
    },
    "SetTerminationProtection": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds",
          "TerminationProtected"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S1i"
          },
          "TerminationProtected": {
            "type": "boolean"
          }
        }
      }
    },
    "SetVisibleToAllUsers": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds",
          "VisibleToAllUsers"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S1i"
          },
          "VisibleToAllUsers": {
            "type": "boolean"
          }
        }
      }
    },
    "TerminateJobFlows": {
      "input": {
        "type": "structure",
        "required": [
          "JobFlowIds"
        ],
        "members": {
          "JobFlowIds": {
            "shape": "S1i"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "InstanceFleetType"
      ],
      "members": {
        "Name": {},
        "InstanceFleetType": {},
        "TargetOnDemandCapacity": {
          "type": "integer"
        },
        "TargetSpotCapacity": {
          "type": "integer"
        },
        "InstanceTypeConfigs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "InstanceType"
            ],
            "members": {
              "InstanceType": {},
              "WeightedCapacity": {
                "type": "integer"
              },
              "BidPrice": {},
              "BidPriceAsPercentageOfOnDemandPrice": {
                "type": "double"
              },
              "EbsConfiguration": {
                "shape": "Sa"
              },
              "Configurations": {
                "shape": "Sh"
              }
            }
          }
        },
        "LaunchSpecifications": {
          "shape": "Sk"
        }
      }
    },
    "Sa": {
      "type": "structure",
      "members": {
        "EbsBlockDeviceConfigs": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "VolumeSpecification"
            ],
            "members": {
              "VolumeSpecification": {
                "shape": "Sd"
              },
              "VolumesPerInstance": {
                "type": "integer"
              }
            }
          }
        },
        "EbsOptimized": {
          "type": "boolean"
        }
      }
    },
    "Sd": {
      "type": "structure",
      "required": [
        "VolumeType",
        "SizeInGB"
      ],
      "members": {
        "VolumeType": {},
        "Iops": {
          "type": "integer"
        },
        "SizeInGB": {
          "type": "integer"
        }
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Classification": {},
          "Configurations": {
            "shape": "Sh"
          },
          "Properties": {
            "shape": "Sj"
          }
        }
      }
    },
    "Sj": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sk": {
      "type": "structure",
      "required": [
        "SpotSpecification"
      ],
      "members": {
        "SpotSpecification": {
          "type": "structure",
          "required": [
            "TimeoutDurationMinutes",
            "TimeoutAction"
          ],
          "members": {
            "TimeoutDurationMinutes": {
              "type": "integer"
            },
            "TimeoutAction": {},
            "BlockDurationMinutes": {
              "type": "integer"
            }
          }
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "InstanceRole",
          "InstanceType",
          "InstanceCount"
        ],
        "members": {
          "Name": {},
          "Market": {},
          "InstanceRole": {},
          "BidPrice": {},
          "InstanceType": {},
          "InstanceCount": {
            "type": "integer"
          },
          "Configurations": {
            "shape": "Sh"
          },
          "EbsConfiguration": {
            "shape": "Sa"
          },
          "AutoScalingPolicy": {
            "shape": "Su"
          }
        }
      }
    },
    "Su": {
      "type": "structure",
      "required": [
        "Constraints",
        "Rules"
      ],
      "members": {
        "Constraints": {
          "shape": "Sv"
        },
        "Rules": {
          "shape": "Sw"
        }
      }
    },
    "Sv": {
      "type": "structure",
      "required": [
        "MinCapacity",
        "MaxCapacity"
      ],
      "members": {
        "MinCapacity": {
          "type": "integer"
        },
        "MaxCapacity": {
          "type": "integer"
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Action",
          "Trigger"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Action": {
            "type": "structure",
            "required": [
              "SimpleScalingPolicyConfiguration"
            ],
            "members": {
              "Market": {},
              "SimpleScalingPolicyConfiguration": {
                "type": "structure",
                "required": [
                  "ScalingAdjustment"
                ],
                "members": {
                  "AdjustmentType": {},
                  "ScalingAdjustment": {
                    "type": "integer"
                  },
                  "CoolDown": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "Trigger": {
            "type": "structure",
            "required": [
              "CloudWatchAlarmDefinition"
            ],
            "members": {
              "CloudWatchAlarmDefinition": {
                "type": "structure",
                "required": [
                  "ComparisonOperator",
                  "MetricName",
                  "Period",
                  "Threshold"
                ],
                "members": {
                  "ComparisonOperator": {},
                  "EvaluationPeriods": {
                    "type": "integer"
                  },
                  "MetricName": {},
                  "Namespace": {},
                  "Period": {
                    "type": "integer"
                  },
                  "Statistic": {},
                  "Threshold": {
                    "type": "double"
                  },
                  "Unit": {},
                  "Dimensions": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "Key": {},
                        "Value": {}
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1b": {
      "type": "list",
      "member": {
        "shape": "S1c"
      }
    },
    "S1c": {
      "type": "structure",
      "required": [
        "Name",
        "HadoopJarStep"
      ],
      "members": {
        "Name": {},
        "ActionOnFailure": {},
        "HadoopJarStep": {
          "type": "structure",
          "required": [
            "Jar"
          ],
          "members": {
            "Properties": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Key": {},
                  "Value": {}
                }
              }
            },
            "Jar": {},
            "MainClass": {},
            "Args": {
              "shape": "S1i"
            }
          }
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {}
    },
    "S1k": {
      "type": "list",
      "member": {}
    },
    "S1n": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "State": {},
        "StateChangeReason": {
          "type": "structure",
          "members": {
            "Code": {},
            "Message": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "CreationDateTime": {
              "type": "timestamp"
            },
            "ReadyDateTime": {
              "type": "timestamp"
            },
            "EndDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2c": {
      "type": "list",
      "member": {}
    },
    "S2f": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "Args": {
            "shape": "S2c"
          },
          "AdditionalInfo": {
            "shape": "Sj"
          }
        }
      }
    },
    "S2j": {
      "type": "structure",
      "required": [
        "Realm",
        "KdcAdminPassword"
      ],
      "members": {
        "Realm": {},
        "KdcAdminPassword": {},
        "CrossRealmTrustPrincipalPassword": {},
        "ADDomainJoinUser": {},
        "ADDomainJoinPassword": {}
      }
    },
    "S2v": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {},
        "AvailabilityZones": {
          "shape": "S2b"
        }
      }
    },
    "S32": {
      "type": "structure",
      "required": [
        "Name",
        "ScriptBootstrapAction"
      ],
      "members": {
        "Name": {},
        "ScriptBootstrapAction": {
          "type": "structure",
          "required": [
            "Path"
          ],
          "members": {
            "Path": {},
            "Args": {
              "shape": "S1i"
            }
          }
        }
      }
    },
    "S34": {
      "type": "list",
      "member": {}
    },
    "S3a": {
      "type": "structure",
      "members": {
        "Jar": {},
        "Properties": {
          "shape": "Sj"
        },
        "MainClass": {},
        "Args": {
          "shape": "S2c"
        }
      }
    },
    "S3b": {
      "type": "structure",
      "members": {
        "State": {},
        "StateChangeReason": {
          "type": "structure",
          "members": {
            "Code": {},
            "Message": {}
          }
        },
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Reason": {},
            "Message": {},
            "LogFile": {}
          }
        },
        "Timeline": {
          "type": "structure",
          "members": {
            "CreationDateTime": {
              "type": "timestamp"
            },
            "StartDateTime": {
              "type": "timestamp"
            },
            "EndDateTime": {
              "type": "timestamp"
            }
          }
        }
      }
    },
    "S3j": {
      "type": "structure",
      "required": [
        "BlockPublicSecurityGroupRules"
      ],
      "members": {
        "BlockPublicSecurityGroupRules": {
          "type": "boolean"
        },
        "PermittedPublicSecurityGroupRuleRanges": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "MinRange"
            ],
            "members": {
              "MinRange": {
                "type": "integer"
              },
              "MaxRange": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S4a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "VolumeSpecification": {
            "shape": "Sd"
          },
          "Device": {}
        }
      }
    },
    "S4n": {
      "type": "structure",
      "members": {
        "DecommissionTimeout": {
          "type": "integer"
        },
        "InstanceResizePolicy": {
          "type": "structure",
          "members": {
            "InstancesToTerminate": {
              "shape": "S4p"
            },
            "InstancesToProtect": {
              "shape": "S4p"
            },
            "InstanceTerminationTimeout": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S4p": {
      "type": "list",
      "member": {}
    },
    "S4r": {
      "type": "structure",
      "members": {
        "Status": {
          "type": "structure",
          "members": {
            "State": {},
            "StateChangeReason": {
              "type": "structure",
              "members": {
                "Code": {},
                "Message": {}
              }
            }
          }
        },
        "Constraints": {
          "shape": "Sv"
        },
        "Rules": {
          "shape": "Sw"
        }
      }
    },
    "S5z": {
      "type": "list",
      "member": {}
    }
  }
}