{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-11-28",
    "endpointPrefix": "organizations",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Organizations",
    "serviceFullName": "AWS Organizations",
    "serviceId": "Organizations",
    "signatureVersion": "v4",
    "targetPrefix": "AWSOrganizationsV20161128",
    "uid": "organizations-2016-11-28"
  },
  "operations": {
    "AcceptHandshake": {
      "input": {
        "type": "structure",
        "required": [
          "HandshakeId"
        ],
        "members": {
          "HandshakeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshake": {
            "shape": "S4"
          }
        }
      }
    },
    "AttachPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId",
          "TargetId"
        ],
        "members": {
          "PolicyId": {},
          "TargetId": {}
        }
      }
    },
    "CancelHandshake": {
      "input": {
        "type": "structure",
        "required": [
          "HandshakeId"
        ],
        "members": {
          "HandshakeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshake": {
            "shape": "S4"
          }
        }
      }
    },
    "CreateAccount": {
      "input": {
        "type": "structure",
        "required": [
          "Email",
          "AccountName"
        ],
        "members": {
          "Email": {
            "shape": "Sn"
          },
          "AccountName": {
            "shape": "So"
          },
          "RoleName": {},
          "IamUserAccessToBilling": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateAccountStatus": {
            "shape": "Ss"
          }
        }
      }
    },
    "CreateGovCloudAccount": {
      "input": {
        "type": "structure",
        "required": [
          "Email",
          "AccountName"
        ],
        "members": {
          "Email": {
            "shape": "Sn"
          },
          "AccountName": {
            "shape": "So"
          },
          "RoleName": {},
          "IamUserAccessToBilling": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateAccountStatus": {
            "shape": "Ss"
          }
        }
      }
    },
    "CreateOrganization": {
      "input": {
        "type": "structure",
        "members": {
          "FeatureSet": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Organization": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateOrganizationalUnit": {
      "input": {
        "type": "structure",
        "required": [
          "ParentId",
          "Name"
        ],
        "members": {
          "ParentId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationalUnit": {
            "shape": "S1e"
          }
        }
      }
    },
    "CreatePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Content",
          "Description",
          "Name",
          "Type"
        ],
        "members": {
          "Content": {},
          "Description": {},
          "Name": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {
            "shape": "S1m"
          }
        }
      }
    },
    "DeclineHandshake": {
      "input": {
        "type": "structure",
        "required": [
          "HandshakeId"
        ],
        "members": {
          "HandshakeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshake": {
            "shape": "S4"
          }
        }
      }
    },
    "DeleteOrganization": {},
    "DeleteOrganizationalUnit": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationalUnitId"
        ],
        "members": {
          "OrganizationalUnitId": {}
        }
      }
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {}
        }
      }
    },
    "DescribeAccount": {
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Account": {
            "shape": "S1w"
          }
        }
      }
    },
    "DescribeCreateAccountStatus": {
      "input": {
        "type": "structure",
        "required": [
          "CreateAccountRequestId"
        ],
        "members": {
          "CreateAccountRequestId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateAccountStatus": {
            "shape": "Ss"
          }
        }
      }
    },
    "DescribeHandshake": {
      "input": {
        "type": "structure",
        "required": [
          "HandshakeId"
        ],
        "members": {
          "HandshakeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshake": {
            "shape": "S4"
          }
        }
      }
    },
    "DescribeOrganization": {
      "output": {
        "type": "structure",
        "members": {
          "Organization": {
            "shape": "S12"
          }
        }
      }
    },
    "DescribeOrganizationalUnit": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationalUnitId"
        ],
        "members": {
          "OrganizationalUnitId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationalUnit": {
            "shape": "S1e"
          }
        }
      }
    },
    "DescribePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {
            "shape": "S1m"
          }
        }
      }
    },
    "DetachPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId",
          "TargetId"
        ],
        "members": {
          "PolicyId": {},
          "TargetId": {}
        }
      }
    },
    "DisableAWSServiceAccess": {
      "input": {
        "type": "structure",
        "required": [
          "ServicePrincipal"
        ],
        "members": {
          "ServicePrincipal": {}
        }
      }
    },
    "DisablePolicyType": {
      "input": {
        "type": "structure",
        "required": [
          "RootId",
          "PolicyType"
        ],
        "members": {
          "RootId": {},
          "PolicyType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Root": {
            "shape": "S2e"
          }
        }
      }
    },
    "EnableAWSServiceAccess": {
      "input": {
        "type": "structure",
        "required": [
          "ServicePrincipal"
        ],
        "members": {
          "ServicePrincipal": {}
        }
      }
    },
    "EnableAllFeatures": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshake": {
            "shape": "S4"
          }
        }
      }
    },
    "EnablePolicyType": {
      "input": {
        "type": "structure",
        "required": [
          "RootId",
          "PolicyType"
        ],
        "members": {
          "RootId": {},
          "PolicyType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Root": {
            "shape": "S2e"
          }
        }
      }
    },
    "InviteAccountToOrganization": {
      "input": {
        "type": "structure",
        "required": [
          "Target"
        ],
        "members": {
          "Target": {
            "shape": "S7"
          },
          "Notes": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshake": {
            "shape": "S4"
          }
        }
      }
    },
    "LeaveOrganization": {},
    "ListAWSServiceAccessForOrganization": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EnabledServicePrincipals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ServicePrincipal": {},
                "DateEnabled": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListAccounts": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accounts": {
            "shape": "S2x"
          },
          "NextToken": {}
        }
      }
    },
    "ListAccountsForParent": {
      "input": {
        "type": "structure",
        "required": [
          "ParentId"
        ],
        "members": {
          "ParentId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accounts": {
            "shape": "S2x"
          },
          "NextToken": {}
        }
      }
    },
    "ListChildren": {
      "input": {
        "type": "structure",
        "required": [
          "ParentId",
          "ChildType"
        ],
        "members": {
          "ParentId": {},
          "ChildType": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Children": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Type": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListCreateAccountStatus": {
      "input": {
        "type": "structure",
        "members": {
          "States": {
            "type": "list",
            "member": {}
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CreateAccountStatuses": {
            "type": "list",
            "member": {
              "shape": "Ss"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListHandshakesForAccount": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S3b"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshakes": {
            "shape": "S3d"
          },
          "NextToken": {}
        }
      }
    },
    "ListHandshakesForOrganization": {
      "input": {
        "type": "structure",
        "members": {
          "Filter": {
            "shape": "S3b"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Handshakes": {
            "shape": "S3d"
          },
          "NextToken": {}
        }
      }
    },
    "ListOrganizationalUnitsForParent": {
      "input": {
        "type": "structure",
        "required": [
          "ParentId"
        ],
        "members": {
          "ParentId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationalUnits": {
            "type": "list",
            "member": {
              "shape": "S1e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListParents": {
      "input": {
        "type": "structure",
        "required": [
          "ChildId"
        ],
        "members": {
          "ChildId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Parents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Type": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "Filter"
        ],
        "members": {
          "Filter": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policies": {
            "shape": "S3q"
          },
          "NextToken": {}
        }
      }
    },
    "ListPoliciesForTarget": {
      "input": {
        "type": "structure",
        "required": [
          "TargetId",
          "Filter"
        ],
        "members": {
          "TargetId": {},
          "Filter": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policies": {
            "shape": "S3q"
          },
          "NextToken": {}
        }
      }
    },
    "ListRoots": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Roots": {
            "type": "list",
            "member": {
              "shape": "S2e"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "ResourceId": {},
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3z"
          },
          "NextToken": {}
        }
      }
    },
    "ListTargetsForPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Targets": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetId": {},
                "Arn": {},
                "Name": {},
                "Type": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "MoveAccount": {
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "SourceParentId",
          "DestinationParentId"
        ],
        "members": {
          "AccountId": {},
          "SourceParentId": {},
          "DestinationParentId": {}
        }
      }
    },
    "RemoveAccountFromOrganization": {
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "Tags"
        ],
        "members": {
          "ResourceId": {},
          "Tags": {
            "shape": "S3z"
          }
        }
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagKeys"
        ],
        "members": {
          "ResourceId": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "UpdateOrganizationalUnit": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationalUnitId"
        ],
        "members": {
          "OrganizationalUnitId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationalUnit": {
            "shape": "S1e"
          }
        }
      }
    },
    "UpdatePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {},
          "Name": {},
          "Description": {},
          "Content": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {
            "shape": "S1m"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Parties": {
          "type": "list",
          "member": {
            "shape": "S7"
          }
        },
        "State": {},
        "RequestedTimestamp": {
          "type": "timestamp"
        },
        "ExpirationTimestamp": {
          "type": "timestamp"
        },
        "Action": {},
        "Resources": {
          "shape": "Sd"
        }
      }
    },
    "S7": {
      "type": "structure",
      "required": [
        "Id",
        "Type"
      ],
      "members": {
        "Id": {
          "type": "string",
          "sensitive": true
        },
        "Type": {}
      }
    },
    "Sd": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Value": {
            "type": "string",
            "sensitive": true
          },
          "Type": {},
          "Resources": {
            "shape": "Sd"
          }
        }
      }
    },
    "Sn": {
      "type": "string",
      "sensitive": true
    },
    "So": {
      "type": "string",
      "sensitive": true
    },
    "Ss": {
      "type": "structure",
      "members": {
        "Id": {},
        "AccountName": {
          "shape": "So"
        },
        "State": {},
        "RequestedTimestamp": {
          "type": "timestamp"
        },
        "CompletedTimestamp": {
          "type": "timestamp"
        },
        "AccountId": {},
        "GovCloudAccountId": {},
        "FailureReason": {}
      }
    },
    "S12": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "FeatureSet": {},
        "MasterAccountArn": {},
        "MasterAccountId": {},
        "MasterAccountEmail": {
          "shape": "Sn"
        },
        "AvailablePolicyTypes": {
          "shape": "S16"
        }
      }
    },
    "S16": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Status": {}
        }
      }
    },
    "S1e": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {}
      }
    },
    "S1m": {
      "type": "structure",
      "members": {
        "PolicySummary": {
          "shape": "S1n"
        },
        "Content": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {},
        "Description": {},
        "Type": {},
        "AwsManaged": {
          "type": "boolean"
        }
      }
    },
    "S1w": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Email": {
          "shape": "Sn"
        },
        "Name": {
          "shape": "So"
        },
        "Status": {},
        "JoinedMethod": {},
        "JoinedTimestamp": {
          "type": "timestamp"
        }
      }
    },
    "S2e": {
      "type": "structure",
      "members": {
        "Id": {},
        "Arn": {},
        "Name": {},
        "PolicyTypes": {
          "shape": "S16"
        }
      }
    },
    "S2x": {
      "type": "list",
      "member": {
        "shape": "S1w"
      }
    },
    "S3b": {
      "type": "structure",
      "members": {
        "ActionType": {},
        "ParentHandshakeId": {}
      }
    },
    "S3d": {
      "type": "list",
      "member": {
        "shape": "S4"
      }
    },
    "S3q": {
      "type": "list",
      "member": {
        "shape": "S1n"
      }
    },
    "S3z": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}