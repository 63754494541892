import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { useState } from 'react';
import { css } from '@emotion/react';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUp from '@mui/icons-material/KeyboardArrowUp';
import MUICollapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
const CollapseIcon = ({ open }) => (_jsx(IconButton, { role: "button", size: "small", children: open ? _jsx(KeyboardArrowUp, {}) : _jsx(KeyboardArrowDown, {}) }));
const collapseHeaderStyles = css `
  padding: 20px;
  display: flex;
  cursor: pointer;
  width: 100%;
  justify-content: space-between;
  align-content: center;
  align-items: center;
`;
const CollapseHeader = ({ onChange, open, children }) => (_jsxs("div", { css: collapseHeaderStyles, onClick: () => onChange(), children: [children, _jsx(CollapseIcon, { open: open })] }));
const collapseStyles = css `
  padding: 0 20px;
`;
export const Collapse = ({ header, children, defaultCollapsed = false, ...rest }) => {
    const [open, setOpen] = useState(!defaultCollapsed);
    const handleChange = () => {
        setOpen(!open);
    };
    return (_jsxs("div", { ...rest, children: [_jsx(CollapseHeader, { onChange: handleChange, open: open, children: header }), _jsx(MUICollapse, { in: open, timeout: "auto", css: collapseStyles, children: children })] }));
};
