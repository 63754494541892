{
  "pagination": {
    "DescribeImages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "imageDetails"
    },
    "DescribeRepositories": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "repositories"
    },
    "ListImages": {
      "input_token": "nextToken",
      "limit_key": "maxResults",
      "output_token": "nextToken",
      "result_key": "imageIds"
    }
  }
}