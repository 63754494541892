{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-08-04",
    "endpointPrefix": "health",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWSHealth",
    "serviceFullName": "AWS Health APIs and Notifications",
    "serviceId": "Health",
    "signatureVersion": "v4",
    "targetPrefix": "AWSHealth_20160804",
    "uid": "health-2016-08-04"
  },
  "operations": {
    "DescribeAffectedEntities": {
      "input": {
        "type": "structure",
        "required": [
          "filter"
        ],
        "members": {
          "filter": {
            "type": "structure",
            "required": [
              "eventArns"
            ],
            "members": {
              "eventArns": {
                "shape": "S3"
              },
              "entityArns": {
                "shape": "S5"
              },
              "entityValues": {
                "shape": "S7"
              },
              "lastUpdatedTimes": {
                "shape": "S9"
              },
              "tags": {
                "shape": "Sc"
              },
              "statusCodes": {
                "type": "list",
                "member": {}
              }
            }
          },
          "locale": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "entities": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "entityArn": {},
                "eventArn": {},
                "entityValue": {},
                "entityUrl": {},
                "awsAccountId": {},
                "lastUpdatedTime": {
                  "type": "timestamp"
                },
                "statusCode": {},
                "tags": {
                  "shape": "Sd"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "DescribeEntityAggregates": {
      "input": {
        "type": "structure",
        "members": {
          "eventArns": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "entityAggregates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "eventArn": {},
                "count": {
                  "type": "integer"
                }
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeEventAggregates": {
      "input": {
        "type": "structure",
        "required": [
          "aggregateField"
        ],
        "members": {
          "filter": {
            "shape": "Sx"
          },
          "aggregateField": {},
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventAggregates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "aggregateValue": {},
                "count": {
                  "type": "integer"
                }
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "DescribeEventDetails": {
      "input": {
        "type": "structure",
        "required": [
          "eventArns"
        ],
        "members": {
          "eventArns": {
            "shape": "S3"
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "successfulSet": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "event": {
                  "shape": "S1j"
                },
                "eventDescription": {
                  "type": "structure",
                  "members": {
                    "latestDescription": {}
                  }
                },
                "eventMetadata": {
                  "type": "map",
                  "key": {},
                  "value": {}
                }
              }
            }
          },
          "failedSet": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "eventArn": {},
                "errorName": {},
                "errorMessage": {}
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeEventTypes": {
      "input": {
        "type": "structure",
        "members": {
          "filter": {
            "type": "structure",
            "members": {
              "eventTypeCodes": {
                "type": "list",
                "member": {}
              },
              "services": {
                "shape": "S10"
              },
              "eventTypeCategories": {
                "type": "list",
                "member": {}
              }
            }
          },
          "locale": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "eventTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "service": {},
                "code": {},
                "category": {}
              }
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "filter": {
            "shape": "Sx"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "locale": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "events": {
            "type": "list",
            "member": {
              "shape": "S1j"
            }
          },
          "nextToken": {}
        }
      },
      "idempotent": true
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S5": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {}
    },
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "from": {
            "type": "timestamp"
          },
          "to": {
            "type": "timestamp"
          }
        }
      }
    },
    "Sc": {
      "type": "list",
      "member": {
        "shape": "Sd"
      }
    },
    "Sd": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sx": {
      "type": "structure",
      "members": {
        "eventArns": {
          "shape": "S3"
        },
        "eventTypeCodes": {
          "type": "list",
          "member": {}
        },
        "services": {
          "shape": "S10"
        },
        "regions": {
          "type": "list",
          "member": {}
        },
        "availabilityZones": {
          "type": "list",
          "member": {}
        },
        "startTimes": {
          "shape": "S9"
        },
        "endTimes": {
          "shape": "S9"
        },
        "lastUpdatedTimes": {
          "shape": "S9"
        },
        "entityArns": {
          "shape": "S5"
        },
        "entityValues": {
          "shape": "S7"
        },
        "eventTypeCategories": {
          "type": "list",
          "member": {}
        },
        "tags": {
          "shape": "Sc"
        },
        "eventStatusCodes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S10": {
      "type": "list",
      "member": {}
    },
    "S1j": {
      "type": "structure",
      "members": {
        "arn": {},
        "service": {},
        "eventTypeCode": {},
        "eventTypeCategory": {},
        "region": {},
        "availabilityZone": {},
        "startTime": {
          "type": "timestamp"
        },
        "endTime": {
          "type": "timestamp"
        },
        "lastUpdatedTime": {
          "type": "timestamp"
        },
        "statusCode": {}
      }
    }
  }
}