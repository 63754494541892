{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2013-06-30",
    "endpointPrefix": "storagegateway",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Storage Gateway",
    "serviceId": "Storage Gateway",
    "signatureVersion": "v4",
    "targetPrefix": "StorageGateway_20130630",
    "uid": "storagegateway-2013-06-30"
  },
  "operations": {
    "ActivateGateway": {
      "input": {
        "type": "structure",
        "required": [
          "ActivationKey",
          "GatewayName",
          "GatewayTimezone",
          "GatewayRegion"
        ],
        "members": {
          "ActivationKey": {},
          "GatewayName": {},
          "GatewayTimezone": {},
          "GatewayRegion": {},
          "GatewayType": {},
          "TapeDriveType": {},
          "MediumChangerType": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AddCache": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskIds"
        ],
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceARN": {}
        }
      }
    },
    "AddUploadBuffer": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskIds"
        ],
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AddWorkingStorage": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskIds"
        ],
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "AssignTapePool": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN",
          "PoolId"
        ],
        "members": {
          "TapeARN": {},
          "PoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "AttachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "VolumeARN",
          "NetworkInterfaceId"
        ],
        "members": {
          "GatewayARN": {},
          "TargetName": {},
          "VolumeARN": {},
          "NetworkInterfaceId": {},
          "DiskId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "TargetARN": {}
        }
      }
    },
    "CancelArchival": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "CancelRetrieval": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "CreateCachediSCSIVolume": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "VolumeSizeInBytes",
          "TargetName",
          "NetworkInterfaceId",
          "ClientToken"
        ],
        "members": {
          "GatewayARN": {},
          "VolumeSizeInBytes": {
            "type": "long"
          },
          "SnapshotId": {},
          "TargetName": {},
          "SourceVolumeARN": {},
          "NetworkInterfaceId": {},
          "ClientToken": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "TargetARN": {}
        }
      }
    },
    "CreateNFSFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "GatewayARN",
          "Role",
          "LocationARN"
        ],
        "members": {
          "ClientToken": {},
          "NFSFileShareDefaults": {
            "shape": "S1c"
          },
          "GatewayARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Role": {},
          "LocationARN": {},
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ClientList": {
            "shape": "S1j"
          },
          "Squash": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "CreateSMBFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "ClientToken",
          "GatewayARN",
          "Role",
          "LocationARN"
        ],
        "members": {
          "ClientToken": {},
          "GatewayARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Role": {},
          "LocationARN": {},
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          },
          "SMBACLEnabled": {
            "type": "boolean"
          },
          "AdminUserList": {
            "shape": "S1p"
          },
          "ValidUserList": {
            "shape": "S1p"
          },
          "InvalidUserList": {
            "shape": "S1p"
          },
          "Authentication": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "CreateSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN",
          "SnapshotDescription"
        ],
        "members": {
          "VolumeARN": {},
          "SnapshotDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "SnapshotId": {}
        }
      }
    },
    "CreateSnapshotFromVolumeRecoveryPoint": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN",
          "SnapshotDescription"
        ],
        "members": {
          "VolumeARN": {},
          "SnapshotDescription": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotId": {},
          "VolumeARN": {},
          "VolumeRecoveryPointTime": {}
        }
      }
    },
    "CreateStorediSCSIVolume": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DiskId",
          "PreserveExistingData",
          "TargetName",
          "NetworkInterfaceId"
        ],
        "members": {
          "GatewayARN": {},
          "DiskId": {},
          "SnapshotId": {},
          "PreserveExistingData": {
            "type": "boolean"
          },
          "TargetName": {},
          "NetworkInterfaceId": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "VolumeSizeInBytes": {
            "type": "long"
          },
          "TargetARN": {}
        }
      }
    },
    "CreateTapeWithBarcode": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeSizeInBytes",
          "TapeBarcode"
        ],
        "members": {
          "GatewayARN": {},
          "TapeSizeInBytes": {
            "type": "long"
          },
          "TapeBarcode": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "PoolId": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "CreateTapes": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeSizeInBytes",
          "ClientToken",
          "NumTapesToCreate",
          "TapeBarcodePrefix"
        ],
        "members": {
          "GatewayARN": {},
          "TapeSizeInBytes": {
            "type": "long"
          },
          "ClientToken": {},
          "NumTapesToCreate": {
            "type": "integer"
          },
          "TapeBarcodePrefix": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "PoolId": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARNs": {
            "shape": "S2a"
          }
        }
      }
    },
    "DeleteBandwidthRateLimit": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "BandwidthType"
        ],
        "members": {
          "GatewayARN": {},
          "BandwidthType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "DeleteChapCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "TargetARN",
          "InitiatorName"
        ],
        "members": {
          "TargetARN": {},
          "InitiatorName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TargetARN": {},
          "InitiatorName": {}
        }
      }
    },
    "DeleteFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "ForceDelete": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "DeleteGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "DeleteSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "DeleteTape": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "TapeARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "DeleteTapeArchive": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN"
        ],
        "members": {
          "TapeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "DeleteVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "DescribeBandwidthRateLimit": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "AverageUploadRateLimitInBitsPerSec": {
            "type": "long"
          },
          "AverageDownloadRateLimitInBitsPerSec": {
            "type": "long"
          }
        }
      }
    },
    "DescribeCache": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          },
          "CacheAllocatedInBytes": {
            "type": "long"
          },
          "CacheUsedPercentage": {
            "type": "double"
          },
          "CacheDirtyPercentage": {
            "type": "double"
          },
          "CacheHitPercentage": {
            "type": "double"
          },
          "CacheMissPercentage": {
            "type": "double"
          }
        }
      }
    },
    "DescribeCachediSCSIVolumes": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARNs"
        ],
        "members": {
          "VolumeARNs": {
            "shape": "S31"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CachediSCSIVolumes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeId": {},
                "VolumeType": {},
                "VolumeStatus": {},
                "VolumeAttachmentStatus": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeProgress": {
                  "type": "double"
                },
                "SourceSnapshotId": {},
                "VolumeiSCSIAttributes": {
                  "shape": "S3a"
                },
                "CreatedDate": {
                  "type": "timestamp"
                },
                "VolumeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "TargetName": {}
              }
            }
          }
        }
      }
    },
    "DescribeChapCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "TargetARN"
        ],
        "members": {
          "TargetARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChapCredentials": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TargetARN": {},
                "SecretToAuthenticateInitiator": {
                  "shape": "S3j"
                },
                "InitiatorName": {},
                "SecretToAuthenticateTarget": {
                  "shape": "S3j"
                }
              }
            }
          }
        }
      }
    },
    "DescribeGatewayInformation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "GatewayId": {},
          "GatewayName": {},
          "GatewayTimezone": {},
          "GatewayState": {},
          "GatewayNetworkInterfaces": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Ipv4Address": {},
                "MacAddress": {},
                "Ipv6Address": {}
              }
            }
          },
          "GatewayType": {},
          "NextUpdateAvailabilityDate": {},
          "LastSoftwareUpdate": {},
          "Ec2InstanceId": {},
          "Ec2InstanceRegion": {},
          "Tags": {
            "shape": "S9"
          },
          "VPCEndpoint": {}
        }
      }
    },
    "DescribeMaintenanceStartTime": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "HourOfDay": {
            "type": "integer"
          },
          "MinuteOfHour": {
            "type": "integer"
          },
          "DayOfWeek": {
            "type": "integer"
          },
          "DayOfMonth": {
            "type": "integer"
          },
          "Timezone": {}
        }
      }
    },
    "DescribeNFSFileShares": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARNList"
        ],
        "members": {
          "FileShareARNList": {
            "shape": "S41"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NFSFileShareInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "NFSFileShareDefaults": {
                  "shape": "S1c"
                },
                "FileShareARN": {},
                "FileShareId": {},
                "FileShareStatus": {},
                "GatewayARN": {},
                "KMSEncrypted": {
                  "type": "boolean"
                },
                "KMSKey": {},
                "Path": {},
                "Role": {},
                "LocationARN": {},
                "DefaultStorageClass": {},
                "ObjectACL": {},
                "ClientList": {
                  "shape": "S1j"
                },
                "Squash": {},
                "ReadOnly": {
                  "type": "boolean"
                },
                "GuessMIMETypeEnabled": {
                  "type": "boolean"
                },
                "RequesterPays": {
                  "type": "boolean"
                },
                "Tags": {
                  "shape": "S9"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSMBFileShares": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARNList"
        ],
        "members": {
          "FileShareARNList": {
            "shape": "S41"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SMBFileShareInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FileShareARN": {},
                "FileShareId": {},
                "FileShareStatus": {},
                "GatewayARN": {},
                "KMSEncrypted": {
                  "type": "boolean"
                },
                "KMSKey": {},
                "Path": {},
                "Role": {},
                "LocationARN": {},
                "DefaultStorageClass": {},
                "ObjectACL": {},
                "ReadOnly": {
                  "type": "boolean"
                },
                "GuessMIMETypeEnabled": {
                  "type": "boolean"
                },
                "RequesterPays": {
                  "type": "boolean"
                },
                "SMBACLEnabled": {
                  "type": "boolean"
                },
                "AdminUserList": {
                  "shape": "S1p"
                },
                "ValidUserList": {
                  "shape": "S1p"
                },
                "InvalidUserList": {
                  "shape": "S1p"
                },
                "Authentication": {},
                "Tags": {
                  "shape": "S9"
                }
              }
            }
          }
        }
      }
    },
    "DescribeSMBSettings": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DomainName": {},
          "SMBGuestPasswordSet": {
            "type": "boolean"
          },
          "SMBSecurityStrategy": {}
        }
      }
    },
    "DescribeSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {},
          "StartAt": {
            "type": "integer"
          },
          "RecurrenceInHours": {
            "type": "integer"
          },
          "Description": {},
          "Timezone": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "DescribeStorediSCSIVolumes": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARNs"
        ],
        "members": {
          "VolumeARNs": {
            "shape": "S31"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StorediSCSIVolumes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeId": {},
                "VolumeType": {},
                "VolumeStatus": {},
                "VolumeAttachmentStatus": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeProgress": {
                  "type": "double"
                },
                "VolumeDiskId": {},
                "SourceSnapshotId": {},
                "PreservedExistingData": {
                  "type": "boolean"
                },
                "VolumeiSCSIAttributes": {
                  "shape": "S3a"
                },
                "CreatedDate": {
                  "type": "timestamp"
                },
                "VolumeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "TargetName": {}
              }
            }
          }
        }
      }
    },
    "DescribeTapeArchives": {
      "input": {
        "type": "structure",
        "members": {
          "TapeARNs": {
            "shape": "S2a"
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeArchives": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeBarcode": {},
                "TapeCreatedDate": {
                  "type": "timestamp"
                },
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "CompletionTime": {
                  "type": "timestamp"
                },
                "RetrievedTo": {},
                "TapeStatus": {},
                "TapeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "PoolId": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeTapeRecoveryPoints": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "TapeRecoveryPointInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeRecoveryPointTime": {
                  "type": "timestamp"
                },
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "TapeStatus": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeTapes": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "TapeARNs": {
            "shape": "S2a"
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tapes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeBarcode": {},
                "TapeCreatedDate": {
                  "type": "timestamp"
                },
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "TapeStatus": {},
                "VTLDevice": {},
                "Progress": {
                  "type": "double"
                },
                "TapeUsedInBytes": {
                  "type": "long"
                },
                "KMSKey": {},
                "PoolId": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeUploadBuffer": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          },
          "UploadBufferUsedInBytes": {
            "type": "long"
          },
          "UploadBufferAllocatedInBytes": {
            "type": "long"
          }
        }
      }
    },
    "DescribeVTLDevices": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "VTLDeviceARNs": {
            "type": "list",
            "member": {}
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "VTLDevices": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VTLDeviceARN": {},
                "VTLDeviceType": {},
                "VTLDeviceVendor": {},
                "VTLDeviceProductIdentifier": {},
                "DeviceiSCSIAttributes": {
                  "type": "structure",
                  "members": {
                    "TargetARN": {},
                    "NetworkInterfaceId": {},
                    "NetworkInterfacePort": {
                      "type": "integer"
                    },
                    "ChapEnabled": {
                      "type": "boolean"
                    }
                  }
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeWorkingStorage": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "DiskIds": {
            "shape": "Sg"
          },
          "WorkingStorageUsedInBytes": {
            "type": "long"
          },
          "WorkingStorageAllocatedInBytes": {
            "type": "long"
          }
        }
      }
    },
    "DetachVolume": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {},
          "ForceDetach": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "DisableGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "JoinDomain": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "DomainName",
          "UserName",
          "Password"
        ],
        "members": {
          "GatewayARN": {},
          "DomainName": {},
          "OrganizationalUnit": {},
          "DomainControllers": {
            "type": "list",
            "member": {}
          },
          "UserName": {},
          "Password": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "ListFileShares": {
      "input": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Limit": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "NextMarker": {},
          "FileShareInfoList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FileShareType": {},
                "FileShareARN": {},
                "FileShareId": {},
                "FileShareStatus": {},
                "GatewayARN": {}
              }
            }
          }
        }
      }
    },
    "ListGateways": {
      "input": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Gateways": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GatewayId": {},
                "GatewayARN": {},
                "GatewayType": {},
                "GatewayOperationalState": {},
                "GatewayName": {},
                "Ec2InstanceId": {},
                "Ec2InstanceRegion": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListLocalDisks": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Disks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DiskId": {},
                "DiskPath": {},
                "DiskNode": {},
                "DiskStatus": {},
                "DiskSizeInBytes": {
                  "type": "long"
                },
                "DiskAllocationType": {},
                "DiskAllocationResource": {},
                "DiskAttributeList": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceARN": {},
          "Marker": {},
          "Tags": {
            "shape": "S9"
          }
        }
      }
    },
    "ListTapes": {
      "input": {
        "type": "structure",
        "members": {
          "TapeARNs": {
            "shape": "S2a"
          },
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TapeARN": {},
                "TapeBarcode": {},
                "TapeSizeInBytes": {
                  "type": "long"
                },
                "TapeStatus": {},
                "GatewayARN": {},
                "PoolId": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ListVolumeInitiators": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN"
        ],
        "members": {
          "VolumeARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Initiators": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListVolumeRecoveryPoints": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "VolumeRecoveryPointInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeUsageInBytes": {
                  "type": "long"
                },
                "VolumeRecoveryPointTime": {}
              }
            }
          }
        }
      }
    },
    "ListVolumes": {
      "input": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Marker": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "Marker": {},
          "VolumeInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "VolumeARN": {},
                "VolumeId": {},
                "GatewayARN": {},
                "GatewayId": {},
                "VolumeType": {},
                "VolumeSizeInBytes": {
                  "type": "long"
                },
                "VolumeAttachmentStatus": {}
              }
            }
          }
        }
      }
    },
    "NotifyWhenUploaded": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {},
          "NotificationId": {}
        }
      }
    },
    "RefreshCache": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "FolderList": {
            "type": "list",
            "member": {}
          },
          "Recursive": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {},
          "NotificationId": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceARN": {}
        }
      }
    },
    "ResetCache": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "RetrieveTapeArchive": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN",
          "GatewayARN"
        ],
        "members": {
          "TapeARN": {},
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "RetrieveTapeRecoveryPoint": {
      "input": {
        "type": "structure",
        "required": [
          "TapeARN",
          "GatewayARN"
        ],
        "members": {
          "TapeARN": {},
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TapeARN": {}
        }
      }
    },
    "SetLocalConsolePassword": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "LocalConsolePassword"
        ],
        "members": {
          "GatewayARN": {},
          "LocalConsolePassword": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "SetSMBGuestPassword": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "Password"
        ],
        "members": {
          "GatewayARN": {},
          "Password": {
            "type": "string",
            "sensitive": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "ShutdownGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "StartGateway": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateBandwidthRateLimit": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "AverageUploadRateLimitInBitsPerSec": {
            "type": "long"
          },
          "AverageDownloadRateLimitInBitsPerSec": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateChapCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "TargetARN",
          "SecretToAuthenticateInitiator",
          "InitiatorName"
        ],
        "members": {
          "TargetARN": {},
          "SecretToAuthenticateInitiator": {
            "shape": "S3j"
          },
          "InitiatorName": {},
          "SecretToAuthenticateTarget": {
            "shape": "S3j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TargetARN": {},
          "InitiatorName": {}
        }
      }
    },
    "UpdateGatewayInformation": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {},
          "GatewayName": {},
          "GatewayTimezone": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {},
          "GatewayName": {}
        }
      }
    },
    "UpdateGatewaySoftwareNow": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN"
        ],
        "members": {
          "GatewayARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateMaintenanceStartTime": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "HourOfDay",
          "MinuteOfHour"
        ],
        "members": {
          "GatewayARN": {},
          "HourOfDay": {
            "type": "integer"
          },
          "MinuteOfHour": {
            "type": "integer"
          },
          "DayOfWeek": {
            "type": "integer"
          },
          "DayOfMonth": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateNFSFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "NFSFileShareDefaults": {
            "shape": "S1c"
          },
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ClientList": {
            "shape": "S1j"
          },
          "Squash": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "UpdateSMBFileShare": {
      "input": {
        "type": "structure",
        "required": [
          "FileShareARN"
        ],
        "members": {
          "FileShareARN": {},
          "KMSEncrypted": {
            "type": "boolean"
          },
          "KMSKey": {},
          "DefaultStorageClass": {},
          "ObjectACL": {},
          "ReadOnly": {
            "type": "boolean"
          },
          "GuessMIMETypeEnabled": {
            "type": "boolean"
          },
          "RequesterPays": {
            "type": "boolean"
          },
          "SMBACLEnabled": {
            "type": "boolean"
          },
          "AdminUserList": {
            "shape": "S1p"
          },
          "ValidUserList": {
            "shape": "S1p"
          },
          "InvalidUserList": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileShareARN": {}
        }
      }
    },
    "UpdateSMBSecurityStrategy": {
      "input": {
        "type": "structure",
        "required": [
          "GatewayARN",
          "SMBSecurityStrategy"
        ],
        "members": {
          "GatewayARN": {},
          "SMBSecurityStrategy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GatewayARN": {}
        }
      }
    },
    "UpdateSnapshotSchedule": {
      "input": {
        "type": "structure",
        "required": [
          "VolumeARN",
          "StartAt",
          "RecurrenceInHours"
        ],
        "members": {
          "VolumeARN": {},
          "StartAt": {
            "type": "integer"
          },
          "RecurrenceInHours": {
            "type": "integer"
          },
          "Description": {},
          "Tags": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VolumeARN": {}
        }
      }
    },
    "UpdateVTLDeviceType": {
      "input": {
        "type": "structure",
        "required": [
          "VTLDeviceARN",
          "DeviceType"
        ],
        "members": {
          "VTLDeviceARN": {},
          "DeviceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VTLDeviceARN": {}
        }
      }
    }
  },
  "shapes": {
    "S9": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sg": {
      "type": "list",
      "member": {}
    },
    "S1c": {
      "type": "structure",
      "members": {
        "FileMode": {},
        "DirectoryMode": {},
        "GroupId": {
          "type": "long"
        },
        "OwnerId": {
          "type": "long"
        }
      }
    },
    "S1j": {
      "type": "list",
      "member": {}
    },
    "S1p": {
      "type": "list",
      "member": {}
    },
    "S2a": {
      "type": "list",
      "member": {}
    },
    "S31": {
      "type": "list",
      "member": {}
    },
    "S3a": {
      "type": "structure",
      "members": {
        "TargetARN": {},
        "NetworkInterfaceId": {},
        "NetworkInterfacePort": {
          "type": "integer"
        },
        "LunNumber": {
          "type": "integer"
        },
        "ChapEnabled": {
          "type": "boolean"
        }
      }
    },
    "S3j": {
      "type": "string",
      "sensitive": true
    },
    "S41": {
      "type": "list",
      "member": {}
    }
  }
}