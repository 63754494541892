{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-01",
    "endpointPrefix": "workmail",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon WorkMail",
    "serviceId": "WorkMail",
    "signatureVersion": "v4",
    "targetPrefix": "WorkMailService",
    "uid": "workmail-2017-10-01"
  },
  "operations": {
    "AssociateDelegateToResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "EntityId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "AssociateMemberToGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId",
          "MemberId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {},
          "MemberId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Alias"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Alias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "CreateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name"
        ],
        "members": {
          "OrganizationId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {}
        }
      },
      "idempotent": true
    },
    "CreateResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name",
          "Type"
        ],
        "members": {
          "OrganizationId": {},
          "Name": {},
          "Type": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {}
        }
      },
      "idempotent": true
    },
    "CreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "Name",
          "DisplayName",
          "Password"
        ],
        "members": {
          "OrganizationId": {},
          "Name": {},
          "DisplayName": {},
          "Password": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {}
        }
      },
      "idempotent": true
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Alias"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Alias": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteMailboxPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "GranteeId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "GranteeId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeregisterFromWorkMail": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DescribeGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupId": {},
          "Name": {},
          "Email": {},
          "State": {},
          "EnabledDate": {
            "type": "timestamp"
          },
          "DisabledDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeOrganization": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationId": {},
          "Alias": {},
          "State": {},
          "DirectoryId": {},
          "DirectoryType": {},
          "DefaultMailDomain": {},
          "CompletedDate": {
            "type": "timestamp"
          },
          "ErrorMessage": {}
        }
      },
      "idempotent": true
    },
    "DescribeResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceId": {},
          "Email": {},
          "Name": {},
          "Type": {},
          "BookingOptions": {
            "shape": "S18"
          },
          "State": {},
          "EnabledDate": {
            "type": "timestamp"
          },
          "DisabledDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DescribeUser": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserId": {},
          "Name": {},
          "Email": {},
          "DisplayName": {},
          "State": {},
          "UserRole": {},
          "EnabledDate": {
            "type": "timestamp"
          },
          "DisabledDate": {
            "type": "timestamp"
          }
        }
      },
      "idempotent": true
    },
    "DisassociateDelegateFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "EntityId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DisassociateMemberFromGroup": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId",
          "MemberId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {},
          "MemberId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetMailboxDetails": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MailboxQuota": {
            "type": "integer"
          },
          "MailboxSize": {
            "type": "double"
          }
        }
      },
      "idempotent": true
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListGroupMembers": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "GroupId"
        ],
        "members": {
          "OrganizationId": {},
          "GroupId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Members": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Type": {},
                "State": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListGroups": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Email": {},
                "Name": {},
                "State": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListMailboxPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "GranteeId",
                "GranteeType",
                "PermissionValues"
              ],
              "members": {
                "GranteeId": {},
                "GranteeType": {},
                "PermissionValues": {
                  "shape": "S23"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListOrganizations": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OrganizationId": {},
                "Alias": {},
                "ErrorMessage": {},
                "State": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListResourceDelegates": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Delegates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Id",
                "Type"
              ],
              "members": {
                "Id": {},
                "Type": {}
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListResources": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Resources": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Email": {},
                "Name": {},
                "Type": {},
                "State": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId"
        ],
        "members": {
          "OrganizationId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Email": {},
                "Name": {},
                "DisplayName": {},
                "State": {},
                "UserRole": {},
                "EnabledDate": {
                  "type": "timestamp"
                },
                "DisabledDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      },
      "idempotent": true
    },
    "PutMailboxPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "GranteeId",
          "PermissionValues"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "GranteeId": {},
          "PermissionValues": {
            "shape": "S23"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "RegisterToWorkMail": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Email"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Email": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "ResetPassword": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId",
          "Password"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "Password": {
            "shape": "Sl"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateMailboxQuota": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "UserId",
          "MailboxQuota"
        ],
        "members": {
          "OrganizationId": {},
          "UserId": {},
          "MailboxQuota": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdatePrimaryEmailAddress": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "EntityId",
          "Email"
        ],
        "members": {
          "OrganizationId": {},
          "EntityId": {},
          "Email": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateResource": {
      "input": {
        "type": "structure",
        "required": [
          "OrganizationId",
          "ResourceId"
        ],
        "members": {
          "OrganizationId": {},
          "ResourceId": {},
          "Name": {},
          "BookingOptions": {
            "shape": "S18"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    }
  },
  "shapes": {
    "Sl": {
      "type": "string",
      "sensitive": true
    },
    "S18": {
      "type": "structure",
      "members": {
        "AutoAcceptRequests": {
          "type": "boolean"
        },
        "AutoDeclineRecurringRequests": {
          "type": "boolean"
        },
        "AutoDeclineConflictingRequests": {
          "type": "boolean"
        }
      }
    },
    "S23": {
      "type": "list",
      "member": {}
    }
  }
}