{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-10-26",
    "endpointPrefix": "securityhub",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceFullName": "AWS SecurityHub",
    "serviceId": "SecurityHub",
    "signatureVersion": "v4",
    "signingName": "securityhub",
    "uid": "securityhub-2018-10-26"
  },
  "operations": {
    "AcceptInvitation": {
      "http": {
        "requestUri": "/master"
      },
      "input": {
        "type": "structure",
        "required": [
          "MasterId",
          "InvitationId"
        ],
        "members": {
          "MasterId": {},
          "InvitationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "BatchDisableStandards": {
      "http": {
        "requestUri": "/standards/deregister"
      },
      "input": {
        "type": "structure",
        "required": [
          "StandardsSubscriptionArns"
        ],
        "members": {
          "StandardsSubscriptionArns": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StandardsSubscriptions": {
            "shape": "S7"
          }
        }
      }
    },
    "BatchEnableStandards": {
      "http": {
        "requestUri": "/standards/register"
      },
      "input": {
        "type": "structure",
        "required": [
          "StandardsSubscriptionRequests"
        ],
        "members": {
          "StandardsSubscriptionRequests": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "StandardsArn"
              ],
              "members": {
                "StandardsArn": {},
                "StandardsInput": {
                  "shape": "S9"
                }
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StandardsSubscriptions": {
            "shape": "S7"
          }
        }
      }
    },
    "BatchImportFindings": {
      "http": {
        "requestUri": "/findings/import"
      },
      "input": {
        "type": "structure",
        "required": [
          "Findings"
        ],
        "members": {
          "Findings": {
            "shape": "Sg"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FailedCount",
          "SuccessCount"
        ],
        "members": {
          "FailedCount": {
            "type": "integer"
          },
          "SuccessCount": {
            "type": "integer"
          },
          "FailedFindings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Id",
                "ErrorCode",
                "ErrorMessage"
              ],
              "members": {
                "Id": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "CreateActionTarget": {
      "http": {
        "requestUri": "/actionTargets"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Description",
          "Id"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {}
        }
      }
    },
    "CreateInsight": {
      "http": {
        "requestUri": "/insights"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Filters",
          "GroupByAttribute"
        ],
        "members": {
          "Name": {},
          "Filters": {
            "shape": "S1o"
          },
          "GroupByAttribute": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {}
        }
      }
    },
    "CreateMembers": {
      "http": {
        "requestUri": "/members"
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "Email": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "S2b"
          }
        }
      }
    },
    "DeclineInvitations": {
      "http": {
        "requestUri": "/invitations/decline"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "S2b"
          }
        }
      }
    },
    "DeleteActionTarget": {
      "http": {
        "method": "DELETE",
        "requestUri": "/actionTargets/{ActionTargetArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {
            "location": "uri",
            "locationName": "ActionTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {}
        }
      }
    },
    "DeleteInsight": {
      "http": {
        "method": "DELETE",
        "requestUri": "/insights/{InsightArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {
            "location": "uri",
            "locationName": "InsightArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {}
        }
      }
    },
    "DeleteInvitations": {
      "http": {
        "requestUri": "/invitations/delete"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "S2b"
          }
        }
      }
    },
    "DeleteMembers": {
      "http": {
        "requestUri": "/members/delete"
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "S2b"
          }
        }
      }
    },
    "DescribeActionTargets": {
      "http": {
        "requestUri": "/actionTargets/get"
      },
      "input": {
        "type": "structure",
        "members": {
          "ActionTargetArns": {
            "shape": "S2p"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ActionTargets"
        ],
        "members": {
          "ActionTargets": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ActionTargetArn",
                "Name",
                "Description"
              ],
              "members": {
                "ActionTargetArn": {},
                "Name": {},
                "Description": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeHub": {
      "http": {
        "method": "GET",
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {
          "HubArn": {
            "location": "querystring",
            "locationName": "HubArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "HubArn": {},
          "SubscribedAt": {}
        }
      }
    },
    "DescribeProducts": {
      "http": {
        "method": "GET",
        "requestUri": "/products"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Products"
        ],
        "members": {
          "Products": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProductArn"
              ],
              "members": {
                "ProductArn": {},
                "ProductName": {},
                "CompanyName": {},
                "Description": {},
                "Categories": {
                  "type": "list",
                  "member": {}
                },
                "MarketplaceUrl": {},
                "ActivationUrl": {},
                "ProductSubscriptionResourcePolicy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DisableImportFindingsForProduct": {
      "http": {
        "method": "DELETE",
        "requestUri": "/productSubscriptions/{ProductSubscriptionArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProductSubscriptionArn"
        ],
        "members": {
          "ProductSubscriptionArn": {
            "location": "uri",
            "locationName": "ProductSubscriptionArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableSecurityHub": {
      "http": {
        "method": "DELETE",
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateFromMasterAccount": {
      "http": {
        "requestUri": "/master/disassociate"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateMembers": {
      "http": {
        "requestUri": "/members/disassociate"
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableImportFindingsForProduct": {
      "http": {
        "requestUri": "/productSubscriptions"
      },
      "input": {
        "type": "structure",
        "required": [
          "ProductArn"
        ],
        "members": {
          "ProductArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductSubscriptionArn": {}
        }
      }
    },
    "EnableSecurityHub": {
      "http": {
        "requestUri": "/accounts"
      },
      "input": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetEnabledStandards": {
      "http": {
        "requestUri": "/standards/get"
      },
      "input": {
        "type": "structure",
        "members": {
          "StandardsSubscriptionArns": {
            "shape": "S5"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StandardsSubscriptions": {
            "shape": "S7"
          },
          "NextToken": {}
        }
      }
    },
    "GetFindings": {
      "http": {
        "requestUri": "/findings"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S1o"
          },
          "SortCriteria": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Field": {},
                "SortOrder": {}
              }
            }
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Findings"
        ],
        "members": {
          "Findings": {
            "shape": "Sg"
          },
          "NextToken": {}
        }
      }
    },
    "GetInsightResults": {
      "http": {
        "method": "GET",
        "requestUri": "/insights/results/{InsightArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {
            "location": "uri",
            "locationName": "InsightArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "InsightResults"
        ],
        "members": {
          "InsightResults": {
            "type": "structure",
            "required": [
              "InsightArn",
              "GroupByAttribute",
              "ResultValues"
            ],
            "members": {
              "InsightArn": {},
              "GroupByAttribute": {},
              "ResultValues": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "GroupByAttributeValue",
                    "Count"
                  ],
                  "members": {
                    "GroupByAttributeValue": {},
                    "Count": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetInsights": {
      "http": {
        "requestUri": "/insights/get"
      },
      "input": {
        "type": "structure",
        "members": {
          "InsightArns": {
            "shape": "S2p"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Insights"
        ],
        "members": {
          "Insights": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "InsightArn",
                "Name",
                "Filters",
                "GroupByAttribute"
              ],
              "members": {
                "InsightArn": {},
                "Name": {},
                "Filters": {
                  "shape": "S1o"
                },
                "GroupByAttribute": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetInvitationsCount": {
      "http": {
        "method": "GET",
        "requestUri": "/invitations/count"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "InvitationsCount": {
            "type": "integer"
          }
        }
      }
    },
    "GetMasterAccount": {
      "http": {
        "method": "GET",
        "requestUri": "/master"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "Master": {
            "shape": "S41"
          }
        }
      }
    },
    "GetMembers": {
      "http": {
        "requestUri": "/members/get"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountIds"
        ],
        "members": {
          "AccountIds": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Members": {
            "shape": "S45"
          },
          "UnprocessedAccounts": {
            "shape": "S2b"
          }
        }
      }
    },
    "InviteMembers": {
      "http": {
        "requestUri": "/members/invite"
      },
      "input": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "shape": "S2e"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UnprocessedAccounts": {
            "shape": "S2b"
          }
        }
      }
    },
    "ListEnabledProductsForImport": {
      "http": {
        "method": "GET",
        "requestUri": "/productSubscriptions"
      },
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductSubscriptions": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListInvitations": {
      "http": {
        "method": "GET",
        "requestUri": "/invitations"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Invitations": {
            "type": "list",
            "member": {
              "shape": "S41"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMembers": {
      "http": {
        "method": "GET",
        "requestUri": "/members"
      },
      "input": {
        "type": "structure",
        "members": {
          "OnlyAssociated": {
            "location": "querystring",
            "locationName": "OnlyAssociated",
            "type": "boolean"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "MaxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "NextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Members": {
            "shape": "S45"
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S3d"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "Tags": {
            "shape": "S3d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{ResourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "ResourceArn"
          },
          "TagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateActionTarget": {
      "http": {
        "method": "PATCH",
        "requestUri": "/actionTargets/{ActionTargetArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ActionTargetArn"
        ],
        "members": {
          "ActionTargetArn": {
            "location": "uri",
            "locationName": "ActionTargetArn"
          },
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateFindings": {
      "http": {
        "method": "PATCH",
        "requestUri": "/findings"
      },
      "input": {
        "type": "structure",
        "required": [
          "Filters"
        ],
        "members": {
          "Filters": {
            "shape": "S1o"
          },
          "Note": {
            "type": "structure",
            "required": [
              "Text",
              "UpdatedBy"
            ],
            "members": {
              "Text": {},
              "UpdatedBy": {}
            }
          },
          "RecordState": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateInsight": {
      "http": {
        "method": "PATCH",
        "requestUri": "/insights/{InsightArn+}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InsightArn"
        ],
        "members": {
          "InsightArn": {
            "location": "uri",
            "locationName": "InsightArn"
          },
          "Name": {},
          "Filters": {
            "shape": "S1o"
          },
          "GroupByAttribute": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "StandardsSubscriptionArn",
          "StandardsArn",
          "StandardsInput",
          "StandardsStatus"
        ],
        "members": {
          "StandardsSubscriptionArn": {},
          "StandardsArn": {},
          "StandardsInput": {
            "shape": "S9"
          },
          "StandardsStatus": {}
        }
      }
    },
    "S9": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sg": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "SchemaVersion",
          "Id",
          "ProductArn",
          "GeneratorId",
          "AwsAccountId",
          "Types",
          "CreatedAt",
          "UpdatedAt",
          "Severity",
          "Title",
          "Description",
          "Resources"
        ],
        "members": {
          "SchemaVersion": {},
          "Id": {},
          "ProductArn": {},
          "GeneratorId": {},
          "AwsAccountId": {},
          "Types": {
            "type": "list",
            "member": {}
          },
          "FirstObservedAt": {},
          "LastObservedAt": {},
          "CreatedAt": {},
          "UpdatedAt": {},
          "Severity": {
            "type": "structure",
            "required": [
              "Normalized"
            ],
            "members": {
              "Product": {
                "type": "double"
              },
              "Normalized": {
                "type": "integer"
              }
            }
          },
          "Confidence": {
            "type": "integer"
          },
          "Criticality": {
            "type": "integer"
          },
          "Title": {},
          "Description": {},
          "Remediation": {
            "type": "structure",
            "members": {
              "Recommendation": {
                "type": "structure",
                "members": {
                  "Text": {},
                  "Url": {}
                }
              }
            }
          },
          "SourceUrl": {},
          "ProductFields": {
            "shape": "So"
          },
          "UserDefinedFields": {
            "shape": "So"
          },
          "Malware": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name"
              ],
              "members": {
                "Name": {},
                "Type": {},
                "Path": {},
                "State": {}
              }
            }
          },
          "Network": {
            "type": "structure",
            "members": {
              "Direction": {},
              "Protocol": {},
              "SourceIpV4": {},
              "SourceIpV6": {},
              "SourcePort": {
                "type": "integer"
              },
              "SourceDomain": {},
              "SourceMac": {},
              "DestinationIpV4": {},
              "DestinationIpV6": {},
              "DestinationPort": {
                "type": "integer"
              },
              "DestinationDomain": {}
            }
          },
          "Process": {
            "type": "structure",
            "members": {
              "Name": {},
              "Path": {},
              "Pid": {
                "type": "integer"
              },
              "ParentPid": {
                "type": "integer"
              },
              "LaunchedAt": {},
              "TerminatedAt": {}
            }
          },
          "ThreatIntelIndicators": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Value": {},
                "Category": {},
                "LastObservedAt": {},
                "Source": {},
                "SourceUrl": {}
              }
            }
          },
          "Resources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Type",
                "Id"
              ],
              "members": {
                "Type": {},
                "Id": {},
                "Partition": {},
                "Region": {},
                "Tags": {
                  "shape": "So"
                },
                "Details": {
                  "type": "structure",
                  "members": {
                    "AwsEc2Instance": {
                      "type": "structure",
                      "members": {
                        "Type": {},
                        "ImageId": {},
                        "IpV4Addresses": {
                          "shape": "S15"
                        },
                        "IpV6Addresses": {
                          "shape": "S15"
                        },
                        "KeyName": {},
                        "IamInstanceProfileArn": {},
                        "VpcId": {},
                        "SubnetId": {},
                        "LaunchedAt": {}
                      }
                    },
                    "AwsS3Bucket": {
                      "type": "structure",
                      "members": {
                        "OwnerId": {},
                        "OwnerName": {}
                      }
                    },
                    "AwsIamAccessKey": {
                      "type": "structure",
                      "members": {
                        "UserName": {},
                        "Status": {},
                        "CreatedAt": {}
                      }
                    },
                    "Container": {
                      "type": "structure",
                      "members": {
                        "Name": {},
                        "ImageId": {},
                        "ImageName": {},
                        "LaunchedAt": {}
                      }
                    },
                    "Other": {
                      "shape": "So"
                    }
                  }
                }
              }
            }
          },
          "Compliance": {
            "type": "structure",
            "members": {
              "Status": {}
            }
          },
          "VerificationState": {},
          "WorkflowState": {},
          "RecordState": {},
          "RelatedFindings": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ProductArn",
                "Id"
              ],
              "members": {
                "ProductArn": {},
                "Id": {}
              }
            }
          },
          "Note": {
            "type": "structure",
            "required": [
              "Text",
              "UpdatedBy",
              "UpdatedAt"
            ],
            "members": {
              "Text": {},
              "UpdatedBy": {},
              "UpdatedAt": {}
            }
          }
        }
      }
    },
    "So": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S15": {
      "type": "list",
      "member": {}
    },
    "S1o": {
      "type": "structure",
      "members": {
        "ProductArn": {
          "shape": "S1p"
        },
        "AwsAccountId": {
          "shape": "S1p"
        },
        "Id": {
          "shape": "S1p"
        },
        "GeneratorId": {
          "shape": "S1p"
        },
        "Type": {
          "shape": "S1p"
        },
        "FirstObservedAt": {
          "shape": "S1s"
        },
        "LastObservedAt": {
          "shape": "S1s"
        },
        "CreatedAt": {
          "shape": "S1s"
        },
        "UpdatedAt": {
          "shape": "S1s"
        },
        "SeverityProduct": {
          "shape": "S1w"
        },
        "SeverityNormalized": {
          "shape": "S1w"
        },
        "SeverityLabel": {
          "shape": "S1p"
        },
        "Confidence": {
          "shape": "S1w"
        },
        "Criticality": {
          "shape": "S1w"
        },
        "Title": {
          "shape": "S1p"
        },
        "Description": {
          "shape": "S1p"
        },
        "RecommendationText": {
          "shape": "S1p"
        },
        "SourceUrl": {
          "shape": "S1p"
        },
        "ProductFields": {
          "shape": "S1y"
        },
        "ProductName": {
          "shape": "S1p"
        },
        "CompanyName": {
          "shape": "S1p"
        },
        "UserDefinedFields": {
          "shape": "S1y"
        },
        "MalwareName": {
          "shape": "S1p"
        },
        "MalwareType": {
          "shape": "S1p"
        },
        "MalwarePath": {
          "shape": "S1p"
        },
        "MalwareState": {
          "shape": "S1p"
        },
        "NetworkDirection": {
          "shape": "S1p"
        },
        "NetworkProtocol": {
          "shape": "S1p"
        },
        "NetworkSourceIpV4": {
          "shape": "S21"
        },
        "NetworkSourceIpV6": {
          "shape": "S21"
        },
        "NetworkSourcePort": {
          "shape": "S1w"
        },
        "NetworkSourceDomain": {
          "shape": "S1p"
        },
        "NetworkSourceMac": {
          "shape": "S1p"
        },
        "NetworkDestinationIpV4": {
          "shape": "S21"
        },
        "NetworkDestinationIpV6": {
          "shape": "S21"
        },
        "NetworkDestinationPort": {
          "shape": "S1w"
        },
        "NetworkDestinationDomain": {
          "shape": "S1p"
        },
        "ProcessName": {
          "shape": "S1p"
        },
        "ProcessPath": {
          "shape": "S1p"
        },
        "ProcessPid": {
          "shape": "S1w"
        },
        "ProcessParentPid": {
          "shape": "S1w"
        },
        "ProcessLaunchedAt": {
          "shape": "S1s"
        },
        "ProcessTerminatedAt": {
          "shape": "S1s"
        },
        "ThreatIntelIndicatorType": {
          "shape": "S1p"
        },
        "ThreatIntelIndicatorValue": {
          "shape": "S1p"
        },
        "ThreatIntelIndicatorCategory": {
          "shape": "S1p"
        },
        "ThreatIntelIndicatorLastObservedAt": {
          "shape": "S1s"
        },
        "ThreatIntelIndicatorSource": {
          "shape": "S1p"
        },
        "ThreatIntelIndicatorSourceUrl": {
          "shape": "S1p"
        },
        "ResourceType": {
          "shape": "S1p"
        },
        "ResourceId": {
          "shape": "S1p"
        },
        "ResourcePartition": {
          "shape": "S1p"
        },
        "ResourceRegion": {
          "shape": "S1p"
        },
        "ResourceTags": {
          "shape": "S1y"
        },
        "ResourceAwsEc2InstanceType": {
          "shape": "S1p"
        },
        "ResourceAwsEc2InstanceImageId": {
          "shape": "S1p"
        },
        "ResourceAwsEc2InstanceIpV4Addresses": {
          "shape": "S21"
        },
        "ResourceAwsEc2InstanceIpV6Addresses": {
          "shape": "S21"
        },
        "ResourceAwsEc2InstanceKeyName": {
          "shape": "S1p"
        },
        "ResourceAwsEc2InstanceIamInstanceProfileArn": {
          "shape": "S1p"
        },
        "ResourceAwsEc2InstanceVpcId": {
          "shape": "S1p"
        },
        "ResourceAwsEc2InstanceSubnetId": {
          "shape": "S1p"
        },
        "ResourceAwsEc2InstanceLaunchedAt": {
          "shape": "S1s"
        },
        "ResourceAwsS3BucketOwnerId": {
          "shape": "S1p"
        },
        "ResourceAwsS3BucketOwnerName": {
          "shape": "S1p"
        },
        "ResourceAwsIamAccessKeyUserName": {
          "shape": "S1p"
        },
        "ResourceAwsIamAccessKeyStatus": {
          "shape": "S1p"
        },
        "ResourceAwsIamAccessKeyCreatedAt": {
          "shape": "S1s"
        },
        "ResourceContainerName": {
          "shape": "S1p"
        },
        "ResourceContainerImageId": {
          "shape": "S1p"
        },
        "ResourceContainerImageName": {
          "shape": "S1p"
        },
        "ResourceContainerLaunchedAt": {
          "shape": "S1s"
        },
        "ResourceDetailsOther": {
          "shape": "S1y"
        },
        "ComplianceStatus": {
          "shape": "S1p"
        },
        "VerificationState": {
          "shape": "S1p"
        },
        "WorkflowState": {
          "shape": "S1p"
        },
        "RecordState": {
          "shape": "S1p"
        },
        "RelatedFindingsProductArn": {
          "shape": "S1p"
        },
        "RelatedFindingsId": {
          "shape": "S1p"
        },
        "NoteText": {
          "shape": "S1p"
        },
        "NoteUpdatedAt": {
          "shape": "S1s"
        },
        "NoteUpdatedBy": {
          "shape": "S1p"
        },
        "Keyword": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Value": {}
            }
          }
        }
      }
    },
    "S1p": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Value": {},
          "Comparison": {}
        }
      }
    },
    "S1s": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Start": {},
          "End": {},
          "DateRange": {
            "type": "structure",
            "members": {
              "Value": {
                "type": "integer"
              },
              "Unit": {}
            }
          }
        }
      }
    },
    "S1w": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Gte": {
            "type": "double"
          },
          "Lte": {
            "type": "double"
          },
          "Eq": {
            "type": "double"
          }
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "Comparison": {}
        }
      }
    },
    "S21": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Cidr": {}
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AccountId": {},
          "ProcessingResult": {}
        }
      }
    },
    "S2e": {
      "type": "list",
      "member": {}
    },
    "S2p": {
      "type": "list",
      "member": {}
    },
    "S3d": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S41": {
      "type": "structure",
      "members": {
        "AccountId": {},
        "InvitationId": {},
        "InvitedAt": {
          "shape": "S42"
        },
        "MemberStatus": {}
      }
    },
    "S42": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    },
    "S45": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AccountId": {},
          "Email": {},
          "MasterId": {},
          "MemberStatus": {},
          "InvitedAt": {
            "shape": "S42"
          },
          "UpdatedAt": {
            "shape": "S42"
          }
        }
      }
    }
  }
}