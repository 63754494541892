"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useQueryParams = void 0;
const useQueryParams = () => {
    const urlParams = new URLSearchParams(window.location.search);
    return Array.from(new URLSearchParams(window.location.search).entries()).reduce((prev, [key]) => {
        let keyValue = urlParams.get(key);
        if (key.endsWith('[]')) {
            keyValue = urlParams.getAll(key);
        }
        return {
            ...prev,
            [`${key.replace('[]', '')}`]: keyValue,
        };
    }, {});
};
exports.useQueryParams = useQueryParams;
