import React, { Suspense, lazy, useEffect } from 'react';
import { Container } from '@mui/material';
import { BasicTheme } from '@mineko/mineko-ui';
import { SentryInit } from '@mineko/ui-core';
import Header from '../Header';
import { getTheme } from '../../theme';
import { css } from '@emotion/react';
import { Route, BrowserRouter as Router, Routes, Navigate, useNavigate, useMatch } from 'react-router-dom';
import { SnackbarProvider } from '../../context/snackbar';
import { useConfig, ConfigReturnType } from '../../hooks';
import { configure } from '../../Auth';
import { useTranslation } from 'react-i18next';
import { TagManager } from '@mineko/react-gtm-promisified';

import { Loader } from '../UI/Loader';
import { Page } from './Page';
import { useQueryParams } from '@mineko/react-toolkit';

const Login = lazy(() => import('../Login'));
const Register = lazy(() => import('../Register'));
const Verify = lazy(() => import('../Verify'));
const ForgotPassword = lazy(() => import('../ForgotPassword'));

// in order to avoid old is24 users with 3rd party blocking being in an infinite error loop
// we emit this event to make is24 redirect the parent window
window.parent.postMessage(
  {
    name: 'do_redirect',
    value: location.href,
  },
  '*',
);
const theme = getTheme();

const RedirectToDefaultRoute: React.FC<{ config: ConfigReturnType }> = ({ config }) => {
  const navigate = useNavigate();
  const { requestedUri } = useQueryParams<{ requestedUri: string }>();
  const isLogin = useMatch('/');
  useEffect(() => {
    const shouldRedirect = config.defaultRegistrationRoutes.some((route) => requestedUri?.includes(route));
    if (isLogin && shouldRedirect) {
      navigate('/register');
    }
  }, []);
  return null;
};

const containerStyles = css`
  padding-top: 32px;
  padding-bottom: 32px;
`;

export const Entrypoint: React.FC = () => {
  const config = useConfig();
  const { t } = useTranslation();

  useEffect(() => {
    if (config) {
      console.info(`Config loaded: ${config.APP_ENV}`);
      config.amplifyConfig &&
        configure({
          amplifyConfig: config.amplifyConfig,
        });
      config.SENTRY_DSN &&
        SentryInit({
          dsn: config.SENTRY_DSN,
          release: (window as Record<string, any>)['VERSION'],
          environment: config.APP_ENV,
        });
      config.gtm &&
        TagManager.init({
          containerId: config.gtm.REACT_APP_GTM_ID,
          preview: config.gtm.REACT_APP_GTM_PREVIEW,
          auth: config.gtm.REACT_APP_GTM_AUTH,
        });
    }
  }, [config]);

  return (
    <BasicTheme theme={theme}>
      <SnackbarProvider>
        {config ? (
          <Router basename={process.env.PUBLIC_URL}>
            <Header />
            <Container maxWidth="sm" css={containerStyles}>
              <Suspense fallback={<Loader />}>
                <Routes>
                  <Route
                    path="/"
                    element={<Page providers={config.identityProviders} title={t('Login.title')} Component={Login} />}
                  />

                  {config.isRegistrationEnabled && (
                    <Route
                      path="/register"
                      element={
                        <Page providers={config.identityProviders} title={t('Register.title')} Component={Register} />
                      }
                    />
                  )}
                  <Route path="/forgot-password/*" element={<ForgotPassword />} />
                  <Route path="/verify" element={<Verify />} />
                  <Route path="*" element={<Navigate to="/" replace />} />
                </Routes>
              </Suspense>
            </Container>
            <RedirectToDefaultRoute config={config} />
          </Router>
        ) : (
          <Loader />
        )}
      </SnackbarProvider>
    </BasicTheme>
  );
};
