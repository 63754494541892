{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-04-01",
    "endpointPrefix": "route53resolver",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Route53Resolver",
    "serviceFullName": "Amazon Route 53 Resolver",
    "serviceId": "Route53Resolver",
    "signatureVersion": "v4",
    "targetPrefix": "Route53Resolver",
    "uid": "route53resolver-2018-04-01"
  },
  "operations": {
    "AssociateResolverEndpointIpAddress": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverEndpointId",
          "IpAddress"
        ],
        "members": {
          "ResolverEndpointId": {},
          "IpAddress": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverEndpoint": {
            "shape": "S7"
          }
        }
      }
    },
    "AssociateResolverRule": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverRuleId",
          "VPCId"
        ],
        "members": {
          "ResolverRuleId": {},
          "Name": {},
          "VPCId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRuleAssociation": {
            "shape": "Sj"
          }
        }
      }
    },
    "CreateResolverEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "CreatorRequestId",
          "SecurityGroupIds",
          "Direction",
          "IpAddresses"
        ],
        "members": {
          "CreatorRequestId": {},
          "Name": {},
          "SecurityGroupIds": {
            "shape": "Sb"
          },
          "Direction": {},
          "IpAddresses": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "SubnetId"
              ],
              "members": {
                "SubnetId": {},
                "Ip": {}
              }
            }
          },
          "Tags": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverEndpoint": {
            "shape": "S7"
          }
        }
      }
    },
    "CreateResolverRule": {
      "input": {
        "type": "structure",
        "required": [
          "CreatorRequestId",
          "RuleType",
          "DomainName"
        ],
        "members": {
          "CreatorRequestId": {},
          "Name": {},
          "RuleType": {},
          "DomainName": {},
          "TargetIps": {
            "shape": "Sw"
          },
          "ResolverEndpointId": {},
          "Tags": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRule": {
            "shape": "S10"
          }
        }
      }
    },
    "DeleteResolverEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverEndpointId"
        ],
        "members": {
          "ResolverEndpointId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverEndpoint": {
            "shape": "S7"
          }
        }
      }
    },
    "DeleteResolverRule": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverRuleId"
        ],
        "members": {
          "ResolverRuleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRule": {
            "shape": "S10"
          }
        }
      }
    },
    "DisassociateResolverEndpointIpAddress": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverEndpointId",
          "IpAddress"
        ],
        "members": {
          "ResolverEndpointId": {},
          "IpAddress": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverEndpoint": {
            "shape": "S7"
          }
        }
      }
    },
    "DisassociateResolverRule": {
      "input": {
        "type": "structure",
        "required": [
          "VPCId",
          "ResolverRuleId"
        ],
        "members": {
          "VPCId": {},
          "ResolverRuleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRuleAssociation": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetResolverEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverEndpointId"
        ],
        "members": {
          "ResolverEndpointId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverEndpoint": {
            "shape": "S7"
          }
        }
      }
    },
    "GetResolverRule": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverRuleId"
        ],
        "members": {
          "ResolverRuleId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRule": {
            "shape": "S10"
          }
        }
      }
    },
    "GetResolverRuleAssociation": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverRuleAssociationId"
        ],
        "members": {
          "ResolverRuleAssociationId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRuleAssociation": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetResolverRulePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRulePolicy": {}
        }
      }
    },
    "ListResolverEndpointIpAddresses": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverEndpointId"
        ],
        "members": {
          "ResolverEndpointId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "IpAddresses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "IpId": {},
                "SubnetId": {},
                "Ip": {},
                "Status": {},
                "StatusMessage": {},
                "CreationTime": {},
                "ModificationTime": {}
              }
            }
          }
        }
      }
    },
    "ListResolverEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ResolverEndpoints": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          }
        }
      }
    },
    "ListResolverRuleAssociations": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ResolverRuleAssociations": {
            "type": "list",
            "member": {
              "shape": "Sj"
            }
          }
        }
      }
    },
    "ListResolverRules": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "ResolverRules": {
            "type": "list",
            "member": {
              "shape": "S10"
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "So"
          },
          "NextToken": {}
        }
      }
    },
    "PutResolverRulePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "ResolverRulePolicy"
        ],
        "members": {
          "Arn": {},
          "ResolverRulePolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReturnValue": {
            "type": "boolean"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "So"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateResolverEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverEndpointId"
        ],
        "members": {
          "ResolverEndpointId": {},
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverEndpoint": {
            "shape": "S7"
          }
        }
      }
    },
    "UpdateResolverRule": {
      "input": {
        "type": "structure",
        "required": [
          "ResolverRuleId",
          "Config"
        ],
        "members": {
          "ResolverRuleId": {},
          "Config": {
            "type": "structure",
            "members": {
              "Name": {},
              "TargetIps": {
                "shape": "Sw"
              },
              "ResolverEndpointId": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResolverRule": {
            "shape": "S10"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "members": {
        "IpId": {},
        "SubnetId": {},
        "Ip": {}
      }
    },
    "S7": {
      "type": "structure",
      "members": {
        "Id": {},
        "CreatorRequestId": {},
        "Arn": {},
        "Name": {},
        "SecurityGroupIds": {
          "shape": "Sb"
        },
        "Direction": {},
        "IpAddressCount": {
          "type": "integer"
        },
        "HostVPCId": {},
        "Status": {},
        "StatusMessage": {},
        "CreationTime": {},
        "ModificationTime": {}
      }
    },
    "Sb": {
      "type": "list",
      "member": {}
    },
    "Sj": {
      "type": "structure",
      "members": {
        "Id": {},
        "ResolverRuleId": {},
        "Name": {},
        "VPCId": {},
        "Status": {},
        "StatusMessage": {}
      }
    },
    "So": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sw": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Ip"
        ],
        "members": {
          "Ip": {},
          "Port": {
            "type": "integer"
          }
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "Id": {},
        "CreatorRequestId": {},
        "Arn": {},
        "DomainName": {},
        "Status": {},
        "StatusMessage": {},
        "RuleType": {},
        "Name": {},
        "TargetIps": {
          "shape": "Sw"
        },
        "ResolverEndpointId": {},
        "OwnerId": {},
        "ShareStatus": {}
      }
    },
    "S1t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    }
  }
}