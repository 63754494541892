{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-12-10",
    "endpointPrefix": "servicecatalog",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Service Catalog",
    "serviceId": "Service Catalog",
    "signatureVersion": "v4",
    "targetPrefix": "AWS242ServiceCatalogService",
    "uid": "servicecatalog-2015-12-10"
  },
  "operations": {
    "AcceptPortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PortfolioShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateBudgetWithResource": {
      "input": {
        "type": "structure",
        "required": [
          "BudgetName",
          "ResourceId"
        ],
        "members": {
          "BudgetName": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociatePrincipalWithPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "PrincipalARN",
          "PrincipalType"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PrincipalARN": {},
          "PrincipalType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateProductWithPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PortfolioId": {},
          "SourcePortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateServiceActionWithProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId",
          "ServiceActionId"
        ],
        "members": {
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "ServiceActionId": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateTagOptionWithResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagOptionId"
        ],
        "members": {
          "ResourceId": {},
          "TagOptionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "BatchAssociateServiceActionWithProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceActionAssociations"
        ],
        "members": {
          "ServiceActionAssociations": {
            "shape": "Sm"
          },
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedServiceActionAssociations": {
            "shape": "Sp"
          }
        }
      }
    },
    "BatchDisassociateServiceActionFromProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceActionAssociations"
        ],
        "members": {
          "ServiceActionAssociations": {
            "shape": "Sm"
          },
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedServiceActionAssociations": {
            "shape": "Sp"
          }
        }
      }
    },
    "CopyProduct": {
      "input": {
        "type": "structure",
        "required": [
          "SourceProductArn",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "SourceProductArn": {},
          "TargetProductId": {},
          "TargetProductName": {},
          "SourceProvisioningArtifactIdentifiers": {
            "type": "list",
            "member": {
              "type": "map",
              "key": {},
              "value": {}
            }
          },
          "CopyOptions": {
            "type": "list",
            "member": {}
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyProductToken": {}
        }
      }
    },
    "CreateConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "ProductId",
          "Parameters",
          "Type",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "ProductId": {},
          "Parameters": {},
          "Type": {},
          "Description": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "S1b"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "CreatePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "DisplayName",
          "ProviderName",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "DisplayName": {},
          "Description": {},
          "ProviderName": {},
          "Tags": {
            "shape": "S1i"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreatePortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "AccountId": {},
          "OrganizationNode": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioShareToken": {}
        }
      }
    },
    "CreateProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Owner",
          "ProductType",
          "ProvisioningArtifactParameters",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "Name": {},
          "Owner": {},
          "Description": {},
          "Distributor": {},
          "SupportDescription": {},
          "SupportEmail": {},
          "SupportUrl": {},
          "ProductType": {},
          "Tags": {
            "shape": "S1i"
          },
          "ProvisioningArtifactParameters": {
            "shape": "S23"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S2c"
          },
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "CreateProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanName",
          "PlanType",
          "ProductId",
          "ProvisionedProductName",
          "ProvisioningArtifactId",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanName": {},
          "PlanType": {},
          "NotificationArns": {
            "shape": "S2n"
          },
          "PathId": {},
          "ProductId": {},
          "ProvisionedProductName": {},
          "ProvisioningArtifactId": {},
          "ProvisioningParameters": {
            "shape": "S2q"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlanName": {},
          "PlanId": {},
          "ProvisionProductId": {},
          "ProvisionedProductName": {},
          "ProvisioningArtifactId": {}
        }
      }
    },
    "CreateProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "Parameters",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "Parameters": {
            "shape": "S23"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Info": {
            "shape": "S26"
          },
          "Status": {}
        }
      }
    },
    "CreateServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "DefinitionType",
          "Definition",
          "IdempotencyToken"
        ],
        "members": {
          "Name": {},
          "DefinitionType": {},
          "Definition": {
            "shape": "S31"
          },
          "Description": {},
          "AcceptLanguage": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionDetail": {
            "shape": "S36"
          }
        }
      }
    },
    "CreateTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetail": {
            "shape": "S3c"
          }
        }
      }
    },
    "DeleteConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "AccountId": {},
          "OrganizationNode": {
            "shape": "S1s"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioShareToken": {}
        }
      }
    },
    "DeleteProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanId": {},
          "IgnoreErrors": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "S1b"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "DescribeCopyProductStatus": {
      "input": {
        "type": "structure",
        "required": [
          "CopyProductToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "CopyProductToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CopyProductStatus": {},
          "TargetProductId": {},
          "StatusDetail": {}
        }
      }
    },
    "DescribePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1q"
          },
          "TagOptions": {
            "shape": "S43"
          },
          "Budgets": {
            "shape": "S44"
          }
        }
      }
    },
    "DescribePortfolioShareStatus": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioShareToken"
        ],
        "members": {
          "PortfolioShareToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioShareToken": {},
          "PortfolioId": {},
          "OrganizationNodeValue": {},
          "Status": {},
          "ShareDetails": {
            "type": "structure",
            "members": {
              "SuccessfulShares": {
                "type": "list",
                "member": {}
              },
              "ShareErrors": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Accounts": {
                      "type": "list",
                      "member": {}
                    },
                    "Message": {},
                    "Error": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummary": {
            "shape": "S2d"
          },
          "ProvisioningArtifacts": {
            "shape": "S4i"
          },
          "Budgets": {
            "shape": "S44"
          }
        }
      }
    },
    "DescribeProductAsAdmin": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S2c"
          },
          "ProvisioningArtifactSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "Description": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "ProvisioningArtifactMetadata": {
                  "shape": "S26"
                }
              }
            }
          },
          "Tags": {
            "shape": "S1q"
          },
          "TagOptions": {
            "shape": "S43"
          },
          "Budgets": {
            "shape": "S44"
          }
        }
      }
    },
    "DescribeProductView": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummary": {
            "shape": "S2d"
          },
          "ProvisioningArtifacts": {
            "shape": "S4i"
          }
        }
      }
    },
    "DescribeProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductDetail": {
            "shape": "S4t"
          },
          "CloudWatchDashboards": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {}
              }
            }
          }
        }
      }
    },
    "DescribeProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductPlanDetails": {
            "type": "structure",
            "members": {
              "CreatedTime": {
                "type": "timestamp"
              },
              "PathId": {},
              "ProductId": {},
              "PlanName": {},
              "PlanId": {},
              "ProvisionProductId": {},
              "ProvisionProductName": {},
              "PlanType": {},
              "ProvisioningArtifactId": {},
              "Status": {},
              "UpdatedTime": {
                "type": "timestamp"
              },
              "NotificationArns": {
                "shape": "S2n"
              },
              "ProvisioningParameters": {
                "shape": "S2q"
              },
              "Tags": {
                "shape": "S1q"
              },
              "StatusMessage": {}
            }
          },
          "ResourceChanges": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Action": {},
                "LogicalResourceId": {},
                "PhysicalResourceId": {},
                "ResourceType": {},
                "Replacement": {},
                "Scope": {
                  "type": "list",
                  "member": {}
                },
                "Details": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Target": {
                        "type": "structure",
                        "members": {
                          "Attribute": {},
                          "Name": {},
                          "RequiresRecreation": {}
                        }
                      },
                      "Evaluation": {},
                      "CausingEntity": {}
                    }
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "DescribeProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisioningArtifactId",
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisioningArtifactId": {},
          "ProductId": {},
          "Verbose": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Info": {
            "shape": "S26"
          },
          "Status": {}
        }
      }
    },
    "DescribeProvisioningParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ParameterKey": {},
                "DefaultValue": {},
                "ParameterType": {},
                "IsNoEcho": {
                  "type": "boolean"
                },
                "Description": {},
                "ParameterConstraints": {
                  "type": "structure",
                  "members": {
                    "AllowedValues": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          },
          "ConstraintSummaries": {
            "shape": "S65"
          },
          "UsageInstructions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Type": {},
                "Value": {}
              }
            }
          },
          "TagOptions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "ProvisioningArtifactPreferences": {
            "type": "structure",
            "members": {
              "StackSetAccounts": {
                "shape": "S6f"
              },
              "StackSetRegions": {
                "shape": "S6g"
              }
            }
          }
        }
      }
    },
    "DescribeRecord": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6k"
          },
          "RecordOutputs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "OutputKey": {},
                "OutputValue": {},
                "Description": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "DescribeServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionDetail": {
            "shape": "S36"
          }
        }
      }
    },
    "DescribeServiceActionExecutionParameters": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId",
          "ServiceActionId"
        ],
        "members": {
          "ProvisionedProductId": {},
          "ServiceActionId": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Type": {},
                "DefaultValues": {
                  "shape": "S77"
                }
              }
            }
          }
        }
      }
    },
    "DescribeTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetail": {
            "shape": "S3c"
          }
        }
      }
    },
    "DisableAWSOrganizationsAccess": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateBudgetFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "BudgetName",
          "ResourceId"
        ],
        "members": {
          "BudgetName": {},
          "ResourceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociatePrincipalFromPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "PrincipalARN"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PrincipalARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateProductFromPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateServiceActionFromProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId",
          "ServiceActionId"
        ],
        "members": {
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "ServiceActionId": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisassociateTagOptionFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId",
          "TagOptionId"
        ],
        "members": {
          "ResourceId": {},
          "TagOptionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "EnableAWSOrganizationsAccess": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ExecuteProvisionedProductPlan": {
      "input": {
        "type": "structure",
        "required": [
          "PlanId",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "PlanId": {},
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6k"
          }
        }
      }
    },
    "ExecuteProvisionedProductServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId",
          "ServiceActionId",
          "ExecuteToken"
        ],
        "members": {
          "ProvisionedProductId": {},
          "ServiceActionId": {},
          "ExecuteToken": {
            "idempotencyToken": true
          },
          "AcceptLanguage": {},
          "Parameters": {
            "type": "map",
            "key": {},
            "value": {
              "shape": "S77"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6k"
          }
        }
      }
    },
    "GetAWSOrganizationsAccessStatus": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccessStatus": {}
        }
      }
    },
    "ListAcceptedPortfolioShares": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          },
          "PortfolioShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S7z"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListBudgetsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ResourceId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Budgets": {
            "shape": "S44"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListConstraintsForPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "ProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetails": {
            "type": "list",
            "member": {
              "shape": "S1b"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListLaunchPaths": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LaunchPathSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ConstraintSummaries": {
                  "shape": "S65"
                },
                "Tags": {
                  "shape": "S1q"
                },
                "Name": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListOrganizationPortfolioAccess": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId",
          "OrganizationNodeType"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "OrganizationNodeType": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrganizationNodes": {
            "type": "list",
            "member": {
              "shape": "S1s"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfolioAccess": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountIds": {
            "type": "list",
            "member": {}
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfolios": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S7z"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPortfoliosForProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetails": {
            "shape": "S7z"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListPrincipalsForPortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Principals": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PrincipalARN": {},
                "PrincipalType": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListProvisionedProductPlans": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "ProvisionProductId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {},
          "AccessLevelFilter": {
            "shape": "S8p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductPlans": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PlanName": {},
                "PlanId": {},
                "ProvisionProductId": {},
                "ProvisionProductName": {},
                "PlanType": {},
                "ProvisioningArtifactId": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListProvisioningArtifacts": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetails": {
            "type": "list",
            "member": {
              "shape": "S2h"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListProvisioningArtifactsForServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceActionId"
        ],
        "members": {
          "ServiceActionId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactViews": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProductViewSummary": {
                  "shape": "S2d"
                },
                "ProvisioningArtifact": {
                  "shape": "S4j"
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListRecordHistory": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S8p"
          },
          "SearchFilter": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetails": {
            "type": "list",
            "member": {
              "shape": "S6k"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListResourcesForTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "TagOptionId"
        ],
        "members": {
          "TagOptionId": {},
          "ResourceType": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "ARN": {},
                "Name": {},
                "Description": {},
                "CreatedTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "PageToken": {}
        }
      }
    },
    "ListServiceActions": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionSummaries": {
            "shape": "S9k"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListServiceActionsForProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionSummaries": {
            "shape": "S9k"
          },
          "NextPageToken": {}
        }
      }
    },
    "ListStackInstancesForProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisionedProductId": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "StackInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Account": {},
                "Region": {},
                "StackInstanceStatus": {}
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "ListTagOptions": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {},
              "Active": {
                "type": "boolean"
              }
            }
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetails": {
            "shape": "S43"
          },
          "PageToken": {}
        }
      }
    },
    "ProvisionProduct": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId",
          "ProvisionedProductName",
          "ProvisionToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {},
          "ProvisionedProductName": {},
          "ProvisioningParameters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Value": {}
              }
            }
          },
          "ProvisioningPreferences": {
            "type": "structure",
            "members": {
              "StackSetAccounts": {
                "shape": "S6f"
              },
              "StackSetRegions": {
                "shape": "S6g"
              },
              "StackSetFailureToleranceCount": {
                "type": "integer"
              },
              "StackSetFailureTolerancePercentage": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyCount": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyPercentage": {
                "type": "integer"
              }
            }
          },
          "Tags": {
            "shape": "S1q"
          },
          "NotificationArns": {
            "shape": "S2n"
          },
          "ProvisionToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6k"
          }
        }
      }
    },
    "RejectPortfolioShare": {
      "input": {
        "type": "structure",
        "required": [
          "PortfolioId"
        ],
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "PortfolioShareType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ScanProvisionedProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S8p"
          },
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProducts": {
            "type": "list",
            "member": {
              "shape": "S4t"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "Filters": {
            "shape": "Saa"
          },
          "PageSize": {
            "type": "integer"
          },
          "SortBy": {},
          "SortOrder": {},
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewSummaries": {
            "type": "list",
            "member": {
              "shape": "S2d"
            }
          },
          "ProductViewAggregations": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "Value": {},
                  "ApproximateCount": {
                    "type": "integer"
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProductsAsAdmin": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "PortfolioId": {},
          "Filters": {
            "shape": "Saa"
          },
          "SortBy": {},
          "SortOrder": {},
          "PageToken": {},
          "PageSize": {
            "type": "integer"
          },
          "ProductSource": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetails": {
            "type": "list",
            "member": {
              "shape": "S2c"
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "SearchProvisionedProducts": {
      "input": {
        "type": "structure",
        "members": {
          "AcceptLanguage": {},
          "AccessLevelFilter": {
            "shape": "S8p"
          },
          "Filters": {
            "type": "map",
            "key": {},
            "value": {
              "type": "list",
              "member": {}
            }
          },
          "SortBy": {},
          "SortOrder": {},
          "PageSize": {
            "type": "integer"
          },
          "PageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProducts": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Arn": {},
                "Type": {},
                "Id": {},
                "Status": {},
                "StatusMessage": {},
                "CreatedTime": {
                  "type": "timestamp"
                },
                "IdempotencyToken": {},
                "LastRecordId": {},
                "Tags": {
                  "shape": "S1q"
                },
                "PhysicalId": {},
                "ProductId": {},
                "ProvisioningArtifactId": {},
                "UserArn": {},
                "UserArnSession": {}
              }
            }
          },
          "TotalResultsCount": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      }
    },
    "TerminateProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "TerminateToken"
        ],
        "members": {
          "ProvisionedProductName": {},
          "ProvisionedProductId": {},
          "TerminateToken": {
            "idempotencyToken": true
          },
          "IgnoreErrors": {
            "type": "boolean"
          },
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6k"
          }
        }
      }
    },
    "UpdateConstraint": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Description": {},
          "Parameters": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ConstraintDetail": {
            "shape": "S1b"
          },
          "ConstraintParameters": {},
          "Status": {}
        }
      }
    },
    "UpdatePortfolio": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "DisplayName": {},
          "Description": {},
          "ProviderName": {},
          "AddTags": {
            "shape": "S1i"
          },
          "RemoveTags": {
            "shape": "Sbb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PortfolioDetail": {
            "shape": "S1n"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "UpdateProduct": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "AcceptLanguage": {},
          "Id": {},
          "Name": {},
          "Owner": {},
          "Description": {},
          "Distributor": {},
          "SupportDescription": {},
          "SupportEmail": {},
          "SupportUrl": {},
          "AddTags": {
            "shape": "S1i"
          },
          "RemoveTags": {
            "shape": "Sbb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProductViewDetail": {
            "shape": "S2c"
          },
          "Tags": {
            "shape": "S1q"
          }
        }
      }
    },
    "UpdateProvisionedProduct": {
      "input": {
        "type": "structure",
        "required": [
          "UpdateToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisionedProductName": {},
          "ProvisionedProductId": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "PathId": {},
          "ProvisioningParameters": {
            "shape": "S2q"
          },
          "ProvisioningPreferences": {
            "type": "structure",
            "members": {
              "StackSetAccounts": {
                "shape": "S6f"
              },
              "StackSetRegions": {
                "shape": "S6g"
              },
              "StackSetFailureToleranceCount": {
                "type": "integer"
              },
              "StackSetFailureTolerancePercentage": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyCount": {
                "type": "integer"
              },
              "StackSetMaxConcurrencyPercentage": {
                "type": "integer"
              },
              "StackSetOperationType": {}
            }
          },
          "Tags": {
            "shape": "S1q"
          },
          "UpdateToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RecordDetail": {
            "shape": "S6k"
          }
        }
      }
    },
    "UpdateProvisionedProductProperties": {
      "input": {
        "type": "structure",
        "required": [
          "ProvisionedProductId",
          "ProvisionedProductProperties",
          "IdempotencyToken"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProvisionedProductId": {},
          "ProvisionedProductProperties": {
            "shape": "Sbk"
          },
          "IdempotencyToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisionedProductId": {},
          "ProvisionedProductProperties": {
            "shape": "Sbk"
          },
          "RecordId": {},
          "Status": {}
        }
      }
    },
    "UpdateProvisioningArtifact": {
      "input": {
        "type": "structure",
        "required": [
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "AcceptLanguage": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "Name": {},
          "Description": {},
          "Active": {
            "type": "boolean"
          },
          "Guidance": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ProvisioningArtifactDetail": {
            "shape": "S2h"
          },
          "Info": {
            "shape": "S26"
          },
          "Status": {}
        }
      }
    },
    "UpdateServiceAction": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "Name": {},
          "Definition": {
            "shape": "S31"
          },
          "Description": {},
          "AcceptLanguage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceActionDetail": {
            "shape": "S36"
          }
        }
      }
    },
    "UpdateTagOption": {
      "input": {
        "type": "structure",
        "required": [
          "Id"
        ],
        "members": {
          "Id": {},
          "Value": {},
          "Active": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagOptionDetail": {
            "shape": "S3c"
          }
        }
      }
    }
  },
  "shapes": {
    "Sm": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ServiceActionId",
          "ProductId",
          "ProvisioningArtifactId"
        ],
        "members": {
          "ServiceActionId": {},
          "ProductId": {},
          "ProvisioningArtifactId": {}
        }
      }
    },
    "Sp": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ServiceActionId": {},
          "ProductId": {},
          "ProvisioningArtifactId": {},
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    },
    "S1b": {
      "type": "structure",
      "members": {
        "ConstraintId": {},
        "Type": {},
        "Description": {},
        "Owner": {}
      }
    },
    "S1i": {
      "type": "list",
      "member": {
        "shape": "S1j"
      }
    },
    "S1j": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S1n": {
      "type": "structure",
      "members": {
        "Id": {},
        "ARN": {},
        "DisplayName": {},
        "Description": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "ProviderName": {}
      }
    },
    "S1q": {
      "type": "list",
      "member": {
        "shape": "S1j"
      }
    },
    "S1s": {
      "type": "structure",
      "members": {
        "Type": {},
        "Value": {}
      }
    },
    "S23": {
      "type": "structure",
      "required": [
        "Info"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Info": {
          "shape": "S26"
        },
        "Type": {},
        "DisableTemplateValidation": {
          "type": "boolean"
        }
      }
    },
    "S26": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2c": {
      "type": "structure",
      "members": {
        "ProductViewSummary": {
          "shape": "S2d"
        },
        "Status": {},
        "ProductARN": {},
        "CreatedTime": {
          "type": "timestamp"
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "Id": {},
        "ProductId": {},
        "Name": {},
        "Owner": {},
        "ShortDescription": {},
        "Type": {},
        "Distributor": {},
        "HasDefaultPath": {
          "type": "boolean"
        },
        "SupportEmail": {},
        "SupportDescription": {},
        "SupportUrl": {}
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "Type": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "Active": {
          "type": "boolean"
        },
        "Guidance": {}
      }
    },
    "S2n": {
      "type": "list",
      "member": {}
    },
    "S2q": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {},
          "UsePreviousValue": {
            "type": "boolean"
          }
        }
      }
    },
    "S31": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S36": {
      "type": "structure",
      "members": {
        "ServiceActionSummary": {
          "shape": "S37"
        },
        "Definition": {
          "shape": "S31"
        }
      }
    },
    "S37": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "DefinitionType": {}
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {},
        "Active": {
          "type": "boolean"
        },
        "Id": {}
      }
    },
    "S43": {
      "type": "list",
      "member": {
        "shape": "S3c"
      }
    },
    "S44": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "BudgetName": {}
        }
      }
    },
    "S4i": {
      "type": "list",
      "member": {
        "shape": "S4j"
      }
    },
    "S4j": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Description": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "Guidance": {}
      }
    },
    "S4t": {
      "type": "structure",
      "members": {
        "Name": {},
        "Arn": {},
        "Type": {},
        "Id": {},
        "Status": {},
        "StatusMessage": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "IdempotencyToken": {},
        "LastRecordId": {},
        "ProductId": {},
        "ProvisioningArtifactId": {}
      }
    },
    "S65": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Description": {}
        }
      }
    },
    "S6f": {
      "type": "list",
      "member": {}
    },
    "S6g": {
      "type": "list",
      "member": {}
    },
    "S6k": {
      "type": "structure",
      "members": {
        "RecordId": {},
        "ProvisionedProductName": {},
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "UpdatedTime": {
          "type": "timestamp"
        },
        "ProvisionedProductType": {},
        "RecordType": {},
        "ProvisionedProductId": {},
        "ProductId": {},
        "ProvisioningArtifactId": {},
        "PathId": {},
        "RecordErrors": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Code": {},
              "Description": {}
            }
          }
        },
        "RecordTags": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        }
      }
    },
    "S77": {
      "type": "list",
      "member": {}
    },
    "S7z": {
      "type": "list",
      "member": {
        "shape": "S1n"
      }
    },
    "S8p": {
      "type": "structure",
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S9k": {
      "type": "list",
      "member": {
        "shape": "S37"
      }
    },
    "Saa": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Sbb": {
      "type": "list",
      "member": {}
    },
    "Sbk": {
      "type": "map",
      "key": {},
      "value": {}
    }
  }
}