{
  "pagination": {
    "DescribeScalableTargets": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScalableTargets"
    },
    "DescribeScalingActivities": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScalingActivities"
    },
    "DescribeScalingPolicies": {
      "input_token": "NextToken",
      "limit_key": "MaxResults",
      "output_token": "NextToken",
      "result_key": "ScalingPolicies"
    }
  }
}