{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-07-27",
    "endpointPrefix": "iotevents",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT Events",
    "serviceId": "IoT Events",
    "signatureVersion": "v4",
    "signingName": "iotevents",
    "uid": "iotevents-2018-07-27"
  },
  "operations": {
    "CreateDetectorModel": {
      "http": {
        "requestUri": "/detector-models"
      },
      "input": {
        "type": "structure",
        "required": [
          "detectorModelName",
          "detectorModelDefinition",
          "roleArn"
        ],
        "members": {
          "detectorModelName": {},
          "detectorModelDefinition": {
            "shape": "S3"
          },
          "detectorModelDescription": {},
          "key": {},
          "roleArn": {},
          "tags": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "detectorModelConfiguration": {
            "shape": "S1a"
          }
        }
      }
    },
    "CreateInput": {
      "http": {
        "requestUri": "/inputs",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "required": [
          "inputName",
          "inputDefinition"
        ],
        "members": {
          "inputName": {},
          "inputDescription": {},
          "inputDefinition": {
            "shape": "S1h"
          },
          "tags": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "inputConfiguration": {
            "shape": "S1l"
          }
        }
      }
    },
    "DeleteDetectorModel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/detector-models/{detectorModelName}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "required": [
          "detectorModelName"
        ],
        "members": {
          "detectorModelName": {
            "location": "uri",
            "locationName": "detectorModelName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteInput": {
      "http": {
        "method": "DELETE",
        "requestUri": "/inputs/{inputName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "inputName"
        ],
        "members": {
          "inputName": {
            "location": "uri",
            "locationName": "inputName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDetectorModel": {
      "http": {
        "method": "GET",
        "requestUri": "/detector-models/{detectorModelName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "detectorModelName"
        ],
        "members": {
          "detectorModelName": {
            "location": "uri",
            "locationName": "detectorModelName"
          },
          "detectorModelVersion": {
            "location": "querystring",
            "locationName": "version"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "detectorModel": {
            "type": "structure",
            "members": {
              "detectorModelDefinition": {
                "shape": "S3"
              },
              "detectorModelConfiguration": {
                "shape": "S1a"
              }
            }
          }
        }
      }
    },
    "DescribeInput": {
      "http": {
        "method": "GET",
        "requestUri": "/inputs/{inputName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "inputName"
        ],
        "members": {
          "inputName": {
            "location": "uri",
            "locationName": "inputName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "input": {
            "type": "structure",
            "members": {
              "inputConfiguration": {
                "shape": "S1l"
              },
              "inputDefinition": {
                "shape": "S1h"
              }
            }
          }
        }
      }
    },
    "DescribeLoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/logging"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "loggingOptions": {
            "shape": "S20"
          }
        }
      }
    },
    "ListDetectorModelVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/detector-models/{detectorModelName}/versions"
      },
      "input": {
        "type": "structure",
        "required": [
          "detectorModelName"
        ],
        "members": {
          "detectorModelName": {
            "location": "uri",
            "locationName": "detectorModelName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "detectorModelVersionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "detectorModelName": {},
                "detectorModelVersion": {},
                "detectorModelArn": {},
                "roleArn": {},
                "creationTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListDetectorModels": {
      "http": {
        "method": "GET",
        "requestUri": "/detector-models"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "detectorModelSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "detectorModelName": {},
                "detectorModelDescription": {},
                "creationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListInputs": {
      "http": {
        "method": "GET",
        "requestUri": "/inputs"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "inputSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "inputName": {},
                "inputDescription": {},
                "inputArn": {},
                "creationTime": {
                  "type": "timestamp"
                },
                "lastUpdateTime": {
                  "type": "timestamp"
                },
                "status": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S15"
          }
        }
      }
    },
    "PutLoggingOptions": {
      "http": {
        "method": "PUT",
        "requestUri": "/logging"
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingOptions"
        ],
        "members": {
          "loggingOptions": {
            "shape": "S20"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S15"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDetectorModel": {
      "http": {
        "requestUri": "/detector-models/{detectorModelName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "detectorModelName",
          "detectorModelDefinition",
          "roleArn"
        ],
        "members": {
          "detectorModelName": {
            "location": "uri",
            "locationName": "detectorModelName"
          },
          "detectorModelDefinition": {
            "shape": "S3"
          },
          "detectorModelDescription": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "detectorModelConfiguration": {
            "shape": "S1a"
          }
        }
      }
    },
    "UpdateInput": {
      "http": {
        "method": "PUT",
        "requestUri": "/inputs/{inputName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "inputName",
          "inputDefinition"
        ],
        "members": {
          "inputName": {
            "location": "uri",
            "locationName": "inputName"
          },
          "inputDescription": {},
          "inputDefinition": {
            "shape": "S1h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "inputConfiguration": {
            "shape": "S1l"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "structure",
      "required": [
        "states",
        "initialStateName"
      ],
      "members": {
        "states": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "stateName"
            ],
            "members": {
              "stateName": {},
              "onInput": {
                "type": "structure",
                "members": {
                  "events": {
                    "shape": "S8"
                  },
                  "transitionEvents": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "eventName",
                        "condition",
                        "nextState"
                      ],
                      "members": {
                        "eventName": {},
                        "condition": {},
                        "actions": {
                          "shape": "Sc"
                        },
                        "nextState": {}
                      }
                    }
                  }
                }
              },
              "onEnter": {
                "type": "structure",
                "members": {
                  "events": {
                    "shape": "S8"
                  }
                }
              },
              "onExit": {
                "type": "structure",
                "members": {
                  "events": {
                    "shape": "S8"
                  }
                }
              }
            }
          }
        },
        "initialStateName": {}
      }
    },
    "S8": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "eventName"
        ],
        "members": {
          "eventName": {},
          "condition": {},
          "actions": {
            "shape": "Sc"
          }
        }
      }
    },
    "Sc": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "setVariable": {
            "type": "structure",
            "required": [
              "variableName",
              "value"
            ],
            "members": {
              "variableName": {},
              "value": {}
            }
          },
          "sns": {
            "type": "structure",
            "required": [
              "targetArn"
            ],
            "members": {
              "targetArn": {}
            }
          },
          "iotTopicPublish": {
            "type": "structure",
            "required": [
              "mqttTopic"
            ],
            "members": {
              "mqttTopic": {}
            }
          },
          "setTimer": {
            "type": "structure",
            "required": [
              "timerName",
              "seconds"
            ],
            "members": {
              "timerName": {},
              "seconds": {
                "type": "integer"
              }
            }
          },
          "clearTimer": {
            "type": "structure",
            "required": [
              "timerName"
            ],
            "members": {
              "timerName": {}
            }
          },
          "resetTimer": {
            "type": "structure",
            "required": [
              "timerName"
            ],
            "members": {
              "timerName": {}
            }
          },
          "lambda": {
            "type": "structure",
            "required": [
              "functionArn"
            ],
            "members": {
              "functionArn": {}
            }
          },
          "iotEvents": {
            "type": "structure",
            "required": [
              "inputName"
            ],
            "members": {
              "inputName": {}
            }
          },
          "sqs": {
            "type": "structure",
            "required": [
              "queueUrl"
            ],
            "members": {
              "queueUrl": {},
              "useBase64": {
                "type": "boolean"
              }
            }
          },
          "firehose": {
            "type": "structure",
            "required": [
              "deliveryStreamName"
            ],
            "members": {
              "deliveryStreamName": {},
              "separator": {}
            }
          }
        }
      }
    },
    "S15": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "key",
          "value"
        ],
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S1a": {
      "type": "structure",
      "members": {
        "detectorModelName": {},
        "detectorModelVersion": {},
        "detectorModelDescription": {},
        "detectorModelArn": {},
        "roleArn": {},
        "creationTime": {
          "type": "timestamp"
        },
        "lastUpdateTime": {
          "type": "timestamp"
        },
        "status": {},
        "key": {}
      }
    },
    "S1h": {
      "type": "structure",
      "required": [
        "attributes"
      ],
      "members": {
        "attributes": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "jsonPath"
            ],
            "members": {
              "jsonPath": {}
            }
          }
        }
      }
    },
    "S1l": {
      "type": "structure",
      "required": [
        "inputName",
        "inputArn",
        "creationTime",
        "lastUpdateTime",
        "status"
      ],
      "members": {
        "inputName": {},
        "inputDescription": {},
        "inputArn": {},
        "creationTime": {
          "type": "timestamp"
        },
        "lastUpdateTime": {
          "type": "timestamp"
        },
        "status": {}
      }
    },
    "S20": {
      "type": "structure",
      "required": [
        "roleArn",
        "level",
        "enabled"
      ],
      "members": {
        "roleArn": {},
        "level": {},
        "enabled": {
          "type": "boolean"
        },
        "detectorDebugOptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "detectorModelName"
            ],
            "members": {
              "detectorModelName": {},
              "keyValue": {}
            }
          }
        }
      }
    }
  }
}