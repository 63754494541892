{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-04-18",
    "endpointPrefix": "cognito-idp",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Cognito Identity Provider",
    "serviceId": "Cognito Identity Provider",
    "signatureVersion": "v4",
    "targetPrefix": "AWSCognitoIdentityProviderService",
    "uid": "cognito-idp-2016-04-18"
  },
  "operations": {
    "AddCustomAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "CustomAttributes"
        ],
        "members": {
          "UserPoolId": {},
          "CustomAttributes": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminAddUserToGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "GroupName": {}
        }
      }
    },
    "AdminConfirmSignUp": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminCreateUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "ValidationData": {
            "shape": "Si"
          },
          "TemporaryPassword": {
            "shape": "Sm"
          },
          "ForceAliasCreation": {
            "type": "boolean"
          },
          "MessageAction": {},
          "DesiredDeliveryMediums": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "User": {
            "shape": "Ss"
          }
        }
      }
    },
    "AdminDeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      }
    },
    "AdminDeleteUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "UserAttributeNames"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributeNames": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminDisableProviderForUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "User"
        ],
        "members": {
          "UserPoolId": {},
          "User": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminDisableUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminEnableUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminForgetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "DeviceKey"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "DeviceKey": {}
        }
      }
    },
    "AdminGetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey",
          "UserPoolId",
          "Username"
        ],
        "members": {
          "DeviceKey": {},
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Device"
        ],
        "members": {
          "Device": {
            "shape": "S1d"
          }
        }
      }
    },
    "AdminGetUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Username"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "UserCreateDate": {
            "type": "timestamp"
          },
          "UserLastModifiedDate": {
            "type": "timestamp"
          },
          "Enabled": {
            "type": "boolean"
          },
          "UserStatus": {},
          "MFAOptions": {
            "shape": "Sv"
          },
          "PreferredMfaSetting": {},
          "UserMFASettingList": {
            "shape": "S1g"
          }
        }
      }
    },
    "AdminInitiateAuth": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId",
          "AuthFlow"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          },
          "AuthFlow": {},
          "AuthParameters": {
            "shape": "S1k"
          },
          "ClientMetadata": {
            "shape": "S1l"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "ContextData": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "AdminLinkProviderForUser": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "DestinationUser",
          "SourceUser"
        ],
        "members": {
          "UserPoolId": {},
          "DestinationUser": {
            "shape": "S12"
          },
          "SourceUser": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminListDevices": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "shape": "S24"
          },
          "PaginationToken": {}
        }
      }
    },
    "AdminListGroupsForUser": {
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "UserPoolId"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserPoolId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "S28"
          },
          "NextToken": {}
        }
      }
    },
    "AdminListUserAuthEvents": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AuthEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "EventType": {},
                "CreationDate": {
                  "type": "timestamp"
                },
                "EventResponse": {},
                "EventRisk": {
                  "type": "structure",
                  "members": {
                    "RiskDecision": {},
                    "RiskLevel": {}
                  }
                },
                "ChallengeResponses": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ChallengeName": {},
                      "ChallengeResponse": {}
                    }
                  }
                },
                "EventContextData": {
                  "type": "structure",
                  "members": {
                    "IpAddress": {},
                    "DeviceName": {},
                    "Timezone": {},
                    "City": {},
                    "Country": {}
                  }
                },
                "EventFeedback": {
                  "type": "structure",
                  "required": [
                    "FeedbackValue",
                    "Provider"
                  ],
                  "members": {
                    "FeedbackValue": {},
                    "Provider": {},
                    "FeedbackDate": {
                      "type": "timestamp"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "AdminRemoveUserFromGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "GroupName": {}
        }
      }
    },
    "AdminResetUserPassword": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminRespondToAuthChallenge": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId",
          "ChallengeName"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          },
          "ChallengeName": {},
          "ChallengeResponses": {
            "shape": "S2x"
          },
          "Session": {},
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "ContextData": {
            "shape": "S1n"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "AdminSetUserMFAPreference": {
      "input": {
        "type": "structure",
        "required": [
          "Username",
          "UserPoolId"
        ],
        "members": {
          "SMSMfaSettings": {
            "shape": "S30"
          },
          "SoftwareTokenMfaSettings": {
            "shape": "S31"
          },
          "Username": {
            "shape": "Sd"
          },
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminSetUserPassword": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "Password"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "Password": {
            "shape": "Sm"
          },
          "Permanent": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminSetUserSettings": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "MFAOptions"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "MFAOptions": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateAuthEventFeedback": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "EventId",
          "FeedbackValue"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "EventId": {},
          "FeedbackValue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateDeviceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "DeviceKey"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "DeviceKey": {},
          "DeviceRememberedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUpdateUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "UserAttributes"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AdminUserGlobalSignOut": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateSoftwareToken": {
      "input": {
        "type": "structure",
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "Session": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SecretCode": {
            "type": "string",
            "sensitive": true
          },
          "Session": {}
        }
      }
    },
    "ChangePassword": {
      "input": {
        "type": "structure",
        "required": [
          "PreviousPassword",
          "ProposedPassword",
          "AccessToken"
        ],
        "members": {
          "PreviousPassword": {
            "shape": "Sm"
          },
          "ProposedPassword": {
            "shape": "Sm"
          },
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "ConfirmDevice": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "DeviceKey": {},
          "DeviceSecretVerifierConfig": {
            "type": "structure",
            "members": {
              "PasswordVerifier": {},
              "Salt": {}
            }
          },
          "DeviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserConfirmationNecessary": {
            "type": "boolean"
          }
        }
      }
    },
    "ConfirmForgotPassword": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "ConfirmationCode",
          "Password"
        ],
        "members": {
          "ClientId": {
            "shape": "S1i"
          },
          "SecretHash": {
            "shape": "S3r"
          },
          "Username": {
            "shape": "Sd"
          },
          "ConfirmationCode": {},
          "Password": {
            "shape": "Sm"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "ConfirmSignUp": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "ConfirmationCode"
        ],
        "members": {
          "ClientId": {
            "shape": "S1i"
          },
          "SecretHash": {
            "shape": "S3r"
          },
          "Username": {
            "shape": "Sd"
          },
          "ConfirmationCode": {},
          "ForceAliasCreation": {
            "type": "boolean"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "CreateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {},
          "Description": {},
          "RoleArn": {},
          "Precedence": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S29"
          }
        }
      }
    },
    "CreateIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName",
          "ProviderType",
          "ProviderDetails"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {},
          "ProviderType": {},
          "ProviderDetails": {
            "shape": "S42"
          },
          "AttributeMapping": {
            "shape": "S43"
          },
          "IdpIdentifiers": {
            "shape": "S45"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S48"
          }
        }
      }
    },
    "CreateResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier",
          "Name"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {},
          "Name": {},
          "Scopes": {
            "shape": "S4c"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServer"
        ],
        "members": {
          "ResourceServer": {
            "shape": "S4h"
          }
        }
      }
    },
    "CreateUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "JobName",
          "UserPoolId",
          "CloudWatchLogsRoleArn"
        ],
        "members": {
          "JobName": {},
          "UserPoolId": {},
          "CloudWatchLogsRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4l"
          }
        }
      }
    },
    "CreateUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "PoolName"
        ],
        "members": {
          "PoolName": {},
          "Policies": {
            "shape": "S4t"
          },
          "LambdaConfig": {
            "shape": "S4x"
          },
          "AutoVerifiedAttributes": {
            "shape": "S4y"
          },
          "AliasAttributes": {
            "shape": "S50"
          },
          "UsernameAttributes": {
            "shape": "S52"
          },
          "SmsVerificationMessage": {},
          "EmailVerificationMessage": {},
          "EmailVerificationSubject": {},
          "VerificationMessageTemplate": {
            "shape": "S57"
          },
          "SmsAuthenticationMessage": {},
          "MfaConfiguration": {},
          "DeviceConfiguration": {
            "shape": "S5c"
          },
          "EmailConfiguration": {
            "shape": "S5d"
          },
          "SmsConfiguration": {
            "shape": "S5g"
          },
          "UserPoolTags": {
            "shape": "S5h"
          },
          "AdminCreateUserConfig": {
            "shape": "S5k"
          },
          "Schema": {
            "shape": "S5n"
          },
          "UserPoolAddOns": {
            "shape": "S5o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPool": {
            "shape": "S5r"
          }
        }
      }
    },
    "CreateUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientName"
        ],
        "members": {
          "UserPoolId": {},
          "ClientName": {},
          "GenerateSecret": {
            "type": "boolean"
          },
          "RefreshTokenValidity": {
            "type": "integer"
          },
          "ReadAttributes": {
            "shape": "S5y"
          },
          "WriteAttributes": {
            "shape": "S5y"
          },
          "ExplicitAuthFlows": {
            "shape": "S60"
          },
          "SupportedIdentityProviders": {
            "shape": "S62"
          },
          "CallbackURLs": {
            "shape": "S63"
          },
          "LogoutURLs": {
            "shape": "S65"
          },
          "DefaultRedirectURI": {},
          "AllowedOAuthFlows": {
            "shape": "S66"
          },
          "AllowedOAuthScopes": {
            "shape": "S68"
          },
          "AllowedOAuthFlowsUserPoolClient": {
            "type": "boolean"
          },
          "AnalyticsConfiguration": {
            "shape": "S6a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S6d"
          }
        }
      }
    },
    "CreateUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain",
          "UserPoolId"
        ],
        "members": {
          "Domain": {},
          "UserPoolId": {},
          "CustomDomainConfig": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontDomain": {}
        }
      }
    },
    "DeleteGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {}
        }
      }
    },
    "DeleteIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {}
        }
      }
    },
    "DeleteResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {}
        }
      }
    },
    "DeleteUser": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "authtype": "none"
    },
    "DeleteUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserAttributeNames",
          "AccessToken"
        ],
        "members": {
          "UserAttributeNames": {
            "shape": "Sz"
          },
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "DeleteUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      }
    },
    "DeleteUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          }
        }
      }
    },
    "DeleteUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain",
          "UserPoolId"
        ],
        "members": {
          "Domain": {},
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S48"
          }
        }
      }
    },
    "DescribeResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServer"
        ],
        "members": {
          "ResourceServer": {
            "shape": "S4h"
          }
        }
      }
    },
    "DescribeRiskConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RiskConfiguration"
        ],
        "members": {
          "RiskConfiguration": {
            "shape": "S6y"
          }
        }
      }
    },
    "DescribeUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4l"
          }
        }
      }
    },
    "DescribeUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPool": {
            "shape": "S5r"
          }
        }
      }
    },
    "DescribeUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S6d"
          }
        }
      }
    },
    "DescribeUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain"
        ],
        "members": {
          "Domain": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainDescription": {
            "type": "structure",
            "members": {
              "UserPoolId": {},
              "AWSAccountId": {},
              "Domain": {},
              "S3Bucket": {},
              "CloudFrontDistribution": {},
              "Version": {},
              "Status": {},
              "CustomDomainConfig": {
                "shape": "S6g"
              }
            }
          }
        }
      }
    },
    "ForgetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "DeviceKey": {}
        }
      }
    },
    "ForgotPassword": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username"
        ],
        "members": {
          "ClientId": {
            "shape": "S1i"
          },
          "SecretHash": {
            "shape": "S3r"
          },
          "UserContextData": {
            "shape": "S3t"
          },
          "Username": {
            "shape": "Sd"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S7w"
          }
        }
      },
      "authtype": "none"
    },
    "GetCSVHeader": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolId": {},
          "CSVHeader": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetDevice": {
      "input": {
        "type": "structure",
        "required": [
          "DeviceKey"
        ],
        "members": {
          "DeviceKey": {},
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Device"
        ],
        "members": {
          "Device": {
            "shape": "S1d"
          }
        }
      }
    },
    "GetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S29"
          }
        }
      }
    },
    "GetIdentityProviderByIdentifier": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "IdpIdentifier"
        ],
        "members": {
          "UserPoolId": {},
          "IdpIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S48"
          }
        }
      }
    },
    "GetSigningCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {}
        }
      }
    },
    "GetUICustomization": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UICustomization"
        ],
        "members": {
          "UICustomization": {
            "shape": "S8a"
          }
        }
      }
    },
    "GetUser": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Username",
          "UserAttributes"
        ],
        "members": {
          "Username": {
            "shape": "Sd"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "MFAOptions": {
            "shape": "Sv"
          },
          "PreferredMfaSetting": {},
          "UserMFASettingList": {
            "shape": "S1g"
          }
        }
      },
      "authtype": "none"
    },
    "GetUserAttributeVerificationCode": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "AttributeName"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "AttributeName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S7w"
          }
        }
      },
      "authtype": "none"
    },
    "GetUserPoolMfaConfig": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SmsMfaConfiguration": {
            "shape": "S8k"
          },
          "SoftwareTokenMfaConfiguration": {
            "shape": "S8l"
          },
          "MfaConfiguration": {}
        }
      }
    },
    "GlobalSignOut": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "InitiateAuth": {
      "input": {
        "type": "structure",
        "required": [
          "AuthFlow",
          "ClientId"
        ],
        "members": {
          "AuthFlow": {},
          "AuthParameters": {
            "shape": "S1k"
          },
          "ClientMetadata": {
            "shape": "S1l"
          },
          "ClientId": {
            "shape": "S1i"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "ListDevices": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Devices": {
            "shape": "S24"
          },
          "PaginationToken": {}
        }
      }
    },
    "ListGroups": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Groups": {
            "shape": "S28"
          },
          "NextToken": {}
        }
      }
    },
    "ListIdentityProviders": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Providers"
        ],
        "members": {
          "Providers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ProviderName": {},
                "ProviderType": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListResourceServers": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServers"
        ],
        "members": {
          "ResourceServers": {
            "type": "list",
            "member": {
              "shape": "S4h"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S5h"
          }
        }
      }
    },
    "ListUserImportJobs": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "MaxResults"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJobs": {
            "type": "list",
            "member": {
              "shape": "S4l"
            }
          },
          "PaginationToken": {}
        }
      }
    },
    "ListUserPoolClients": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClients": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ClientId": {
                  "shape": "S1i"
                },
                "UserPoolId": {},
                "ClientName": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUserPools": {
      "input": {
        "type": "structure",
        "required": [
          "MaxResults"
        ],
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPools": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Id": {},
                "Name": {},
                "LambdaConfig": {
                  "shape": "S4x"
                },
                "Status": {},
                "LastModifiedDate": {
                  "type": "timestamp"
                },
                "CreationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListUsers": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "AttributesToGet": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "PaginationToken": {},
          "Filter": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "shape": "S9n"
          },
          "PaginationToken": {}
        }
      }
    },
    "ListUsersInGroup": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "GroupName"
        ],
        "members": {
          "UserPoolId": {},
          "GroupName": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Users": {
            "shape": "S9n"
          },
          "NextToken": {}
        }
      }
    },
    "ResendConfirmationCode": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username"
        ],
        "members": {
          "ClientId": {
            "shape": "S1i"
          },
          "SecretHash": {
            "shape": "S3r"
          },
          "UserContextData": {
            "shape": "S3t"
          },
          "Username": {
            "shape": "Sd"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetails": {
            "shape": "S7w"
          }
        }
      },
      "authtype": "none"
    },
    "RespondToAuthChallenge": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "ChallengeName"
        ],
        "members": {
          "ClientId": {
            "shape": "S1i"
          },
          "ChallengeName": {},
          "Session": {},
          "ChallengeResponses": {
            "shape": "S2x"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ChallengeName": {},
          "Session": {},
          "ChallengeParameters": {
            "shape": "S1t"
          },
          "AuthenticationResult": {
            "shape": "S1u"
          }
        }
      }
    },
    "SetRiskConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          },
          "CompromisedCredentialsRiskConfiguration": {
            "shape": "S6z"
          },
          "AccountTakeoverRiskConfiguration": {
            "shape": "S74"
          },
          "RiskExceptionConfiguration": {
            "shape": "S7d"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RiskConfiguration"
        ],
        "members": {
          "RiskConfiguration": {
            "shape": "S6y"
          }
        }
      }
    },
    "SetUICustomization": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          },
          "CSS": {},
          "ImageFile": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UICustomization"
        ],
        "members": {
          "UICustomization": {
            "shape": "S8a"
          }
        }
      }
    },
    "SetUserMFAPreference": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken"
        ],
        "members": {
          "SMSMfaSettings": {
            "shape": "S30"
          },
          "SoftwareTokenMfaSettings": {
            "shape": "S31"
          },
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SetUserPoolMfaConfig": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "SmsMfaConfiguration": {
            "shape": "S8k"
          },
          "SoftwareTokenMfaConfiguration": {
            "shape": "S8l"
          },
          "MfaConfiguration": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SmsMfaConfiguration": {
            "shape": "S8k"
          },
          "SoftwareTokenMfaConfiguration": {
            "shape": "S8l"
          },
          "MfaConfiguration": {}
        }
      }
    },
    "SetUserSettings": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "MFAOptions"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "MFAOptions": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    },
    "SignUp": {
      "input": {
        "type": "structure",
        "required": [
          "ClientId",
          "Username",
          "Password"
        ],
        "members": {
          "ClientId": {
            "shape": "S1i"
          },
          "SecretHash": {
            "shape": "S3r"
          },
          "Username": {
            "shape": "Sd"
          },
          "Password": {
            "shape": "Sm"
          },
          "UserAttributes": {
            "shape": "Si"
          },
          "ValidationData": {
            "shape": "Si"
          },
          "AnalyticsMetadata": {
            "shape": "S1m"
          },
          "UserContextData": {
            "shape": "S3t"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UserConfirmed",
          "UserSub"
        ],
        "members": {
          "UserConfirmed": {
            "type": "boolean"
          },
          "CodeDeliveryDetails": {
            "shape": "S7w"
          },
          "UserSub": {}
        }
      },
      "authtype": "none"
    },
    "StartUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4l"
          }
        }
      }
    },
    "StopUserImportJob": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "JobId"
        ],
        "members": {
          "UserPoolId": {},
          "JobId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserImportJob": {
            "shape": "S4l"
          }
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S5h"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAuthEventFeedback": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Username",
          "EventId",
          "FeedbackToken",
          "FeedbackValue"
        ],
        "members": {
          "UserPoolId": {},
          "Username": {
            "shape": "Sd"
          },
          "EventId": {},
          "FeedbackToken": {
            "shape": "S1v"
          },
          "FeedbackValue": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDeviceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "DeviceKey"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "DeviceKey": {},
          "DeviceRememberedStatus": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateGroup": {
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "UserPoolId"
        ],
        "members": {
          "GroupName": {},
          "UserPoolId": {},
          "Description": {},
          "RoleArn": {},
          "Precedence": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "S29"
          }
        }
      }
    },
    "UpdateIdentityProvider": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ProviderName"
        ],
        "members": {
          "UserPoolId": {},
          "ProviderName": {},
          "ProviderDetails": {
            "shape": "S42"
          },
          "AttributeMapping": {
            "shape": "S43"
          },
          "IdpIdentifiers": {
            "shape": "S45"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "IdentityProvider"
        ],
        "members": {
          "IdentityProvider": {
            "shape": "S48"
          }
        }
      }
    },
    "UpdateResourceServer": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "Identifier",
          "Name"
        ],
        "members": {
          "UserPoolId": {},
          "Identifier": {},
          "Name": {},
          "Scopes": {
            "shape": "S4c"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ResourceServer"
        ],
        "members": {
          "ResourceServer": {
            "shape": "S4h"
          }
        }
      }
    },
    "UpdateUserAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "UserAttributes",
          "AccessToken"
        ],
        "members": {
          "UserAttributes": {
            "shape": "Si"
          },
          "AccessToken": {
            "shape": "S1v"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CodeDeliveryDetailsList": {
            "type": "list",
            "member": {
              "shape": "S7w"
            }
          }
        }
      },
      "authtype": "none"
    },
    "UpdateUserPool": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId"
        ],
        "members": {
          "UserPoolId": {},
          "Policies": {
            "shape": "S4t"
          },
          "LambdaConfig": {
            "shape": "S4x"
          },
          "AutoVerifiedAttributes": {
            "shape": "S4y"
          },
          "SmsVerificationMessage": {},
          "EmailVerificationMessage": {},
          "EmailVerificationSubject": {},
          "VerificationMessageTemplate": {
            "shape": "S57"
          },
          "SmsAuthenticationMessage": {},
          "MfaConfiguration": {},
          "DeviceConfiguration": {
            "shape": "S5c"
          },
          "EmailConfiguration": {
            "shape": "S5d"
          },
          "SmsConfiguration": {
            "shape": "S5g"
          },
          "UserPoolTags": {
            "shape": "S5h"
          },
          "AdminCreateUserConfig": {
            "shape": "S5k"
          },
          "UserPoolAddOns": {
            "shape": "S5o"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateUserPoolClient": {
      "input": {
        "type": "structure",
        "required": [
          "UserPoolId",
          "ClientId"
        ],
        "members": {
          "UserPoolId": {},
          "ClientId": {
            "shape": "S1i"
          },
          "ClientName": {},
          "RefreshTokenValidity": {
            "type": "integer"
          },
          "ReadAttributes": {
            "shape": "S5y"
          },
          "WriteAttributes": {
            "shape": "S5y"
          },
          "ExplicitAuthFlows": {
            "shape": "S60"
          },
          "SupportedIdentityProviders": {
            "shape": "S62"
          },
          "CallbackURLs": {
            "shape": "S63"
          },
          "LogoutURLs": {
            "shape": "S65"
          },
          "DefaultRedirectURI": {},
          "AllowedOAuthFlows": {
            "shape": "S66"
          },
          "AllowedOAuthScopes": {
            "shape": "S68"
          },
          "AllowedOAuthFlowsUserPoolClient": {
            "type": "boolean"
          },
          "AnalyticsConfiguration": {
            "shape": "S6a"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UserPoolClient": {
            "shape": "S6d"
          }
        }
      }
    },
    "UpdateUserPoolDomain": {
      "input": {
        "type": "structure",
        "required": [
          "Domain",
          "UserPoolId",
          "CustomDomainConfig"
        ],
        "members": {
          "Domain": {},
          "UserPoolId": {},
          "CustomDomainConfig": {
            "shape": "S6g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CloudFrontDomain": {}
        }
      }
    },
    "VerifySoftwareToken": {
      "input": {
        "type": "structure",
        "required": [
          "UserCode"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "Session": {},
          "UserCode": {},
          "FriendlyDeviceName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Status": {},
          "Session": {}
        }
      }
    },
    "VerifyUserAttribute": {
      "input": {
        "type": "structure",
        "required": [
          "AccessToken",
          "AttributeName",
          "Code"
        ],
        "members": {
          "AccessToken": {
            "shape": "S1v"
          },
          "AttributeName": {},
          "Code": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "authtype": "none"
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "Name": {},
        "AttributeDataType": {},
        "DeveloperOnlyAttribute": {
          "type": "boolean"
        },
        "Mutable": {
          "type": "boolean"
        },
        "Required": {
          "type": "boolean"
        },
        "NumberAttributeConstraints": {
          "type": "structure",
          "members": {
            "MinValue": {},
            "MaxValue": {}
          }
        },
        "StringAttributeConstraints": {
          "type": "structure",
          "members": {
            "MinLength": {},
            "MaxLength": {}
          }
        }
      }
    },
    "Sd": {
      "type": "string",
      "sensitive": true
    },
    "Si": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Value": {
            "type": "string",
            "sensitive": true
          }
        }
      }
    },
    "Sm": {
      "type": "string",
      "sensitive": true
    },
    "Ss": {
      "type": "structure",
      "members": {
        "Username": {
          "shape": "Sd"
        },
        "Attributes": {
          "shape": "Si"
        },
        "UserCreateDate": {
          "type": "timestamp"
        },
        "UserLastModifiedDate": {
          "type": "timestamp"
        },
        "Enabled": {
          "type": "boolean"
        },
        "UserStatus": {},
        "MFAOptions": {
          "shape": "Sv"
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DeliveryMedium": {},
          "AttributeName": {}
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {}
    },
    "S12": {
      "type": "structure",
      "members": {
        "ProviderName": {},
        "ProviderAttributeName": {},
        "ProviderAttributeValue": {}
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "DeviceKey": {},
        "DeviceAttributes": {
          "shape": "Si"
        },
        "DeviceCreateDate": {
          "type": "timestamp"
        },
        "DeviceLastModifiedDate": {
          "type": "timestamp"
        },
        "DeviceLastAuthenticatedDate": {
          "type": "timestamp"
        }
      }
    },
    "S1g": {
      "type": "list",
      "member": {}
    },
    "S1i": {
      "type": "string",
      "sensitive": true
    },
    "S1k": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1l": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1m": {
      "type": "structure",
      "members": {
        "AnalyticsEndpointId": {}
      }
    },
    "S1n": {
      "type": "structure",
      "required": [
        "IpAddress",
        "ServerName",
        "ServerPath",
        "HttpHeaders"
      ],
      "members": {
        "IpAddress": {},
        "ServerName": {},
        "ServerPath": {},
        "HttpHeaders": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "headerName": {},
              "headerValue": {}
            }
          }
        },
        "EncodedData": {}
      }
    },
    "S1t": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1u": {
      "type": "structure",
      "members": {
        "AccessToken": {
          "shape": "S1v"
        },
        "ExpiresIn": {
          "type": "integer"
        },
        "TokenType": {},
        "RefreshToken": {
          "shape": "S1v"
        },
        "IdToken": {
          "shape": "S1v"
        },
        "NewDeviceMetadata": {
          "type": "structure",
          "members": {
            "DeviceKey": {},
            "DeviceGroupKey": {}
          }
        }
      }
    },
    "S1v": {
      "type": "string",
      "sensitive": true
    },
    "S24": {
      "type": "list",
      "member": {
        "shape": "S1d"
      }
    },
    "S28": {
      "type": "list",
      "member": {
        "shape": "S29"
      }
    },
    "S29": {
      "type": "structure",
      "members": {
        "GroupName": {},
        "UserPoolId": {},
        "Description": {},
        "RoleArn": {},
        "Precedence": {
          "type": "integer"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        }
      }
    },
    "S2x": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S30": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "PreferredMfa": {
          "type": "boolean"
        }
      }
    },
    "S31": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "PreferredMfa": {
          "type": "boolean"
        }
      }
    },
    "S3r": {
      "type": "string",
      "sensitive": true
    },
    "S3t": {
      "type": "structure",
      "members": {
        "EncodedData": {}
      }
    },
    "S42": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S43": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S45": {
      "type": "list",
      "member": {}
    },
    "S48": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ProviderName": {},
        "ProviderType": {},
        "ProviderDetails": {
          "shape": "S42"
        },
        "AttributeMapping": {
          "shape": "S43"
        },
        "IdpIdentifiers": {
          "shape": "S45"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        }
      }
    },
    "S4c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ScopeName",
          "ScopeDescription"
        ],
        "members": {
          "ScopeName": {},
          "ScopeDescription": {}
        }
      }
    },
    "S4h": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "Identifier": {},
        "Name": {},
        "Scopes": {
          "shape": "S4c"
        }
      }
    },
    "S4l": {
      "type": "structure",
      "members": {
        "JobName": {},
        "JobId": {},
        "UserPoolId": {},
        "PreSignedUrl": {},
        "CreationDate": {
          "type": "timestamp"
        },
        "StartDate": {
          "type": "timestamp"
        },
        "CompletionDate": {
          "type": "timestamp"
        },
        "Status": {},
        "CloudWatchLogsRoleArn": {},
        "ImportedUsers": {
          "type": "long"
        },
        "SkippedUsers": {
          "type": "long"
        },
        "FailedUsers": {
          "type": "long"
        },
        "CompletionMessage": {}
      }
    },
    "S4t": {
      "type": "structure",
      "members": {
        "PasswordPolicy": {
          "type": "structure",
          "members": {
            "MinimumLength": {
              "type": "integer"
            },
            "RequireUppercase": {
              "type": "boolean"
            },
            "RequireLowercase": {
              "type": "boolean"
            },
            "RequireNumbers": {
              "type": "boolean"
            },
            "RequireSymbols": {
              "type": "boolean"
            },
            "TemporaryPasswordValidityDays": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S4x": {
      "type": "structure",
      "members": {
        "PreSignUp": {},
        "CustomMessage": {},
        "PostConfirmation": {},
        "PreAuthentication": {},
        "PostAuthentication": {},
        "DefineAuthChallenge": {},
        "CreateAuthChallenge": {},
        "VerifyAuthChallengeResponse": {},
        "PreTokenGeneration": {},
        "UserMigration": {}
      }
    },
    "S4y": {
      "type": "list",
      "member": {}
    },
    "S50": {
      "type": "list",
      "member": {}
    },
    "S52": {
      "type": "list",
      "member": {}
    },
    "S57": {
      "type": "structure",
      "members": {
        "SmsMessage": {},
        "EmailMessage": {},
        "EmailSubject": {},
        "EmailMessageByLink": {},
        "EmailSubjectByLink": {},
        "DefaultEmailOption": {}
      }
    },
    "S5c": {
      "type": "structure",
      "members": {
        "ChallengeRequiredOnNewDevice": {
          "type": "boolean"
        },
        "DeviceOnlyRememberedOnUserPrompt": {
          "type": "boolean"
        }
      }
    },
    "S5d": {
      "type": "structure",
      "members": {
        "SourceArn": {},
        "ReplyToEmailAddress": {},
        "EmailSendingAccount": {}
      }
    },
    "S5g": {
      "type": "structure",
      "required": [
        "SnsCallerArn"
      ],
      "members": {
        "SnsCallerArn": {},
        "ExternalId": {}
      }
    },
    "S5h": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S5k": {
      "type": "structure",
      "members": {
        "AllowAdminCreateUserOnly": {
          "type": "boolean"
        },
        "UnusedAccountValidityDays": {
          "type": "integer"
        },
        "InviteMessageTemplate": {
          "type": "structure",
          "members": {
            "SMSMessage": {},
            "EmailMessage": {},
            "EmailSubject": {}
          }
        }
      }
    },
    "S5n": {
      "type": "list",
      "member": {
        "shape": "S4"
      }
    },
    "S5o": {
      "type": "structure",
      "required": [
        "AdvancedSecurityMode"
      ],
      "members": {
        "AdvancedSecurityMode": {}
      }
    },
    "S5r": {
      "type": "structure",
      "members": {
        "Id": {},
        "Name": {},
        "Policies": {
          "shape": "S4t"
        },
        "LambdaConfig": {
          "shape": "S4x"
        },
        "Status": {},
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        },
        "SchemaAttributes": {
          "shape": "S5n"
        },
        "AutoVerifiedAttributes": {
          "shape": "S4y"
        },
        "AliasAttributes": {
          "shape": "S50"
        },
        "UsernameAttributes": {
          "shape": "S52"
        },
        "SmsVerificationMessage": {},
        "EmailVerificationMessage": {},
        "EmailVerificationSubject": {},
        "VerificationMessageTemplate": {
          "shape": "S57"
        },
        "SmsAuthenticationMessage": {},
        "MfaConfiguration": {},
        "DeviceConfiguration": {
          "shape": "S5c"
        },
        "EstimatedNumberOfUsers": {
          "type": "integer"
        },
        "EmailConfiguration": {
          "shape": "S5d"
        },
        "SmsConfiguration": {
          "shape": "S5g"
        },
        "UserPoolTags": {
          "shape": "S5h"
        },
        "SmsConfigurationFailure": {},
        "EmailConfigurationFailure": {},
        "Domain": {},
        "CustomDomain": {},
        "AdminCreateUserConfig": {
          "shape": "S5k"
        },
        "UserPoolAddOns": {
          "shape": "S5o"
        },
        "Arn": {}
      }
    },
    "S5y": {
      "type": "list",
      "member": {}
    },
    "S60": {
      "type": "list",
      "member": {}
    },
    "S62": {
      "type": "list",
      "member": {}
    },
    "S63": {
      "type": "list",
      "member": {}
    },
    "S65": {
      "type": "list",
      "member": {}
    },
    "S66": {
      "type": "list",
      "member": {}
    },
    "S68": {
      "type": "list",
      "member": {}
    },
    "S6a": {
      "type": "structure",
      "required": [
        "ApplicationId",
        "RoleArn",
        "ExternalId"
      ],
      "members": {
        "ApplicationId": {},
        "RoleArn": {},
        "ExternalId": {},
        "UserDataShared": {
          "type": "boolean"
        }
      }
    },
    "S6d": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ClientName": {},
        "ClientId": {
          "shape": "S1i"
        },
        "ClientSecret": {
          "type": "string",
          "sensitive": true
        },
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        },
        "RefreshTokenValidity": {
          "type": "integer"
        },
        "ReadAttributes": {
          "shape": "S5y"
        },
        "WriteAttributes": {
          "shape": "S5y"
        },
        "ExplicitAuthFlows": {
          "shape": "S60"
        },
        "SupportedIdentityProviders": {
          "shape": "S62"
        },
        "CallbackURLs": {
          "shape": "S63"
        },
        "LogoutURLs": {
          "shape": "S65"
        },
        "DefaultRedirectURI": {},
        "AllowedOAuthFlows": {
          "shape": "S66"
        },
        "AllowedOAuthScopes": {
          "shape": "S68"
        },
        "AllowedOAuthFlowsUserPoolClient": {
          "type": "boolean"
        },
        "AnalyticsConfiguration": {
          "shape": "S6a"
        }
      }
    },
    "S6g": {
      "type": "structure",
      "required": [
        "CertificateArn"
      ],
      "members": {
        "CertificateArn": {}
      }
    },
    "S6y": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ClientId": {
          "shape": "S1i"
        },
        "CompromisedCredentialsRiskConfiguration": {
          "shape": "S6z"
        },
        "AccountTakeoverRiskConfiguration": {
          "shape": "S74"
        },
        "RiskExceptionConfiguration": {
          "shape": "S7d"
        },
        "LastModifiedDate": {
          "type": "timestamp"
        }
      }
    },
    "S6z": {
      "type": "structure",
      "required": [
        "Actions"
      ],
      "members": {
        "EventFilter": {
          "type": "list",
          "member": {}
        },
        "Actions": {
          "type": "structure",
          "required": [
            "EventAction"
          ],
          "members": {
            "EventAction": {}
          }
        }
      }
    },
    "S74": {
      "type": "structure",
      "required": [
        "Actions"
      ],
      "members": {
        "NotifyConfiguration": {
          "type": "structure",
          "required": [
            "SourceArn"
          ],
          "members": {
            "From": {},
            "ReplyTo": {},
            "SourceArn": {},
            "BlockEmail": {
              "shape": "S76"
            },
            "NoActionEmail": {
              "shape": "S76"
            },
            "MfaEmail": {
              "shape": "S76"
            }
          }
        },
        "Actions": {
          "type": "structure",
          "members": {
            "LowAction": {
              "shape": "S7a"
            },
            "MediumAction": {
              "shape": "S7a"
            },
            "HighAction": {
              "shape": "S7a"
            }
          }
        }
      }
    },
    "S76": {
      "type": "structure",
      "required": [
        "Subject"
      ],
      "members": {
        "Subject": {},
        "HtmlBody": {},
        "TextBody": {}
      }
    },
    "S7a": {
      "type": "structure",
      "required": [
        "Notify",
        "EventAction"
      ],
      "members": {
        "Notify": {
          "type": "boolean"
        },
        "EventAction": {}
      }
    },
    "S7d": {
      "type": "structure",
      "members": {
        "BlockedIPRangeList": {
          "type": "list",
          "member": {}
        },
        "SkippedIPRangeList": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S7w": {
      "type": "structure",
      "members": {
        "Destination": {},
        "DeliveryMedium": {},
        "AttributeName": {}
      }
    },
    "S8a": {
      "type": "structure",
      "members": {
        "UserPoolId": {},
        "ClientId": {
          "shape": "S1i"
        },
        "ImageUrl": {},
        "CSS": {},
        "CSSVersion": {},
        "LastModifiedDate": {
          "type": "timestamp"
        },
        "CreationDate": {
          "type": "timestamp"
        }
      }
    },
    "S8k": {
      "type": "structure",
      "members": {
        "SmsAuthenticationMessage": {},
        "SmsConfiguration": {
          "shape": "S5g"
        }
      }
    },
    "S8l": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S9n": {
      "type": "list",
      "member": {
        "shape": "Ss"
      }
    }
  }
}