{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-29",
    "endpointPrefix": "robomaker",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "RoboMaker",
    "serviceFullName": "AWS RoboMaker",
    "serviceId": "RoboMaker",
    "signatureVersion": "v4",
    "signingName": "robomaker",
    "uid": "robomaker-2018-06-29"
  },
  "operations": {
    "BatchDescribeSimulationJob": {
      "http": {
        "requestUri": "/batchDescribeSimulationJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobs"
        ],
        "members": {
          "jobs": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "name": {},
                "status": {},
                "lastStartedAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "failureBehavior": {},
                "failureCode": {},
                "failureReason": {},
                "clientRequestToken": {},
                "outputLocation": {
                  "shape": "Sf"
                },
                "loggingConfig": {
                  "shape": "Si"
                },
                "maxJobDurationInSeconds": {
                  "type": "long"
                },
                "simulationTimeMillis": {
                  "type": "long"
                },
                "iamRole": {},
                "robotApplications": {
                  "shape": "Sn"
                },
                "simulationApplications": {
                  "shape": "Sv"
                },
                "dataSources": {
                  "shape": "Sx"
                },
                "tags": {
                  "shape": "S12"
                },
                "vpcConfig": {
                  "shape": "S15"
                }
              }
            }
          },
          "unprocessedJobs": {
            "shape": "S2"
          }
        }
      }
    },
    "CancelDeploymentJob": {
      "http": {
        "requestUri": "/cancelDeploymentJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "job"
        ],
        "members": {
          "job": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelSimulationJob": {
      "http": {
        "requestUri": "/cancelSimulationJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "job"
        ],
        "members": {
          "job": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateDeploymentJob": {
      "http": {
        "requestUri": "/createDeploymentJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "clientRequestToken",
          "fleet",
          "deploymentApplicationConfigs"
        ],
        "members": {
          "deploymentConfig": {
            "shape": "S1f"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "fleet": {},
          "deploymentApplicationConfigs": {
            "shape": "S1i"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "fleet": {},
          "status": {},
          "deploymentApplicationConfigs": {
            "shape": "S1i"
          },
          "failureReason": {},
          "failureCode": {},
          "createdAt": {
            "type": "timestamp"
          },
          "deploymentConfig": {
            "shape": "S1f"
          },
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateFleet": {
      "http": {
        "requestUri": "/createFleet"
      },
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "createdAt": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateRobot": {
      "http": {
        "requestUri": "/createRobot"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "architecture",
          "greengrassGroupId"
        ],
        "members": {
          "name": {},
          "architecture": {},
          "greengrassGroupId": {},
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "createdAt": {
            "type": "timestamp"
          },
          "greengrassGroupId": {},
          "architecture": {},
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateRobotApplication": {
      "http": {
        "requestUri": "/createRobotApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "sources",
          "robotSoftwareSuite"
        ],
        "members": {
          "name": {},
          "sources": {
            "shape": "S1y"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "revisionId": {},
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateRobotApplicationVersion": {
      "http": {
        "requestUri": "/createRobotApplicationVersion"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {},
          "currentRevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "revisionId": {}
        }
      }
    },
    "CreateSimulationApplication": {
      "http": {
        "requestUri": "/createSimulationApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "name",
          "sources",
          "simulationSoftwareSuite",
          "robotSoftwareSuite"
        ],
        "members": {
          "name": {},
          "sources": {
            "shape": "S1y"
          },
          "simulationSoftwareSuite": {
            "shape": "S2a"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "renderingEngine": {
            "shape": "S2d"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "simulationSoftwareSuite": {
            "shape": "S2a"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "renderingEngine": {
            "shape": "S2d"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "revisionId": {},
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "CreateSimulationApplicationVersion": {
      "http": {
        "requestUri": "/createSimulationApplicationVersion"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {},
          "currentRevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "simulationSoftwareSuite": {
            "shape": "S2a"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "renderingEngine": {
            "shape": "S2d"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "revisionId": {}
        }
      }
    },
    "CreateSimulationJob": {
      "http": {
        "requestUri": "/createSimulationJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "maxJobDurationInSeconds",
          "iamRole"
        ],
        "members": {
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "outputLocation": {
            "shape": "Sf"
          },
          "loggingConfig": {
            "shape": "Si"
          },
          "maxJobDurationInSeconds": {
            "type": "long"
          },
          "iamRole": {},
          "failureBehavior": {},
          "robotApplications": {
            "shape": "Sn"
          },
          "simulationApplications": {
            "shape": "Sv"
          },
          "dataSources": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "name",
                "s3Bucket",
                "s3Keys"
              ],
              "members": {
                "name": {},
                "s3Bucket": {},
                "s3Keys": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "tags": {
            "shape": "S12"
          },
          "vpcConfig": {
            "type": "structure",
            "required": [
              "subnets"
            ],
            "members": {
              "subnets": {
                "shape": "S16"
              },
              "securityGroups": {
                "shape": "S18"
              },
              "assignPublicIp": {
                "type": "boolean"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "status": {},
          "lastStartedAt": {
            "type": "timestamp"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "failureBehavior": {},
          "failureCode": {},
          "clientRequestToken": {},
          "outputLocation": {
            "shape": "Sf"
          },
          "loggingConfig": {
            "shape": "Si"
          },
          "maxJobDurationInSeconds": {
            "type": "long"
          },
          "simulationTimeMillis": {
            "type": "long"
          },
          "iamRole": {},
          "robotApplications": {
            "shape": "Sn"
          },
          "simulationApplications": {
            "shape": "Sv"
          },
          "dataSources": {
            "shape": "Sx"
          },
          "tags": {
            "shape": "S12"
          },
          "vpcConfig": {
            "shape": "S15"
          }
        }
      }
    },
    "DeleteFleet": {
      "http": {
        "requestUri": "/deleteFleet"
      },
      "input": {
        "type": "structure",
        "required": [
          "fleet"
        ],
        "members": {
          "fleet": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRobot": {
      "http": {
        "requestUri": "/deleteRobot"
      },
      "input": {
        "type": "structure",
        "required": [
          "robot"
        ],
        "members": {
          "robot": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRobotApplication": {
      "http": {
        "requestUri": "/deleteRobotApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {},
          "applicationVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSimulationApplication": {
      "http": {
        "requestUri": "/deleteSimulationApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {},
          "applicationVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeregisterRobot": {
      "http": {
        "requestUri": "/deregisterRobot"
      },
      "input": {
        "type": "structure",
        "required": [
          "fleet",
          "robot"
        ],
        "members": {
          "fleet": {},
          "robot": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fleet": {},
          "robot": {}
        }
      }
    },
    "DescribeDeploymentJob": {
      "http": {
        "requestUri": "/describeDeploymentJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "job"
        ],
        "members": {
          "job": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "fleet": {},
          "status": {},
          "deploymentConfig": {
            "shape": "S1f"
          },
          "deploymentApplicationConfigs": {
            "shape": "S1i"
          },
          "failureReason": {},
          "failureCode": {},
          "createdAt": {
            "type": "timestamp"
          },
          "robotDeploymentSummary": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "deploymentStartTime": {
                  "type": "timestamp"
                },
                "deploymentFinishTime": {
                  "type": "timestamp"
                },
                "status": {},
                "progressDetail": {
                  "type": "structure",
                  "members": {
                    "currentProgress": {},
                    "percentDone": {
                      "type": "float"
                    },
                    "estimatedTimeRemainingSeconds": {
                      "type": "integer"
                    },
                    "targetResource": {}
                  }
                },
                "failureReason": {},
                "failureCode": {}
              }
            }
          },
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "DescribeFleet": {
      "http": {
        "requestUri": "/describeFleet"
      },
      "input": {
        "type": "structure",
        "required": [
          "fleet"
        ],
        "members": {
          "fleet": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "name": {},
          "arn": {},
          "robots": {
            "shape": "S3a"
          },
          "createdAt": {
            "type": "timestamp"
          },
          "lastDeploymentStatus": {},
          "lastDeploymentJob": {},
          "lastDeploymentTime": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "DescribeRobot": {
      "http": {
        "requestUri": "/describeRobot"
      },
      "input": {
        "type": "structure",
        "required": [
          "robot"
        ],
        "members": {
          "robot": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "fleetArn": {},
          "status": {},
          "greengrassGroupId": {},
          "createdAt": {
            "type": "timestamp"
          },
          "architecture": {},
          "lastDeploymentJob": {},
          "lastDeploymentTime": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "DescribeRobotApplication": {
      "http": {
        "requestUri": "/describeRobotApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {},
          "applicationVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "revisionId": {},
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "DescribeSimulationApplication": {
      "http": {
        "requestUri": "/describeSimulationApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "application"
        ],
        "members": {
          "application": {},
          "applicationVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "simulationSoftwareSuite": {
            "shape": "S2a"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "renderingEngine": {
            "shape": "S2d"
          },
          "revisionId": {},
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "DescribeSimulationJob": {
      "http": {
        "requestUri": "/describeSimulationJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "job"
        ],
        "members": {
          "job": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "status": {},
          "lastStartedAt": {
            "type": "timestamp"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "failureBehavior": {},
          "failureCode": {},
          "failureReason": {},
          "clientRequestToken": {},
          "outputLocation": {
            "shape": "Sf"
          },
          "loggingConfig": {
            "shape": "Si"
          },
          "maxJobDurationInSeconds": {
            "type": "long"
          },
          "simulationTimeMillis": {
            "type": "long"
          },
          "iamRole": {},
          "robotApplications": {
            "shape": "Sn"
          },
          "simulationApplications": {
            "shape": "Sv"
          },
          "dataSources": {
            "shape": "Sx"
          },
          "tags": {
            "shape": "S12"
          },
          "vpcConfig": {
            "shape": "S15"
          }
        }
      }
    },
    "ListDeploymentJobs": {
      "http": {
        "requestUri": "/listDeploymentJobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "filters": {
            "shape": "S3l"
          },
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "deploymentJobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "fleet": {},
                "status": {},
                "deploymentApplicationConfigs": {
                  "shape": "S1i"
                },
                "deploymentConfig": {
                  "shape": "S1f"
                },
                "failureReason": {},
                "failureCode": {},
                "createdAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListFleets": {
      "http": {
        "requestUri": "/listFleets"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "S3l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fleetDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "arn": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastDeploymentStatus": {},
                "lastDeploymentJob": {},
                "lastDeploymentTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRobotApplications": {
      "http": {
        "requestUri": "/listRobotApplications"
      },
      "input": {
        "type": "structure",
        "members": {
          "versionQualifier": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "S3l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "robotApplicationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "arn": {},
                "version": {},
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "robotSoftwareSuite": {
                  "shape": "S20"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListRobots": {
      "http": {
        "requestUri": "/listRobots"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "S3l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "robots": {
            "shape": "S3a"
          },
          "nextToken": {}
        }
      }
    },
    "ListSimulationApplications": {
      "http": {
        "requestUri": "/listSimulationApplications"
      },
      "input": {
        "type": "structure",
        "members": {
          "versionQualifier": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "S3l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "simulationApplicationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "arn": {},
                "version": {},
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "robotSoftwareSuite": {
                  "shape": "S20"
                },
                "simulationSoftwareSuite": {
                  "shape": "S2a"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSimulationJobs": {
      "http": {
        "requestUri": "/listSimulationJobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "filters": {
            "shape": "S3l"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "simulationJobSummaries"
        ],
        "members": {
          "simulationJobSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "name": {},
                "status": {},
                "simulationApplicationNames": {
                  "type": "list",
                  "member": {}
                },
                "robotApplicationNames": {
                  "type": "list",
                  "member": {}
                },
                "dataSourceNames": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S12"
          }
        }
      }
    },
    "RegisterRobot": {
      "http": {
        "requestUri": "/registerRobot"
      },
      "input": {
        "type": "structure",
        "required": [
          "fleet",
          "robot"
        ],
        "members": {
          "fleet": {},
          "robot": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "fleet": {},
          "robot": {}
        }
      }
    },
    "RestartSimulationJob": {
      "http": {
        "requestUri": "/restartSimulationJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "job"
        ],
        "members": {
          "job": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "SyncDeploymentJob": {
      "http": {
        "requestUri": "/syncDeploymentJob"
      },
      "input": {
        "type": "structure",
        "required": [
          "clientRequestToken",
          "fleet"
        ],
        "members": {
          "clientRequestToken": {
            "idempotencyToken": true
          },
          "fleet": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "fleet": {},
          "status": {},
          "deploymentConfig": {
            "shape": "S1f"
          },
          "deploymentApplicationConfigs": {
            "shape": "S1i"
          },
          "failureReason": {},
          "failureCode": {},
          "createdAt": {
            "type": "timestamp"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tags": {
            "shape": "S12"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "tagKeys": {
            "location": "querystring",
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateRobotApplication": {
      "http": {
        "requestUri": "/updateRobotApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "application",
          "sources",
          "robotSoftwareSuite"
        ],
        "members": {
          "application": {},
          "sources": {
            "shape": "S1y"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "currentRevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "revisionId": {}
        }
      }
    },
    "UpdateSimulationApplication": {
      "http": {
        "requestUri": "/updateSimulationApplication"
      },
      "input": {
        "type": "structure",
        "required": [
          "application",
          "sources",
          "simulationSoftwareSuite",
          "robotSoftwareSuite"
        ],
        "members": {
          "application": {},
          "sources": {
            "shape": "S1y"
          },
          "simulationSoftwareSuite": {
            "shape": "S2a"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "renderingEngine": {
            "shape": "S2d"
          },
          "currentRevisionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "version": {},
          "sources": {
            "shape": "S24"
          },
          "simulationSoftwareSuite": {
            "shape": "S2a"
          },
          "robotSoftwareSuite": {
            "shape": "S20"
          },
          "renderingEngine": {
            "shape": "S2d"
          },
          "lastUpdatedAt": {
            "type": "timestamp"
          },
          "revisionId": {}
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "structure",
      "members": {
        "s3Bucket": {},
        "s3Prefix": {}
      }
    },
    "Si": {
      "type": "structure",
      "required": [
        "recordAllRosTopics"
      ],
      "members": {
        "recordAllRosTopics": {
          "type": "boolean"
        }
      }
    },
    "Sn": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "application",
          "launchConfig"
        ],
        "members": {
          "application": {},
          "applicationVersion": {},
          "launchConfig": {
            "shape": "Sq"
          }
        }
      }
    },
    "Sq": {
      "type": "structure",
      "required": [
        "packageName",
        "launchFile"
      ],
      "members": {
        "packageName": {},
        "launchFile": {},
        "environmentVariables": {
          "shape": "Ss"
        }
      }
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "application",
          "launchConfig"
        ],
        "members": {
          "application": {},
          "applicationVersion": {},
          "launchConfig": {
            "shape": "Sq"
          }
        }
      }
    },
    "Sx": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "s3Bucket": {},
          "s3Keys": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "s3Key": {},
                "etag": {}
              }
            }
          }
        }
      }
    },
    "S12": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S15": {
      "type": "structure",
      "members": {
        "subnets": {
          "shape": "S16"
        },
        "securityGroups": {
          "shape": "S18"
        },
        "vpcId": {},
        "assignPublicIp": {
          "type": "boolean"
        }
      }
    },
    "S16": {
      "type": "list",
      "member": {}
    },
    "S18": {
      "type": "list",
      "member": {}
    },
    "S1f": {
      "type": "structure",
      "members": {
        "concurrentDeploymentPercentage": {
          "type": "integer"
        },
        "failureThresholdPercentage": {
          "type": "integer"
        },
        "robotDeploymentTimeoutInSeconds": {
          "type": "long"
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "application",
          "applicationVersion",
          "launchConfig"
        ],
        "members": {
          "application": {},
          "applicationVersion": {},
          "launchConfig": {
            "type": "structure",
            "required": [
              "packageName",
              "launchFile"
            ],
            "members": {
              "packageName": {},
              "preLaunchFile": {},
              "launchFile": {},
              "postLaunchFile": {},
              "environmentVariables": {
                "shape": "Ss"
              }
            }
          }
        }
      }
    },
    "S1y": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "s3Bucket": {},
          "s3Key": {},
          "architecture": {}
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "name": {},
        "version": {}
      }
    },
    "S24": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "s3Bucket": {},
          "s3Key": {},
          "etag": {},
          "architecture": {}
        }
      }
    },
    "S2a": {
      "type": "structure",
      "members": {
        "name": {},
        "version": {}
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "name": {},
        "version": {}
      }
    },
    "S3a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "arn": {},
          "name": {},
          "fleetArn": {},
          "status": {},
          "greenGrassGroupId": {},
          "createdAt": {
            "type": "timestamp"
          },
          "architecture": {},
          "lastDeploymentJob": {},
          "lastDeploymentTime": {
            "type": "timestamp"
          }
        }
      }
    },
    "S3l": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "name": {},
          "values": {
            "type": "list",
            "member": {}
          }
        }
      }
    }
  }
}