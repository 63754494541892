{
  "pagination": {
    "DescribeRemediationExecutionStatus": {
      "input_token": "NextToken",
      "limit_key": "Limit",
      "output_token": "NextToken",
      "result_key": "RemediationExecutionStatuses"
    },
    "GetResourceConfigHistory": {
      "input_token": "nextToken",
      "limit_key": "limit",
      "output_token": "nextToken",
      "result_key": "configurationItems"
    }
  }
}