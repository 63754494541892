{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-05-23",
    "endpointPrefix": "kinesisanalytics",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Kinesis Analytics V2",
    "serviceFullName": "Amazon Kinesis Analytics",
    "serviceId": "Kinesis Analytics V2",
    "signatureVersion": "v4",
    "signingName": "kinesisanalytics",
    "targetPrefix": "KinesisAnalytics_20180523",
    "uid": "kinesisanalyticsv2-2018-05-23"
  },
  "operations": {
    "AddApplicationCloudWatchLoggingOption": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "CloudWatchLoggingOption"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "CloudWatchLoggingOption": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          },
          "CloudWatchLoggingOptionDescriptions": {
            "shape": "S8"
          }
        }
      }
    },
    "AddApplicationInput": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "Input"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "Input": {
            "shape": "Sd"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          },
          "InputDescriptions": {
            "shape": "S11"
          }
        }
      }
    },
    "AddApplicationInputProcessingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "InputId",
          "InputProcessingConfiguration"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "InputId": {},
          "InputProcessingConfiguration": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          },
          "InputId": {},
          "InputProcessingConfigurationDescription": {
            "shape": "S14"
          }
        }
      }
    },
    "AddApplicationOutput": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "Output"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "Output": {
            "shape": "S1d"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          },
          "OutputDescriptions": {
            "shape": "S1j"
          }
        }
      }
    },
    "AddApplicationReferenceDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "ReferenceDataSource"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "ReferenceDataSource": {
            "shape": "S1p"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          },
          "ReferenceDataSourceDescriptions": {
            "shape": "S1v"
          }
        }
      }
    },
    "CreateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "RuntimeEnvironment",
          "ServiceExecutionRole"
        ],
        "members": {
          "ApplicationName": {},
          "ApplicationDescription": {},
          "RuntimeEnvironment": {},
          "ServiceExecutionRole": {},
          "ApplicationConfiguration": {
            "type": "structure",
            "required": [
              "ApplicationCodeConfiguration"
            ],
            "members": {
              "SqlApplicationConfiguration": {
                "type": "structure",
                "members": {
                  "Inputs": {
                    "type": "list",
                    "member": {
                      "shape": "Sd"
                    }
                  },
                  "Outputs": {
                    "type": "list",
                    "member": {
                      "shape": "S1d"
                    }
                  },
                  "ReferenceDataSources": {
                    "type": "list",
                    "member": {
                      "shape": "S1p"
                    }
                  }
                }
              },
              "FlinkApplicationConfiguration": {
                "type": "structure",
                "members": {
                  "CheckpointConfiguration": {
                    "type": "structure",
                    "required": [
                      "ConfigurationType"
                    ],
                    "members": {
                      "ConfigurationType": {},
                      "CheckpointingEnabled": {
                        "type": "boolean"
                      },
                      "CheckpointInterval": {
                        "type": "long"
                      },
                      "MinPauseBetweenCheckpoints": {
                        "type": "long"
                      }
                    }
                  },
                  "MonitoringConfiguration": {
                    "type": "structure",
                    "required": [
                      "ConfigurationType"
                    ],
                    "members": {
                      "ConfigurationType": {},
                      "MetricsLevel": {},
                      "LogLevel": {}
                    }
                  },
                  "ParallelismConfiguration": {
                    "type": "structure",
                    "required": [
                      "ConfigurationType"
                    ],
                    "members": {
                      "ConfigurationType": {},
                      "Parallelism": {
                        "type": "integer"
                      },
                      "ParallelismPerKPU": {
                        "type": "integer"
                      },
                      "AutoScalingEnabled": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              },
              "EnvironmentProperties": {
                "type": "structure",
                "required": [
                  "PropertyGroups"
                ],
                "members": {
                  "PropertyGroups": {
                    "shape": "S2j"
                  }
                }
              },
              "ApplicationCodeConfiguration": {
                "type": "structure",
                "required": [
                  "CodeContentType"
                ],
                "members": {
                  "CodeContent": {
                    "type": "structure",
                    "members": {
                      "TextContent": {},
                      "ZipFileContent": {
                        "type": "blob"
                      },
                      "S3ContentLocation": {
                        "type": "structure",
                        "required": [
                          "BucketARN",
                          "FileKey"
                        ],
                        "members": {
                          "BucketARN": {},
                          "FileKey": {},
                          "ObjectVersion": {}
                        }
                      }
                    }
                  },
                  "CodeContentType": {}
                }
              },
              "ApplicationSnapshotConfiguration": {
                "type": "structure",
                "required": [
                  "SnapshotsEnabled"
                ],
                "members": {
                  "SnapshotsEnabled": {
                    "type": "boolean"
                  }
                }
              }
            }
          },
          "CloudWatchLoggingOptions": {
            "type": "list",
            "member": {
              "shape": "S4"
            }
          },
          "Tags": {
            "shape": "S2x"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ApplicationDetail"
        ],
        "members": {
          "ApplicationDetail": {
            "shape": "S32"
          }
        }
      }
    },
    "CreateApplicationSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "SnapshotName"
        ],
        "members": {
          "ApplicationName": {},
          "SnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CreateTimestamp"
        ],
        "members": {
          "ApplicationName": {},
          "CreateTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteApplicationCloudWatchLoggingOption": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "CloudWatchLoggingOptionId"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "CloudWatchLoggingOptionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          },
          "CloudWatchLoggingOptionDescriptions": {
            "shape": "S8"
          }
        }
      }
    },
    "DeleteApplicationInputProcessingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "InputId"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "InputId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          }
        }
      }
    },
    "DeleteApplicationOutput": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "OutputId"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "OutputId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          }
        }
      }
    },
    "DeleteApplicationReferenceDataSource": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId",
          "ReferenceId"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "ReferenceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ApplicationARN": {},
          "ApplicationVersionId": {
            "type": "long"
          }
        }
      }
    },
    "DeleteApplicationSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "SnapshotName",
          "SnapshotCreationTimestamp"
        ],
        "members": {
          "ApplicationName": {},
          "SnapshotName": {},
          "SnapshotCreationTimestamp": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {},
          "IncludeAdditionalDetails": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ApplicationDetail"
        ],
        "members": {
          "ApplicationDetail": {
            "shape": "S32"
          }
        }
      }
    },
    "DescribeApplicationSnapshot": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "SnapshotName"
        ],
        "members": {
          "ApplicationName": {},
          "SnapshotName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "SnapshotDetails"
        ],
        "members": {
          "SnapshotDetails": {
            "shape": "S45"
          }
        }
      }
    },
    "DiscoverInputSchema": {
      "input": {
        "type": "structure",
        "required": [
          "ServiceExecutionRole"
        ],
        "members": {
          "ResourceARN": {},
          "ServiceExecutionRole": {},
          "InputStartingPositionConfiguration": {
            "shape": "S18"
          },
          "S3Configuration": {
            "type": "structure",
            "required": [
              "BucketARN",
              "FileKey"
            ],
            "members": {
              "BucketARN": {},
              "FileKey": {}
            }
          },
          "InputProcessingConfiguration": {
            "shape": "Sf"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InputSchema": {
            "shape": "Sl"
          },
          "ParsedInputRecords": {
            "type": "list",
            "member": {
              "type": "list",
              "member": {}
            }
          },
          "ProcessedInputRecords": {
            "type": "list",
            "member": {}
          },
          "RawInputRecords": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "ListApplicationSnapshots": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "SnapshotSummaries": {
            "type": "list",
            "member": {
              "shape": "S45"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListApplications": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ApplicationSummaries"
        ],
        "members": {
          "ApplicationSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "ApplicationName",
                "ApplicationARN",
                "ApplicationStatus",
                "ApplicationVersionId",
                "RuntimeEnvironment"
              ],
              "members": {
                "ApplicationName": {},
                "ApplicationARN": {},
                "ApplicationStatus": {},
                "ApplicationVersionId": {
                  "type": "long"
                },
                "RuntimeEnvironment": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S2x"
          }
        }
      }
    },
    "StartApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "RunConfiguration"
        ],
        "members": {
          "ApplicationName": {},
          "RunConfiguration": {
            "type": "structure",
            "members": {
              "SqlRunConfigurations": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "InputId",
                    "InputStartingPositionConfiguration"
                  ],
                  "members": {
                    "InputId": {},
                    "InputStartingPositionConfiguration": {
                      "shape": "S18"
                    }
                  }
                }
              },
              "ApplicationRestoreConfiguration": {
                "shape": "S3d"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName"
        ],
        "members": {
          "ApplicationName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S2x"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateApplication": {
      "input": {
        "type": "structure",
        "required": [
          "ApplicationName",
          "CurrentApplicationVersionId"
        ],
        "members": {
          "ApplicationName": {},
          "CurrentApplicationVersionId": {
            "type": "long"
          },
          "ApplicationConfigurationUpdate": {
            "type": "structure",
            "members": {
              "SqlApplicationConfigurationUpdate": {
                "type": "structure",
                "members": {
                  "InputUpdates": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "InputId"
                      ],
                      "members": {
                        "InputId": {},
                        "NamePrefixUpdate": {},
                        "InputProcessingConfigurationUpdate": {
                          "type": "structure",
                          "required": [
                            "InputLambdaProcessorUpdate"
                          ],
                          "members": {
                            "InputLambdaProcessorUpdate": {
                              "type": "structure",
                              "required": [
                                "ResourceARNUpdate"
                              ],
                              "members": {
                                "ResourceARNUpdate": {}
                              }
                            }
                          }
                        },
                        "KinesisStreamsInputUpdate": {
                          "type": "structure",
                          "required": [
                            "ResourceARNUpdate"
                          ],
                          "members": {
                            "ResourceARNUpdate": {}
                          }
                        },
                        "KinesisFirehoseInputUpdate": {
                          "type": "structure",
                          "required": [
                            "ResourceARNUpdate"
                          ],
                          "members": {
                            "ResourceARNUpdate": {}
                          }
                        },
                        "InputSchemaUpdate": {
                          "type": "structure",
                          "members": {
                            "RecordFormatUpdate": {
                              "shape": "Sm"
                            },
                            "RecordEncodingUpdate": {},
                            "RecordColumnUpdates": {
                              "shape": "Sv"
                            }
                          }
                        },
                        "InputParallelismUpdate": {
                          "type": "structure",
                          "required": [
                            "CountUpdate"
                          ],
                          "members": {
                            "CountUpdate": {
                              "type": "integer"
                            }
                          }
                        }
                      }
                    }
                  },
                  "OutputUpdates": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "OutputId"
                      ],
                      "members": {
                        "OutputId": {},
                        "NameUpdate": {},
                        "KinesisStreamsOutputUpdate": {
                          "type": "structure",
                          "required": [
                            "ResourceARNUpdate"
                          ],
                          "members": {
                            "ResourceARNUpdate": {}
                          }
                        },
                        "KinesisFirehoseOutputUpdate": {
                          "type": "structure",
                          "required": [
                            "ResourceARNUpdate"
                          ],
                          "members": {
                            "ResourceARNUpdate": {}
                          }
                        },
                        "LambdaOutputUpdate": {
                          "type": "structure",
                          "required": [
                            "ResourceARNUpdate"
                          ],
                          "members": {
                            "ResourceARNUpdate": {}
                          }
                        },
                        "DestinationSchemaUpdate": {
                          "shape": "S1h"
                        }
                      }
                    }
                  },
                  "ReferenceDataSourceUpdates": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "ReferenceId"
                      ],
                      "members": {
                        "ReferenceId": {},
                        "TableNameUpdate": {},
                        "S3ReferenceDataSourceUpdate": {
                          "type": "structure",
                          "members": {
                            "BucketARNUpdate": {},
                            "FileKeyUpdate": {}
                          }
                        },
                        "ReferenceSchemaUpdate": {
                          "shape": "Sl"
                        }
                      }
                    }
                  }
                }
              },
              "ApplicationCodeConfigurationUpdate": {
                "type": "structure",
                "members": {
                  "CodeContentTypeUpdate": {},
                  "CodeContentUpdate": {
                    "type": "structure",
                    "members": {
                      "TextContentUpdate": {},
                      "ZipFileContentUpdate": {
                        "type": "blob"
                      },
                      "S3ContentLocationUpdate": {
                        "type": "structure",
                        "members": {
                          "BucketARNUpdate": {},
                          "FileKeyUpdate": {},
                          "ObjectVersionUpdate": {}
                        }
                      }
                    }
                  }
                }
              },
              "FlinkApplicationConfigurationUpdate": {
                "type": "structure",
                "members": {
                  "CheckpointConfigurationUpdate": {
                    "type": "structure",
                    "members": {
                      "ConfigurationTypeUpdate": {},
                      "CheckpointingEnabledUpdate": {
                        "type": "boolean"
                      },
                      "CheckpointIntervalUpdate": {
                        "type": "long"
                      },
                      "MinPauseBetweenCheckpointsUpdate": {
                        "type": "long"
                      }
                    }
                  },
                  "MonitoringConfigurationUpdate": {
                    "type": "structure",
                    "members": {
                      "ConfigurationTypeUpdate": {},
                      "MetricsLevelUpdate": {},
                      "LogLevelUpdate": {}
                    }
                  },
                  "ParallelismConfigurationUpdate": {
                    "type": "structure",
                    "members": {
                      "ConfigurationTypeUpdate": {},
                      "ParallelismUpdate": {
                        "type": "integer"
                      },
                      "ParallelismPerKPUUpdate": {
                        "type": "integer"
                      },
                      "AutoScalingEnabledUpdate": {
                        "type": "boolean"
                      }
                    }
                  }
                }
              },
              "EnvironmentPropertyUpdates": {
                "type": "structure",
                "required": [
                  "PropertyGroups"
                ],
                "members": {
                  "PropertyGroups": {
                    "shape": "S2j"
                  }
                }
              },
              "ApplicationSnapshotConfigurationUpdate": {
                "type": "structure",
                "required": [
                  "SnapshotsEnabledUpdate"
                ],
                "members": {
                  "SnapshotsEnabledUpdate": {
                    "type": "boolean"
                  }
                }
              }
            }
          },
          "ServiceExecutionRoleUpdate": {},
          "RunConfigurationUpdate": {
            "type": "structure",
            "members": {
              "ApplicationRestoreConfiguration": {
                "shape": "S3d"
              }
            }
          },
          "CloudWatchLoggingOptionUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "CloudWatchLoggingOptionId"
              ],
              "members": {
                "CloudWatchLoggingOptionId": {},
                "LogStreamARNUpdate": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "ApplicationDetail"
        ],
        "members": {
          "ApplicationDetail": {
            "shape": "S32"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "LogStreamARN"
      ],
      "members": {
        "LogStreamARN": {}
      }
    },
    "S8": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "LogStreamARN"
        ],
        "members": {
          "CloudWatchLoggingOptionId": {},
          "LogStreamARN": {},
          "RoleARN": {}
        }
      }
    },
    "Sd": {
      "type": "structure",
      "required": [
        "NamePrefix",
        "InputSchema"
      ],
      "members": {
        "NamePrefix": {},
        "InputProcessingConfiguration": {
          "shape": "Sf"
        },
        "KinesisStreamsInput": {
          "type": "structure",
          "required": [
            "ResourceARN"
          ],
          "members": {
            "ResourceARN": {}
          }
        },
        "KinesisFirehoseInput": {
          "type": "structure",
          "required": [
            "ResourceARN"
          ],
          "members": {
            "ResourceARN": {}
          }
        },
        "InputParallelism": {
          "shape": "Sj"
        },
        "InputSchema": {
          "shape": "Sl"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "required": [
        "InputLambdaProcessor"
      ],
      "members": {
        "InputLambdaProcessor": {
          "type": "structure",
          "required": [
            "ResourceARN"
          ],
          "members": {
            "ResourceARN": {}
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "Count": {
          "type": "integer"
        }
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "RecordFormat",
        "RecordColumns"
      ],
      "members": {
        "RecordFormat": {
          "shape": "Sm"
        },
        "RecordEncoding": {},
        "RecordColumns": {
          "shape": "Sv"
        }
      }
    },
    "Sm": {
      "type": "structure",
      "required": [
        "RecordFormatType"
      ],
      "members": {
        "RecordFormatType": {},
        "MappingParameters": {
          "type": "structure",
          "members": {
            "JSONMappingParameters": {
              "type": "structure",
              "required": [
                "RecordRowPath"
              ],
              "members": {
                "RecordRowPath": {}
              }
            },
            "CSVMappingParameters": {
              "type": "structure",
              "required": [
                "RecordRowDelimiter",
                "RecordColumnDelimiter"
              ],
              "members": {
                "RecordRowDelimiter": {},
                "RecordColumnDelimiter": {}
              }
            }
          }
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "SqlType"
        ],
        "members": {
          "Name": {},
          "Mapping": {},
          "SqlType": {}
        }
      }
    },
    "S11": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "InputId": {},
          "NamePrefix": {},
          "InAppStreamNames": {
            "type": "list",
            "member": {}
          },
          "InputProcessingConfigurationDescription": {
            "shape": "S14"
          },
          "KinesisStreamsInputDescription": {
            "type": "structure",
            "required": [
              "ResourceARN"
            ],
            "members": {
              "ResourceARN": {},
              "RoleARN": {}
            }
          },
          "KinesisFirehoseInputDescription": {
            "type": "structure",
            "required": [
              "ResourceARN"
            ],
            "members": {
              "ResourceARN": {},
              "RoleARN": {}
            }
          },
          "InputSchema": {
            "shape": "Sl"
          },
          "InputParallelism": {
            "shape": "Sj"
          },
          "InputStartingPositionConfiguration": {
            "shape": "S18"
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "members": {
        "InputLambdaProcessorDescription": {
          "type": "structure",
          "required": [
            "ResourceARN"
          ],
          "members": {
            "ResourceARN": {},
            "RoleARN": {}
          }
        }
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "InputStartingPosition": {}
      }
    },
    "S1d": {
      "type": "structure",
      "required": [
        "Name",
        "DestinationSchema"
      ],
      "members": {
        "Name": {},
        "KinesisStreamsOutput": {
          "type": "structure",
          "required": [
            "ResourceARN"
          ],
          "members": {
            "ResourceARN": {}
          }
        },
        "KinesisFirehoseOutput": {
          "type": "structure",
          "required": [
            "ResourceARN"
          ],
          "members": {
            "ResourceARN": {}
          }
        },
        "LambdaOutput": {
          "type": "structure",
          "required": [
            "ResourceARN"
          ],
          "members": {
            "ResourceARN": {}
          }
        },
        "DestinationSchema": {
          "shape": "S1h"
        }
      }
    },
    "S1h": {
      "type": "structure",
      "required": [
        "RecordFormatType"
      ],
      "members": {
        "RecordFormatType": {}
      }
    },
    "S1j": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "OutputId": {},
          "Name": {},
          "KinesisStreamsOutputDescription": {
            "type": "structure",
            "required": [
              "ResourceARN"
            ],
            "members": {
              "ResourceARN": {},
              "RoleARN": {}
            }
          },
          "KinesisFirehoseOutputDescription": {
            "type": "structure",
            "required": [
              "ResourceARN"
            ],
            "members": {
              "ResourceARN": {},
              "RoleARN": {}
            }
          },
          "LambdaOutputDescription": {
            "type": "structure",
            "required": [
              "ResourceARN"
            ],
            "members": {
              "ResourceARN": {},
              "RoleARN": {}
            }
          },
          "DestinationSchema": {
            "shape": "S1h"
          }
        }
      }
    },
    "S1p": {
      "type": "structure",
      "required": [
        "TableName",
        "ReferenceSchema"
      ],
      "members": {
        "TableName": {},
        "S3ReferenceDataSource": {
          "type": "structure",
          "members": {
            "BucketARN": {},
            "FileKey": {}
          }
        },
        "ReferenceSchema": {
          "shape": "Sl"
        }
      }
    },
    "S1v": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "ReferenceId",
          "TableName",
          "S3ReferenceDataSourceDescription"
        ],
        "members": {
          "ReferenceId": {},
          "TableName": {},
          "S3ReferenceDataSourceDescription": {
            "type": "structure",
            "required": [
              "BucketARN",
              "FileKey"
            ],
            "members": {
              "BucketARN": {},
              "FileKey": {},
              "ReferenceRoleARN": {}
            }
          },
          "ReferenceSchema": {
            "shape": "Sl"
          }
        }
      }
    },
    "S2j": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "PropertyGroupId",
          "PropertyMap"
        ],
        "members": {
          "PropertyGroupId": {},
          "PropertyMap": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S2x": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S32": {
      "type": "structure",
      "required": [
        "ApplicationARN",
        "ApplicationName",
        "RuntimeEnvironment",
        "ApplicationStatus",
        "ApplicationVersionId"
      ],
      "members": {
        "ApplicationARN": {},
        "ApplicationDescription": {},
        "ApplicationName": {},
        "RuntimeEnvironment": {},
        "ServiceExecutionRole": {},
        "ApplicationStatus": {},
        "ApplicationVersionId": {
          "type": "long"
        },
        "CreateTimestamp": {
          "type": "timestamp"
        },
        "LastUpdateTimestamp": {
          "type": "timestamp"
        },
        "ApplicationConfigurationDescription": {
          "type": "structure",
          "members": {
            "SqlApplicationConfigurationDescription": {
              "type": "structure",
              "members": {
                "InputDescriptions": {
                  "shape": "S11"
                },
                "OutputDescriptions": {
                  "shape": "S1j"
                },
                "ReferenceDataSourceDescriptions": {
                  "shape": "S1v"
                }
              }
            },
            "ApplicationCodeConfigurationDescription": {
              "type": "structure",
              "required": [
                "CodeContentType"
              ],
              "members": {
                "CodeContentType": {},
                "CodeContentDescription": {
                  "type": "structure",
                  "members": {
                    "TextContent": {},
                    "CodeMD5": {},
                    "CodeSize": {
                      "type": "long"
                    },
                    "S3ApplicationCodeLocationDescription": {
                      "type": "structure",
                      "required": [
                        "BucketARN",
                        "FileKey"
                      ],
                      "members": {
                        "BucketARN": {},
                        "FileKey": {},
                        "ObjectVersion": {}
                      }
                    }
                  }
                }
              }
            },
            "RunConfigurationDescription": {
              "type": "structure",
              "members": {
                "ApplicationRestoreConfigurationDescription": {
                  "shape": "S3d"
                }
              }
            },
            "FlinkApplicationConfigurationDescription": {
              "type": "structure",
              "members": {
                "CheckpointConfigurationDescription": {
                  "type": "structure",
                  "members": {
                    "ConfigurationType": {},
                    "CheckpointingEnabled": {
                      "type": "boolean"
                    },
                    "CheckpointInterval": {
                      "type": "long"
                    },
                    "MinPauseBetweenCheckpoints": {
                      "type": "long"
                    }
                  }
                },
                "MonitoringConfigurationDescription": {
                  "type": "structure",
                  "members": {
                    "ConfigurationType": {},
                    "MetricsLevel": {},
                    "LogLevel": {}
                  }
                },
                "ParallelismConfigurationDescription": {
                  "type": "structure",
                  "members": {
                    "ConfigurationType": {},
                    "Parallelism": {
                      "type": "integer"
                    },
                    "ParallelismPerKPU": {
                      "type": "integer"
                    },
                    "CurrentParallelism": {
                      "type": "integer"
                    },
                    "AutoScalingEnabled": {
                      "type": "boolean"
                    }
                  }
                },
                "JobPlanDescription": {}
              }
            },
            "EnvironmentPropertyDescriptions": {
              "type": "structure",
              "members": {
                "PropertyGroupDescriptions": {
                  "shape": "S2j"
                }
              }
            },
            "ApplicationSnapshotConfigurationDescription": {
              "type": "structure",
              "required": [
                "SnapshotsEnabled"
              ],
              "members": {
                "SnapshotsEnabled": {
                  "type": "boolean"
                }
              }
            }
          }
        },
        "CloudWatchLoggingOptionDescriptions": {
          "shape": "S8"
        }
      }
    },
    "S3d": {
      "type": "structure",
      "required": [
        "ApplicationRestoreType"
      ],
      "members": {
        "ApplicationRestoreType": {},
        "SnapshotName": {}
      }
    },
    "S45": {
      "type": "structure",
      "required": [
        "SnapshotName",
        "SnapshotStatus",
        "ApplicationVersionId"
      ],
      "members": {
        "SnapshotName": {},
        "SnapshotStatus": {},
        "ApplicationVersionId": {
          "type": "long"
        },
        "SnapshotCreationTimestamp": {
          "type": "timestamp"
        }
      }
    }
  }
}