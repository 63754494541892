{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-01-01",
    "endpointPrefix": "fms",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "FMS",
    "serviceFullName": "Firewall Management Service",
    "serviceId": "FMS",
    "signatureVersion": "v4",
    "targetPrefix": "AWSFMS_20180101",
    "uid": "fms-2018-01-01"
  },
  "operations": {
    "AssociateAdminAccount": {
      "input": {
        "type": "structure",
        "required": [
          "AdminAccount"
        ],
        "members": {
          "AdminAccount": {}
        }
      }
    },
    "DeleteNotificationChannel": {
      "input": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {},
          "DeleteAllPolicyResources": {
            "type": "boolean"
          }
        }
      }
    },
    "DisassociateAdminAccount": {
      "input": {
        "type": "structure",
        "members": {}
      }
    },
    "GetAdminAccount": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AdminAccount": {},
          "RoleStatus": {}
        }
      }
    },
    "GetComplianceDetail": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId",
          "MemberAccount"
        ],
        "members": {
          "PolicyId": {},
          "MemberAccount": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyComplianceDetail": {
            "type": "structure",
            "members": {
              "PolicyOwner": {},
              "PolicyId": {},
              "MemberAccount": {},
              "Violators": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "ResourceId": {},
                    "ViolationReason": {},
                    "ResourceType": {}
                  }
                }
              },
              "EvaluationLimitExceeded": {
                "type": "boolean"
              },
              "ExpiredAt": {
                "type": "timestamp"
              },
              "IssueInfoMap": {
                "shape": "Sk"
              }
            }
          }
        }
      }
    },
    "GetNotificationChannel": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "SnsTopicArn": {},
          "SnsRoleName": {}
        }
      }
    },
    "GetPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {
            "shape": "Ss"
          },
          "PolicyArn": {}
        }
      }
    },
    "GetProtectionStatus": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {},
          "MemberAccountId": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AdminAccountId": {},
          "ServiceType": {},
          "Data": {},
          "NextToken": {}
        }
      }
    },
    "ListComplianceStatus": {
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyComplianceStatusList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyOwner": {},
                "PolicyId": {},
                "PolicyName": {},
                "MemberAccount": {},
                "EvaluationResults": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "ComplianceStatus": {},
                      "ViolatorCount": {
                        "type": "long"
                      },
                      "EvaluationLimitExceeded": {
                        "type": "boolean"
                      }
                    }
                  }
                },
                "LastUpdated": {
                  "type": "timestamp"
                },
                "IssueInfoMap": {
                  "shape": "Sk"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListMemberAccounts": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MemberAccounts": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListPolicies": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyArn": {},
                "PolicyId": {},
                "PolicyName": {},
                "ResourceType": {},
                "SecurityServiceType": {},
                "RemediationEnabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutNotificationChannel": {
      "input": {
        "type": "structure",
        "required": [
          "SnsTopicArn",
          "SnsRoleName"
        ],
        "members": {
          "SnsTopicArn": {},
          "SnsRoleName": {}
        }
      }
    },
    "PutPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Policy"
        ],
        "members": {
          "Policy": {
            "shape": "Ss"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {
            "shape": "Ss"
          },
          "PolicyArn": {}
        }
      }
    }
  },
  "shapes": {
    "Sk": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Ss": {
      "type": "structure",
      "required": [
        "PolicyName",
        "SecurityServicePolicyData",
        "ResourceType",
        "ExcludeResourceTags",
        "RemediationEnabled"
      ],
      "members": {
        "PolicyId": {},
        "PolicyName": {},
        "PolicyUpdateToken": {},
        "SecurityServicePolicyData": {
          "type": "structure",
          "required": [
            "Type"
          ],
          "members": {
            "Type": {},
            "ManagedServiceData": {}
          }
        },
        "ResourceType": {},
        "ResourceTypeList": {
          "type": "list",
          "member": {}
        },
        "ResourceTags": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Key"
            ],
            "members": {
              "Key": {},
              "Value": {}
            }
          }
        },
        "ExcludeResourceTags": {
          "type": "boolean"
        },
        "RemediationEnabled": {
          "type": "boolean"
        },
        "IncludeMap": {
          "shape": "S13"
        },
        "ExcludeMap": {
          "shape": "S13"
        }
      }
    },
    "S13": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    }
  }
}