{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-10-01",
    "endpointPrefix": "gamelift",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon GameLift",
    "serviceId": "GameLift",
    "signatureVersion": "v4",
    "targetPrefix": "GameLift",
    "uid": "gamelift-2015-10-01"
  },
  "operations": {
    "AcceptMatch": {
      "input": {
        "type": "structure",
        "required": [
          "TicketId",
          "PlayerIds",
          "AcceptanceType"
        ],
        "members": {
          "TicketId": {},
          "PlayerIds": {
            "shape": "S3"
          },
          "AcceptanceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RoutingStrategy"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "RoutingStrategy": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "Se"
          }
        }
      }
    },
    "CreateBuild": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "StorageLocation": {
            "shape": "Sj"
          },
          "OperatingSystem": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "Sn"
          },
          "UploadCredentials": {
            "shape": "Sr"
          },
          "StorageLocation": {
            "shape": "Sj"
          }
        }
      }
    },
    "CreateFleet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "EC2InstanceType"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "BuildId": {},
          "ScriptId": {},
          "ServerLaunchPath": {},
          "ServerLaunchParameters": {},
          "LogPaths": {
            "shape": "S3"
          },
          "EC2InstanceType": {},
          "EC2InboundPermissions": {
            "shape": "Sv"
          },
          "NewGameSessionProtectionPolicy": {},
          "RuntimeConfiguration": {
            "shape": "S11"
          },
          "ResourceCreationLimitPolicy": {
            "shape": "S17"
          },
          "MetricGroups": {
            "shape": "S19"
          },
          "PeerVpcAwsAccountId": {},
          "PeerVpcId": {},
          "FleetType": {},
          "InstanceRoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetAttributes": {
            "shape": "S1d"
          }
        }
      }
    },
    "CreateGameSession": {
      "input": {
        "type": "structure",
        "required": [
          "MaximumPlayerSessionCount"
        ],
        "members": {
          "FleetId": {},
          "AliasId": {},
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "Name": {},
          "GameProperties": {
            "shape": "S1i"
          },
          "CreatorId": {},
          "GameSessionId": {},
          "IdempotencyToken": {},
          "GameSessionData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSession": {
            "shape": "S1p"
          }
        }
      }
    },
    "CreateGameSessionQueue": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "TimeoutInSeconds": {
            "type": "integer"
          },
          "PlayerLatencyPolicies": {
            "shape": "S1x"
          },
          "Destinations": {
            "shape": "S1z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionQueue": {
            "shape": "S22"
          }
        }
      }
    },
    "CreateMatchmakingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "GameSessionQueueArns",
          "RequestTimeoutSeconds",
          "AcceptanceRequired",
          "RuleSetName"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "GameSessionQueueArns": {
            "shape": "S24"
          },
          "RequestTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "RuleSetName": {},
          "NotificationTarget": {},
          "AdditionalPlayerCount": {
            "type": "integer"
          },
          "CustomEventData": {},
          "GameProperties": {
            "shape": "S1i"
          },
          "GameSessionData": {},
          "BackfillMode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S2c"
          }
        }
      }
    },
    "CreateMatchmakingRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "RuleSetBody"
        ],
        "members": {
          "Name": {},
          "RuleSetBody": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RuleSet"
        ],
        "members": {
          "RuleSet": {
            "shape": "S2g"
          }
        }
      }
    },
    "CreatePlayerSession": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId",
          "PlayerId"
        ],
        "members": {
          "GameSessionId": {},
          "PlayerId": {},
          "PlayerData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSession": {
            "shape": "S2k"
          }
        }
      }
    },
    "CreatePlayerSessions": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId",
          "PlayerIds"
        ],
        "members": {
          "GameSessionId": {},
          "PlayerIds": {
            "type": "list",
            "member": {}
          },
          "PlayerDataMap": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSessions": {
            "shape": "S2r"
          }
        }
      }
    },
    "CreateScript": {
      "input": {
        "type": "structure",
        "members": {
          "Name": {},
          "Version": {},
          "StorageLocation": {
            "shape": "Sj"
          },
          "ZipFile": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Script": {
            "shape": "S2v"
          }
        }
      }
    },
    "CreateVpcPeeringAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "GameLiftAwsAccountId",
          "PeerVpcId"
        ],
        "members": {
          "GameLiftAwsAccountId": {},
          "PeerVpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringAuthorization": {
            "shape": "S2y"
          }
        }
      }
    },
    "CreateVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "PeerVpcAwsAccountId",
          "PeerVpcId"
        ],
        "members": {
          "FleetId": {},
          "PeerVpcAwsAccountId": {},
          "PeerVpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      }
    },
    "DeleteBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      }
    },
    "DeleteFleet": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      }
    },
    "DeleteGameSessionQueue": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteMatchmakingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteMatchmakingRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "FleetId"
        ],
        "members": {
          "Name": {},
          "FleetId": {}
        }
      }
    },
    "DeleteScript": {
      "input": {
        "type": "structure",
        "required": [
          "ScriptId"
        ],
        "members": {
          "ScriptId": {}
        }
      }
    },
    "DeleteVpcPeeringAuthorization": {
      "input": {
        "type": "structure",
        "required": [
          "GameLiftAwsAccountId",
          "PeerVpcId"
        ],
        "members": {
          "GameLiftAwsAccountId": {},
          "PeerVpcId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteVpcPeeringConnection": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "VpcPeeringConnectionId"
        ],
        "members": {
          "FleetId": {},
          "VpcPeeringConnectionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "Se"
          }
        }
      }
    },
    "DescribeBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "Sn"
          }
        }
      }
    },
    "DescribeEC2InstanceLimits": {
      "input": {
        "type": "structure",
        "members": {
          "EC2InstanceType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EC2InstanceLimits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EC2InstanceType": {},
                "CurrentInstances": {
                  "type": "integer"
                },
                "InstanceLimit": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "DescribeFleetAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S3p"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetAttributes": {
            "type": "list",
            "member": {
              "shape": "S1d"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetCapacity": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S3p"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetCapacity": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "InstanceType": {},
                "InstanceCounts": {
                  "type": "structure",
                  "members": {
                    "DESIRED": {
                      "type": "integer"
                    },
                    "MINIMUM": {
                      "type": "integer"
                    },
                    "MAXIMUM": {
                      "type": "integer"
                    },
                    "PENDING": {
                      "type": "integer"
                    },
                    "ACTIVE": {
                      "type": "integer"
                    },
                    "IDLE": {
                      "type": "integer"
                    },
                    "TERMINATING": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetEvents": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EventId": {},
                "ResourceId": {},
                "EventCode": {},
                "Message": {},
                "EventTime": {
                  "type": "timestamp"
                },
                "PreSignedLogUrl": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFleetPortSettings": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InboundPermissions": {
            "shape": "Sv"
          }
        }
      }
    },
    "DescribeFleetUtilization": {
      "input": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S3p"
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetUtilization": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "ActiveServerProcessCount": {
                  "type": "integer"
                },
                "ActiveGameSessionCount": {
                  "type": "integer"
                },
                "CurrentPlayerSessionCount": {
                  "type": "integer"
                },
                "MaximumPlayerSessionCount": {
                  "type": "integer"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameSessionDetails": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "GameSessionId": {},
          "AliasId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionDetails": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GameSession": {
                  "shape": "S1p"
                },
                "ProtectionPolicy": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameSessionPlacement": {
      "input": {
        "type": "structure",
        "required": [
          "PlacementId"
        ],
        "members": {
          "PlacementId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionPlacement": {
            "shape": "S4e"
          }
        }
      }
    },
    "DescribeGameSessionQueues": {
      "input": {
        "type": "structure",
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionQueues": {
            "type": "list",
            "member": {
              "shape": "S22"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeGameSessions": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "GameSessionId": {},
          "AliasId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessions": {
            "shape": "S4r"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeInstances": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "InstanceId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Instances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "InstanceId": {},
                "IpAddress": {},
                "OperatingSystem": {},
                "Type": {},
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMatchmaking": {
      "input": {
        "type": "structure",
        "required": [
          "TicketIds"
        ],
        "members": {
          "TicketIds": {
            "shape": "S4z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TicketList": {
            "type": "list",
            "member": {
              "shape": "S52"
            }
          }
        }
      }
    },
    "DescribeMatchmakingConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "Names": {
            "shape": "S4z"
          },
          "RuleSetName": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configurations": {
            "type": "list",
            "member": {
              "shape": "S2c"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeMatchmakingRuleSets": {
      "input": {
        "type": "structure",
        "members": {
          "Names": {
            "type": "list",
            "member": {}
          },
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RuleSets"
        ],
        "members": {
          "RuleSets": {
            "type": "list",
            "member": {
              "shape": "S2g"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribePlayerSessions": {
      "input": {
        "type": "structure",
        "members": {
          "GameSessionId": {},
          "PlayerId": {},
          "PlayerSessionId": {},
          "PlayerSessionStatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PlayerSessions": {
            "shape": "S2r"
          },
          "NextToken": {}
        }
      }
    },
    "DescribeRuntimeConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuntimeConfiguration": {
            "shape": "S11"
          }
        }
      }
    },
    "DescribeScalingPolicies": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "StatusFilter": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ScalingPolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "Name": {},
                "Status": {},
                "ScalingAdjustment": {
                  "type": "integer"
                },
                "ScalingAdjustmentType": {},
                "ComparisonOperator": {},
                "Threshold": {
                  "type": "double"
                },
                "EvaluationPeriods": {
                  "type": "integer"
                },
                "MetricName": {},
                "PolicyType": {},
                "TargetConfiguration": {
                  "shape": "S62"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeScript": {
      "input": {
        "type": "structure",
        "required": [
          "ScriptId"
        ],
        "members": {
          "ScriptId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Script": {
            "shape": "S2v"
          }
        }
      }
    },
    "DescribeVpcPeeringAuthorizations": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringAuthorizations": {
            "type": "list",
            "member": {
              "shape": "S2y"
            }
          }
        }
      }
    },
    "DescribeVpcPeeringConnections": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "VpcPeeringConnections": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "FleetId": {},
                "IpV4CidrBlock": {},
                "VpcPeeringConnectionId": {},
                "Status": {
                  "type": "structure",
                  "members": {
                    "Code": {},
                    "Message": {}
                  }
                },
                "PeerVpcId": {},
                "GameLiftVpcId": {}
              }
            }
          }
        }
      }
    },
    "GetGameSessionLogUrl": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId"
        ],
        "members": {
          "GameSessionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PreSignedUrl": {}
        }
      }
    },
    "GetInstanceAccess": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "InstanceId"
        ],
        "members": {
          "FleetId": {},
          "InstanceId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "InstanceAccess": {
            "type": "structure",
            "members": {
              "FleetId": {},
              "InstanceId": {},
              "IpAddress": {},
              "OperatingSystem": {},
              "Credentials": {
                "type": "structure",
                "members": {
                  "UserName": {},
                  "Secret": {}
                },
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "ListAliases": {
      "input": {
        "type": "structure",
        "members": {
          "RoutingStrategyType": {},
          "Name": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Aliases": {
            "type": "list",
            "member": {
              "shape": "Se"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListBuilds": {
      "input": {
        "type": "structure",
        "members": {
          "Status": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Builds": {
            "type": "list",
            "member": {
              "shape": "Sn"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListFleets": {
      "input": {
        "type": "structure",
        "members": {
          "BuildId": {},
          "ScriptId": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetIds": {
            "shape": "S3p"
          },
          "NextToken": {}
        }
      }
    },
    "ListScripts": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Scripts": {
            "type": "list",
            "member": {
              "shape": "S2v"
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutScalingPolicy": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "FleetId",
          "MetricName"
        ],
        "members": {
          "Name": {},
          "FleetId": {},
          "ScalingAdjustment": {
            "type": "integer"
          },
          "ScalingAdjustmentType": {},
          "Threshold": {
            "type": "double"
          },
          "ComparisonOperator": {},
          "EvaluationPeriods": {
            "type": "integer"
          },
          "MetricName": {},
          "PolicyType": {},
          "TargetConfiguration": {
            "shape": "S62"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Name": {}
        }
      }
    },
    "RequestUploadCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UploadCredentials": {
            "shape": "Sr"
          },
          "StorageLocation": {
            "shape": "Sj"
          }
        }
      }
    },
    "ResolveAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "SearchGameSessions": {
      "input": {
        "type": "structure",
        "members": {
          "FleetId": {},
          "AliasId": {},
          "FilterExpression": {},
          "SortExpression": {},
          "Limit": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessions": {
            "shape": "S4r"
          },
          "NextToken": {}
        }
      }
    },
    "StartFleetActions": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "Actions"
        ],
        "members": {
          "FleetId": {},
          "Actions": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartGameSessionPlacement": {
      "input": {
        "type": "structure",
        "required": [
          "PlacementId",
          "GameSessionQueueName",
          "MaximumPlayerSessionCount"
        ],
        "members": {
          "PlacementId": {},
          "GameSessionQueueName": {},
          "GameProperties": {
            "shape": "S1i"
          },
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "GameSessionName": {},
          "PlayerLatencies": {
            "shape": "S4g"
          },
          "DesiredPlayerSessions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PlayerId": {},
                "PlayerData": {}
              }
            }
          },
          "GameSessionData": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionPlacement": {
            "shape": "S4e"
          }
        }
      }
    },
    "StartMatchBackfill": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationName",
          "GameSessionArn",
          "Players"
        ],
        "members": {
          "TicketId": {},
          "ConfigurationName": {},
          "GameSessionArn": {},
          "Players": {
            "shape": "S55"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MatchmakingTicket": {
            "shape": "S52"
          }
        }
      }
    },
    "StartMatchmaking": {
      "input": {
        "type": "structure",
        "required": [
          "ConfigurationName",
          "Players"
        ],
        "members": {
          "TicketId": {},
          "ConfigurationName": {},
          "Players": {
            "shape": "S55"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "MatchmakingTicket": {
            "shape": "S52"
          }
        }
      }
    },
    "StopFleetActions": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "Actions"
        ],
        "members": {
          "FleetId": {},
          "Actions": {
            "shape": "S1f"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopGameSessionPlacement": {
      "input": {
        "type": "structure",
        "required": [
          "PlacementId"
        ],
        "members": {
          "PlacementId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionPlacement": {
            "shape": "S4e"
          }
        }
      }
    },
    "StopMatchmaking": {
      "input": {
        "type": "structure",
        "required": [
          "TicketId"
        ],
        "members": {
          "TicketId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAlias": {
      "input": {
        "type": "structure",
        "required": [
          "AliasId"
        ],
        "members": {
          "AliasId": {},
          "Name": {},
          "Description": {},
          "RoutingStrategy": {
            "shape": "S9"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Alias": {
            "shape": "Se"
          }
        }
      }
    },
    "UpdateBuild": {
      "input": {
        "type": "structure",
        "required": [
          "BuildId"
        ],
        "members": {
          "BuildId": {},
          "Name": {},
          "Version": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Build": {
            "shape": "Sn"
          }
        }
      }
    },
    "UpdateFleetAttributes": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "Name": {},
          "Description": {},
          "NewGameSessionProtectionPolicy": {},
          "ResourceCreationLimitPolicy": {
            "shape": "S17"
          },
          "MetricGroups": {
            "shape": "S19"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateFleetCapacity": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "DesiredInstances": {
            "type": "integer"
          },
          "MinSize": {
            "type": "integer"
          },
          "MaxSize": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateFleetPortSettings": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId"
        ],
        "members": {
          "FleetId": {},
          "InboundPermissionAuthorizations": {
            "shape": "Sv"
          },
          "InboundPermissionRevocations": {
            "shape": "Sv"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FleetId": {}
        }
      }
    },
    "UpdateGameSession": {
      "input": {
        "type": "structure",
        "required": [
          "GameSessionId"
        ],
        "members": {
          "GameSessionId": {},
          "MaximumPlayerSessionCount": {
            "type": "integer"
          },
          "Name": {},
          "PlayerSessionCreationPolicy": {},
          "ProtectionPolicy": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSession": {
            "shape": "S1p"
          }
        }
      }
    },
    "UpdateGameSessionQueue": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "TimeoutInSeconds": {
            "type": "integer"
          },
          "PlayerLatencyPolicies": {
            "shape": "S1x"
          },
          "Destinations": {
            "shape": "S1z"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GameSessionQueue": {
            "shape": "S22"
          }
        }
      }
    },
    "UpdateMatchmakingConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "GameSessionQueueArns": {
            "shape": "S24"
          },
          "RequestTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceTimeoutSeconds": {
            "type": "integer"
          },
          "AcceptanceRequired": {
            "type": "boolean"
          },
          "RuleSetName": {},
          "NotificationTarget": {},
          "AdditionalPlayerCount": {
            "type": "integer"
          },
          "CustomEventData": {},
          "GameProperties": {
            "shape": "S1i"
          },
          "GameSessionData": {},
          "BackfillMode": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Configuration": {
            "shape": "S2c"
          }
        }
      }
    },
    "UpdateRuntimeConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "FleetId",
          "RuntimeConfiguration"
        ],
        "members": {
          "FleetId": {},
          "RuntimeConfiguration": {
            "shape": "S11"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RuntimeConfiguration": {
            "shape": "S11"
          }
        }
      }
    },
    "UpdateScript": {
      "input": {
        "type": "structure",
        "required": [
          "ScriptId"
        ],
        "members": {
          "ScriptId": {},
          "Name": {},
          "Version": {},
          "StorageLocation": {
            "shape": "Sj"
          },
          "ZipFile": {
            "type": "blob"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Script": {
            "shape": "S2v"
          }
        }
      }
    },
    "ValidateMatchmakingRuleSet": {
      "input": {
        "type": "structure",
        "required": [
          "RuleSetBody"
        ],
        "members": {
          "RuleSetBody": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Valid": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {}
    },
    "S9": {
      "type": "structure",
      "members": {
        "Type": {},
        "FleetId": {},
        "Message": {}
      }
    },
    "Se": {
      "type": "structure",
      "members": {
        "AliasId": {},
        "Name": {},
        "AliasArn": {},
        "Description": {},
        "RoutingStrategy": {
          "shape": "S9"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "LastUpdatedTime": {
          "type": "timestamp"
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "Bucket": {},
        "Key": {},
        "RoleArn": {},
        "ObjectVersion": {}
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "BuildId": {},
        "Name": {},
        "Version": {},
        "Status": {},
        "SizeOnDisk": {
          "type": "long"
        },
        "OperatingSystem": {},
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "AccessKeyId": {},
        "SecretAccessKey": {},
        "SessionToken": {}
      },
      "sensitive": true
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "FromPort",
          "ToPort",
          "IpRange",
          "Protocol"
        ],
        "members": {
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          },
          "IpRange": {},
          "Protocol": {}
        }
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "ServerProcesses": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "LaunchPath",
              "ConcurrentExecutions"
            ],
            "members": {
              "LaunchPath": {},
              "Parameters": {},
              "ConcurrentExecutions": {
                "type": "integer"
              }
            }
          }
        },
        "MaxConcurrentGameSessionActivations": {
          "type": "integer"
        },
        "GameSessionActivationTimeoutSeconds": {
          "type": "integer"
        }
      }
    },
    "S17": {
      "type": "structure",
      "members": {
        "NewGameSessionsPerCreator": {
          "type": "integer"
        },
        "PolicyPeriodInMinutes": {
          "type": "integer"
        }
      }
    },
    "S19": {
      "type": "list",
      "member": {}
    },
    "S1d": {
      "type": "structure",
      "members": {
        "FleetId": {},
        "FleetArn": {},
        "FleetType": {},
        "InstanceType": {},
        "Description": {},
        "Name": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "Status": {},
        "BuildId": {},
        "ScriptId": {},
        "ServerLaunchPath": {},
        "ServerLaunchParameters": {},
        "LogPaths": {
          "shape": "S3"
        },
        "NewGameSessionProtectionPolicy": {},
        "OperatingSystem": {},
        "ResourceCreationLimitPolicy": {
          "shape": "S17"
        },
        "MetricGroups": {
          "shape": "S19"
        },
        "StoppedActions": {
          "shape": "S1f"
        },
        "InstanceRoleArn": {}
      }
    },
    "S1f": {
      "type": "list",
      "member": {}
    },
    "S1i": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S1p": {
      "type": "structure",
      "members": {
        "GameSessionId": {},
        "Name": {},
        "FleetId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "CurrentPlayerSessionCount": {
          "type": "integer"
        },
        "MaximumPlayerSessionCount": {
          "type": "integer"
        },
        "Status": {},
        "StatusReason": {},
        "GameProperties": {
          "shape": "S1i"
        },
        "IpAddress": {},
        "Port": {
          "type": "integer"
        },
        "PlayerSessionCreationPolicy": {},
        "CreatorId": {},
        "GameSessionData": {},
        "MatchmakerData": {}
      }
    },
    "S1x": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "MaximumIndividualPlayerLatencyMilliseconds": {
            "type": "integer"
          },
          "PolicyDurationSeconds": {
            "type": "integer"
          }
        }
      }
    },
    "S1z": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "DestinationArn": {}
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "Name": {},
        "GameSessionQueueArn": {},
        "TimeoutInSeconds": {
          "type": "integer"
        },
        "PlayerLatencyPolicies": {
          "shape": "S1x"
        },
        "Destinations": {
          "shape": "S1z"
        }
      }
    },
    "S24": {
      "type": "list",
      "member": {}
    },
    "S2c": {
      "type": "structure",
      "members": {
        "Name": {},
        "Description": {},
        "GameSessionQueueArns": {
          "shape": "S24"
        },
        "RequestTimeoutSeconds": {
          "type": "integer"
        },
        "AcceptanceTimeoutSeconds": {
          "type": "integer"
        },
        "AcceptanceRequired": {
          "type": "boolean"
        },
        "RuleSetName": {},
        "NotificationTarget": {},
        "AdditionalPlayerCount": {
          "type": "integer"
        },
        "CustomEventData": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "GameProperties": {
          "shape": "S1i"
        },
        "GameSessionData": {},
        "BackfillMode": {}
      }
    },
    "S2g": {
      "type": "structure",
      "required": [
        "RuleSetBody"
      ],
      "members": {
        "RuleSetName": {},
        "RuleSetBody": {},
        "CreationTime": {
          "type": "timestamp"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "PlayerSessionId": {},
        "PlayerId": {},
        "GameSessionId": {},
        "FleetId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "TerminationTime": {
          "type": "timestamp"
        },
        "Status": {},
        "IpAddress": {},
        "Port": {
          "type": "integer"
        },
        "PlayerData": {}
      }
    },
    "S2r": {
      "type": "list",
      "member": {
        "shape": "S2k"
      }
    },
    "S2v": {
      "type": "structure",
      "members": {
        "ScriptId": {},
        "Name": {},
        "Version": {},
        "SizeOnDisk": {
          "type": "long"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "StorageLocation": {
          "shape": "Sj"
        }
      }
    },
    "S2y": {
      "type": "structure",
      "members": {
        "GameLiftAwsAccountId": {},
        "PeerVpcAwsAccountId": {},
        "PeerVpcId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "ExpirationTime": {
          "type": "timestamp"
        }
      }
    },
    "S3p": {
      "type": "list",
      "member": {}
    },
    "S4e": {
      "type": "structure",
      "members": {
        "PlacementId": {},
        "GameSessionQueueName": {},
        "Status": {},
        "GameProperties": {
          "shape": "S1i"
        },
        "MaximumPlayerSessionCount": {
          "type": "integer"
        },
        "GameSessionName": {},
        "GameSessionId": {},
        "GameSessionArn": {},
        "GameSessionRegion": {},
        "PlayerLatencies": {
          "shape": "S4g"
        },
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "IpAddress": {},
        "Port": {
          "type": "integer"
        },
        "PlacedPlayerSessions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "PlayerId": {},
              "PlayerSessionId": {}
            }
          }
        },
        "GameSessionData": {},
        "MatchmakerData": {}
      }
    },
    "S4g": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PlayerId": {},
          "RegionIdentifier": {},
          "LatencyInMilliseconds": {
            "type": "float"
          }
        }
      }
    },
    "S4r": {
      "type": "list",
      "member": {
        "shape": "S1p"
      }
    },
    "S4z": {
      "type": "list",
      "member": {}
    },
    "S52": {
      "type": "structure",
      "members": {
        "TicketId": {},
        "ConfigurationName": {},
        "Status": {},
        "StatusReason": {},
        "StatusMessage": {},
        "StartTime": {
          "type": "timestamp"
        },
        "EndTime": {
          "type": "timestamp"
        },
        "Players": {
          "shape": "S55"
        },
        "GameSessionConnectionInfo": {
          "type": "structure",
          "members": {
            "GameSessionArn": {},
            "IpAddress": {},
            "Port": {
              "type": "integer"
            },
            "MatchedPlayerSessions": {
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "PlayerId": {},
                  "PlayerSessionId": {}
                }
              }
            }
          }
        },
        "EstimatedWaitTime": {
          "type": "integer"
        }
      }
    },
    "S55": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "PlayerId": {},
          "PlayerAttributes": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "S": {},
                "N": {
                  "type": "double"
                },
                "SL": {
                  "shape": "S3"
                },
                "SDM": {
                  "type": "map",
                  "key": {},
                  "value": {
                    "type": "double"
                  }
                }
              }
            }
          },
          "Team": {},
          "LatencyInMs": {
            "type": "map",
            "key": {},
            "value": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S62": {
      "type": "structure",
      "required": [
        "TargetValue"
      ],
      "members": {
        "TargetValue": {
          "type": "double"
        }
      }
    }
  }
}