import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "@emotion/react/jsx-runtime";
import { Avatar, Box, Divider, IconButton, LinearProgress, Stack, Typography, } from '@mui/material';
import { Clear, ErrorOutline, TaskOutlined, UploadFile } from '@mui/icons-material';
import { formatBytes } from '../../utils/formatBytes';
const LinearProgressWithLabel = (props) => {
    return (_jsxs(Stack, { direction: "row", alignItems: "center", spacing: 2, children: [_jsx(Box, { width: "100%", children: _jsx(LinearProgress, { variant: "determinate", ...props }) }), _jsx(Box, { justifyContent: "flex-end", children: _jsx(Typography, { variant: "body2", color: "text.secondary", children: `${Math.round(props.value)}%` }) })] }));
};
export const UploadItem = (props) => {
    const { error, fileName, fileSizeInBytes, onDelete, progress, showDivider = false } = props;
    const formatedFileSize = formatBytes(fileSizeInBytes);
    const handleDelete = () => onDelete();
    return (_jsxs(_Fragment, { children: [_jsxs(Stack, { paddingY: 2, spacing: 2, direction: "row", bgcolor: '#fff', children: [_jsx(Avatar, { sx: { bgcolor: 'transparent' }, children: error ? (_jsx(ErrorOutline, { color: "error", "data-id": "errorIcon", fontSize: "large" })) : progress < 100 ? (_jsx(UploadFile, { color: "success", "data-id": "progressIcon", fontSize: "large" })) : (_jsx(TaskOutlined, { color: "success", "data-id": "successIcon", fontSize: "large" })) }), _jsxs(Stack, { direction: "column", width: '100%', children: [_jsxs(Stack, { spacing: 1, direction: "row", children: [_jsx(Typography, { variant: "body2", children: fileName }), _jsx(Typography, { variant: "body2", color: "text.secondary", children: formatedFileSize }), error && (_jsx(Typography, { variant: "body2", color: "error", children: error }))] }), _jsx(LinearProgressWithLabel, { value: progress, color: !error ? 'secondary' : 'error' })] }), _jsx(IconButton, { onClick: handleDelete, "data-id": "deleteButton", children: _jsx(Clear, {}) })] }), showDivider && _jsx(Divider, {})] }));
};
