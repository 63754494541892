{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-05-18",
    "endpointPrefix": "athena",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Athena",
    "serviceId": "Athena",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonAthena",
    "uid": "athena-2017-05-18"
  },
  "operations": {
    "BatchGetNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "NamedQueryIds"
        ],
        "members": {
          "NamedQueryIds": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQueries": {
            "type": "list",
            "member": {
              "shape": "S6"
            }
          },
          "UnprocessedNamedQueryIds": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "NamedQueryId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "BatchGetQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionIds"
        ],
        "members": {
          "QueryExecutionIds": {
            "shape": "Sh"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecutions": {
            "type": "list",
            "member": {
              "shape": "Sl"
            }
          },
          "UnprocessedQueryExecutionIds": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "QueryExecutionId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "CreateNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "Database",
          "QueryString"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "Database": {},
          "QueryString": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQueryId": {}
        }
      },
      "idempotent": true
    },
    "CreateWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "Name"
        ],
        "members": {
          "Name": {},
          "Configuration": {
            "shape": "S13"
          },
          "Description": {},
          "Tags": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "NamedQueryId"
        ],
        "members": {
          "NamedQueryId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "DeleteWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "WorkGroup"
        ],
        "members": {
          "WorkGroup": {},
          "RecursiveDeleteOption": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "GetNamedQuery": {
      "input": {
        "type": "structure",
        "required": [
          "NamedQueryId"
        ],
        "members": {
          "NamedQueryId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQuery": {
            "shape": "S6"
          }
        }
      }
    },
    "GetQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionId"
        ],
        "members": {
          "QueryExecutionId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecution": {
            "shape": "Sl"
          }
        }
      }
    },
    "GetQueryResults": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionId"
        ],
        "members": {
          "QueryExecutionId": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpdateCount": {
            "type": "long"
          },
          "ResultSet": {
            "type": "structure",
            "members": {
              "Rows": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "Data": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "VarCharValue": {}
                        }
                      }
                    }
                  }
                }
              },
              "ResultSetMetadata": {
                "type": "structure",
                "members": {
                  "ColumnInfo": {
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "required": [
                        "Name",
                        "Type"
                      ],
                      "members": {
                        "CatalogName": {},
                        "SchemaName": {},
                        "TableName": {},
                        "Name": {},
                        "Label": {},
                        "Type": {},
                        "Precision": {
                          "type": "integer"
                        },
                        "Scale": {
                          "type": "integer"
                        },
                        "Nullable": {},
                        "CaseSensitive": {
                          "type": "boolean"
                        }
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "WorkGroup"
        ],
        "members": {
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkGroup": {
            "type": "structure",
            "required": [
              "Name"
            ],
            "members": {
              "Name": {},
              "State": {},
              "Configuration": {
                "shape": "S13"
              },
              "Description": {},
              "CreationTime": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "ListNamedQueries": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NamedQueryIds": {
            "shape": "S2"
          },
          "NextToken": {}
        }
      }
    },
    "ListQueryExecutions": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecutionIds": {
            "shape": "Sh"
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S17"
          },
          "NextToken": {}
        }
      }
    },
    "ListWorkGroups": {
      "input": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "WorkGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "State": {},
                "Description": {},
                "CreationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "StartQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryString"
        ],
        "members": {
          "QueryString": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "QueryExecutionContext": {
            "shape": "Sr"
          },
          "ResultConfiguration": {
            "shape": "Sn"
          },
          "WorkGroup": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "QueryExecutionId": {}
        }
      },
      "idempotent": true
    },
    "StopQueryExecution": {
      "input": {
        "type": "structure",
        "required": [
          "QueryExecutionId"
        ],
        "members": {
          "QueryExecutionId": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S17"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateWorkGroup": {
      "input": {
        "type": "structure",
        "required": [
          "WorkGroup"
        ],
        "members": {
          "WorkGroup": {},
          "Description": {},
          "ConfigurationUpdates": {
            "type": "structure",
            "members": {
              "EnforceWorkGroupConfiguration": {
                "type": "boolean"
              },
              "ResultConfigurationUpdates": {
                "type": "structure",
                "members": {
                  "OutputLocation": {},
                  "RemoveOutputLocation": {
                    "type": "boolean"
                  },
                  "EncryptionConfiguration": {
                    "shape": "Sp"
                  },
                  "RemoveEncryptionConfiguration": {
                    "type": "boolean"
                  }
                }
              },
              "PublishCloudWatchMetricsEnabled": {
                "type": "boolean"
              },
              "BytesScannedCutoffPerQuery": {
                "type": "long"
              },
              "RemoveBytesScannedCutoffPerQuery": {
                "type": "boolean"
              },
              "RequesterPaysEnabled": {
                "type": "boolean"
              }
            }
          },
          "State": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "structure",
      "required": [
        "Name",
        "Database",
        "QueryString"
      ],
      "members": {
        "Name": {},
        "Description": {},
        "Database": {},
        "QueryString": {},
        "NamedQueryId": {},
        "WorkGroup": {}
      }
    },
    "Sh": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "structure",
      "members": {
        "QueryExecutionId": {},
        "Query": {},
        "StatementType": {},
        "ResultConfiguration": {
          "shape": "Sn"
        },
        "QueryExecutionContext": {
          "shape": "Sr"
        },
        "Status": {
          "type": "structure",
          "members": {
            "State": {},
            "StateChangeReason": {},
            "SubmissionDateTime": {
              "type": "timestamp"
            },
            "CompletionDateTime": {
              "type": "timestamp"
            }
          }
        },
        "Statistics": {
          "type": "structure",
          "members": {
            "EngineExecutionTimeInMillis": {
              "type": "long"
            },
            "DataScannedInBytes": {
              "type": "long"
            }
          }
        },
        "WorkGroup": {}
      }
    },
    "Sn": {
      "type": "structure",
      "members": {
        "OutputLocation": {},
        "EncryptionConfiguration": {
          "shape": "Sp"
        }
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "EncryptionOption"
      ],
      "members": {
        "EncryptionOption": {},
        "KmsKey": {}
      }
    },
    "Sr": {
      "type": "structure",
      "members": {
        "Database": {}
      }
    },
    "S13": {
      "type": "structure",
      "members": {
        "ResultConfiguration": {
          "shape": "Sn"
        },
        "EnforceWorkGroupConfiguration": {
          "type": "boolean"
        },
        "PublishCloudWatchMetricsEnabled": {
          "type": "boolean"
        },
        "BytesScannedCutoffPerQuery": {
          "type": "long"
        },
        "RequesterPaysEnabled": {
          "type": "boolean"
        }
      }
    },
    "S17": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    }
  }
}