{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-01-12",
    "endpointPrefix": "dlm",
    "jsonVersion": "1.1",
    "protocol": "rest-json",
    "serviceAbbreviation": "Amazon DLM",
    "serviceFullName": "Amazon Data Lifecycle Manager",
    "serviceId": "DLM",
    "signatureVersion": "v4",
    "signingName": "dlm",
    "uid": "dlm-2018-01-12"
  },
  "operations": {
    "CreateLifecyclePolicy": {
      "http": {
        "requestUri": "/policies"
      },
      "input": {
        "type": "structure",
        "required": [
          "ExecutionRoleArn",
          "Description",
          "State",
          "PolicyDetails"
        ],
        "members": {
          "ExecutionRoleArn": {},
          "Description": {},
          "State": {},
          "PolicyDetails": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PolicyId": {}
        }
      }
    },
    "DeleteLifecyclePolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyId}/"
      },
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {
            "location": "uri",
            "locationName": "policyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetLifecyclePolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "PolicyIds": {
            "location": "querystring",
            "locationName": "policyIds",
            "type": "list",
            "member": {}
          },
          "State": {
            "location": "querystring",
            "locationName": "state"
          },
          "ResourceTypes": {
            "shape": "S7",
            "location": "querystring",
            "locationName": "resourceTypes"
          },
          "TargetTags": {
            "location": "querystring",
            "locationName": "targetTags",
            "type": "list",
            "member": {}
          },
          "TagsToAdd": {
            "location": "querystring",
            "locationName": "tagsToAdd",
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "PolicyId": {},
                "Description": {},
                "State": {}
              }
            }
          }
        }
      }
    },
    "GetLifecyclePolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyId}/"
      },
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {
            "location": "uri",
            "locationName": "policyId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Policy": {
            "type": "structure",
            "members": {
              "PolicyId": {},
              "Description": {},
              "State": {},
              "ExecutionRoleArn": {},
              "DateCreated": {
                "shape": "S17"
              },
              "DateModified": {
                "shape": "S17"
              },
              "PolicyDetails": {
                "shape": "S5"
              }
            }
          }
        }
      }
    },
    "UpdateLifecyclePolicy": {
      "http": {
        "method": "PATCH",
        "requestUri": "/policies/{policyId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "PolicyId"
        ],
        "members": {
          "PolicyId": {
            "location": "uri",
            "locationName": "policyId"
          },
          "ExecutionRoleArn": {},
          "State": {},
          "Description": {},
          "PolicyDetails": {
            "shape": "S5"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S5": {
      "type": "structure",
      "members": {
        "PolicyType": {},
        "ResourceTypes": {
          "shape": "S7"
        },
        "TargetTags": {
          "type": "list",
          "member": {
            "shape": "Sa"
          }
        },
        "Schedules": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Name": {},
              "CopyTags": {
                "type": "boolean"
              },
              "TagsToAdd": {
                "type": "list",
                "member": {
                  "shape": "Sa"
                }
              },
              "VariableTags": {
                "type": "list",
                "member": {
                  "shape": "Sa"
                }
              },
              "CreateRule": {
                "type": "structure",
                "required": [
                  "Interval",
                  "IntervalUnit"
                ],
                "members": {
                  "Interval": {
                    "type": "integer"
                  },
                  "IntervalUnit": {},
                  "Times": {
                    "type": "list",
                    "member": {}
                  }
                }
              },
              "RetainRule": {
                "type": "structure",
                "required": [
                  "Count"
                ],
                "members": {
                  "Count": {
                    "type": "integer"
                  }
                }
              }
            }
          }
        },
        "Parameters": {
          "type": "structure",
          "members": {
            "ExcludeBootVolume": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S7": {
      "type": "list",
      "member": {}
    },
    "Sa": {
      "type": "structure",
      "required": [
        "Key",
        "Value"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S17": {
      "type": "timestamp",
      "timestampFormat": "iso8601"
    }
  }
}