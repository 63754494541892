{
  "metadata": {
    "apiVersion": "2017-08-29",
    "endpointPrefix": "mediaconvert",
    "signingName": "mediaconvert",
    "serviceFullName": "AWS Elemental MediaConvert",
    "serviceId": "MediaConvert",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "mediaconvert-2017-08-29",
    "signatureVersion": "v4",
    "serviceAbbreviation": "MediaConvert"
  },
  "operations": {
    "AssociateCertificate": {
      "http": {
        "requestUri": "/2017-08-29/certificates",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-08-29/jobs/{id}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "Id": {
            "locationName": "id",
            "location": "uri"
          }
        },
        "required": [
          "Id"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateJob": {
      "http": {
        "requestUri": "/2017-08-29/jobs",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "AccelerationSettings": {
            "shape": "S7",
            "locationName": "accelerationSettings"
          },
          "BillingTagsSource": {
            "locationName": "billingTagsSource"
          },
          "ClientRequestToken": {
            "locationName": "clientRequestToken",
            "idempotencyToken": true
          },
          "JobTemplate": {
            "locationName": "jobTemplate"
          },
          "Priority": {
            "locationName": "priority",
            "type": "integer"
          },
          "Queue": {
            "locationName": "queue"
          },
          "Role": {
            "locationName": "role"
          },
          "Settings": {
            "shape": "Sb",
            "locationName": "settings"
          },
          "StatusUpdateInterval": {
            "locationName": "statusUpdateInterval"
          },
          "UserMetadata": {
            "shape": "Sd5",
            "locationName": "userMetadata"
          }
        },
        "required": [
          "Role",
          "Settings"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "Sd7",
            "locationName": "job"
          }
        }
      }
    },
    "CreateJobTemplate": {
      "http": {
        "requestUri": "/2017-08-29/jobTemplates",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "AccelerationSettings": {
            "shape": "S7",
            "locationName": "accelerationSettings"
          },
          "Category": {
            "locationName": "category"
          },
          "Description": {
            "locationName": "description"
          },
          "Name": {
            "locationName": "name"
          },
          "Priority": {
            "locationName": "priority",
            "type": "integer"
          },
          "Queue": {
            "locationName": "queue"
          },
          "Settings": {
            "shape": "Sdj",
            "locationName": "settings"
          },
          "StatusUpdateInterval": {
            "locationName": "statusUpdateInterval"
          },
          "Tags": {
            "shape": "Sd5",
            "locationName": "tags"
          }
        },
        "required": [
          "Settings",
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JobTemplate": {
            "shape": "Sdn",
            "locationName": "jobTemplate"
          }
        }
      }
    },
    "CreatePreset": {
      "http": {
        "requestUri": "/2017-08-29/presets",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "Category": {
            "locationName": "category"
          },
          "Description": {
            "locationName": "description"
          },
          "Name": {
            "locationName": "name"
          },
          "Settings": {
            "shape": "Sdq",
            "locationName": "settings"
          },
          "Tags": {
            "shape": "Sd5",
            "locationName": "tags"
          }
        },
        "required": [
          "Settings",
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Preset": {
            "shape": "Sdu",
            "locationName": "preset"
          }
        }
      }
    },
    "CreateQueue": {
      "http": {
        "requestUri": "/2017-08-29/queues",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "Name": {
            "locationName": "name"
          },
          "PricingPlan": {
            "locationName": "pricingPlan"
          },
          "ReservationPlanSettings": {
            "shape": "Sdx",
            "locationName": "reservationPlanSettings"
          },
          "Status": {
            "locationName": "status"
          },
          "Tags": {
            "shape": "Sd5",
            "locationName": "tags"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Queue": {
            "shape": "Se2",
            "locationName": "queue"
          }
        }
      }
    },
    "DeleteJobTemplate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-08-29/jobTemplates/{name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "locationName": "name",
            "location": "uri"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePreset": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-08-29/presets/{name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "locationName": "name",
            "location": "uri"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteQueue": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-08-29/queues/{name}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "locationName": "name",
            "location": "uri"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeEndpoints": {
      "http": {
        "requestUri": "/2017-08-29/endpoints",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "locationName": "maxResults",
            "type": "integer"
          },
          "Mode": {
            "locationName": "mode"
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoints": {
            "locationName": "endpoints",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Url": {
                  "locationName": "url"
                }
              }
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "DisassociateCertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2017-08-29/certificates/{arn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn",
            "location": "uri"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetJob": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/jobs/{id}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Id": {
            "locationName": "id",
            "location": "uri"
          }
        },
        "required": [
          "Id"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "shape": "Sd7",
            "locationName": "job"
          }
        }
      }
    },
    "GetJobTemplate": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/jobTemplates/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "locationName": "name",
            "location": "uri"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JobTemplate": {
            "shape": "Sdn",
            "locationName": "jobTemplate"
          }
        }
      }
    },
    "GetPreset": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/presets/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "locationName": "name",
            "location": "uri"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Preset": {
            "shape": "Sdu",
            "locationName": "preset"
          }
        }
      }
    },
    "GetQueue": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/queues/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Name": {
            "locationName": "name",
            "location": "uri"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Queue": {
            "shape": "Se2",
            "locationName": "queue"
          }
        }
      }
    },
    "ListJobTemplates": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/jobTemplates",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Category": {
            "locationName": "category",
            "location": "querystring"
          },
          "ListBy": {
            "locationName": "listBy",
            "location": "querystring"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "location": "querystring",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken",
            "location": "querystring"
          },
          "Order": {
            "locationName": "order",
            "location": "querystring"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobTemplates": {
            "locationName": "jobTemplates",
            "type": "list",
            "member": {
              "shape": "Sdn"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/jobs",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "locationName": "maxResults",
            "location": "querystring",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken",
            "location": "querystring"
          },
          "Order": {
            "locationName": "order",
            "location": "querystring"
          },
          "Queue": {
            "locationName": "queue",
            "location": "querystring"
          },
          "Status": {
            "locationName": "status",
            "location": "querystring"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Jobs": {
            "locationName": "jobs",
            "type": "list",
            "member": {
              "shape": "Sd7"
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListPresets": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/presets",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Category": {
            "locationName": "category",
            "location": "querystring"
          },
          "ListBy": {
            "locationName": "listBy",
            "location": "querystring"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "location": "querystring",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken",
            "location": "querystring"
          },
          "Order": {
            "locationName": "order",
            "location": "querystring"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Presets": {
            "locationName": "presets",
            "type": "list",
            "member": {
              "shape": "Sdu"
            }
          }
        }
      }
    },
    "ListQueues": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/queues",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ListBy": {
            "locationName": "listBy",
            "location": "querystring"
          },
          "MaxResults": {
            "locationName": "maxResults",
            "location": "querystring",
            "type": "integer"
          },
          "NextToken": {
            "locationName": "nextToken",
            "location": "querystring"
          },
          "Order": {
            "locationName": "order",
            "location": "querystring"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {
            "locationName": "nextToken"
          },
          "Queues": {
            "locationName": "queues",
            "type": "list",
            "member": {
              "shape": "Se2"
            }
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/2017-08-29/tags/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn",
            "location": "uri"
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceTags": {
            "locationName": "resourceTags",
            "type": "structure",
            "members": {
              "Arn": {
                "locationName": "arn"
              },
              "Tags": {
                "shape": "Sd5",
                "locationName": "tags"
              }
            }
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/2017-08-29/tags",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn"
          },
          "Tags": {
            "shape": "Sd5",
            "locationName": "tags"
          }
        },
        "required": [
          "Arn",
          "Tags"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-08-29/tags/{arn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Arn": {
            "locationName": "arn",
            "location": "uri"
          },
          "TagKeys": {
            "locationName": "tagKeys",
            "type": "list",
            "member": {}
          }
        },
        "required": [
          "Arn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateJobTemplate": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-08-29/jobTemplates/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "AccelerationSettings": {
            "shape": "S7",
            "locationName": "accelerationSettings"
          },
          "Category": {
            "locationName": "category"
          },
          "Description": {
            "locationName": "description"
          },
          "Name": {
            "locationName": "name",
            "location": "uri"
          },
          "Priority": {
            "locationName": "priority",
            "type": "integer"
          },
          "Queue": {
            "locationName": "queue"
          },
          "Settings": {
            "shape": "Sdj",
            "locationName": "settings"
          },
          "StatusUpdateInterval": {
            "locationName": "statusUpdateInterval"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "JobTemplate": {
            "shape": "Sdn",
            "locationName": "jobTemplate"
          }
        }
      }
    },
    "UpdatePreset": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-08-29/presets/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Category": {
            "locationName": "category"
          },
          "Description": {
            "locationName": "description"
          },
          "Name": {
            "locationName": "name",
            "location": "uri"
          },
          "Settings": {
            "shape": "Sdq",
            "locationName": "settings"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Preset": {
            "shape": "Sdu",
            "locationName": "preset"
          }
        }
      }
    },
    "UpdateQueue": {
      "http": {
        "method": "PUT",
        "requestUri": "/2017-08-29/queues/{name}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "Name": {
            "locationName": "name",
            "location": "uri"
          },
          "ReservationPlanSettings": {
            "shape": "Sdx",
            "locationName": "reservationPlanSettings"
          },
          "Status": {
            "locationName": "status"
          }
        },
        "required": [
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Queue": {
            "shape": "Se2",
            "locationName": "queue"
          }
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "members": {
        "Mode": {
          "locationName": "mode"
        }
      },
      "required": [
        "Mode"
      ]
    },
    "Sb": {
      "type": "structure",
      "members": {
        "AdAvailOffset": {
          "locationName": "adAvailOffset",
          "type": "integer"
        },
        "AvailBlanking": {
          "shape": "Sd",
          "locationName": "availBlanking"
        },
        "Esam": {
          "shape": "Sf",
          "locationName": "esam"
        },
        "Inputs": {
          "locationName": "inputs",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "AudioSelectorGroups": {
                "shape": "Sn",
                "locationName": "audioSelectorGroups"
              },
              "AudioSelectors": {
                "shape": "Sr",
                "locationName": "audioSelectors"
              },
              "CaptionSelectors": {
                "shape": "S1a",
                "locationName": "captionSelectors"
              },
              "Crop": {
                "shape": "S1q",
                "locationName": "crop"
              },
              "DeblockFilter": {
                "locationName": "deblockFilter"
              },
              "DecryptionSettings": {
                "locationName": "decryptionSettings",
                "type": "structure",
                "members": {
                  "DecryptionMode": {
                    "locationName": "decryptionMode"
                  },
                  "EncryptedDecryptionKey": {
                    "locationName": "encryptedDecryptionKey"
                  },
                  "InitializationVector": {
                    "locationName": "initializationVector"
                  },
                  "KmsKeyRegion": {
                    "locationName": "kmsKeyRegion"
                  }
                }
              },
              "DenoiseFilter": {
                "locationName": "denoiseFilter"
              },
              "FileInput": {
                "locationName": "fileInput"
              },
              "FilterEnable": {
                "locationName": "filterEnable"
              },
              "FilterStrength": {
                "locationName": "filterStrength",
                "type": "integer"
              },
              "ImageInserter": {
                "shape": "S23",
                "locationName": "imageInserter"
              },
              "InputClippings": {
                "shape": "S2a",
                "locationName": "inputClippings"
              },
              "Position": {
                "shape": "S1q",
                "locationName": "position"
              },
              "ProgramNumber": {
                "locationName": "programNumber",
                "type": "integer"
              },
              "PsiControl": {
                "locationName": "psiControl"
              },
              "SupplementalImps": {
                "locationName": "supplementalImps",
                "type": "list",
                "member": {}
              },
              "TimecodeSource": {
                "locationName": "timecodeSource"
              },
              "VideoSelector": {
                "shape": "S2h",
                "locationName": "videoSelector"
              }
            }
          }
        },
        "MotionImageInserter": {
          "shape": "S2o",
          "locationName": "motionImageInserter"
        },
        "NielsenConfiguration": {
          "shape": "S2x",
          "locationName": "nielsenConfiguration"
        },
        "OutputGroups": {
          "shape": "S2z",
          "locationName": "outputGroups"
        },
        "TimecodeConfig": {
          "shape": "Scx",
          "locationName": "timecodeConfig"
        },
        "TimedMetadataInsertion": {
          "shape": "Sd0",
          "locationName": "timedMetadataInsertion"
        }
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "AvailBlankingImage": {
          "locationName": "availBlankingImage"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "ManifestConfirmConditionNotification": {
          "locationName": "manifestConfirmConditionNotification",
          "type": "structure",
          "members": {
            "MccXml": {
              "locationName": "mccXml"
            }
          }
        },
        "ResponseSignalPreroll": {
          "locationName": "responseSignalPreroll",
          "type": "integer"
        },
        "SignalProcessingNotification": {
          "locationName": "signalProcessingNotification",
          "type": "structure",
          "members": {
            "SccXml": {
              "locationName": "sccXml"
            }
          }
        }
      }
    },
    "Sn": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "AudioSelectorNames": {
            "locationName": "audioSelectorNames",
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Sr": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "CustomLanguageCode": {
            "locationName": "customLanguageCode"
          },
          "DefaultSelection": {
            "locationName": "defaultSelection"
          },
          "ExternalAudioFileInput": {
            "locationName": "externalAudioFileInput"
          },
          "LanguageCode": {
            "locationName": "languageCode"
          },
          "Offset": {
            "locationName": "offset",
            "type": "integer"
          },
          "Pids": {
            "shape": "Sy",
            "locationName": "pids"
          },
          "ProgramSelection": {
            "locationName": "programSelection",
            "type": "integer"
          },
          "RemixSettings": {
            "shape": "S11",
            "locationName": "remixSettings"
          },
          "SelectorType": {
            "locationName": "selectorType"
          },
          "Tracks": {
            "shape": "Sy",
            "locationName": "tracks"
          }
        }
      }
    },
    "Sy": {
      "type": "list",
      "member": {
        "type": "integer"
      }
    },
    "S11": {
      "type": "structure",
      "members": {
        "ChannelMapping": {
          "locationName": "channelMapping",
          "type": "structure",
          "members": {
            "OutputChannels": {
              "locationName": "outputChannels",
              "type": "list",
              "member": {
                "type": "structure",
                "members": {
                  "InputChannels": {
                    "locationName": "inputChannels",
                    "type": "list",
                    "member": {
                      "type": "integer"
                    }
                  }
                }
              }
            }
          }
        },
        "ChannelsIn": {
          "locationName": "channelsIn",
          "type": "integer"
        },
        "ChannelsOut": {
          "locationName": "channelsOut",
          "type": "integer"
        }
      }
    },
    "S1a": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "CustomLanguageCode": {
            "locationName": "customLanguageCode"
          },
          "LanguageCode": {
            "locationName": "languageCode"
          },
          "SourceSettings": {
            "locationName": "sourceSettings",
            "type": "structure",
            "members": {
              "AncillarySourceSettings": {
                "locationName": "ancillarySourceSettings",
                "type": "structure",
                "members": {
                  "SourceAncillaryChannelNumber": {
                    "locationName": "sourceAncillaryChannelNumber",
                    "type": "integer"
                  }
                }
              },
              "DvbSubSourceSettings": {
                "locationName": "dvbSubSourceSettings",
                "type": "structure",
                "members": {
                  "Pid": {
                    "locationName": "pid",
                    "type": "integer"
                  }
                }
              },
              "EmbeddedSourceSettings": {
                "locationName": "embeddedSourceSettings",
                "type": "structure",
                "members": {
                  "Convert608To708": {
                    "locationName": "convert608To708"
                  },
                  "Source608ChannelNumber": {
                    "locationName": "source608ChannelNumber",
                    "type": "integer"
                  },
                  "Source608TrackNumber": {
                    "locationName": "source608TrackNumber",
                    "type": "integer"
                  }
                }
              },
              "FileSourceSettings": {
                "locationName": "fileSourceSettings",
                "type": "structure",
                "members": {
                  "Convert608To708": {
                    "locationName": "convert608To708"
                  },
                  "SourceFile": {
                    "locationName": "sourceFile"
                  },
                  "TimeDelta": {
                    "locationName": "timeDelta",
                    "type": "integer"
                  }
                }
              },
              "SourceType": {
                "locationName": "sourceType"
              },
              "TeletextSourceSettings": {
                "locationName": "teletextSourceSettings",
                "type": "structure",
                "members": {
                  "PageNumber": {
                    "locationName": "pageNumber"
                  }
                }
              },
              "TrackSourceSettings": {
                "locationName": "trackSourceSettings",
                "type": "structure",
                "members": {
                  "TrackNumber": {
                    "locationName": "trackNumber",
                    "type": "integer"
                  }
                }
              }
            }
          }
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "Height": {
          "locationName": "height",
          "type": "integer"
        },
        "Width": {
          "locationName": "width",
          "type": "integer"
        },
        "X": {
          "locationName": "x",
          "type": "integer"
        },
        "Y": {
          "locationName": "y",
          "type": "integer"
        }
      }
    },
    "S23": {
      "type": "structure",
      "members": {
        "InsertableImages": {
          "locationName": "insertableImages",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Duration": {
                "locationName": "duration",
                "type": "integer"
              },
              "FadeIn": {
                "locationName": "fadeIn",
                "type": "integer"
              },
              "FadeOut": {
                "locationName": "fadeOut",
                "type": "integer"
              },
              "Height": {
                "locationName": "height",
                "type": "integer"
              },
              "ImageInserterInput": {
                "locationName": "imageInserterInput"
              },
              "ImageX": {
                "locationName": "imageX",
                "type": "integer"
              },
              "ImageY": {
                "locationName": "imageY",
                "type": "integer"
              },
              "Layer": {
                "locationName": "layer",
                "type": "integer"
              },
              "Opacity": {
                "locationName": "opacity",
                "type": "integer"
              },
              "StartTime": {
                "locationName": "startTime"
              },
              "Width": {
                "locationName": "width",
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S2a": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "EndTimecode": {
            "locationName": "endTimecode"
          },
          "StartTimecode": {
            "locationName": "startTimecode"
          }
        }
      }
    },
    "S2h": {
      "type": "structure",
      "members": {
        "ColorSpace": {
          "locationName": "colorSpace"
        },
        "ColorSpaceUsage": {
          "locationName": "colorSpaceUsage"
        },
        "Hdr10Metadata": {
          "shape": "S2k",
          "locationName": "hdr10Metadata"
        },
        "Pid": {
          "locationName": "pid",
          "type": "integer"
        },
        "ProgramNumber": {
          "locationName": "programNumber",
          "type": "integer"
        },
        "Rotate": {
          "locationName": "rotate"
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "BluePrimaryX": {
          "locationName": "bluePrimaryX",
          "type": "integer"
        },
        "BluePrimaryY": {
          "locationName": "bluePrimaryY",
          "type": "integer"
        },
        "GreenPrimaryX": {
          "locationName": "greenPrimaryX",
          "type": "integer"
        },
        "GreenPrimaryY": {
          "locationName": "greenPrimaryY",
          "type": "integer"
        },
        "MaxContentLightLevel": {
          "locationName": "maxContentLightLevel",
          "type": "integer"
        },
        "MaxFrameAverageLightLevel": {
          "locationName": "maxFrameAverageLightLevel",
          "type": "integer"
        },
        "MaxLuminance": {
          "locationName": "maxLuminance",
          "type": "integer"
        },
        "MinLuminance": {
          "locationName": "minLuminance",
          "type": "integer"
        },
        "RedPrimaryX": {
          "locationName": "redPrimaryX",
          "type": "integer"
        },
        "RedPrimaryY": {
          "locationName": "redPrimaryY",
          "type": "integer"
        },
        "WhitePointX": {
          "locationName": "whitePointX",
          "type": "integer"
        },
        "WhitePointY": {
          "locationName": "whitePointY",
          "type": "integer"
        }
      }
    },
    "S2o": {
      "type": "structure",
      "members": {
        "Framerate": {
          "locationName": "framerate",
          "type": "structure",
          "members": {
            "FramerateDenominator": {
              "locationName": "framerateDenominator",
              "type": "integer"
            },
            "FramerateNumerator": {
              "locationName": "framerateNumerator",
              "type": "integer"
            }
          }
        },
        "Input": {
          "locationName": "input"
        },
        "InsertionMode": {
          "locationName": "insertionMode"
        },
        "Offset": {
          "locationName": "offset",
          "type": "structure",
          "members": {
            "ImageX": {
              "locationName": "imageX",
              "type": "integer"
            },
            "ImageY": {
              "locationName": "imageY",
              "type": "integer"
            }
          }
        },
        "Playback": {
          "locationName": "playback"
        },
        "StartTime": {
          "locationName": "startTime"
        }
      }
    },
    "S2x": {
      "type": "structure",
      "members": {
        "BreakoutCode": {
          "locationName": "breakoutCode",
          "type": "integer"
        },
        "DistributorId": {
          "locationName": "distributorId"
        }
      }
    },
    "S2z": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "CustomName": {
            "locationName": "customName"
          },
          "Name": {
            "locationName": "name"
          },
          "OutputGroupSettings": {
            "locationName": "outputGroupSettings",
            "type": "structure",
            "members": {
              "CmafGroupSettings": {
                "locationName": "cmafGroupSettings",
                "type": "structure",
                "members": {
                  "BaseUrl": {
                    "locationName": "baseUrl"
                  },
                  "ClientCache": {
                    "locationName": "clientCache"
                  },
                  "CodecSpecification": {
                    "locationName": "codecSpecification"
                  },
                  "Destination": {
                    "locationName": "destination"
                  },
                  "DestinationSettings": {
                    "shape": "S36",
                    "locationName": "destinationSettings"
                  },
                  "Encryption": {
                    "locationName": "encryption",
                    "type": "structure",
                    "members": {
                      "ConstantInitializationVector": {
                        "locationName": "constantInitializationVector"
                      },
                      "EncryptionMethod": {
                        "locationName": "encryptionMethod"
                      },
                      "InitializationVectorInManifest": {
                        "locationName": "initializationVectorInManifest"
                      },
                      "SpekeKeyProvider": {
                        "locationName": "spekeKeyProvider",
                        "type": "structure",
                        "members": {
                          "CertificateArn": {
                            "locationName": "certificateArn"
                          },
                          "DashSignaledSystemIds": {
                            "shape": "S3h",
                            "locationName": "dashSignaledSystemIds"
                          },
                          "HlsSignaledSystemIds": {
                            "shape": "S3h",
                            "locationName": "hlsSignaledSystemIds"
                          },
                          "ResourceId": {
                            "locationName": "resourceId"
                          },
                          "Url": {
                            "locationName": "url"
                          }
                        }
                      },
                      "StaticKeyProvider": {
                        "shape": "S3l",
                        "locationName": "staticKeyProvider"
                      },
                      "Type": {
                        "locationName": "type"
                      }
                    }
                  },
                  "FragmentLength": {
                    "locationName": "fragmentLength",
                    "type": "integer"
                  },
                  "ManifestCompression": {
                    "locationName": "manifestCompression"
                  },
                  "ManifestDurationFormat": {
                    "locationName": "manifestDurationFormat"
                  },
                  "MinBufferTime": {
                    "locationName": "minBufferTime",
                    "type": "integer"
                  },
                  "MinFinalSegmentLength": {
                    "locationName": "minFinalSegmentLength",
                    "type": "double"
                  },
                  "SegmentControl": {
                    "locationName": "segmentControl"
                  },
                  "SegmentLength": {
                    "locationName": "segmentLength",
                    "type": "integer"
                  },
                  "StreamInfResolution": {
                    "locationName": "streamInfResolution"
                  },
                  "WriteDashManifest": {
                    "locationName": "writeDashManifest"
                  },
                  "WriteHlsManifest": {
                    "locationName": "writeHlsManifest"
                  }
                }
              },
              "DashIsoGroupSettings": {
                "locationName": "dashIsoGroupSettings",
                "type": "structure",
                "members": {
                  "BaseUrl": {
                    "locationName": "baseUrl"
                  },
                  "Destination": {
                    "locationName": "destination"
                  },
                  "DestinationSettings": {
                    "shape": "S36",
                    "locationName": "destinationSettings"
                  },
                  "Encryption": {
                    "locationName": "encryption",
                    "type": "structure",
                    "members": {
                      "PlaybackDeviceCompatibility": {
                        "locationName": "playbackDeviceCompatibility"
                      },
                      "SpekeKeyProvider": {
                        "shape": "S40",
                        "locationName": "spekeKeyProvider"
                      }
                    }
                  },
                  "FragmentLength": {
                    "locationName": "fragmentLength",
                    "type": "integer"
                  },
                  "HbbtvCompliance": {
                    "locationName": "hbbtvCompliance"
                  },
                  "MinBufferTime": {
                    "locationName": "minBufferTime",
                    "type": "integer"
                  },
                  "SegmentControl": {
                    "locationName": "segmentControl"
                  },
                  "SegmentLength": {
                    "locationName": "segmentLength",
                    "type": "integer"
                  },
                  "WriteSegmentTimelineInRepresentation": {
                    "locationName": "writeSegmentTimelineInRepresentation"
                  }
                }
              },
              "FileGroupSettings": {
                "locationName": "fileGroupSettings",
                "type": "structure",
                "members": {
                  "Destination": {
                    "locationName": "destination"
                  },
                  "DestinationSettings": {
                    "shape": "S36",
                    "locationName": "destinationSettings"
                  }
                }
              },
              "HlsGroupSettings": {
                "locationName": "hlsGroupSettings",
                "type": "structure",
                "members": {
                  "AdMarkers": {
                    "locationName": "adMarkers",
                    "type": "list",
                    "member": {}
                  },
                  "BaseUrl": {
                    "locationName": "baseUrl"
                  },
                  "CaptionLanguageMappings": {
                    "locationName": "captionLanguageMappings",
                    "type": "list",
                    "member": {
                      "type": "structure",
                      "members": {
                        "CaptionChannel": {
                          "locationName": "captionChannel",
                          "type": "integer"
                        },
                        "CustomLanguageCode": {
                          "locationName": "customLanguageCode"
                        },
                        "LanguageCode": {
                          "locationName": "languageCode"
                        },
                        "LanguageDescription": {
                          "locationName": "languageDescription"
                        }
                      }
                    }
                  },
                  "CaptionLanguageSetting": {
                    "locationName": "captionLanguageSetting"
                  },
                  "ClientCache": {
                    "locationName": "clientCache"
                  },
                  "CodecSpecification": {
                    "locationName": "codecSpecification"
                  },
                  "Destination": {
                    "locationName": "destination"
                  },
                  "DestinationSettings": {
                    "shape": "S36",
                    "locationName": "destinationSettings"
                  },
                  "DirectoryStructure": {
                    "locationName": "directoryStructure"
                  },
                  "Encryption": {
                    "locationName": "encryption",
                    "type": "structure",
                    "members": {
                      "ConstantInitializationVector": {
                        "locationName": "constantInitializationVector"
                      },
                      "EncryptionMethod": {
                        "locationName": "encryptionMethod"
                      },
                      "InitializationVectorInManifest": {
                        "locationName": "initializationVectorInManifest"
                      },
                      "OfflineEncrypted": {
                        "locationName": "offlineEncrypted"
                      },
                      "SpekeKeyProvider": {
                        "shape": "S40",
                        "locationName": "spekeKeyProvider"
                      },
                      "StaticKeyProvider": {
                        "shape": "S3l",
                        "locationName": "staticKeyProvider"
                      },
                      "Type": {
                        "locationName": "type"
                      }
                    }
                  },
                  "ManifestCompression": {
                    "locationName": "manifestCompression"
                  },
                  "ManifestDurationFormat": {
                    "locationName": "manifestDurationFormat"
                  },
                  "MinFinalSegmentLength": {
                    "locationName": "minFinalSegmentLength",
                    "type": "double"
                  },
                  "MinSegmentLength": {
                    "locationName": "minSegmentLength",
                    "type": "integer"
                  },
                  "OutputSelection": {
                    "locationName": "outputSelection"
                  },
                  "ProgramDateTime": {
                    "locationName": "programDateTime"
                  },
                  "ProgramDateTimePeriod": {
                    "locationName": "programDateTimePeriod",
                    "type": "integer"
                  },
                  "SegmentControl": {
                    "locationName": "segmentControl"
                  },
                  "SegmentLength": {
                    "locationName": "segmentLength",
                    "type": "integer"
                  },
                  "SegmentsPerSubdirectory": {
                    "locationName": "segmentsPerSubdirectory",
                    "type": "integer"
                  },
                  "StreamInfResolution": {
                    "locationName": "streamInfResolution"
                  },
                  "TimedMetadataId3Frame": {
                    "locationName": "timedMetadataId3Frame"
                  },
                  "TimedMetadataId3Period": {
                    "locationName": "timedMetadataId3Period",
                    "type": "integer"
                  },
                  "TimestampDeltaMilliseconds": {
                    "locationName": "timestampDeltaMilliseconds",
                    "type": "integer"
                  }
                }
              },
              "MsSmoothGroupSettings": {
                "locationName": "msSmoothGroupSettings",
                "type": "structure",
                "members": {
                  "AudioDeduplication": {
                    "locationName": "audioDeduplication"
                  },
                  "Destination": {
                    "locationName": "destination"
                  },
                  "DestinationSettings": {
                    "shape": "S36",
                    "locationName": "destinationSettings"
                  },
                  "Encryption": {
                    "locationName": "encryption",
                    "type": "structure",
                    "members": {
                      "SpekeKeyProvider": {
                        "shape": "S40",
                        "locationName": "spekeKeyProvider"
                      }
                    }
                  },
                  "FragmentLength": {
                    "locationName": "fragmentLength",
                    "type": "integer"
                  },
                  "ManifestEncoding": {
                    "locationName": "manifestEncoding"
                  }
                }
              },
              "Type": {
                "locationName": "type"
              }
            }
          },
          "Outputs": {
            "locationName": "outputs",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AudioDescriptions": {
                  "shape": "S50",
                  "locationName": "audioDescriptions"
                },
                "CaptionDescriptions": {
                  "locationName": "captionDescriptions",
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "CaptionSelectorName": {
                        "locationName": "captionSelectorName"
                      },
                      "CustomLanguageCode": {
                        "locationName": "customLanguageCode"
                      },
                      "DestinationSettings": {
                        "shape": "S72",
                        "locationName": "destinationSettings"
                      },
                      "LanguageCode": {
                        "locationName": "languageCode"
                      },
                      "LanguageDescription": {
                        "locationName": "languageDescription"
                      }
                    }
                  }
                },
                "ContainerSettings": {
                  "shape": "S7v",
                  "locationName": "containerSettings"
                },
                "Extension": {
                  "locationName": "extension"
                },
                "NameModifier": {
                  "locationName": "nameModifier"
                },
                "OutputSettings": {
                  "locationName": "outputSettings",
                  "type": "structure",
                  "members": {
                    "HlsSettings": {
                      "locationName": "hlsSettings",
                      "type": "structure",
                      "members": {
                        "AudioGroupId": {
                          "locationName": "audioGroupId"
                        },
                        "AudioOnlyContainer": {
                          "locationName": "audioOnlyContainer"
                        },
                        "AudioRenditionSets": {
                          "locationName": "audioRenditionSets"
                        },
                        "AudioTrackType": {
                          "locationName": "audioTrackType"
                        },
                        "IFrameOnlyManifest": {
                          "locationName": "iFrameOnlyManifest"
                        },
                        "SegmentModifier": {
                          "locationName": "segmentModifier"
                        }
                      }
                    }
                  }
                },
                "Preset": {
                  "locationName": "preset"
                },
                "VideoDescription": {
                  "shape": "S9c",
                  "locationName": "videoDescription"
                }
              }
            }
          }
        }
      }
    },
    "S36": {
      "type": "structure",
      "members": {
        "S3Settings": {
          "locationName": "s3Settings",
          "type": "structure",
          "members": {
            "Encryption": {
              "locationName": "encryption",
              "type": "structure",
              "members": {
                "EncryptionType": {
                  "locationName": "encryptionType"
                },
                "KmsKeyArn": {
                  "locationName": "kmsKeyArn"
                }
              }
            }
          }
        }
      }
    },
    "S3h": {
      "type": "list",
      "member": {}
    },
    "S3l": {
      "type": "structure",
      "members": {
        "KeyFormat": {
          "locationName": "keyFormat"
        },
        "KeyFormatVersions": {
          "locationName": "keyFormatVersions"
        },
        "StaticKeyValue": {
          "locationName": "staticKeyValue"
        },
        "Url": {
          "locationName": "url"
        }
      }
    },
    "S40": {
      "type": "structure",
      "members": {
        "CertificateArn": {
          "locationName": "certificateArn"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "SystemIds": {
          "locationName": "systemIds",
          "type": "list",
          "member": {}
        },
        "Url": {
          "locationName": "url"
        }
      }
    },
    "S50": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "AudioNormalizationSettings": {
            "locationName": "audioNormalizationSettings",
            "type": "structure",
            "members": {
              "Algorithm": {
                "locationName": "algorithm"
              },
              "AlgorithmControl": {
                "locationName": "algorithmControl"
              },
              "CorrectionGateLevel": {
                "locationName": "correctionGateLevel",
                "type": "integer"
              },
              "LoudnessLogging": {
                "locationName": "loudnessLogging"
              },
              "PeakCalculation": {
                "locationName": "peakCalculation"
              },
              "TargetLkfs": {
                "locationName": "targetLkfs",
                "type": "double"
              }
            }
          },
          "AudioSourceName": {
            "locationName": "audioSourceName"
          },
          "AudioType": {
            "locationName": "audioType",
            "type": "integer"
          },
          "AudioTypeControl": {
            "locationName": "audioTypeControl"
          },
          "CodecSettings": {
            "locationName": "codecSettings",
            "type": "structure",
            "members": {
              "AacSettings": {
                "locationName": "aacSettings",
                "type": "structure",
                "members": {
                  "AudioDescriptionBroadcasterMix": {
                    "locationName": "audioDescriptionBroadcasterMix"
                  },
                  "Bitrate": {
                    "locationName": "bitrate",
                    "type": "integer"
                  },
                  "CodecProfile": {
                    "locationName": "codecProfile"
                  },
                  "CodingMode": {
                    "locationName": "codingMode"
                  },
                  "RateControlMode": {
                    "locationName": "rateControlMode"
                  },
                  "RawFormat": {
                    "locationName": "rawFormat"
                  },
                  "SampleRate": {
                    "locationName": "sampleRate",
                    "type": "integer"
                  },
                  "Specification": {
                    "locationName": "specification"
                  },
                  "VbrQuality": {
                    "locationName": "vbrQuality"
                  }
                }
              },
              "Ac3Settings": {
                "locationName": "ac3Settings",
                "type": "structure",
                "members": {
                  "Bitrate": {
                    "locationName": "bitrate",
                    "type": "integer"
                  },
                  "BitstreamMode": {
                    "locationName": "bitstreamMode"
                  },
                  "CodingMode": {
                    "locationName": "codingMode"
                  },
                  "Dialnorm": {
                    "locationName": "dialnorm",
                    "type": "integer"
                  },
                  "DynamicRangeCompressionProfile": {
                    "locationName": "dynamicRangeCompressionProfile"
                  },
                  "LfeFilter": {
                    "locationName": "lfeFilter"
                  },
                  "MetadataControl": {
                    "locationName": "metadataControl"
                  },
                  "SampleRate": {
                    "locationName": "sampleRate",
                    "type": "integer"
                  }
                }
              },
              "AiffSettings": {
                "locationName": "aiffSettings",
                "type": "structure",
                "members": {
                  "BitDepth": {
                    "locationName": "bitDepth",
                    "type": "integer"
                  },
                  "Channels": {
                    "locationName": "channels",
                    "type": "integer"
                  },
                  "SampleRate": {
                    "locationName": "sampleRate",
                    "type": "integer"
                  }
                }
              },
              "Codec": {
                "locationName": "codec"
              },
              "Eac3AtmosSettings": {
                "locationName": "eac3AtmosSettings",
                "type": "structure",
                "members": {
                  "Bitrate": {
                    "locationName": "bitrate",
                    "type": "integer"
                  },
                  "BitstreamMode": {
                    "locationName": "bitstreamMode"
                  },
                  "CodingMode": {
                    "locationName": "codingMode"
                  },
                  "DialogueIntelligence": {
                    "locationName": "dialogueIntelligence"
                  },
                  "DynamicRangeCompressionLine": {
                    "locationName": "dynamicRangeCompressionLine"
                  },
                  "DynamicRangeCompressionRf": {
                    "locationName": "dynamicRangeCompressionRf"
                  },
                  "LoRoCenterMixLevel": {
                    "locationName": "loRoCenterMixLevel",
                    "type": "double"
                  },
                  "LoRoSurroundMixLevel": {
                    "locationName": "loRoSurroundMixLevel",
                    "type": "double"
                  },
                  "LtRtCenterMixLevel": {
                    "locationName": "ltRtCenterMixLevel",
                    "type": "double"
                  },
                  "LtRtSurroundMixLevel": {
                    "locationName": "ltRtSurroundMixLevel",
                    "type": "double"
                  },
                  "MeteringMode": {
                    "locationName": "meteringMode"
                  },
                  "SampleRate": {
                    "locationName": "sampleRate",
                    "type": "integer"
                  },
                  "SpeechThreshold": {
                    "locationName": "speechThreshold",
                    "type": "integer"
                  },
                  "StereoDownmix": {
                    "locationName": "stereoDownmix"
                  },
                  "SurroundExMode": {
                    "locationName": "surroundExMode"
                  }
                }
              },
              "Eac3Settings": {
                "locationName": "eac3Settings",
                "type": "structure",
                "members": {
                  "AttenuationControl": {
                    "locationName": "attenuationControl"
                  },
                  "Bitrate": {
                    "locationName": "bitrate",
                    "type": "integer"
                  },
                  "BitstreamMode": {
                    "locationName": "bitstreamMode"
                  },
                  "CodingMode": {
                    "locationName": "codingMode"
                  },
                  "DcFilter": {
                    "locationName": "dcFilter"
                  },
                  "Dialnorm": {
                    "locationName": "dialnorm",
                    "type": "integer"
                  },
                  "DynamicRangeCompressionLine": {
                    "locationName": "dynamicRangeCompressionLine"
                  },
                  "DynamicRangeCompressionRf": {
                    "locationName": "dynamicRangeCompressionRf"
                  },
                  "LfeControl": {
                    "locationName": "lfeControl"
                  },
                  "LfeFilter": {
                    "locationName": "lfeFilter"
                  },
                  "LoRoCenterMixLevel": {
                    "locationName": "loRoCenterMixLevel",
                    "type": "double"
                  },
                  "LoRoSurroundMixLevel": {
                    "locationName": "loRoSurroundMixLevel",
                    "type": "double"
                  },
                  "LtRtCenterMixLevel": {
                    "locationName": "ltRtCenterMixLevel",
                    "type": "double"
                  },
                  "LtRtSurroundMixLevel": {
                    "locationName": "ltRtSurroundMixLevel",
                    "type": "double"
                  },
                  "MetadataControl": {
                    "locationName": "metadataControl"
                  },
                  "PassthroughControl": {
                    "locationName": "passthroughControl"
                  },
                  "PhaseControl": {
                    "locationName": "phaseControl"
                  },
                  "SampleRate": {
                    "locationName": "sampleRate",
                    "type": "integer"
                  },
                  "StereoDownmix": {
                    "locationName": "stereoDownmix"
                  },
                  "SurroundExMode": {
                    "locationName": "surroundExMode"
                  },
                  "SurroundMode": {
                    "locationName": "surroundMode"
                  }
                }
              },
              "Mp2Settings": {
                "locationName": "mp2Settings",
                "type": "structure",
                "members": {
                  "Bitrate": {
                    "locationName": "bitrate",
                    "type": "integer"
                  },
                  "Channels": {
                    "locationName": "channels",
                    "type": "integer"
                  },
                  "SampleRate": {
                    "locationName": "sampleRate",
                    "type": "integer"
                  }
                }
              },
              "WavSettings": {
                "locationName": "wavSettings",
                "type": "structure",
                "members": {
                  "BitDepth": {
                    "locationName": "bitDepth",
                    "type": "integer"
                  },
                  "Channels": {
                    "locationName": "channels",
                    "type": "integer"
                  },
                  "Format": {
                    "locationName": "format"
                  },
                  "SampleRate": {
                    "locationName": "sampleRate",
                    "type": "integer"
                  }
                }
              }
            }
          },
          "CustomLanguageCode": {
            "locationName": "customLanguageCode"
          },
          "LanguageCode": {
            "locationName": "languageCode"
          },
          "LanguageCodeControl": {
            "locationName": "languageCodeControl"
          },
          "RemixSettings": {
            "shape": "S11",
            "locationName": "remixSettings"
          },
          "StreamName": {
            "locationName": "streamName"
          }
        }
      }
    },
    "S72": {
      "type": "structure",
      "members": {
        "BurninDestinationSettings": {
          "locationName": "burninDestinationSettings",
          "type": "structure",
          "members": {
            "Alignment": {
              "locationName": "alignment"
            },
            "BackgroundColor": {
              "locationName": "backgroundColor"
            },
            "BackgroundOpacity": {
              "locationName": "backgroundOpacity",
              "type": "integer"
            },
            "FontColor": {
              "locationName": "fontColor"
            },
            "FontOpacity": {
              "locationName": "fontOpacity",
              "type": "integer"
            },
            "FontResolution": {
              "locationName": "fontResolution",
              "type": "integer"
            },
            "FontScript": {
              "locationName": "fontScript"
            },
            "FontSize": {
              "locationName": "fontSize",
              "type": "integer"
            },
            "OutlineColor": {
              "locationName": "outlineColor"
            },
            "OutlineSize": {
              "locationName": "outlineSize",
              "type": "integer"
            },
            "ShadowColor": {
              "locationName": "shadowColor"
            },
            "ShadowOpacity": {
              "locationName": "shadowOpacity",
              "type": "integer"
            },
            "ShadowXOffset": {
              "locationName": "shadowXOffset",
              "type": "integer"
            },
            "ShadowYOffset": {
              "locationName": "shadowYOffset",
              "type": "integer"
            },
            "TeletextSpacing": {
              "locationName": "teletextSpacing"
            },
            "XPosition": {
              "locationName": "xPosition",
              "type": "integer"
            },
            "YPosition": {
              "locationName": "yPosition",
              "type": "integer"
            }
          }
        },
        "DestinationType": {
          "locationName": "destinationType"
        },
        "DvbSubDestinationSettings": {
          "locationName": "dvbSubDestinationSettings",
          "type": "structure",
          "members": {
            "Alignment": {
              "locationName": "alignment"
            },
            "BackgroundColor": {
              "locationName": "backgroundColor"
            },
            "BackgroundOpacity": {
              "locationName": "backgroundOpacity",
              "type": "integer"
            },
            "FontColor": {
              "locationName": "fontColor"
            },
            "FontOpacity": {
              "locationName": "fontOpacity",
              "type": "integer"
            },
            "FontResolution": {
              "locationName": "fontResolution",
              "type": "integer"
            },
            "FontScript": {
              "locationName": "fontScript"
            },
            "FontSize": {
              "locationName": "fontSize",
              "type": "integer"
            },
            "OutlineColor": {
              "locationName": "outlineColor"
            },
            "OutlineSize": {
              "locationName": "outlineSize",
              "type": "integer"
            },
            "ShadowColor": {
              "locationName": "shadowColor"
            },
            "ShadowOpacity": {
              "locationName": "shadowOpacity",
              "type": "integer"
            },
            "ShadowXOffset": {
              "locationName": "shadowXOffset",
              "type": "integer"
            },
            "ShadowYOffset": {
              "locationName": "shadowYOffset",
              "type": "integer"
            },
            "TeletextSpacing": {
              "locationName": "teletextSpacing"
            },
            "XPosition": {
              "locationName": "xPosition",
              "type": "integer"
            },
            "YPosition": {
              "locationName": "yPosition",
              "type": "integer"
            }
          }
        },
        "EmbeddedDestinationSettings": {
          "locationName": "embeddedDestinationSettings",
          "type": "structure",
          "members": {
            "Destination608ChannelNumber": {
              "locationName": "destination608ChannelNumber",
              "type": "integer"
            },
            "Destination708ServiceNumber": {
              "locationName": "destination708ServiceNumber",
              "type": "integer"
            }
          }
        },
        "SccDestinationSettings": {
          "locationName": "sccDestinationSettings",
          "type": "structure",
          "members": {
            "Framerate": {
              "locationName": "framerate"
            }
          }
        },
        "TeletextDestinationSettings": {
          "locationName": "teletextDestinationSettings",
          "type": "structure",
          "members": {
            "PageNumber": {
              "locationName": "pageNumber"
            },
            "PageTypes": {
              "locationName": "pageTypes",
              "type": "list",
              "member": {}
            }
          }
        },
        "TtmlDestinationSettings": {
          "locationName": "ttmlDestinationSettings",
          "type": "structure",
          "members": {
            "StylePassthrough": {
              "locationName": "stylePassthrough"
            }
          }
        }
      }
    },
    "S7v": {
      "type": "structure",
      "members": {
        "Container": {
          "locationName": "container"
        },
        "F4vSettings": {
          "locationName": "f4vSettings",
          "type": "structure",
          "members": {
            "MoovPlacement": {
              "locationName": "moovPlacement"
            }
          }
        },
        "M2tsSettings": {
          "locationName": "m2tsSettings",
          "type": "structure",
          "members": {
            "AudioBufferModel": {
              "locationName": "audioBufferModel"
            },
            "AudioFramesPerPes": {
              "locationName": "audioFramesPerPes",
              "type": "integer"
            },
            "AudioPids": {
              "shape": "S81",
              "locationName": "audioPids"
            },
            "Bitrate": {
              "locationName": "bitrate",
              "type": "integer"
            },
            "BufferModel": {
              "locationName": "bufferModel"
            },
            "DvbNitSettings": {
              "locationName": "dvbNitSettings",
              "type": "structure",
              "members": {
                "NetworkId": {
                  "locationName": "networkId",
                  "type": "integer"
                },
                "NetworkName": {
                  "locationName": "networkName"
                },
                "NitInterval": {
                  "locationName": "nitInterval",
                  "type": "integer"
                }
              }
            },
            "DvbSdtSettings": {
              "locationName": "dvbSdtSettings",
              "type": "structure",
              "members": {
                "OutputSdt": {
                  "locationName": "outputSdt"
                },
                "SdtInterval": {
                  "locationName": "sdtInterval",
                  "type": "integer"
                },
                "ServiceName": {
                  "locationName": "serviceName"
                },
                "ServiceProviderName": {
                  "locationName": "serviceProviderName"
                }
              }
            },
            "DvbSubPids": {
              "shape": "S81",
              "locationName": "dvbSubPids"
            },
            "DvbTdtSettings": {
              "locationName": "dvbTdtSettings",
              "type": "structure",
              "members": {
                "TdtInterval": {
                  "locationName": "tdtInterval",
                  "type": "integer"
                }
              }
            },
            "DvbTeletextPid": {
              "locationName": "dvbTeletextPid",
              "type": "integer"
            },
            "EbpAudioInterval": {
              "locationName": "ebpAudioInterval"
            },
            "EbpPlacement": {
              "locationName": "ebpPlacement"
            },
            "EsRateInPes": {
              "locationName": "esRateInPes"
            },
            "ForceTsVideoEbpOrder": {
              "locationName": "forceTsVideoEbpOrder"
            },
            "FragmentTime": {
              "locationName": "fragmentTime",
              "type": "double"
            },
            "MaxPcrInterval": {
              "locationName": "maxPcrInterval",
              "type": "integer"
            },
            "MinEbpInterval": {
              "locationName": "minEbpInterval",
              "type": "integer"
            },
            "NielsenId3": {
              "locationName": "nielsenId3"
            },
            "NullPacketBitrate": {
              "locationName": "nullPacketBitrate",
              "type": "double"
            },
            "PatInterval": {
              "locationName": "patInterval",
              "type": "integer"
            },
            "PcrControl": {
              "locationName": "pcrControl"
            },
            "PcrPid": {
              "locationName": "pcrPid",
              "type": "integer"
            },
            "PmtInterval": {
              "locationName": "pmtInterval",
              "type": "integer"
            },
            "PmtPid": {
              "locationName": "pmtPid",
              "type": "integer"
            },
            "PrivateMetadataPid": {
              "locationName": "privateMetadataPid",
              "type": "integer"
            },
            "ProgramNumber": {
              "locationName": "programNumber",
              "type": "integer"
            },
            "RateMode": {
              "locationName": "rateMode"
            },
            "Scte35Esam": {
              "locationName": "scte35Esam",
              "type": "structure",
              "members": {
                "Scte35EsamPid": {
                  "locationName": "scte35EsamPid",
                  "type": "integer"
                }
              }
            },
            "Scte35Pid": {
              "locationName": "scte35Pid",
              "type": "integer"
            },
            "Scte35Source": {
              "locationName": "scte35Source"
            },
            "SegmentationMarkers": {
              "locationName": "segmentationMarkers"
            },
            "SegmentationStyle": {
              "locationName": "segmentationStyle"
            },
            "SegmentationTime": {
              "locationName": "segmentationTime",
              "type": "double"
            },
            "TimedMetadataPid": {
              "locationName": "timedMetadataPid",
              "type": "integer"
            },
            "TransportStreamId": {
              "locationName": "transportStreamId",
              "type": "integer"
            },
            "VideoPid": {
              "locationName": "videoPid",
              "type": "integer"
            }
          }
        },
        "M3u8Settings": {
          "locationName": "m3u8Settings",
          "type": "structure",
          "members": {
            "AudioFramesPerPes": {
              "locationName": "audioFramesPerPes",
              "type": "integer"
            },
            "AudioPids": {
              "shape": "S81",
              "locationName": "audioPids"
            },
            "NielsenId3": {
              "locationName": "nielsenId3"
            },
            "PatInterval": {
              "locationName": "patInterval",
              "type": "integer"
            },
            "PcrControl": {
              "locationName": "pcrControl"
            },
            "PcrPid": {
              "locationName": "pcrPid",
              "type": "integer"
            },
            "PmtInterval": {
              "locationName": "pmtInterval",
              "type": "integer"
            },
            "PmtPid": {
              "locationName": "pmtPid",
              "type": "integer"
            },
            "PrivateMetadataPid": {
              "locationName": "privateMetadataPid",
              "type": "integer"
            },
            "ProgramNumber": {
              "locationName": "programNumber",
              "type": "integer"
            },
            "Scte35Pid": {
              "locationName": "scte35Pid",
              "type": "integer"
            },
            "Scte35Source": {
              "locationName": "scte35Source"
            },
            "TimedMetadata": {
              "locationName": "timedMetadata"
            },
            "TimedMetadataPid": {
              "locationName": "timedMetadataPid",
              "type": "integer"
            },
            "TransportStreamId": {
              "locationName": "transportStreamId",
              "type": "integer"
            },
            "VideoPid": {
              "locationName": "videoPid",
              "type": "integer"
            }
          }
        },
        "MovSettings": {
          "locationName": "movSettings",
          "type": "structure",
          "members": {
            "ClapAtom": {
              "locationName": "clapAtom"
            },
            "CslgAtom": {
              "locationName": "cslgAtom"
            },
            "Mpeg2FourCCControl": {
              "locationName": "mpeg2FourCCControl"
            },
            "PaddingControl": {
              "locationName": "paddingControl"
            },
            "Reference": {
              "locationName": "reference"
            }
          }
        },
        "Mp4Settings": {
          "locationName": "mp4Settings",
          "type": "structure",
          "members": {
            "CslgAtom": {
              "locationName": "cslgAtom"
            },
            "FreeSpaceBox": {
              "locationName": "freeSpaceBox"
            },
            "MoovPlacement": {
              "locationName": "moovPlacement"
            },
            "Mp4MajorBrand": {
              "locationName": "mp4MajorBrand"
            }
          }
        }
      }
    },
    "S81": {
      "type": "list",
      "member": {
        "type": "integer"
      }
    },
    "S9c": {
      "type": "structure",
      "members": {
        "AfdSignaling": {
          "locationName": "afdSignaling"
        },
        "AntiAlias": {
          "locationName": "antiAlias"
        },
        "CodecSettings": {
          "locationName": "codecSettings",
          "type": "structure",
          "members": {
            "Codec": {
              "locationName": "codec"
            },
            "FrameCaptureSettings": {
              "locationName": "frameCaptureSettings",
              "type": "structure",
              "members": {
                "FramerateDenominator": {
                  "locationName": "framerateDenominator",
                  "type": "integer"
                },
                "FramerateNumerator": {
                  "locationName": "framerateNumerator",
                  "type": "integer"
                },
                "MaxCaptures": {
                  "locationName": "maxCaptures",
                  "type": "integer"
                },
                "Quality": {
                  "locationName": "quality",
                  "type": "integer"
                }
              }
            },
            "H264Settings": {
              "locationName": "h264Settings",
              "type": "structure",
              "members": {
                "AdaptiveQuantization": {
                  "locationName": "adaptiveQuantization"
                },
                "Bitrate": {
                  "locationName": "bitrate",
                  "type": "integer"
                },
                "CodecLevel": {
                  "locationName": "codecLevel"
                },
                "CodecProfile": {
                  "locationName": "codecProfile"
                },
                "DynamicSubGop": {
                  "locationName": "dynamicSubGop"
                },
                "EntropyEncoding": {
                  "locationName": "entropyEncoding"
                },
                "FieldEncoding": {
                  "locationName": "fieldEncoding"
                },
                "FlickerAdaptiveQuantization": {
                  "locationName": "flickerAdaptiveQuantization"
                },
                "FramerateControl": {
                  "locationName": "framerateControl"
                },
                "FramerateConversionAlgorithm": {
                  "locationName": "framerateConversionAlgorithm"
                },
                "FramerateDenominator": {
                  "locationName": "framerateDenominator",
                  "type": "integer"
                },
                "FramerateNumerator": {
                  "locationName": "framerateNumerator",
                  "type": "integer"
                },
                "GopBReference": {
                  "locationName": "gopBReference"
                },
                "GopClosedCadence": {
                  "locationName": "gopClosedCadence",
                  "type": "integer"
                },
                "GopSize": {
                  "locationName": "gopSize",
                  "type": "double"
                },
                "GopSizeUnits": {
                  "locationName": "gopSizeUnits"
                },
                "HrdBufferInitialFillPercentage": {
                  "locationName": "hrdBufferInitialFillPercentage",
                  "type": "integer"
                },
                "HrdBufferSize": {
                  "locationName": "hrdBufferSize",
                  "type": "integer"
                },
                "InterlaceMode": {
                  "locationName": "interlaceMode"
                },
                "MaxBitrate": {
                  "locationName": "maxBitrate",
                  "type": "integer"
                },
                "MinIInterval": {
                  "locationName": "minIInterval",
                  "type": "integer"
                },
                "NumberBFramesBetweenReferenceFrames": {
                  "locationName": "numberBFramesBetweenReferenceFrames",
                  "type": "integer"
                },
                "NumberReferenceFrames": {
                  "locationName": "numberReferenceFrames",
                  "type": "integer"
                },
                "ParControl": {
                  "locationName": "parControl"
                },
                "ParDenominator": {
                  "locationName": "parDenominator",
                  "type": "integer"
                },
                "ParNumerator": {
                  "locationName": "parNumerator",
                  "type": "integer"
                },
                "QualityTuningLevel": {
                  "locationName": "qualityTuningLevel"
                },
                "QvbrSettings": {
                  "locationName": "qvbrSettings",
                  "type": "structure",
                  "members": {
                    "MaxAverageBitrate": {
                      "locationName": "maxAverageBitrate",
                      "type": "integer"
                    },
                    "QvbrQualityLevel": {
                      "locationName": "qvbrQualityLevel",
                      "type": "integer"
                    }
                  }
                },
                "RateControlMode": {
                  "locationName": "rateControlMode"
                },
                "RepeatPps": {
                  "locationName": "repeatPps"
                },
                "SceneChangeDetect": {
                  "locationName": "sceneChangeDetect"
                },
                "Slices": {
                  "locationName": "slices",
                  "type": "integer"
                },
                "SlowPal": {
                  "locationName": "slowPal"
                },
                "Softness": {
                  "locationName": "softness",
                  "type": "integer"
                },
                "SpatialAdaptiveQuantization": {
                  "locationName": "spatialAdaptiveQuantization"
                },
                "Syntax": {
                  "locationName": "syntax"
                },
                "Telecine": {
                  "locationName": "telecine"
                },
                "TemporalAdaptiveQuantization": {
                  "locationName": "temporalAdaptiveQuantization"
                },
                "UnregisteredSeiTimecode": {
                  "locationName": "unregisteredSeiTimecode"
                }
              }
            },
            "H265Settings": {
              "locationName": "h265Settings",
              "type": "structure",
              "members": {
                "AdaptiveQuantization": {
                  "locationName": "adaptiveQuantization"
                },
                "AlternateTransferFunctionSei": {
                  "locationName": "alternateTransferFunctionSei"
                },
                "Bitrate": {
                  "locationName": "bitrate",
                  "type": "integer"
                },
                "CodecLevel": {
                  "locationName": "codecLevel"
                },
                "CodecProfile": {
                  "locationName": "codecProfile"
                },
                "DynamicSubGop": {
                  "locationName": "dynamicSubGop"
                },
                "FlickerAdaptiveQuantization": {
                  "locationName": "flickerAdaptiveQuantization"
                },
                "FramerateControl": {
                  "locationName": "framerateControl"
                },
                "FramerateConversionAlgorithm": {
                  "locationName": "framerateConversionAlgorithm"
                },
                "FramerateDenominator": {
                  "locationName": "framerateDenominator",
                  "type": "integer"
                },
                "FramerateNumerator": {
                  "locationName": "framerateNumerator",
                  "type": "integer"
                },
                "GopBReference": {
                  "locationName": "gopBReference"
                },
                "GopClosedCadence": {
                  "locationName": "gopClosedCadence",
                  "type": "integer"
                },
                "GopSize": {
                  "locationName": "gopSize",
                  "type": "double"
                },
                "GopSizeUnits": {
                  "locationName": "gopSizeUnits"
                },
                "HrdBufferInitialFillPercentage": {
                  "locationName": "hrdBufferInitialFillPercentage",
                  "type": "integer"
                },
                "HrdBufferSize": {
                  "locationName": "hrdBufferSize",
                  "type": "integer"
                },
                "InterlaceMode": {
                  "locationName": "interlaceMode"
                },
                "MaxBitrate": {
                  "locationName": "maxBitrate",
                  "type": "integer"
                },
                "MinIInterval": {
                  "locationName": "minIInterval",
                  "type": "integer"
                },
                "NumberBFramesBetweenReferenceFrames": {
                  "locationName": "numberBFramesBetweenReferenceFrames",
                  "type": "integer"
                },
                "NumberReferenceFrames": {
                  "locationName": "numberReferenceFrames",
                  "type": "integer"
                },
                "ParControl": {
                  "locationName": "parControl"
                },
                "ParDenominator": {
                  "locationName": "parDenominator",
                  "type": "integer"
                },
                "ParNumerator": {
                  "locationName": "parNumerator",
                  "type": "integer"
                },
                "QualityTuningLevel": {
                  "locationName": "qualityTuningLevel"
                },
                "QvbrSettings": {
                  "locationName": "qvbrSettings",
                  "type": "structure",
                  "members": {
                    "MaxAverageBitrate": {
                      "locationName": "maxAverageBitrate",
                      "type": "integer"
                    },
                    "QvbrQualityLevel": {
                      "locationName": "qvbrQualityLevel",
                      "type": "integer"
                    }
                  }
                },
                "RateControlMode": {
                  "locationName": "rateControlMode"
                },
                "SampleAdaptiveOffsetFilterMode": {
                  "locationName": "sampleAdaptiveOffsetFilterMode"
                },
                "SceneChangeDetect": {
                  "locationName": "sceneChangeDetect"
                },
                "Slices": {
                  "locationName": "slices",
                  "type": "integer"
                },
                "SlowPal": {
                  "locationName": "slowPal"
                },
                "SpatialAdaptiveQuantization": {
                  "locationName": "spatialAdaptiveQuantization"
                },
                "Telecine": {
                  "locationName": "telecine"
                },
                "TemporalAdaptiveQuantization": {
                  "locationName": "temporalAdaptiveQuantization"
                },
                "TemporalIds": {
                  "locationName": "temporalIds"
                },
                "Tiles": {
                  "locationName": "tiles"
                },
                "UnregisteredSeiTimecode": {
                  "locationName": "unregisteredSeiTimecode"
                },
                "WriteMp4PackagingType": {
                  "locationName": "writeMp4PackagingType"
                }
              }
            },
            "Mpeg2Settings": {
              "locationName": "mpeg2Settings",
              "type": "structure",
              "members": {
                "AdaptiveQuantization": {
                  "locationName": "adaptiveQuantization"
                },
                "Bitrate": {
                  "locationName": "bitrate",
                  "type": "integer"
                },
                "CodecLevel": {
                  "locationName": "codecLevel"
                },
                "CodecProfile": {
                  "locationName": "codecProfile"
                },
                "DynamicSubGop": {
                  "locationName": "dynamicSubGop"
                },
                "FramerateControl": {
                  "locationName": "framerateControl"
                },
                "FramerateConversionAlgorithm": {
                  "locationName": "framerateConversionAlgorithm"
                },
                "FramerateDenominator": {
                  "locationName": "framerateDenominator",
                  "type": "integer"
                },
                "FramerateNumerator": {
                  "locationName": "framerateNumerator",
                  "type": "integer"
                },
                "GopClosedCadence": {
                  "locationName": "gopClosedCadence",
                  "type": "integer"
                },
                "GopSize": {
                  "locationName": "gopSize",
                  "type": "double"
                },
                "GopSizeUnits": {
                  "locationName": "gopSizeUnits"
                },
                "HrdBufferInitialFillPercentage": {
                  "locationName": "hrdBufferInitialFillPercentage",
                  "type": "integer"
                },
                "HrdBufferSize": {
                  "locationName": "hrdBufferSize",
                  "type": "integer"
                },
                "InterlaceMode": {
                  "locationName": "interlaceMode"
                },
                "IntraDcPrecision": {
                  "locationName": "intraDcPrecision"
                },
                "MaxBitrate": {
                  "locationName": "maxBitrate",
                  "type": "integer"
                },
                "MinIInterval": {
                  "locationName": "minIInterval",
                  "type": "integer"
                },
                "NumberBFramesBetweenReferenceFrames": {
                  "locationName": "numberBFramesBetweenReferenceFrames",
                  "type": "integer"
                },
                "ParControl": {
                  "locationName": "parControl"
                },
                "ParDenominator": {
                  "locationName": "parDenominator",
                  "type": "integer"
                },
                "ParNumerator": {
                  "locationName": "parNumerator",
                  "type": "integer"
                },
                "QualityTuningLevel": {
                  "locationName": "qualityTuningLevel"
                },
                "RateControlMode": {
                  "locationName": "rateControlMode"
                },
                "SceneChangeDetect": {
                  "locationName": "sceneChangeDetect"
                },
                "SlowPal": {
                  "locationName": "slowPal"
                },
                "Softness": {
                  "locationName": "softness",
                  "type": "integer"
                },
                "SpatialAdaptiveQuantization": {
                  "locationName": "spatialAdaptiveQuantization"
                },
                "Syntax": {
                  "locationName": "syntax"
                },
                "Telecine": {
                  "locationName": "telecine"
                },
                "TemporalAdaptiveQuantization": {
                  "locationName": "temporalAdaptiveQuantization"
                }
              }
            },
            "ProresSettings": {
              "locationName": "proresSettings",
              "type": "structure",
              "members": {
                "CodecProfile": {
                  "locationName": "codecProfile"
                },
                "FramerateControl": {
                  "locationName": "framerateControl"
                },
                "FramerateConversionAlgorithm": {
                  "locationName": "framerateConversionAlgorithm"
                },
                "FramerateDenominator": {
                  "locationName": "framerateDenominator",
                  "type": "integer"
                },
                "FramerateNumerator": {
                  "locationName": "framerateNumerator",
                  "type": "integer"
                },
                "InterlaceMode": {
                  "locationName": "interlaceMode"
                },
                "ParControl": {
                  "locationName": "parControl"
                },
                "ParDenominator": {
                  "locationName": "parDenominator",
                  "type": "integer"
                },
                "ParNumerator": {
                  "locationName": "parNumerator",
                  "type": "integer"
                },
                "SlowPal": {
                  "locationName": "slowPal"
                },
                "Telecine": {
                  "locationName": "telecine"
                }
              }
            }
          }
        },
        "ColorMetadata": {
          "locationName": "colorMetadata"
        },
        "Crop": {
          "shape": "S1q",
          "locationName": "crop"
        },
        "DropFrameTimecode": {
          "locationName": "dropFrameTimecode"
        },
        "FixedAfd": {
          "locationName": "fixedAfd",
          "type": "integer"
        },
        "Height": {
          "locationName": "height",
          "type": "integer"
        },
        "Position": {
          "shape": "S1q",
          "locationName": "position"
        },
        "RespondToAfd": {
          "locationName": "respondToAfd"
        },
        "ScalingBehavior": {
          "locationName": "scalingBehavior"
        },
        "Sharpness": {
          "locationName": "sharpness",
          "type": "integer"
        },
        "TimecodeInsertion": {
          "locationName": "timecodeInsertion"
        },
        "VideoPreprocessors": {
          "locationName": "videoPreprocessors",
          "type": "structure",
          "members": {
            "ColorCorrector": {
              "locationName": "colorCorrector",
              "type": "structure",
              "members": {
                "Brightness": {
                  "locationName": "brightness",
                  "type": "integer"
                },
                "ColorSpaceConversion": {
                  "locationName": "colorSpaceConversion"
                },
                "Contrast": {
                  "locationName": "contrast",
                  "type": "integer"
                },
                "Hdr10Metadata": {
                  "shape": "S2k",
                  "locationName": "hdr10Metadata"
                },
                "Hue": {
                  "locationName": "hue",
                  "type": "integer"
                },
                "Saturation": {
                  "locationName": "saturation",
                  "type": "integer"
                }
              }
            },
            "Deinterlacer": {
              "locationName": "deinterlacer",
              "type": "structure",
              "members": {
                "Algorithm": {
                  "locationName": "algorithm"
                },
                "Control": {
                  "locationName": "control"
                },
                "Mode": {
                  "locationName": "mode"
                }
              }
            },
            "ImageInserter": {
              "shape": "S23",
              "locationName": "imageInserter"
            },
            "NoiseReducer": {
              "locationName": "noiseReducer",
              "type": "structure",
              "members": {
                "Filter": {
                  "locationName": "filter"
                },
                "FilterSettings": {
                  "locationName": "filterSettings",
                  "type": "structure",
                  "members": {
                    "Strength": {
                      "locationName": "strength",
                      "type": "integer"
                    }
                  }
                },
                "SpatialFilterSettings": {
                  "locationName": "spatialFilterSettings",
                  "type": "structure",
                  "members": {
                    "PostFilterSharpenStrength": {
                      "locationName": "postFilterSharpenStrength",
                      "type": "integer"
                    },
                    "Speed": {
                      "locationName": "speed",
                      "type": "integer"
                    },
                    "Strength": {
                      "locationName": "strength",
                      "type": "integer"
                    }
                  }
                },
                "TemporalFilterSettings": {
                  "locationName": "temporalFilterSettings",
                  "type": "structure",
                  "members": {
                    "AggressiveMode": {
                      "locationName": "aggressiveMode",
                      "type": "integer"
                    },
                    "Speed": {
                      "locationName": "speed",
                      "type": "integer"
                    },
                    "Strength": {
                      "locationName": "strength",
                      "type": "integer"
                    }
                  }
                }
              }
            },
            "TimecodeBurnin": {
              "locationName": "timecodeBurnin",
              "type": "structure",
              "members": {
                "FontSize": {
                  "locationName": "fontSize",
                  "type": "integer"
                },
                "Position": {
                  "locationName": "position"
                },
                "Prefix": {
                  "locationName": "prefix"
                }
              }
            }
          }
        },
        "Width": {
          "locationName": "width",
          "type": "integer"
        }
      }
    },
    "Scx": {
      "type": "structure",
      "members": {
        "Anchor": {
          "locationName": "anchor"
        },
        "Source": {
          "locationName": "source"
        },
        "Start": {
          "locationName": "start"
        },
        "TimestampOffset": {
          "locationName": "timestampOffset"
        }
      }
    },
    "Sd0": {
      "type": "structure",
      "members": {
        "Id3Insertions": {
          "locationName": "id3Insertions",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Id3": {
                "locationName": "id3"
              },
              "Timecode": {
                "locationName": "timecode"
              }
            }
          }
        }
      }
    },
    "Sd5": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sd7": {
      "type": "structure",
      "members": {
        "AccelerationSettings": {
          "shape": "S7",
          "locationName": "accelerationSettings"
        },
        "Arn": {
          "locationName": "arn"
        },
        "BillingTagsSource": {
          "locationName": "billingTagsSource"
        },
        "CreatedAt": {
          "shape": "Sd8",
          "locationName": "createdAt"
        },
        "CurrentPhase": {
          "locationName": "currentPhase"
        },
        "ErrorCode": {
          "locationName": "errorCode",
          "type": "integer"
        },
        "ErrorMessage": {
          "locationName": "errorMessage"
        },
        "Id": {
          "locationName": "id"
        },
        "JobPercentComplete": {
          "locationName": "jobPercentComplete",
          "type": "integer"
        },
        "JobTemplate": {
          "locationName": "jobTemplate"
        },
        "OutputGroupDetails": {
          "locationName": "outputGroupDetails",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "OutputDetails": {
                "locationName": "outputDetails",
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "DurationInMs": {
                      "locationName": "durationInMs",
                      "type": "integer"
                    },
                    "VideoDetails": {
                      "locationName": "videoDetails",
                      "type": "structure",
                      "members": {
                        "HeightInPx": {
                          "locationName": "heightInPx",
                          "type": "integer"
                        },
                        "WidthInPx": {
                          "locationName": "widthInPx",
                          "type": "integer"
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        },
        "Priority": {
          "locationName": "priority",
          "type": "integer"
        },
        "Queue": {
          "locationName": "queue"
        },
        "RetryCount": {
          "locationName": "retryCount",
          "type": "integer"
        },
        "Role": {
          "locationName": "role"
        },
        "Settings": {
          "shape": "Sb",
          "locationName": "settings"
        },
        "Status": {
          "locationName": "status"
        },
        "StatusUpdateInterval": {
          "locationName": "statusUpdateInterval"
        },
        "Timing": {
          "locationName": "timing",
          "type": "structure",
          "members": {
            "FinishTime": {
              "shape": "Sd8",
              "locationName": "finishTime"
            },
            "StartTime": {
              "shape": "Sd8",
              "locationName": "startTime"
            },
            "SubmitTime": {
              "shape": "Sd8",
              "locationName": "submitTime"
            }
          }
        },
        "UserMetadata": {
          "shape": "Sd5",
          "locationName": "userMetadata"
        }
      },
      "required": [
        "Role",
        "Settings"
      ]
    },
    "Sd8": {
      "type": "timestamp",
      "timestampFormat": "unixTimestamp"
    },
    "Sdj": {
      "type": "structure",
      "members": {
        "AdAvailOffset": {
          "locationName": "adAvailOffset",
          "type": "integer"
        },
        "AvailBlanking": {
          "shape": "Sd",
          "locationName": "availBlanking"
        },
        "Esam": {
          "shape": "Sf",
          "locationName": "esam"
        },
        "Inputs": {
          "locationName": "inputs",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "AudioSelectorGroups": {
                "shape": "Sn",
                "locationName": "audioSelectorGroups"
              },
              "AudioSelectors": {
                "shape": "Sr",
                "locationName": "audioSelectors"
              },
              "CaptionSelectors": {
                "shape": "S1a",
                "locationName": "captionSelectors"
              },
              "Crop": {
                "shape": "S1q",
                "locationName": "crop"
              },
              "DeblockFilter": {
                "locationName": "deblockFilter"
              },
              "DenoiseFilter": {
                "locationName": "denoiseFilter"
              },
              "FilterEnable": {
                "locationName": "filterEnable"
              },
              "FilterStrength": {
                "locationName": "filterStrength",
                "type": "integer"
              },
              "ImageInserter": {
                "shape": "S23",
                "locationName": "imageInserter"
              },
              "InputClippings": {
                "shape": "S2a",
                "locationName": "inputClippings"
              },
              "Position": {
                "shape": "S1q",
                "locationName": "position"
              },
              "ProgramNumber": {
                "locationName": "programNumber",
                "type": "integer"
              },
              "PsiControl": {
                "locationName": "psiControl"
              },
              "TimecodeSource": {
                "locationName": "timecodeSource"
              },
              "VideoSelector": {
                "shape": "S2h",
                "locationName": "videoSelector"
              }
            }
          }
        },
        "MotionImageInserter": {
          "shape": "S2o",
          "locationName": "motionImageInserter"
        },
        "NielsenConfiguration": {
          "shape": "S2x",
          "locationName": "nielsenConfiguration"
        },
        "OutputGroups": {
          "shape": "S2z",
          "locationName": "outputGroups"
        },
        "TimecodeConfig": {
          "shape": "Scx",
          "locationName": "timecodeConfig"
        },
        "TimedMetadataInsertion": {
          "shape": "Sd0",
          "locationName": "timedMetadataInsertion"
        }
      }
    },
    "Sdn": {
      "type": "structure",
      "members": {
        "AccelerationSettings": {
          "shape": "S7",
          "locationName": "accelerationSettings"
        },
        "Arn": {
          "locationName": "arn"
        },
        "Category": {
          "locationName": "category"
        },
        "CreatedAt": {
          "shape": "Sd8",
          "locationName": "createdAt"
        },
        "Description": {
          "locationName": "description"
        },
        "LastUpdated": {
          "shape": "Sd8",
          "locationName": "lastUpdated"
        },
        "Name": {
          "locationName": "name"
        },
        "Priority": {
          "locationName": "priority",
          "type": "integer"
        },
        "Queue": {
          "locationName": "queue"
        },
        "Settings": {
          "shape": "Sdj",
          "locationName": "settings"
        },
        "StatusUpdateInterval": {
          "locationName": "statusUpdateInterval"
        },
        "Type": {
          "locationName": "type"
        }
      },
      "required": [
        "Settings",
        "Name"
      ]
    },
    "Sdq": {
      "type": "structure",
      "members": {
        "AudioDescriptions": {
          "shape": "S50",
          "locationName": "audioDescriptions"
        },
        "CaptionDescriptions": {
          "locationName": "captionDescriptions",
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "CustomLanguageCode": {
                "locationName": "customLanguageCode"
              },
              "DestinationSettings": {
                "shape": "S72",
                "locationName": "destinationSettings"
              },
              "LanguageCode": {
                "locationName": "languageCode"
              },
              "LanguageDescription": {
                "locationName": "languageDescription"
              }
            }
          }
        },
        "ContainerSettings": {
          "shape": "S7v",
          "locationName": "containerSettings"
        },
        "VideoDescription": {
          "shape": "S9c",
          "locationName": "videoDescription"
        }
      }
    },
    "Sdu": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "Category": {
          "locationName": "category"
        },
        "CreatedAt": {
          "shape": "Sd8",
          "locationName": "createdAt"
        },
        "Description": {
          "locationName": "description"
        },
        "LastUpdated": {
          "shape": "Sd8",
          "locationName": "lastUpdated"
        },
        "Name": {
          "locationName": "name"
        },
        "Settings": {
          "shape": "Sdq",
          "locationName": "settings"
        },
        "Type": {
          "locationName": "type"
        }
      },
      "required": [
        "Settings",
        "Name"
      ]
    },
    "Sdx": {
      "type": "structure",
      "members": {
        "Commitment": {
          "locationName": "commitment"
        },
        "RenewalType": {
          "locationName": "renewalType"
        },
        "ReservedSlots": {
          "locationName": "reservedSlots",
          "type": "integer"
        }
      },
      "required": [
        "Commitment",
        "ReservedSlots",
        "RenewalType"
      ]
    },
    "Se2": {
      "type": "structure",
      "members": {
        "Arn": {
          "locationName": "arn"
        },
        "CreatedAt": {
          "shape": "Sd8",
          "locationName": "createdAt"
        },
        "Description": {
          "locationName": "description"
        },
        "LastUpdated": {
          "shape": "Sd8",
          "locationName": "lastUpdated"
        },
        "Name": {
          "locationName": "name"
        },
        "PricingPlan": {
          "locationName": "pricingPlan"
        },
        "ProgressingJobsCount": {
          "locationName": "progressingJobsCount",
          "type": "integer"
        },
        "ReservationPlan": {
          "locationName": "reservationPlan",
          "type": "structure",
          "members": {
            "Commitment": {
              "locationName": "commitment"
            },
            "ExpiresAt": {
              "shape": "Sd8",
              "locationName": "expiresAt"
            },
            "PurchasedAt": {
              "shape": "Sd8",
              "locationName": "purchasedAt"
            },
            "RenewalType": {
              "locationName": "renewalType"
            },
            "ReservedSlots": {
              "locationName": "reservedSlots",
              "type": "integer"
            },
            "Status": {
              "locationName": "status"
            }
          }
        },
        "Status": {
          "locationName": "status"
        },
        "SubmittedJobsCount": {
          "locationName": "submittedJobsCount",
          "type": "integer"
        },
        "Type": {
          "locationName": "type"
        }
      },
      "required": [
        "Name"
      ]
    }
  }
}