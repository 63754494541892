{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-01-01",
    "endpointPrefix": "es",
    "protocol": "rest-json",
    "serviceFullName": "Amazon Elasticsearch Service",
    "serviceId": "Elasticsearch Service",
    "signatureVersion": "v4",
    "uid": "es-2015-01-01"
  },
  "operations": {
    "AddTags": {
      "http": {
        "requestUri": "/2015-01-01/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN",
          "TagList"
        ],
        "members": {
          "ARN": {},
          "TagList": {
            "shape": "S3"
          }
        }
      }
    },
    "CancelElasticsearchServiceSoftwareUpdate": {
      "http": {
        "requestUri": "/2015-01-01/es/serviceSoftwareUpdate/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSoftwareOptions": {
            "shape": "Sa"
          }
        }
      }
    },
    "CreateElasticsearchDomain": {
      "http": {
        "requestUri": "/2015-01-01/es/domain"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {},
          "ElasticsearchVersion": {},
          "ElasticsearchClusterConfig": {
            "shape": "Sh"
          },
          "EBSOptions": {
            "shape": "Sl"
          },
          "AccessPolicies": {},
          "SnapshotOptions": {
            "shape": "So"
          },
          "VPCOptions": {
            "shape": "Sp"
          },
          "CognitoOptions": {
            "shape": "Sr"
          },
          "EncryptionAtRestOptions": {
            "shape": "Sv"
          },
          "NodeToNodeEncryptionOptions": {
            "shape": "Sx"
          },
          "AdvancedOptions": {
            "shape": "Sy"
          },
          "LogPublishingOptions": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainStatus": {
            "shape": "S14"
          }
        }
      }
    },
    "DeleteElasticsearchDomain": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-01-01/es/domain/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainStatus": {
            "shape": "S14"
          }
        }
      }
    },
    "DeleteElasticsearchServiceRole": {
      "http": {
        "method": "DELETE",
        "requestUri": "/2015-01-01/es/role"
      }
    },
    "DescribeElasticsearchDomain": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/domain/{DomainName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainStatus"
        ],
        "members": {
          "DomainStatus": {
            "shape": "S14"
          }
        }
      }
    },
    "DescribeElasticsearchDomainConfig": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/domain/{DomainName}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainConfig"
        ],
        "members": {
          "DomainConfig": {
            "shape": "S1f"
          }
        }
      }
    },
    "DescribeElasticsearchDomains": {
      "http": {
        "requestUri": "/2015-01-01/es/domain-info"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainNames"
        ],
        "members": {
          "DomainNames": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainStatusList"
        ],
        "members": {
          "DomainStatusList": {
            "type": "list",
            "member": {
              "shape": "S14"
            }
          }
        }
      }
    },
    "DescribeElasticsearchInstanceTypeLimits": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/instanceTypeLimits/{ElasticsearchVersion}/{InstanceType}"
      },
      "input": {
        "type": "structure",
        "required": [
          "InstanceType",
          "ElasticsearchVersion"
        ],
        "members": {
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          },
          "InstanceType": {
            "location": "uri",
            "locationName": "InstanceType"
          },
          "ElasticsearchVersion": {
            "location": "uri",
            "locationName": "ElasticsearchVersion"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LimitsByRole": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "StorageTypes": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "StorageTypeName": {},
                      "StorageSubTypeName": {},
                      "StorageTypeLimits": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "LimitName": {},
                            "LimitValues": {
                              "shape": "S2b"
                            }
                          }
                        }
                      }
                    }
                  }
                },
                "InstanceLimits": {
                  "type": "structure",
                  "members": {
                    "InstanceCountLimits": {
                      "type": "structure",
                      "members": {
                        "MinimumInstanceCount": {
                          "type": "integer"
                        },
                        "MaximumInstanceCount": {
                          "type": "integer"
                        }
                      }
                    }
                  }
                },
                "AdditionalLimits": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "LimitName": {},
                      "LimitValues": {
                        "shape": "S2b"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedElasticsearchInstanceOfferings": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/reservedInstanceOfferings"
      },
      "input": {
        "type": "structure",
        "members": {
          "ReservedElasticsearchInstanceOfferingId": {
            "location": "querystring",
            "locationName": "offeringId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ReservedElasticsearchInstanceOfferings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReservedElasticsearchInstanceOfferingId": {},
                "ElasticsearchInstanceType": {},
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "PaymentOption": {},
                "RecurringCharges": {
                  "shape": "S2t"
                }
              }
            }
          }
        }
      }
    },
    "DescribeReservedElasticsearchInstances": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/reservedInstances"
      },
      "input": {
        "type": "structure",
        "members": {
          "ReservedElasticsearchInstanceId": {
            "location": "querystring",
            "locationName": "reservationId"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "ReservedElasticsearchInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReservationName": {},
                "ReservedElasticsearchInstanceId": {},
                "ReservedElasticsearchInstanceOfferingId": {},
                "ElasticsearchInstanceType": {},
                "StartTime": {
                  "type": "timestamp"
                },
                "Duration": {
                  "type": "integer"
                },
                "FixedPrice": {
                  "type": "double"
                },
                "UsagePrice": {
                  "type": "double"
                },
                "CurrencyCode": {},
                "ElasticsearchInstanceCount": {
                  "type": "integer"
                },
                "State": {},
                "PaymentOption": {},
                "RecurringCharges": {
                  "shape": "S2t"
                }
              }
            }
          }
        }
      }
    },
    "GetCompatibleElasticsearchVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/compatibleVersions"
      },
      "input": {
        "type": "structure",
        "members": {
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "CompatibleElasticsearchVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceVersion": {},
                "TargetVersions": {
                  "shape": "S34"
                }
              }
            }
          }
        }
      }
    },
    "GetUpgradeHistory": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/upgradeDomain/{DomainName}/history"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpgradeHistories": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "UpgradeName": {},
                "StartTimestamp": {
                  "type": "timestamp"
                },
                "UpgradeStatus": {},
                "StepsList": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "UpgradeStep": {},
                      "UpgradeStepStatus": {},
                      "Issues": {
                        "type": "list",
                        "member": {}
                      },
                      "ProgressPercent": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "GetUpgradeStatus": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/upgradeDomain/{DomainName}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "UpgradeStep": {},
          "StepStatus": {},
          "UpgradeName": {}
        }
      }
    },
    "ListDomainNames": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/domain"
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainNames": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "DomainName": {}
              }
            }
          }
        }
      }
    },
    "ListElasticsearchInstanceTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/instanceTypes/{ElasticsearchVersion}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ElasticsearchVersion"
        ],
        "members": {
          "ElasticsearchVersion": {
            "location": "uri",
            "locationName": "ElasticsearchVersion"
          },
          "DomainName": {
            "location": "querystring",
            "locationName": "domainName"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticsearchInstanceTypes": {
            "type": "list",
            "member": {}
          },
          "NextToken": {}
        }
      }
    },
    "ListElasticsearchVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/es/versions"
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ElasticsearchVersions": {
            "shape": "S34"
          },
          "NextToken": {}
        }
      }
    },
    "ListTags": {
      "http": {
        "method": "GET",
        "requestUri": "/2015-01-01/tags/"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN"
        ],
        "members": {
          "ARN": {
            "location": "querystring",
            "locationName": "arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S3"
          }
        }
      }
    },
    "PurchaseReservedElasticsearchInstanceOffering": {
      "http": {
        "requestUri": "/2015-01-01/es/purchaseReservedInstanceOffering"
      },
      "input": {
        "type": "structure",
        "required": [
          "ReservedElasticsearchInstanceOfferingId",
          "ReservationName"
        ],
        "members": {
          "ReservedElasticsearchInstanceOfferingId": {},
          "ReservationName": {},
          "InstanceCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReservedElasticsearchInstanceId": {},
          "ReservationName": {}
        }
      }
    },
    "RemoveTags": {
      "http": {
        "requestUri": "/2015-01-01/tags-removal"
      },
      "input": {
        "type": "structure",
        "required": [
          "ARN",
          "TagKeys"
        ],
        "members": {
          "ARN": {},
          "TagKeys": {
            "shape": "Sq"
          }
        }
      }
    },
    "StartElasticsearchServiceSoftwareUpdate": {
      "http": {
        "requestUri": "/2015-01-01/es/serviceSoftwareUpdate/start"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ServiceSoftwareOptions": {
            "shape": "Sa"
          }
        }
      }
    },
    "UpdateElasticsearchDomainConfig": {
      "http": {
        "requestUri": "/2015-01-01/es/domain/{DomainName}/config"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName"
        ],
        "members": {
          "DomainName": {
            "location": "uri",
            "locationName": "DomainName"
          },
          "ElasticsearchClusterConfig": {
            "shape": "Sh"
          },
          "EBSOptions": {
            "shape": "Sl"
          },
          "SnapshotOptions": {
            "shape": "So"
          },
          "VPCOptions": {
            "shape": "Sp"
          },
          "CognitoOptions": {
            "shape": "Sr"
          },
          "AdvancedOptions": {
            "shape": "Sy"
          },
          "AccessPolicies": {},
          "LogPublishingOptions": {
            "shape": "Sz"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DomainConfig"
        ],
        "members": {
          "DomainConfig": {
            "shape": "S1f"
          }
        }
      }
    },
    "UpgradeElasticsearchDomain": {
      "http": {
        "requestUri": "/2015-01-01/es/upgradeDomain"
      },
      "input": {
        "type": "structure",
        "required": [
          "DomainName",
          "TargetVersion"
        ],
        "members": {
          "DomainName": {},
          "TargetVersion": {},
          "PerformCheckOnly": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DomainName": {},
          "TargetVersion": {},
          "PerformCheckOnly": {
            "type": "boolean"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Sa": {
      "type": "structure",
      "members": {
        "CurrentVersion": {},
        "NewVersion": {},
        "UpdateAvailable": {
          "type": "boolean"
        },
        "Cancellable": {
          "type": "boolean"
        },
        "UpdateStatus": {},
        "Description": {},
        "AutomatedUpdateDate": {
          "type": "timestamp"
        }
      }
    },
    "Sh": {
      "type": "structure",
      "members": {
        "InstanceType": {},
        "InstanceCount": {
          "type": "integer"
        },
        "DedicatedMasterEnabled": {
          "type": "boolean"
        },
        "ZoneAwarenessEnabled": {
          "type": "boolean"
        },
        "ZoneAwarenessConfig": {
          "type": "structure",
          "members": {
            "AvailabilityZoneCount": {
              "type": "integer"
            }
          }
        },
        "DedicatedMasterType": {},
        "DedicatedMasterCount": {
          "type": "integer"
        }
      }
    },
    "Sl": {
      "type": "structure",
      "members": {
        "EBSEnabled": {
          "type": "boolean"
        },
        "VolumeType": {},
        "VolumeSize": {
          "type": "integer"
        },
        "Iops": {
          "type": "integer"
        }
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "AutomatedSnapshotStartHour": {
          "type": "integer"
        }
      }
    },
    "Sp": {
      "type": "structure",
      "members": {
        "SubnetIds": {
          "shape": "Sq"
        },
        "SecurityGroupIds": {
          "shape": "Sq"
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {}
    },
    "Sr": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "UserPoolId": {},
        "IdentityPoolId": {},
        "RoleArn": {}
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "KmsKeyId": {}
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "Sy": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sz": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "CloudWatchLogsLogGroupArn": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "S14": {
      "type": "structure",
      "required": [
        "DomainId",
        "DomainName",
        "ARN",
        "ElasticsearchClusterConfig"
      ],
      "members": {
        "DomainId": {},
        "DomainName": {},
        "ARN": {},
        "Created": {
          "type": "boolean"
        },
        "Deleted": {
          "type": "boolean"
        },
        "Endpoint": {},
        "Endpoints": {
          "type": "map",
          "key": {},
          "value": {}
        },
        "Processing": {
          "type": "boolean"
        },
        "UpgradeProcessing": {
          "type": "boolean"
        },
        "ElasticsearchVersion": {},
        "ElasticsearchClusterConfig": {
          "shape": "Sh"
        },
        "EBSOptions": {
          "shape": "Sl"
        },
        "AccessPolicies": {},
        "SnapshotOptions": {
          "shape": "So"
        },
        "VPCOptions": {
          "shape": "S18"
        },
        "CognitoOptions": {
          "shape": "Sr"
        },
        "EncryptionAtRestOptions": {
          "shape": "Sv"
        },
        "NodeToNodeEncryptionOptions": {
          "shape": "Sx"
        },
        "AdvancedOptions": {
          "shape": "Sy"
        },
        "LogPublishingOptions": {
          "shape": "Sz"
        },
        "ServiceSoftwareOptions": {
          "shape": "Sa"
        }
      }
    },
    "S18": {
      "type": "structure",
      "members": {
        "VPCId": {},
        "SubnetIds": {
          "shape": "Sq"
        },
        "AvailabilityZones": {
          "shape": "Sq"
        },
        "SecurityGroupIds": {
          "shape": "Sq"
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "ElasticsearchVersion": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {},
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "ElasticsearchClusterConfig": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "Sh"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "EBSOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "Sl"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "AccessPolicies": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {},
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "SnapshotOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "So"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "VPCOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "S18"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "CognitoOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "Sr"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "EncryptionAtRestOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "Sv"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "NodeToNodeEncryptionOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "Sx"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "AdvancedOptions": {
          "type": "structure",
          "required": [
            "Options",
            "Status"
          ],
          "members": {
            "Options": {
              "shape": "Sy"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        },
        "LogPublishingOptions": {
          "type": "structure",
          "members": {
            "Options": {
              "shape": "Sz"
            },
            "Status": {
              "shape": "S1h"
            }
          }
        }
      }
    },
    "S1h": {
      "type": "structure",
      "required": [
        "CreationDate",
        "UpdateDate",
        "State"
      ],
      "members": {
        "CreationDate": {
          "type": "timestamp"
        },
        "UpdateDate": {
          "type": "timestamp"
        },
        "UpdateVersion": {
          "type": "integer"
        },
        "State": {},
        "PendingDeletion": {
          "type": "boolean"
        }
      }
    },
    "S2b": {
      "type": "list",
      "member": {}
    },
    "S2t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RecurringChargeAmount": {
            "type": "double"
          },
          "RecurringChargeFrequency": {}
        }
      }
    },
    "S34": {
      "type": "list",
      "member": {}
    }
  }
}