{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-01-26",
    "endpointPrefix": "tagging",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Resource Groups Tagging API",
    "serviceId": "Resource Groups Tagging API",
    "signatureVersion": "v4",
    "targetPrefix": "ResourceGroupsTaggingAPI_20170126",
    "uid": "resourcegroupstaggingapi-2017-01-26"
  },
  "operations": {
    "GetResources": {
      "input": {
        "type": "structure",
        "members": {
          "PaginationToken": {},
          "TagFilters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Key": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "ResourcesPerPage": {
            "type": "integer"
          },
          "TagsPerPage": {
            "type": "integer"
          },
          "ResourceTypeFilters": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PaginationToken": {},
          "ResourceTagMappingList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceARN": {},
                "Tags": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "required": [
                      "Key",
                      "Value"
                    ],
                    "members": {
                      "Key": {},
                      "Value": {}
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "GetTagKeys": {
      "input": {
        "type": "structure",
        "members": {
          "PaginationToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PaginationToken": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "GetTagValues": {
      "input": {
        "type": "structure",
        "required": [
          "Key"
        ],
        "members": {
          "PaginationToken": {},
          "Key": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PaginationToken": {},
          "TagValues": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "TagResources": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARNList",
          "Tags"
        ],
        "members": {
          "ResourceARNList": {
            "shape": "Sp"
          },
          "Tags": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedResourcesMap": {
            "shape": "Ss"
          }
        }
      }
    },
    "UntagResources": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARNList",
          "TagKeys"
        ],
        "members": {
          "ResourceARNList": {
            "shape": "Sp"
          },
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FailedResourcesMap": {
            "shape": "Ss"
          }
        }
      }
    }
  },
  "shapes": {
    "Sp": {
      "type": "list",
      "member": {}
    },
    "Ss": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "StatusCode": {
            "type": "integer"
          },
          "ErrorCode": {},
          "ErrorMessage": {}
        }
      }
    }
  }
}