import React from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingOverlay } from '@mineko/mineko-ui';
import { useTheme, Theme } from '@mui/material';
import { css } from '@emotion/react';

const primaryTextStyles = (theme: Theme) => css`
  color: ${theme.palette.primary.main};
  font-weight: bold;
  margin-top: 12px;
`;
export const Loader = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <LoadingOverlay loading={true}>
      <span css={primaryTextStyles(theme)}>{t('Global.please_wait')}</span>
    </LoadingOverlay>
  );
};
