"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useKeyPress = void 0;
const react_1 = require("react");
const useKeyPress = (targetKey) => {
    const [keyPressed, setKeyPressed] = (0, react_1.useState)(false);
    (0, react_1.useEffect)(() => {
        const handler = (val) => ({ key }) => key === targetKey && setKeyPressed(val);
        const downHandler = handler(true);
        const upHandler = handler(false);
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
        };
    }, [targetKey]);
    return keyPressed;
};
exports.useKeyPress = useKeyPress;
