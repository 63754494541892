import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { css } from '@emotion/react';
import { useTheme } from '@mui/material';
const backdropStyles = (theme) => css `
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: ${theme.zIndex.drawer + 1};
`;
export const LoadingOverlay = ({ loading, children, ...rest }) => {
    const theme = useTheme();
    return (_jsxs(Backdrop, { open: loading, css: backdropStyles(theme), ...rest, children: [_jsx(CircularProgress, { "aria-busy": loading, role: "progressbar", color: "primary" }), children] }));
};
