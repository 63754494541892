{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-06-26",
    "endpointPrefix": "forecastquery",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon Forecast Query Service",
    "serviceId": "forecastquery",
    "signatureVersion": "v4",
    "signingName": "forecast",
    "targetPrefix": "AmazonForecastRuntime",
    "uid": "forecastquery-2018-06-26"
  },
  "operations": {
    "QueryForecast": {
      "input": {
        "type": "structure",
        "required": [
          "ForecastArn",
          "Filters"
        ],
        "members": {
          "ForecastArn": {},
          "StartDate": {},
          "EndDate": {},
          "Filters": {
            "type": "map",
            "key": {},
            "value": {}
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Forecast": {
            "type": "structure",
            "members": {
              "Predictions": {
                "type": "map",
                "key": {},
                "value": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Timestamp": {},
                      "Value": {
                        "type": "double"
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  },
  "shapes": {}
}