{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-08-20",
    "endpointPrefix": "s3-control",
    "protocol": "rest-xml",
    "serviceFullName": "AWS S3 Control",
    "serviceId": "S3 Control",
    "signatureVersion": "s3v4",
    "signingName": "s3",
    "uid": "s3control-2018-08-20"
  },
  "operations": {
    "CreateJob": {
      "http": {
        "requestUri": "/v20180820/jobs"
      },
      "input": {
        "locationName": "CreateJobRequest",
        "xmlNamespace": {
          "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
        },
        "type": "structure",
        "required": [
          "AccountId",
          "Operation",
          "Report",
          "ClientRequestToken",
          "Manifest",
          "Priority",
          "RoleArn"
        ],
        "members": {
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "ConfirmationRequired": {
            "type": "boolean"
          },
          "Operation": {
            "shape": "S4"
          },
          "Report": {
            "shape": "S12"
          },
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Manifest": {
            "shape": "S17"
          },
          "Description": {},
          "Priority": {
            "type": "integer"
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {}
        }
      }
    },
    "DeletePublicAccessBlock": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v20180820/configuration/publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      }
    },
    "DescribeJob": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/jobs/{id}"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId"
        ],
        "members": {
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Job": {
            "type": "structure",
            "members": {
              "JobId": {},
              "ConfirmationRequired": {
                "type": "boolean"
              },
              "Description": {},
              "JobArn": {},
              "Status": {},
              "Manifest": {
                "shape": "S17"
              },
              "Operation": {
                "shape": "S4"
              },
              "Priority": {
                "type": "integer"
              },
              "ProgressSummary": {
                "shape": "S1q"
              },
              "StatusUpdateReason": {},
              "FailureReasons": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "FailureCode": {},
                    "FailureReason": {}
                  }
                }
              },
              "Report": {
                "shape": "S12"
              },
              "CreationTime": {
                "type": "timestamp"
              },
              "TerminationDate": {
                "type": "timestamp"
              },
              "RoleArn": {},
              "SuspendedDate": {
                "type": "timestamp"
              },
              "SuspendedCause": {}
            }
          }
        }
      }
    },
    "GetPublicAccessBlock": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/configuration/publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PublicAccessBlockConfiguration": {
            "shape": "S25"
          }
        },
        "payload": "PublicAccessBlockConfiguration"
      }
    },
    "ListJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/v20180820/jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId"
        ],
        "members": {
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobStatuses": {
            "location": "querystring",
            "locationName": "jobStatuses",
            "type": "list",
            "member": {}
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextToken": {},
          "Jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "JobId": {},
                "Description": {},
                "Operation": {},
                "Priority": {
                  "type": "integer"
                },
                "Status": {},
                "CreationTime": {
                  "type": "timestamp"
                },
                "TerminationDate": {
                  "type": "timestamp"
                },
                "ProgressSummary": {
                  "shape": "S1q"
                }
              }
            }
          }
        }
      }
    },
    "PutPublicAccessBlock": {
      "http": {
        "method": "PUT",
        "requestUri": "/v20180820/configuration/publicAccessBlock"
      },
      "input": {
        "type": "structure",
        "required": [
          "PublicAccessBlockConfiguration",
          "AccountId"
        ],
        "members": {
          "PublicAccessBlockConfiguration": {
            "shape": "S25",
            "locationName": "PublicAccessBlockConfiguration",
            "xmlNamespace": {
              "uri": "http://awss3control.amazonaws.com/doc/2018-08-20/"
            }
          },
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          }
        },
        "payload": "PublicAccessBlockConfiguration"
      }
    },
    "UpdateJobPriority": {
      "http": {
        "requestUri": "/v20180820/jobs/{id}/priority"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId",
          "Priority"
        ],
        "members": {
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          },
          "Priority": {
            "location": "querystring",
            "locationName": "priority",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "JobId",
          "Priority"
        ],
        "members": {
          "JobId": {},
          "Priority": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateJobStatus": {
      "http": {
        "requestUri": "/v20180820/jobs/{id}/status"
      },
      "input": {
        "type": "structure",
        "required": [
          "AccountId",
          "JobId",
          "RequestedJobStatus"
        ],
        "members": {
          "AccountId": {
            "location": "header",
            "locationName": "x-amz-account-id"
          },
          "JobId": {
            "location": "uri",
            "locationName": "id"
          },
          "RequestedJobStatus": {
            "location": "querystring",
            "locationName": "requestedJobStatus"
          },
          "StatusUpdateReason": {
            "location": "querystring",
            "locationName": "statusUpdateReason"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "JobId": {},
          "Status": {},
          "StatusUpdateReason": {}
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "members": {
        "LambdaInvoke": {
          "type": "structure",
          "members": {
            "FunctionArn": {}
          }
        },
        "S3PutObjectCopy": {
          "type": "structure",
          "members": {
            "TargetResource": {},
            "CannedAccessControlList": {},
            "AccessControlGrants": {
              "shape": "Sa"
            },
            "MetadataDirective": {},
            "ModifiedSinceConstraint": {
              "type": "timestamp"
            },
            "NewObjectMetadata": {
              "type": "structure",
              "members": {
                "CacheControl": {},
                "ContentDisposition": {},
                "ContentEncoding": {},
                "ContentLanguage": {},
                "UserMetadata": {
                  "type": "map",
                  "key": {},
                  "value": {}
                },
                "ContentLength": {
                  "type": "long"
                },
                "ContentMD5": {},
                "ContentType": {},
                "HttpExpiresDate": {
                  "type": "timestamp"
                },
                "RequesterCharged": {
                  "type": "boolean"
                },
                "SSEAlgorithm": {}
              }
            },
            "NewObjectTagging": {
              "shape": "Sn"
            },
            "RedirectLocation": {},
            "RequesterPays": {
              "type": "boolean"
            },
            "StorageClass": {},
            "UnModifiedSinceConstraint": {
              "type": "timestamp"
            },
            "SSEAwsKmsKeyId": {},
            "TargetKeyPrefix": {},
            "ObjectLockLegalHoldStatus": {},
            "ObjectLockMode": {},
            "ObjectLockRetainUntilDate": {
              "type": "timestamp"
            }
          }
        },
        "S3PutObjectAcl": {
          "type": "structure",
          "members": {
            "AccessControlPolicy": {
              "type": "structure",
              "members": {
                "AccessControlList": {
                  "type": "structure",
                  "required": [
                    "Owner"
                  ],
                  "members": {
                    "Owner": {
                      "type": "structure",
                      "members": {
                        "ID": {},
                        "DisplayName": {}
                      }
                    },
                    "Grants": {
                      "shape": "Sa"
                    }
                  }
                },
                "CannedAccessControlList": {}
              }
            }
          }
        },
        "S3PutObjectTagging": {
          "type": "structure",
          "members": {
            "TagSet": {
              "shape": "Sn"
            }
          }
        },
        "S3InitiateRestoreObject": {
          "type": "structure",
          "members": {
            "ExpirationInDays": {
              "type": "integer"
            },
            "GlacierJobTier": {}
          }
        }
      }
    },
    "Sa": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Grantee": {
            "type": "structure",
            "members": {
              "TypeIdentifier": {},
              "Identifier": {},
              "DisplayName": {}
            }
          },
          "Permission": {}
        }
      }
    },
    "Sn": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Key",
          "Value"
        ],
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S12": {
      "type": "structure",
      "required": [
        "Enabled"
      ],
      "members": {
        "Bucket": {},
        "Format": {},
        "Enabled": {
          "type": "boolean"
        },
        "Prefix": {},
        "ReportScope": {}
      }
    },
    "S17": {
      "type": "structure",
      "required": [
        "Spec",
        "Location"
      ],
      "members": {
        "Spec": {
          "type": "structure",
          "required": [
            "Format"
          ],
          "members": {
            "Format": {},
            "Fields": {
              "type": "list",
              "member": {}
            }
          }
        },
        "Location": {
          "type": "structure",
          "required": [
            "ObjectArn",
            "ETag"
          ],
          "members": {
            "ObjectArn": {},
            "ObjectVersionId": {},
            "ETag": {}
          }
        }
      }
    },
    "S1q": {
      "type": "structure",
      "members": {
        "TotalNumberOfTasks": {
          "type": "long"
        },
        "NumberOfTasksSucceeded": {
          "type": "long"
        },
        "NumberOfTasksFailed": {
          "type": "long"
        }
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "BlockPublicAcls": {
          "locationName": "BlockPublicAcls",
          "type": "boolean"
        },
        "IgnorePublicAcls": {
          "locationName": "IgnorePublicAcls",
          "type": "boolean"
        },
        "BlockPublicPolicy": {
          "locationName": "BlockPublicPolicy",
          "type": "boolean"
        },
        "RestrictPublicBuckets": {
          "locationName": "RestrictPublicBuckets",
          "type": "boolean"
        }
      }
    }
  }
}