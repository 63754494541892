{
  "metadata": {
    "apiVersion": "2018-11-14",
    "endpointPrefix": "mediaconnect",
    "signingName": "mediaconnect",
    "serviceFullName": "AWS MediaConnect",
    "serviceId": "MediaConnect",
    "protocol": "rest-json",
    "jsonVersion": "1.1",
    "uid": "mediaconnect-2018-11-14",
    "signatureVersion": "v4"
  },
  "operations": {
    "AddFlowOutputs": {
      "http": {
        "requestUri": "/v1/flows/{flowArn}/outputs",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          },
          "Outputs": {
            "shape": "S3",
            "locationName": "outputs"
          }
        },
        "required": [
          "FlowArn",
          "Outputs"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "locationName": "flowArn"
          },
          "Outputs": {
            "shape": "Sc",
            "locationName": "outputs"
          }
        }
      }
    },
    "CreateFlow": {
      "http": {
        "requestUri": "/v1/flows",
        "responseCode": 201
      },
      "input": {
        "type": "structure",
        "members": {
          "AvailabilityZone": {
            "locationName": "availabilityZone"
          },
          "Entitlements": {
            "shape": "Sg",
            "locationName": "entitlements"
          },
          "Name": {
            "locationName": "name"
          },
          "Outputs": {
            "shape": "S3",
            "locationName": "outputs"
          },
          "Source": {
            "locationName": "source",
            "type": "structure",
            "members": {
              "Decryption": {
                "shape": "S6",
                "locationName": "decryption"
              },
              "Description": {
                "locationName": "description"
              },
              "EntitlementArn": {
                "locationName": "entitlementArn"
              },
              "IngestPort": {
                "locationName": "ingestPort",
                "type": "integer"
              },
              "MaxBitrate": {
                "locationName": "maxBitrate",
                "type": "integer"
              },
              "MaxLatency": {
                "locationName": "maxLatency",
                "type": "integer"
              },
              "Name": {
                "locationName": "name"
              },
              "Protocol": {
                "locationName": "protocol"
              },
              "StreamId": {
                "locationName": "streamId"
              },
              "WhitelistCidr": {
                "locationName": "whitelistCidr"
              }
            }
          }
        },
        "required": [
          "Source",
          "Name"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Flow": {
            "shape": "Sk",
            "locationName": "flow"
          }
        }
      }
    },
    "DeleteFlow": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/flows/{flowArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          }
        },
        "required": [
          "FlowArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "locationName": "flowArn"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "DescribeFlow": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/flows/{flowArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          }
        },
        "required": [
          "FlowArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Flow": {
            "shape": "Sk",
            "locationName": "flow"
          },
          "Messages": {
            "locationName": "messages",
            "type": "structure",
            "members": {
              "Errors": {
                "shape": "S5",
                "locationName": "errors"
              }
            },
            "required": [
              "Errors"
            ]
          }
        }
      }
    },
    "GrantFlowEntitlements": {
      "http": {
        "requestUri": "/v1/flows/{flowArn}/entitlements",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "Entitlements": {
            "shape": "Sg",
            "locationName": "entitlements"
          },
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          }
        },
        "required": [
          "FlowArn",
          "Entitlements"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Entitlements": {
            "shape": "Sl",
            "locationName": "entitlements"
          },
          "FlowArn": {
            "locationName": "flowArn"
          }
        }
      }
    },
    "ListEntitlements": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/entitlements",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Entitlements": {
            "locationName": "entitlements",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EntitlementArn": {
                  "locationName": "entitlementArn"
                },
                "EntitlementName": {
                  "locationName": "entitlementName"
                }
              },
              "required": [
                "EntitlementArn",
                "EntitlementName"
              ]
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListFlows": {
      "http": {
        "method": "GET",
        "requestUri": "/v1/flows",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Flows": {
            "locationName": "flows",
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AvailabilityZone": {
                  "locationName": "availabilityZone"
                },
                "Description": {
                  "locationName": "description"
                },
                "FlowArn": {
                  "locationName": "flowArn"
                },
                "Name": {
                  "locationName": "name"
                },
                "SourceType": {
                  "locationName": "sourceType"
                },
                "Status": {
                  "locationName": "status"
                }
              },
              "required": [
                "Status",
                "Description",
                "SourceType",
                "AvailabilityZone",
                "FlowArn",
                "Name"
              ]
            }
          },
          "NextToken": {
            "locationName": "nextToken"
          }
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 200
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          }
        },
        "required": [
          "ResourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S18",
            "locationName": "tags"
          }
        }
      }
    },
    "RemoveFlowOutput": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/flows/{flowArn}/outputs/{outputArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          },
          "OutputArn": {
            "location": "uri",
            "locationName": "outputArn"
          }
        },
        "required": [
          "FlowArn",
          "OutputArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "locationName": "flowArn"
          },
          "OutputArn": {
            "locationName": "outputArn"
          }
        }
      }
    },
    "RevokeFlowEntitlement": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v1/flows/{flowArn}/entitlements/{entitlementArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "EntitlementArn": {
            "location": "uri",
            "locationName": "entitlementArn"
          },
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          }
        },
        "required": [
          "FlowArn",
          "EntitlementArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "EntitlementArn": {
            "locationName": "entitlementArn"
          },
          "FlowArn": {
            "locationName": "flowArn"
          }
        }
      }
    },
    "StartFlow": {
      "http": {
        "requestUri": "/v1/flows/start/{flowArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          }
        },
        "required": [
          "FlowArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "locationName": "flowArn"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "StopFlow": {
      "http": {
        "requestUri": "/v1/flows/stop/{flowArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          }
        },
        "required": [
          "FlowArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "locationName": "flowArn"
          },
          "Status": {
            "locationName": "status"
          }
        }
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "Tags": {
            "shape": "S18",
            "locationName": "tags"
          }
        },
        "required": [
          "ResourceArn",
          "Tags"
        ]
      }
    },
    "UntagResource": {
      "http": {
        "method": "DELETE",
        "requestUri": "/tags/{resourceArn}",
        "responseCode": 204
      },
      "input": {
        "type": "structure",
        "members": {
          "ResourceArn": {
            "location": "uri",
            "locationName": "resourceArn"
          },
          "TagKeys": {
            "shape": "S5",
            "location": "querystring",
            "locationName": "tagKeys"
          }
        },
        "required": [
          "TagKeys",
          "ResourceArn"
        ]
      }
    },
    "UpdateFlowEntitlement": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/flows/{flowArn}/entitlements/{entitlementArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "Encryption": {
            "shape": "S1k",
            "locationName": "encryption"
          },
          "EntitlementArn": {
            "location": "uri",
            "locationName": "entitlementArn"
          },
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          },
          "Subscribers": {
            "shape": "S5",
            "locationName": "subscribers"
          }
        },
        "required": [
          "FlowArn",
          "EntitlementArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "Entitlement": {
            "shape": "Sm",
            "locationName": "entitlement"
          },
          "FlowArn": {
            "locationName": "flowArn"
          }
        }
      }
    },
    "UpdateFlowOutput": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/flows/{flowArn}/outputs/{outputArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "CidrAllowList": {
            "shape": "S5",
            "locationName": "cidrAllowList"
          },
          "Description": {
            "locationName": "description"
          },
          "Destination": {
            "locationName": "destination"
          },
          "Encryption": {
            "shape": "S1k",
            "locationName": "encryption"
          },
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          },
          "MaxLatency": {
            "locationName": "maxLatency",
            "type": "integer"
          },
          "OutputArn": {
            "location": "uri",
            "locationName": "outputArn"
          },
          "Port": {
            "locationName": "port",
            "type": "integer"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RemoteId": {
            "locationName": "remoteId"
          },
          "SmoothingLatency": {
            "locationName": "smoothingLatency",
            "type": "integer"
          },
          "StreamId": {
            "locationName": "streamId"
          }
        },
        "required": [
          "FlowArn",
          "OutputArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "locationName": "flowArn"
          },
          "Output": {
            "shape": "Sd",
            "locationName": "output"
          }
        }
      }
    },
    "UpdateFlowSource": {
      "http": {
        "method": "PUT",
        "requestUri": "/v1/flows/{flowArn}/source/{sourceArn}",
        "responseCode": 202
      },
      "input": {
        "type": "structure",
        "members": {
          "Decryption": {
            "shape": "S1k",
            "locationName": "decryption"
          },
          "Description": {
            "locationName": "description"
          },
          "EntitlementArn": {
            "locationName": "entitlementArn"
          },
          "FlowArn": {
            "location": "uri",
            "locationName": "flowArn"
          },
          "IngestPort": {
            "locationName": "ingestPort",
            "type": "integer"
          },
          "MaxBitrate": {
            "locationName": "maxBitrate",
            "type": "integer"
          },
          "MaxLatency": {
            "locationName": "maxLatency",
            "type": "integer"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "SourceArn": {
            "location": "uri",
            "locationName": "sourceArn"
          },
          "StreamId": {
            "locationName": "streamId"
          },
          "WhitelistCidr": {
            "locationName": "whitelistCidr"
          }
        },
        "required": [
          "FlowArn",
          "SourceArn"
        ]
      },
      "output": {
        "type": "structure",
        "members": {
          "FlowArn": {
            "locationName": "flowArn"
          },
          "Source": {
            "shape": "Sn",
            "locationName": "source"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "CidrAllowList": {
            "shape": "S5",
            "locationName": "cidrAllowList"
          },
          "Description": {
            "locationName": "description"
          },
          "Destination": {
            "locationName": "destination"
          },
          "Encryption": {
            "shape": "S6",
            "locationName": "encryption"
          },
          "MaxLatency": {
            "locationName": "maxLatency",
            "type": "integer"
          },
          "Name": {
            "locationName": "name"
          },
          "Port": {
            "locationName": "port",
            "type": "integer"
          },
          "Protocol": {
            "locationName": "protocol"
          },
          "RemoteId": {
            "locationName": "remoteId"
          },
          "SmoothingLatency": {
            "locationName": "smoothingLatency",
            "type": "integer"
          },
          "StreamId": {
            "locationName": "streamId"
          }
        },
        "required": [
          "Protocol"
        ]
      }
    },
    "S5": {
      "type": "list",
      "member": {}
    },
    "S6": {
      "type": "structure",
      "members": {
        "Algorithm": {
          "locationName": "algorithm"
        },
        "ConstantInitializationVector": {
          "locationName": "constantInitializationVector"
        },
        "DeviceId": {
          "locationName": "deviceId"
        },
        "KeyType": {
          "locationName": "keyType"
        },
        "Region": {
          "locationName": "region"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "RoleArn": {
          "locationName": "roleArn"
        },
        "SecretArn": {
          "locationName": "secretArn"
        },
        "Url": {
          "locationName": "url"
        }
      },
      "required": [
        "Algorithm",
        "RoleArn"
      ]
    },
    "Sc": {
      "type": "list",
      "member": {
        "shape": "Sd"
      }
    },
    "Sd": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "Destination": {
          "locationName": "destination"
        },
        "Encryption": {
          "shape": "S6",
          "locationName": "encryption"
        },
        "EntitlementArn": {
          "locationName": "entitlementArn"
        },
        "MediaLiveInputArn": {
          "locationName": "mediaLiveInputArn"
        },
        "Name": {
          "locationName": "name"
        },
        "OutputArn": {
          "locationName": "outputArn"
        },
        "Port": {
          "locationName": "port",
          "type": "integer"
        },
        "Transport": {
          "shape": "Se",
          "locationName": "transport"
        }
      },
      "required": [
        "OutputArn",
        "Name"
      ]
    },
    "Se": {
      "type": "structure",
      "members": {
        "CidrAllowList": {
          "shape": "S5",
          "locationName": "cidrAllowList"
        },
        "MaxBitrate": {
          "locationName": "maxBitrate",
          "type": "integer"
        },
        "MaxLatency": {
          "locationName": "maxLatency",
          "type": "integer"
        },
        "Protocol": {
          "locationName": "protocol"
        },
        "RemoteId": {
          "locationName": "remoteId"
        },
        "SmoothingLatency": {
          "locationName": "smoothingLatency",
          "type": "integer"
        },
        "StreamId": {
          "locationName": "streamId"
        }
      },
      "required": [
        "Protocol"
      ]
    },
    "Sg": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Description": {
            "locationName": "description"
          },
          "Encryption": {
            "shape": "S6",
            "locationName": "encryption"
          },
          "Name": {
            "locationName": "name"
          },
          "Subscribers": {
            "shape": "S5",
            "locationName": "subscribers"
          }
        },
        "required": [
          "Subscribers"
        ]
      }
    },
    "Sk": {
      "type": "structure",
      "members": {
        "AvailabilityZone": {
          "locationName": "availabilityZone"
        },
        "Description": {
          "locationName": "description"
        },
        "EgressIp": {
          "locationName": "egressIp"
        },
        "Entitlements": {
          "shape": "Sl",
          "locationName": "entitlements"
        },
        "FlowArn": {
          "locationName": "flowArn"
        },
        "Name": {
          "locationName": "name"
        },
        "Outputs": {
          "shape": "Sc",
          "locationName": "outputs"
        },
        "Source": {
          "shape": "Sn",
          "locationName": "source"
        },
        "Status": {
          "locationName": "status"
        }
      },
      "required": [
        "Status",
        "Entitlements",
        "Outputs",
        "AvailabilityZone",
        "FlowArn",
        "Source",
        "Name"
      ]
    },
    "Sl": {
      "type": "list",
      "member": {
        "shape": "Sm"
      }
    },
    "Sm": {
      "type": "structure",
      "members": {
        "Description": {
          "locationName": "description"
        },
        "Encryption": {
          "shape": "S6",
          "locationName": "encryption"
        },
        "EntitlementArn": {
          "locationName": "entitlementArn"
        },
        "Name": {
          "locationName": "name"
        },
        "Subscribers": {
          "shape": "S5",
          "locationName": "subscribers"
        }
      },
      "required": [
        "EntitlementArn",
        "Subscribers",
        "Name"
      ]
    },
    "Sn": {
      "type": "structure",
      "members": {
        "Decryption": {
          "shape": "S6",
          "locationName": "decryption"
        },
        "Description": {
          "locationName": "description"
        },
        "EntitlementArn": {
          "locationName": "entitlementArn"
        },
        "IngestIp": {
          "locationName": "ingestIp"
        },
        "IngestPort": {
          "locationName": "ingestPort",
          "type": "integer"
        },
        "Name": {
          "locationName": "name"
        },
        "SourceArn": {
          "locationName": "sourceArn"
        },
        "Transport": {
          "shape": "Se",
          "locationName": "transport"
        },
        "WhitelistCidr": {
          "locationName": "whitelistCidr"
        }
      },
      "required": [
        "SourceArn",
        "Name"
      ]
    },
    "S18": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1k": {
      "type": "structure",
      "members": {
        "Algorithm": {
          "locationName": "algorithm"
        },
        "ConstantInitializationVector": {
          "locationName": "constantInitializationVector"
        },
        "DeviceId": {
          "locationName": "deviceId"
        },
        "KeyType": {
          "locationName": "keyType"
        },
        "Region": {
          "locationName": "region"
        },
        "ResourceId": {
          "locationName": "resourceId"
        },
        "RoleArn": {
          "locationName": "roleArn"
        },
        "SecretArn": {
          "locationName": "secretArn"
        },
        "Url": {
          "locationName": "url"
        }
      }
    }
  }
}