{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-08-08",
    "endpointPrefix": "globalaccelerator",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Global Accelerator",
    "serviceId": "Global Accelerator",
    "signatureVersion": "v4",
    "signingName": "globalaccelerator",
    "targetPrefix": "GlobalAccelerator_V20180706",
    "uid": "globalaccelerator-2018-08-08"
  },
  "operations": {
    "CreateAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "IdempotencyToken"
        ],
        "members": {
          "Name": {},
          "IpAddressType": {},
          "Enabled": {
            "type": "boolean"
          },
          "IdempotencyToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "S7"
          }
        }
      }
    },
    "CreateEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn",
          "EndpointGroupRegion",
          "IdempotencyToken"
        ],
        "members": {
          "ListenerArn": {},
          "EndpointGroupRegion": {},
          "EndpointConfigurations": {
            "shape": "Sf"
          },
          "TrafficDialPercentage": {
            "type": "float"
          },
          "HealthCheckPort": {
            "type": "integer"
          },
          "HealthCheckProtocol": {},
          "HealthCheckPath": {},
          "HealthCheckIntervalSeconds": {
            "type": "integer"
          },
          "ThresholdCount": {
            "type": "integer"
          },
          "IdempotencyToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "So"
          }
        }
      }
    },
    "CreateListener": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn",
          "PortRanges",
          "Protocol",
          "IdempotencyToken"
        ],
        "members": {
          "AcceleratorArn": {},
          "PortRanges": {
            "shape": "St"
          },
          "Protocol": {},
          "ClientAffinity": {},
          "IdempotencyToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "Sz"
          }
        }
      }
    },
    "DeleteAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      }
    },
    "DeleteEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {}
        }
      }
    },
    "DeleteListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {}
        }
      }
    },
    "DescribeAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "S7"
          }
        }
      }
    },
    "DescribeAcceleratorAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "AcceleratorArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AcceleratorAttributes": {
            "shape": "S17"
          }
        }
      }
    },
    "DescribeEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "So"
          }
        }
      }
    },
    "DescribeListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "Sz"
          }
        }
      }
    },
    "ListAccelerators": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerators": {
            "type": "list",
            "member": {
              "shape": "S7"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListEndpointGroups": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroups": {
            "type": "list",
            "member": {
              "shape": "So"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListListeners": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listeners": {
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          },
          "NextToken": {}
        }
      }
    },
    "UpdateAccelerator": {
      "input": {
        "type": "structure",
        "required": [
          "AcceleratorArn"
        ],
        "members": {
          "AcceleratorArn": {},
          "Name": {},
          "IpAddressType": {},
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Accelerator": {
            "shape": "S7"
          }
        }
      }
    },
    "UpdateAcceleratorAttributes": {
      "input": {
        "type": "structure",
        "members": {
          "AcceleratorArn": {},
          "FlowLogsEnabled": {
            "type": "boolean"
          },
          "FlowLogsS3Bucket": {},
          "FlowLogsS3Prefix": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "AcceleratorAttributes": {
            "shape": "S17"
          }
        }
      }
    },
    "UpdateEndpointGroup": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointGroupArn"
        ],
        "members": {
          "EndpointGroupArn": {},
          "EndpointConfigurations": {
            "shape": "Sf"
          },
          "TrafficDialPercentage": {
            "type": "float"
          },
          "HealthCheckPort": {
            "type": "integer"
          },
          "HealthCheckProtocol": {},
          "HealthCheckPath": {},
          "HealthCheckIntervalSeconds": {
            "type": "integer"
          },
          "ThresholdCount": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EndpointGroup": {
            "shape": "So"
          }
        }
      }
    },
    "UpdateListener": {
      "input": {
        "type": "structure",
        "required": [
          "ListenerArn"
        ],
        "members": {
          "ListenerArn": {},
          "PortRanges": {
            "shape": "St"
          },
          "Protocol": {},
          "ClientAffinity": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Listener": {
            "shape": "Sz"
          }
        }
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "members": {
        "AcceleratorArn": {},
        "Name": {},
        "IpAddressType": {},
        "Enabled": {
          "type": "boolean"
        },
        "IpSets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "IpFamily": {},
              "IpAddresses": {
                "type": "list",
                "member": {}
              }
            }
          }
        },
        "Status": {},
        "CreatedTime": {
          "type": "timestamp"
        },
        "LastModifiedTime": {
          "type": "timestamp"
        }
      }
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "EndpointId": {},
          "Weight": {
            "type": "integer"
          }
        }
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "EndpointGroupArn": {},
        "EndpointGroupRegion": {},
        "EndpointDescriptions": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "EndpointId": {},
              "Weight": {
                "type": "integer"
              },
              "HealthState": {},
              "HealthReason": {}
            }
          }
        },
        "TrafficDialPercentage": {
          "type": "float"
        },
        "HealthCheckPort": {
          "type": "integer"
        },
        "HealthCheckProtocol": {},
        "HealthCheckPath": {},
        "HealthCheckIntervalSeconds": {
          "type": "integer"
        },
        "ThresholdCount": {
          "type": "integer"
        }
      }
    },
    "St": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "FromPort": {
            "type": "integer"
          },
          "ToPort": {
            "type": "integer"
          }
        }
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "ListenerArn": {},
        "PortRanges": {
          "shape": "St"
        },
        "Protocol": {},
        "ClientAffinity": {}
      }
    },
    "S17": {
      "type": "structure",
      "members": {
        "FlowLogsEnabled": {
          "type": "boolean"
        },
        "FlowLogsS3Bucket": {},
        "FlowLogsS3Prefix": {}
      }
    }
  }
}