{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-03-01",
    "endpointPrefix": "fsx",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "Amazon FSx",
    "serviceId": "FSx",
    "signatureVersion": "v4",
    "targetPrefix": "AWSSimbaAPIService_v20180301",
    "uid": "fsx-2018-03-01"
  },
  "operations": {
    "CreateBackup": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backup": {
            "shape": "S9"
          }
        }
      },
      "idempotent": true
    },
    "CreateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemType",
          "StorageCapacity",
          "SubnetIds"
        ],
        "members": {
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "FileSystemType": {},
          "StorageCapacity": {
            "type": "integer"
          },
          "SubnetIds": {
            "shape": "Sq"
          },
          "SecurityGroupIds": {
            "shape": "S1h"
          },
          "Tags": {
            "shape": "S4"
          },
          "KmsKeyId": {},
          "WindowsConfiguration": {
            "shape": "S1j"
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "ImportPath": {},
              "ExportPath": {},
              "ImportedFileChunkSize": {
                "type": "integer"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sj"
          }
        }
      }
    },
    "CreateFileSystemFromBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId",
          "SubnetIds"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "SubnetIds": {
            "shape": "Sq"
          },
          "SecurityGroupIds": {
            "shape": "S1h"
          },
          "Tags": {
            "shape": "S4"
          },
          "WindowsConfiguration": {
            "shape": "S1j"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sj"
          }
        }
      }
    },
    "DeleteBackup": {
      "input": {
        "type": "structure",
        "required": [
          "BackupId"
        ],
        "members": {
          "BackupId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "BackupId": {},
          "Lifecycle": {}
        }
      },
      "idempotent": true
    },
    "DeleteFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "SkipFinalBackup": {
                "type": "boolean"
              },
              "FinalBackupTags": {
                "shape": "S4"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystemId": {},
          "Lifecycle": {},
          "WindowsResponse": {
            "type": "structure",
            "members": {
              "FinalBackupId": {},
              "FinalBackupTags": {
                "shape": "S4"
              }
            }
          }
        }
      },
      "idempotent": true
    },
    "DescribeBackups": {
      "input": {
        "type": "structure",
        "members": {
          "BackupIds": {
            "type": "list",
            "member": {}
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Backups": {
            "type": "list",
            "member": {
              "shape": "S9"
            }
          },
          "NextToken": {}
        }
      }
    },
    "DescribeFileSystems": {
      "input": {
        "type": "structure",
        "members": {
          "FileSystemIds": {
            "type": "list",
            "member": {}
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystems": {
            "type": "list",
            "member": {
              "shape": "Sj"
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN"
        ],
        "members": {
          "ResourceARN": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S4"
          },
          "NextToken": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "Tags"
        ],
        "members": {
          "ResourceARN": {},
          "Tags": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceARN",
          "TagKeys"
        ],
        "members": {
          "ResourceARN": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      },
      "idempotent": true
    },
    "UpdateFileSystem": {
      "input": {
        "type": "structure",
        "required": [
          "FileSystemId"
        ],
        "members": {
          "FileSystemId": {},
          "ClientRequestToken": {
            "idempotencyToken": true
          },
          "WindowsConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {},
              "DailyAutomaticBackupStartTime": {},
              "AutomaticBackupRetentionDays": {
                "type": "integer"
              },
              "SelfManagedActiveDirectoryConfiguration": {
                "type": "structure",
                "members": {
                  "UserName": {},
                  "Password": {
                    "shape": "S1l"
                  },
                  "DnsIps": {
                    "shape": "S12"
                  }
                }
              }
            }
          },
          "LustreConfiguration": {
            "type": "structure",
            "members": {
              "WeeklyMaintenanceStartTime": {}
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "FileSystem": {
            "shape": "Sj"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S9": {
      "type": "structure",
      "required": [
        "BackupId",
        "Lifecycle",
        "Type",
        "CreationTime",
        "FileSystem"
      ],
      "members": {
        "BackupId": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "Type": {},
        "ProgressPercent": {
          "type": "integer"
        },
        "CreationTime": {
          "type": "timestamp"
        },
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "S4"
        },
        "FileSystem": {
          "shape": "Sj"
        },
        "DirectoryInformation": {
          "type": "structure",
          "members": {
            "DomainName": {},
            "ActiveDirectoryId": {}
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "OwnerId": {},
        "CreationTime": {
          "type": "timestamp"
        },
        "FileSystemId": {},
        "FileSystemType": {},
        "Lifecycle": {},
        "FailureDetails": {
          "type": "structure",
          "members": {
            "Message": {}
          }
        },
        "StorageCapacity": {
          "type": "integer"
        },
        "VpcId": {},
        "SubnetIds": {
          "shape": "Sq"
        },
        "NetworkInterfaceIds": {
          "type": "list",
          "member": {}
        },
        "DNSName": {},
        "KmsKeyId": {},
        "ResourceARN": {},
        "Tags": {
          "shape": "S4"
        },
        "WindowsConfiguration": {
          "type": "structure",
          "members": {
            "ActiveDirectoryId": {},
            "SelfManagedActiveDirectoryConfiguration": {
              "type": "structure",
              "members": {
                "DomainName": {},
                "OrganizationalUnitDistinguishedName": {},
                "FileSystemAdministratorsGroup": {},
                "UserName": {},
                "DnsIps": {
                  "shape": "S12"
                }
              }
            },
            "ThroughputCapacity": {
              "type": "integer"
            },
            "MaintenanceOperationsInProgress": {
              "type": "list",
              "member": {}
            },
            "WeeklyMaintenanceStartTime": {},
            "DailyAutomaticBackupStartTime": {},
            "AutomaticBackupRetentionDays": {
              "type": "integer"
            },
            "CopyTagsToBackups": {
              "type": "boolean"
            }
          }
        },
        "LustreConfiguration": {
          "type": "structure",
          "members": {
            "WeeklyMaintenanceStartTime": {},
            "DataRepositoryConfiguration": {
              "type": "structure",
              "members": {
                "ImportPath": {},
                "ExportPath": {},
                "ImportedFileChunkSize": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "Sq": {
      "type": "list",
      "member": {}
    },
    "S12": {
      "type": "list",
      "member": {}
    },
    "S1h": {
      "type": "list",
      "member": {}
    },
    "S1j": {
      "type": "structure",
      "required": [
        "ThroughputCapacity"
      ],
      "members": {
        "ActiveDirectoryId": {},
        "SelfManagedActiveDirectoryConfiguration": {
          "type": "structure",
          "required": [
            "DomainName",
            "UserName",
            "Password",
            "DnsIps"
          ],
          "members": {
            "DomainName": {},
            "OrganizationalUnitDistinguishedName": {},
            "FileSystemAdministratorsGroup": {},
            "UserName": {},
            "Password": {
              "shape": "S1l"
            },
            "DnsIps": {
              "shape": "S12"
            }
          }
        },
        "ThroughputCapacity": {
          "type": "integer"
        },
        "WeeklyMaintenanceStartTime": {},
        "DailyAutomaticBackupStartTime": {},
        "AutomaticBackupRetentionDays": {
          "type": "integer"
        },
        "CopyTagsToBackups": {
          "type": "boolean"
        }
      }
    },
    "S1l": {
      "type": "string",
      "sensitive": true
    }
  }
}