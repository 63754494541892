import React, { PropsWithChildren } from 'react';
import { SnackbarProvider as SP } from 'notistack';
import { IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';

export const SnackbarProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const notistackRef = React.createRef<SP>();
  const onClickDismiss = (key: React.ReactText) => () => {
    notistackRef?.current?.closeSnackbar(key);
  };

  return (
    <SP
      ref={notistackRef}
      action={(key) => (
        <IconButton color="inherit" size="small" onClick={onClickDismiss(key)}>
          <Close />
        </IconButton>
      )}
      maxSnack={5}
      autoHideDuration={10000}
    >
      {children}
    </SP>
  );
};
