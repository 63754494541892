{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-08-04",
    "endpointPrefix": "firehose",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "Firehose",
    "serviceFullName": "Amazon Kinesis Firehose",
    "serviceId": "Firehose",
    "signatureVersion": "v4",
    "targetPrefix": "Firehose_20150804",
    "uid": "firehose-2015-08-04"
  },
  "operations": {
    "CreateDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "DeliveryStreamType": {},
          "KinesisStreamSourceConfiguration": {
            "type": "structure",
            "required": [
              "KinesisStreamARN",
              "RoleARN"
            ],
            "members": {
              "KinesisStreamARN": {},
              "RoleARN": {}
            }
          },
          "S3DestinationConfiguration": {
            "shape": "S7",
            "deprecated": true
          },
          "ExtendedS3DestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "BucketARN"
            ],
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "ErrorOutputPrefix": {},
              "BufferingHints": {
                "shape": "Sb"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Sf"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "S7"
              },
              "DataFormatConversionConfiguration": {
                "shape": "Sx"
              }
            }
          },
          "RedshiftDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "ClusterJDBCURL",
              "CopyCommand",
              "Username",
              "Password",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "S1o"
              },
              "Username": {
                "shape": "S1s"
              },
              "Password": {
                "shape": "S1t"
              },
              "RetryOptions": {
                "shape": "S1u"
              },
              "S3Configuration": {
                "shape": "S7"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "S3BackupMode": {},
              "S3BackupConfiguration": {
                "shape": "S7"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              }
            }
          },
          "ElasticsearchDestinationConfiguration": {
            "type": "structure",
            "required": [
              "RoleARN",
              "DomainARN",
              "IndexName",
              "TypeName",
              "S3Configuration"
            ],
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S22"
              },
              "RetryOptions": {
                "shape": "S25"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "S7"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              }
            }
          },
          "SplunkDestinationConfiguration": {
            "type": "structure",
            "required": [
              "HECEndpoint",
              "HECEndpointType",
              "HECToken",
              "S3Configuration"
            ],
            "members": {
              "HECEndpoint": {},
              "HECEndpointType": {},
              "HECToken": {},
              "HECAcknowledgmentTimeoutInSeconds": {
                "type": "integer"
              },
              "RetryOptions": {
                "shape": "S2d"
              },
              "S3BackupMode": {},
              "S3Configuration": {
                "shape": "S7"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              }
            }
          },
          "Tags": {
            "shape": "S2g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DeliveryStreamARN": {}
        }
      }
    },
    "DeleteDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Limit": {
            "type": "integer"
          },
          "ExclusiveStartDestinationId": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamDescription"
        ],
        "members": {
          "DeliveryStreamDescription": {
            "type": "structure",
            "required": [
              "DeliveryStreamName",
              "DeliveryStreamARN",
              "DeliveryStreamStatus",
              "DeliveryStreamType",
              "VersionId",
              "Destinations",
              "HasMoreDestinations"
            ],
            "members": {
              "DeliveryStreamName": {},
              "DeliveryStreamARN": {},
              "DeliveryStreamStatus": {},
              "DeliveryStreamEncryptionConfiguration": {
                "type": "structure",
                "members": {
                  "Status": {}
                }
              },
              "DeliveryStreamType": {},
              "VersionId": {},
              "CreateTimestamp": {
                "type": "timestamp"
              },
              "LastUpdateTimestamp": {
                "type": "timestamp"
              },
              "Source": {
                "type": "structure",
                "members": {
                  "KinesisStreamSourceDescription": {
                    "type": "structure",
                    "members": {
                      "KinesisStreamARN": {},
                      "RoleARN": {},
                      "DeliveryStartTimestamp": {
                        "type": "timestamp"
                      }
                    }
                  }
                }
              },
              "Destinations": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "DestinationId"
                  ],
                  "members": {
                    "DestinationId": {},
                    "S3DestinationDescription": {
                      "shape": "S33"
                    },
                    "ExtendedS3DestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "BucketARN",
                        "BufferingHints",
                        "CompressionFormat",
                        "EncryptionConfiguration"
                      ],
                      "members": {
                        "RoleARN": {},
                        "BucketARN": {},
                        "Prefix": {},
                        "ErrorOutputPrefix": {},
                        "BufferingHints": {
                          "shape": "Sb"
                        },
                        "CompressionFormat": {},
                        "EncryptionConfiguration": {
                          "shape": "Sf"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sj"
                        },
                        "ProcessingConfiguration": {
                          "shape": "So"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S33"
                        },
                        "DataFormatConversionConfiguration": {
                          "shape": "Sx"
                        }
                      }
                    },
                    "RedshiftDestinationDescription": {
                      "type": "structure",
                      "required": [
                        "RoleARN",
                        "ClusterJDBCURL",
                        "CopyCommand",
                        "Username",
                        "S3DestinationDescription"
                      ],
                      "members": {
                        "RoleARN": {},
                        "ClusterJDBCURL": {},
                        "CopyCommand": {
                          "shape": "S1o"
                        },
                        "Username": {
                          "shape": "S1s"
                        },
                        "RetryOptions": {
                          "shape": "S1u"
                        },
                        "S3DestinationDescription": {
                          "shape": "S33"
                        },
                        "ProcessingConfiguration": {
                          "shape": "So"
                        },
                        "S3BackupMode": {},
                        "S3BackupDescription": {
                          "shape": "S33"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sj"
                        }
                      }
                    },
                    "ElasticsearchDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "RoleARN": {},
                        "DomainARN": {},
                        "IndexName": {},
                        "TypeName": {},
                        "IndexRotationPeriod": {},
                        "BufferingHints": {
                          "shape": "S22"
                        },
                        "RetryOptions": {
                          "shape": "S25"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S33"
                        },
                        "ProcessingConfiguration": {
                          "shape": "So"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sj"
                        }
                      }
                    },
                    "SplunkDestinationDescription": {
                      "type": "structure",
                      "members": {
                        "HECEndpoint": {},
                        "HECEndpointType": {},
                        "HECToken": {},
                        "HECAcknowledgmentTimeoutInSeconds": {
                          "type": "integer"
                        },
                        "RetryOptions": {
                          "shape": "S2d"
                        },
                        "S3BackupMode": {},
                        "S3DestinationDescription": {
                          "shape": "S33"
                        },
                        "ProcessingConfiguration": {
                          "shape": "So"
                        },
                        "CloudWatchLoggingOptions": {
                          "shape": "Sj"
                        }
                      }
                    }
                  }
                }
              },
              "HasMoreDestinations": {
                "type": "boolean"
              }
            }
          }
        }
      }
    },
    "ListDeliveryStreams": {
      "input": {
        "type": "structure",
        "members": {
          "Limit": {
            "type": "integer"
          },
          "DeliveryStreamType": {},
          "ExclusiveStartDeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DeliveryStreamNames",
          "HasMoreDeliveryStreams"
        ],
        "members": {
          "DeliveryStreamNames": {
            "type": "list",
            "member": {}
          },
          "HasMoreDeliveryStreams": {
            "type": "boolean"
          }
        }
      }
    },
    "ListTagsForDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {},
          "ExclusiveStartTagKey": {},
          "Limit": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags",
          "HasMoreTags"
        ],
        "members": {
          "Tags": {
            "type": "list",
            "member": {
              "shape": "S2h"
            }
          },
          "HasMoreTags": {
            "type": "boolean"
          }
        }
      }
    },
    "PutRecord": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Record"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Record": {
            "shape": "S3h"
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "RecordId"
        ],
        "members": {
          "RecordId": {},
          "Encrypted": {
            "type": "boolean"
          }
        }
      }
    },
    "PutRecordBatch": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Records"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Records": {
            "type": "list",
            "member": {
              "shape": "S3h"
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "FailedPutCount",
          "RequestResponses"
        ],
        "members": {
          "FailedPutCount": {
            "type": "integer"
          },
          "Encrypted": {
            "type": "boolean"
          },
          "RequestResponses": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "RecordId": {},
                "ErrorCode": {},
                "ErrorMessage": {}
              }
            }
          }
        }
      }
    },
    "StartDeliveryStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StopDeliveryStreamEncryption": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName"
        ],
        "members": {
          "DeliveryStreamName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "Tags"
        ],
        "members": {
          "DeliveryStreamName": {},
          "Tags": {
            "shape": "S2g"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagDeliveryStream": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "TagKeys"
        ],
        "members": {
          "DeliveryStreamName": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateDestination": {
      "input": {
        "type": "structure",
        "required": [
          "DeliveryStreamName",
          "CurrentDeliveryStreamVersionId",
          "DestinationId"
        ],
        "members": {
          "DeliveryStreamName": {},
          "CurrentDeliveryStreamVersionId": {},
          "DestinationId": {},
          "S3DestinationUpdate": {
            "shape": "S42",
            "deprecated": true
          },
          "ExtendedS3DestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "BucketARN": {},
              "Prefix": {},
              "ErrorOutputPrefix": {},
              "BufferingHints": {
                "shape": "Sb"
              },
              "CompressionFormat": {},
              "EncryptionConfiguration": {
                "shape": "Sf"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S42"
              },
              "DataFormatConversionConfiguration": {
                "shape": "Sx"
              }
            }
          },
          "RedshiftDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "ClusterJDBCURL": {},
              "CopyCommand": {
                "shape": "S1o"
              },
              "Username": {
                "shape": "S1s"
              },
              "Password": {
                "shape": "S1t"
              },
              "RetryOptions": {
                "shape": "S1u"
              },
              "S3Update": {
                "shape": "S42"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "S3BackupMode": {},
              "S3BackupUpdate": {
                "shape": "S42"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              }
            }
          },
          "ElasticsearchDestinationUpdate": {
            "type": "structure",
            "members": {
              "RoleARN": {},
              "DomainARN": {},
              "IndexName": {},
              "TypeName": {},
              "IndexRotationPeriod": {},
              "BufferingHints": {
                "shape": "S22"
              },
              "RetryOptions": {
                "shape": "S25"
              },
              "S3Update": {
                "shape": "S42"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              }
            }
          },
          "SplunkDestinationUpdate": {
            "type": "structure",
            "members": {
              "HECEndpoint": {},
              "HECEndpointType": {},
              "HECToken": {},
              "HECAcknowledgmentTimeoutInSeconds": {
                "type": "integer"
              },
              "RetryOptions": {
                "shape": "S2d"
              },
              "S3BackupMode": {},
              "S3Update": {
                "shape": "S42"
              },
              "ProcessingConfiguration": {
                "shape": "So"
              },
              "CloudWatchLoggingOptions": {
                "shape": "Sj"
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S7": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Sb"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Sf"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sj"
        }
      }
    },
    "Sb": {
      "type": "structure",
      "members": {
        "SizeInMBs": {
          "type": "integer"
        },
        "IntervalInSeconds": {
          "type": "integer"
        }
      }
    },
    "Sf": {
      "type": "structure",
      "members": {
        "NoEncryptionConfig": {},
        "KMSEncryptionConfig": {
          "type": "structure",
          "required": [
            "AWSKMSKeyARN"
          ],
          "members": {
            "AWSKMSKeyARN": {}
          }
        }
      }
    },
    "Sj": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "LogGroupName": {},
        "LogStreamName": {}
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "Enabled": {
          "type": "boolean"
        },
        "Processors": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "Type"
            ],
            "members": {
              "Type": {},
              "Parameters": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "required": [
                    "ParameterName",
                    "ParameterValue"
                  ],
                  "members": {
                    "ParameterName": {},
                    "ParameterValue": {}
                  }
                }
              }
            }
          }
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "SchemaConfiguration": {
          "type": "structure",
          "members": {
            "RoleARN": {},
            "CatalogId": {},
            "DatabaseName": {},
            "TableName": {},
            "Region": {},
            "VersionId": {}
          }
        },
        "InputFormatConfiguration": {
          "type": "structure",
          "members": {
            "Deserializer": {
              "type": "structure",
              "members": {
                "OpenXJsonSerDe": {
                  "type": "structure",
                  "members": {
                    "ConvertDotsInJsonKeysToUnderscores": {
                      "type": "boolean"
                    },
                    "CaseInsensitive": {
                      "type": "boolean"
                    },
                    "ColumnToJsonKeyMappings": {
                      "type": "map",
                      "key": {},
                      "value": {}
                    }
                  }
                },
                "HiveJsonSerDe": {
                  "type": "structure",
                  "members": {
                    "TimestampFormats": {
                      "type": "list",
                      "member": {}
                    }
                  }
                }
              }
            }
          }
        },
        "OutputFormatConfiguration": {
          "type": "structure",
          "members": {
            "Serializer": {
              "type": "structure",
              "members": {
                "ParquetSerDe": {
                  "type": "structure",
                  "members": {
                    "BlockSizeBytes": {
                      "type": "integer"
                    },
                    "PageSizeBytes": {
                      "type": "integer"
                    },
                    "Compression": {},
                    "EnableDictionaryCompression": {
                      "type": "boolean"
                    },
                    "MaxPaddingBytes": {
                      "type": "integer"
                    },
                    "WriterVersion": {}
                  }
                },
                "OrcSerDe": {
                  "type": "structure",
                  "members": {
                    "StripeSizeBytes": {
                      "type": "integer"
                    },
                    "BlockSizeBytes": {
                      "type": "integer"
                    },
                    "RowIndexStride": {
                      "type": "integer"
                    },
                    "EnablePadding": {
                      "type": "boolean"
                    },
                    "PaddingTolerance": {
                      "type": "double"
                    },
                    "Compression": {},
                    "BloomFilterColumns": {
                      "type": "list",
                      "member": {}
                    },
                    "BloomFilterFalsePositiveProbability": {
                      "type": "double"
                    },
                    "DictionaryKeyThreshold": {
                      "type": "double"
                    },
                    "FormatVersion": {}
                  }
                }
              }
            }
          }
        },
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S1o": {
      "type": "structure",
      "required": [
        "DataTableName"
      ],
      "members": {
        "DataTableName": {},
        "DataTableColumns": {},
        "CopyOptions": {}
      }
    },
    "S1s": {
      "type": "string",
      "sensitive": true
    },
    "S1t": {
      "type": "string",
      "sensitive": true
    },
    "S1u": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S22": {
      "type": "structure",
      "members": {
        "IntervalInSeconds": {
          "type": "integer"
        },
        "SizeInMBs": {
          "type": "integer"
        }
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S2d": {
      "type": "structure",
      "members": {
        "DurationInSeconds": {
          "type": "integer"
        }
      }
    },
    "S2g": {
      "type": "list",
      "member": {
        "shape": "S2h"
      }
    },
    "S2h": {
      "type": "structure",
      "required": [
        "Key"
      ],
      "members": {
        "Key": {},
        "Value": {}
      }
    },
    "S33": {
      "type": "structure",
      "required": [
        "RoleARN",
        "BucketARN",
        "BufferingHints",
        "CompressionFormat",
        "EncryptionConfiguration"
      ],
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Sb"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Sf"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sj"
        }
      }
    },
    "S3h": {
      "type": "structure",
      "required": [
        "Data"
      ],
      "members": {
        "Data": {
          "type": "blob"
        }
      }
    },
    "S42": {
      "type": "structure",
      "members": {
        "RoleARN": {},
        "BucketARN": {},
        "Prefix": {},
        "ErrorOutputPrefix": {},
        "BufferingHints": {
          "shape": "Sb"
        },
        "CompressionFormat": {},
        "EncryptionConfiguration": {
          "shape": "Sf"
        },
        "CloudWatchLoggingOptions": {
          "shape": "Sj"
        }
      }
    }
  }
}