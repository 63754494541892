{
  "pagination": {
    "ListAcceptedPortfolioShares": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListBudgetsForResource": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListConstraintsForPortfolio": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListLaunchPaths": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListOrganizationPortfolioAccess": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListPortfolios": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListPortfoliosForProduct": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListPrincipalsForPortfolio": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListProvisioningArtifactsForServiceAction": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListResourcesForTagOption": {
      "input_token": "PageToken",
      "output_token": "PageToken",
      "limit_key": "PageSize"
    },
    "ListServiceActions": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListServiceActionsForProvisioningArtifact": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "ListTagOptions": {
      "input_token": "PageToken",
      "output_token": "PageToken",
      "limit_key": "PageSize"
    },
    "SearchProducts": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "SearchProductsAsAdmin": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    },
    "SearchProvisionedProducts": {
      "input_token": "PageToken",
      "output_token": "NextPageToken",
      "limit_key": "PageSize"
    }
  }
}
