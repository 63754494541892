{
  "pagination": {
    "DescribeCases": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "cases"
    },
    "DescribeCommunications": {
      "input_token": "nextToken",
      "output_token": "nextToken",
      "limit_key": "maxResults",
      "result_key": "communications"
    },
    "DescribeServices": {
      "result_key": "services"
    },
    "DescribeTrustedAdvisorCheckRefreshStatuses": {
      "result_key": "statuses"
    },
    "DescribeTrustedAdvisorCheckSummaries": {
      "result_key": "summaries"
    }
  }
}
