{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-01-01",
    "endpointPrefix": "dms",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Database Migration Service",
    "serviceId": "Database Migration Service",
    "signatureVersion": "v4",
    "targetPrefix": "AmazonDMSv20160101",
    "uid": "dms-2016-01-01"
  },
  "operations": {
    "AddTagsToResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ApplyPendingMaintenanceAction": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn",
          "ApplyAction",
          "OptInType"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "ApplyAction": {},
          "OptInType": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourcePendingMaintenanceActions": {
            "shape": "S8"
          }
        }
      }
    },
    "CreateEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointIdentifier",
          "EndpointType",
          "EngineName"
        ],
        "members": {
          "EndpointIdentifier": {},
          "EndpointType": {},
          "EngineName": {},
          "Username": {},
          "Password": {
            "shape": "Se"
          },
          "ServerName": {},
          "Port": {
            "type": "integer"
          },
          "DatabaseName": {},
          "ExtraConnectionAttributes": {},
          "KmsKeyId": {},
          "Tags": {
            "shape": "S3"
          },
          "CertificateArn": {},
          "SslMode": {},
          "ServiceAccessRoleArn": {},
          "ExternalTableDefinition": {},
          "DynamoDbSettings": {
            "shape": "Sh"
          },
          "S3Settings": {
            "shape": "Si"
          },
          "DmsTransferSettings": {
            "shape": "Sp"
          },
          "MongoDbSettings": {
            "shape": "Sq"
          },
          "KinesisSettings": {
            "shape": "Su"
          },
          "ElasticsearchSettings": {
            "shape": "Sw"
          },
          "RedshiftSettings": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoint": {
            "shape": "Sz"
          }
        }
      }
    },
    "CreateEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName",
          "SnsTopicArn"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S11"
          },
          "SourceIds": {
            "shape": "S12"
          },
          "Enabled": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S14"
          }
        }
      }
    },
    "CreateReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceIdentifier",
          "ReplicationInstanceClass"
        ],
        "members": {
          "ReplicationInstanceIdentifier": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "ReplicationInstanceClass": {},
          "VpcSecurityGroupIds": {
            "shape": "S17"
          },
          "AvailabilityZone": {},
          "ReplicationSubnetGroupIdentifier": {},
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "Tags": {
            "shape": "S3"
          },
          "KmsKeyId": {},
          "PubliclyAccessible": {
            "type": "boolean"
          },
          "DnsNameServers": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S19"
          }
        }
      }
    },
    "CreateReplicationSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationSubnetGroupIdentifier",
          "ReplicationSubnetGroupDescription",
          "SubnetIds"
        ],
        "members": {
          "ReplicationSubnetGroupIdentifier": {},
          "ReplicationSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1l"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationSubnetGroup": {
            "shape": "S1d"
          }
        }
      }
    },
    "CreateReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskIdentifier",
          "SourceEndpointArn",
          "TargetEndpointArn",
          "ReplicationInstanceArn",
          "MigrationType",
          "TableMappings"
        ],
        "members": {
          "ReplicationTaskIdentifier": {},
          "SourceEndpointArn": {},
          "TargetEndpointArn": {},
          "ReplicationInstanceArn": {},
          "MigrationType": {},
          "TableMappings": {},
          "ReplicationTaskSettings": {},
          "CdcStartTime": {
            "type": "timestamp"
          },
          "CdcStartPosition": {},
          "CdcStopPosition": {},
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S1q"
          }
        }
      }
    },
    "DeleteCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateArn"
        ],
        "members": {
          "CertificateArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {
            "shape": "S1v"
          }
        }
      }
    },
    "DeleteEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoint": {
            "shape": "Sz"
          }
        }
      }
    },
    "DeleteEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S14"
          }
        }
      }
    },
    "DeleteReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S19"
          }
        }
      }
    },
    "DeleteReplicationSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationSubnetGroupIdentifier"
        ],
        "members": {
          "ReplicationSubnetGroupIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S1q"
          }
        }
      }
    },
    "DescribeAccountAttributes": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "AccountQuotas": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountQuotaName": {},
                "Used": {
                  "type": "long"
                },
                "Max": {
                  "type": "long"
                }
              }
            }
          },
          "UniqueAccountIdentifier": {}
        }
      }
    },
    "DescribeCertificates": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Certificates": {
            "type": "list",
            "member": {
              "shape": "S1v"
            }
          }
        }
      }
    },
    "DescribeConnections": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Connections": {
            "type": "list",
            "member": {
              "shape": "S2k"
            }
          }
        }
      }
    },
    "DescribeEndpointTypes": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "SupportedEndpointTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EngineName": {},
                "SupportsCDC": {
                  "type": "boolean"
                },
                "EndpointType": {},
                "EngineDisplayName": {}
              }
            }
          }
        }
      }
    },
    "DescribeEndpoints": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Endpoints": {
            "type": "list",
            "member": {
              "shape": "Sz"
            }
          }
        }
      }
    },
    "DescribeEventCategories": {
      "input": {
        "type": "structure",
        "members": {
          "SourceType": {},
          "Filters": {
            "shape": "S2c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventCategoryGroupList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceType": {},
                "EventCategories": {
                  "shape": "S11"
                }
              }
            }
          }
        }
      }
    },
    "DescribeEventSubscriptions": {
      "input": {
        "type": "structure",
        "members": {
          "SubscriptionName": {},
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "EventSubscriptionsList": {
            "type": "list",
            "member": {
              "shape": "S14"
            }
          }
        }
      }
    },
    "DescribeEvents": {
      "input": {
        "type": "structure",
        "members": {
          "SourceIdentifier": {},
          "SourceType": {},
          "StartTime": {
            "type": "timestamp"
          },
          "EndTime": {
            "type": "timestamp"
          },
          "Duration": {
            "type": "integer"
          },
          "EventCategories": {
            "shape": "S11"
          },
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Events": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SourceIdentifier": {},
                "SourceType": {},
                "Message": {},
                "EventCategories": {
                  "shape": "S11"
                },
                "Date": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "DescribeOrderableReplicationInstances": {
      "input": {
        "type": "structure",
        "members": {
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "OrderableReplicationInstances": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "EngineVersion": {},
                "ReplicationInstanceClass": {},
                "StorageType": {},
                "MinAllocatedStorage": {
                  "type": "integer"
                },
                "MaxAllocatedStorage": {
                  "type": "integer"
                },
                "DefaultAllocatedStorage": {
                  "type": "integer"
                },
                "IncludedAllocatedStorage": {
                  "type": "integer"
                },
                "AvailabilityZones": {
                  "type": "list",
                  "member": {}
                },
                "ReleaseStatus": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribePendingMaintenanceActions": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationInstanceArn": {},
          "Filters": {
            "shape": "S2c"
          },
          "Marker": {},
          "MaxRecords": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PendingMaintenanceActions": {
            "type": "list",
            "member": {
              "shape": "S8"
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeRefreshSchemasStatus": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RefreshSchemasStatus": {
            "shape": "S3f"
          }
        }
      }
    },
    "DescribeReplicationInstanceTaskLogs": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstanceArn": {},
          "ReplicationInstanceTaskLogs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReplicationTaskName": {},
                "ReplicationTaskArn": {},
                "ReplicationInstanceTaskLogSize": {
                  "type": "long"
                }
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "DescribeReplicationInstances": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationInstances": {
            "type": "list",
            "member": {
              "shape": "S19"
            }
          }
        }
      }
    },
    "DescribeReplicationSubnetGroups": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationSubnetGroups": {
            "type": "list",
            "member": {
              "shape": "S1d"
            }
          }
        }
      }
    },
    "DescribeReplicationTaskAssessmentResults": {
      "input": {
        "type": "structure",
        "members": {
          "ReplicationTaskArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "BucketName": {},
          "ReplicationTaskAssessmentResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ReplicationTaskIdentifier": {},
                "ReplicationTaskArn": {},
                "ReplicationTaskLastAssessmentDate": {
                  "type": "timestamp"
                },
                "AssessmentStatus": {},
                "AssessmentResultsFile": {},
                "AssessmentResults": {},
                "S3ObjectUrl": {}
              }
            }
          }
        }
      }
    },
    "DescribeReplicationTasks": {
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "shape": "S2c"
          },
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "WithoutSettings": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "ReplicationTasks": {
            "type": "list",
            "member": {
              "shape": "S1q"
            }
          }
        }
      }
    },
    "DescribeSchemas": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Marker": {},
          "Schemas": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "DescribeTableStatistics": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "MaxRecords": {
            "type": "integer"
          },
          "Marker": {},
          "Filters": {
            "shape": "S2c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTaskArn": {},
          "TableStatistics": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SchemaName": {},
                "TableName": {},
                "Inserts": {
                  "type": "long"
                },
                "Deletes": {
                  "type": "long"
                },
                "Updates": {
                  "type": "long"
                },
                "Ddls": {
                  "type": "long"
                },
                "FullLoadRows": {
                  "type": "long"
                },
                "FullLoadCondtnlChkFailedRows": {
                  "type": "long"
                },
                "FullLoadErrorRows": {
                  "type": "long"
                },
                "LastUpdateTime": {
                  "type": "timestamp"
                },
                "TableState": {},
                "ValidationPendingRecords": {
                  "type": "long"
                },
                "ValidationFailedRecords": {
                  "type": "long"
                },
                "ValidationSuspendedRecords": {
                  "type": "long"
                },
                "ValidationState": {},
                "ValidationStateDetails": {}
              }
            }
          },
          "Marker": {}
        }
      }
    },
    "ImportCertificate": {
      "input": {
        "type": "structure",
        "required": [
          "CertificateIdentifier"
        ],
        "members": {
          "CertificateIdentifier": {},
          "CertificatePem": {},
          "CertificateWallet": {
            "type": "blob"
          },
          "Tags": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Certificate": {
            "shape": "S1v"
          }
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "TagList": {
            "shape": "S3"
          }
        }
      }
    },
    "ModifyEndpoint": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn"
        ],
        "members": {
          "EndpointArn": {},
          "EndpointIdentifier": {},
          "EndpointType": {},
          "EngineName": {},
          "Username": {},
          "Password": {
            "shape": "Se"
          },
          "ServerName": {},
          "Port": {
            "type": "integer"
          },
          "DatabaseName": {},
          "ExtraConnectionAttributes": {},
          "CertificateArn": {},
          "SslMode": {},
          "ServiceAccessRoleArn": {},
          "ExternalTableDefinition": {},
          "DynamoDbSettings": {
            "shape": "Sh"
          },
          "S3Settings": {
            "shape": "Si"
          },
          "DmsTransferSettings": {
            "shape": "Sp"
          },
          "MongoDbSettings": {
            "shape": "Sq"
          },
          "KinesisSettings": {
            "shape": "Su"
          },
          "ElasticsearchSettings": {
            "shape": "Sw"
          },
          "RedshiftSettings": {
            "shape": "Sx"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Endpoint": {
            "shape": "Sz"
          }
        }
      }
    },
    "ModifyEventSubscription": {
      "input": {
        "type": "structure",
        "required": [
          "SubscriptionName"
        ],
        "members": {
          "SubscriptionName": {},
          "SnsTopicArn": {},
          "SourceType": {},
          "EventCategories": {
            "shape": "S11"
          },
          "Enabled": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "EventSubscription": {
            "shape": "S14"
          }
        }
      }
    },
    "ModifyReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "AllocatedStorage": {
            "type": "integer"
          },
          "ApplyImmediately": {
            "type": "boolean"
          },
          "ReplicationInstanceClass": {},
          "VpcSecurityGroupIds": {
            "shape": "S17"
          },
          "PreferredMaintenanceWindow": {},
          "MultiAZ": {
            "type": "boolean"
          },
          "EngineVersion": {},
          "AllowMajorVersionUpgrade": {
            "type": "boolean"
          },
          "AutoMinorVersionUpgrade": {
            "type": "boolean"
          },
          "ReplicationInstanceIdentifier": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S19"
          }
        }
      }
    },
    "ModifyReplicationSubnetGroup": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationSubnetGroupIdentifier",
          "SubnetIds"
        ],
        "members": {
          "ReplicationSubnetGroupIdentifier": {},
          "ReplicationSubnetGroupDescription": {},
          "SubnetIds": {
            "shape": "S1l"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationSubnetGroup": {
            "shape": "S1d"
          }
        }
      }
    },
    "ModifyReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "ReplicationTaskIdentifier": {},
          "MigrationType": {},
          "TableMappings": {},
          "ReplicationTaskSettings": {},
          "CdcStartTime": {
            "type": "timestamp"
          },
          "CdcStartPosition": {},
          "CdcStopPosition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S1q"
          }
        }
      }
    },
    "RebootReplicationInstance": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "ForceFailover": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationInstance": {
            "shape": "S19"
          }
        }
      }
    },
    "RefreshSchemas": {
      "input": {
        "type": "structure",
        "required": [
          "EndpointArn",
          "ReplicationInstanceArn"
        ],
        "members": {
          "EndpointArn": {},
          "ReplicationInstanceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "RefreshSchemasStatus": {
            "shape": "S3f"
          }
        }
      }
    },
    "ReloadTables": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn",
          "TablesToReload"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "TablesToReload": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "SchemaName": {},
                "TableName": {}
              }
            }
          },
          "ReloadOption": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTaskArn": {}
        }
      }
    },
    "RemoveTagsFromResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "StartReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn",
          "StartReplicationTaskType"
        ],
        "members": {
          "ReplicationTaskArn": {},
          "StartReplicationTaskType": {},
          "CdcStartTime": {
            "type": "timestamp"
          },
          "CdcStartPosition": {},
          "CdcStopPosition": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S1q"
          }
        }
      }
    },
    "StartReplicationTaskAssessment": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S1q"
          }
        }
      }
    },
    "StopReplicationTask": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationTaskArn"
        ],
        "members": {
          "ReplicationTaskArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ReplicationTask": {
            "shape": "S1q"
          }
        }
      }
    },
    "TestConnection": {
      "input": {
        "type": "structure",
        "required": [
          "ReplicationInstanceArn",
          "EndpointArn"
        ],
        "members": {
          "ReplicationInstanceArn": {},
          "EndpointArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Connection": {
            "shape": "S2k"
          }
        }
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "ResourceIdentifier": {},
        "PendingMaintenanceActionDetails": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "Action": {},
              "AutoAppliedAfterDate": {
                "type": "timestamp"
              },
              "ForcedApplyDate": {
                "type": "timestamp"
              },
              "OptInStatus": {},
              "CurrentApplyDate": {
                "type": "timestamp"
              },
              "Description": {}
            }
          }
        }
      }
    },
    "Se": {
      "type": "string",
      "sensitive": true
    },
    "Sh": {
      "type": "structure",
      "required": [
        "ServiceAccessRoleArn"
      ],
      "members": {
        "ServiceAccessRoleArn": {}
      }
    },
    "Si": {
      "type": "structure",
      "members": {
        "ServiceAccessRoleArn": {},
        "ExternalTableDefinition": {},
        "CsvRowDelimiter": {},
        "CsvDelimiter": {},
        "BucketFolder": {},
        "BucketName": {},
        "CompressionType": {},
        "EncryptionMode": {},
        "ServerSideEncryptionKmsKeyId": {},
        "DataFormat": {},
        "EncodingType": {},
        "DictPageSizeLimit": {
          "type": "integer"
        },
        "RowGroupLength": {
          "type": "integer"
        },
        "DataPageSize": {
          "type": "integer"
        },
        "ParquetVersion": {},
        "EnableStatistics": {
          "type": "boolean"
        },
        "IncludeOpForFullLoad": {
          "type": "boolean"
        },
        "CdcInsertsOnly": {
          "type": "boolean"
        },
        "TimestampColumnName": {}
      }
    },
    "Sp": {
      "type": "structure",
      "members": {
        "ServiceAccessRoleArn": {},
        "BucketName": {}
      }
    },
    "Sq": {
      "type": "structure",
      "members": {
        "Username": {},
        "Password": {
          "shape": "Se"
        },
        "ServerName": {},
        "Port": {
          "type": "integer"
        },
        "DatabaseName": {},
        "AuthType": {},
        "AuthMechanism": {},
        "NestingLevel": {},
        "ExtractDocId": {},
        "DocsToInvestigate": {},
        "AuthSource": {},
        "KmsKeyId": {}
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "StreamArn": {},
        "MessageFormat": {},
        "ServiceAccessRoleArn": {}
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "ServiceAccessRoleArn",
        "EndpointUri"
      ],
      "members": {
        "ServiceAccessRoleArn": {},
        "EndpointUri": {},
        "FullLoadErrorPercentage": {
          "type": "integer"
        },
        "ErrorRetryDuration": {
          "type": "integer"
        }
      }
    },
    "Sx": {
      "type": "structure",
      "members": {
        "AcceptAnyDate": {
          "type": "boolean"
        },
        "AfterConnectScript": {},
        "BucketFolder": {},
        "BucketName": {},
        "ConnectionTimeout": {
          "type": "integer"
        },
        "DatabaseName": {},
        "DateFormat": {},
        "EmptyAsNull": {
          "type": "boolean"
        },
        "EncryptionMode": {},
        "FileTransferUploadStreams": {
          "type": "integer"
        },
        "LoadTimeout": {
          "type": "integer"
        },
        "MaxFileSize": {
          "type": "integer"
        },
        "Password": {
          "shape": "Se"
        },
        "Port": {
          "type": "integer"
        },
        "RemoveQuotes": {
          "type": "boolean"
        },
        "ReplaceInvalidChars": {},
        "ReplaceChars": {},
        "ServerName": {},
        "ServiceAccessRoleArn": {},
        "ServerSideEncryptionKmsKeyId": {},
        "TimeFormat": {},
        "TrimBlanks": {
          "type": "boolean"
        },
        "TruncateColumns": {
          "type": "boolean"
        },
        "Username": {},
        "WriteBufferSize": {
          "type": "integer"
        }
      }
    },
    "Sz": {
      "type": "structure",
      "members": {
        "EndpointIdentifier": {},
        "EndpointType": {},
        "EngineName": {},
        "EngineDisplayName": {},
        "Username": {},
        "ServerName": {},
        "Port": {
          "type": "integer"
        },
        "DatabaseName": {},
        "ExtraConnectionAttributes": {},
        "Status": {},
        "KmsKeyId": {},
        "EndpointArn": {},
        "CertificateArn": {},
        "SslMode": {},
        "ServiceAccessRoleArn": {},
        "ExternalTableDefinition": {},
        "ExternalId": {},
        "DynamoDbSettings": {
          "shape": "Sh"
        },
        "S3Settings": {
          "shape": "Si"
        },
        "DmsTransferSettings": {
          "shape": "Sp"
        },
        "MongoDbSettings": {
          "shape": "Sq"
        },
        "KinesisSettings": {
          "shape": "Su"
        },
        "ElasticsearchSettings": {
          "shape": "Sw"
        },
        "RedshiftSettings": {
          "shape": "Sx"
        }
      }
    },
    "S11": {
      "type": "list",
      "member": {}
    },
    "S12": {
      "type": "list",
      "member": {}
    },
    "S14": {
      "type": "structure",
      "members": {
        "CustomerAwsId": {},
        "CustSubscriptionId": {},
        "SnsTopicArn": {},
        "Status": {},
        "SubscriptionCreationTime": {},
        "SourceType": {},
        "SourceIdsList": {
          "shape": "S12"
        },
        "EventCategoriesList": {
          "shape": "S11"
        },
        "Enabled": {
          "type": "boolean"
        }
      }
    },
    "S17": {
      "type": "list",
      "member": {}
    },
    "S19": {
      "type": "structure",
      "members": {
        "ReplicationInstanceIdentifier": {},
        "ReplicationInstanceClass": {},
        "ReplicationInstanceStatus": {},
        "AllocatedStorage": {
          "type": "integer"
        },
        "InstanceCreateTime": {
          "type": "timestamp"
        },
        "VpcSecurityGroups": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "VpcSecurityGroupId": {},
              "Status": {}
            }
          }
        },
        "AvailabilityZone": {},
        "ReplicationSubnetGroup": {
          "shape": "S1d"
        },
        "PreferredMaintenanceWindow": {},
        "PendingModifiedValues": {
          "type": "structure",
          "members": {
            "ReplicationInstanceClass": {},
            "AllocatedStorage": {
              "type": "integer"
            },
            "MultiAZ": {
              "type": "boolean"
            },
            "EngineVersion": {}
          }
        },
        "MultiAZ": {
          "type": "boolean"
        },
        "EngineVersion": {},
        "AutoMinorVersionUpgrade": {
          "type": "boolean"
        },
        "KmsKeyId": {},
        "ReplicationInstanceArn": {},
        "ReplicationInstancePublicIpAddress": {
          "deprecated": true
        },
        "ReplicationInstancePrivateIpAddress": {
          "deprecated": true
        },
        "ReplicationInstancePublicIpAddresses": {
          "type": "list",
          "member": {}
        },
        "ReplicationInstancePrivateIpAddresses": {
          "type": "list",
          "member": {}
        },
        "PubliclyAccessible": {
          "type": "boolean"
        },
        "SecondaryAvailabilityZone": {},
        "FreeUntil": {
          "type": "timestamp"
        },
        "DnsNameServers": {}
      }
    },
    "S1d": {
      "type": "structure",
      "members": {
        "ReplicationSubnetGroupIdentifier": {},
        "ReplicationSubnetGroupDescription": {},
        "VpcId": {},
        "SubnetGroupStatus": {},
        "Subnets": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "SubnetIdentifier": {},
              "SubnetAvailabilityZone": {
                "type": "structure",
                "members": {
                  "Name": {}
                }
              },
              "SubnetStatus": {}
            }
          }
        }
      }
    },
    "S1l": {
      "type": "list",
      "member": {}
    },
    "S1q": {
      "type": "structure",
      "members": {
        "ReplicationTaskIdentifier": {},
        "SourceEndpointArn": {},
        "TargetEndpointArn": {},
        "ReplicationInstanceArn": {},
        "MigrationType": {},
        "TableMappings": {},
        "ReplicationTaskSettings": {},
        "Status": {},
        "LastFailureMessage": {},
        "StopReason": {},
        "ReplicationTaskCreationDate": {
          "type": "timestamp"
        },
        "ReplicationTaskStartDate": {
          "type": "timestamp"
        },
        "CdcStartPosition": {},
        "CdcStopPosition": {},
        "RecoveryCheckpoint": {},
        "ReplicationTaskArn": {},
        "ReplicationTaskStats": {
          "type": "structure",
          "members": {
            "FullLoadProgressPercent": {
              "type": "integer"
            },
            "ElapsedTimeMillis": {
              "type": "long"
            },
            "TablesLoaded": {
              "type": "integer"
            },
            "TablesLoading": {
              "type": "integer"
            },
            "TablesQueued": {
              "type": "integer"
            },
            "TablesErrored": {
              "type": "integer"
            }
          }
        }
      }
    },
    "S1v": {
      "type": "structure",
      "members": {
        "CertificateIdentifier": {},
        "CertificateCreationDate": {
          "type": "timestamp"
        },
        "CertificatePem": {},
        "CertificateWallet": {
          "type": "blob"
        },
        "CertificateArn": {},
        "CertificateOwner": {},
        "ValidFromDate": {
          "type": "timestamp"
        },
        "ValidToDate": {
          "type": "timestamp"
        },
        "SigningAlgorithm": {},
        "KeyLength": {
          "type": "integer"
        }
      }
    },
    "S2c": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "Name",
          "Values"
        ],
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S2k": {
      "type": "structure",
      "members": {
        "ReplicationInstanceArn": {},
        "EndpointArn": {},
        "Status": {},
        "LastFailureMessage": {},
        "EndpointIdentifier": {},
        "ReplicationInstanceIdentifier": {}
      }
    },
    "S3f": {
      "type": "structure",
      "members": {
        "EndpointArn": {},
        "ReplicationInstanceArn": {},
        "Status": {},
        "LastRefreshDate": {
          "type": "timestamp"
        },
        "LastFailureMessage": {}
      }
    }
  }
}