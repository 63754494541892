{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2016-10-06",
    "endpointPrefix": "codebuild",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS CodeBuild",
    "serviceId": "CodeBuild",
    "signatureVersion": "v4",
    "targetPrefix": "CodeBuild_20161006",
    "uid": "codebuild-2016-10-06"
  },
  "operations": {
    "BatchDeleteBuilds": {
      "input": {
        "type": "structure",
        "required": [
          "ids"
        ],
        "members": {
          "ids": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "buildsDeleted": {
            "shape": "S2"
          },
          "buildsNotDeleted": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "id": {},
                "statusCode": {}
              }
            }
          }
        }
      }
    },
    "BatchGetBuilds": {
      "input": {
        "type": "structure",
        "required": [
          "ids"
        ],
        "members": {
          "ids": {
            "shape": "S2"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "builds": {
            "type": "list",
            "member": {
              "shape": "Sb"
            }
          },
          "buildsNotFound": {
            "shape": "S2"
          }
        }
      }
    },
    "BatchGetProjects": {
      "input": {
        "type": "structure",
        "required": [
          "names"
        ],
        "members": {
          "names": {
            "shape": "S1k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "projects": {
            "type": "list",
            "member": {
              "shape": "S1n"
            }
          },
          "projectsNotFound": {
            "shape": "S1k"
          }
        }
      }
    },
    "CreateProject": {
      "input": {
        "type": "structure",
        "required": [
          "name",
          "source",
          "artifacts",
          "environment",
          "serviceRole"
        ],
        "members": {
          "name": {},
          "description": {},
          "source": {
            "shape": "Sk"
          },
          "secondarySources": {
            "shape": "Sr"
          },
          "sourceVersion": {},
          "secondarySourceVersions": {
            "shape": "Ss"
          },
          "artifacts": {
            "shape": "S1q"
          },
          "secondaryArtifacts": {
            "shape": "S1u"
          },
          "cache": {
            "shape": "Sw"
          },
          "environment": {
            "shape": "S10"
          },
          "serviceRole": {},
          "timeoutInMinutes": {
            "type": "integer"
          },
          "queuedTimeoutInMinutes": {
            "type": "integer"
          },
          "encryptionKey": {},
          "tags": {
            "shape": "S1w"
          },
          "vpcConfig": {
            "shape": "S1f"
          },
          "badgeEnabled": {
            "type": "boolean"
          },
          "logsConfig": {
            "shape": "S26"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "S1n"
          }
        }
      }
    },
    "CreateWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "branchFilter": {},
          "filterGroups": {
            "shape": "S21"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "webhook": {
            "shape": "S20"
          }
        }
      }
    },
    "DeleteProject": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSourceCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "arn"
        ],
        "members": {
          "arn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {}
        }
      }
    },
    "DeleteWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ImportSourceCredentials": {
      "input": {
        "type": "structure",
        "required": [
          "token",
          "serverType",
          "authType"
        ],
        "members": {
          "username": {},
          "token": {
            "type": "string",
            "sensitive": true
          },
          "serverType": {},
          "authType": {},
          "shouldOverwrite": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "arn": {}
        }
      }
    },
    "InvalidateProjectCache": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListBuilds": {
      "input": {
        "type": "structure",
        "members": {
          "sortOrder": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ids": {
            "shape": "S2"
          },
          "nextToken": {}
        }
      }
    },
    "ListBuildsForProject": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "sortOrder": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ids": {
            "shape": "S2"
          },
          "nextToken": {}
        }
      }
    },
    "ListCuratedEnvironmentImages": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "platforms": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "platform": {},
                "languages": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "language": {},
                      "images": {
                        "type": "list",
                        "member": {
                          "type": "structure",
                          "members": {
                            "name": {},
                            "description": {},
                            "versions": {
                              "type": "list",
                              "member": {}
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    },
    "ListProjects": {
      "input": {
        "type": "structure",
        "members": {
          "sortBy": {},
          "sortOrder": {},
          "nextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "projects": {
            "shape": "S1k"
          }
        }
      }
    },
    "ListSourceCredentials": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "sourceCredentialsInfos": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "arn": {},
                "serverType": {},
                "authType": {}
              }
            }
          }
        }
      }
    },
    "StartBuild": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "secondarySourcesOverride": {
            "shape": "Sr"
          },
          "secondarySourcesVersionOverride": {
            "shape": "Ss"
          },
          "sourceVersion": {},
          "artifactsOverride": {
            "shape": "S1q"
          },
          "secondaryArtifactsOverride": {
            "shape": "S1u"
          },
          "environmentVariablesOverride": {
            "shape": "S13"
          },
          "sourceTypeOverride": {},
          "sourceLocationOverride": {},
          "sourceAuthOverride": {
            "shape": "Sp"
          },
          "gitCloneDepthOverride": {
            "type": "integer"
          },
          "gitSubmodulesConfigOverride": {
            "shape": "Sn"
          },
          "buildspecOverride": {},
          "insecureSslOverride": {
            "type": "boolean"
          },
          "reportBuildStatusOverride": {
            "type": "boolean"
          },
          "environmentTypeOverride": {},
          "imageOverride": {},
          "computeTypeOverride": {},
          "certificateOverride": {},
          "cacheOverride": {
            "shape": "Sw"
          },
          "serviceRoleOverride": {},
          "privilegedModeOverride": {
            "type": "boolean"
          },
          "timeoutInMinutesOverride": {
            "type": "integer"
          },
          "queuedTimeoutInMinutesOverride": {
            "type": "integer"
          },
          "idempotencyToken": {},
          "logsConfigOverride": {
            "shape": "S26"
          },
          "registryCredentialOverride": {
            "shape": "S16"
          },
          "imagePullCredentialsTypeOverride": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "build": {
            "shape": "Sb"
          }
        }
      }
    },
    "StopBuild": {
      "input": {
        "type": "structure",
        "required": [
          "id"
        ],
        "members": {
          "id": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "build": {
            "shape": "Sb"
          }
        }
      }
    },
    "UpdateProject": {
      "input": {
        "type": "structure",
        "required": [
          "name"
        ],
        "members": {
          "name": {},
          "description": {},
          "source": {
            "shape": "Sk"
          },
          "secondarySources": {
            "shape": "Sr"
          },
          "sourceVersion": {},
          "secondarySourceVersions": {
            "shape": "Ss"
          },
          "artifacts": {
            "shape": "S1q"
          },
          "secondaryArtifacts": {
            "shape": "S1u"
          },
          "cache": {
            "shape": "Sw"
          },
          "environment": {
            "shape": "S10"
          },
          "serviceRole": {},
          "timeoutInMinutes": {
            "type": "integer"
          },
          "queuedTimeoutInMinutes": {
            "type": "integer"
          },
          "encryptionKey": {},
          "tags": {
            "shape": "S1w"
          },
          "vpcConfig": {
            "shape": "S1f"
          },
          "badgeEnabled": {
            "type": "boolean"
          },
          "logsConfig": {
            "shape": "S26"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "project": {
            "shape": "S1n"
          }
        }
      }
    },
    "UpdateWebhook": {
      "input": {
        "type": "structure",
        "required": [
          "projectName"
        ],
        "members": {
          "projectName": {},
          "branchFilter": {},
          "rotateSecret": {
            "type": "boolean"
          },
          "filterGroups": {
            "shape": "S21"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "webhook": {
            "shape": "S20"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "structure",
      "members": {
        "id": {},
        "arn": {},
        "startTime": {
          "type": "timestamp"
        },
        "endTime": {
          "type": "timestamp"
        },
        "currentPhase": {},
        "buildStatus": {},
        "sourceVersion": {},
        "resolvedSourceVersion": {},
        "projectName": {},
        "phases": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "phaseType": {},
              "phaseStatus": {},
              "startTime": {
                "type": "timestamp"
              },
              "endTime": {
                "type": "timestamp"
              },
              "durationInSeconds": {
                "type": "long"
              },
              "contexts": {
                "type": "list",
                "member": {
                  "type": "structure",
                  "members": {
                    "statusCode": {},
                    "message": {}
                  }
                }
              }
            }
          }
        },
        "source": {
          "shape": "Sk"
        },
        "secondarySources": {
          "shape": "Sr"
        },
        "secondarySourceVersions": {
          "shape": "Ss"
        },
        "artifacts": {
          "shape": "Su"
        },
        "secondaryArtifacts": {
          "type": "list",
          "member": {
            "shape": "Su"
          }
        },
        "cache": {
          "shape": "Sw"
        },
        "environment": {
          "shape": "S10"
        },
        "serviceRole": {},
        "logs": {
          "type": "structure",
          "members": {
            "groupName": {},
            "streamName": {},
            "deepLink": {},
            "s3DeepLink": {},
            "cloudWatchLogs": {
              "shape": "S1a"
            },
            "s3Logs": {
              "shape": "S1c"
            }
          }
        },
        "timeoutInMinutes": {
          "type": "integer"
        },
        "queuedTimeoutInMinutes": {
          "type": "integer"
        },
        "buildComplete": {
          "type": "boolean"
        },
        "initiator": {},
        "vpcConfig": {
          "shape": "S1f"
        },
        "networkInterface": {
          "type": "structure",
          "members": {
            "subnetId": {},
            "networkInterfaceId": {}
          }
        },
        "encryptionKey": {}
      }
    },
    "Sk": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "location": {},
        "gitCloneDepth": {
          "type": "integer"
        },
        "gitSubmodulesConfig": {
          "shape": "Sn"
        },
        "buildspec": {},
        "auth": {
          "shape": "Sp"
        },
        "reportBuildStatus": {
          "type": "boolean"
        },
        "insecureSsl": {
          "type": "boolean"
        },
        "sourceIdentifier": {}
      }
    },
    "Sn": {
      "type": "structure",
      "required": [
        "fetchSubmodules"
      ],
      "members": {
        "fetchSubmodules": {
          "type": "boolean"
        }
      }
    },
    "Sp": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "resource": {}
      }
    },
    "Sr": {
      "type": "list",
      "member": {
        "shape": "Sk"
      }
    },
    "Ss": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "sourceIdentifier",
          "sourceVersion"
        ],
        "members": {
          "sourceIdentifier": {},
          "sourceVersion": {}
        }
      }
    },
    "Su": {
      "type": "structure",
      "members": {
        "location": {},
        "sha256sum": {},
        "md5sum": {},
        "overrideArtifactName": {
          "type": "boolean"
        },
        "encryptionDisabled": {
          "type": "boolean"
        },
        "artifactIdentifier": {}
      }
    },
    "Sw": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "location": {},
        "modes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S10": {
      "type": "structure",
      "required": [
        "type",
        "image",
        "computeType"
      ],
      "members": {
        "type": {},
        "image": {},
        "computeType": {},
        "environmentVariables": {
          "shape": "S13"
        },
        "privilegedMode": {
          "type": "boolean"
        },
        "certificate": {},
        "registryCredential": {
          "shape": "S16"
        },
        "imagePullCredentialsType": {}
      }
    },
    "S13": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "name",
          "value"
        ],
        "members": {
          "name": {},
          "value": {},
          "type": {}
        }
      }
    },
    "S16": {
      "type": "structure",
      "required": [
        "credential",
        "credentialProvider"
      ],
      "members": {
        "credential": {},
        "credentialProvider": {}
      }
    },
    "S1a": {
      "type": "structure",
      "required": [
        "status"
      ],
      "members": {
        "status": {},
        "groupName": {},
        "streamName": {}
      }
    },
    "S1c": {
      "type": "structure",
      "required": [
        "status"
      ],
      "members": {
        "status": {},
        "location": {},
        "encryptionDisabled": {
          "type": "boolean"
        }
      }
    },
    "S1f": {
      "type": "structure",
      "members": {
        "vpcId": {},
        "subnets": {
          "type": "list",
          "member": {}
        },
        "securityGroupIds": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S1k": {
      "type": "list",
      "member": {}
    },
    "S1n": {
      "type": "structure",
      "members": {
        "name": {},
        "arn": {},
        "description": {},
        "source": {
          "shape": "Sk"
        },
        "secondarySources": {
          "shape": "Sr"
        },
        "sourceVersion": {},
        "secondarySourceVersions": {
          "shape": "Ss"
        },
        "artifacts": {
          "shape": "S1q"
        },
        "secondaryArtifacts": {
          "shape": "S1u"
        },
        "cache": {
          "shape": "Sw"
        },
        "environment": {
          "shape": "S10"
        },
        "serviceRole": {},
        "timeoutInMinutes": {
          "type": "integer"
        },
        "queuedTimeoutInMinutes": {
          "type": "integer"
        },
        "encryptionKey": {},
        "tags": {
          "shape": "S1w"
        },
        "created": {
          "type": "timestamp"
        },
        "lastModified": {
          "type": "timestamp"
        },
        "webhook": {
          "shape": "S20"
        },
        "vpcConfig": {
          "shape": "S1f"
        },
        "badge": {
          "type": "structure",
          "members": {
            "badgeEnabled": {
              "type": "boolean"
            },
            "badgeRequestUrl": {}
          }
        },
        "logsConfig": {
          "shape": "S26"
        }
      }
    },
    "S1q": {
      "type": "structure",
      "required": [
        "type"
      ],
      "members": {
        "type": {},
        "location": {},
        "path": {},
        "namespaceType": {},
        "name": {},
        "packaging": {},
        "overrideArtifactName": {
          "type": "boolean"
        },
        "encryptionDisabled": {
          "type": "boolean"
        },
        "artifactIdentifier": {}
      }
    },
    "S1u": {
      "type": "list",
      "member": {
        "shape": "S1q"
      }
    },
    "S1w": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "key": {},
          "value": {}
        }
      }
    },
    "S20": {
      "type": "structure",
      "members": {
        "url": {},
        "payloadUrl": {},
        "secret": {},
        "branchFilter": {},
        "filterGroups": {
          "shape": "S21"
        },
        "lastModifiedSecret": {
          "type": "timestamp"
        }
      }
    },
    "S21": {
      "type": "list",
      "member": {
        "type": "list",
        "member": {
          "type": "structure",
          "required": [
            "type",
            "pattern"
          ],
          "members": {
            "type": {},
            "pattern": {},
            "excludeMatchedPattern": {
              "type": "boolean"
            }
          }
        }
      }
    },
    "S26": {
      "type": "structure",
      "members": {
        "cloudWatchLogs": {
          "shape": "S1a"
        },
        "s3Logs": {
          "shape": "S1c"
        }
      }
    }
  }
}