import { createTheme } from '@mui/material/styles';

interface ExtraColors {
  secondaryHover: string;
  productHighlight: string;
  progressLeft: string;
  progressRight: string;
  headlineBackgroundLeft: string;
  headlineBackgroundRight: string;
  gradientLeft: string;
  gradientRight: string;
  helpButtonBackground: string;
  helpButtonBackgroundMobile: string;
  helpButtonText: string;
}

declare module '@mui/material/styles' {
  interface Theme {
    extraColors: ExtraColors;
  }
  interface ThemeOptions {
    extraColors?: ExtraColors;
  }
}

const VARS = {
  colorText: '#333333',
  colorTextTitle: '#666666',
  colorPrimary: '#00ffd0',
  colorPrimaryDarker: '#00d9b1',
  colorPrimaryLighter: '#00ffd0',
  colorSecondary: '#333333',
  colorSecondaryLighter: '#676767',
  colorSecondaryDarker: '#242424',
  colorSecondaryBg: '#f5f5f5',
  colorSecondaryBgDarker: '#eaeaea',
  colorBackground: '#ffffff',
  colorError: '#e74b3c',
  colorGoogle: '#4d87ed',
  colorFacebook: '#415b93',
  colorProductHighlight: '#b5203a',
  colorWhite: '#ffffff',
  customSpacing: '6.5px',
  colorDarkPrimary: '#006878',
  colorLightPrimary: '#e7fdf1',
};

export const theme = createTheme({
  palette: {
    primary: {
      main: VARS.colorPrimary,
      light: VARS.colorPrimaryLighter,
      dark: VARS.colorPrimaryDarker,
      contrastText: VARS.colorText,
    },
    secondary: {
      main: VARS.colorSecondary,
      light: VARS.colorSecondaryLighter,
      dark: VARS.colorSecondaryDarker,
    },
    background: {
      default: VARS.colorBackground,
    },
    text: {
      primary: VARS.colorText,
    },
    error: {
      main: VARS.colorError,
    },
  },
  typography: {
    fontFamily: 'Open Sans, sans-serif',
    htmlFontSize: 16,
    fontSize: 14,
    allVariants: {
      letterSpacing: '-0.3px',
    },
  },
  extraColors: {
    secondaryHover: VARS.colorSecondaryBg,
    productHighlight: VARS.colorProductHighlight,
    progressLeft: VARS.colorPrimary,
    progressRight: VARS.colorPrimaryDarker,
    headlineBackgroundLeft: VARS.colorLightPrimary,
    headlineBackgroundRight: VARS.colorLightPrimary,
    gradientLeft: VARS.colorPrimary,
    gradientRight: VARS.colorPrimaryDarker,
    helpButtonText: VARS.colorWhite,
    helpButtonBackground: VARS.colorDarkPrimary,
    helpButtonBackgroundMobile: VARS.colorDarkPrimary,
  },
  components: {
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        disableElevation: true,
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontSize: '15px',
          fontWeight: 'bold',
          borderRadius: '3px',
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        item: {
          padding: VARS.customSpacing,
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 13,
          color: VARS.colorWhite,
          backgroundColor: VARS.colorSecondary,
          fontFamily: 'inherit',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: VARS.colorSecondary,
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        subtitle2: {
          color: VARS.colorText,
          fontSize: 14,
          fontWeight: 'normal',
          lineHeight: 1.3,
        },
        subtitle1: {
          color: VARS.colorText,
          fontSize: 16,
          fontWeight: 'normal',
          lineHeight: 1.5,
        },
        h6: {
          color: VARS.colorText,
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
        h5: {
          color: VARS.colorText,
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
        h4: {
          color: VARS.colorText,
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
        h3: {
          color: VARS.colorText,
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
        h2: {
          color: VARS.colorTextTitle,
          fontWeight: 600,
          lineHeight: 1.5,
          marginBottom: 8,
        },
        h1: {
          color: VARS.colorText,
          fontWeight: 'bold',
          lineHeight: 1.5,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '@global': {
          html: {
            margin: 0,
            padding: 0,
          },
          body: {
            margin: 0,
            padding: 0,
            fontSize: '16px',
            color: VARS.colorText,
          },
          '#root': {
            height: '100%',
          },
          a: {
            color: VARS.colorSecondary,
          },
          'input:-webkit-autofill': {
            transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
          },
          'input, select, textarea': {
            fontSize: '16px',
          },
          'input[type=date]': {
            cursor: 'pointer',
          },
          'input[type=date]::-webkit-calendar-picker-indicator': {
            cursor: 'pointer',
          },
        },
      },
    },
  },
});
