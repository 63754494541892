import GTM from '@mineko-io/react-gtm-module';
import Util from './Util';
import { Config } from './Config';
import { DataLayer } from './DataLayer';
import { CustomWindow } from './CustomWindow';


class TagManager {
  private config: Config;

  private tagManager: typeof GTM;

  private window: CustomWindow;

  private static instance: TagManager;

  private constructor(config: Config, tagManager: typeof GTM, window: CustomWindow) {
    this.config = config;
    this.window = window;
    this.tagManager = tagManager;
    this.tagManager.initialize({
      gtmId: this.config.containerId,
      ...this.config,
    });
  }

  static getInstance(config: Config, tagManager: typeof GTM, window: CustomWindow): TagManager {
    if (!TagManager.instance) {
      TagManager.instance = new TagManager(config, tagManager, window);
    }
    return TagManager.instance;
  }

  static getInitializedInstance(): TagManager {
    if (!TagManager.instance) throw new Error('TagManger not initialized');
    return TagManager.instance;
  }

  private log(msg: string): void {
    if (this.config.debug) Util.log(msg);
  }

  private getGTMEventCallback(
    customEventCallback: Function,
    resolve: Function,
  ): Function {
    return (containerId: string) => {
      if (!this.config.containerId || this.config.containerId === containerId) {
        this.log('GTM callback');
        resolve(customEventCallback(containerId));
      }
    };
  }

  private getDataLayerPromise(
    dataLayer: DataLayer,
    eventCallback: Function,
  ): Promise<any> {
    return new Promise((resolve) => {
      if (!this.window.google_tag_manager) {
        this.log('No GTM loaded, will resolve.');
        return resolve(eventCallback());
      }

      return this.tagManager.dataLayer({
        dataLayer: {
          ...dataLayer,
          eventCallback: this.getGTMEventCallback(eventCallback, resolve),
        },
      });
    });
  }

  public dataLayer(dataLayer: DataLayer, timeout = 1500): Promise<any> {
    const { eventCallback = () => {}, eventTimeout = timeout } = dataLayer;

    return Util.promiseTimeout(
      timeout,
      this.getDataLayerPromise({ ...dataLayer, eventTimeout }, eventCallback),
      eventCallback,
    );
  }
}

const init = (config: Config): TagManager => TagManager.getInstance(config, GTM, window);
const getInstance = (): TagManager => TagManager.getInitializedInstance();

export { TagManager };
export default { init, getInstance };
