{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2018-08-01",
    "endpointPrefix": "license-manager",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS License Manager",
    "serviceId": "License Manager",
    "signatureVersion": "v4",
    "targetPrefix": "AWSLicenseManager",
    "uid": "license-manager-2018-08-01"
  },
  "operations": {
    "CreateLicenseConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "LicenseCountingType"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "LicenseCountingType": {},
          "LicenseCount": {
            "type": "long"
          },
          "LicenseCountHardLimit": {
            "type": "boolean"
          },
          "LicenseRules": {
            "shape": "S6"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LicenseConfigurationArn": {}
        }
      }
    },
    "DeleteLicenseConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LicenseConfigurationArn"
        ],
        "members": {
          "LicenseConfigurationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "GetLicenseConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LicenseConfigurationArn"
        ],
        "members": {
          "LicenseConfigurationArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LicenseConfigurationId": {},
          "LicenseConfigurationArn": {},
          "Name": {},
          "Description": {},
          "LicenseCountingType": {},
          "LicenseRules": {
            "shape": "S6"
          },
          "LicenseCount": {
            "type": "long"
          },
          "LicenseCountHardLimit": {
            "type": "boolean"
          },
          "ConsumedLicenses": {
            "type": "long"
          },
          "Status": {},
          "OwnerAccountId": {},
          "ConsumedLicenseSummaryList": {
            "shape": "Se"
          },
          "ManagedResourceSummaryList": {
            "shape": "Sh"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "GetServiceSettings": {
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "S3BucketArn": {},
          "SnsTopicArn": {},
          "OrganizationConfiguration": {
            "shape": "Sl"
          },
          "EnableCrossAccountsDiscovery": {
            "type": "boolean"
          }
        }
      }
    },
    "ListAssociationsForLicenseConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LicenseConfigurationArn"
        ],
        "members": {
          "LicenseConfigurationArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LicenseConfigurationAssociations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceArn": {},
                "ResourceType": {},
                "ResourceOwnerId": {},
                "AssociationTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLicenseConfigurations": {
      "input": {
        "type": "structure",
        "members": {
          "LicenseConfigurationArns": {
            "shape": "S6"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LicenseConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "LicenseConfigurationId": {},
                "LicenseConfigurationArn": {},
                "Name": {},
                "Description": {},
                "LicenseCountingType": {},
                "LicenseRules": {
                  "shape": "S6"
                },
                "LicenseCount": {
                  "type": "long"
                },
                "LicenseCountHardLimit": {
                  "type": "boolean"
                },
                "ConsumedLicenses": {
                  "type": "long"
                },
                "Status": {},
                "OwnerAccountId": {},
                "ConsumedLicenseSummaryList": {
                  "shape": "Se"
                },
                "ManagedResourceSummaryList": {
                  "shape": "Sh"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListLicenseSpecificationsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LicenseSpecifications": {
            "shape": "S14"
          },
          "NextToken": {}
        }
      }
    },
    "ListResourceInventory": {
      "input": {
        "type": "structure",
        "members": {
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Condition"
              ],
              "members": {
                "Name": {},
                "Condition": {},
                "Value": {}
              }
            }
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceInventoryList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceId": {},
                "ResourceType": {},
                "ResourceArn": {},
                "Platform": {},
                "PlatformVersion": {},
                "ResourceOwningAccountId": {}
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "ListUsageForLicenseConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LicenseConfigurationArn"
        ],
        "members": {
          "LicenseConfigurationArn": {},
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {},
          "Filters": {
            "shape": "Su"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "LicenseConfigurationUsageList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ResourceArn": {},
                "ResourceType": {},
                "ResourceStatus": {},
                "ResourceOwnerId": {},
                "AssociationTime": {
                  "type": "timestamp"
                },
                "ConsumedLicenses": {
                  "type": "long"
                }
              }
            }
          },
          "NextToken": {}
        }
      }
    },
    "TagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "Tags"
        ],
        "members": {
          "ResourceArn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UntagResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn",
          "TagKeys"
        ],
        "members": {
          "ResourceArn": {},
          "TagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLicenseConfiguration": {
      "input": {
        "type": "structure",
        "required": [
          "LicenseConfigurationArn"
        ],
        "members": {
          "LicenseConfigurationArn": {},
          "LicenseConfigurationStatus": {},
          "LicenseRules": {
            "shape": "S6"
          },
          "LicenseCount": {
            "type": "long"
          },
          "LicenseCountHardLimit": {
            "type": "boolean"
          },
          "Name": {},
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateLicenseSpecificationsForResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "AddLicenseSpecifications": {
            "shape": "S14"
          },
          "RemoveLicenseSpecifications": {
            "shape": "S14"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateServiceSettings": {
      "input": {
        "type": "structure",
        "members": {
          "S3BucketArn": {},
          "SnsTopicArn": {},
          "OrganizationConfiguration": {
            "shape": "Sl"
          },
          "EnableCrossAccountsDiscovery": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S6": {
      "type": "list",
      "member": {}
    },
    "S7": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "Se": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "ConsumedLicenses": {
            "type": "long"
          }
        }
      }
    },
    "Sh": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceType": {},
          "AssociationCount": {
            "type": "long"
          }
        }
      }
    },
    "Sl": {
      "type": "structure",
      "required": [
        "EnableIntegration"
      ],
      "members": {
        "EnableIntegration": {
          "type": "boolean"
        }
      }
    },
    "Su": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Name": {},
          "Values": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "S14": {
      "type": "list",
      "member": {
        "type": "structure",
        "required": [
          "LicenseConfigurationArn"
        ],
        "members": {
          "LicenseConfigurationArn": {}
        }
      }
    }
  }
}