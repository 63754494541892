import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import React, { forwardRef, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import { Autocomplete, Select, TableCell } from '@mui/material';
import { useHotkeys } from 'react-hotkeys-hook';
import { KEYS_TO_ACTIVATE_CELL } from './helpers';
const formatNumber = (value) => {
    if (value === null)
        return value;
    const parsed = parseFloat(value.toString());
    return Number.isNaN(parsed) ? null : parsed;
};
const cellStyles = (isSelected) => css `
  background-color: none;
  user-select: none;
  padding: 0 5px;
  position: relative;
  box-sizing: border-box;
  border-radius: 3px;
  ${isSelected &&
    css `
    border: 2px solid rgba(0, 0, 255, 0.6);
  `}
`;
const absolutePositioningStyles = css `
  top: 0;
  left: 0;
  position: absolute;
`;
const inputStyles = ({ align, isAbsolute = true }) => css `
  ${isAbsolute && absolutePositioningStyles}
  padding: 0 5px;
  border-radius: 0px;
  box-sizing: border-box;
  border: none;
  width: 100%;
  height: 100%;
  font-size: inherit;
  font-family: inherit;
  letter-spacing: inherit;
  outline: none;
  text-align: ${align};
  background-color: white;
`;
const TableCellWrapper = forwardRef(({ children, ...rest }, ref) => (_jsx(TableCell, { ref: ref, tabIndex: -1, ...rest, children: children })));
const TableEditableCellBase = ({ isSelected, isActive, onClick, onValueChange, value, renderValue, type = 'text', options, activateCell, selectNextCell, ...rest }) => {
    const [localValue, setLocalValue] = useState(value?.toString() ?? '');
    const ref = useHotkeys(KEYS_TO_ACTIVATE_CELL, (e) => {
        if (activateCell && isSelected && !isActive) {
            e.preventDefault();
            setLocalValue(e.key);
            activateCell();
        }
    }, [isSelected, isActive]);
    useEffect(() => {
        if (value)
            setLocalValue(value.toString());
    }, [value]);
    const handleChange = (e) => {
        setLocalValue(e.target.value);
    };
    const handleAutoCompleteChange = (_, newValue) => {
        setLocalValue(newValue ?? '');
    };
    const triggerChange = () => {
        let value = localValue;
        if (type === 'number') {
            value = formatNumber(value);
        }
        onValueChange(value);
    };
    const handleOnBlur = () => {
        if (localValue !== value?.toString()) {
            triggerChange();
        }
    };
    const handleKeyDown = (e) => {
        switch (e.key) {
            case 'Enter':
                if (type === 'autocomplete') {
                    break;
                }
                e.preventDefault();
                triggerChange();
                selectNextCell && selectNextCell();
                break;
            case 'Tab':
            case 'Escape':
                e.preventDefault();
                triggerChange();
                break;
        }
    };
    if (isActive) {
        switch (type) {
            case 'select':
                return (_jsx(TableCellWrapper, { ref: ref, css: cellStyles(isSelected), onClick: onClick, ...rest, children: _jsx(Select, { css: inputStyles({ align: rest.align }), native: true, value: localValue, onChange: handleChange, onBlur: handleOnBlur, children: options &&
                            options.map((option) => (_jsx("option", { value: option, children: option }, option))) }) }));
            case 'autocomplete':
                return (_jsx(TableCellWrapper, { ref: ref, css: cellStyles(isSelected), onClick: onClick, ...rest, children: _jsx(Autocomplete, { options: options ?? [], openOnFocus: true, css: inputStyles({ align: rest.align, isAbsolute: true }), onChange: handleAutoCompleteChange, onKeyDown: handleKeyDown, inputValue: localValue, renderInput: (params) => (_jsx("div", { ref: params.InputProps.ref, css: css `
                    height: 100%;
                  `, children: _jsx("input", { type: "text", autoFocus: true, ...params.inputProps, onChange: handleChange, onBlur: handleOnBlur, css: inputStyles({ align: rest.align, isAbsolute: false }) }) })) }) }));
            case 'number':
            case 'text':
                return (_jsxs(TableCellWrapper, { ref: ref, css: cellStyles(isSelected), onClick: onClick, ...rest, children: [_jsx("input", { autoFocus: true, css: inputStyles({ align: rest.align }), type: type, value: localValue, onBlur: handleOnBlur, onChange: handleChange, onKeyDown: handleKeyDown }), ";"] }));
            default:
                throw new Error(`Cell type ${type} is not supported`);
        }
    }
    return (_jsx(TableCellWrapper, { ref: ref, css: cellStyles(isSelected), onClick: onClick, ...rest, children: renderValue ? renderValue(value) : value }));
};
export const TableEditableCell = React.memo(TableEditableCellBase);
