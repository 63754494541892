import { createTheme } from '@mui/material/styles';
const VARS = {
    colorWhite: '#fff',
    colorPrimary: '#67ae48',
    colorPrimaryLighter: '#6eb64f',
    colorPrimaryDarker: '#61a344',
    colorSecondary: '#1ba282',
    colorSecondaryLighter: '#6ec7b5',
    colorSecondaryContrast: '#f3f9f1',
    colorSecondaryDarker: '#178c70',
    colorSecondaryBg: '#d6ebe6',
    colorPrimaryBg: '#e0ebe4',
    colorSecondaryBgDarker: '#bedfd7',
    colorText: '#4a4a4a',
    colorTextTitle: '#666666',
    colorBackground: '#fff',
    colorError: '#f04e4e',
    colorGoogle: '#4d87ed',
    colorFacebook: '#415b93',
    colorProductHighlight: '#b5203a',
    customSpacing: '6.5px',
};
export const theme = createTheme({
    palette: {
        primary: {
            main: VARS.colorPrimary,
            light: VARS.colorPrimaryLighter,
            contrastText: VARS.colorWhite,
        },
        secondary: {
            main: VARS.colorSecondary,
            light: VARS.colorSecondaryLighter,
            dark: VARS.colorSecondaryDarker,
            contrastText: VARS.colorWhite,
        },
        success: {
            main: VARS.colorSecondary,
            light: VARS.colorSecondaryLighter,
            dark: VARS.colorSecondaryDarker,
            contrastText: VARS.colorWhite,
        },
        background: {
            default: VARS.colorBackground,
        },
        text: {
            primary: VARS.colorText,
        },
        error: {
            main: VARS.colorError,
        },
    },
    mixins: {
        toolbar: {
            height: '70px',
        },
    },
    typography: {
        fontFamily: 'Open Sans, sans-serif',
        htmlFontSize: 16,
        fontSize: 14,
        allVariants: {
            letterSpacing: '-0.3px',
        },
    },
    extraColors: {
        secondaryContrast: VARS.colorSecondaryContrast,
        secondaryHover: VARS.colorSecondaryBg,
        productHighlight: VARS.colorProductHighlight,
        progressLeft: VARS.colorSecondaryLighter,
        progressRight: VARS.colorSecondary,
        headlineBackgroundLeft: VARS.colorPrimary,
        headlineBackgroundRight: VARS.colorSecondary,
        gradientLeft: VARS.colorPrimary,
        gradientRight: VARS.colorSecondary,
        helpButtonText: VARS.colorWhite,
        helpButtonBackground: VARS.colorSecondary,
        helpButtonBackgroundMobile: VARS.colorSecondaryDarker,
    },
    components: {
        MuiButton: {
            defaultProps: {
                disableRipple: true,
                disableElevation: true,
            },
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontSize: '15px',
                    fontWeight: 'bold',
                    borderRadius: '3px',
                },
                containedPrimary: {
                    backgroundImage: `linear-gradient(to right, ${VARS.colorPrimary}, ${VARS.colorSecondary})`,
                    '&:hover': {
                        backgroundImage: `linear-gradient(to right, ${VARS.colorPrimaryLighter}, ${VARS.colorSecondaryLighter})`,
                    },
                    '&.Mui-disabled': {
                        backgroundImage: 'none',
                    },
                },
                containedSecondary: {
                    backgroundColor: VARS.colorSecondaryBg,
                    color: VARS.colorSecondary,
                    '&:hover': {
                        backgroundColor: VARS.colorSecondaryBgDarker,
                    },
                },
            },
        },
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    fontSize: 13,
                    color: VARS.colorWhite,
                    backgroundColor: VARS.colorSecondary,
                    fontFamily: 'inherit',
                },
            },
        },
        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    color: VARS.colorText,
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    fontSize: 16,
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                subtitle2: {
                    color: VARS.colorText,
                    fontSize: 14,
                    fontWeight: 'normal',
                    lineHeight: 1.3,
                },
                subtitle1: {
                    color: VARS.colorText,
                    fontSize: 16,
                    fontWeight: 'normal',
                    lineHeight: 1.5,
                },
                h6: {
                    color: VARS.colorText,
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    fontSize: '14px',
                },
                h5: {
                    color: VARS.colorText,
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    fontSize: '16px',
                },
                h4: {
                    color: VARS.colorText,
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    fontSize: '18px',
                },
                h3: {
                    color: VARS.colorText,
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    fontSize: '20px',
                },
                h2: {
                    color: VARS.colorTextTitle,
                    fontWeight: 600,
                    lineHeight: 1.5,
                    marginBottom: 8,
                    fontSize: '22px',
                },
                h1: {
                    color: VARS.colorText,
                    fontWeight: 'bold',
                    lineHeight: 1.5,
                    fontSize: '24px',
                },
            },
        },
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    html: {
                        margin: 0,
                        padding: 0,
                    },
                    body: {
                        margin: 0,
                        padding: 0,
                        fontSize: '16px',
                        color: VARS.colorText,
                    },
                    '#root': {
                        height: '100%',
                    },
                    a: {
                        color: VARS.colorSecondary,
                    },
                    'input:-webkit-autofill': {
                        transition: 'background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s',
                    },
                    'input, select, textarea': {
                        fontSize: '16px',
                    },
                    'input[type=date]': {
                        cursor: 'pointer',
                    },
                    'input[type=date]::-webkit-calendar-picker-indicator': {
                        cursor: 'pointer',
                    },
                },
            },
        },
    },
});
