import { jsx as _jsx, jsxs as _jsxs } from "@emotion/react/jsx-runtime";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Divider, useTheme, } from '@mui/material';
import { WarningAmber, CheckCircleOutline, ErrorOutline, HelpOutline } from '@mui/icons-material';
import { css } from '@emotion/react';
const getAccentColor = (theme, variant) => {
    const accentColorMap = {
        error: theme.palette.error.main,
        warning: theme.palette.warning.main,
        success: theme.palette.secondary.main,
        question: theme.palette.secondary.main,
    };
    return accentColorMap[variant];
};
const getStyles = (theme, variant) => ({
    dialog: css `
    padding: 0;
    :before {
      display: block;
      content: '';
      width: 100%;
      height: 10px;
      background-color: ${getAccentColor(theme, variant)};
    }
  `,
    dialogContent: css `
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  `,
    dialogText: css `
    padding: 20px 24px;
  `,
    dialogTitle: css `
    padding: 0;
    margin-bottom: ${theme.spacing(1)};
  `,
    dialogIcon: css `
    width: 80px;
    height: 80px;
    margin: 20px 0 0 20px;
  `,
});
const buttonColorMap = {
    error: 'error',
    warning: 'warning',
    success: 'success',
    question: 'success',
};
export const ConfirmationDialog = (props) => {
    const { onCancel, onConfirm, variant = 'success', dialogTitle, children, cancelText, confirmText, disableConfirmButton = false, ...rest } = props;
    const theme = useTheme();
    const styles = getStyles(theme, variant);
    const dialogIconMap = {
        error: _jsx(ErrorOutline, { color: "error", css: styles.dialogIcon }),
        warning: _jsx(WarningAmber, { color: "warning", css: styles.dialogIcon }),
        success: _jsx(CheckCircleOutline, { color: "secondary", css: styles.dialogIcon }),
        question: _jsx(HelpOutline, { color: "secondary", css: styles.dialogIcon }),
    };
    return (_jsxs(Dialog, { ...rest, children: [_jsx(DialogContent, { css: styles.dialog, children: _jsxs("div", { css: styles.dialogContent, children: [dialogIconMap[variant], _jsxs("div", { css: styles.dialogText, children: [dialogTitle && _jsx(DialogTitle, { css: styles.dialogTitle, children: dialogTitle }), children] })] }) }), _jsx(Divider, { light: true }), _jsxs(DialogActions, { children: [_jsx(Button, { variant: "contained", color: "inherit", onClick: onCancel, children: cancelText }), _jsx(Button, { variant: "contained", color: buttonColorMap[variant], onClick: onConfirm, disabled: disableConfirmButton, children: confirmText })] })] }));
};
