import React from 'react';
import { css } from '@emotion/react';
import { Typography } from '@mui/material';

const pageHeadingStyles = css`
  font-weight: bold;
  margin-bottom: 24px;
`;

export const PageHeading = ({ children, ...rest }: Parameters<typeof Typography>[0]) => (
  <Typography variant="body1" css={pageHeadingStyles} {...rest}>
    {children}
  </Typography>
);
