{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2015-05-28",
    "endpointPrefix": "iot",
    "protocol": "rest-json",
    "serviceFullName": "AWS IoT",
    "serviceId": "IoT",
    "signatureVersion": "v4",
    "signingName": "execute-api",
    "uid": "iot-2015-05-28"
  },
  "operations": {
    "AcceptCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/accept-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      }
    },
    "AddThingToBillingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/billing-groups/addThingToBillingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AddThingToThingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/addThingToThingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingName": {},
          "thingArn": {},
          "overrideDynamicGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AssociateTargetsWithJob": {
      "http": {
        "requestUri": "/jobs/{jobId}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "targets",
          "jobId"
        ],
        "members": {
          "targets": {
            "shape": "Sg"
          },
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "comment": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "AttachPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/target-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "target"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "target": {}
        }
      }
    },
    "AttachPrincipalPolicy": {
      "http": {
        "method": "PUT",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      },
      "deprecated": true
    },
    "AttachSecurityProfile": {
      "http": {
        "method": "PUT",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName",
          "securityProfileTargetArn"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "AttachThingPrincipal": {
      "http": {
        "method": "PUT",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelAuditMitigationActionsTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/mitigationactions/tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelAuditTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/audit/tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CancelCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/cancel-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      }
    },
    "CancelJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/jobs/{jobId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "reasonCode": {},
          "comment": {},
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "CancelJobExecution": {
      "http": {
        "method": "PUT",
        "requestUri": "/things/{thingName}/jobs/{jobId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          },
          "expectedVersion": {
            "type": "long"
          },
          "statusDetails": {
            "shape": "S1b"
          }
        }
      }
    },
    "ClearDefaultAuthorizer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "CreateAuthorizer": {
      "http": {
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName",
          "authorizerFunctionArn",
          "tokenKeyName",
          "tokenSigningPublicKeys"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "authorizerFunctionArn": {},
          "tokenKeyName": {},
          "tokenSigningPublicKeys": {
            "shape": "S1k"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "CreateBillingGroup": {
      "http": {
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "billingGroupProperties": {
            "shape": "S1r"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "billingGroupId": {}
        }
      }
    },
    "CreateCertificateFromCsr": {
      "http": {
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateSigningRequest"
        ],
        "members": {
          "certificateSigningRequest": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {}
        }
      }
    },
    "CreateDynamicThingGroup": {
      "http": {
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "queryString"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S25"
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {},
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingGroupId": {},
          "indexName": {},
          "queryString": {},
          "queryVersion": {}
        }
      }
    },
    "CreateJob": {
      "http": {
        "method": "PUT",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "targets"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "targets": {
            "shape": "Sg"
          },
          "documentSource": {},
          "document": {},
          "description": {},
          "presignedUrlConfig": {
            "shape": "S2k"
          },
          "targetSelection": {},
          "jobExecutionsRolloutConfig": {
            "shape": "S2o"
          },
          "abortConfig": {
            "shape": "S2v"
          },
          "timeoutConfig": {
            "shape": "S32"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobArn": {},
          "jobId": {},
          "description": {}
        }
      }
    },
    "CreateKeysAndCertificate": {
      "http": {
        "requestUri": "/keys-and-certificate"
      },
      "input": {
        "type": "structure",
        "members": {
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "certificatePem": {},
          "keyPair": {
            "type": "structure",
            "members": {
              "PublicKey": {},
              "PrivateKey": {
                "type": "string",
                "sensitive": true
              }
            }
          }
        }
      }
    },
    "CreateMitigationAction": {
      "http": {
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName",
          "roleArn",
          "actionParams"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          },
          "roleArn": {},
          "actionParams": {
            "shape": "S3c"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionArn": {},
          "actionId": {}
        }
      }
    },
    "CreateOTAUpdate": {
      "http": {
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId",
          "targets",
          "files",
          "roleArn"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          },
          "description": {},
          "targets": {
            "shape": "S3v"
          },
          "targetSelection": {},
          "awsJobExecutionsRolloutConfig": {
            "shape": "S3x"
          },
          "files": {
            "shape": "S3z"
          },
          "roleArn": {},
          "additionalParameters": {
            "shape": "S4w"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdateId": {},
          "awsIotJobId": {},
          "otaUpdateArn": {},
          "awsIotJobArn": {},
          "otaUpdateStatus": {}
        }
      }
    },
    "CreatePolicy": {
      "http": {
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {}
        }
      }
    },
    "CreatePolicyVersion": {
      "http": {
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyDocument"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyDocument": {},
          "setAsDefault": {
            "location": "querystring",
            "locationName": "setAsDefault",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          }
        }
      }
    },
    "CreateRoleAlias": {
      "http": {
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias",
          "roleArn"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          },
          "roleArn": {},
          "credentialDurationSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAlias": {},
          "roleAliasArn": {}
        }
      }
    },
    "CreateScheduledAudit": {
      "http": {
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "frequency",
          "targetCheckNames",
          "scheduledAuditName"
        ],
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S5k"
          },
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAuditArn": {}
        }
      }
    },
    "CreateSecurityProfile": {
      "http": {
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S5r"
          },
          "alertTargets": {
            "shape": "S68"
          },
          "additionalMetricsToRetain": {
            "shape": "S6c"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {}
        }
      }
    },
    "CreateStream": {
      "http": {
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId",
          "files",
          "roleArn"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "description": {},
          "files": {
            "shape": "S6h"
          },
          "roleArn": {},
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamId": {},
          "streamArn": {},
          "description": {},
          "streamVersion": {
            "type": "integer"
          }
        }
      }
    },
    "CreateThing": {
      "http": {
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "S27"
          },
          "billingGroupName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingName": {},
          "thingArn": {},
          "thingId": {}
        }
      }
    },
    "CreateThingGroup": {
      "http": {
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "parentGroupName": {},
          "thingGroupProperties": {
            "shape": "S25"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingGroupId": {}
        }
      }
    },
    "CreateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "thingTypeProperties": {
            "shape": "S6t"
          },
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeArn": {},
          "thingTypeId": {}
        }
      }
    },
    "CreateTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "S71"
          },
          "tags": {
            "location": "header",
            "locationName": "x-amz-tagging"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "DeleteAccountAuditConfiguration": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {
          "deleteScheduledAudits": {
            "location": "querystring",
            "locationName": "deleteScheduledAudits",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteAuthorizer": {
      "http": {
        "method": "DELETE",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteBillingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCACertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteCertificate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "forceDelete": {
            "location": "querystring",
            "locationName": "forceDelete",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteDynamicThingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteJob": {
      "http": {
        "method": "DELETE",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteJobExecution": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/jobs/{jobId}/executionNumber/{executionNumber}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName",
          "executionNumber"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "executionNumber": {
            "location": "uri",
            "locationName": "executionNumber",
            "type": "long"
          },
          "force": {
            "location": "querystring",
            "locationName": "force",
            "type": "boolean"
          }
        }
      }
    },
    "DeleteMitigationAction": {
      "http": {
        "method": "DELETE",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteOTAUpdate": {
      "http": {
        "method": "DELETE",
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          },
          "deleteStream": {
            "location": "querystring",
            "locationName": "deleteStream",
            "type": "boolean"
          },
          "forceDeleteAWSJob": {
            "location": "querystring",
            "locationName": "forceDeleteAWSJob",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeletePolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      }
    },
    "DeletePolicyVersion": {
      "http": {
        "method": "DELETE",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "DeleteRegistrationCode": {
      "http": {
        "method": "DELETE",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteRoleAlias": {
      "http": {
        "method": "DELETE",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteScheduledAudit": {
      "http": {
        "method": "DELETE",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteSecurityProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteStream": {
      "http": {
        "method": "DELETE",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThing": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThingGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteThingType": {
      "http": {
        "method": "DELETE",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DeleteTopicRule": {
      "http": {
        "method": "DELETE",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "DeleteV2LoggingLevel": {
      "http": {
        "method": "DELETE",
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "required": [
          "targetType",
          "targetName"
        ],
        "members": {
          "targetType": {
            "location": "querystring",
            "locationName": "targetType"
          },
          "targetName": {
            "location": "querystring",
            "locationName": "targetName"
          }
        }
      }
    },
    "DeprecateThingType": {
      "http": {
        "requestUri": "/thing-types/{thingTypeName}/deprecate"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          },
          "undoDeprecate": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeAccountAuditConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "auditNotificationTargetConfigurations": {
            "shape": "Sa4"
          },
          "auditCheckConfigurations": {
            "shape": "Sa8"
          }
        }
      }
    },
    "DescribeAuditFinding": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/findings/{findingId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "findingId"
        ],
        "members": {
          "findingId": {
            "location": "uri",
            "locationName": "findingId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "finding": {
            "shape": "Sad"
          }
        }
      }
    },
    "DescribeAuditMitigationActionsTask": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskStatus": {},
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          },
          "taskStatistics": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "totalFindingsCount": {
                  "type": "long"
                },
                "failedFindingsCount": {
                  "type": "long"
                },
                "succeededFindingsCount": {
                  "type": "long"
                },
                "skippedFindingsCount": {
                  "type": "long"
                },
                "canceledFindingsCount": {
                  "type": "long"
                }
              }
            }
          },
          "target": {
            "shape": "Sb2"
          },
          "auditCheckToActionsMapping": {
            "shape": "Sb6"
          },
          "actionsDefinition": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "name": {},
                "id": {},
                "roleArn": {},
                "actionParams": {
                  "shape": "S3c"
                }
              }
            }
          }
        }
      }
    },
    "DescribeAuditTask": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskStatus": {},
          "taskType": {},
          "taskStartTime": {
            "type": "timestamp"
          },
          "taskStatistics": {
            "type": "structure",
            "members": {
              "totalChecks": {
                "type": "integer"
              },
              "inProgressChecks": {
                "type": "integer"
              },
              "waitingForDataCollectionChecks": {
                "type": "integer"
              },
              "compliantChecks": {
                "type": "integer"
              },
              "nonCompliantChecks": {
                "type": "integer"
              },
              "failedChecks": {
                "type": "integer"
              },
              "canceledChecks": {
                "type": "integer"
              }
            }
          },
          "scheduledAuditName": {},
          "auditDetails": {
            "type": "map",
            "key": {},
            "value": {
              "type": "structure",
              "members": {
                "checkRunStatus": {},
                "checkCompliant": {
                  "type": "boolean"
                },
                "totalResourcesCount": {
                  "type": "long"
                },
                "nonCompliantResourcesCount": {
                  "type": "long"
                },
                "errorCode": {},
                "message": {}
              }
            }
          }
        }
      }
    },
    "DescribeAuthorizer": {
      "http": {
        "method": "GET",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerDescription": {
            "shape": "Sbw"
          }
        }
      }
    },
    "DescribeBillingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupId": {},
          "billingGroupArn": {},
          "version": {
            "type": "long"
          },
          "billingGroupProperties": {
            "shape": "S1r"
          },
          "billingGroupMetadata": {
            "type": "structure",
            "members": {
              "creationDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeCACertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "autoRegistrationStatus": {},
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "customerVersion": {
                "type": "integer"
              },
              "generationId": {},
              "validity": {
                "shape": "Sca"
              }
            }
          },
          "registrationConfig": {
            "shape": "Scb"
          }
        }
      }
    },
    "DescribeCertificate": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateDescription": {
            "type": "structure",
            "members": {
              "certificateArn": {},
              "certificateId": {},
              "caCertificateId": {},
              "status": {},
              "certificatePem": {},
              "ownedBy": {},
              "previousOwnedBy": {},
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "customerVersion": {
                "type": "integer"
              },
              "transferData": {
                "type": "structure",
                "members": {
                  "transferMessage": {},
                  "rejectReason": {},
                  "transferDate": {
                    "type": "timestamp"
                  },
                  "acceptDate": {
                    "type": "timestamp"
                  },
                  "rejectDate": {
                    "type": "timestamp"
                  }
                }
              },
              "generationId": {},
              "validity": {
                "shape": "Sca"
              }
            }
          }
        }
      }
    },
    "DescribeDefaultAuthorizer": {
      "http": {
        "method": "GET",
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerDescription": {
            "shape": "Sbw"
          }
        }
      }
    },
    "DescribeEndpoint": {
      "http": {
        "method": "GET",
        "requestUri": "/endpoint"
      },
      "input": {
        "type": "structure",
        "members": {
          "endpointType": {
            "location": "querystring",
            "locationName": "endpointType"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "endpointAddress": {}
        }
      }
    },
    "DescribeEventConfigurations": {
      "http": {
        "method": "GET",
        "requestUri": "/event-configurations"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "eventConfigurations": {
            "shape": "Scr"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeIndex": {
      "http": {
        "method": "GET",
        "requestUri": "/indices/{indexName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "indexName"
        ],
        "members": {
          "indexName": {
            "location": "uri",
            "locationName": "indexName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indexName": {},
          "indexStatus": {},
          "schema": {}
        }
      }
    },
    "DescribeJob": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "documentSource": {},
          "job": {
            "type": "structure",
            "members": {
              "jobArn": {},
              "jobId": {},
              "targetSelection": {},
              "status": {},
              "forceCanceled": {
                "type": "boolean"
              },
              "reasonCode": {},
              "comment": {},
              "targets": {
                "shape": "Sg"
              },
              "description": {},
              "presignedUrlConfig": {
                "shape": "S2k"
              },
              "jobExecutionsRolloutConfig": {
                "shape": "S2o"
              },
              "abortConfig": {
                "shape": "S2v"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "completedAt": {
                "type": "timestamp"
              },
              "jobProcessDetails": {
                "type": "structure",
                "members": {
                  "processingTargets": {
                    "type": "list",
                    "member": {}
                  },
                  "numberOfCanceledThings": {
                    "type": "integer"
                  },
                  "numberOfSucceededThings": {
                    "type": "integer"
                  },
                  "numberOfFailedThings": {
                    "type": "integer"
                  },
                  "numberOfRejectedThings": {
                    "type": "integer"
                  },
                  "numberOfQueuedThings": {
                    "type": "integer"
                  },
                  "numberOfInProgressThings": {
                    "type": "integer"
                  },
                  "numberOfRemovedThings": {
                    "type": "integer"
                  },
                  "numberOfTimedOutThings": {
                    "type": "integer"
                  }
                }
              },
              "timeoutConfig": {
                "shape": "S32"
              }
            }
          }
        }
      }
    },
    "DescribeJobExecution": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId",
          "thingName"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "executionNumber": {
            "location": "querystring",
            "locationName": "executionNumber",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "execution": {
            "type": "structure",
            "members": {
              "jobId": {},
              "status": {},
              "forceCanceled": {
                "type": "boolean"
              },
              "statusDetails": {
                "type": "structure",
                "members": {
                  "detailsMap": {
                    "shape": "S1b"
                  }
                }
              },
              "thingArn": {},
              "queuedAt": {
                "type": "timestamp"
              },
              "startedAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "executionNumber": {
                "type": "long"
              },
              "versionNumber": {
                "type": "long"
              },
              "approximateSecondsBeforeTimedOut": {
                "type": "long"
              }
            }
          }
        }
      }
    },
    "DescribeMitigationAction": {
      "http": {
        "method": "GET",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionName": {},
          "actionType": {},
          "actionArn": {},
          "actionId": {},
          "roleArn": {},
          "actionParams": {
            "shape": "S3c"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeRoleAlias": {
      "http": {
        "method": "GET",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAliasDescription": {
            "type": "structure",
            "members": {
              "roleAlias": {},
              "roleAliasArn": {},
              "roleArn": {},
              "owner": {},
              "credentialDurationSeconds": {
                "type": "integer"
              },
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              }
            }
          }
        }
      }
    },
    "DescribeScheduledAudit": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S5k"
          },
          "scheduledAuditName": {},
          "scheduledAuditArn": {}
        }
      }
    },
    "DescribeSecurityProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {},
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S5r"
          },
          "alertTargets": {
            "shape": "S68"
          },
          "additionalMetricsToRetain": {
            "shape": "S6c"
          },
          "version": {
            "type": "long"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "DescribeStream": {
      "http": {
        "method": "GET",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamInfo": {
            "type": "structure",
            "members": {
              "streamId": {},
              "streamArn": {},
              "streamVersion": {
                "type": "integer"
              },
              "description": {},
              "files": {
                "shape": "S6h"
              },
              "createdAt": {
                "type": "timestamp"
              },
              "lastUpdatedAt": {
                "type": "timestamp"
              },
              "roleArn": {}
            }
          }
        }
      }
    },
    "DescribeThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "defaultClientId": {},
          "thingName": {},
          "thingId": {},
          "thingArn": {},
          "thingTypeName": {},
          "attributes": {
            "shape": "S28"
          },
          "version": {
            "type": "long"
          },
          "billingGroupName": {}
        }
      }
    },
    "DescribeThingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupId": {},
          "thingGroupArn": {},
          "version": {
            "type": "long"
          },
          "thingGroupProperties": {
            "shape": "S25"
          },
          "thingGroupMetadata": {
            "type": "structure",
            "members": {
              "parentGroupName": {},
              "rootToParentThingGroups": {
                "shape": "Se4"
              },
              "creationDate": {
                "type": "timestamp"
              }
            }
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {},
          "status": {}
        }
      }
    },
    "DescribeThingRegistrationTask": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "templateBody": {},
          "inputFileBucket": {},
          "inputFileKey": {},
          "roleArn": {},
          "status": {},
          "message": {},
          "successCount": {
            "type": "integer"
          },
          "failureCount": {
            "type": "integer"
          },
          "percentageProgress": {
            "type": "integer"
          }
        }
      }
    },
    "DescribeThingType": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types/{thingTypeName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingTypeName"
        ],
        "members": {
          "thingTypeName": {
            "location": "uri",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypeName": {},
          "thingTypeId": {},
          "thingTypeArn": {},
          "thingTypeProperties": {
            "shape": "S6t"
          },
          "thingTypeMetadata": {
            "shape": "Seh"
          }
        }
      }
    },
    "DetachPolicy": {
      "http": {
        "requestUri": "/target-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "target"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "target": {}
        }
      }
    },
    "DetachPrincipalPolicy": {
      "http": {
        "method": "DELETE",
        "requestUri": "/principal-policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "principal"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          }
        }
      },
      "deprecated": true
    },
    "DetachSecurityProfile": {
      "http": {
        "method": "DELETE",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName",
          "securityProfileTargetArn"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DetachThingPrincipal": {
      "http": {
        "method": "DELETE",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName",
          "principal"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DisableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/disable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "EnableTopicRule": {
      "http": {
        "requestUri": "/rules/{ruleName}/enable"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      }
    },
    "GetEffectivePolicies": {
      "http": {
        "requestUri": "/effective-policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "principal": {},
          "cognitoIdentityPoolId": {},
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "effectivePolicies": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "policyName": {},
                "policyArn": {},
                "policyDocument": {}
              }
            }
          }
        }
      }
    },
    "GetIndexingConfiguration": {
      "http": {
        "method": "GET",
        "requestUri": "/indexing/config"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "thingIndexingConfiguration": {
            "shape": "Sey"
          },
          "thingGroupIndexingConfiguration": {
            "shape": "Sf1"
          }
        }
      }
    },
    "GetJobDocument": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}/job-document"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "document": {}
        }
      }
    },
    "GetLoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "logLevel": {}
        }
      }
    },
    "GetOTAUpdate": {
      "http": {
        "method": "GET",
        "requestUri": "/otaUpdates/{otaUpdateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "otaUpdateId"
        ],
        "members": {
          "otaUpdateId": {
            "location": "uri",
            "locationName": "otaUpdateId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdateInfo": {
            "type": "structure",
            "members": {
              "otaUpdateId": {},
              "otaUpdateArn": {},
              "creationDate": {
                "type": "timestamp"
              },
              "lastModifiedDate": {
                "type": "timestamp"
              },
              "description": {},
              "targets": {
                "shape": "S3v"
              },
              "awsJobExecutionsRolloutConfig": {
                "shape": "S3x"
              },
              "targetSelection": {},
              "otaUpdateFiles": {
                "shape": "S3z"
              },
              "otaUpdateStatus": {},
              "awsIotJobId": {},
              "awsIotJobArn": {},
              "errorInfo": {
                "type": "structure",
                "members": {
                  "code": {},
                  "message": {}
                }
              },
              "additionalParameters": {
                "shape": "S4w"
              }
            }
          }
        }
      }
    },
    "GetPolicy": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {},
          "policyDocument": {},
          "defaultVersionId": {},
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "generationId": {}
        }
      }
    },
    "GetPolicyVersion": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyArn": {},
          "policyName": {},
          "policyDocument": {},
          "policyVersionId": {},
          "isDefaultVersion": {
            "type": "boolean"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          },
          "generationId": {}
        }
      }
    },
    "GetRegistrationCode": {
      "http": {
        "method": "GET",
        "requestUri": "/registrationcode"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "registrationCode": {}
        }
      }
    },
    "GetStatistics": {
      "http": {
        "requestUri": "/indices/statistics"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "aggregationField": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "statistics": {
            "type": "structure",
            "members": {
              "count": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "GetTopicRule": {
      "http": {
        "method": "GET",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ruleArn": {},
          "rule": {
            "type": "structure",
            "members": {
              "ruleName": {},
              "sql": {},
              "description": {},
              "createdAt": {
                "type": "timestamp"
              },
              "actions": {
                "shape": "S74"
              },
              "ruleDisabled": {
                "type": "boolean"
              },
              "awsIotSqlVersion": {},
              "errorAction": {
                "shape": "S75"
              }
            }
          }
        }
      }
    },
    "GetV2LoggingOptions": {
      "http": {
        "method": "GET",
        "requestUri": "/v2LoggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {}
      },
      "output": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "defaultLogLevel": {},
          "disableAllLogs": {
            "type": "boolean"
          }
        }
      }
    },
    "ListActiveViolations": {
      "http": {
        "method": "GET",
        "requestUri": "/active-violations"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "securityProfileName": {
            "location": "querystring",
            "locationName": "securityProfileName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "activeViolations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "violationId": {},
                "thingName": {},
                "securityProfileName": {},
                "behavior": {
                  "shape": "S5s"
                },
                "lastViolationValue": {
                  "shape": "S5x"
                },
                "lastViolationTime": {
                  "type": "timestamp"
                },
                "violationStartTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAttachedPolicies": {
      "http": {
        "requestUri": "/attached-policies/{target}"
      },
      "input": {
        "type": "structure",
        "required": [
          "target"
        ],
        "members": {
          "target": {
            "location": "uri",
            "locationName": "target"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Sg9"
          },
          "nextMarker": {}
        }
      }
    },
    "ListAuditFindings": {
      "http": {
        "requestUri": "/audit/findings"
      },
      "input": {
        "type": "structure",
        "members": {
          "taskId": {},
          "checkName": {},
          "resourceIdentifier": {
            "shape": "Sai"
          },
          "maxResults": {
            "type": "integer"
          },
          "nextToken": {},
          "startTime": {
            "type": "timestamp"
          },
          "endTime": {
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "findings": {
            "type": "list",
            "member": {
              "shape": "Sad"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditMitigationActionsExecutions": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/executions"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "findingId"
        ],
        "members": {
          "taskId": {
            "location": "querystring",
            "locationName": "taskId"
          },
          "actionStatus": {
            "location": "querystring",
            "locationName": "actionStatus"
          },
          "findingId": {
            "location": "querystring",
            "locationName": "findingId"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionsExecutions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "findingId": {},
                "actionName": {},
                "actionId": {},
                "status": {},
                "startTime": {
                  "type": "timestamp"
                },
                "endTime": {
                  "type": "timestamp"
                },
                "errorCode": {},
                "message": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditMitigationActionsTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/mitigationactions/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "auditTaskId": {
            "location": "querystring",
            "locationName": "auditTaskId"
          },
          "findingId": {
            "location": "querystring",
            "locationName": "findingId"
          },
          "taskStatus": {
            "location": "querystring",
            "locationName": "taskStatus"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "startTime": {
                  "type": "timestamp"
                },
                "taskStatus": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuditTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "taskType": {
            "location": "querystring",
            "locationName": "taskType"
          },
          "taskStatus": {
            "location": "querystring",
            "locationName": "taskStatus"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tasks": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "taskId": {},
                "taskStatus": {},
                "taskType": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListAuthorizers": {
      "http": {
        "method": "GET",
        "requestUri": "/authorizers/"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authorizerName": {},
                "authorizerArn": {}
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListBillingGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "namePrefixFilter": {
            "location": "querystring",
            "locationName": "namePrefixFilter"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "billingGroups": {
            "type": "list",
            "member": {
              "shape": "Se5"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListCACertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/cacertificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "status": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "Sh6"
          },
          "nextMarker": {}
        }
      }
    },
    "ListCertificatesByCA": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-by-ca/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificateId"
        ],
        "members": {
          "caCertificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificates": {
            "shape": "Sh6"
          },
          "nextMarker": {}
        }
      }
    },
    "ListIndices": {
      "http": {
        "method": "GET",
        "requestUri": "/indices"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "indexNames": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListJobExecutionsForJob": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs/{jobId}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "executionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingArn": {},
                "jobExecutionSummary": {
                  "shape": "Shj"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobExecutionsForThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/jobs"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "executionSummaries": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobId": {},
                "jobExecutionSummary": {
                  "shape": "Shj"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListJobs": {
      "http": {
        "method": "GET",
        "requestUri": "/jobs"
      },
      "input": {
        "type": "structure",
        "members": {
          "status": {
            "location": "querystring",
            "locationName": "status"
          },
          "targetSelection": {
            "location": "querystring",
            "locationName": "targetSelection"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "thingGroupName": {
            "location": "querystring",
            "locationName": "thingGroupName"
          },
          "thingGroupId": {
            "location": "querystring",
            "locationName": "thingGroupId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "jobs": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "jobArn": {},
                "jobId": {},
                "thingGroupId": {},
                "targetSelection": {},
                "status": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "lastUpdatedAt": {
                  "type": "timestamp"
                },
                "completedAt": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListMitigationActions": {
      "http": {
        "method": "GET",
        "requestUri": "/mitigationactions/actions"
      },
      "input": {
        "type": "structure",
        "members": {
          "actionType": {
            "location": "querystring",
            "locationName": "actionType"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "actionName": {},
                "actionArn": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOTAUpdates": {
      "http": {
        "method": "GET",
        "requestUri": "/otaUpdates"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "otaUpdateStatus": {
            "location": "querystring",
            "locationName": "otaUpdateStatus"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "otaUpdates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "otaUpdateId": {},
                "otaUpdateArn": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListOutgoingCertificates": {
      "http": {
        "method": "GET",
        "requestUri": "/certificates-out-going"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "outgoingCertificates": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "certificateArn": {},
                "certificateId": {},
                "transferredTo": {},
                "transferDate": {
                  "type": "timestamp"
                },
                "transferMessage": {},
                "creationDate": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/policies"
      },
      "input": {
        "type": "structure",
        "members": {
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Sg9"
          },
          "nextMarker": {}
        }
      }
    },
    "ListPolicyPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/policy-principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "header",
            "locationName": "x-amzn-iot-policy"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "Si8"
          },
          "nextMarker": {}
        }
      },
      "deprecated": true
    },
    "ListPolicyVersions": {
      "http": {
        "method": "GET",
        "requestUri": "/policies/{policyName}/version"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policyVersions": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "versionId": {},
                "isDefaultVersion": {
                  "type": "boolean"
                },
                "createDate": {
                  "type": "timestamp"
                }
              }
            }
          }
        }
      }
    },
    "ListPrincipalPolicies": {
      "http": {
        "method": "GET",
        "requestUri": "/principal-policies"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "principal": {
            "location": "header",
            "locationName": "x-amzn-iot-principal"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "policies": {
            "shape": "Sg9"
          },
          "nextMarker": {}
        }
      },
      "deprecated": true
    },
    "ListPrincipalThings": {
      "http": {
        "method": "GET",
        "requestUri": "/principals/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "principal"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "principal": {
            "location": "header",
            "locationName": "x-amzn-principal"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Sii"
          },
          "nextToken": {}
        }
      }
    },
    "ListRoleAliases": {
      "http": {
        "method": "GET",
        "requestUri": "/role-aliases"
      },
      "input": {
        "type": "structure",
        "members": {
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAliases": {
            "type": "list",
            "member": {}
          },
          "nextMarker": {}
        }
      }
    },
    "ListScheduledAudits": {
      "http": {
        "method": "GET",
        "requestUri": "/audit/scheduledaudits"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAudits": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "scheduledAuditName": {},
                "scheduledAuditArn": {},
                "frequency": {},
                "dayOfMonth": {},
                "dayOfWeek": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSecurityProfiles": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileIdentifiers": {
            "type": "list",
            "member": {
              "shape": "Sit"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListSecurityProfilesForTarget": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles-for-target"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileTargetArn"
        ],
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "securityProfileTargetArn": {
            "location": "querystring",
            "locationName": "securityProfileTargetArn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileTargetMappings": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "securityProfileIdentifier": {
                  "shape": "Sit"
                },
                "target": {
                  "shape": "Siy"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListStreams": {
      "http": {
        "method": "GET",
        "requestUri": "/streams"
      },
      "input": {
        "type": "structure",
        "members": {
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ascendingOrder": {
            "location": "querystring",
            "locationName": "isAscendingOrder",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streams": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "streamId": {},
                "streamArn": {},
                "streamVersion": {
                  "type": "integer"
                },
                "description": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListTagsForResource": {
      "http": {
        "method": "GET",
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn"
        ],
        "members": {
          "resourceArn": {
            "location": "querystring",
            "locationName": "resourceArn"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "tags": {
            "shape": "S1t"
          },
          "nextToken": {}
        }
      }
    },
    "ListTargetsForPolicy": {
      "http": {
        "requestUri": "/policy-targets/{policyName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "marker": {
            "location": "querystring",
            "locationName": "marker"
          },
          "pageSize": {
            "location": "querystring",
            "locationName": "pageSize",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "targets": {
            "type": "list",
            "member": {}
          },
          "nextMarker": {}
        }
      }
    },
    "ListTargetsForSecurityProfile": {
      "http": {
        "method": "GET",
        "requestUri": "/security-profiles/{securityProfileName}/targets"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileTargets": {
            "type": "list",
            "member": {
              "shape": "Siy"
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThingGroups": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "parentGroup": {
            "location": "querystring",
            "locationName": "parentGroup"
          },
          "namePrefixFilter": {
            "location": "querystring",
            "locationName": "namePrefixFilter"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroups": {
            "shape": "Se4"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingGroupsForThing": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/thing-groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingGroups": {
            "shape": "Se4"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingPrincipals": {
      "http": {
        "method": "GET",
        "requestUri": "/things/{thingName}/principals"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "principals": {
            "shape": "Si8"
          }
        }
      }
    },
    "ListThingRegistrationTaskReports": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks/{taskId}/reports"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "reportType"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          },
          "reportType": {
            "location": "querystring",
            "locationName": "reportType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "resourceLinks": {
            "type": "list",
            "member": {}
          },
          "reportType": {},
          "nextToken": {}
        }
      }
    },
    "ListThingRegistrationTasks": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-registration-tasks"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "status": {
            "location": "querystring",
            "locationName": "status"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskIds": {
            "type": "list",
            "member": {}
          },
          "nextToken": {}
        }
      }
    },
    "ListThingTypes": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-types"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "thingTypes": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingTypeName": {},
                "thingTypeArn": {},
                "thingTypeProperties": {
                  "shape": "S6t"
                },
                "thingTypeMetadata": {
                  "shape": "Seh"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThings": {
      "http": {
        "method": "GET",
        "requestUri": "/things"
      },
      "input": {
        "type": "structure",
        "members": {
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "attributeName": {
            "location": "querystring",
            "locationName": "attributeName"
          },
          "attributeValue": {
            "location": "querystring",
            "locationName": "attributeValue"
          },
          "thingTypeName": {
            "location": "querystring",
            "locationName": "thingTypeName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingName": {},
                "thingTypeName": {},
                "thingArn": {},
                "attributes": {
                  "shape": "S28"
                },
                "version": {
                  "type": "long"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListThingsInBillingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/billing-groups/{billingGroupName}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Sii"
          },
          "nextToken": {}
        }
      }
    },
    "ListThingsInThingGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/thing-groups/{thingGroupName}/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "recursive": {
            "location": "querystring",
            "locationName": "recursive",
            "type": "boolean"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "things": {
            "shape": "Sii"
          },
          "nextToken": {}
        }
      }
    },
    "ListTopicRules": {
      "http": {
        "method": "GET",
        "requestUri": "/rules"
      },
      "input": {
        "type": "structure",
        "members": {
          "topic": {
            "location": "querystring",
            "locationName": "topic"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "ruleDisabled": {
            "location": "querystring",
            "locationName": "ruleDisabled",
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "rules": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "ruleArn": {},
                "ruleName": {},
                "topicPattern": {},
                "createdAt": {
                  "type": "timestamp"
                },
                "ruleDisabled": {
                  "type": "boolean"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListV2LoggingLevels": {
      "http": {
        "method": "GET",
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "members": {
          "targetType": {
            "location": "querystring",
            "locationName": "targetType"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "logTargetConfigurations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "logTarget": {
                  "shape": "Ske"
                },
                "logLevel": {}
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "ListViolationEvents": {
      "http": {
        "method": "GET",
        "requestUri": "/violation-events"
      },
      "input": {
        "type": "structure",
        "required": [
          "startTime",
          "endTime"
        ],
        "members": {
          "startTime": {
            "location": "querystring",
            "locationName": "startTime",
            "type": "timestamp"
          },
          "endTime": {
            "location": "querystring",
            "locationName": "endTime",
            "type": "timestamp"
          },
          "thingName": {
            "location": "querystring",
            "locationName": "thingName"
          },
          "securityProfileName": {
            "location": "querystring",
            "locationName": "securityProfileName"
          },
          "nextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          },
          "maxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "violationEvents": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "violationId": {},
                "thingName": {},
                "securityProfileName": {},
                "behavior": {
                  "shape": "S5s"
                },
                "metricValue": {
                  "shape": "S5x"
                },
                "violationEventType": {},
                "violationEventTime": {
                  "type": "timestamp"
                }
              }
            }
          },
          "nextToken": {}
        }
      }
    },
    "RegisterCACertificate": {
      "http": {
        "requestUri": "/cacertificate"
      },
      "input": {
        "type": "structure",
        "required": [
          "caCertificate",
          "verificationCertificate"
        ],
        "members": {
          "caCertificate": {},
          "verificationCertificate": {},
          "setAsActive": {
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "allowAutoRegistration": {
            "location": "querystring",
            "locationName": "allowAutoRegistration",
            "type": "boolean"
          },
          "registrationConfig": {
            "shape": "Scb"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterCertificate": {
      "http": {
        "requestUri": "/certificate/register"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificatePem"
        ],
        "members": {
          "certificatePem": {},
          "caCertificatePem": {},
          "setAsActive": {
            "deprecated": true,
            "location": "querystring",
            "locationName": "setAsActive",
            "type": "boolean"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {}
        }
      }
    },
    "RegisterThing": {
      "http": {
        "requestUri": "/things"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateBody"
        ],
        "members": {
          "templateBody": {},
          "parameters": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "certificatePem": {},
          "resourceArns": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "RejectCertificateTransfer": {
      "http": {
        "method": "PATCH",
        "requestUri": "/reject-certificate-transfer/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "rejectReason": {}
        }
      }
    },
    "RemoveThingFromBillingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/billing-groups/removeThingFromBillingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "billingGroupName": {},
          "billingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RemoveThingFromThingGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/removeThingFromThingGroup"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingGroupName": {},
          "thingGroupArn": {},
          "thingName": {},
          "thingArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ReplaceTopicRule": {
      "http": {
        "method": "PATCH",
        "requestUri": "/rules/{ruleName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "ruleName",
          "topicRulePayload"
        ],
        "members": {
          "ruleName": {
            "location": "uri",
            "locationName": "ruleName"
          },
          "topicRulePayload": {
            "shape": "S71"
          }
        },
        "payload": "topicRulePayload"
      }
    },
    "SearchIndex": {
      "http": {
        "requestUri": "/indices/search"
      },
      "input": {
        "type": "structure",
        "required": [
          "queryString"
        ],
        "members": {
          "indexName": {},
          "queryString": {},
          "nextToken": {},
          "maxResults": {
            "type": "integer"
          },
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "nextToken": {},
          "things": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingName": {},
                "thingId": {},
                "thingTypeName": {},
                "thingGroupNames": {
                  "shape": "Sl6"
                },
                "attributes": {
                  "shape": "S28"
                },
                "shadow": {},
                "connectivity": {
                  "type": "structure",
                  "members": {
                    "connected": {
                      "type": "boolean"
                    },
                    "timestamp": {
                      "type": "long"
                    }
                  }
                }
              }
            }
          },
          "thingGroups": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "thingGroupName": {},
                "thingGroupId": {},
                "thingGroupDescription": {},
                "attributes": {
                  "shape": "S28"
                },
                "parentGroupNames": {
                  "shape": "Sl6"
                }
              }
            }
          }
        }
      }
    },
    "SetDefaultAuthorizer": {
      "http": {
        "requestUri": "/default-authorizer"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "SetDefaultPolicyVersion": {
      "http": {
        "method": "PATCH",
        "requestUri": "/policies/{policyName}/version/{policyVersionId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "policyName",
          "policyVersionId"
        ],
        "members": {
          "policyName": {
            "location": "uri",
            "locationName": "policyName"
          },
          "policyVersionId": {
            "location": "uri",
            "locationName": "policyVersionId"
          }
        }
      }
    },
    "SetLoggingOptions": {
      "http": {
        "requestUri": "/loggingOptions"
      },
      "input": {
        "type": "structure",
        "required": [
          "loggingOptionsPayload"
        ],
        "members": {
          "loggingOptionsPayload": {
            "type": "structure",
            "required": [
              "roleArn"
            ],
            "members": {
              "roleArn": {},
              "logLevel": {}
            }
          }
        },
        "payload": "loggingOptionsPayload"
      }
    },
    "SetV2LoggingLevel": {
      "http": {
        "requestUri": "/v2LoggingLevel"
      },
      "input": {
        "type": "structure",
        "required": [
          "logTarget",
          "logLevel"
        ],
        "members": {
          "logTarget": {
            "shape": "Ske"
          },
          "logLevel": {}
        }
      }
    },
    "SetV2LoggingOptions": {
      "http": {
        "requestUri": "/v2LoggingOptions"
      },
      "input": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "defaultLogLevel": {},
          "disableAllLogs": {
            "type": "boolean"
          }
        }
      }
    },
    "StartAuditMitigationActionsTask": {
      "http": {
        "requestUri": "/audit/mitigationactions/tasks/{taskId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId",
          "target",
          "auditCheckToActionsMapping",
          "clientRequestToken"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          },
          "target": {
            "shape": "Sb2"
          },
          "auditCheckToActionsMapping": {
            "shape": "Sb6"
          },
          "clientRequestToken": {
            "idempotencyToken": true
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StartOnDemandAuditTask": {
      "http": {
        "requestUri": "/audit/tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "targetCheckNames"
        ],
        "members": {
          "targetCheckNames": {
            "shape": "S5k"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StartThingRegistrationTask": {
      "http": {
        "requestUri": "/thing-registration-tasks"
      },
      "input": {
        "type": "structure",
        "required": [
          "templateBody",
          "inputFileBucket",
          "inputFileKey",
          "roleArn"
        ],
        "members": {
          "templateBody": {},
          "inputFileBucket": {},
          "inputFileKey": {},
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "taskId": {}
        }
      }
    },
    "StopThingRegistrationTask": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-registration-tasks/{taskId}/cancel"
      },
      "input": {
        "type": "structure",
        "required": [
          "taskId"
        ],
        "members": {
          "taskId": {
            "location": "uri",
            "locationName": "taskId"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TagResource": {
      "http": {
        "requestUri": "/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tags"
        ],
        "members": {
          "resourceArn": {},
          "tags": {
            "shape": "S1t"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "TestAuthorization": {
      "http": {
        "requestUri": "/test-authorization"
      },
      "input": {
        "type": "structure",
        "required": [
          "authInfos"
        ],
        "members": {
          "principal": {},
          "cognitoIdentityPoolId": {},
          "authInfos": {
            "type": "list",
            "member": {
              "shape": "Slw"
            }
          },
          "clientId": {
            "location": "querystring",
            "locationName": "clientId"
          },
          "policyNamesToAdd": {
            "shape": "Sm0"
          },
          "policyNamesToSkip": {
            "shape": "Sm0"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authResults": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "authInfo": {
                  "shape": "Slw"
                },
                "allowed": {
                  "type": "structure",
                  "members": {
                    "policies": {
                      "shape": "Sg9"
                    }
                  }
                },
                "denied": {
                  "type": "structure",
                  "members": {
                    "implicitDeny": {
                      "type": "structure",
                      "members": {
                        "policies": {
                          "shape": "Sg9"
                        }
                      }
                    },
                    "explicitDeny": {
                      "type": "structure",
                      "members": {
                        "policies": {
                          "shape": "Sg9"
                        }
                      }
                    }
                  }
                },
                "authDecision": {},
                "missingContextValues": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          }
        }
      }
    },
    "TestInvokeAuthorizer": {
      "http": {
        "requestUri": "/authorizer/{authorizerName}/test"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName",
          "token",
          "tokenSignature"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "token": {},
          "tokenSignature": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "isAuthenticated": {
            "type": "boolean"
          },
          "principalId": {},
          "policyDocuments": {
            "type": "list",
            "member": {}
          },
          "refreshAfterInSeconds": {
            "type": "integer"
          },
          "disconnectAfterInSeconds": {
            "type": "integer"
          }
        }
      }
    },
    "TransferCertificate": {
      "http": {
        "method": "PATCH",
        "requestUri": "/transfer-certificate/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "targetAwsAccount"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "targetAwsAccount": {
            "location": "querystring",
            "locationName": "targetAwsAccount"
          },
          "transferMessage": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "transferredCertificateArn": {}
        }
      }
    },
    "UntagResource": {
      "http": {
        "requestUri": "/untag"
      },
      "input": {
        "type": "structure",
        "required": [
          "resourceArn",
          "tagKeys"
        ],
        "members": {
          "resourceArn": {},
          "tagKeys": {
            "type": "list",
            "member": {}
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAccountAuditConfiguration": {
      "http": {
        "method": "PATCH",
        "requestUri": "/audit/configuration"
      },
      "input": {
        "type": "structure",
        "members": {
          "roleArn": {},
          "auditNotificationTargetConfigurations": {
            "shape": "Sa4"
          },
          "auditCheckConfigurations": {
            "shape": "Sa8"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateAuthorizer": {
      "http": {
        "method": "PUT",
        "requestUri": "/authorizer/{authorizerName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "authorizerName"
        ],
        "members": {
          "authorizerName": {
            "location": "uri",
            "locationName": "authorizerName"
          },
          "authorizerFunctionArn": {},
          "tokenKeyName": {},
          "tokenSigningPublicKeys": {
            "shape": "S1k"
          },
          "status": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "authorizerName": {},
          "authorizerArn": {}
        }
      }
    },
    "UpdateBillingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/billing-groups/{billingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "billingGroupName",
          "billingGroupProperties"
        ],
        "members": {
          "billingGroupName": {
            "location": "uri",
            "locationName": "billingGroupName"
          },
          "billingGroupProperties": {
            "shape": "S1r"
          },
          "expectedVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateCACertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/cacertificate/{caCertificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "caCertificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          },
          "newAutoRegistrationStatus": {
            "location": "querystring",
            "locationName": "newAutoRegistrationStatus"
          },
          "registrationConfig": {
            "shape": "Scb"
          },
          "removeAutoRegistration": {
            "type": "boolean"
          }
        }
      }
    },
    "UpdateCertificate": {
      "http": {
        "method": "PUT",
        "requestUri": "/certificates/{certificateId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "certificateId",
          "newStatus"
        ],
        "members": {
          "certificateId": {
            "location": "uri",
            "locationName": "certificateId"
          },
          "newStatus": {
            "location": "querystring",
            "locationName": "newStatus"
          }
        }
      }
    },
    "UpdateDynamicThingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/dynamic-thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "thingGroupProperties"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S25"
          },
          "expectedVersion": {
            "type": "long"
          },
          "indexName": {},
          "queryString": {},
          "queryVersion": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateEventConfigurations": {
      "http": {
        "method": "PATCH",
        "requestUri": "/event-configurations"
      },
      "input": {
        "type": "structure",
        "members": {
          "eventConfigurations": {
            "shape": "Scr"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateIndexingConfiguration": {
      "http": {
        "requestUri": "/indexing/config"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingIndexingConfiguration": {
            "shape": "Sey"
          },
          "thingGroupIndexingConfiguration": {
            "shape": "Sf1"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateJob": {
      "http": {
        "method": "PATCH",
        "requestUri": "/jobs/{jobId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "jobId"
        ],
        "members": {
          "jobId": {
            "location": "uri",
            "locationName": "jobId"
          },
          "description": {},
          "presignedUrlConfig": {
            "shape": "S2k"
          },
          "jobExecutionsRolloutConfig": {
            "shape": "S2o"
          },
          "abortConfig": {
            "shape": "S2v"
          },
          "timeoutConfig": {
            "shape": "S32"
          }
        }
      }
    },
    "UpdateMitigationAction": {
      "http": {
        "method": "PATCH",
        "requestUri": "/mitigationactions/actions/{actionName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "actionName"
        ],
        "members": {
          "actionName": {
            "location": "uri",
            "locationName": "actionName"
          },
          "roleArn": {},
          "actionParams": {
            "shape": "S3c"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "actionArn": {},
          "actionId": {}
        }
      }
    },
    "UpdateRoleAlias": {
      "http": {
        "method": "PUT",
        "requestUri": "/role-aliases/{roleAlias}"
      },
      "input": {
        "type": "structure",
        "required": [
          "roleAlias"
        ],
        "members": {
          "roleAlias": {
            "location": "uri",
            "locationName": "roleAlias"
          },
          "roleArn": {},
          "credentialDurationSeconds": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "roleAlias": {},
          "roleAliasArn": {}
        }
      }
    },
    "UpdateScheduledAudit": {
      "http": {
        "method": "PATCH",
        "requestUri": "/audit/scheduledaudits/{scheduledAuditName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "scheduledAuditName"
        ],
        "members": {
          "frequency": {},
          "dayOfMonth": {},
          "dayOfWeek": {},
          "targetCheckNames": {
            "shape": "S5k"
          },
          "scheduledAuditName": {
            "location": "uri",
            "locationName": "scheduledAuditName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "scheduledAuditArn": {}
        }
      }
    },
    "UpdateSecurityProfile": {
      "http": {
        "method": "PATCH",
        "requestUri": "/security-profiles/{securityProfileName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "securityProfileName"
        ],
        "members": {
          "securityProfileName": {
            "location": "uri",
            "locationName": "securityProfileName"
          },
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S5r"
          },
          "alertTargets": {
            "shape": "S68"
          },
          "additionalMetricsToRetain": {
            "shape": "S6c"
          },
          "deleteBehaviors": {
            "type": "boolean"
          },
          "deleteAlertTargets": {
            "type": "boolean"
          },
          "deleteAdditionalMetricsToRetain": {
            "type": "boolean"
          },
          "expectedVersion": {
            "location": "querystring",
            "locationName": "expectedVersion",
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "securityProfileName": {},
          "securityProfileArn": {},
          "securityProfileDescription": {},
          "behaviors": {
            "shape": "S5r"
          },
          "alertTargets": {
            "shape": "S68"
          },
          "additionalMetricsToRetain": {
            "shape": "S6c"
          },
          "version": {
            "type": "long"
          },
          "creationDate": {
            "type": "timestamp"
          },
          "lastModifiedDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "UpdateStream": {
      "http": {
        "method": "PUT",
        "requestUri": "/streams/{streamId}"
      },
      "input": {
        "type": "structure",
        "required": [
          "streamId"
        ],
        "members": {
          "streamId": {
            "location": "uri",
            "locationName": "streamId"
          },
          "description": {},
          "files": {
            "shape": "S6h"
          },
          "roleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "streamId": {},
          "streamArn": {},
          "description": {},
          "streamVersion": {
            "type": "integer"
          }
        }
      }
    },
    "UpdateThing": {
      "http": {
        "method": "PATCH",
        "requestUri": "/things/{thingName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingName"
        ],
        "members": {
          "thingName": {
            "location": "uri",
            "locationName": "thingName"
          },
          "thingTypeName": {},
          "attributePayload": {
            "shape": "S27"
          },
          "expectedVersion": {
            "type": "long"
          },
          "removeThingType": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateThingGroup": {
      "http": {
        "method": "PATCH",
        "requestUri": "/thing-groups/{thingGroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "thingGroupName",
          "thingGroupProperties"
        ],
        "members": {
          "thingGroupName": {
            "location": "uri",
            "locationName": "thingGroupName"
          },
          "thingGroupProperties": {
            "shape": "S25"
          },
          "expectedVersion": {
            "type": "long"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "version": {
            "type": "long"
          }
        }
      }
    },
    "UpdateThingGroupsForThing": {
      "http": {
        "method": "PUT",
        "requestUri": "/thing-groups/updateThingGroupsForThing"
      },
      "input": {
        "type": "structure",
        "members": {
          "thingName": {},
          "thingGroupsToAdd": {
            "shape": "Snn"
          },
          "thingGroupsToRemove": {
            "shape": "Snn"
          },
          "overrideDynamicGroups": {
            "type": "boolean"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ValidateSecurityProfileBehaviors": {
      "http": {
        "requestUri": "/security-profile-behaviors/validate"
      },
      "input": {
        "type": "structure",
        "required": [
          "behaviors"
        ],
        "members": {
          "behaviors": {
            "shape": "S5r"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "valid": {
            "type": "boolean"
          },
          "validationErrors": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "errorMessage": {}
              }
            }
          }
        }
      }
    }
  },
  "shapes": {
    "Sg": {
      "type": "list",
      "member": {}
    },
    "S1b": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1k": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1r": {
      "type": "structure",
      "members": {
        "billingGroupDescription": {}
      }
    },
    "S1t": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Key": {},
          "Value": {}
        }
      }
    },
    "S25": {
      "type": "structure",
      "members": {
        "thingGroupDescription": {},
        "attributePayload": {
          "shape": "S27"
        }
      }
    },
    "S27": {
      "type": "structure",
      "members": {
        "attributes": {
          "shape": "S28"
        },
        "merge": {
          "type": "boolean"
        }
      }
    },
    "S28": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S2k": {
      "type": "structure",
      "members": {
        "roleArn": {},
        "expiresInSec": {
          "type": "long"
        }
      }
    },
    "S2o": {
      "type": "structure",
      "members": {
        "maximumPerMinute": {
          "type": "integer"
        },
        "exponentialRate": {
          "type": "structure",
          "required": [
            "baseRatePerMinute",
            "incrementFactor",
            "rateIncreaseCriteria"
          ],
          "members": {
            "baseRatePerMinute": {
              "type": "integer"
            },
            "incrementFactor": {
              "type": "double"
            },
            "rateIncreaseCriteria": {
              "type": "structure",
              "members": {
                "numberOfNotifiedThings": {
                  "type": "integer"
                },
                "numberOfSucceededThings": {
                  "type": "integer"
                }
              }
            }
          }
        }
      }
    },
    "S2v": {
      "type": "structure",
      "required": [
        "criteriaList"
      ],
      "members": {
        "criteriaList": {
          "type": "list",
          "member": {
            "type": "structure",
            "required": [
              "failureType",
              "action",
              "thresholdPercentage",
              "minNumberOfExecutedThings"
            ],
            "members": {
              "failureType": {},
              "action": {},
              "thresholdPercentage": {
                "type": "double"
              },
              "minNumberOfExecutedThings": {
                "type": "integer"
              }
            }
          }
        }
      }
    },
    "S32": {
      "type": "structure",
      "members": {
        "inProgressTimeoutInMinutes": {
          "type": "long"
        }
      }
    },
    "S3c": {
      "type": "structure",
      "members": {
        "updateDeviceCertificateParams": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {}
          }
        },
        "updateCACertificateParams": {
          "type": "structure",
          "required": [
            "action"
          ],
          "members": {
            "action": {}
          }
        },
        "addThingsToThingGroupParams": {
          "type": "structure",
          "required": [
            "thingGroupNames"
          ],
          "members": {
            "thingGroupNames": {
              "type": "list",
              "member": {}
            },
            "overrideDynamicGroups": {
              "type": "boolean"
            }
          }
        },
        "replaceDefaultPolicyVersionParams": {
          "type": "structure",
          "required": [
            "templateName"
          ],
          "members": {
            "templateName": {}
          }
        },
        "enableIoTLoggingParams": {
          "type": "structure",
          "required": [
            "roleArnForLogging",
            "logLevel"
          ],
          "members": {
            "roleArnForLogging": {},
            "logLevel": {}
          }
        },
        "publishFindingToSnsParams": {
          "type": "structure",
          "required": [
            "topicArn"
          ],
          "members": {
            "topicArn": {}
          }
        }
      }
    },
    "S3v": {
      "type": "list",
      "member": {}
    },
    "S3x": {
      "type": "structure",
      "members": {
        "maximumPerMinute": {
          "type": "integer"
        }
      }
    },
    "S3z": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "fileName": {},
          "fileVersion": {},
          "fileLocation": {
            "type": "structure",
            "members": {
              "stream": {
                "type": "structure",
                "members": {
                  "streamId": {},
                  "fileId": {
                    "type": "integer"
                  }
                }
              },
              "s3Location": {
                "shape": "S47"
              }
            }
          },
          "codeSigning": {
            "type": "structure",
            "members": {
              "awsSignerJobId": {},
              "startSigningJobParameter": {
                "type": "structure",
                "members": {
                  "signingProfileParameter": {
                    "type": "structure",
                    "members": {
                      "certificateArn": {},
                      "platform": {},
                      "certificatePathOnDevice": {}
                    }
                  },
                  "signingProfileName": {},
                  "destination": {
                    "type": "structure",
                    "members": {
                      "s3Destination": {
                        "type": "structure",
                        "members": {
                          "bucket": {},
                          "prefix": {}
                        }
                      }
                    }
                  }
                }
              },
              "customCodeSigning": {
                "type": "structure",
                "members": {
                  "signature": {
                    "type": "structure",
                    "members": {
                      "inlineDocument": {
                        "type": "blob"
                      }
                    }
                  },
                  "certificateChain": {
                    "type": "structure",
                    "members": {
                      "certificateName": {},
                      "inlineDocument": {}
                    }
                  },
                  "hashAlgorithm": {},
                  "signatureAlgorithm": {}
                }
              }
            }
          },
          "attributes": {
            "type": "map",
            "key": {},
            "value": {}
          }
        }
      }
    },
    "S47": {
      "type": "structure",
      "members": {
        "bucket": {},
        "key": {},
        "version": {}
      }
    },
    "S4w": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S5k": {
      "type": "list",
      "member": {}
    },
    "S5r": {
      "type": "list",
      "member": {
        "shape": "S5s"
      }
    },
    "S5s": {
      "type": "structure",
      "required": [
        "name"
      ],
      "members": {
        "name": {},
        "metric": {},
        "criteria": {
          "type": "structure",
          "members": {
            "comparisonOperator": {},
            "value": {
              "shape": "S5x"
            },
            "durationSeconds": {
              "type": "integer"
            },
            "consecutiveDatapointsToAlarm": {
              "type": "integer"
            },
            "consecutiveDatapointsToClear": {
              "type": "integer"
            },
            "statisticalThreshold": {
              "type": "structure",
              "members": {
                "statistic": {}
              }
            }
          }
        }
      }
    },
    "S5x": {
      "type": "structure",
      "members": {
        "count": {
          "type": "long"
        },
        "cidrs": {
          "type": "list",
          "member": {}
        },
        "ports": {
          "type": "list",
          "member": {
            "type": "integer"
          }
        }
      }
    },
    "S68": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "required": [
          "alertTargetArn",
          "roleArn"
        ],
        "members": {
          "alertTargetArn": {},
          "roleArn": {}
        }
      }
    },
    "S6c": {
      "type": "list",
      "member": {}
    },
    "S6h": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "fileId": {
            "type": "integer"
          },
          "s3Location": {
            "shape": "S47"
          }
        }
      }
    },
    "S6t": {
      "type": "structure",
      "members": {
        "thingTypeDescription": {},
        "searchableAttributes": {
          "type": "list",
          "member": {}
        }
      }
    },
    "S71": {
      "type": "structure",
      "required": [
        "sql",
        "actions"
      ],
      "members": {
        "sql": {},
        "description": {},
        "actions": {
          "shape": "S74"
        },
        "ruleDisabled": {
          "type": "boolean"
        },
        "awsIotSqlVersion": {},
        "errorAction": {
          "shape": "S75"
        }
      }
    },
    "S74": {
      "type": "list",
      "member": {
        "shape": "S75"
      }
    },
    "S75": {
      "type": "structure",
      "members": {
        "dynamoDB": {
          "type": "structure",
          "required": [
            "tableName",
            "roleArn",
            "hashKeyField",
            "hashKeyValue"
          ],
          "members": {
            "tableName": {},
            "roleArn": {},
            "operation": {},
            "hashKeyField": {},
            "hashKeyValue": {},
            "hashKeyType": {},
            "rangeKeyField": {},
            "rangeKeyValue": {},
            "rangeKeyType": {},
            "payloadField": {}
          }
        },
        "dynamoDBv2": {
          "type": "structure",
          "required": [
            "roleArn",
            "putItem"
          ],
          "members": {
            "roleArn": {},
            "putItem": {
              "type": "structure",
              "required": [
                "tableName"
              ],
              "members": {
                "tableName": {}
              }
            }
          }
        },
        "lambda": {
          "type": "structure",
          "required": [
            "functionArn"
          ],
          "members": {
            "functionArn": {}
          }
        },
        "sns": {
          "type": "structure",
          "required": [
            "targetArn",
            "roleArn"
          ],
          "members": {
            "targetArn": {},
            "roleArn": {},
            "messageFormat": {}
          }
        },
        "sqs": {
          "type": "structure",
          "required": [
            "roleArn",
            "queueUrl"
          ],
          "members": {
            "roleArn": {},
            "queueUrl": {},
            "useBase64": {
              "type": "boolean"
            }
          }
        },
        "kinesis": {
          "type": "structure",
          "required": [
            "roleArn",
            "streamName"
          ],
          "members": {
            "roleArn": {},
            "streamName": {},
            "partitionKey": {}
          }
        },
        "republish": {
          "type": "structure",
          "required": [
            "roleArn",
            "topic"
          ],
          "members": {
            "roleArn": {},
            "topic": {},
            "qos": {
              "type": "integer"
            }
          }
        },
        "s3": {
          "type": "structure",
          "required": [
            "roleArn",
            "bucketName",
            "key"
          ],
          "members": {
            "roleArn": {},
            "bucketName": {},
            "key": {},
            "cannedAcl": {}
          }
        },
        "firehose": {
          "type": "structure",
          "required": [
            "roleArn",
            "deliveryStreamName"
          ],
          "members": {
            "roleArn": {},
            "deliveryStreamName": {},
            "separator": {}
          }
        },
        "cloudwatchMetric": {
          "type": "structure",
          "required": [
            "roleArn",
            "metricNamespace",
            "metricName",
            "metricValue",
            "metricUnit"
          ],
          "members": {
            "roleArn": {},
            "metricNamespace": {},
            "metricName": {},
            "metricValue": {},
            "metricUnit": {},
            "metricTimestamp": {}
          }
        },
        "cloudwatchAlarm": {
          "type": "structure",
          "required": [
            "roleArn",
            "alarmName",
            "stateReason",
            "stateValue"
          ],
          "members": {
            "roleArn": {},
            "alarmName": {},
            "stateReason": {},
            "stateValue": {}
          }
        },
        "elasticsearch": {
          "type": "structure",
          "required": [
            "roleArn",
            "endpoint",
            "index",
            "type",
            "id"
          ],
          "members": {
            "roleArn": {},
            "endpoint": {},
            "index": {},
            "type": {},
            "id": {}
          }
        },
        "salesforce": {
          "type": "structure",
          "required": [
            "token",
            "url"
          ],
          "members": {
            "token": {},
            "url": {}
          }
        },
        "iotAnalytics": {
          "type": "structure",
          "members": {
            "channelArn": {},
            "channelName": {},
            "roleArn": {}
          }
        },
        "iotEvents": {
          "type": "structure",
          "required": [
            "inputName",
            "roleArn"
          ],
          "members": {
            "inputName": {},
            "messageId": {},
            "roleArn": {}
          }
        },
        "stepFunctions": {
          "type": "structure",
          "required": [
            "stateMachineName",
            "roleArn"
          ],
          "members": {
            "executionNamePrefix": {},
            "stateMachineName": {},
            "roleArn": {}
          }
        }
      }
    },
    "Sa4": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "targetArn": {},
          "roleArn": {},
          "enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Sa8": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Sad": {
      "type": "structure",
      "members": {
        "findingId": {},
        "taskId": {},
        "checkName": {},
        "taskStartTime": {
          "type": "timestamp"
        },
        "findingTime": {
          "type": "timestamp"
        },
        "severity": {},
        "nonCompliantResource": {
          "type": "structure",
          "members": {
            "resourceType": {},
            "resourceIdentifier": {
              "shape": "Sai"
            },
            "additionalInfo": {
              "shape": "San"
            }
          }
        },
        "relatedResources": {
          "type": "list",
          "member": {
            "type": "structure",
            "members": {
              "resourceType": {},
              "resourceIdentifier": {
                "shape": "Sai"
              },
              "additionalInfo": {
                "shape": "San"
              }
            }
          }
        },
        "reasonForNonCompliance": {},
        "reasonForNonComplianceCode": {}
      }
    },
    "Sai": {
      "type": "structure",
      "members": {
        "deviceCertificateId": {},
        "caCertificateId": {},
        "cognitoIdentityPoolId": {},
        "clientId": {},
        "policyVersionIdentifier": {
          "type": "structure",
          "members": {
            "policyName": {},
            "policyVersionId": {}
          }
        },
        "account": {}
      }
    },
    "San": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sb2": {
      "type": "structure",
      "members": {
        "auditTaskId": {},
        "findingIds": {
          "type": "list",
          "member": {}
        },
        "auditCheckToReasonCodeFilter": {
          "type": "map",
          "key": {},
          "value": {
            "type": "list",
            "member": {}
          }
        }
      }
    },
    "Sb6": {
      "type": "map",
      "key": {},
      "value": {
        "type": "list",
        "member": {}
      }
    },
    "Sbw": {
      "type": "structure",
      "members": {
        "authorizerName": {},
        "authorizerArn": {},
        "authorizerFunctionArn": {},
        "tokenKeyName": {},
        "tokenSigningPublicKeys": {
          "shape": "S1k"
        },
        "status": {},
        "creationDate": {
          "type": "timestamp"
        },
        "lastModifiedDate": {
          "type": "timestamp"
        }
      }
    },
    "Sca": {
      "type": "structure",
      "members": {
        "notBefore": {
          "type": "timestamp"
        },
        "notAfter": {
          "type": "timestamp"
        }
      }
    },
    "Scb": {
      "type": "structure",
      "members": {
        "templateBody": {},
        "roleArn": {}
      }
    },
    "Scr": {
      "type": "map",
      "key": {},
      "value": {
        "type": "structure",
        "members": {
          "Enabled": {
            "type": "boolean"
          }
        }
      }
    },
    "Se4": {
      "type": "list",
      "member": {
        "shape": "Se5"
      }
    },
    "Se5": {
      "type": "structure",
      "members": {
        "groupName": {},
        "groupArn": {}
      }
    },
    "Seh": {
      "type": "structure",
      "members": {
        "deprecated": {
          "type": "boolean"
        },
        "deprecationDate": {
          "type": "timestamp"
        },
        "creationDate": {
          "type": "timestamp"
        }
      }
    },
    "Sey": {
      "type": "structure",
      "required": [
        "thingIndexingMode"
      ],
      "members": {
        "thingIndexingMode": {},
        "thingConnectivityIndexingMode": {}
      }
    },
    "Sf1": {
      "type": "structure",
      "required": [
        "thingGroupIndexingMode"
      ],
      "members": {
        "thingGroupIndexingMode": {}
      }
    },
    "Sg9": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "policyName": {},
          "policyArn": {}
        }
      }
    },
    "Sh6": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "certificateArn": {},
          "certificateId": {},
          "status": {},
          "creationDate": {
            "type": "timestamp"
          }
        }
      }
    },
    "Shj": {
      "type": "structure",
      "members": {
        "status": {},
        "queuedAt": {
          "type": "timestamp"
        },
        "startedAt": {
          "type": "timestamp"
        },
        "lastUpdatedAt": {
          "type": "timestamp"
        },
        "executionNumber": {
          "type": "long"
        }
      }
    },
    "Si8": {
      "type": "list",
      "member": {}
    },
    "Sii": {
      "type": "list",
      "member": {}
    },
    "Sit": {
      "type": "structure",
      "required": [
        "name",
        "arn"
      ],
      "members": {
        "name": {},
        "arn": {}
      }
    },
    "Siy": {
      "type": "structure",
      "required": [
        "arn"
      ],
      "members": {
        "arn": {}
      }
    },
    "Ske": {
      "type": "structure",
      "required": [
        "targetType"
      ],
      "members": {
        "targetType": {},
        "targetName": {}
      }
    },
    "Sl6": {
      "type": "list",
      "member": {}
    },
    "Slw": {
      "type": "structure",
      "members": {
        "actionType": {},
        "resources": {
          "type": "list",
          "member": {}
        }
      }
    },
    "Sm0": {
      "type": "list",
      "member": {}
    },
    "Snn": {
      "type": "list",
      "member": {}
    }
  }
}