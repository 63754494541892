import React from 'react';
import './Logo.css';

type LogoPropsType = {
  title?: string;
  claim?: string;
  claimText?: string;
  height?: number;
  width?: number;
  className?: string;
  textColor?: string;
  iconColor0?: string;
  iconColor1?: string;
  onClick?: () => void;
};

export const Logo: React.FC<LogoPropsType> = ({
  claim = true,
  claimText = 'Mietnebenkosten korrekt?',
  height = 35,
  width = 122,
  title = 'Nebenkostenabrechnung prüfen lassen bei MINEKO',
  textColor = '#383839',
  iconColor0 = '#6fbc4d',
  iconColor1 = '#16a085',
  onClick,
  className,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 854.7 255"
    version="1"
    width={width}
    height={height}
    aria-labelledby={title}
    className={className}
    onClick={onClick}
  >
    <title id={title}>{title}</title>
    <linearGradient id="SVGID_1_" x1="652.1" x2="853.1" y1="84.75" y2="84.75" gradientUnits="userSpaceOnUse">
      <stop offset="0" stopColor={iconColor0} />
      <stop offset="1" stopColor={iconColor1} />
    </linearGradient>
    <path fill="url(#SVGID_1_)" d="M652.1 78.8h29.4l28.6 33.6L826.3 3h26.8L705.7 166.5z" />
    <g>
      <path
        fill={textColor}
        d="M673.5 130.1c-1.1 0-2.1.2-2.9.2-23.4 0-42.1-18.9-42.1-42.1 0-23.4 18.9-42.1 42.1-42.1 23.4 0 42.1 18.9 42.1 42.1 0 3.4-.4 6.5-1.1 9.7l29.5-27.8c-8-31.4-36.6-54.7-70.5-54.7C630.4 15.4 598 48 598 88c0 40.2 32.6 72.6 72.6 72.6 6.9 0 13.7-1.1 20.2-2.7l-17.3-27.8z"
      />
      <path
        fill={textColor}
        d="M128 160c-2.1-54.7-3.4-91.8-3.4-111.3h-.6c-3.4 14.7-14.5 50.9-33.5 109H65.9c-11.4-40-18.1-64.6-20.4-73.7-2.3-9-4.8-20.8-7.8-35.4h-.4c-.8 27.6-1.7 47.4-2.3 59.6L32.3 160H2.8l8.8-137.9h42.9c10.5 35.1 16.8 57 19.2 65.2 2.1 8.4 4.2 18.3 6.5 29.9h.8c5.1-21.3 9.3-37.3 12.8-48l15.8-47.1h42.1l7.4 137.9H128zM181.7 22.2h31.8V160h-31.8V22.2zM267.8 160h-29.3V22.2h37.7l33.3 56.4c6.3 10.9 13 24.8 20 41.9h.4c-1.7-14.5-2.5-33.5-2.5-56.8V22.2h29.3V160h-33.5c-23.4-40.6-37.3-65-41.9-73.5-4.4-8.4-9.3-18.1-14.1-29.3h-.8c1.1 17.5 1.5 37.5 1.5 60l-.1 42.8zM462.4 76.5V102h-53v32.4h59.1V160h-90.7V22.2h87.8v25.7h-56.2v28.4h53v.2zM487.9 160V22.2h31.4V83c4.2-6.1 8-11.8 11.4-16.4l31.6-44.2h39.4l-47.1 58.5 49.9 79.3h-37l-35.6-59.8-12.2 14.7v45l-31.8-.1z"
      />
    </g>
    {claim && (
      <g>
        <text x="0" y="241" style={{ letterSpacing: '-1pt' }} fontSize={70} fontFamily="Meta" fill={textColor}>
          {claimText}
        </text>
      </g>
    )}
  </svg>
);
