{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-11-27",
    "endpointPrefix": "resource-groups",
    "protocol": "rest-json",
    "serviceAbbreviation": "Resource Groups",
    "serviceFullName": "AWS Resource Groups",
    "serviceId": "Resource Groups",
    "signatureVersion": "v4",
    "signingName": "resource-groups",
    "uid": "resource-groups-2017-11-27"
  },
  "operations": {
    "CreateGroup": {
      "http": {
        "requestUri": "/groups"
      },
      "input": {
        "type": "structure",
        "required": [
          "Name",
          "ResourceQuery"
        ],
        "members": {
          "Name": {},
          "Description": {},
          "ResourceQuery": {
            "shape": "S4"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          },
          "ResourceQuery": {
            "shape": "S4"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "DeleteGroup": {
      "http": {
        "method": "DELETE",
        "requestUri": "/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetGroup": {
      "http": {
        "method": "GET",
        "requestUri": "/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          }
        }
      }
    },
    "GetGroupQuery": {
      "http": {
        "method": "GET",
        "requestUri": "/groups/{GroupName}/query"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupQuery": {
            "shape": "Sj"
          }
        }
      }
    },
    "GetTags": {
      "http": {
        "method": "GET",
        "requestUri": "/resources/{Arn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn"
        ],
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "Arn"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "ListGroupResources": {
      "http": {
        "requestUri": "/groups/{GroupName}/resource-identifiers-list"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Values"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceIdentifiers": {
            "shape": "Sv"
          },
          "NextToken": {},
          "QueryErrors": {
            "shape": "Sz"
          }
        }
      }
    },
    "ListGroups": {
      "http": {
        "requestUri": "/groups-list"
      },
      "input": {
        "type": "structure",
        "members": {
          "Filters": {
            "type": "list",
            "member": {
              "type": "structure",
              "required": [
                "Name",
                "Values"
              ],
              "members": {
                "Name": {},
                "Values": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "location": "querystring",
            "locationName": "maxResults",
            "type": "integer"
          },
          "NextToken": {
            "location": "querystring",
            "locationName": "nextToken"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupIdentifiers": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "GroupName": {},
                "GroupArn": {}
              }
            }
          },
          "Groups": {
            "deprecated": true,
            "deprecatedMessage": "This field is deprecated, use GroupIdentifiers instead.",
            "type": "list",
            "member": {
              "shape": "Sb"
            }
          },
          "NextToken": {}
        }
      }
    },
    "SearchResources": {
      "http": {
        "requestUri": "/resources/search"
      },
      "input": {
        "type": "structure",
        "required": [
          "ResourceQuery"
        ],
        "members": {
          "ResourceQuery": {
            "shape": "S4"
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceIdentifiers": {
            "shape": "Sv"
          },
          "NextToken": {},
          "QueryErrors": {
            "shape": "Sz"
          }
        }
      }
    },
    "Tag": {
      "http": {
        "method": "PUT",
        "requestUri": "/resources/{Arn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "Tags"
        ],
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "Arn"
          },
          "Tags": {
            "shape": "S7"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Tags": {
            "shape": "S7"
          }
        }
      }
    },
    "Untag": {
      "http": {
        "method": "PATCH",
        "requestUri": "/resources/{Arn}/tags"
      },
      "input": {
        "type": "structure",
        "required": [
          "Arn",
          "Keys"
        ],
        "members": {
          "Arn": {
            "location": "uri",
            "locationName": "Arn"
          },
          "Keys": {
            "shape": "S1i"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Arn": {},
          "Keys": {
            "shape": "S1i"
          }
        }
      }
    },
    "UpdateGroup": {
      "http": {
        "method": "PUT",
        "requestUri": "/groups/{GroupName}"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "Description": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Group": {
            "shape": "Sb"
          }
        }
      }
    },
    "UpdateGroupQuery": {
      "http": {
        "method": "PUT",
        "requestUri": "/groups/{GroupName}/query"
      },
      "input": {
        "type": "structure",
        "required": [
          "GroupName",
          "ResourceQuery"
        ],
        "members": {
          "GroupName": {
            "location": "uri",
            "locationName": "GroupName"
          },
          "ResourceQuery": {
            "shape": "S4"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "GroupQuery": {
            "shape": "Sj"
          }
        }
      }
    }
  },
  "shapes": {
    "S4": {
      "type": "structure",
      "required": [
        "Type",
        "Query"
      ],
      "members": {
        "Type": {},
        "Query": {}
      }
    },
    "S7": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "Sb": {
      "type": "structure",
      "required": [
        "GroupArn",
        "Name"
      ],
      "members": {
        "GroupArn": {},
        "Name": {},
        "Description": {}
      }
    },
    "Sj": {
      "type": "structure",
      "required": [
        "GroupName",
        "ResourceQuery"
      ],
      "members": {
        "GroupName": {},
        "ResourceQuery": {
          "shape": "S4"
        }
      }
    },
    "Sv": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ResourceArn": {},
          "ResourceType": {}
        }
      }
    },
    "Sz": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "ErrorCode": {},
          "Message": {}
        }
      }
    },
    "S1i": {
      "type": "list",
      "member": {}
    }
  }
}