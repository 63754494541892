"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useConfig = void 0;
const react_1 = require("react");
const context_1 = require("../context");
const useConfig = () => {
    const config = (0, react_1.useContext)(context_1.ConfigContext);
    if (config instanceof Error) {
        config.message = `Error while fetching the application config: ${config.message}`;
        throw config;
    }
    if (config === undefined) {
        throw Error('useConfig must be used within a ConfigProvider tag');
    }
    return config;
};
exports.useConfig = useConfig;
