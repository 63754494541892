import { useTranslation } from 'react-i18next';

interface Rules {
  EMAIL: any;
  PASSWORD_BASIC: any;
  PASSWORD_ADVANCED: any;
  PASSWORD_ADVANCED_STRONG: any;
  CODE: any;
  PASSWORD_CONFIRMATION: any;
  FIRST_NAME: any;
  LAST_NAME: any;
  SALUTATION: any;
  ACCEPT: any;
}

export const strongPasswordRegex = new RegExp(
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[=+\-^$*.\[\]{}()?"!@#%&/\\,><':;|_~`])\S{8,99}$/,
);

export function useValidationRules(): Rules {
  const { t } = useTranslation();

  const EMAIL = {
    required: { value: true, message: t('Validation.email.required') },
    pattern: {
      value:
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, // eslint-disable-line
      message: t('Validation.email.format'),
    },
  };

  const PASSWORD_BASIC = {
    required: { value: true, message: t('Validation.password.required') },
  };

  const PASSWORD_ADVANCED = {
    required: { value: true, message: t('Validation.password.required') },
    pattern: {
      value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).{8,}$/,
      message: t('Validation.password.format'),
    },
  };

  const PASSWORD_ADVANCED_STRONG = {
    required: { value: true, message: t('Validation.password.required') },
    pattern: {
      value: strongPasswordRegex,
      message: t('Validation.password.format_strong'),
    },
  };

  const CODE = {
    required: { value: true, message: t('Validation.code.required') },
    pattern: { value: /^[0-9]{6}$/g, message: t('Validation.code.format') },
  };

  const PASSWORD_CONFIRMATION = {
    required: { value: true, message: t('Validation.confirm_password.required') },
  };

  const FIRST_NAME = {
    required: { value: true, message: t('Validation.first_name.required') },
  };
  const LAST_NAME = {
    required: { value: true, message: t('Validation.last_name.required') },
  };
  const SALUTATION = {
    required: { value: true, message: t('Validation.salutation.required') },
  };
  const ACCEPT = {
    required: { value: true, message: t('Validation.accept.required') },
  };

  return {
    EMAIL,
    PASSWORD_BASIC,
    PASSWORD_ADVANCED,
    PASSWORD_ADVANCED_STRONG,
    CODE,
    PASSWORD_CONFIRMATION,
    FIRST_NAME,
    LAST_NAME,
    SALUTATION,
    ACCEPT,
  };
}
