{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-03-31",
    "endpointPrefix": "lakeformation",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceFullName": "AWS Lake Formation",
    "serviceId": "LakeFormation",
    "signatureVersion": "v4",
    "signingName": "lakeformation",
    "targetPrefix": "AWSLakeFormation",
    "uid": "lakeformation-2017-03-31"
  },
  "operations": {
    "BatchGrantPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "Entries": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sl"
          }
        }
      }
    },
    "BatchRevokePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Entries"
        ],
        "members": {
          "CatalogId": {},
          "Entries": {
            "shape": "S3"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Failures": {
            "shape": "Sl"
          }
        }
      }
    },
    "DeregisterResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "DescribeResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceInfo": {
            "shape": "Sv"
          }
        }
      }
    },
    "GetDataLakeSettings": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "DataLakeSettings": {
            "shape": "S10"
          }
        }
      }
    },
    "GetEffectivePermissionsForPath": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "CatalogId": {},
          "ResourceArn": {},
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Permissions": {
            "shape": "S18"
          },
          "NextToken": {}
        }
      }
    },
    "GrantPermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Principal",
          "Resource",
          "Permissions"
        ],
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "S6"
          },
          "Resource": {
            "shape": "S8"
          },
          "Permissions": {
            "shape": "Si"
          },
          "PermissionsWithGrantOption": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "ListPermissions": {
      "input": {
        "type": "structure",
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "S6"
          },
          "ResourceType": {},
          "Resource": {
            "shape": "S8"
          },
          "NextToken": {},
          "MaxResults": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "PrincipalResourcePermissions": {
            "shape": "S18"
          },
          "NextToken": {}
        }
      }
    },
    "ListResources": {
      "input": {
        "type": "structure",
        "members": {
          "FilterConditionList": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Field": {},
                "ComparisonOperator": {},
                "StringValueList": {
                  "type": "list",
                  "member": {}
                }
              }
            }
          },
          "MaxResults": {
            "type": "integer"
          },
          "NextToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "ResourceInfoList": {
            "type": "list",
            "member": {
              "shape": "Sv"
            }
          },
          "NextToken": {}
        }
      }
    },
    "PutDataLakeSettings": {
      "input": {
        "type": "structure",
        "required": [
          "DataLakeSettings"
        ],
        "members": {
          "CatalogId": {},
          "DataLakeSettings": {
            "shape": "S10"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RegisterResource": {
      "input": {
        "type": "structure",
        "required": [
          "ResourceArn"
        ],
        "members": {
          "ResourceArn": {},
          "UseServiceLinkedRole": {
            "type": "boolean"
          },
          "RoleArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "RevokePermissions": {
      "input": {
        "type": "structure",
        "required": [
          "Principal",
          "Resource",
          "Permissions"
        ],
        "members": {
          "CatalogId": {},
          "Principal": {
            "shape": "S6"
          },
          "Resource": {
            "shape": "S8"
          },
          "Permissions": {
            "shape": "Si"
          },
          "PermissionsWithGrantOption": {
            "shape": "Si"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    },
    "UpdateResource": {
      "input": {
        "type": "structure",
        "required": [
          "RoleArn",
          "ResourceArn"
        ],
        "members": {
          "RoleArn": {},
          "ResourceArn": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {}
      }
    }
  },
  "shapes": {
    "S3": {
      "type": "list",
      "member": {
        "shape": "S4"
      }
    },
    "S4": {
      "type": "structure",
      "required": [
        "Id"
      ],
      "members": {
        "Id": {},
        "Principal": {
          "shape": "S6"
        },
        "Resource": {
          "shape": "S8"
        },
        "Permissions": {
          "shape": "Si"
        },
        "PermissionsWithGrantOption": {
          "shape": "Si"
        }
      }
    },
    "S6": {
      "type": "structure",
      "members": {
        "DataLakePrincipalIdentifier": {}
      }
    },
    "S8": {
      "type": "structure",
      "members": {
        "Catalog": {
          "type": "structure",
          "members": {}
        },
        "Database": {
          "type": "structure",
          "required": [
            "Name"
          ],
          "members": {
            "Name": {}
          }
        },
        "Table": {
          "type": "structure",
          "required": [
            "DatabaseName",
            "Name"
          ],
          "members": {
            "DatabaseName": {},
            "Name": {}
          }
        },
        "TableWithColumns": {
          "type": "structure",
          "members": {
            "DatabaseName": {},
            "Name": {},
            "ColumnNames": {
              "shape": "Se"
            },
            "ColumnWildcard": {
              "type": "structure",
              "members": {
                "ExcludedColumnNames": {
                  "shape": "Se"
                }
              }
            }
          }
        },
        "DataLocation": {
          "type": "structure",
          "required": [
            "ResourceArn"
          ],
          "members": {
            "ResourceArn": {}
          }
        }
      }
    },
    "Se": {
      "type": "list",
      "member": {}
    },
    "Si": {
      "type": "list",
      "member": {}
    },
    "Sl": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "RequestEntry": {
            "shape": "S4"
          },
          "Error": {
            "type": "structure",
            "members": {
              "ErrorCode": {},
              "ErrorMessage": {}
            }
          }
        }
      }
    },
    "Sv": {
      "type": "structure",
      "members": {
        "ResourceArn": {},
        "RoleArn": {},
        "LastModified": {
          "type": "timestamp"
        }
      }
    },
    "S10": {
      "type": "structure",
      "members": {
        "DataLakeAdmins": {
          "type": "list",
          "member": {
            "shape": "S6"
          }
        },
        "CreateDatabaseDefaultPermissions": {
          "shape": "S12"
        },
        "CreateTableDefaultPermissions": {
          "shape": "S12"
        }
      }
    },
    "S12": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "shape": "S6"
          },
          "Permissions": {
            "shape": "Si"
          }
        }
      }
    },
    "S18": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Principal": {
            "shape": "S6"
          },
          "Resource": {
            "shape": "S8"
          },
          "Permissions": {
            "shape": "Si"
          },
          "PermissionsWithGrantOption": {
            "shape": "Si"
          }
        }
      }
    }
  }
}