import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import './i18n';

console.info(`Release: ${(window as Record<string, any>)['VERSION']}`);

export const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(<App />);
