{
  "version": "2.0",
  "metadata": {
    "apiVersion": "2017-10-25",
    "endpointPrefix": "ce",
    "jsonVersion": "1.1",
    "protocol": "json",
    "serviceAbbreviation": "AWS Cost Explorer",
    "serviceFullName": "AWS Cost Explorer Service",
    "serviceId": "Cost Explorer",
    "signatureVersion": "v4",
    "signingName": "ce",
    "targetPrefix": "AWSInsightsIndexService",
    "uid": "ce-2017-10-25"
  },
  "operations": {
    "GetCostAndUsage": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "S2"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S5"
          },
          "Metrics": {
            "shape": "Sd"
          },
          "GroupBy": {
            "shape": "Sf"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "NextPageToken": {},
          "GroupDefinitions": {
            "shape": "Sf"
          },
          "ResultsByTime": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TimePeriod": {
                  "shape": "S2"
                },
                "Total": {
                  "shape": "Sn"
                },
                "Groups": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Keys": {
                        "type": "list",
                        "member": {}
                      },
                      "Metrics": {
                        "shape": "Sn"
                      }
                    }
                  }
                },
                "Estimated": {
                  "type": "boolean"
                }
              }
            }
          }
        }
      }
    },
    "GetCostForecast": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod",
          "Metric",
          "Granularity"
        ],
        "members": {
          "TimePeriod": {
            "shape": "S2"
          },
          "Metric": {},
          "Granularity": {},
          "Filter": {
            "shape": "S5"
          },
          "PredictionIntervalLevel": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Total": {
            "shape": "So"
          },
          "ForecastResultsByTime": {
            "shape": "S10"
          }
        }
      }
    },
    "GetDimensionValues": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod",
          "Dimension"
        ],
        "members": {
          "SearchString": {},
          "TimePeriod": {
            "shape": "S2"
          },
          "Dimension": {},
          "Context": {},
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "DimensionValues",
          "ReturnSize",
          "TotalSize"
        ],
        "members": {
          "DimensionValues": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "Value": {},
                "Attributes": {
                  "shape": "S19"
                }
              }
            }
          },
          "ReturnSize": {
            "type": "integer"
          },
          "TotalSize": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      }
    },
    "GetReservationCoverage": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "S2"
          },
          "GroupBy": {
            "shape": "Sf"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S5"
          },
          "Metrics": {
            "shape": "Sd"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "CoveragesByTime"
        ],
        "members": {
          "CoveragesByTime": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TimePeriod": {
                  "shape": "S2"
                },
                "Groups": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Attributes": {
                        "shape": "S19"
                      },
                      "Coverage": {
                        "shape": "S1j"
                      }
                    }
                  }
                },
                "Total": {
                  "shape": "S1j"
                }
              }
            }
          },
          "Total": {
            "shape": "S1j"
          },
          "NextPageToken": {}
        }
      }
    },
    "GetReservationPurchaseRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "Service"
        ],
        "members": {
          "AccountId": {},
          "Service": {},
          "AccountScope": {},
          "LookbackPeriodInDays": {},
          "TermInYears": {},
          "PaymentOption": {},
          "ServiceSpecification": {
            "shape": "S21"
          },
          "PageSize": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "type": "structure",
            "members": {
              "RecommendationId": {},
              "GenerationTimestamp": {}
            }
          },
          "Recommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountScope": {},
                "LookbackPeriodInDays": {},
                "TermInYears": {},
                "PaymentOption": {},
                "ServiceSpecification": {
                  "shape": "S21"
                },
                "RecommendationDetails": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "AccountId": {},
                      "InstanceDetails": {
                        "type": "structure",
                        "members": {
                          "EC2InstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "InstanceType": {},
                              "Region": {},
                              "AvailabilityZone": {},
                              "Platform": {},
                              "Tenancy": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "RDSInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "InstanceType": {},
                              "Region": {},
                              "DatabaseEngine": {},
                              "DatabaseEdition": {},
                              "DeploymentOption": {},
                              "LicenseModel": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "RedshiftInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "NodeType": {},
                              "Region": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "ElastiCacheInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "Family": {},
                              "NodeType": {},
                              "Region": {},
                              "ProductDescription": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          },
                          "ESInstanceDetails": {
                            "type": "structure",
                            "members": {
                              "InstanceClass": {},
                              "InstanceSize": {},
                              "Region": {},
                              "CurrentGeneration": {
                                "type": "boolean"
                              },
                              "SizeFlexEligible": {
                                "type": "boolean"
                              }
                            }
                          }
                        }
                      },
                      "RecommendedNumberOfInstancesToPurchase": {},
                      "RecommendedNormalizedUnitsToPurchase": {},
                      "MinimumNumberOfInstancesUsedPerHour": {},
                      "MinimumNormalizedUnitsUsedPerHour": {},
                      "MaximumNumberOfInstancesUsedPerHour": {},
                      "MaximumNormalizedUnitsUsedPerHour": {},
                      "AverageNumberOfInstancesUsedPerHour": {},
                      "AverageNormalizedUnitsUsedPerHour": {},
                      "AverageUtilization": {},
                      "EstimatedBreakEvenInMonths": {},
                      "CurrencyCode": {},
                      "EstimatedMonthlySavingsAmount": {},
                      "EstimatedMonthlySavingsPercentage": {},
                      "EstimatedMonthlyOnDemandCost": {},
                      "EstimatedReservationCostForLookbackPeriod": {},
                      "UpfrontCost": {},
                      "RecurringStandardMonthlyCost": {}
                    }
                  }
                },
                "RecommendationSummary": {
                  "type": "structure",
                  "members": {
                    "TotalEstimatedMonthlySavingsAmount": {},
                    "TotalEstimatedMonthlySavingsPercentage": {},
                    "CurrencyCode": {}
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "GetReservationUtilization": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "TimePeriod": {
            "shape": "S2"
          },
          "GroupBy": {
            "shape": "Sf"
          },
          "Granularity": {},
          "Filter": {
            "shape": "S5"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "UtilizationsByTime"
        ],
        "members": {
          "UtilizationsByTime": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "TimePeriod": {
                  "shape": "S2"
                },
                "Groups": {
                  "type": "list",
                  "member": {
                    "type": "structure",
                    "members": {
                      "Key": {},
                      "Value": {},
                      "Attributes": {
                        "shape": "S19"
                      },
                      "Utilization": {
                        "shape": "S2r"
                      }
                    }
                  }
                },
                "Total": {
                  "shape": "S2r"
                }
              }
            }
          },
          "Total": {
            "shape": "S2r"
          },
          "NextPageToken": {}
        }
      }
    },
    "GetRightsizingRecommendation": {
      "input": {
        "type": "structure",
        "required": [
          "Service"
        ],
        "members": {
          "Filter": {
            "shape": "S5"
          },
          "Service": {},
          "PageSize": {
            "type": "integer"
          },
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Metadata": {
            "type": "structure",
            "members": {
              "RecommendationId": {},
              "GenerationTimestamp": {},
              "LookbackPeriodInDays": {}
            }
          },
          "Summary": {
            "type": "structure",
            "members": {
              "TotalRecommendationCount": {},
              "EstimatedTotalMonthlySavingsAmount": {},
              "SavingsCurrencyCode": {},
              "SavingsPercentage": {}
            }
          },
          "RightsizingRecommendations": {
            "type": "list",
            "member": {
              "type": "structure",
              "members": {
                "AccountId": {},
                "CurrentInstance": {
                  "type": "structure",
                  "members": {
                    "ResourceId": {},
                    "Tags": {
                      "type": "list",
                      "member": {
                        "shape": "Sb"
                      }
                    },
                    "ResourceDetails": {
                      "shape": "S3e"
                    },
                    "ResourceUtilization": {
                      "shape": "S3g"
                    },
                    "ReservationCoveredHoursInLookbackPeriod": {},
                    "OnDemandHoursInLookbackPeriod": {},
                    "TotalRunningHoursInLookbackPeriod": {},
                    "MonthlyCost": {},
                    "CurrencyCode": {}
                  }
                },
                "RightsizingType": {},
                "ModifyRecommendationDetail": {
                  "type": "structure",
                  "members": {
                    "TargetInstances": {
                      "type": "list",
                      "member": {
                        "type": "structure",
                        "members": {
                          "EstimatedMonthlyCost": {},
                          "EstimatedMonthlySavings": {},
                          "CurrencyCode": {},
                          "DefaultTargetInstance": {
                            "type": "boolean"
                          },
                          "ResourceDetails": {
                            "shape": "S3e"
                          },
                          "ExpectedResourceUtilization": {
                            "shape": "S3g"
                          }
                        }
                      }
                    }
                  }
                },
                "TerminateRecommendationDetail": {
                  "type": "structure",
                  "members": {
                    "EstimatedMonthlySavings": {},
                    "CurrencyCode": {}
                  }
                }
              }
            }
          },
          "NextPageToken": {}
        }
      }
    },
    "GetTags": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod"
        ],
        "members": {
          "SearchString": {},
          "TimePeriod": {
            "shape": "S2"
          },
          "TagKey": {},
          "NextPageToken": {}
        }
      },
      "output": {
        "type": "structure",
        "required": [
          "Tags",
          "ReturnSize",
          "TotalSize"
        ],
        "members": {
          "NextPageToken": {},
          "Tags": {
            "type": "list",
            "member": {}
          },
          "ReturnSize": {
            "type": "integer"
          },
          "TotalSize": {
            "type": "integer"
          }
        }
      }
    },
    "GetUsageForecast": {
      "input": {
        "type": "structure",
        "required": [
          "TimePeriod",
          "Metric",
          "Granularity"
        ],
        "members": {
          "TimePeriod": {
            "shape": "S2"
          },
          "Metric": {},
          "Granularity": {},
          "Filter": {
            "shape": "S5"
          },
          "PredictionIntervalLevel": {
            "type": "integer"
          }
        }
      },
      "output": {
        "type": "structure",
        "members": {
          "Total": {
            "shape": "So"
          },
          "ForecastResultsByTime": {
            "shape": "S10"
          }
        }
      }
    }
  },
  "shapes": {
    "S2": {
      "type": "structure",
      "required": [
        "Start",
        "End"
      ],
      "members": {
        "Start": {},
        "End": {}
      }
    },
    "S5": {
      "type": "structure",
      "members": {
        "Or": {
          "shape": "S6"
        },
        "And": {
          "shape": "S6"
        },
        "Not": {
          "shape": "S5"
        },
        "Dimensions": {
          "type": "structure",
          "members": {
            "Key": {},
            "Values": {
              "shape": "S9"
            }
          }
        },
        "Tags": {
          "shape": "Sb"
        }
      }
    },
    "S6": {
      "type": "list",
      "member": {
        "shape": "S5"
      }
    },
    "S9": {
      "type": "list",
      "member": {}
    },
    "Sb": {
      "type": "structure",
      "members": {
        "Key": {},
        "Values": {
          "shape": "S9"
        }
      }
    },
    "Sd": {
      "type": "list",
      "member": {}
    },
    "Sf": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "Type": {},
          "Key": {}
        }
      }
    },
    "Sn": {
      "type": "map",
      "key": {},
      "value": {
        "shape": "So"
      }
    },
    "So": {
      "type": "structure",
      "members": {
        "Amount": {},
        "Unit": {}
      }
    },
    "S10": {
      "type": "list",
      "member": {
        "type": "structure",
        "members": {
          "TimePeriod": {
            "shape": "S2"
          },
          "MeanValue": {},
          "PredictionIntervalLowerBound": {},
          "PredictionIntervalUpperBound": {}
        }
      }
    },
    "S19": {
      "type": "map",
      "key": {},
      "value": {}
    },
    "S1j": {
      "type": "structure",
      "members": {
        "CoverageHours": {
          "type": "structure",
          "members": {
            "OnDemandHours": {},
            "ReservedHours": {},
            "TotalRunningHours": {},
            "CoverageHoursPercentage": {}
          }
        },
        "CoverageNormalizedUnits": {
          "type": "structure",
          "members": {
            "OnDemandNormalizedUnits": {},
            "ReservedNormalizedUnits": {},
            "TotalRunningNormalizedUnits": {},
            "CoverageNormalizedUnitsPercentage": {}
          }
        },
        "CoverageCost": {
          "type": "structure",
          "members": {
            "OnDemandCost": {}
          }
        }
      }
    },
    "S21": {
      "type": "structure",
      "members": {
        "EC2Specification": {
          "type": "structure",
          "members": {
            "OfferingClass": {}
          }
        }
      }
    },
    "S2r": {
      "type": "structure",
      "members": {
        "UtilizationPercentage": {},
        "UtilizationPercentageInUnits": {},
        "PurchasedHours": {},
        "PurchasedUnits": {},
        "TotalActualHours": {},
        "TotalActualUnits": {},
        "UnusedHours": {},
        "UnusedUnits": {},
        "OnDemandCostOfRIHoursUsed": {},
        "NetRISavings": {},
        "TotalPotentialRISavings": {},
        "AmortizedUpfrontFee": {},
        "AmortizedRecurringFee": {},
        "TotalAmortizedFee": {}
      }
    },
    "S3e": {
      "type": "structure",
      "members": {
        "EC2ResourceDetails": {
          "type": "structure",
          "members": {
            "HourlyOnDemandRate": {},
            "InstanceType": {},
            "Platform": {},
            "Region": {},
            "Sku": {},
            "Memory": {},
            "NetworkPerformance": {},
            "Storage": {},
            "Vcpu": {}
          }
        }
      }
    },
    "S3g": {
      "type": "structure",
      "members": {
        "EC2ResourceUtilization": {
          "type": "structure",
          "members": {
            "MaxCpuUtilizationPercentage": {},
            "MaxMemoryUtilizationPercentage": {},
            "MaxStorageUtilizationPercentage": {}
          }
        }
      }
    }
  }
}